import { JSONRender } from "@common/components/PreJSON/JSONRender";
import {
  EDIMessage,
  orderByCreatedAtAndIdDesc,
} from "@common/models/EDIMessage.model";
import {
  BoAccordion,
  BoAccordionDetails,
  BoAccordionSummary,
} from "@ops-design-system/components/BoAccordion/BoAccordion";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import isEmpty from "lodash/isEmpty";
import React from "react";
import styled from "styled-components";

const EmptyStateMessage = styled(BoCard)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100px;
`;

export interface EDIMessageProps {
  message: EDIMessage;
}

export interface EDIMessagesProps {
  messages: EDIMessage[] | undefined;
}

export const EDIMessageDetail = ({ message }: EDIMessageProps) => {
  return (
    <BoAccordion data-testid="ediMessageDetail">
      <BoAccordionSummary
        summary={message.formatSummary()}
        label={`Open EDI Message ${message.formatCreatedAt()}`}
      />
      <BoAccordionDetails>
        <JSONRender object={message.jsonMessage} />
      </BoAccordionDetails>
    </BoAccordion>
  );
};

export const EDIMessages = ({ messages }: EDIMessagesProps) => {
  if (isEmpty(messages)) {
    return (
      <EmptyStateMessage>There are no EDI Messages to show.</EmptyStateMessage>
    );
  }
  return (
    <>
      {(messages || []).sort(orderByCreatedAtAndIdDesc).map((message) => (
        <EDIMessageDetail key={`ediMessage:${message.id}`} message={message} />
      ))}
    </>
  );
};
