import {
  BoTextField,
  BoTextFieldProps,
} from "@ops-design-system/components/BoTextField/BoTextField";
import { maskPhone, unmaskPhone } from "@ops-design-system/forms/phone.mask";
import React, { FC } from "react";

interface BoPhoneFieldProps extends BoTextFieldProps<string> {}

export const BoPhoneField: FC<React.PropsWithChildren<BoPhoneFieldProps>> = ({
  name,
  disabled,
  children,
  ...rest
}) => {
  return (
    <BoTextField
      name={name}
      type="tel"
      inputMode="numeric"
      format={maskPhone}
      parse={unmaskPhone}
      disabled={disabled}
      {...rest}
    >
      {children}
    </BoTextField>
  );
};
