import {
  DownPaymentCalculatorStateContext,
  DownPaymentCalculatorStateProviderContext,
} from "@ops/components/DownPaymentCalculator/DownPaymentCalculatorStateProvider";
import { useContext } from "react";

export function useDownPaymentCalculatorState(): DownPaymentCalculatorStateProviderContext {
  const context = useContext<DownPaymentCalculatorStateProviderContext>(
    DownPaymentCalculatorStateContext
  );

  if (context === null) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }
  return context;
}
