import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledBillFactorsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: start;
  margin: ${rhOpsSpacingPx(3)} 0;
  row-gap: ${rhOpsSpacingPx(2.5)};
`;

export const StyledBillFactor = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  position: relative;
  text-align: center;
  width: 200px;

  > svg {
    height: 48px;
    width: 48px;
  }
`;
