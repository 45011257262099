import { premiseApi } from "@common/api/premiseApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { Premise } from "@common/models/Premise.model";
import { DeferredPaymentPlanListResponseType } from "@common/types/apiResponseTypes";
import { DeferredPaymentPlanType } from "@common/types/deferredPaymentPlanTypes";
import { RhApiError } from "@common/types/errorTypes";
import { downloadClickWrapper } from "@common/utils/downloadClickWrapper";
import { dppContractDownload } from "@common/utils/dppContractDownload";
import { noop } from "@common/utils/genericFunctions";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { DeferredPaymentInfo } from "@ops/components/DeferPaymentCard/DeferredPaymentInfo";
import { DeferPaymentFormStateProvider } from "@ops/components/DeferPaymentForm/DeferPaymentFormStateProvider";
import { DeferPaymentManageFormStateProvider } from "@ops/components/DeferPaymentManageForm/DeferPaymentManageFormStateProvider";
import { DeferPaymentManageModal } from "@ops/components/DeferPaymentManageWizard/DeferPaymentManageModal";
import { DeferPaymentManageModalStateProvider } from "@ops/components/DeferPaymentManageWizard/DeferPaymentManageModalStateProvider";
import { DeferPaymentModal } from "@ops/components/DeferPaymentWizard/DeferPaymentModal";
import { DeferPaymentModalStateProvider } from "@ops/components/DeferPaymentWizard/DeferPaymentModalStateProvider";
import { DownPaymentCalculatorStateProvider } from "@ops/components/DownPaymentCalculator/DownPaymentCalculatorStateProvider";
import { AccountSummary } from "@ops/models/AccountSummary.model";
import React, { useEffect } from "react";

interface DeferPaymentCardProps {
  accountSummary: AccountSummary;
  onSuccess: () => void;
  premise: Premise;
}

export const DeferPaymentCard = ({
  premise,
  accountSummary,
  onSuccess,
}: DeferPaymentCardProps) => {
  const flash = useRhFlash();

  const [
    { data: deferredPaymentPlanList, requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<DeferredPaymentPlanListResponseType>();

  useEffect(() => {
    setPending();

    if (premise.id) {
      premiseApi.deferredPaymentPlans
        .list(premise.id)
        .then(setSuccess)
        .catch(setFailure);
    } else {
      setSuccess([]);
    }
  }, [premise.id]);

  if (requestMonitor.isWaiting) {
    return (
      <BoCard data-testid="premise-billing-card-target">
        <BoCircularProgress />
      </BoCard>
    );
  }

  let activeDPP: DeferredPaymentPlanType | undefined;

  if (deferredPaymentPlanList) {
    activeDPP = deferredPaymentPlanList.find((dpp) => dpp.status === "ACTIVE");
  }

  const downloadContract = downloadClickWrapper(() => {
    if (activeDPP) {
      dppContractDownload(premise.id, activeDPP.id)
        .then(noop)
        .catch((_error: RhApiError) => {
          flash.error(
            "We are having issues retrieving the deferred payment plan contract. Please try again."
          );
        });
    }
  });

  function renderDPPContractLetter() {
    if (activeDPP) {
      return (
        <BoInfoContainer>
          <BoInfoLabel>DPP Contract Letter</BoInfoLabel>
          <BoInfoValue>
            <BoLink onClick={downloadContract} href="#">
              View
            </BoLink>
          </BoInfoValue>
        </BoInfoContainer>
      );
    }
  }

  function renderDPPModal() {
    const totalBalance =
      accountSummary.balanceBreakdown?.energy.totalBalance ?? 0;
    const pastDueBalance =
      accountSummary.balanceBreakdown?.energy.pastDueBalance ?? 0;

    if (activeDPP) {
      return (
        <DeferPaymentManageModalStateProvider>
          <DeferPaymentManageFormStateProvider>
            <DeferPaymentManageModal
              activeDeferredPaymentPlan={activeDPP}
              currentBalance={totalBalance}
              premise={premise}
              onSuccess={onSuccess}
            />
          </DeferPaymentManageFormStateProvider>
        </DeferPaymentManageModalStateProvider>
      );
    }

    return (
      <DeferPaymentModalStateProvider>
        <DeferPaymentFormStateProvider>
          <DownPaymentCalculatorStateProvider>
            <DeferPaymentModal
              currentBalance={totalBalance}
              pastDueBalance={pastDueBalance}
              premise={premise}
              onSuccess={onSuccess}
            />
          </DownPaymentCalculatorStateProvider>
        </DeferPaymentFormStateProvider>
      </DeferPaymentModalStateProvider>
    );
  }

  return (
    <BoCard data-testid="premise-billing-card-target">
      <BoGrid>
        <BoGridColumn>
          <DeferredPaymentInfo activeDeferredPaymentPlan={activeDPP} />
        </BoGridColumn>
        <BoGridColumn>
          {renderDPPContractLetter()}

          {renderDPPModal()}
        </BoGridColumn>
      </BoGrid>
    </BoCard>
  );
};
