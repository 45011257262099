import { grey } from "@design-system/theme/palette.colors";
import { Plugin } from "chart.js";

export const backgroundPlugin: Plugin<"bar"> = {
  beforeDatasetsDraw: (chart) => {
    const context = chart.ctx;

    const {
      width: chartWidth,
      height: chartHeight,
      top,
      left,
    } = chart.chartArea;

    const barLocations: Record<string, { horizontal: boolean; size: number }> =
      {};

    chart.data.datasets.forEach((_, datasetIndex) => {
      const datasetMeta = chart.getDatasetMeta(datasetIndex);

      datasetMeta.data.forEach((segment) => {
        const {
          width: elementWidth,
          height: elementHeight,
          horizontal,
        } = segment.getProps(["height", "width", "horizontal"], true);

        if (horizontal) {
          barLocations[segment.y] = { horizontal, size: elementHeight };
        } else {
          barLocations[segment.x] = { horizontal, size: elementWidth };
        }
      });
    });

    context.save();

    context.fillStyle = grey["50"];

    Object.entries(barLocations).forEach(([location, { horizontal, size }]) => {
      if (horizontal) {
        context.fillRect(left, Number(location) - size / 2, chartWidth, size);
      } else {
        context.fillRect(Number(location) - size / 2, top, size, chartHeight);
      }
    });

    context.restore();
  },
  id: "backgroundPlugin",
};

const getOrCreateLegendList = () => {
  const legendContainer = document.getElementById("legend-container");

  let listContainer = legendContainer?.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.flexWrap = "wrap";
    listContainer.style.justifyContent = "center";
    listContainer.style.paddingTop = "16px";
    listContainer.style.gap = "16px";

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

export const customLegendPlugin: Plugin<"bar" | "line"> = {
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList();

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const originalLabels =
      chart.options.plugins?.legend?.labels?.generateLabels?.(chart);

    originalLabels?.forEach((label) => {
      const li = document.createElement("li");

      li.style.alignItems = "center";
      li.style.display = "flex";
      li.style.flexDirection = "row";

      // Color box
      const boxSpan = document.createElement("span");

      boxSpan.style.display = "inline-block";
      boxSpan.style.marginRight = "10px";

      const dataset = label.datasetIndex
        ? chart.data.datasets[label.datasetIndex]
        : undefined;

      if (dataset?.type === "line") {
        boxSpan.style.background = dataset.backgroundColor as string;
        boxSpan.style.height = "2.5px";
        boxSpan.style.width = "20px";
      } else {
        boxSpan.style.background = label.fillStyle as string;
        boxSpan.style.height = "16px";
        boxSpan.style.width = "40px";
      }

      // Text
      const textContainer = document.createElement("p");

      const text = document.createTextNode(label.text);

      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
  id: "htmlLegend",
};
