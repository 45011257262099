import { AffiliateType } from "@common/types/affiliateType";
import { RHYTHM_MARKETING_URL } from "@ops/constants/url.constant";

const REFERRAL_CODE_QUERY_PARAM = "referralCode";
const UTM_COMMUNITY = "community";

export const signUpReferralUrl = ({
  firstName,
  lastName,
  referralCode,
}: AffiliateType) => {
  const queryParams = {
    utm_medium: UTM_COMMUNITY,
    utm_source: `${firstName} ${lastName}`,
  };

  const params = new URLSearchParams(queryParams);

  if (referralCode) {
    params.append(REFERRAL_CODE_QUERY_PARAM, referralCode);
    params.append("utm_campaign", referralCode);
  }

  return `${RHYTHM_MARKETING_URL}?${params.toString()}`;
};
