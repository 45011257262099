import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { CreateProductContract } from "@ops/components/CreateProductContract/CreateProductContract";
import React from "react";

export const NewProductContractCard = () => {
  return (
    <>
      <BoCardLabel>New Add On Contract</BoCardLabel>
      <BoCard as="article">
        <ButtonsGrid>
          <CreateProductContract />
        </ButtonsGrid>
      </BoCard>
    </>
  );
};
