import {
  darkPurple,
  grey,
  trueWhite,
} from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

export const OpsRadioInput = styled.input.attrs({ type: "radio" })`
  -webkit-appearance: none;
  appearance: none;
  border: 3px solid ${trueWhite};
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${darkPurple["500"]};
  height: 18px;
  width: 18px;

  & + label {
    color: ${grey["700"]};
    font-size: 14px;
  }

  &:checked {
    background: ${darkPurple["500"]};
  }
`;
