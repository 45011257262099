import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { ChartType, UsageType } from "@common/types/usageTypes";
import dayjs from "dayjs";

interface GroupByTierProps {
  defaultTierTitle: string;
  isGeneration: boolean;
  usageData: UsageType[];
}

export const groupByTier = (props: GroupByTierProps) => {
  const { usageData, defaultTierTitle, isGeneration } = props;
  const usageByTier: Record<string, UsageType[]> = {};

  for (const usage of usageData) {
    const tierName =
      (isGeneration ? usage.generationTierName : usage.consumptionTierName) ??
      defaultTierTitle;

    if (!usageByTier[tierName]) {
      usageByTier[tierName] = [];
    }

    usageByTier[tierName].push(usage);
  }

  return usageByTier;
};

interface AggregateUsageByDateProps {
  chartType: ChartType;
  usageData: UsageType[];
}

export const aggregateUsageByDate = (props: AggregateUsageByDateProps) => {
  const { chartType, usageData } = props;
  const usageByDate: Record<string, UsageType> = {};

  for (const usage of usageData) {
    const dateFormat = chartType === "daily" ? ISO8601_DATE_FORMAT : "YYYY-MM";
    const usageDate = dayjs(usage.datetime).format(dateFormat);

    const existingEntry = usageByDate[usageDate];

    // If an entry for the current date exists, increment the usage
    if (existingEntry) {
      let consumptionKwh = Number(existingEntry.consumptionKwh);

      consumptionKwh += Number(usage.consumptionKwh);
      existingEntry.consumptionKwh = consumptionKwh.toString();

      let consumptionCost = Number(existingEntry.consumptionCost);

      consumptionCost += Number(usage.consumptionCost);
      existingEntry.consumptionCost = consumptionCost.toString();

      let generationKwh = Number(existingEntry.generationKwh);

      generationKwh += Number(usage.generationKwh);
      existingEntry.generationKwh = generationKwh.toString();

      let generationEarned = Number(existingEntry.generationEarned);

      generationEarned += Number(usage.generationEarned);
      existingEntry.generationEarned = generationEarned.toString();
    } else {
      usageByDate[usageDate] = {
        ...usage,
        datetime: usageDate,
      };
    }
  }

  return Object.values(usageByDate);
};

interface GroupByTierAndDateProps {
  chartType: ChartType;
  defaultTierTitle: string;
  isGeneration: boolean;
  usageData: UsageType[];
}

export const groupByTierAndDate = (props: GroupByTierAndDateProps) => {
  const { usageData, chartType, defaultTierTitle, isGeneration } = props;
  const usageByTier = groupByTier({
    defaultTierTitle,
    isGeneration,
    usageData,
  });

  Object.keys(usageByTier).forEach((tierName) => {
    usageByTier[tierName] = aggregateUsageByDate({
      chartType,
      usageData: usageByTier[tierName],
    });
  });

  return usageByTier;
};
