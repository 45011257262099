import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import {
  customerCancel,
  customerErrored,
  customerFetch,
  customerReceived,
  customerRefetch,
  customerRefetchWithPremise,
  customerRequested,
} from "@ops/slices/customerSlice";
import { Task } from "redux-saga";
import {
  AllEffect,
  SagaReturnType,
  all,
  call,
  cancel,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

export function* fetchCustomerWorker(action: {
  payload: IdType;
  type: string;
}) {
  const { customer } = yield select(selectCustomerState);
  const customerId = action.payload;

  if (customer?.id !== customerId) {
    yield call(refetchCustomerWorker, action);
  }
}

export function* refetchCustomerWorker(action: {
  payload: IdType;
  type: string;
}) {
  yield put(customerRequested());

  try {
    const customerId = action.payload;

    const customer: SagaReturnType<typeof api.customers.retrieve> = yield call(
      api.customers.retrieve,
      customerId
    );

    yield put(customerReceived(customer));
  } catch (err: unknown) {
    yield put(customerErrored(err as RhApiError));
  }
}

export function* refetchCustomerWithPremiseWorker(action: {
  payload: { customerId: IdType; premiseId?: IdType };
  type: string;
}) {
  yield put(customerRequested());
  try {
    const { customerId, premiseId } = action.payload;

    const customer: SagaReturnType<typeof api.customers.retrieve> = yield call(
      api.customers.retrieve,
      customerId,
      {
        activePremiseId: premiseId,
      }
    );

    yield put(customerReceived(customer));
  } catch (err: unknown) {
    yield put(customerErrored(err as RhApiError));
  }
}

export function* customerWatcher(): Generator<
  unknown,
  void,
  AllEffect<unknown>
> {
  const allTask = yield all([
    takeLatest(customerFetch.type, fetchCustomerWorker) as unknown as Task,
    takeLatest(customerRefetch.type, refetchCustomerWorker) as unknown as Task,
    takeLatest(
      customerRefetchWithPremise.type,
      refetchCustomerWithPremiseWorker
    ) as unknown as Task,
  ]);

  const cancelTask = takeLatest(
    customerCancel.type,
    function* cancelAndRestart() {
      yield cancel(allTask as unknown as Task);
      yield customerWatcher();
    }
  );

  yield all([allTask, cancelTask]);
}
