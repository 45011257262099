import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { CommunicationPreferenceType } from "@common/types/premiseTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoToggle } from "@ops-design-system/components/BoToggleField/BoToggle";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { onOff } from "@ops-design-system/utils/booleanHelpers";
import {
  OnOffText,
  PaperlessFormFooter,
  PaperlessFormGrid,
  ToggleContainer,
} from "@ops/components/BillingPreferencesInfoCard/EditCommunicationPreferences/EditCommunicationPreferences.styled";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { usePremiseCommunicationPreferencesMutation } from "@ops/hooks/mutations/usePremiseCommunicationPreferencesUpdate.mutation";
import { useCustomer } from "@ops/hooks/useCustomer";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import { premiseStatusIndicatorsFetch } from "@ops/slices/premiseStatusIndicatorsSlice";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

interface EditCommunicationPreferencesProps {
  premise: Premise;
}

interface EditCommunicationPreferencesFormValues {
  eBill?: boolean;
  paperless: boolean;
}

interface EditCommunicationPreferencesModalProps {
  close: () => void;
  premise: Premise;
}

const EditCommunicationPreferencesModal = ({
  premise,
  close,
}: EditCommunicationPreferencesModalProps) => {
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const premiseCommunicationPreferencesUpdateMutation =
    usePremiseCommunicationPreferencesMutation();

  const { customer } = useCustomer(premise.customerId);
  const { communicationPreference, isOnLegacyEBill } = premise;

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { isDirty },
  } = useForm<EditCommunicationPreferencesFormValues>({
    defaultValues: {
      eBill: communicationPreference === CommunicationPreferenceType.EBILL,
      paperless:
        communicationPreference === CommunicationPreferenceType.PAPERLESS,
    },
  });

  const { onChange: onPaperlessChange, ...restPaperlessRegisterProps } =
    register("paperless");
  const { onChange: onEbillChange, ...restEBillRegisterProps } =
    register("eBill");

  const watchPaperlessValue = watch("paperless");
  const watchEBillValue = watch("eBill");

  const handleFormSubmit = handleSubmit(
    (formValues: EditCommunicationPreferencesFormValues) => {
      premiseCommunicationPreferencesUpdateMutation.mutate(
        { paperless: formValues.paperless, premiseId: premise.id },
        {
          onError: (error) => {
            flash.error(error.data.errors.map((e) => e?.detail).join(", "));
          },
          onSuccess: () => {
            dispatch(premiseRefetch(premise.id));
            dispatch(premiseStatusIndicatorsFetch(premise.id));
            close();
          },
        }
      );
    }
  );

  return (
    <BoModal open onClose={close}>
      <BoDialogHeader>Edit Communications</BoDialogHeader>
      <BoDialogBody>
        <form onSubmit={handleFormSubmit}>
          {isOnLegacyEBill ? (
            <PaperlessFormGrid>
              <Subtitle2 $fontWeight="SemiBold">eBill</Subtitle2>
              <ToggleContainer>
                <OnOffText>{onOff(watchEBillValue).toUpperCase()}</OnOffText>
                <BoToggle
                  inputProps={{
                    ...restEBillRegisterProps,
                    onChange: (event) => {
                      onEbillChange(event);

                      if (event.target.checked) {
                        setValue("paperless", false);
                      }
                    },
                  }}
                />
              </ToggleContainer>
              {watchEBillValue ? (
                <Subtitle2>Customer will not receive paper bills.</Subtitle2>
              ) : (
                <Subtitle2>Customer will receive paper bills.</Subtitle2>
              )}
            </PaperlessFormGrid>
          ) : null}
          <PaperlessFormGrid>
            <Subtitle2 $fontWeight="SemiBold">Paperless</Subtitle2>
            <ToggleContainer>
              <OnOffText>{onOff(watchPaperlessValue).toUpperCase()}</OnOffText>
              <BoToggle
                inputProps={{
                  ...restPaperlessRegisterProps,
                  onChange: (event) => {
                    onPaperlessChange(event);

                    if (isOnLegacyEBill && event.target.checked) {
                      setValue("eBill", false);
                    }
                  },
                }}
              />
            </ToggleContainer>
            {watchPaperlessValue ? (
              <Subtitle2>
                Customer will receive their Welcome Kit, Bills, Contract
                Documents, and Contract Expiration Notices to their email{" "}
                {customer?.email}.
              </Subtitle2>
            ) : (
              <Subtitle2>
                Customer will receive their Welcome Kit, Bills, Contract
                Documents, and Contract Expiration Notices in the mail
                <b>AND</b> to their email {customer?.email}.
              </Subtitle2>
            )}
          </PaperlessFormGrid>
          <PaperlessFormFooter
            confirmDisabled={
              !isDirty ||
              premiseCommunicationPreferencesUpdateMutation.isPending
            }
            confirmBtnText="Update"
            confirmBtnType="submit"
            cancelBtnText="Cancel"
            onCancel={close}
          />
        </form>
      </BoDialogBody>
    </BoModal>
  );
};

export const EditCommunicationPreferences = ({
  premise,
}: EditCommunicationPreferencesProps) => {
  const { open, close, isOpen } = useModalState();

  return (
    <>
      <EditLink onClick={open} aria-label="Update Paperless" />
      {isOpen ? (
        <EditCommunicationPreferencesModal premise={premise} close={close} />
      ) : null}
    </>
  );
};
