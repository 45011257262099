import { OktaGroups } from "@common/constants/okta.constant";
import { useAuthorizeComponent } from "@ops/hooks/useAuthorizeComponent";
import React from "react";

interface AccessControlProps {
  /** accepts a list containing at least the minimum tier group allowed to access the component being wrapped;
   * Ops groups is always appended to the allowed lsit as well. */
  allowedGroups?: OktaGroups[];
}

export const AccessControl = ({
  allowedGroups = [],
  children,
}: React.PropsWithChildren<AccessControlProps>) => {
  const component = useAuthorizeComponent({
    allowedGroups,
    component: children,
    unauthorizedComponent: null,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{component}</>;
};
