import { OktaGroups } from "@common/constants/okta.constant";
import { useOktaGroups } from "@common/hooks/useOktaGroups";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { Error404Page } from "@ops/pages/Error404Page/Error404Page";
import React from "react";
import { Outlet } from "react-router-dom";

interface RhOpsSecureOutletProps {
  allowedGroups: OktaGroups[];
}
export const SecureOutlet = ({ allowedGroups }: RhOpsSecureOutletProps) => {
  const { groups, hasResolvedGroups } = useOktaGroups();

  if (!hasResolvedGroups) {
    return <RhCircularProgress />;
  }

  if (allowedGroups.some((group) => groups.includes(group))) {
    return <Outlet />;
  }

  return <Error404Page />;
};
