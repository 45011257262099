import { AllEnrollmentTypes } from "@common/types/customerTypes";
import { convertSnakeCaseToTitleCase } from "@common/utils/stringFormatters";
import {
  BoRadioGroup,
  BoRadioInput,
} from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import React, { useState } from "react";
import { useField, useForm } from "react-final-form";

export type EnrollmentValueType = (typeof AllEnrollmentTypes)[number];
interface EnrollmentRadioGroupProps {
  initialValue?: EnrollmentValueType | null;
  onChange?: (value: EnrollmentValueType) => void;
}

const useCheckForRFF = () => {
  try {
    useForm();
    return true;
  } catch {
    return false;
  }
};

interface EnrollmentTypeOptionFieldProps {
  value: EnrollmentValueType;
}

const EnrollmentTypeOptionField = ({
  value,
}: EnrollmentTypeOptionFieldProps) => {
  const { input } = useField<EnrollmentValueType>("enrollmentType", {
    type: "radio",
    value,
  });

  return (
    <BoRadioInput
      id={value}
      {...input}
      label={convertSnakeCaseToTitleCase(value)}
    />
  );
};

interface EnrollmentTypeOptionInputProps
  extends EnrollmentTypeOptionFieldProps {
  checked: boolean;
  defaultChecked: boolean;
  onClick: (value: EnrollmentValueType) => void;
}

const EnrollmentTypeOptionInput = ({
  value,
  checked,
  defaultChecked,
  onClick,
}: EnrollmentTypeOptionInputProps) => {
  return (
    <BoRadioInput
      key={value}
      id={value}
      value={value}
      name="enrollmentType"
      onClick={() => {
        onClick(value);
      }}
      defaultChecked={defaultChecked}
      checked={checked}
      label={convertSnakeCaseToTitleCase(value)}
    />
  );
};

export const EnrollmentRadioGroup = ({
  initialValue,
  onChange = (_) => {},
}: EnrollmentRadioGroupProps) => {
  const insideRffContext = useCheckForRFF();
  const [checkedValue, setCheckedValue] = useState<EnrollmentValueType | null>(
    initialValue ?? null
  );
  const handleEnrollmentChange = (value: EnrollmentValueType) => {
    setCheckedValue(value);
    onChange(value);
  };

  return (
    <BoRadioGroup orientation="horizontal">
      {AllEnrollmentTypes.map((enrollmentType) =>
        insideRffContext ? (
          <EnrollmentTypeOptionField
            key={enrollmentType}
            value={enrollmentType}
          />
        ) : (
          <EnrollmentTypeOptionInput
            key={enrollmentType}
            value={enrollmentType}
            onClick={handleEnrollmentChange}
            defaultChecked={initialValue === enrollmentType}
            checked={checkedValue === enrollmentType}
          />
        )
      )}
    </BoRadioGroup>
  );
};
