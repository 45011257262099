import { green, infoBlue } from "@design-system/theme/palette.colors";
import { ScriptableContext, TooltipItem } from "chart.js";

export const getConsumptionBgColor = (
  context: ScriptableContext<"bar"> | TooltipItem<"bar">
) => {
  if (context.raw && typeof context.raw === "object") {
    const raw = context.raw as Record<string, unknown>;

    if (
      "consumptionTierColor" in raw &&
      typeof raw.consumptionTierColor === "string"
    ) {
      return raw.consumptionTierColor;
    }
  }

  return infoBlue[200];
};

export const getGenerationBgColor = (
  context: ScriptableContext<"bar"> | TooltipItem<"bar">
) => {
  if (context.raw && typeof context.raw === "object") {
    const raw = context.raw as Record<string, unknown>;

    if (
      "generationTierColor" in raw &&
      typeof raw.generationTierColor === "string"
    ) {
      return raw.generationTierColor;
    }
  }

  return green[300];
};

export function getGradient({
  context,
  color1,
  color2,
}: {
  color1: string;
  color2: string;
  context: ScriptableContext<"line">;
}) {
  const { ctx, chartArea } = context.chart;

  if (!chartArea) {
    return;
  }

  const { top, bottom } = chartArea;
  const gradient = ctx.createLinearGradient(0, top, 0, bottom);

  gradient.addColorStop(0, color1);
  gradient.addColorStop(1, color2);

  return gradient;
}
