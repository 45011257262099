import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import { formatCurrency } from "@common/utils/dataFormatters";
import { ReactComponent as LinkSolid } from "@design-system/icons/LinkSolid.svg";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { MakeAPayment } from "@ops/components/MakeAPayment/MakeAPayment";
import { ManagePaymentMethods } from "@ops/components/ManagePaymentMethods/ManagePaymentMethods";
import {
  BillInfoContainer,
  StyledPastDue,
} from "@ops/components/PaymentCard/PaymentCard.styled";
import { PremiseBillingBalanceBreakdown } from "@ops/components/PremiseBillingBalanceBreakdown/PremiseBillingBalanceBreakdown";
import { SendPaymentLink } from "@ops/components/SendPaymentLink/SendPaymentLink";
import { AccountSummary } from "@ops/models/AccountSummary.model";
import React from "react";

interface PaymentCardProps {
  accountSummary: AccountSummary;
  customer: Customer | null;
  premise: Premise;
}

export const PaymentCard = ({
  accountSummary,
  premise,
  customer,
}: PaymentCardProps) => {
  if (!accountSummary) {
    return null;
  }

  return (
    <BoCard data-testid="premise-billing-card-target">
      <BoGrid>
        <BoGridColumn>
          <BoFlexBox>
            <BoInfoContainer>
              <BoInfoLabel>Bill Due</BoInfoLabel>
              <BoInfoValue>
                {formatCurrency(accountSummary.totalBalance)}
              </BoInfoValue>
            </BoInfoContainer>
            <BillInfoContainer>
              <BoInfoLabel>Past Due</BoInfoLabel>
              <BoInfoValue>
                <StyledPastDue pastDueBalance={accountSummary.pastDueBalance}>
                  {formatCurrency(accountSummary.pastDueBalance)}
                </StyledPastDue>
              </BoInfoValue>
            </BillInfoContainer>
          </BoFlexBox>
        </BoGridColumn>

        <BoGridColumn>
          <MakeAPayment
            includeButtonIcon
            accountSummary={accountSummary}
            premiseId={premise.id}
          />
          {customer ? (
            <SendPaymentLink
              customerEmail={customer.email}
              premiseId={premise.id}
              icon={LinkSolid}
            />
          ) : null}
          <ManagePaymentMethods premise={premise} />
        </BoGridColumn>
      </BoGrid>
      <div>
        <PremiseBillingBalanceBreakdown premiseId={premise.id} />
      </div>
    </BoCard>
  );
};
