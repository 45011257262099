import { MobileRhHeader } from "@design-system/components/RhHeader/MobileRhHeader/MobileRhHeader";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  BoHeaderContainer,
  BoHeaderItem,
  BoHeaderListContainer,
  BoHeaderLogoContainer,
} from "@ops-design-system/components/BoHeader/BoHeader.styled";
import React from "react";

export interface BoHeaderProps {
  items: JSX.Element[];
  logo: JSX.Element;
}

export const name = () => {
  return <svg />;
};

export const BoHeader = ({ items, logo }: BoHeaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  const listItems = (
    <BoHeaderListContainer data-testid="BoHeader__list">
      {items.map((item, index) => {
        if (!item.key) {
          throw new Error(
            "Each list element passed in the props must have a key"
          );
        }

        return (
          <React.Fragment key={item.key}>
            <BoHeaderItem>{item}</BoHeaderItem>
          </React.Fragment>
        );
      })}
    </BoHeaderListContainer>
  );

  return (
    <BoHeaderContainer position="fixed" elevation={0} role="navigation">
      <BoHeaderLogoContainer data-testid="BoHeader__Logo">
        {logo}
      </BoHeaderLogoContainer>

      {isMobile ? <MobileRhHeader items={items} /> : <>{listItems}</>}
    </BoHeaderContainer>
  );
};
