import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  padding: ${rhOpsSpacingPx(2)} 0;
`;

export const StyledFilterAndSortRow = styled.div`
  align-items: flex-end;
  display: flex;
  gap: ${rhOpsSpacingPx(3)};
  margin-left: ${rhOpsSpacingPx(2)};

  > div {
    min-width: 200px;
  }

  & > :last-child {
    margin-left: auto;
  }
`;

export const StyledFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rhOpsSpacingPx(1)};
  margin-left: ${rhOpsSpacingPx(2)};
`;

export const StyledFilterLabel = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhOpsSpacingPx(1)};

  > svg {
    height: 12px;
    width: 12px;
  }
`;
