import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import {
  BoButton,
  BoButtonProps,
} from "@ops-design-system/components/BoButton/BoButton";
import { ReactComponent as ProhibitedSymbol } from "@ops-design-system/icons/ProhibitedSymbol.svg";
import { ServiceEndDateFormModal } from "@ops/components/CancellationActionsCard/ServiceEndDateFormModal/ServiceEndDateFormModal";
import React from "react";

interface CancelServiceProps {
  currentPremise: Premise;
  customer: Customer;
}

const CancelServiceButton = (
  props: Pick<BoButtonProps, "onClick" | "disabled">
) => {
  return (
    <BoButton
      icon={ProhibitedSymbol}
      variant="secondary"
      size="extraSmall"
      {...props}
    >
      Cancel Service
    </BoButton>
  );
};

export const CancelService = ({
  customer,
  currentPremise,
}: CancelServiceProps) => {
  const { open, close, isOpen } = useModalState();

  if (
    currentPremise.isMoveOutRequested ||
    currentPremise.isMoveOutDateChangeRequested ||
    currentPremise.isMoveOutAccepted
  ) {
    return <CancelServiceButton disabled />;
  }

  return (
    <>
      <CancelServiceButton onClick={open} />
      <ServiceEndDateFormModal
        header="Cancel Service"
        open={isOpen}
        onClose={close}
        premise={currentPremise}
        customer={customer}
      />
    </>
  );
};
