import { Customer } from "@common/models/Customer.model";
import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { OpsStoreType } from "@ops-utils/types/opsSlicesTypes";

export const selectCustomerState = ({
  customer,
}: Pick<OpsStoreType, "customer">) => {
  const { data, error, requestStatus } = customer;

  return {
    customer: data ? new Customer(data) : null,
    error,
    status: new RequestMonitor(requestStatus),
  };
};
