import { legacyCustomerApi } from "@ops-data/api/legacyCustomerApi";
import { legacyProspectApi } from "@ops-data/api/legacyProspectApi";
import {
  SupportLogTarget,
  TargetType,
} from "@ops/components/SupportLogs/SupportLogs.types";
import { ContactLogKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseContactLogEntriesQueryProps {
  target: SupportLogTarget;
}

export const useContactLogEntriesQuery = (
  props: UseContactLogEntriesQueryProps
) => {
  const { target } = props;

  const apiFn = {
    [TargetType.PROSPECT]: legacyProspectApi.contactLogs.list,
    [TargetType.CUSTOMER]: legacyCustomerApi.contactLogs.list,
  }[target.type];

  return useQuery({
    queryFn: () => apiFn(target.id),
    queryKey: ContactLogKeys.ENTRIES(target.type, target.id),
  });
};
