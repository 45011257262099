import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledCard = styled(BoCard)`
  flex: 1;
  margin-right: ${rhOpsSpacingPx(1)};

  &:last-child {
    margin-left: ${rhOpsSpacingPx(1)};
    margin-right: 0;
  }
`;

export const StyledLabelText = styled(Body1)`
  color: ${grey["900"]};
`;

export const WarningMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const WarningMessageText = styled(Body1)`
  color: ${red.main};
`;

export const AutopayPaperlessContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const AutopayPaperlessCheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;
