import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { OfferSnapshotSearchOptions } from "@ops-utils/types/offerSnapshotTypes";
import { CardsContainer } from "@ops/components/ContractSwapsAndRenewals/ContractRenewal/ContractRenewal.styled";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import { useOfferSnapshotsQuery } from "@ops/hooks/queries/useOfferSnapshots.query";
import React, { useEffect } from "react";

interface OfferSnapshotCardGroupProps {
  offerSnapshotListSearchOptions: OfferSnapshotSearchOptions;
  onOfferSnapshotSelect: (offerSnapshot: OfferSnapshot) => void;
  selectedOfferSnapshotId?: IdType;
}
export const OfferSnapshotCardGroup = (props: OfferSnapshotCardGroupProps) => {
  const {
    offerSnapshotListSearchOptions,
    onOfferSnapshotSelect,
    selectedOfferSnapshotId,
  } = props;

  const flash = useRhFlash();

  const offerSnapshotsQuery = useOfferSnapshotsQuery({
    searchOptions: offerSnapshotListSearchOptions,
  });

  useEffect(() => {
    if (offerSnapshotsQuery.error) {
      flash.error(
        "An error occurred. Unable to get offers for this service address."
      );
    }
  }, [offerSnapshotsQuery.error]);

  if (offerSnapshotsQuery.data?.count === 0) {
    return (
      <CardsContainer>
        <Body2>No offers available</Body2>
      </CardsContainer>
    );
  }

  return (
    <CardsContainer>
      {offerSnapshotsQuery.data?.results.map((offerSnapshot) => {
        return (
          <OfferCard
            key={offerSnapshot.id}
            offerSnapshot={offerSnapshot}
            onSelect={() => onOfferSnapshotSelect(offerSnapshot)}
            active={offerSnapshot.id === selectedOfferSnapshotId}
            name="offerChooser"
          />
        );
      })}
    </CardsContainer>
  );
};
