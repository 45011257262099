import { UUIDType } from "@common/types/apiTypes";
import { ProductPriceTermPeriod } from "@ops-utils/types/productTypes";

export enum ProductContractStatusFilter {
  ACTIVE = "active",
  EXPIRED = "expired",
  UPCOMING = "upcoming",
}

export enum ProductContractFrequency {
  ANNUALLY = "ANNUALLY",
  MONTHLY = "MONTHLY",
}

export type ProductContractIdType = UUIDType;

export interface ProductContractType {
  agreementEndDate: string;
  endDate: string;
  frequency: ProductContractFrequency;
  id: ProductContractIdType;
  number: string;
  productName: string;
  rate: string;
  startDate: string;
  termLength: number;
  termPeriod: ProductPriceTermPeriod;
  tierName: string;

  tosUrl: string;
}

export interface ProductContractCancelRequestType {
  contractId: UUIDType;
  endDate: string;
}

export interface ProductContractClaimType {
  id: UUIDType;
  policyNumber: string;
  tierName: string;
  uploadDate: string;
}
