import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as ChevronDown } from "@ops-design-system/icons/ChevronDown.svg";
import { borders } from "@ops-design-system/styles/borders";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

const StyledSummaryWrapper = styled.summary`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: ${rhOpsSpacingPx(2)} 0;
  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
  > *:first-child {
    flex: 1;
  }
`;

export const BoAccordionDetails = styled.div`
  color: ${grey["900"]};
`;

interface BoAccordionSummaryProps {
  className?: string;
  label?: string;
  summary: string;
}

export const BoAccordionSummary = ({
  label,
  summary,
  className,
}: BoAccordionSummaryProps) => {
  return (
    <StyledSummaryWrapper
      role="button"
      aria-label={label ?? "Expand"}
      className={className}
    >
      <Body2>{summary}</Body2>
      <ChevronDown />
    </StyledSummaryWrapper>
  );
};

export const BoAccordion = styled.details`
  @keyframes sweep {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  border-bottom: ${borders["100"]};
  color: ${darkPurple["500"]};
  margin: 0;
  position: relative;

  & summary svg {
    bottom: 0;
    position: absolute;
    right: ${rhOpsSpacingPx(2)};
    top: 17px;
    transform: translateY(-50%);
    transition: transform 300ms ease-in-out;
  }

  &[open] summary ~ * {
    animation: sweep 300ms ease-in-out;
  }

  &[open] summary svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;
