import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface DeferredPaymentPlanContractGenerationData
  extends EventLogBaseData {
  agreementDate: string;
  contractId: string;
  depositAmount: string;
  numberOfInstallments: string;
  pastDueBalance: string;
}

export class DeferredPaymentPlanContractGeneration extends EventLog {
  public readonly agreementDate: string;
  public readonly depositAmount: string;
  public readonly pastDueBalance: string;
  public readonly numberOfInstallments: string;
  public readonly contractId: string;

  constructor({
    agreementDate,
    depositAmount,
    pastDueBalance,
    numberOfInstallments,
    contractId,
    ...baseEventData
  }: DeferredPaymentPlanContractGenerationData) {
    super({
      ...baseEventData,
      eventText: "Deferred Payment Plan (DPP) Contract Generated",
    });
    this.agreementDate = agreementDate;
    this.depositAmount = depositAmount;
    this.pastDueBalance = pastDueBalance;
    this.numberOfInstallments = numberOfInstallments;
    this.contractId = contractId;
  }

  override get body() {
    return `${this.name} - Contract ID: ${this.contractId}`;
  }
}
