import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { AddressForm } from "@common/models/AddressForm.model";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ConfirmMeterField } from "@ops/components/ConfirmMeterField/ConfirmMeterField";
import { addressIsEqual } from "@ops/components/MeterSearchForms/addressFormHelpers";
import {
  ValidatedAddressType,
  useMeterSearchForms,
} from "@ops/components/MeterSearchForms/useMeterSearchForms";
import { initialTosWizardState } from "@ops/components/TransferServiceWizard/TransferServiceWizard.constants";
import { WizardComponentName } from "@ops/components/TransferServiceWizard/TransferServiceWizard.types";
import { TransferServiceWizardForm } from "@ops/components/TransferServiceWizard/TransferServiceWizardForm";
import { TosWizardStateType } from "@ops/components/TransferServiceWizard/TransferServiceWizardProvider";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import React, { useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

interface NewPremiseFormValues {
  confirmValidatedAddress: boolean;
  mailingAddressSameAsServiceAddress: boolean;
}

const ValidationFormsContainer = styled.div`
  width: 480px;
  & > :last-child {
    padding-bottom: 0;
  }
`;

const NewPremiseFormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const AddressEsiIdDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

const formValidators = generateValidationErrorCollector<NewPremiseFormValues>({
  confirmValidatedAddress: [opsAndPricingIsRequired],
});

export const NewPremiseForm = () => {
  const {
    setActiveComponentName,
    updateTosWizardState,
    tosWizardState: { validatedAddress, mailingAddress, esiId },
    premise,
  } = useTransferServiceWizardContext();
  const [currentServiceAddress, setCurrentServiceAddress] =
    useState<AddressForm | null>(
      validatedAddress?.serviceAddress
        ? new AddressForm(validatedAddress.serviceAddress)
        : null
    );
  const [SearchByAddress, SearchByEsiId] = useMeterSearchForms();
  const flash = useRhFlash();

  const mailingAddressSameAsServiceAddress =
    validatedAddress?.serviceAddress &&
    addressIsEqual(
      new AddressForm(mailingAddress),
      new AddressForm(validatedAddress.serviceAddress)
    );

  const onSubmit = ({
    confirmValidatedAddress,
    mailingAddressSameAsServiceAddress: mailingSameAsService,
  }: NewPremiseFormValues) => {
    if (!confirmValidatedAddress || !validatedAddress) {
      return;
    }
    const { serviceAddress } = validatedAddress;
    let nextContentName: WizardComponentName;

    const updatedWizardState: Partial<TosWizardStateType> = {
      ...validatedAddress,
      ...(mailingSameAsService
        ? { mailingAddress: { ...serviceAddress } }
        : null),
    };

    if (!mailingSameAsService) {
      nextContentName = WizardComponentName.mailingAddressForm;
    } else if (
      validatedAddress.dunsNumber !== premise.currentOrder?.dunsNumber
    ) {
      nextContentName = WizardComponentName.offerForm;
      updatedWizardState.offerSnapshotId = "";
    } else {
      nextContentName = WizardComponentName.movingForm;
      updatedWizardState.offerSnapshotId =
        premise.currentOrder?.offersnapshotId ?? "";
    }

    setActiveComponentName(nextContentName);
    updateTosWizardState({
      ...updatedWizardState,
    });
  };

  const handleAddressEsiIdSuccess = (
    response?: ValidatedAddressType,
    error?: string
  ) => {
    setCurrentServiceAddress(
      response ? new AddressForm(response.serviceAddress) : null
    );

    if (error || !response) {
      flash.error(error);
      return;
    }

    updateTosWizardState({
      ...initialTosWizardState,
      validatedAddress: error ? undefined : response,
    });
  };

  return (
    <NewPremiseFormContainer>
      <ValidationFormsContainer>
        <FormRow>
          <Subtitle2 $fontWeight="Bold">New meter information</Subtitle2>
        </FormRow>

        <FormRow>
          <SearchByAddress
            onSubmit={handleAddressEsiIdSuccess}
            currentServiceAddress={currentServiceAddress}
          />
        </FormRow>
        <AddressEsiIdDivider />
        <FormRow>
          <SearchByEsiId
            onSubmit={handleAddressEsiIdSuccess}
            meter={premise.meter}
          />
        </FormRow>
      </ValidationFormsContainer>
      {Boolean(validatedAddress) && (
        <Form<NewPremiseFormValues>
          initialValues={{
            confirmValidatedAddress: Boolean(esiId),
            mailingAddressSameAsServiceAddress,
          }}
          validate={formValidators}
          validateOnChange
          onSubmit={onSubmit}
          render={({ values: { confirmValidatedAddress } }) => {
            return (
              <TransferServiceWizardForm
                id={WizardComponentName.newPremiseForm}
              >
                <ConfirmMeterField
                  validatedAddress={validatedAddress}
                  confirmValidatedAddress={confirmValidatedAddress}
                />
              </TransferServiceWizardForm>
            );
          }}
        />
      )}
    </NewPremiseFormContainer>
  );
};
