export enum AmountType {
  Custom = "custom",
  PastDue = "pastDue",
  Total = "total",
}

export type MakeAPaymentFormValues = {
  amount: string;
  amountType: AmountType;
  includePaymentTransactionFee?: boolean;
  paymentMethodId: string;
};

export type PaymentSuccessPayloadType = {
  paymentNumber: string;
  submittedAmount: number;
};

export type MakeAPaymentStepType =
  | "makeAPaymentForm"
  | "paymentSuccess"
  | "addBankAccount"
  | "addACreditCard"
  | "noPaymentOptions"
  | "loading";
