import { grey } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  width: 350px;

  & > h1 {
    color: ${grey["300"]};
  }

  & > h2 {
    font-weight: bold;
  }

  & > span:last-child {
    margin-top: 16px;
  }
`;
