import { ediApi } from "@common/api/ediApi";
import { UtilityType } from "@common/types/ediTypes";
import { CreateFampMessageResponseType } from "@common/types/fampTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { FampCreateMessageForm } from "@ops/components/FampCreateMessageForm/FampCreateMessageForm";
import { FampSubmitDetails } from "@ops/components/FampSubmitDetails/FampSubmitDetails";
import React, { useEffect, useState } from "react";

export const FinanceFampPage = () => {
  const [fampMessage, setFampMessage] =
    useState<CreateFampMessageResponseType | null>(null);

  const [isFetchingUtilities, setIsFetchingUtilities] =
    useState<boolean>(false);
  const [utilities, setUtilities] = useState<UtilityType[] | null>(null);
  const flash = useRhFlash();

  useEffect(() => {
    async function fetchUtilities() {
      setIsFetchingUtilities(true);

      const [error, response] = await handleAjaxCall(ediApi.utilities.index());

      if (error || !response) {
        flash.error("Error getting utilities. Please try refreshing this page");
      }
      setUtilities(response);

      setIsFetchingUtilities(false);
    }

    fetchUtilities();
  }, []);

  if (isFetchingUtilities) {
    return <RhCircularProgress />;
  }

  if (utilities === null) {
    return null;
  }

  return fampMessage ? (
    <FampSubmitDetails fampMessage={fampMessage} utilities={utilities} />
  ) : (
    <FampCreateMessageForm
      onCreateFampMessageSuccess={setFampMessage}
      utilities={utilities}
    />
  );
};
