import { ValidatedAddressType } from "@ops/components/MeterSearchForms/useMeterSearchForms";
import { ConfirmMeter } from "@ops/components/ProspectEditor/ServiceAddressTab/ConfirmMeter";
import { ServiceAddressForm } from "@ops/components/ProspectEditor/ServiceAddressTab/ServiceAddressForm";
import { StyledTab } from "@ops/components/ProspectEditor/shared/ProspectEditor.styled";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React, { useState } from "react";

export const ServiceAddressTab = () => {
  const {
    prospect: { meterId, addressLine1, unitNumber, city, state, zipCode },
  } = useEnrollmentFlowContext();
  const [validatedAddress, setValidatedAddress] =
    useState<ValidatedAddressType>({
      esiId: meterId ?? "",
      serviceAddress: {
        addressLine1,
        city,
        state,
        unitNumber,
        zipCode,
      },
    });

  return (
    <StyledTab>
      <ServiceAddressForm onValidatedAddressRetrieved={setValidatedAddress} />
      {validatedAddress.esiId && (
        <ConfirmMeter validatedAddress={validatedAddress} />
      )}
    </StyledTab>
  );
};
