import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import {
  StyledBillBreakdownDetails,
  StyledBillBreakdownDetailsRow,
} from "@ops/components/BillBreakdownDetails/BillBreakdownDetails.styled";
import { useInvoiceQuery } from "@ops/hooks/queries/useInvoice.query";
import React from "react";

interface BillBreakdownDetailsProps {
  invoiceId: string;
}

export const BillBreakdownDetails = (props: BillBreakdownDetailsProps) => {
  const { invoiceId } = props;

  const { isError, isPending, data } = useInvoiceQuery({ invoiceId });

  if (isPending) {
    return <BoCircularProgress />;
  }

  if (isError) {
    return <Body2>Failed to get invoice data.</Body2>;
  }

  const {
    amount,
    invoiceDate,
    invoiceNumber,
    serviceEndDate,
    serviceStartDate,
    dueDate,
  } = data;

  return (
    <StyledBillBreakdownDetails>
      <StyledBillBreakdownDetailsRow>
        <Body3 $fontWeight="Bold">Amount Due</Body3>
        <Body2>{formatCurrency(amount)}</Body2>
      </StyledBillBreakdownDetailsRow>
      <StyledBillBreakdownDetailsRow>
        <Body3 $fontWeight="Bold">Invoice Date</Body3>
        <Body2>{formatMonthDayYear(invoiceDate)}</Body2>
      </StyledBillBreakdownDetailsRow>
      <StyledBillBreakdownDetailsRow>
        <Body3 $fontWeight="Bold">Invoice Number</Body3>
        <Body2>{invoiceNumber}</Body2>
      </StyledBillBreakdownDetailsRow>
      <StyledBillBreakdownDetailsRow>
        <Body3 $fontWeight="Bold">Service Period</Body3>
        <Body2>{`${formatMonthDayYear(serviceStartDate)} - ${formatMonthDayYear(serviceEndDate)}`}</Body2>
      </StyledBillBreakdownDetailsRow>
      <StyledBillBreakdownDetailsRow />
      <StyledBillBreakdownDetailsRow>
        <Body3 $fontWeight="Bold">Due Date</Body3>
        <Body2>{formatMonthDayYear(dueDate)}</Body2>
      </StyledBillBreakdownDetailsRow>
    </StyledBillBreakdownDetails>
  );
};
