import { premiseApi } from "@common/api/premiseApi";
import { EnrollmentStatus } from "@common/enums/customer.enum";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { usePremise } from "@ops/hooks/usePremise";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import { editDialogContentsCss } from "@ops/styles/mixins";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

interface EditEsiIdProps {
  premiseId: IdType;
}

interface EditEsiIdFormValues {
  esiId: string;
}

const editEsiIdFormValidator =
  generateValidationErrorCollector<EditEsiIdFormValues>({
    esiId: [opsAndPricingIsRequired],
  });

const DetailsWrapper = styled.div`
  ${editDialogContentsCss}
`;

export const EditEsiId = ({ premiseId }: EditEsiIdProps) => {
  const { premise } = usePremise(premiseId);
  const { open, close, isOpen } = useModalState();
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();

  if (!premise) {
    return null;
  }

  const allowEdit =
    premise.enrollmentStatus === EnrollmentStatus.CANCELED ||
    premise.enrollmentStatus === EnrollmentStatus.PRE_ENROLL;

  const handleSubmit = (formValues: EditEsiIdFormValues) => {
    setPending();
    premiseApi
      .setEsiId(premise.id, formValues.esiId)
      .then(() => {
        setSuccess();
        dispatch(premiseRefetch(premise.id));
      })
      .catch(() => {
        setFailure();
        flash.error(
          "Unable to update ESI ID for this premise. Please check that it is a valid ESIID"
        );
      })
      .finally(() => {
        close();
      });
  };

  return (
    <>
      <EditLink onClick={open} disabled={!allowEdit} aria-label="Edit ESI Id" />
      {isOpen ? (
        <BoModal open={isOpen} onClose={close}>
          <Form<EditEsiIdFormValues>
            initialValues={{ esiId: premise.meter?.esiId || "" }}
            onSubmit={handleSubmit}
            validate={editEsiIdFormValidator}
            render={({ handleSubmit: formHandleSubmit, valid, dirty }) => (
              <form noValidate onSubmit={formHandleSubmit}>
                <BoDialogHeader>Edit ESIID</BoDialogHeader>
                <BoDialogBody>
                  <DetailsWrapper>
                    <Subtitle2 as="div">
                      Customer&apos;s current ESIID:
                    </Subtitle2>
                    <Subtitle2 as="div" $fontWeight="Bold">
                      {premise.meter?.esiId}
                    </Subtitle2>
                  </DetailsWrapper>
                  <BoTextField name="esiId" placeholder="" type="text">
                    ESIID
                  </BoTextField>
                </BoDialogBody>
                <BoDialogButtonFooter
                  confirmDisabled={!valid || !dirty}
                  confirmLoading={requestMonitor.isPending}
                  confirmBtnText="Update"
                  confirmBtnType="submit"
                  cancelBtnText="Cancel"
                  onCancel={close}
                />
              </form>
            )}
          />
        </BoModal>
      ) : null}
    </>
  );
};
