import { useModalState } from "@common/hooks/useModalState";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { StyledAdminPage } from "@ops/components/AdminDashboard/shared/StyledAdminPage";
import { BatchContactLogUploadTable } from "@ops/components/BatchContactLogUploadTable/BatchContactLogUploadTable";
import { BatchNoteUploader } from "@ops/components/BatchNoteUploader/BatchNoteUploader";
import { useBatchContactLogUploadsInState } from "@ops/hooks/useBatchContactLogUploadsInState";
import React, { useEffect } from "react";

export const ManageContactLogsPage = () => {
  const { open, close, isOpen } = useModalState();
  const { requestMonitor, data, fetch } = useBatchContactLogUploadsInState();
  const flash = useRhFlash();

  useEffect(fetch, [fetch]);

  if (requestMonitor.didFail) {
    flash.error(
      "An error occurred while fetching contact log uploads. Please try again."
    );
  }

  return (
    <StyledAdminPage
      pageName="Manage Contact Logs"
      nameInLineButton="Upload Batch Notes"
      onButtonClick={open}
    >
      {requestMonitor.isPending && <BoCircularProgress />}
      <BatchContactLogUploadTable batchContactLogUploads={data} />

      <BatchNoteUploader open={isOpen} onClose={close} onSubmit={fetch} />
    </StyledAdminPage>
  );
};
