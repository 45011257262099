import { IdType } from "@common/types/apiTypes";
import { PaymentHistoryType } from "@common/types/premiseTypes";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { useDeferPaymentFormState } from "@ops/components/DeferPaymentForm/useDeferPaymentFormState";
import { DeferPaymentComponentType } from "@ops/components/DeferPaymentWizard/DeferPaymentModalStateProvider";
import { useDeferPaymentModalState } from "@ops/components/DeferPaymentWizard/useDeferPaymentModalStateProvider";
import { PaymentMethodSelector } from "@ops/components/PaymentMethodSelector/PaymentMethodSelector";
import { PaymentTable } from "@ops/components/PaymentTable/PaymentTable";
import { usePaymentMethodOptions } from "@ops/hooks/usePaymentMethodOptions";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const PaymentTableWrapper = styled.div`
  flex-basis: 50%;
  height: 25vh;
  overflow-y: auto;
`;

export const PaymentSelector = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);
  const { paymentMethods } = usePaymentMethods(premiseId);

  const {
    updateFormState,
    currentPaymentMethod,
    hasMadeDownPayment,
    selectedPaymentList,
  } = useDeferPaymentFormState();

  const { updateModalState } = useDeferPaymentModalState();

  const handlePaymentSelection = (toggledPayment: PaymentHistoryType) => {
    const index = selectedPaymentList.findIndex((payment: PaymentHistoryType) =>
      payment ? payment.number === toggledPayment.number : null
    );

    if (toggledPayment.selected && index === -1) {
      selectedPaymentList.push(toggledPayment);
    }

    if (!toggledPayment.selected && index >= 0) {
      delete selectedPaymentList[index];
    }

    updateFormState({ selectedPaymentList });
  };

  const handleAddPayment = () => {
    updateModalState({
      activeComponent: DeferPaymentComponentType.addPaymentMethod,
    });
  };

  const handlePaymentMethodSelection = (
    paymentMethodOption: BoSelectOptionType | null
  ) => {
    if (paymentMethodOption) {
      updateFormState({
        currentPaymentMethod: paymentMethodOption.value,
      });
    }
  };

  const { defaultPaymentMethod, paymentMethodOptions } =
    usePaymentMethodOptions(paymentMethods);

  if (!premise) {
    return null;
  }
  if (hasMadeDownPayment === undefined) {
    return null;
  }
  if (hasMadeDownPayment) {
    return (
      <PaymentTableWrapper>
        <PaymentTable
          premise={premise}
          header="Payment History"
          selectable
          onSelect={handlePaymentSelection}
        />
      </PaymentTableWrapper>
    );
  } else {
    return (
      <PaymentMethodSelector
        current={currentPaymentMethod ?? defaultPaymentMethod?.id ?? null}
        options={paymentMethodOptions}
        onChange={handlePaymentMethodSelection}
        noCreditOrDebitCard={premise.isNoCreditOrDebitCard}
        handleAddPayment={handleAddPayment}
      />
    );
  }
};
