import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { SWITCH } from "@common/types/customerTypes";
import { isOps } from "@common/utils/authenticationHelpers";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  Body2,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow, FormRow } from "@ops-design-system/styles/common.styles";
import { AddPremiseWizardForm } from "@ops/components/AddPremiseWizard/AddPremiseWizardForm";
import { AddPremiseWizardComponentName } from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";
import {
  AutoPayInfoCard,
  BillingPreferencesContainer,
  CheckboxContainer,
  CheckboxLabel,
  PaperlessCheckboxContainer,
  PaperlessDisclaimer,
  PaperlessWarningCard,
  StyledDivider,
} from "@ops/components/AddPremiseWizard/StartDateForm/StartDateForm.styled";
import {
  EnrollmentRadioGroup,
  EnrollmentValueType,
} from "@ops/components/EnrollmentRadioGroup/EnrollmentRadioGroup";
import { ServiceDatePicker } from "@ops/components/ServiceDatePicker/ServiceDatePicker";
import { useAddPremiseWizardContext } from "@ops/hooks/contexts/useAddPremiseWizardContext";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import React, { useState } from "react";

export interface StartDateFormValues {
  enrollmentType: EnrollmentValueType | null;
  paperless: boolean;
  priorityEnrollment: boolean;
  startDate: string | null;
}

export const StartDateForm = () => {
  const { data: groups } = useAuthenticatedUserGroups();
  const {
    wizardState: {
      startDate,
      enrollmentType,
      priorityEnrollment,
      paperless,
      offerSnapshotId,
    },
    updateWizardState,
    setActiveComponentName,
  } = useAddPremiseWizardContext();
  const [formValues, setFormValues] = useState<StartDateFormValues>({
    enrollmentType,
    paperless,
    priorityEnrollment,
    startDate,
  });

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId,
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <div>
        <BoCircularProgress />
      </div>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <div>
        <Body2>Failed to fetch offer details</Body2>
      </div>
    );
  }

  const hasValidationErrors =
    !formValues.startDate || !formValues.enrollmentType;

  const updateFormValues = (values: Partial<StartDateFormValues>) => {
    setFormValues({ ...formValues, ...values });
  };
  const onSubmit = () => {
    if (hasValidationErrors) {
      return;
    }

    updateWizardState(formValues);
    setActiveComponentName(AddPremiseWizardComponentName.confirmDetails);
  };

  const offerSnapshot = offerSnapshotQuery.data;

  const showPaperlessWarning =
    offerSnapshot.isAutoPayPaperlessDiscountOffer && !formValues.paperless;

  const showAutoPayMessage = offerSnapshot.isAutoPayPaperlessDiscountOffer;

  return (
    <AddPremiseWizardForm
      hasValidationErrors={hasValidationErrors}
      onSubmit={onSubmit}
    >
      <FormFlexRow>
        <ServiceDatePicker
          header="Start Date"
          name="startDate"
          value={formValues.startDate}
          onChange={(value) => {
            updateFormValues({ startDate: value });
          }}
          allowAnyDaysInTheFuture={isOps(groups)}
          label=""
        />
      </FormFlexRow>
      <FormFlexRow>
        <EnrollmentRadioGroup
          initialValue={formValues.enrollmentType}
          onChange={(value) => {
            const priorityEnrollmentValue =
              value === SWITCH ? false : formValues.priorityEnrollment;

            updateFormValues({
              enrollmentType: value,
              priorityEnrollment: priorityEnrollmentValue,
            });
          }}
        />
      </FormFlexRow>
      <FormFlexRow>
        <CheckboxContainer>
          <BoCheckboxInput
            name="priorityEnrollment"
            id="priorityEnrollment"
            onChange={() => {
              updateFormValues({
                priorityEnrollment: !formValues.priorityEnrollment,
              });
            }}
            disabled={formValues.enrollmentType === SWITCH}
            checked={formValues.priorityEnrollment}
          />
        </CheckboxContainer>
        <CheckboxLabel $htmlFor="priorityEnrollment">
          Priority Enrollment
        </CheckboxLabel>
      </FormFlexRow>
      <StyledDivider $light />
      <FormRow>
        <Subtitle2 $fontWeight="Bold" as="div">
          Billing Preferences
        </Subtitle2>
      </FormRow>

      <BillingPreferencesContainer>
        <div>
          <BoCard>
            <PaperlessCheckboxContainer>
              <CheckboxContainer>
                <BoCheckboxInput
                  name="paperless"
                  id="paperless"
                  onChange={() => {
                    updateFormValues({ paperless: !formValues.paperless });
                  }}
                  checked={formValues.paperless}
                />
              </CheckboxContainer>

              <CheckboxLabel $htmlFor="paperless">Paperless</CheckboxLabel>
            </PaperlessCheckboxContainer>
            <PaperlessDisclaimer>
              The customer agrees to receive their welcome packet, bills,
              contract documents, and contract related notices (including
              contract expiration notices and disconnect notices) via email.
              They understand they won&apos;t receive their bills via regular
              mail.
            </PaperlessDisclaimer>
          </BoCard>

          {showPaperlessWarning ? (
            <PaperlessWarningCard $variant={BoCardVariants.Warning}>
              The customer must be enrolled in Auto Pay and Paperless to receive
              the Auto Pay and Paperless Credit.
            </PaperlessWarningCard>
          ) : null}
        </div>

        <div>
          {showAutoPayMessage ? (
            <AutoPayInfoCard $variant={BoCardVariants.Warning}>
              Auto Pay must be turned on after the completion of signing up for
              the customer to receive the Auto Pay and Paperless Credit.
            </AutoPayInfoCard>
          ) : null}
        </div>
      </BillingPreferencesContainer>
    </AddPremiseWizardForm>
  );
};
