import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import {
  SuccessCard,
  SuccessMessage,
} from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoStyledCards";
import { AddProspectPaymentMethod } from "@ops/components/ProspectEditor/BillingInfoTab/ProspectPaymentMethod/AddProspectPaymentMethod";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { usePaymentMethodInState } from "@ops/hooks/usePaymentMethodInState";
import React, { useEffect } from "react";
import styled from "styled-components";

interface ShowProspectPaymentMethodProps {
  billingPaymentMethodId: string;
}

const StyledCard = styled(BoCard)`
  margin-top: ${rhOpsSpacingPx(1)};
`;

const PaymentMethodRowCard = styled(BoCard)`
  margin-bottom: ${rhOpsSpacingPx(1)};
  width: 50%;
`;

const Body1Expiry = styled(Body1)`
  color: ${grey["600"]};
  padding-left: ${rhOpsSpacingPx(1)};
`;

const MessageWrapper = styled.div`
  padding-bottom: ${rhOpsSpacingPx(1)};
`;

export const ShowProspectPaymentMethod = ({
  billingPaymentMethodId,
}: ShowProspectPaymentMethodProps) => {
  const {
    paymentMethod: paymentMethodData,
    requestMonitor,
    fetchPaymentMethod,
    deletePaymentMethod,
  } = usePaymentMethodInState(billingPaymentMethodId);

  const { updateProspectState } = useEnrollmentFlowContext();
  const flash = useRhFlash();

  useEffect(() => {
    fetchPaymentMethod();
  }, [fetchPaymentMethod]);

  if (requestMonitor.didFail) {
    flash.error("The payment method was not found for this prospect");
    return (
      <StyledCard>
        <AddProspectPaymentMethod />
      </StyledCard>
    );
  }

  if (requestMonitor.isPending) {
    return <BoCircularProgress />;
  }

  if (!paymentMethodData) {
    return null;
  }

  const paymentMethod = PaymentMethod.fromPaymentMethodRetrieve({
    isDefault: true,
    paymentMethodData,
    paymentMethodId: billingPaymentMethodId,
  });

  const handleDelete = () => {
    deletePaymentMethod()
      .then(() => updateProspectState({ billingPaymentMethodId: null }))
      .catch((error) => {
        flash.error(error.data.errors[0].detail);
      });
  };

  return (
    <SuccessCard>
      <MessageWrapper>
        <SuccessMessage>Customer has entered payment method.</SuccessMessage>
      </MessageWrapper>

      {paymentMethod ? (
        <PaymentMethodRowCard>
          <BoFlexBox $justifyContent="space-between">
            <div>
              <Body1 $fontWeight="Bold">{paymentMethod.companyName}</Body1>
              <Body1Expiry>Exp. {paymentMethod.expiration}</Body1Expiry>
              <div>
                <Body2>Default payment method</Body2>
              </div>
            </div>
            <div>
              <Body1>{paymentMethod.trimmedMaskedNumber}</Body1>
              <div>
                <Body2>
                  <BoLink color={red["500"]} onClick={handleDelete}>
                    Delete
                  </BoLink>
                </Body2>
              </div>
            </div>
          </BoFlexBox>
        </PaymentMethodRowCard>
      ) : null}
    </SuccessCard>
  );
};
