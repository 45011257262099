import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { UNKNOWN_ACCOUNT_NUMBER } from "@common/constants/billing.constants";
import { IdType } from "@common/types/apiTypes";
import { PremiseListItemType } from "@common/types/premiseTypes";
import { isOps } from "@common/utils/authenticationHelpers";
import { Body3 } from "@ops-design-system/components/Typography/Typography";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import {
  AccountNumberOption,
  AccountNumberSelected,
  ColoredBar,
  GraySeparatorBar,
  PremiseIdOption,
  PremiseIdSelected,
  SelectOptionWrapper,
  SelectedOptionValueWrapper,
  StyledCheckIcon,
  customStyles,
} from "@ops/components/PremiseSelect/PremiseSelect.styled";
import React, { FC, Ref, useContext } from "react";
import Select, { OptionProps } from "react-select";

export interface PremiseSelectOptionType {
  label: JSX.Element | string;
  premise: PremiseListItemType;
  value: string;
}

export interface PremiseSelectProps {
  onChange: (option: PremiseSelectOptionType | null) => void;
  premises: PremiseListItemType[];
  selected: IdType | null;
}

const CustomOption = ({
  innerRef,
  innerProps,
  data,
  isFocused,
  isSelected,
}: OptionProps<PremiseSelectOptionType, false>) => {
  const { groups } = useContext(AuthGroupsContext);
  const isOpsUser = isOps(groups);
  const includePremiseId = isOpsUser;

  return (
    <SelectOptionWrapper
      isActive={isFocused}
      ref={innerRef as Ref<HTMLDivElement>}
      {...innerProps}
    >
      <ColoredBar $status={data.premise.status} />
      <AccountNumberOption $includePremiseId={includePremiseId}>
        {data.premise.billingAccountNumber ?? UNKNOWN_ACCOUNT_NUMBER}
      </AccountNumberOption>
      <AccessControl>
        <GraySeparatorBar />
        <PremiseIdOption>Premise ID {data.premise.id}</PremiseIdOption>
      </AccessControl>
      <Body3>{data.premise.serviceAddress?.deliveryLine1}</Body3>
      {isSelected && <StyledCheckIcon />}
    </SelectOptionWrapper>
  );
};

export const PremiseSelect: FC<React.PropsWithChildren<PremiseSelectProps>> = ({
  premises,
  selected: selectedPremiseId,
  onChange,
}) => {
  const { groups } = useContext(AuthGroupsContext);
  const isOpsUser = isOps(groups);
  const [isLongText, includePremiseId] = Array(2).fill(isOpsUser);

  const options = premises.map((premise) => {
    return {
      label: "",
      premise,
      value: premise.id,
    };
  });

  const selected = selectedPremiseId
    ? options.find(({ value }) => {
        return value === selectedPremiseId;
      })
    : options[0];

  return (
    <>
      {selected?.premise && (
        <SelectedOptionValueWrapper>
          <ColoredBar $status={selected.premise.status} />
          <AccountNumberSelected $includePremiseId={includePremiseId}>
            {selected?.premise.billingAccountNumber ?? UNKNOWN_ACCOUNT_NUMBER}
          </AccountNumberSelected>
          <AccessControl>
            <GraySeparatorBar />
            <PremiseIdSelected>
              Premise ID {selected.premise.id}
            </PremiseIdSelected>
          </AccessControl>
        </SelectedOptionValueWrapper>
      )}
      <Select
        aria-label="Select a Premise"
        isSearchable={false}
        styles={customStyles(isLongText)}
        onChange={onChange}
        options={options}
        value={selected}
        components={{ Option: CustomOption }}
      />
    </>
  );
};
