import { premiseApi } from "@common/api/premiseApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { UsageHistoryResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { HistoricalUsageType } from "@common/types/usageTypes";
import {
  formatKwh,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableCellContentWidth,
  BoTableHead,
  BoTableHeadCell,
  BoTableLabel,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const COLUMNS = ["Usage", "Start Date", "End Date", "Usage Days", "Type"];

interface UsageHistoryRowProps {
  usage: HistoricalUsageType;
}

const StyledTable = styled(BoTable)`
  width: 100%;
`;

const UsageHistoryRow = ({ usage }: UsageHistoryRowProps) => {
  const startDate = dayjs(usage.startDate);
  const endDate = dayjs(usage.endDate);
  const usageDays = endDate.diff(startDate, "days");

  return (
    <BoTableRow>
      <BoTableCellContentWidth $align="center">
        {formatKwh(usage.quantity, 0)}
      </BoTableCellContentWidth>
      <BoTableCellContentWidth>
        {formatMonthDayShortYear(usage.startDate)}
      </BoTableCellContentWidth>
      <BoTableCellContentWidth $align="center">
        {formatMonthDayShortYear(usage.endDate)}
      </BoTableCellContentWidth>
      <BoTableCellContentWidth $align="center">
        {usageDays}
      </BoTableCellContentWidth>
      <BoTableCell>{usage.type}</BoTableCell>
    </BoTableRow>
  );
};

export const UsageHistoryTable = () => {
  const [{ data, requestMonitor }, { setSuccess, setPending, setFailure }] =
    useAjaxState<UsageHistoryResponseType>();
  const { premiseId } = useParams<{ premiseId: IdType }>();

  useEffect(() => {
    if (premiseId) {
      setPending();
      premiseApi
        .usageHistory(premiseId)
        .then((response) => {
          setSuccess(response);
        })
        .catch((error) => {
          setFailure(error);
        });
    }
  }, [premiseId]);

  if (requestMonitor.isPending) {
    return <BoCircularProgress />;
  }

  if (requestMonitor.didFail || !data) {
    return (
      <>
        <BoCardLabel>Usage History</BoCardLabel>
        <BoCard>No Usage Available</BoCard>
      </>
    );
  }
  return (
    <>
      <BoTableLabel>Usage History</BoTableLabel>
      <StyledTable>
        <BoTableHead>
          <BoTableRow>
            {COLUMNS.map((columnName) => (
              <BoTableHeadCell key={columnName}>{columnName}</BoTableHeadCell>
            ))}
          </BoTableRow>
        </BoTableHead>
        <BoTableBody>
          {data.map((usage, index) => (
            <UsageHistoryRow
              usage={usage}
              // eslint-disable-next-line react/no-array-index-key
              key={`usage-${usage.startDate}-${index}`}
            />
          ))}
        </BoTableBody>
      </StyledTable>
    </>
  );
};
