import { formatCurrency, separateNumber } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButtonWithConfirmDialog } from "@ops-design-system/components/BoButtonWithConfirmDialog/BoButtonWithConfirmDialog";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  Body1,
  H4,
  Subtitle1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { useAffiliateRedeemRewards } from "@ops/hooks/mutations/useAffiliateRedeemRewards";
import { useAffiliateRewards } from "@ops/hooks/queries/useAffiliateRewards";
import React from "react";
import styled from "styled-components";

interface RafRewardsSectionProps {
  affiliateId: string;
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
`;

export const RafRewardsSection = ({ affiliateId }: RafRewardsSectionProps) => {
  const flash = useRhFlash();

  const affiliateRewardsQuery = useAffiliateRewards({ affiliateId });
  const redeemAffiliateRewardsMutation = useAffiliateRedeemRewards();

  if (affiliateRewardsQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (affiliateRewardsQuery.isError) {
    return (
      <Body1>
        There was an issue fetching the rewards balance for this affiliate.
        Please try again.
      </Body1>
    );
  }

  const { balance, value, lifetime, referredCustomers } =
    affiliateRewardsQuery.data;

  const redeemAllPoints = () => {
    const amountToRedeem = balance;

    redeemAffiliateRewardsMutation.mutate(
      { affiliateId, amount: amountToRedeem },
      {
        onError: () => {
          flash.error(
            "There seems to be an issue with redeeming points. Please try again later."
          );
        },
        onSuccess: () => {
          flash.success(
            `Successfully redeemed ${amountToRedeem} points for this affiliate.`
          );
        },
      }
    );
  };

  return (
    <Container>
      <H4>Points Balance</H4>

      <Subtitle1>
        <strong>{separateNumber(balance)}</strong> Points
      </Subtitle1>

      <Subtitle2>{`${formatCurrency(value)} cash value`}</Subtitle2>
      <Subtitle2 color="textSecondary">
        {`${separateNumber(lifetime)} lifetime Points earned`}
      </Subtitle2>
      <Subtitle2 color="textSecondary">
        {`${referredCustomers} customers referred`}
      </Subtitle2>
      <BoButtonWithConfirmDialog
        variant="primary"
        disabled={balance === 0}
        buttonContent="Redeem Points"
        handleConfirm={redeemAllPoints}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        header="Redeem All Points?"
        message={
          <Body1>
            This will redeem all {balance} points for this affiliate. Would you
            like to continue?
          </Body1>
        }
      />
    </Container>
  );
};
