import { StyledCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress.style";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import React from "react";

export const CIRCULAR_PROGRESS_DEFAULT_SIZE = 40;

interface RhCircularProgressProps {
  className?: string;
  marginBottom?: number;
  marginTop?: number;
  size?: number;
  width?: string;
}

export const RhCircularProgress = ({
  className,
  marginBottom,
  marginTop,
  size,
  width,
}: RhCircularProgressProps) => (
  <RhFlexBox
    alignItems="center"
    justifyContent="center"
    width={width ?? "100%"}
    marginBottom={marginBottom ?? 2}
    marginTop={marginTop ?? 2}
    className={className}
  >
    <StyledCircularProgress size={size} />
  </RhFlexBox>
);
