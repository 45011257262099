import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface EnergyContractDeletedData extends EventLogBaseData {
  orderConfirmedEndDate: string;
  orderEnd: string;
  orderId: string;
  orderNumber: string;
  orderPlanName: string;
  orderRate: string;
  orderStart: string;
  orderTerm: string;
  solarBuybackRate: string;
}

export class EnergyContractDeleted extends EventLog {
  public readonly orderNumber: string;
  public readonly orderId: string;
  public readonly orderStart: string;
  public readonly orderEnd: string;
  public readonly orderPlanName: string;
  public readonly orderConfirmedEndDate: string;
  public readonly orderRate: string;
  public readonly orderTerm: string;
  public readonly solarBuybackRate: string;

  constructor({
    orderNumber,
    orderId,
    orderStart,
    orderEnd,
    orderPlanName,
    orderConfirmedEndDate,
    orderRate,
    orderTerm,
    solarBuybackRate,
    ...baseEventData
  }: EnergyContractDeletedData) {
    super(baseEventData);

    this.orderNumber = orderNumber;
    this.orderId = orderId;
    this.orderStart = formatMonthDayYear(orderStart);
    this.orderEnd = formatMonthDayYear(orderEnd);
    this.orderPlanName = orderPlanName;
    this.orderRate = orderRate;
    this.orderTerm = orderTerm;
    this.orderConfirmedEndDate = formatMonthDayYear(orderConfirmedEndDate);
    this.solarBuybackRate = solarBuybackRate;
  }

  override get body() {
    const sbbText = this.solarBuybackRate
      ? `- SBB Rate: ${this.solarBuybackRate} $/kwh `
      : "";

    return `${this.name} - Order Number: ${this.orderNumber} -  Id: ${this.orderId} - Plan:${this.orderPlanName} - Rate: ${this.orderRate} $/kwh ${sbbText}- Term: ${this.orderTerm}  - Start Date: ${this.orderStart} - End Date: ${this.orderEnd}`;
  }
}
