import { IdType } from "@common/types/apiTypes";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { EnergyGraph } from "@ops/components/EnergyUsageChartV2/EnergyGraph/EnergyGraph";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";

export const EnergyUsageChartV2 = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();

  const { premise } = usePremise(premiseId);

  if (!premise?.meter?.isSmt || !premise?.currentOrder) {
    return null;
  }

  return (
    <>
      <BoCardLabel>Energy Usage</BoCardLabel>
      <BoCard>
        <EnergyGraph premise={premise} />
      </BoCard>
    </>
  );
};
