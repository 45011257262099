import { IdType } from "@common/types/apiTypes";
import { rhapsodyPremiseApi } from "@ops-data/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@ops/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UseBillForecastSummaryProps {
  premiseId: IdType;
}
export const useBillForecastSummary = (props: UseBillForecastSummaryProps) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => rhapsodyPremiseApi.billForecastSummary(premiseId),
    queryKey: PremiseKeys.billForecastSummary(premiseId),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });
};
