import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { legacyEdiProviderApi } from "@ops-data/api/legacyEdiProviderApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

interface GenerateInboundEDIFormValues {
  ediIntent: string;
}

const StyledContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
  & > label {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  & > button {
    width: 100px;
  }
`;

export const GenerateInboundEDIForm = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const query = useQuery({
    queryFn: () => {
      return legacyEdiProviderApi.getAllowableInboundEDIActions(premiseId);
    },
    queryKey: ["premise", premiseId, "edi", "inboundActions"],
  });

  const mutation = useMutation({
    mutationFn: ({
      targetPremiseId,
      intent,
    }: {
      intent: string;
      targetPremiseId: IdType;
    }) => legacyEdiProviderApi.triggerEDIGeneration(targetPremiseId, intent),
  });

  if (query.isPending) {
    return <BoCircularProgress />;
  }

  if (query.isError) {
    flash.error("Error fetching inbound actions");
    return null;
  }

  const onSubmit = (values: GenerateInboundEDIFormValues) => {
    if (!values.ediIntent) {
      flash.error("Select an option");
      return;
    }

    mutation.mutate(
      { intent: values.ediIntent, targetPremiseId: premiseId },
      {
        onError: () => {
          flash.error("Unable to generate EDI Message. Please try again.");
        },
        onSuccess: () => {
          flash.success("EDI message created");
          dispatch(premiseRefetch(premiseId));
          queryClient.invalidateQueries({
            queryKey: ["premise", premiseId, "edi"],
          });
        },
      }
    );
  };

  return (
    <Form<GenerateInboundEDIFormValues>
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <StyledContainer>
            <Field name="ediIntent">
              {(props) => (
                <label htmlFor="mock-edi-message-select">
                  Select EDI Type
                  <select id="mock-edi-message-select" {...props.input}>
                    <option aria-label="Null" />
                    {query.data.valid.map((edi) => (
                      <option value={edi.intent} key={edi.intent}>
                        {edi.label}
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </Field>
            <BoButton type="submit" variant="secondary" size="extraSmall">
              Send
            </BoButton>
          </StyledContainer>
        </form>
      )}
    />
  );
};
