import { SHORT_DATE_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  Body1,
  Body3,
  H1,
} from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ConfirmField } from "@ops/components/CreateProductContract/ConfirmField";
import type { CreateProductContractFormValues } from "@ops/components/CreateProductContract/CreateProductContract";
import { useProductPrices } from "@ops/hooks/queries/useProductPrices";
import { useCustomer } from "@ops/hooks/useCustomer";
import { usePremise } from "@ops/hooks/usePremise";
import dayjs from "dayjs";
import React from "react";
import { useFormState } from "react-final-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledArticle = styled.article`
  display: grid;
  flex: 1;
  gap: ${rhOpsSpacingPx(5)};
  grid-template-areas:
    "header header header"
    "customer addon1 addon2"
    "confirm confirm confirm";
  grid-template-columns: 1fr 1fr 1fr;
  place-content: baseline;
`;

const StyledH1 = styled(H1)`
  grid-area: header;
  text-align: center;
`;

const StyledValueHeader = styled(Body1).attrs({ $fontWeight: "SemiBold" })``;
const StyledValue = styled(Body1)`
  color: ${grey["600"]};
`;

const paddingTop = rhOpsSpacingPx(2);
const paddingRight = rhOpsSpacingPx(4);
const paddingBottom = rhOpsSpacingPx(2);
const paddingLeft = rhOpsSpacingPx(2);
const StyledAside = styled.aside`
  border: 1px solid ${grey["100"]};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  grid-area: customer;
  padding: ${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft};
  place-self: center baseline;

  row-gap: 4px;
  ${StyledValue}:not(:last-child) {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;

const StyledSection = styled.section<{ gridArea: "addon1" | "addon2" }>`
  display: flex;
  flex-direction: column;
  grid-area: ${(props) => props.gridArea};
  place-self: start start;
  ${StyledValue}:not(:last-child) {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
`;

const StyledConfirmField = styled(ConfirmField)`
  grid-area: confirm;
  width: 90%;
`;

const StyledHeaderAndValue = ({
  header,
  value,
}: {
  header: string;
  value: React.ReactNode;
}) => {
  const id = `value-info-group-${header.replaceAll(" ", "-")}`; // TODO: replace with useID() from React 18 once available

  return (
    <>
      <StyledValueHeader as="label" id={id}>
        {header}
      </StyledValueHeader>
      <StyledValue aria-labelledby={id}>{value}</StyledValue>
    </>
  );
};

interface ConfirmationPageProps {
  startDate: Date;
}

export const ConfirmationPage = ({
  startDate: givenStartDate,
}: ConfirmationPageProps) => {
  const { id, premiseId } = useParams<{ id: IdType; premiseId: IdType }>();
  const { customer } = useCustomer(id as IdType);
  const { premise } = usePremise(premiseId as IdType);
  const { values } = useFormState<CreateProductContractFormValues>();
  const { data: productPrices, ...productPricesQuery } = useProductPrices();

  if (productPricesQuery.isPending) {
    return (
      <div>
        <RhCircularProgress />
      </div>
    );
  }
  if (productPricesQuery.isError) {
    return (
      <div>
        <Body1>
          Error loading product prices information. Please try again.
        </Body1>
      </div>
    );
  }

  const selectedProductPrice = productPrices.find(
    (price) => price.id === values.productSelection
  );

  if (!selectedProductPrice) {
    return (
      <div>
        <Body1>
          Could not find a matching product price for price id{" "}
          {values.productSelection}
        </Body1>
      </div>
    );
  }

  const startDate = dayjs(givenStartDate);
  const endDate = startDate.add(1, "year");

  const productDisplayName =
    selectedProductPrice.tier.name ?? selectedProductPrice.product.name;

  if (!productDisplayName) {
    throw new Error("Product display name is not defined");
  }

  return (
    <>
      <StyledH1>Review New Add On Details</StyledH1>
      <StyledArticle aria-label="Confirmation Page">
        <StyledAside>
          <StyledHeaderAndValue
            header="Customer Name"
            value={customer?.fullName}
          />
          <StyledHeaderAndValue
            header="Service Address"
            value={
              <>
                {premise?.serviceAddress?.line1}
                <br />
                {premise?.serviceAddress?.line2}
              </>
            }
          />
        </StyledAside>
        <StyledSection gridArea="addon1">
          <StyledHeaderAndValue
            header="New Add On"
            value={productDisplayName}
          />
          <StyledHeaderAndValue
            header="Contract Start Date"
            value={startDate.format(SHORT_DATE_FORMAT)}
          />
          <StyledHeaderAndValue
            header="Contract End Date"
            value={endDate.format(SHORT_DATE_FORMAT)}
          />
        </StyledSection>
        <StyledSection gridArea="addon2">
          <StyledHeaderAndValue
            header="Price"
            value={selectedProductPrice.formattedPricePerFrequency}
          />
        </StyledSection>
        <StyledConfirmField>
          <Body3>
            By checking this box, the customer has agreed to the above Product
            Add Ons.
          </Body3>
        </StyledConfirmField>
      </StyledArticle>
    </>
  );
};
