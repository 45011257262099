import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import {
  DateTimeWrapper,
  LeftContainer,
  StyledDate,
  StyledEntry,
  StyledTextWrapper,
  StyledTextWrapperSmall,
  StyledTime,
} from "@ops/components/SupportLogs/SupportLogs.styled";
import React from "react";
import styled from "styled-components";

const EventLogEntryHeader = styled.div`
  padding-right: ${rhOpsSpacingPx(1)};
`;

interface EventLogEntryProps {
  body: JSX.Element | string | null;
  date: JSX.Element | string;
  header: JSX.Element | string;
  initiatedBy: JSX.Element | string;
  key: string;
  subheader: JSX.Element | string;
  time: JSX.Element | string;
}

export const EventLogEntry = ({
  key,
  date,
  time,
  initiatedBy,
  header,
  subheader,
  body,
}: EventLogEntryProps) => {
  return (
    <StyledEntry key={key} data-testid={`eventLog__entry:${key}`}>
      <LeftContainer>
        <DateTimeWrapper>
          <StyledDate>{date}</StyledDate> <StyledTime>{time}</StyledTime>
        </DateTimeWrapper>
        <StyledTextWrapperSmall>
          <div>{initiatedBy}</div>
        </StyledTextWrapperSmall>
      </LeftContainer>
      <div>
        <BoFlexBox $alignItems="center">
          <EventLogEntryHeader>
            <Body2 $fontWeight="Bold" as="div">
              {header}
            </Body2>
          </EventLogEntryHeader>
          <Body3 as="div">{subheader}</Body3>
        </BoFlexBox>
        <StyledTextWrapper $whiteSpace="pre-line">{body}</StyledTextWrapper>
      </div>
    </StyledEntry>
  );
};
