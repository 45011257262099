import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const SideBarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;

interface ContractRenewalWizardContainerProps {
  $twoColumns: boolean;
}
export const ContractRenewalWizardContainer = styled.div<ContractRenewalWizardContainerProps>`
  display: grid;
  gap: ${rhOpsSpacingPx(2)};
  grid-template-columns: ${({ $twoColumns }) =>
    $twoColumns ? "1fr 5fr" : "1fr"};
`;

export const ContractRenewalWizardButtonContainer = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(2)};
  position: absolute;
  right: 0;
`;

export const ContractRenewalWizardStepContainer = styled.div`
  position: relative;
`;

export const ContractRenewalWizardLabel = styled.span<{ $inline?: boolean }>`
  font-weight: ${FontWeight.SemiBold};

  ${({ $inline }) =>
    $inline &&
    `
    &::after {
      content: ":";
    }
  `}
`;

export const ContractRenewalWizardValue = styled.span`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(0.25)};
`;

export const ContractRenewalWizardSectionContainer = styled.div<{
  $inline?: boolean;
}>`
  display: flex;
  flex-direction: ${({ $inline }) => ($inline ? "row" : "column")};
`;

export const ContractRenewalWizardStepForm = styled.form`
  min-height: 300px;
  padding-bottom: ${rhOpsSpacingPx(6)};
`;
