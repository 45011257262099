import React from "react";
import styled from "styled-components";

export const StyledCodeBlock = styled.code`
  line-height: 1.5;
`;

interface JSONRenderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>;
}

export const JSONRender = ({ object }: JSONRenderProps) => {
  return (
    <pre>
      <StyledCodeBlock>{JSON.stringify(object, null, 2)}</StyledCodeBlock>
    </pre>
  );
};
