import { FinalFormFieldValidator } from "@common/forms/validators";
import { FormError } from "@common/types/errorTypes";

export interface Validations<FormState extends object>
  extends Record<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FinalFormFieldValidator<any, FormState>[] | Validations<FormState>
  > {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resolveFieldErrors = <FormState extends Record<string, any>>(
  fieldName: string,
  allValues: FormState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validators: FinalFormFieldValidator<any, FormState>[]
): string[] | undefined => {
  const fieldValue = allValues[fieldName];

  const fieldErrorMessages = validators.reduce<string[]>(
    (errorMessages, validateFn) => {
      const error = validateFn(fieldValue, allValues);

      return error ? [...errorMessages, error] : errorMessages;
    },
    []
  );

  return fieldErrorMessages.length ? fieldErrorMessages : undefined;
};

export const generateValidationErrorCollector =
  <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FormState extends Record<string, any>
  >(
    validations: Validations<FormState>
  ) =>
  (allValues: FormState) => {
    const resolvedErrors: FormError = {};

    const errorReducer = (errors: FormError, fieldName: string) => {
      const validation = validations[fieldName];

      if (Array.isArray(validation)) {
        const fieldErrors = resolveFieldErrors(
          fieldName,
          allValues,
          validation
        );

        if (fieldErrors) {
          // eslint-disable-next-line no-param-reassign
          errors[fieldName] = fieldErrors;
        }
      } else {
        const nestedErrorCollector =
          generateValidationErrorCollector(validation);
        const nestedErrors = nestedErrorCollector(allValues[fieldName]);

        if (Object.keys(nestedErrors).length) {
          // eslint-disable-next-line no-param-reassign
          errors[fieldName] = nestedErrors;
        }
      }
      return errors;
    };

    return Object.keys(validations).reduce(errorReducer, resolvedErrors);
  };
