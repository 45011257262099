import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";

export const usePaymentMethodOptions = (
  paymentMethods: PaymentMethod[] | null,
  { withDefaultFallback }: { withDefaultFallback?: boolean } = {
    withDefaultFallback: false,
  }
): {
  defaultPaymentMethod?: PaymentMethod;
  paymentMethodOptions: BoSelectOptionType[];
} => {
  const defaultPaymentMethod = paymentMethods?.find(
    (method) => method.defaultPaymentMethod
  );
  const paymentMethodOptions =
    paymentMethods?.map((paymentMethod) => ({
      label: paymentMethod.companyNameAndNumber,
      value: paymentMethod.id,
    })) ?? [];

  if (withDefaultFallback && paymentMethods?.length) {
    return {
      defaultPaymentMethod: defaultPaymentMethod ?? paymentMethods[0],
      paymentMethodOptions,
    };
  } else {
    return { defaultPaymentMethod, paymentMethodOptions };
  }
};
