import { formatCurrency } from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface RewardsAddedData extends EventLogBaseData {
  quantity: string;
  rewardType: string;
}

export class RewardsAdded extends EventLog {
  public readonly quantity: string;
  public readonly rewardType: string;

  constructor({ quantity, rewardType, ...baseEventData }: RewardsAddedData) {
    super(baseEventData);
    this.quantity = quantity;
    this.rewardType = rewardType;
  }

  override get body() {
    return `${this.name}
    Reason: ${this.rewardType}
    Quantity: ${this.quantity} points / ${formatCurrency(rewardPointsToDollars(parseInt(this.quantity as string, 10)))}`;
  }
}
