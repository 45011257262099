import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { OktaGroups } from "@common/constants/okta.constant";
import { IdType } from "@common/types/apiTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as Renew } from "@ops-design-system/icons/Renew.svg";
import {
  contractRenewalWizardCurrentStepAtom,
  contractRenewalWizardModalAtom,
  contractRenewalWizardStateAtom,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.atoms";
import { contractRenewalWizardStepMapping } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.constants";
import {
  ContractRenewalWizardContainer,
  SideBarsContainer,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import { ContractRenewalWizardNewContractSideBar } from "@ops/components/ContractRenewalWizard/ContractRenewalWizardNewContractSideBar/ContractRenewalWizardNewContractSideBar";
import { ContractRenewalWizardPremiseInfoSidebar } from "@ops/components/ContractRenewalWizard/ContractRenewalWizardPremiseInfoSidebar/ContractRenewalWizardPremiseInfoSidebar";
import { useContractRenewalWizard } from "@ops/components/ContractRenewalWizard/useContractRenewalWizard";
import { usePremise } from "@ops/hooks/usePremise";
import { useAtom, useAtomValue } from "jotai";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";

export const ContractRenewalWizard = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);

  const contractRenewalWizardFormStateAtom = useAtomValue(
    contractRenewalWizardStateAtom
  );
  const currentStepAtom = useAtomValue(contractRenewalWizardCurrentStepAtom);
  const [modalOpenAtom, setModalOpenAtom] = useAtom(
    contractRenewalWizardModalAtom
  );

  const { reset } = useContractRenewalWizard();

  const { groups } = useContext(AuthGroupsContext);

  if (!premise) {
    return null;
  }

  const allowedRenewAnytime =
    !!groups &&
    (groups.includes(OktaGroups.CSRTier2) ||
      groups.includes(OktaGroups.CSRTier3) ||
      groups.includes(OktaGroups.Ops));

  const { inRenewalWindow } = premise;
  const monthToMonth = premise.currentOrder?.isMonthToMonth;
  const visible = allowedRenewAnytime || monthToMonth || inRenewalWindow;

  if (!visible) {
    return null;
  }

  const showSideBar = !["confirmationStep", "successStep"].includes(
    currentStepAtom
  );

  const modalSize = currentStepAtom !== "successStep" ? "large" : "default";

  return (
    <>
      <BoButton
        onClick={() => setModalOpenAtom(true)}
        variant="secondary"
        size="extraSmall"
        icon={Renew}
      >
        Renew Contract
      </BoButton>

      {modalOpenAtom ? (
        <BoModal open onClose={reset} size={modalSize}>
          <BoDialogHeader>Renew Contract</BoDialogHeader>
          <BoDialogBody>
            <ContractRenewalWizardContainer $twoColumns={showSideBar}>
              {showSideBar ? (
                <SideBarsContainer>
                  <ContractRenewalWizardPremiseInfoSidebar premise={premise} />
                  {contractRenewalWizardFormStateAtom.offerSnapshotId !== "" ? (
                    <ContractRenewalWizardNewContractSideBar
                      offerSnapshotId={
                        contractRenewalWizardFormStateAtom.offerSnapshotId
                      }
                      premise={premise}
                    />
                  ) : null}
                </SideBarsContainer>
              ) : null}

              {contractRenewalWizardStepMapping[currentStepAtom]}
            </ContractRenewalWizardContainer>
          </BoDialogBody>
        </BoModal>
      ) : null}
    </>
  );
};
