import { RhSelect } from "@design-system/components/RhSelect/RhSelect.styled";
import { BoTextButton } from "@ops-design-system/components/BoTextButton/BoTextButton";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import {
  darkPurple,
  grey,
  red,
  trueBlack,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledAddNewButton = styled(Body2).attrs({
  as: BoTextButton,
})`
  color: ${darkPurple["500"]};
`;

export const StyledFormBody = styled.div`
  & > div {
    margin-bottom: ${rhOpsSpacingPx(2)};

    &:last-child {
      margin-bottom: 0;
    }
  }
  & > select {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0.8rem 0.5rem;
  }
  & > select:disabled {
    color: ${grey[400]};
  }
  & > label {
    margin-top: ${rhOpsSpacingPx(1)};
  }
  & > textarea {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const StyledContactLogSelect = styled(RhSelect)`
  & > option {
    font-size: 0.8rem;
    font-weight: ${FontWeight.Bold};
  }
`;

export const StyledContactLogCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const StyledContactLogCheckboxLabel = styled(BoLabel)`
  color: ${trueBlack};
  font-size: 1rem;
  font-weight: ${FontWeight.Regular};
`;

export const StyledContactLogError = styled(Body2).attrs({ as: "div" })`
  color: ${red.main};
  margin-bottom: ${rhOpsSpacingPx(1)} !important;
  margin-top: -${rhOpsSpacingPx(0.8)};
`;
