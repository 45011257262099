import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface BillSpikeData extends EventLogBaseData {
  actualCost: string;
  billForecastCost: string;
}

export class BillSpike extends EventLog {
  public readonly actualCost: string;
  public readonly billForecastCost: string;

  constructor({
    actualCost,
    billForecastCost,
    ...baseEventData
  }: BillSpikeData) {
    super(baseEventData);

    this.actualCost = actualCost;
    this.billForecastCost = billForecastCost;
  }

  override get body() {
    return `${this.name} - Actual Cost: $${this.actualCost} - Bill Forecast Cost: $${this.billForecastCost}`;
  }
}
