import { OktaGroups } from "@common/constants/okta.constant";

export interface OktaGroupsPermission {
  isCSRs: boolean;
  isCustomer: boolean;
  isFinance: boolean;
  isOps: boolean;
  isPricing: boolean;
}

export const isCustomer = (groups: OktaGroups[] | null = []) => {
  if (!groups) {
    return false;
  }
  return groups.includes(OktaGroups.Customers);
};

export const isCSRs = (groups: OktaGroups[] | null = []) => {
  if (!groups) {
    return false;
  }
  return groups.includes(OktaGroups.CSRs);
};

export const isFinance = (groups: OktaGroups[] | null = []) => {
  if (!groups) {
    return false;
  }
  return groups.includes(OktaGroups.Finance);
};

export const isOps = (groups: OktaGroups[] | null = []) => {
  if (!groups) {
    return false;
  }
  return groups.includes(OktaGroups.Ops);
};

export const isPricing = (groups: OktaGroups[] | null = []) => {
  if (!groups) {
    return false;
  }
  return groups.includes(OktaGroups.Pricing);
};

export const getOktaAuthPermissions = (
  groups: OktaGroups[] = []
): OktaGroupsPermission => {
  return {
    isCSRs: isCSRs(groups),
    isCustomer: isCustomer(groups),
    isFinance: isFinance(groups),
    isOps: isOps(groups),
    isPricing: isPricing(groups),
  };
};

export const isInGroups = (
  groups: OktaGroups[] | null = []
): OktaGroupsPermission | false => {
  if (!groups) {
    return false;
  }

  return getOktaAuthPermissions(groups);
};
