import { BoInfoContainer } from "@ops-design-system/components/BoInfo/BoInfo";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

interface StylePastDueProps {
  pastDueBalance: number;
}

export const StyledPastDue = styled.span<StylePastDueProps>`
  color: ${({ pastDueBalance }) =>
    pastDueBalance > 0 ? red["500"] : grey["900"]};
`;
export const BillInfoContainer = styled(BoInfoContainer)`
  margin-left: auto;
  margin-right: auto;
`;
