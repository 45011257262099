import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ConnectionRequestData extends EventLogBaseData {
  balance?: string;
  disconnectionDate?: string;
  serviceOrderPurposeCode?: string;
}

export abstract class ConnectionRequest extends EventLog {
  private readonly balance?: string;
  private readonly disconnectionDate?: string;
  private readonly serviceOrderPurposeCode?: string;

  constructor({
    balance,
    disconnectionDate,
    serviceOrderPurposeCode,
    ...baseEventData
  }: ConnectionRequestData) {
    super(baseEventData);
    this.balance = balance;
    this.disconnectionDate = disconnectionDate
      ? formatMonthDayYear(disconnectionDate)
      : undefined;
    this.serviceOrderPurposeCode = serviceOrderPurposeCode;
  }

  override get body(): string {
    if (this.balance && this.disconnectionDate) {
      return `${this.name} - Reason Code: ${stringOrEmDash(
        this.serviceOrderPurposeCode
      )} - DNP Expired: ${this.disconnectionDate} - Due for Reconnection: $${
        this.balance
      }`;
    } else if (this.serviceOrderPurposeCode) {
      return `${this.name} - Reason Code: ${stringOrEmDash(
        this.serviceOrderPurposeCode
      )}`;
    } else {
      return `${this.name}`;
    }
  }
}
