import { stringOrEmDash } from "@common/utils/stringFormatters";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { ConfirmationSection } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationSection";
import { ConfirmationCardType } from "@ops/components/ProspectEditor/ConfirmationTab/prospectConfirmationTypes";
import React from "react";

interface ConfirmationMarketingCardProps extends ConfirmationCardType {}

export const ConfirmationMarketingCard = ({
  prospect,
  errors,
}: ConfirmationMarketingCardProps) => {
  return (
    <ConfirmationSection label="Marketing" error={Boolean(errors)}>
      <BoGrid $variant={GridVariants.Grid4_1}>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.acquisitionCampaign}>
            <BoInfoLabel>Campaign</BoInfoLabel>
            <BoInfoValue>
              {stringOrEmDash(prospect.acquisitionCampaign)}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
      </BoGrid>
    </ConfirmationSection>
  );
};
