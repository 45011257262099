import { IdType } from "@common/types/apiTypes";
import { rhapsodyInvoicesApi } from "@ops-data/api/rhapsodyInvoicesApi";
import { InvoiceQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseBillBreakdownComparisonQueryProps {
  invoiceId: IdType;
}

export const useBillBreakdownComparisonQuery = (
  props: UseBillBreakdownComparisonQueryProps
) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => rhapsodyInvoicesApi.compare(invoiceId),
    queryKey: InvoiceQueryKeys.compare(invoiceId),
  });
};
