import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { ReactComponent as PaymentCard } from "@ops-design-system/icons/PaymentCard.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

interface AddCreditCardButtonProps {
  onClick: () => void;
}

export const AddPaymentButtonWrapper = styled.div`
  margin-top: ${rhOpsSpacingPx(3)};
`;

export const AddCreditCardButton = ({ onClick }: AddCreditCardButtonProps) => {
  return (
    <BoButton variant="secondary" onClick={onClick} icon={PaymentCard}>
      Add Credit or Debit
    </BoButton>
  );
};
