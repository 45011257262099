import { EM_DASH } from "@common/constants/characters.constant";
import { formatCurrency } from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import React from "react";

interface RewardPointsWithValueProps {
  points?: number | string | null;
}

export const RewardPointsWithValue = ({
  points,
}: RewardPointsWithValueProps) => {
  let dollarVal: string;
  let pointVal: string | number;

  if (!points && points !== 0) {
    return (
      <span>
        {EM_DASH} / {EM_DASH}
      </span>
    );
  } else {
    pointVal = points;
    dollarVal = formatCurrency(
      rewardPointsToDollars(parseInt(points as string, 10))
    );
    return (
      <span>
        {pointVal} points / {dollarVal}
      </span>
    );
  }
};
