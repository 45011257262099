import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { AddressForm } from "@common/models/AddressForm.model";
import { Premise } from "@common/models/Premise.model";
import { FormError } from "@common/types/errorTypes";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { legacyPremiseApi } from "@ops-data/api/legacyPremiseApi";
import { TransferServiceDataType } from "@ops-utils/types/transferServiceTypes";
import { addressIsEqual } from "@ops/components/MeterSearchForms/addressFormHelpers";
import { TransferServiceErrors } from "@ops/components/TransferServiceWizard/shared/enums";
import { TosWizardStateType } from "@ops/components/TransferServiceWizard/TransferServiceWizardProvider";
import dayjs from "dayjs";

const validator = generateValidationErrorCollector<TosWizardStateType>({
  esiId: [opsAndPricingIsRequired],
  mailingAddress: [opsAndPricingIsRequired],
  moveIn: [opsAndPricingIsRequired],
});

export class TransferServiceSubmission {
  private readonly tosWizardState: TosWizardStateType;
  private readonly premise: Premise;
  private transferServiceData?: TransferServiceDataType;
  private errorMessages?: (string | FormError)[];

  constructor(tosWizardState: TosWizardStateType, premise: Premise) {
    this.tosWizardState = tosWizardState;
    this.premise = premise;
  }

  submit() {
    if (!this.transferServiceData) {
      throw Error("Data is not validated");
    }

    return legacyPremiseApi.transferService(
      this.premise.id,
      this.transferServiceData
    );
  }

  get isValid(): boolean {
    const errors = validator(this.tosWizardState);

    this.errorMessages = Object.entries(errors).reduce<string[]>(
      (memo, [field, fieldErrors]) => {
        if (Array.isArray(fieldErrors) && fieldErrors.length > 0) {
          memo.push(convertCamelCaseToSentenceCase(field));
        }
        return memo;
      },
      []
    );

    const valid = this.errorMessages.length === 0;

    if (valid && this.tosWizardState.offerSnapshotId) {
      this.assignTransferServiceData();
    }

    return valid;
  }

  checkTransferability(): TransferServiceErrors | undefined {
    const previousOrder = this.premise.orders[this.premise.orders.length - 1];

    if (!previousOrder) {
      return TransferServiceErrors.noPreviousOrder;
    }

    if (!previousOrder.expectedEndDate) {
      return TransferServiceErrors.noEndDate;
    }

    if (dayjs(previousOrder.expectedEndDate).isBefore(dayjs(), "day")) {
      return TransferServiceErrors.endDateBeforeToday;
    }
  }

  get errors(): (string | FormError)[] {
    if (this.errorMessages) {
      return this.errorMessages;
    }

    throw Error(
      "Must validate data by running isValid() before calling errors"
    );
  }

  private assignTransferServiceData() {
    const {
      moveIn,
      moveOut,
      paperless,
      offerSnapshotId,
      mailingAddress,
      esiId,
      serviceAddress,
    } = this.tosWizardState;
    const mailingAddressSameAsServiceAddress = addressIsEqual(
      new AddressForm(mailingAddress),
      new AddressForm(serviceAddress)
    );

    this.transferServiceData = {
      eBill: paperless,
      esiId,
      mailingAddress: mailingAddressSameAsServiceAddress
        ? undefined
        : mailingAddress,
      offerId: offerSnapshotId,
      serviceEndDate: moveOut,
      serviceStartDate: moveIn as string,
    };
  }
}
