import { premiseApi } from "@common/api/premiseApi";
import { EN_DASH } from "@common/constants/characters.constant";
import { Premise } from "@common/models/Premise.model";
import { IdType } from "@common/types/apiTypes";
import { WelcomePacketMetadataType } from "@common/types/premiseTypes";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { borders } from "@ops-design-system/styles/borders";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ContractExpirationNoticesList } from "@ops/components/ContractExpirationNoticesList/ContractExpirationNoticesList";
import { WelcomePacketDownload } from "@ops/components/WelcomePacketDownload/WelcomePacketDownload";
import { usePremise } from "@ops/hooks/usePremise";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export const DocumentsListContainer = styled(Body2).attrs({ as: "div" })`
  border-top: ${borders["100"]};
  margin-top: ${rhOpsSpacingPx(2)};
  padding-top: ${rhOpsSpacingPx(2)};
`;

const PacketInfo = (label: string, show: boolean, download: JSX.Element) => {
  return (
    <BoInfoContainer>
      <BoInfoLabel>{label}</BoInfoLabel>
      {show ? download : <BoInfoValue>{EN_DASH}</BoInfoValue>}
    </BoInfoContainer>
  );
};

const WelcomePacketInfo = ({
  premise,
  welcomePacketInfo,
}: {
  premise: Premise;
  welcomePacketInfo: WelcomePacketMetadataType | null | undefined;
}) => {
  const packet = (
    <WelcomePacketDownload
      premiseId={premise.id}
      orderId={premise.currentOrder ? premise.currentOrder.id : ""}
    >
      View
    </WelcomePacketDownload>
  );

  return PacketInfo("Welcome Kit", !!welcomePacketInfo, packet);
};

export const PremiseDocumentsList = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise, error } = usePremise(premiseId);
  const [welcomePacketInfo, setWelcomePacketInfo] = useState<
    WelcomePacketMetadataType | null | undefined
  >(undefined);

  useEffect(() => {
    if (!premise?.id || !premise.currentOrder) {
      return;
    }
    premiseApi
      .getWelcomePacketMetadata(premise.id, premise.currentOrder.id)
      .then((data) => {
        setWelcomePacketInfo(data);
      })
      .catch((err) => setWelcomePacketInfo(null));
  }, [premise?.id, premise?.currentOrder?.id, setWelcomePacketInfo]);

  if (error || !premise || !premise.currentOrder) {
    return null;
  }

  return (
    <DocumentsListContainer>
      <BoGrid>
        <BoGridColumn>
          <WelcomePacketInfo
            premise={premise}
            welcomePacketInfo={welcomePacketInfo}
          />
        </BoGridColumn>
        <BoGridColumn>
          <ContractExpirationNoticesList
            premiseId={premise.id}
            orderId={premise.currentOrder.id}
          />
        </BoGridColumn>
      </BoGrid>
    </DocumentsListContainer>
  );
};
