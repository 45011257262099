import { ediApi } from "@common/api/ediApi";
import { Meter } from "@common/models/Meter.model";
import { cityStateZip } from "@common/utils/dataFormatters";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ValidatedAddressType } from "@ops/components/MeterSearchForms/useMeterSearchForms";
import { StatusIndicator } from "@ops/components/StatusIndicator/StatusIndicator";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useField } from "react-final-form";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  margin: -9px;
`;
const DataContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(2)};
  padding-left: ${rhOpsSpacingPx(2)};

  &:last-child {
    padding-bottom: 0;
  }
`;
const HeaderContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(0.5)};
  padding-top: ${rhOpsSpacingPx(0.5)};
`;

interface HeaderProps {}
const Header = ({ children }: PropsWithChildren<HeaderProps>) => {
  return (
    <HeaderContainer>
      <Body3 $fontWeight="SemiBold" as="div">
        {children}
      </Body3>
    </HeaderContainer>
  );
};
const Body = styled(Body2).attrs({ as: "div" })``;

interface ValidatedAddressCheckboxProps {
  validatedAddress: ValidatedAddressType;
}

export const ValidatedAddressCheckbox = ({
  validatedAddress: { serviceAddress, esiId },
}: ValidatedAddressCheckboxProps) => {
  const [meter, setMeter] = useState<Meter | null>(null);
  const { input } = useField<string>("confirmValidatedAddress", {
    type: "checkbox",
  });

  useEffect(() => {
    ediApi.meter.retrieve(esiId).then((meterData) => {
      setMeter(new Meter(meterData));
    });
  }, [serviceAddress]);

  return (
    <BoFlexBox>
      <CheckboxContainer>
        <BoCheckboxInput id={esiId} {...input} />
      </CheckboxContainer>
      <label htmlFor={esiId}>
        <DataContainer>
          <Header>Service Address</Header>
          <Body>{serviceAddress.addressLine1}</Body>
          {serviceAddress.unitNumber && (
            <Body>{serviceAddress.unitNumber}</Body>
          )}
          <Body>{cityStateZip(serviceAddress)}</Body>
        </DataContainer>
        <DataContainer>
          <Header>ESIID</Header>
          <Body>{esiId}</Body>
        </DataContainer>
        <DataContainer>
          <Header>Utility</Header>
          <Body>{meter?.utilityName}</Body>
        </DataContainer>
        {meter?.hasViolationErrors && (
          <DataContainer>
            {meter.violationErrors.map(({ text, status }) => (
              <StatusIndicator status={status} key={text}>
                {text}
              </StatusIndicator>
            ))}
          </DataContainer>
        )}
      </label>
    </BoFlexBox>
  );
};
