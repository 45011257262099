import {
  BoTextInput,
  BoTextInputProps,
} from "@ops-design-system/components/BoTextInput/BoTextInput";
import { maskSSN, unmaskSSN } from "@ops-design-system/forms/ssn.mask";
import React, { ChangeEventHandler, useEffect, useState } from "react";

type BoSsnInputProps = Omit<BoTextInputProps, "onChange"> & {
  onChange: (value: number) => void;
  value?: string;
};

export const BoSsnInput = ({
  onChange,
  value,
  children,
  ...rest
}: BoSsnInputProps) => {
  const [formattedValue, setFormattedValue] = useState<string>(
    value ? value.toString() : ""
  );
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputValue = e?.currentTarget?.value ?? "";

    setFormattedValue(maskSSN(inputValue));
    onChange && onChange(Number(unmaskSSN(inputValue)));
  };

  useEffect(() => {
    setFormattedValue(maskSSN(value?.toString() ?? ""));
  }, [value]);

  return (
    <BoTextInput {...rest} onChange={handleChange} value={formattedValue}>
      {children}
    </BoTextInput>
  );
};
