export const ISO8601_DATE_FORMAT = "YYYY-MM-DD";
export const ISO8601_DATE_FORMAT_REGEX =
  /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
export const MONTH_NAME_DAY_YEAR = "MMMM D, YYYY";
export const MONTH_DAY_YEAR_SLASH = "MM/DD/YYYY";
export const DOB_DATE_FORMAT = MONTH_DAY_YEAR_SLASH;
export const SHORT_DATE_FORMAT = "M/D/YY";
export const MONTH_DAY_SHORT_YEAR_SLASH = "MM/DD/YY";
export const SHORT_MONTH_DATE_FORMAT = "MM/DD";
export const SHORT_MONTH_WITH_HOUR_DATE_FORMAT = "MM/DD, ha";
export const DUE_DATE_FORMAT = "MMMM D";
export const LONG_DATE_TIME_FORMAT = "MM-DD-YY hh:mm A Z";
export const SHORT_TIME_FORMAT = "hh:mm A";
export const LONG_FRIENDLY_DATE_FORMAT = "MM/DD/YYYY hh:mm:ss A";
export const SHORT_MONTH_NAME_DAY_YEAR = "MMM D, YYYY";
