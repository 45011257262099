import {
  PaymentExtensionModalStateContext,
  PaymentExtensionModalStateProviderContext,
} from "@ops/components/PaymentExtensionWizardModal/PaymentExtensionModalStateProvider";
import { useContext } from "react";

export function usePaymentExtensionModalState(): PaymentExtensionModalStateProviderContext {
  const context = useContext<PaymentExtensionModalStateProviderContext>(
    PaymentExtensionModalStateContext
  );

  if (context === null) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }
  return context;
}
