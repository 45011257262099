import { ReactQueryDevtoolsContainer } from "@common/components/ReactQueryDevTools.style";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  // eslint-disable-next-line import/extensions
  import("@tanstack/react-query-devtools/build/modern/production.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

export const ReactQueryDevTools = () => {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:  Needed to allow Dev Tools in production for React Query
    window.toggleDevtools = () => setShowDevtools((prevState) => !prevState);
  }, []);

  return (
    <ReactQueryDevtoolsContainer>
      <ReactQueryDevtools buttonPosition="relative" />
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
    </ReactQueryDevtoolsContainer>
  );
};
