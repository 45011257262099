import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { useAffiliateReferrals } from "@ops/hooks/queries/useAffiliateReferrals";
import { customerDetailPath } from "@ops/routes/routePaths";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface AffiliateReferralTableProps {
  affiliateId: string;
}

const StyledTable = styled(BoTable)`
  width: 100%;
`;

export const AffiliateReferralTable = ({
  affiliateId,
}: AffiliateReferralTableProps) => {
  const navigate = useNavigate();

  const affiliateReferralQuery = useAffiliateReferrals({ affiliateId });

  if (affiliateReferralQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (affiliateReferralQuery.isError) {
    return (
      <Body2>
        An error occurred while getting the referrals for this affiliate. Please
        try again later.
      </Body2>
    );
  }

  if (affiliateReferralQuery.data.length === 0) {
    return (
      <Body2>
        No referrals associated with this affiliate have signed up for Rhythm
        yet.
      </Body2>
    );
  }

  const goToCustomerDetailPage = (customerId: string) => {
    navigate(customerDetailPath(customerId));
  };

  return (
    <StyledTable>
      <BoTableHead>
        <BoTableRow>
          <BoTableHeadCell>Id</BoTableHeadCell>
          <BoTableHeadCell>Email Address</BoTableHeadCell>
          <BoTableHeadCell>Customer Redeemed At</BoTableHeadCell>
          <BoTableHeadCell>Affiliate Rewarded At</BoTableHeadCell>
        </BoTableRow>
      </BoTableHead>
      <BoTableBody>
        {affiliateReferralQuery.data.map(
          ({
            refereeEmail,
            refereeId,
            refereeRedeemedReferralCodeAt,
            referrerRewardedPointsAt,
          }) => (
            <BoTableRow
              key={`${refereeEmail}${refereeId}`}
              onClick={() => goToCustomerDetailPage(refereeId)}
            >
              <BoTableCell>{refereeId}</BoTableCell>
              <BoTableCell>{refereeEmail}</BoTableCell>
              <BoTableCell>
                {refereeRedeemedReferralCodeAt
                  ? formatMonthDayYear(refereeRedeemedReferralCodeAt)
                  : "Not Redeemed"}
              </BoTableCell>
              <BoTableCell>
                {referrerRewardedPointsAt
                  ? formatMonthDayYear(referrerRewardedPointsAt)
                  : "Not Rewarded"}
              </BoTableCell>
            </BoTableRow>
          )
        )}
      </BoTableBody>
    </StyledTable>
  );
};
