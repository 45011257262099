import { AddressForm } from "@common/models/AddressForm.model";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { Prospect } from "@ops-utils/models/Prospect.model";
import {
  ValidatedAddressType,
  useMeterSearchForms,
} from "@ops/components/MeterSearchForms/useMeterSearchForms";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React, { useState } from "react";
import styled from "styled-components";

interface ServiceAddressFormProps {
  onValidatedAddressRetrieved: (validatedAddress: ValidatedAddressType) => void;
}

const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(4)};
  margin-top: ${rhOpsSpacingPx(4)};
`;

export const ServiceAddressForm = ({
  onValidatedAddressRetrieved,
}: ServiceAddressFormProps) => {
  const [SearchByAddress, SearchByEsiId] = useMeterSearchForms();
  const flash = useRhFlash();
  const { prospect: prospectData } = useEnrollmentFlowContext();
  const prospect = new Prospect(prospectData);
  const [currentServiceAddress, setCurrentServiceAddress] =
    useState<AddressForm | null>(prospect.serviceAddress);

  const handleAddressEsiIdSubmit = (
    response?: ValidatedAddressType,
    error?: string
  ) => {
    setCurrentServiceAddress(
      response ? new AddressForm(response.serviceAddress) : null
    );

    if (error || !response) {
      flash.error(error);
      return;
    }

    onValidatedAddressRetrieved(response);
  };

  return (
    <BoCard>
      <SearchByAddress
        align="left"
        onSubmit={handleAddressEsiIdSubmit}
        currentServiceAddress={currentServiceAddress}
      />
      <StyledDivider $light />
      <SearchByEsiId align="left" onSubmit={handleAddressEsiIdSubmit} />
    </BoCard>
  );
};
