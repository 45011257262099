import { blankAddress } from "@common/constants/blankAddress.constant";
import { TosWizardStateType } from "@ops/components/TransferServiceWizard/TransferServiceWizardProvider";

export const initialTosWizardState: TosWizardStateType = {
  esiId: "",
  mailingAddress: { ...blankAddress },
  moveIn: null,
  moveOut: null,
  moveOutUnknown: false,
  offerSnapshotId: "",
  paperless: true,
  serviceAddress: { ...blankAddress },
  transferServiceError: null,
};
