import {
  StyledBoButton,
  StyledButtonIconContainer,
  StyledButtonText,
  StyledLoadingContainer,
  buttonSizeThemes,
  buttonVariantThemes,
} from "@ops-design-system/components/BoButton/BoButton.styled";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Property } from "csstype";
import React, { FC } from "react";

export type BoButtonVariants = keyof typeof buttonVariantThemes;
export type BoButtonSizes = keyof typeof buttonSizeThemes;
export interface BoButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "size"> {
  alignSelf?: Property.AlignSelf;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: FC<React.SVGProps<SVGSVGElement>>;
  loading?: boolean;
  size?: BoButtonSizes;
  variant?: BoButtonVariants;
}

export const BoButton = ({
  icon: Icon,
  variant,
  disabled,
  size,
  children,
  type,
  loading,
  fullWidth,
  alignSelf,
  ...props
}: BoButtonProps) => {
  const disabledStyling = (disabled || loading) ?? false;

  return (
    <StyledBoButton
      type={type ?? "button"}
      disabled={disabledStyling}
      $disabled={disabledStyling}
      $iconPresent={Boolean(Icon)}
      $fullWidth={fullWidth}
      $variant={variant}
      $size={size}
      {...props}
      role="button"
    >
      {loading && (
        <StyledLoadingContainer>
          <BoCircularProgress position="absolute" />
        </StyledLoadingContainer>
      )}
      {Icon && (
        <StyledButtonIconContainer
          $variant={variant}
          $disabled={disabledStyling}
          $size={size}
        >
          <Icon aria-hidden="true" />
        </StyledButtonIconContainer>
      )}
      <StyledButtonText
        $disabled={disabledStyling}
        $variant={variant}
        $size={size}
        $iconPresent={Boolean(Icon)}
      >
        {children}
      </StyledButtonText>
    </StyledBoButton>
  );
};
