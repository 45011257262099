import { DateType, IdType } from "@common/types/apiTypes";
import {
  StatusIndicatorLevel,
  StatusIndicatorType,
} from "@common/types/statusIndicatorTypes";

export class StatusIndicator implements StatusIndicatorType {
  public readonly id: IdType | string;
  public readonly name: string;
  public readonly level: StatusIndicatorLevel;
  public readonly priority: number;
  public readonly expirationDate: DateType | null;
  public readonly activationDate: DateType | null;

  constructor(statusIndicator: StatusIndicatorType) {
    this.id = statusIndicator.id ?? `derived-${statusIndicator.name}`;
    this.name = statusIndicator.name;
    this.level = statusIndicator.level;
    this.priority = statusIndicator.priority;
    this.expirationDate = statusIndicator.expirationDate;
    this.activationDate = statusIndicator.activationDate;
  }
}
