import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const BILL_BREAKDOWN_TABLE_HEADER = 100;

export const StyledBillBreakdownComparisonTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  table-layout: fixed;
  text-align: center;
  width: 100%;

  th,
  td {
    border: 1px solid ${grey[100]};
    padding: ${rhOpsSpacingPx(1)};
  }

  th {
    width: ${BILL_BREAKDOWN_TABLE_HEADER}px;
  }
`;
