import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDialogProps } from "@ops-design-system/components/BoDialog/BoDialog";
import {
  BoDialogBodyContainer,
  BoDialogButtonContainer,
  BoDialogFooterContainer,
  BoDialogHeaderContainer,
} from "@ops-design-system/components/BoDialog/BoDialog.styled";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import React from "react";

interface BoDialogHeaderProps {
  children: React.ReactNode;
}

export const BoDialogHeader = ({ children }: BoDialogHeaderProps) => {
  return (
    <BoDialogHeaderContainer>
      <Subtitle2 $fontWeight="SemiBold">{children}</Subtitle2>
    </BoDialogHeaderContainer>
  );
};

export const BoDialogBody = BoDialogBodyContainer;

export interface BoDialogButtonFooterProps
  extends Pick<
    BoDialogProps,
    | "confirmBtnText"
    | "cancelBtnText"
    | "cancelDisabled"
    | "confirmDisabled"
    | "onConfirm"
    | "confirmButtonVariant"
    | "confirmLoading"
  > {
  className?: string;
  confirmBtnType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onCancel?: () => void;
}

export const BoDialogButtonFooter = ({
  cancelDisabled,
  confirmDisabled,
  cancelBtnText,
  confirmBtnText,
  confirmBtnType,
  onConfirm,
  onCancel,
  confirmButtonVariant,
  confirmLoading,
  className,
}: BoDialogButtonFooterProps) => {
  return (
    <BoDialogFooterContainer className={className}>
      {confirmBtnText && (
        <BoDialogButtonContainer>
          <BoButton
            loading={confirmLoading}
            type={confirmBtnType}
            disabled={confirmDisabled}
            onClick={onConfirm}
            variant={confirmButtonVariant || "primary"}
          >
            {confirmBtnText}
          </BoButton>
        </BoDialogButtonContainer>
      )}
      {cancelBtnText && (
        <BoDialogButtonContainer>
          <BoButton
            disabled={cancelDisabled}
            variant="secondary"
            onClick={onCancel}
          >
            {cancelBtnText}
          </BoButton>
        </BoDialogButtonContainer>
      )}
    </BoDialogFooterContainer>
  );
};
