import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  Body1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledSuccessMessage = styled(Subtitle2).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const AmountTypeContainer = styled.div`
  border-bottom: 1px solid ${grey["100"]};
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  padding-bottom: ${rhOpsSpacingPx(2)};
`;

export const AmountTypeRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AmountTypeOption = styled.div`
  align-items: center;
  color: ${grey[700]};
  display: flex;
  gap: ${rhOpsSpacingPx(1)};
`;

export const PaymentTransactionFee = styled.div`
  border-bottom: 1px solid ${grey["100"]};
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  padding: ${rhOpsSpacingPx(2)} 0 ${rhSpacingPx(3)};
`;

export const PaymentTransactionFeeNote = styled(Body1)`
  color: ${grey[500]};
  padding-left: ${rhOpsSpacingPx(4)};
`;

export const CurrencyInput = styled.input`
  border: 1px solid ${grey[200]};
  border-radius: 4px;
  font-size: 14px;
  padding: ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(1.5)};
  text-align: right;
  width: 110px;
`;

export const PaymentMethodsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(3)};
  margin-top: ${rhOpsSpacingPx(2)};
`;

export const TotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPaymentButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  width: fit-content;
`;

export const StyledNoPaymentMethodBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;
