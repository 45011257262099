import {
  PaymentMethodContext,
  PaymentMethodContextType,
} from "@ops/components/ManagePaymentMethods/ManagePaymentMethodsContextProvider";
import { useContext } from "react";

export function usePaymentMethodContext(): PaymentMethodContextType {
  const context = useContext(PaymentMethodContext);

  if (context === null) {
    throw new Error("Using context outside the Payment Method Provider");
  }
  return context;
}
