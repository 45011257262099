import { DeviceType } from "@ops-utils/types/deviceTypes";
import {
  StyledCheckIcon,
  StyledOpsSelectorListItem,
  StyledOpsSelectorStatusIndicator,
  StyledOpsSelectorStatusText,
  StyledOpsSelectorSubtitle,
  StyledOpsSelectorTitle,
  StyledOpsSelectorTitleContainer,
} from "@ops/components/OpsSelector/OpsSelector.styled";
import { deviceStatusColor } from "@ops/utils/deviceStatusColor";
import React from "react";

interface DevicesProps {
  device: DeviceType;
  isInMenu?: boolean;
  isSelectedDevice?: boolean;
}

export const DeviceSelectorListItem = (props: DevicesProps) => {
  const { device, isSelectedDevice, isInMenu } = props;
  const { deviceBrand, deviceName, deviceStatus } = device;

  return (
    <StyledOpsSelectorListItem>
      {!isInMenu ? (
        <StyledOpsSelectorStatusIndicator
          $statusColor={deviceStatusColor(deviceStatus)}
          data-testid="DeviceSelector__StatusIndicator"
        />
      ) : null}

      <StyledOpsSelectorTitleContainer>
        <StyledOpsSelectorTitle $isSelected={Boolean(isSelectedDevice)}>
          {deviceName}
        </StyledOpsSelectorTitle>

        <StyledOpsSelectorSubtitle>
          {deviceBrand} Thermostat
        </StyledOpsSelectorSubtitle>

        {isInMenu ? (
          <StyledOpsSelectorStatusText
            $statusColor={deviceStatusColor(deviceStatus)}
          >
            {deviceStatus}
          </StyledOpsSelectorStatusText>
        ) : null}
      </StyledOpsSelectorTitleContainer>

      {isSelectedDevice && isInMenu ? <StyledCheckIcon /> : null}
    </StyledOpsSelectorListItem>
  );
};
