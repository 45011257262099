import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const FampSubmitDetailsContainer = styled.div`
  width: 800px;
`;

export const StyledFampSubmitDetails = styled.div`
  width: 100%;
`;

export const SubmitDetailsSection = styled(BoFlexBox)`
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${rhOpsSpacingPx(4)};

  > div {
    flex: 1 0 30%;
    margin-bottom: ${rhOpsSpacingPx(4)};
  }
`;

export const SubmitDetailsBox = styled.div`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const SubmitDetailsDateBox = styled(SubmitDetailsBox)`
  justify-content: space-between;
  > div {
    width: 40%;
  }
`;
