import { capitalizeFirstLetterOfWords } from "@common/utils/stringFormatters";
import { BoSearchInput } from "@ops-design-system/components/BoSearchInput/BoSearchInput";
import { ReactComponent as XIcon } from "@ops-design-system/icons/XIcon.svg";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { LogType } from "@ops/components/SupportLogs/SupportLogs.types";
import React from "react";
import styled from "styled-components";

const StyledSearchContainer = styled.div`
  padding-left: ${rhOpsSpacingPx(2)};
  position: relative;
`;

const StyledIconContainer = styled.span`
  bottom: 2px;
  color: ${grey["400"]};
  position: absolute;
  right: 4px;
  z-index: 0;
  & svg {
    height: 10px;
    width: 10px;
  }
`;

interface SupportLogSearchInputProps {
  setValue: (value: string) => void;
  supportType: LogType;
  value?: string;
}

export const SupportLogSearchInput = ({
  setValue,
  value,
  supportType,
}: SupportLogSearchInputProps) => {
  const supportTypeText = capitalizeFirstLetterOfWords(supportType);
  const ariaLabel: string = `Search ${supportTypeText} Logs`;

  return (
    <StyledSearchContainer>
      <BoSearchInput
        aria-label={ariaLabel}
        name="search"
        value={value}
        placeholder="Search"
        onChange={(event) => setValue(event.target?.value)}
      />
      {Boolean(value) && (
        <StyledIconContainer onClick={() => setValue("")}>
          <XIcon />
        </StyledIconContainer>
      )}
    </StyledSearchContainer>
  );
};
