import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import { red } from "@ops-design-system/styles/palette.colors";
import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";

interface StyledSectionProps {
  $error?: boolean;
}
const StyledSection = styled.section<StyledSectionProps>`
  ${({ $error }) =>
    $error
      ? css`
          color: ${red.main};
        `
      : ""}
`;

interface ConfirmationSectionProps {
  error?: boolean;
  label: string;
}
export const ConfirmationSection = ({
  label,
  error,
  children,
}: PropsWithChildren<ConfirmationSectionProps>) => {
  const labelId = `header-${label}`.replace(/\s+/, "-");

  return (
    <StyledSection
      aria-labelledby={labelId}
      $error={error}
      aria-invalid={Boolean(error)}
    >
      <BoCardLabel id={labelId}>{label}</BoCardLabel>
      <BoCard $variant={error ? BoCardVariants.Warning : BoCardVariants.Normal}>
        {children}
      </BoCard>
    </StyledSection>
  );
};
