import { AdminDashboard } from "@ops/components/AdminDashboard/AdminDashboard";
import { MIN_WIDTH } from "@ops/constants/layout.constants";
import React from "react";
import styled from "styled-components";

export const StyledAdminHomePage = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-width: ${MIN_WIDTH};
  width: 100%;
`;

export const AdminHomePage = () => {
  return (
    <StyledAdminHomePage>
      <AdminDashboard />
    </StyledAdminHomePage>
  );
};
