import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import {
  darkPurple,
  grey,
  red,
  trueWhite,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledDragAndDropFileUpload = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;

export const StyledDropzone = styled.div`
  align-items: center;
  background: ${grey[50]};
  border: 1px dashed ${grey[200]};
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: ${rhOpsSpacingPx(2.5)};
  text-align: center;
`;

export const StyledUploadIconContainer = styled.div`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const StyledDragAndDropSubtile = styled(Body2)`
  color: ${grey[500]};
`;

export const StyledDragAndDropFileFormat = styled(Body3)`
  color: ${grey[400]};
  margin-top: ${rhOpsSpacingPx(1)};
`;

export const StyledFileNameContainer = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(1)};

  > svg {
    color: ${trueWhite};
    fill: ${darkPurple[500]};
  }
`;

export const StyledDeleteFileButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${red.main};
  cursor: pointer;
  font: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: inherit;
  text-transform: none;
`;
