import { EdiActions } from "@common/constants/edi.constants";
import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import {
  BoButton,
  BoButtonProps,
} from "@ops-design-system/components/BoButton/BoButton";
import { ReactComponent as XInCircle } from "@ops-design-system/icons/XInCircle.svg";
import { EdiActionTriggerWithConfirmation } from "@ops/components/EdiActionTriggerWithConfirmation/EdiActionTriggerWithConfirmation";
import React from "react";

interface CancelMoveOutProps {
  currentPremise: Premise;
  customer: Customer;
}

const CancelMoveOutButton = (
  props: Pick<BoButtonProps, "onClick" | "disabled">
) => {
  return (
    <BoButton icon={XInCircle} variant="secondary" size="extraSmall" {...props}>
      Cancel Move Out
    </BoButton>
  );
};

export const CancelMoveOut = ({
  customer,
  currentPremise,
}: CancelMoveOutProps) => {
  if (
    currentPremise.isMoveOutRequested ||
    currentPremise.isMoveOutDateChangeRequested ||
    currentPremise.isMoveOutAccepted
  ) {
    return (
      <EdiActionTriggerWithConfirmation
        action={EdiActions.MOVE_OUT_CANCEL}
        buttonIcon={XInCircle}
        confirmBtnText="Cancel Move Out"
        confirmMessage="Are you sure you want to cancel move out?"
        customerId={customer.id}
        errorMessage="There was a problem sending the cancel move out.  Please try again."
        header="Cancel Move Out"
        triggerOpenDialogBtnText="Cancel Move Out"
      />
    );
  }

  return <CancelMoveOutButton disabled />;
};
