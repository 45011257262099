import { StyledPre } from "@common/components/Version/Version.style";
import { EnvTypes } from "@common/enums/envTypes.enum";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import preval from "babel-plugin-preval/macro";
import dayjs from "dayjs";
import React from "react";

// This `preval` allows us to execute code at build time - think of it as
// javascript `eval` during the build.
// This allows us to stamp a built distribution with the build time.
// We might later use it to read a version number or SHA
const BUILD_TIMESTAMP = preval`
  module.exports = new Date().getTime();
`;

const GIT_SHA = preval`
  const { execSync } = require('child_process');
  module.exports = execSync('git log -1 --pretty="format:%h %s"', {
    encoding: 'utf-8',
  }).replace('\\n', "");
`;

const formatBuildTimestamp = () => {
  const lastUpdateMoment = dayjs.unix(BUILD_TIMESTAMP / 1000);

  return lastUpdateMoment.format("YYYY-MM-DD HH:mm:ss");
};

interface VersionProps {
  handleClick?: () => void;
}

const Version = (props: VersionProps) => {
  const { handleClick } = props;
  const isVisible = getEnv() !== EnvTypes.production;

  const version =
    `Env: ${getEnv()}  |  ` +
    `SHA: ${GIT_SHA}  |  ` +
    `Build Time: ${formatBuildTimestamp()}`;

  return (
    <StyledPre $isVisible={isVisible} onClick={handleClick}>
      {version}
    </StyledPre>
  );
};

export { Version };
