import { rewardsApi } from "@common/api/rewardsApi";
import { IdType } from "@common/types/apiTypes";
import { RewardsResponseType } from "@common/types/rewardsTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { CustomerRewardsHistoryTable } from "@ops/components/CustomerRewardsHistoryTable/CustomerRewardsHistoryTable";
import { RewardBalanceCard } from "@ops/components/RewardBalanceCard/RewardBalanceCard";
import { useCustomer } from "@ops/hooks/useCustomer";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const RewardsTab = () => {
  const { id } = useParams<{ id: IdType }>();
  const { customer } = useCustomer(id);

  const [rewards, setRewards] = useState<RewardsResponseType | null>(null);

  const fetchRewards = useCallback(() => {
    if (customer?.id) {
      return rewardsApi.customers.retrieve(customer?.id).then(setRewards);
    }
  }, [customer?.id]);

  useEffect(() => {
    if (customer?.id) {
      fetchRewards();
    }
  }, [customer?.id]);

  if (!customer || !rewards) {
    return <BoCircularProgress />;
  }

  return (
    <>
      <RewardBalanceCard
        customer={customer}
        rewards={rewards}
        onAddRewardsUpdate={fetchRewards}
        onRedeemRewardsUpdate={fetchRewards}
      />
      <CustomerRewardsHistoryTable rewards={rewards} />
    </>
  );
};
