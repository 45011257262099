import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { ReactComponent as FlyingDollarSign } from "@ops-design-system/icons/FlyingDollarSign.svg";
import React from "react";

interface AddBankAccountButtonProps {
  onClick: () => void;
}

export const AddBankAccountButton = ({
  onClick,
}: AddBankAccountButtonProps) => {
  return (
    <BoButton variant="secondary" onClick={onClick} icon={FlyingDollarSign}>
      Add Bank Account
    </BoButton>
  );
};
