import { z } from "zod";

export const offersStepSchema = z.object({
  offerSnapshotId: z.string().min(1),
});

export const billingPreferencesStepSchema = z.object({
  paperless: z.boolean().optional(),
});

export const confirmationStepSchema = z.object({
  confirmRenewal: z.boolean(),
});

export const contractRenewalWizardSchema = z.object({
  ...offersStepSchema.shape,
  ...billingPreferencesStepSchema.shape,
  ...confirmationStepSchema.shape,
});

export type ContractRenewalWizardFormStateType = z.infer<
  typeof contractRenewalWizardSchema
>;

export const contractRenewalWizardSubmissionSchema = z
  .object({
    ...contractRenewalWizardSchema.shape,
    paperless: z.boolean(),
  })
  .refine((data) => data.confirmRenewal === true, {
    message: "Confirmation required before submission",
  })
  .refine((data) => data.offerSnapshotId !== "", {
    message: "Offer snapshot missing",
  });
