import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import {
  BoButton,
  BoButtonProps,
} from "@ops-design-system/components/BoButton/BoButton";
import { ReactComponent as ExclamationPoint } from "@ops-design-system/icons/ExclamationPoint.svg";
import { ServiceEndDateFormModal } from "@ops/components/CancellationActionsCard/ServiceEndDateFormModal/ServiceEndDateFormModal";
import React from "react";

interface UpdateServiceEndProps {
  currentPremise: Premise;
  customer: Customer;
}

const UpdateServiceEndButton = (
  props: Pick<BoButtonProps, "onClick" | "disabled">
) => {
  return (
    <BoButton
      icon={ExclamationPoint}
      variant="secondary"
      size="extraSmall"
      {...props}
    >
      Update Service End
    </BoButton>
  );
};

export const UpdateServiceEnd = ({
  currentPremise,
  customer,
}: UpdateServiceEndProps) => {
  const { open, close, isOpen } = useModalState();

  if (currentPremise.canUpdateMoveOutEndDate) {
    return (
      <>
        <UpdateServiceEndButton onClick={open} />
        <ServiceEndDateFormModal
          header="Update Service End"
          open={isOpen}
          onClose={close}
          premise={currentPremise}
          customer={customer}
        />
      </>
    );
  }

  return <UpdateServiceEndButton disabled />;
};
