import { EM_DASH } from "@common/constants/characters.constant";
import { AcquisitionCampaignType } from "@common/types/campaignTypes";
import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import {
  BoSelectOptionType,
  BoSelectValue,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { ProspectType } from "@ops-utils/types/prospectTypes";
import { ProspectEditorForm } from "@ops/components/ProspectEditor/ProspectEditorForm";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useAcquisitionCampaignsInState } from "@ops/hooks/useAcquisitionCampaignsInState";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";

export const AcquisitionCampaignForm = () => {
  const { prospect, updateProspectState } = useEnrollmentFlowContext();
  const [selectedAcquisitionCampaign, setSelectedAcquisitionCampaign] =
    useState<BoSelectValue>();
  const flash = useRhFlash();
  const {
    data: acquisitionCampaigns,
    requestMonitor,
    fetch,
  } = useAcquisitionCampaignsInState();

  const [acquisitionCampaignOptions, setAcquisitionCampaignOptions] = useState<
    BoSelectOptionType[]
  >([]);

  const findCampaignBySlug = (
    slug: string,
    campaignList: AcquisitionCampaignType[]
  ) => {
    return campaignList.find((item) => slug === item.slug);
  };

  useEffect(fetch, [fetch]);

  useEffect(() => {
    if (prospect && acquisitionCampaigns) {
      const activeAcquisitionCampaigns = acquisitionCampaigns.filter(
        (acqCamp) => acqCamp.active
      );
      const options = activeAcquisitionCampaigns.map(
        ({ name, phone, slug: value }) => ({
          label: `${name} ${formatPhoneNumber(phone)}`,
          value,
        })
      );
      const existingSlug = prospect?.acquisitionCampaign ?? "";
      const existingSlugNotPresentInActiveList = !findCampaignBySlug(
        existingSlug,
        activeAcquisitionCampaigns
      );

      if (existingSlugNotPresentInActiveList && existingSlug !== "") {
        const { name, phone } =
          findCampaignBySlug(existingSlug, acquisitionCampaigns) ?? {};

        options.unshift({
          label:
            name && phone
              ? `${name} ${formatPhoneNumber(
                  phone
                )} ${EM_DASH} Inactive, please choose an active campaign`
              : `${existingSlug} ${EM_DASH} Outside sales channel`,
          value: existingSlug,
        });
      }

      if (prospect.acquisitionMedium === "broker") {
        options.unshift({
          label: `${prospect.acquisitionMedium}`,
          value: `${existingSlug}`,
        });
      }

      setAcquisitionCampaignOptions(options);
      setSelectedAcquisitionCampaign(existingSlug);
    }
  }, [prospect, acquisitionCampaigns]);

  if (requestMonitor.didFail) {
    flash.error("Could not retrieve list of Acquisition Campaigns");

    return null;
  }

  const onSubmit = ({
    acquisitionCampaign: givenAcquisitionCampaignSlug,
  }: Partial<ProspectType>) => {
    const prospectPayload: Partial<ProspectType> = {
      acquisitionCampaign: givenAcquisitionCampaignSlug as string,
    };

    if (acquisitionCampaigns) {
      const targetAcquisitionCampaign = findCampaignBySlug(
        givenAcquisitionCampaignSlug as string,
        acquisitionCampaigns
      );

      if (prospect.acquisitionMedium !== "broker") {
        if (targetAcquisitionCampaign) {
          if (prospect.rcid !== targetAcquisitionCampaign.pricingCampaignSlug) {
            prospectPayload.offersnapshotId = null;
          }
          prospectPayload.rcid = targetAcquisitionCampaign.pricingCampaignSlug
            ? targetAcquisitionCampaign.pricingCampaignSlug
            : null;
          prospectPayload.acquisitionMedium =
            targetAcquisitionCampaign.salesChannel;
          prospectPayload.acquisitionSource = targetAcquisitionCampaign.phone;
          updateProspectState(prospectPayload);
        }
      } else {
        updateProspectState(prospect);
      }
    }
  };

  return requestMonitor.isIdle ||
    requestMonitor.isPending ||
    !acquisitionCampaigns ||
    !acquisitionCampaignOptions ? (
    <BoCard>
      <BoCircularProgress position="relative" />
    </BoCard>
  ) : (
    <Form
      onSubmit={onSubmit}
      initialValues={{ acquisitionCampaign: selectedAcquisitionCampaign }}
      render={() => {
        return (
          <ProspectEditorForm id={TabType.marketing}>
            <BoCard>
              <BoSelectField
                name="acquisitionCampaign"
                label="Campaign"
                options={acquisitionCampaignOptions}
              />
            </BoCard>
          </ProspectEditorForm>
        );
      }}
    />
  );
};
