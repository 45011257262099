import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { AddressFormType } from "@common/types/customerTypes";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { AddressFormFields } from "@ops/components/MeterSearchForms/AddressFormFields";
import { WizardComponentName } from "@ops/components/TransferServiceWizard/TransferServiceWizard.types";
import { TransferServiceWizardForm } from "@ops/components/TransferServiceWizard/TransferServiceWizardForm";
import { isValidUSAddress } from "@ops/forms/validators";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

export interface MailingAddressFormValues {
  mailingAddress: AddressFormType;
}

const formValidators =
  generateValidationErrorCollector<MailingAddressFormValues>({
    mailingAddress: isValidUSAddress,
  });

const Container = styled.div`
  width: 480px;
`;

export const MailingAddressForm = () => {
  const {
    tosWizardState: { mailingAddress, dunsNumber },
    updateTosWizardState,
    setActiveComponentName,
    premise,
  } = useTransferServiceWizardContext();

  const onSubmit = ({
    mailingAddress: givenMailingAddress,
  }: MailingAddressFormValues) => {
    updateTosWizardState({ mailingAddress: { ...givenMailingAddress } });

    if (premise.currentOrder?.dunsNumber !== dunsNumber) {
      setActiveComponentName(WizardComponentName.offerForm);
    } else {
      setActiveComponentName(WizardComponentName.movingForm);
    }
  };

  return (
    <Form<MailingAddressFormValues>
      validate={formValidators}
      initialValues={{ mailingAddress }}
      validateOnChange
      onSubmit={onSubmit}
      render={() => {
        return (
          <TransferServiceWizardForm
            id={WizardComponentName.mailingAddressForm}
          >
            <Container>
              <FormRow>
                <Subtitle2 $fontWeight="Bold">New mailing address</Subtitle2>
              </FormRow>
              <AddressFormFields groupName="mailingAddress" />
            </Container>
          </TransferServiceWizardForm>
        );
      }}
    />
  );
};
