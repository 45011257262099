import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { ZuoraAddBankAccountForm } from "@ops/components/ZuoraAddBankAccountForm/ZuoraAddBankAccountForm";
import React from "react";

interface AddBankAccountProps {
  onCancel: () => void;
  onSuccess: () => void;
  premiseId: IdType;
  setAsDefault?: boolean;
}

export const AddBankAccountForm = ({
  onCancel,
  onSuccess,
  premiseId,
  setAsDefault,
}: AddBankAccountProps) => {
  const flash = useRhFlash();

  const handleFailure = (errorMessage: string) => {
    flash.error(errorMessage);
  };

  const handleSuccess = () => {
    flash.success("Successfully added new bank account");
    onSuccess();
  };

  return (
    <>
      <BoDialogHeader>Add Bank Account</BoDialogHeader>
      <BoDialogBody>
        <ZuoraAddBankAccountForm
          onFailure={handleFailure}
          onSuccess={handleSuccess}
          premiseId={premiseId}
          setAsDefault={setAsDefault}
        />
      </BoDialogBody>
      <BoDialogButtonFooter cancelBtnText="Cancel" onCancel={onCancel} />
    </>
  );
};
