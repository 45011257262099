import { WinbackWizardStateType } from "@ops/components/WinbackWizard/winbackWizard.types";

export const initialWinbackWizardState: WinbackWizardStateType = {
  contractOption: null,
  enrollmentType: null,
  offerSnapshotId: "",
  paperless: true,
  priorityEnrollment: false,
  startDate: null,
};
