import { premiseApi } from "@common/api/premiseApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { ExternalPaymentMethod } from "@common/models/ExternalPaymentMethod.model";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { Premise } from "@common/models/Premise.model";
import { PaymentListResponseType } from "@common/types/apiResponseTypes";
import {
  ExternalPaymentMethodType,
  PaymentMethodType,
  PaymentTypes,
} from "@common/types/paymentMethodTypes";
import { SelectablePaymentHistoryType } from "@common/types/premiseTypes";
import {
  formatCurrency,
  formatMonthDayShortYear,
  separateWordByUppercase,
} from "@common/utils/dataFormatters";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import {
  EmptyPaymentList,
  StyledPaymentTable,
  StyledPaymentTableHeader,
} from "@ops/components/PaymentTable/PaymentTable.styled";
import React, { useEffect } from "react";
import styled from "styled-components";

interface PaymentTableProps {
  header: string;
  onSelect?: (payment: SelectablePaymentHistoryType) => void;
  premise: Premise;
  selectable?: boolean;
}

interface PaymentTableRowProps {
  onSelect?: (payment: SelectablePaymentHistoryType) => void;
  payment: SelectablePaymentHistoryType;
  selectable?: boolean;
}

const CheckboxContainer = styled.div`
  margin-left: -12px;
`;

const NoWrapBoTableCell = styled(BoTableCell)`
  white-space: nowrap;
`;

const PaymentTableRow = ({
  payment,
  selectable,
  onSelect,
}: PaymentTableRowProps) => {
  const { amount, effectiveDate } = payment;
  const handleRowSelect = () => {
    const toggledPayment = payment;

    toggledPayment.selected = !toggledPayment.selected;
    return onSelect ? onSelect(toggledPayment) : null;
  };

  return (
    <BoTableRow>
      {selectable && (
        <BoTableCell $align="right">
          <CheckboxContainer>
            <BoCheckboxInput
              name={`selectPayment${payment.number}`}
              id={`selectPayment${payment.number}`}
              onChange={() => handleRowSelect()}
              checked={payment.selected}
            />
          </CheckboxContainer>
        </BoTableCell>
      )}
      <BoTableCell $align="right">
        {formatMonthDayShortYear(effectiveDate)}
      </BoTableCell>
      <BoTableCell $align="right">{formatCurrency(amount)}</BoTableCell>
      <NoWrapBoTableCell $align="center">
        {payment.type !== PaymentTypes.EXTERNAL
          ? new PaymentMethod(
              payment.paymentMethodSnapshot as PaymentMethodType
            ).companyNameAndNumber
          : separateWordByUppercase(
              new ExternalPaymentMethod(
                payment.paymentMethodSnapshot as ExternalPaymentMethodType
              ).type
            )}
      </NoWrapBoTableCell>
      <BoTableCell $align="center">{payment.paymentSource}</BoTableCell>
      <NoWrapBoTableCell $align="center">{payment.number}</NoWrapBoTableCell>
    </BoTableRow>
  );
};

export const PaymentTable = ({
  premise,
  header,
  selectable,
  onSelect,
}: PaymentTableProps) => {
  const [
    { data: paymentList, requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<PaymentListResponseType>();

  useEffect(() => {
    setPending();

    if (premise.id) {
      premiseApi.payments.list(premise.id).then(setSuccess).catch(setFailure);
    } else {
      setSuccess([]);
    }
  }, [premise.id]);

  if (requestMonitor.isWaiting) {
    return (
      <>
        <StyledPaymentTableHeader $fontWeight="SemiBold">
          {header}
        </StyledPaymentTableHeader>
        <EmptyPaymentList>
          <BoCircularProgress />
        </EmptyPaymentList>
      </>
    );
  }

  return (
    <>
      <StyledPaymentTableHeader $fontWeight="SemiBold">
        {header}
      </StyledPaymentTableHeader>
      {paymentList?.length ? (
        <StyledPaymentTable>
          <BoTableHead>
            <BoTableRow>
              {selectable && <BoTableHeadCell aria-label="Checkbox Header" />}
              <BoTableHeadCell>Payment Date</BoTableHeadCell>
              <BoTableHeadCell>Payment Amount</BoTableHeadCell>
              <BoTableHeadCell>Payment Method</BoTableHeadCell>
              <BoTableHeadCell>Payment Source</BoTableHeadCell>
              <BoTableHeadCell aria-label="Zuora Payment Number">
                Payment ID
              </BoTableHeadCell>
            </BoTableRow>
          </BoTableHead>
          <BoTableBody>
            {paymentList?.map((payment) => {
              return (
                <PaymentTableRow
                  payment={payment}
                  selectable={selectable}
                  onSelect={onSelect}
                  key={`${payment.amount}-${
                    payment.paymentMethodSnapshot
                      ? payment.paymentMethodSnapshot.id
                      : "external"
                  }-${payment.effectiveDate}`}
                />
              );
            })}
          </BoTableBody>
        </StyledPaymentTable>
      ) : (
        <EmptyPaymentList>
          This premise has no previous payments.
        </EmptyPaymentList>
      )}
    </>
  );
};
