import { uniq } from "@common/utils/arrayUtils";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { FormEvent } from "react";
import { useForm, useFormState } from "react-final-form";

const generateErrors = (
  formErrors: Record<string, Record<string, unknown> | string[]>,
  generatedErrors: { fieldNames: string[]; messages: string[] } = {
    fieldNames: [],
    messages: [],
  },
  parentKey: string = ""
) => {
  for (const [key, value] of Object.entries(formErrors)) {
    const completeKey = `${parentKey ? `${parentKey}.` : ""}${key}`;

    if (value.length) {
      generatedErrors.fieldNames.push(completeKey);
      // eslint-disable-next-line no-param-reassign
      generatedErrors.messages = [
        ...generatedErrors.messages,
        ...(value as string[]),
      ];
    } else {
      generateErrors(
        value as Record<string, string[]>,
        generatedErrors,
        completeKey
      );
    }
  }

  return generatedErrors;
};

export const useWizardForm = (onSuccess: () => void = () => {}) => {
  const { hasValidationErrors, errors: formErrors } = useFormState();
  const { submit, blur } = useForm();
  const flash = useRhFlash();

  const errors = generateErrors(
    formErrors as Record<string, Record<string, unknown> | string[]>
  );
  const validationErrorMessages =
    errors && uniq<string>(errors.messages).join(" ");

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (hasValidationErrors) {
      flash.error(validationErrorMessages);
      errors.fieldNames.forEach((field) => blur(field));

      return;
    }

    onSuccess();

    submit();
  };

  return {
    onSubmit,
  };
};
