import { DateType, IdType } from "@common/types/apiTypes";
import { AddressType } from "@common/types/customerTypes";

export interface ProspectValidationReportCustomerType {
  dateOfBirth: DateType;
  email: string;
  firstName: string;
  id: IdType;
  lastName: string;
  phone: string;
}

export interface ProspectValidationReportPremiseType {
  billDue: string;
  billingAccountNumber: IdType;
  esiId: IdType;
  id: IdType;
  pastDueBalance: string;
  serviceAddress: AddressType;
  serviceEndDate: DateType;
  status: string;
}

export enum MatchCriteria {
  EMAIL = "Email",
  ESIID = "ESIID",
  LASTNAMEANDDOB = "Last Name and Date of Birth",
  PHONENUMBER = "Phone Number",
}

export enum PremiseMatchCollisionStatusLevels {
  ERROR = "Error",
  WARNING = "Warning",
}

export interface ProspectValidationReportType {
  customer: ProspectValidationReportCustomerType;
  matchCriteria: MatchCriteria[];
  premise: ProspectValidationReportPremiseType;
  premiseMatchCollisionStatusLevel: PremiseMatchCollisionStatusLevels;
}
