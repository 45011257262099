import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const TableHeader = styled(Body2).attrs({
  as: "div",
})`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const EmptyStateMessage = styled(BoCard).attrs({ as: "div" })`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100px;
`;

export const EmptyRowMessage = styled(BoCard).attrs({ as: "div" })`
  align-items: center;
  border: 0px;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  min-height: 6px;
  padding: 0px;
`;

export const DownloadDnpLink = styled(BoLink)`
  color: ${grey["500"]};
`;
