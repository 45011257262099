import { isNil } from "lodash";

export const findClosest = (arr: number[], val: number): number =>
  [...arr].sort().reduce((prev, curr) => {
    return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
  });

export const intersect = <T>(arrA: T[], arrB: T[]): T[] => {
  return arrA.filter((x) => arrB.includes(x));
};

type AllowedPrimitives = number | boolean | string;
export const uniq = <T extends AllowedPrimitives>(
  array: Array<T>
): Array<T> => {
  return array.sort().filter((item, position, arr) => {
    return !position || item !== arr[position - 1];
  });
};

export function isDefined<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return !isNil(value);
}
