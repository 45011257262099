import styled, { css } from "styled-components";

export const BoTextButton = styled.button`
  background: none;
  border: none;
  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
        `
      : ""};
`;
