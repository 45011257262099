import {
  MatchCriteria,
  PremiseMatchCollisionStatusLevels,
  ProspectValidationReportCustomerType,
  ProspectValidationReportPremiseType,
  ProspectValidationReportType,
} from "@ops-utils/types/prospectValidationReportTypes";

export class ProspectValidationReport {
  public readonly customer: ProspectValidationReportCustomerType;
  public readonly premise: ProspectValidationReportPremiseType;
  public readonly matchCriteria: MatchCriteria[];
  public readonly premiseMatchCollisionStatusLevel: PremiseMatchCollisionStatusLevels;

  constructor(prospectValidationReport: ProspectValidationReportType) {
    this.customer = prospectValidationReport.customer;
    this.premise = prospectValidationReport.premise;
    this.matchCriteria = prospectValidationReport.matchCriteria;
    this.premiseMatchCollisionStatusLevel =
      prospectValidationReport.premiseMatchCollisionStatusLevel;
  }
}
