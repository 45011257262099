import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import {
  BoHashTab,
  BoTabs,
} from "@ops-design-system/components/BoHashTab/BoHashTab";
import {
  TabType,
  prospectEditorTabs,
  tabDisplayNames,
} from "@ops/components/ProspectEditorTabs/configuration";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { getTabFromLocation } from "@ops/utils/getTabFromLocation";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ProspectEditorTabs = () => {
  const { featureFlagClient } = useFeatureFlagClient();
  const location = useLocation();
  const { currentTabComponent, setCurrentTabComponent } =
    useEnrollmentFlowContext();

  const { opsProductAddOnsEnrollment } = featureFlagClient.getFlags([
    ["opsProductAddOnsEnrollment", false],
  ]);

  useEffect(() => {
    const hash = getTabFromLocation<TabType>(location);

    if (location.hash && prospectEditorTabs.includes(hash)) {
      setCurrentTabComponent(hash);
    }
  }, []);

  return (
    <BoTabs>
      {prospectEditorTabs.map((tabName) => {
        if (
          tabName === TabType.productAddons &&
          !opsProductAddOnsEnrollment.value
        ) {
          return null;
        }

        return (
          <BoHashTab
            key={tabName}
            hashkey={tabName}
            name={tabDisplayNames[tabName]}
            onClick={() => setCurrentTabComponent(tabName)}
            active={currentTabComponent === tabName}
          />
        );
      })}
    </BoTabs>
  );
};
