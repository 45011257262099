import { ediUrls } from "@common/api/ediUrls";
import { ajax } from "@common/services/ajax";
import {
  CopyEdiMessageRequestType,
  EnrollmentStatusesResponseType,
  MeterAvailabilityResponseType,
  MoveEdiMessageRequestType,
  ReprocessInvlUsgmMessageRequestType,
  ReprocessInvlUsgmMessageResponseType,
} from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  MeterLookupType,
  UtilitySearchRequestType,
  UtilitySearchResponseType,
  UtilityType,
} from "@common/types/ediTypes";
import { MeterType } from "@common/types/meterTypes";

export const ediApi = {
  ediMessage: {
    copy: (data: CopyEdiMessageRequestType): Promise<void> => {
      return ajax.post(ediUrls.ediMessage.copy(), data);
    },
    move: (data: MoveEdiMessageRequestType): Promise<void> => {
      return ajax.post(ediUrls.ediMessage.move(), data);
    },
  },
  getEnrollmentStatuses: (): Promise<EnrollmentStatusesResponseType> => {
    return ajax.get(ediUrls.enrollmentStatuses());
  },
  getServiceStartDates: (
    offerSnapshotId?: IdType,
    useMinimumDate?: boolean
  ): Promise<string[]> => {
    return ajax.get(ediUrls.serviceStartDates(), {
      params: { offerSnapshotId, useMinimumDate },
    });
  },
  invoiceMatches: {
    reprocessInvlUsgmMessage: (
      data: ReprocessInvlUsgmMessageRequestType
    ): Promise<ReprocessInvlUsgmMessageResponseType> => {
      return ajax.post(ediUrls.invoiceMatches.reprocessInvlUsgmMessage(), data);
    },
  },
  meter: {
    retrieve: (esiId: string): Promise<MeterType> => {
      return ajax.get(ediUrls.meter.retrieve(esiId));
    },
  },
  meterAvailability: (
    params: Partial<MeterLookupType> | { esiId: string }
  ): Promise<MeterAvailabilityResponseType> => {
    return ajax.get(ediUrls.meterAvailability(), {
      params,
    });
  },
  utilities: {
    index: (): Promise<UtilityType[]> => {
      return ajax.get(ediUrls.utilities.index());
    },
    search: (
      queryParams?: UtilitySearchRequestType
    ): Promise<UtilitySearchResponseType> => {
      return ajax.get(ediUrls.utilities.search(), {
        params: queryParams ?? {},
      });
    },
  },
};
