import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import {
  BoInfoContainer,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledCard = styled(BoCard)<{
  $showRenewalPeriodWarning?: boolean;
}>`
  border-color: ${({ $showRenewalPeriodWarning }) =>
    $showRenewalPeriodWarning ? red[400] : grey[100]};
`;

export const UnavailableMessage = styled(Body2)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledBoInfoValue = styled(BoInfoValue)`
  align-items: center;
  display: flex;
  gap: ${rhOpsSpacingPx(0.5)};
`;

export const StyledWarningIcon = styled.div`
  path {
    fill: ${red[400]};
  }
`;

export const StyledEndDateContainer = styled(BoInfoContainer)<{
  $showRenewalPeriodWarning?: boolean;
}>`
  color: ${({ $showRenewalPeriodWarning }) =>
    $showRenewalPeriodWarning ? red[400] : grey[900]};
  display: flex;
  flex-direction: column;
`;
