import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import {
  StyledBillBreakdownGraphContainer,
  StyledBillBreakdownGraphTitle,
} from "@ops/components/BillBreakdownGraph/BillBreakdownGraph.styled";
import { BillBreakdownGraphBody } from "@ops/components/BillBreakdownGraph/BillBreakdownGraphBody";
import React from "react";

interface BillBreakdownGraphProps {
  invoiceId: string;
}

export const BillBreakdownGraph = (props: BillBreakdownGraphProps) => {
  const { invoiceId } = props;

  return (
    <StyledBillBreakdownGraphContainer>
      <StyledBillBreakdownGraphTitle>
        Energy Usage + Temperature
      </StyledBillBreakdownGraphTitle>
      <Subtitle2>
        Here&apos;s how Mother Nature behaved during your last billing period,
        and how it may have impacted your usage.
      </Subtitle2>
      <BillBreakdownGraphBody invoiceId={invoiceId} />
    </StyledBillBreakdownGraphContainer>
  );
};
