import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  BoModal,
  BoModalProps,
} from "@ops-design-system/components/BoModal/BoModal";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { customerUpdated } from "@ops/slices/customerSlice";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface CustomerEmployeeIndicatorModalProps
  extends Pick<BoModalProps, "open" | "onClose"> {
  customer: Customer;
}

interface EditIsEmployeeFormValues {
  isEmployee: boolean;
}

export const CustomerEmployeeIndicatorModal = ({
  onClose = () => {},
  open,
  customer,
}: CustomerEmployeeIndicatorModalProps) => {
  const flash = useRhFlash();
  const dispatch = useDispatch();
  const [{ requestMonitor }, { setFailure, setPending, setSuccess }] =
    useAjaxState<boolean>();
  const [isEmployee, setIsEmployee] = useState<boolean>(customer.isEmployee);

  const handleSubmit = async (formValues: EditIsEmployeeFormValues) => {
    setPending();
    const [error, updatedCustomer] = await handleAjaxCall(
      api.customers.update(customer.id, formValues)
    );

    if (error) {
      setFailure();
      flash.error(
        "Failed to update Employee Indicator due to an internal error. Please try again."
      );
      return;
    }

    setSuccess(true);
    dispatch(customerUpdated(updatedCustomer as CustomerType));
    flash.success("Successfully updated Employee Indicator");
    onClose();
  };

  const isFormValid = isEmployee !== customer.isEmployee;

  return (
    <BoModal size="default" open={open} onClose={onClose}>
      <Form<EditIsEmployeeFormValues>
        onSubmit={handleSubmit}
        initialValues={{ isEmployee }}
        render={({ handleSubmit: handleFormSubmit }) => (
          <form onSubmit={handleFormSubmit}>
            <BoDialogHeader>Customer Indicator</BoDialogHeader>
            <BoDialogBody>
              <BoLabel key={customer.id}>
                <BoCheckboxInput
                  name="Employee"
                  onChange={() => setIsEmployee(!isEmployee)}
                  defaultChecked={customer.isEmployee}
                />
                <Body1>Employee</Body1>
              </BoLabel>
            </BoDialogBody>
            <BoDialogButtonFooter
              confirmLoading={requestMonitor.isPending}
              confirmDisabled={!isFormValid}
              confirmBtnText="Update"
              confirmBtnType="submit"
              cancelBtnText="Cancel"
              onCancel={onClose}
            />
          </form>
        )}
      />
    </BoModal>
  );
};
