import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { AddPremiseWizard } from "@ops/components/AddPremiseWizard/AddPremiseWizard";
import { TransferServiceWizard } from "@ops/components/TransferServiceWizard/TransferServiceWizard";
import React from "react";

interface ServiceChangesCardProps {
  customer: Customer;
  premise: Premise;
}

export const ServiceChangesCard = ({
  premise,
  customer,
}: ServiceChangesCardProps) => {
  return (
    <>
      <BoCardLabel>Service Changes</BoCardLabel>
      <BoCard>
        <ButtonsGrid>
          <AddPremiseWizard customer={customer} />
          <TransferServiceWizard customer={customer} premise={premise} />
        </ButtonsGrid>
      </BoCard>
    </>
  );
};
