import { IdType } from "@common/types/apiTypes";
import { DeferredPaymentPlanType } from "@common/types/deferredPaymentPlanTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { DeferPaymentManageComponentType } from "@ops/components/DeferPaymentManageWizard/DeferPaymentManageModalStateProvider";
import { useDeferPaymentManageModalState } from "@ops/components/DeferPaymentManageWizard/useDeferPaymentManageModalStateProvider";
import React from "react";
import styled from "styled-components";

const Subheader = styled(Subtitle2).attrs({ as: "div" })``;

const StyledBoGridColumn = styled(BoGridColumn)`
  gap: ${rhOpsSpacingPx(1)};
`;

const StyledBold = styled.span`
  font-weight: ${FontWeight.Bold};
`;
const StyledRed = styled.span`
  color: ${red.main};
`;

interface DeferPaymentManageFormProps {
  activeDeferredPaymentPlan: DeferredPaymentPlanType;
  closeModal: () => void;
  currentBalance: number;
  onSuccess: () => void;
  premiseId: IdType;
}

export const DeferPaymentManageForm = ({
  closeModal,
  onSuccess,
  premiseId,
  activeDeferredPaymentPlan,
  currentBalance,
}: DeferPaymentManageFormProps) => {
  const remainingDPPBalance = Number(activeDeferredPaymentPlan.debitBalance);
  const total = Number(activeDeferredPaymentPlan.debitBalance) + currentBalance;

  const { updateModalState } = useDeferPaymentManageModalState();

  const handleDeferPaymentPayOff = () => {
    updateModalState({
      activeComponent: DeferPaymentManageComponentType.deferPaymentPayOffForm,
    });
  };

  return (
    <>
      <BoDialogHeader>Manage Deferred Payment</BoDialogHeader>
      <BoDialogBody>
        <BoGrid $variant={GridVariants.Grid2_1}>
          <StyledBoGridColumn>
            <Subheader>
              Remaining DPP balance:{" "}
              <StyledRed>{formatCurrency(remainingDPPBalance)}</StyledRed>
            </Subheader>
            <Subheader>
              Current energy balance:{" "}
              <StyledBold>{formatCurrency(currentBalance)}</StyledBold>
            </Subheader>
            <Subheader>
              Total: <StyledBold>{formatCurrency(total)}</StyledBold>
            </Subheader>
            <BoButton
              size="extraSmall"
              variant="secondary"
              onClick={handleDeferPaymentPayOff}
            >
              Pay Off DPP
            </BoButton>
          </StyledBoGridColumn>
        </BoGrid>
      </BoDialogBody>
      <BoDialogButtonFooter cancelBtnText="Close" onCancel={closeModal} />
    </>
  );
};
