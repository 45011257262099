import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledBillBreakdownDetails = styled.div`
  border-bottom: 1px solid ${grey[100]};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: ${rhOpsSpacingPx(3)} 0;
  row-gap: ${rhOpsSpacingPx(3)};
`;

export const StyledBillBreakdownDetailsRow = styled.div`
  display: flex;
  flex-direction: column;
`;
