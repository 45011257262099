import { grey } from "@mui/material/colors";
import {
  Body1,
  Body2,
  H2,
} from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const WinBackConfirmationCenteredRow = styled(FormRow)`
  text-align: center;
`;

export const WinBackConfirmationHeader = styled(H2).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const WinBackConfirmationContainer = styled.div`
  margin: auto;
  width: 680px;
`;

export const WinBackConfirmationSection = styled.div`
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

export const WinBackConfirmationInfoDetailsContainer = styled.div`
  & > label {
    font-weight: ${FontWeight.SemiBold};
  }
`;

export const WinBackConfirmationLabel = styled(Body1).attrs({ as: "label" })``;

export const WinBackConfirmationText = styled(Body1)`
  color: ${grey["600"]};
`;

export const WinBackConfirmationCheckboxContainer = styled.div`
  margin-left: -12px;
`;

export const PaperlessWarningText = styled(Body2)`
  color: ${red.main};
`;

export const AutoPayWarningText = styled(Body2)`
  color: ${red.main};
`;
