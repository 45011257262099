import { darkPurple } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

export const OpsCheckboxInput = styled.input.attrs({ type: "checkbox" })`
  accent-color: ${darkPurple[500]};
  border: 1px solid ${darkPurple[300]};
  border-radius: 2px;
  height: 16px;
  width: 16px;

  & + label {
    font-size: 14px;
  }
`;
