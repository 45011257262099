import { externalPaymentsApi } from "@common/api/externalPaymentsApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { ExternalPaymentFile } from "@common/models/ExternalPayment.model";
import { useCallback, useEffect } from "react";

export const useFidelityPaymentFileUploadsInState = () => {
  const [{ requestMonitor, data }, { setPending, setFailure, setSuccess }] =
    useAjaxState<ExternalPaymentFile[]>();

  const fetch = useCallback(() => {
    if (requestMonitor.isPending) {
      return;
    }

    setPending();

    externalPaymentsApi.files
      .list("Fidelity")
      .then((resp) => {
        setSuccess(
          resp.results.map((respData) => new ExternalPaymentFile(respData))
        );
      })
      .catch(setFailure);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    data,
    fetch,
    requestMonitor,
  };
};
