import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { AuthClient } from "@common/services/AuthClient.service";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { H1 } from "@ops-design-system/components/Typography/Typography";
import { isValidEmail } from "@ops/forms/validators";
import { OKTA_CONFIG } from "@ops/settings/config";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

export interface SignInFormValues {
  email: string;
  password: string;
}

const initialValues = Object.freeze<SignInFormValues>({
  email: "",
  password: "",
});

const signInFormValidator = generateValidationErrorCollector<SignInFormValues>({
  email: [opsAndPricingIsRequired, isValidEmail],
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
  width: 300px;

  & > h1 {
    margin-bottom: 32px;
    text-align: center;
  }

  & > form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > button {
      margin-top: 16px;
    }
  }
`;

const authClient = AuthClient.getInstance(OKTA_CONFIG);

export const SignInPage = () => {
  const flash = useRhFlash();

  const onSubmit = ({ email, password }: SignInFormValues) => {
    return authClient
      .signIn(email, password)
      .catch(() => flash.error("Error logging In"));
  };

  return (
    <Container>
      <H1>Rhythm Ops</H1>
      <Form<SignInFormValues>
        onSubmit={onSubmit}
        initialValues={{ ...initialValues }}
        validate={signInFormValidator}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <BoTextField name="email" autoComplete="email" autoFocus>
              Email
            </BoTextField>
            <BoTextField name="password" type="password">
              Password
            </BoTextField>
            <BoButton type="submit" disabled={submitting}>
              Login
            </BoButton>
          </form>
        )}
      />
    </Container>
  );
};
