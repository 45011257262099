import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { IdType } from "@common/types/apiTypes";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { EnergyUsageChartV2 } from "@ops/components/EnergyUsageChartV2/EnergyUsageChartV2";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";

interface ErrorStateCardProps {
  children: React.ReactNode;
}

const ErrorStateCard = ({ children }: ErrorStateCardProps) => {
  const sectionName = "Energy Usage";

  return (
    <section aria-label={sectionName}>
      <BoCardLabel>{sectionName}</BoCardLabel>
      <BoCard>{children}</BoCard>
    </section>
  );
};

export const EnergyUsageChartHandler = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { featureFlagClient } = useFeatureFlagClient();

  const { premise, requestMonitor } = usePremise(premiseId);

  const { opsUsageChart } = featureFlagClient.getFlags([
    ["opsUsageChart", false],
  ]);

  if (!opsUsageChart.value) {
    return null;
  }

  if (requestMonitor.isPending) {
    return (
      <ErrorStateCard>
        <BoCircularProgress />
      </ErrorStateCard>
    );
  }

  if (!premise || requestMonitor.didFail) {
    return <ErrorStateCard>Unable to get premise data.</ErrorStateCard>;
  }

  if (!premise.meter) {
    return <ErrorStateCard>Unable to get meter data.</ErrorStateCard>;
  }

  if (!premise.meter.isSmt) {
    return <ErrorStateCard>The meter is not smart.</ErrorStateCard>;
  }

  if (!premise.currentOrder) {
    return (
      <ErrorStateCard>Premise does not have an active order.</ErrorStateCard>
    );
  }

  return <EnergyUsageChartV2 />;
};
