import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { AffiliateType } from "@common/types/affiliateType";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  Body1,
  H1,
  H3,
  H4,
} from "@ops-design-system/components/Typography/Typography";
import { AffiliateReferralTable } from "@ops/components/AffiliateReferralTable/AffiliateReferralTable";
import { RafReferralUrl } from "@ops/components/RafReferralUrl/RafReferralUrl";
import { RafRewardHistoryTable } from "@ops/components/RafRewardHistoryTable/RafRewardHistoryTable";
import { RafRewardsSection } from "@ops/components/RafRewardsSection/RafRewardsSection";
import { useAffiliate } from "@ops/hooks/queries/useAffiliate";
import {
  AffiliateContainer,
  AffiliateDetailRewardsContainer,
  FullWidthBoCard,
} from "@ops/pages/AffiliateDetailPage/AffiliateDetailPage.styled";
import { affiliateEditPath } from "@ops/routes/routePaths";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const affiliateDetailTableColumns: (keyof AffiliateType)[] = [
  "id",
  "firstName",
  "lastName",
  "email",
  "phone",
  "addressLine1",
  "unitNumber",
  "city",
  "state",
  "zipCode",
  "organizationName",
  "einTaxId",
];

export const AffiliateDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const affiliateQuery = useAffiliate({ affiliateId: id });

  const goToAffiliateEditPage = () => {
    navigate(affiliateEditPath(id));
  };

  if (affiliateQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (affiliateQuery.isError) {
    return (
      <BoCard>
        <Body1>Unable to fetch affiliate information</Body1>
      </BoCard>
    );
  }

  const affiliate = affiliateQuery.data;

  return (
    <AffiliateContainer>
      <H1>
        Affiliate: {affiliate.firstName} {affiliate.lastName}
      </H1>
      <FullWidthBoCard>
        <H3 $fontWeight="Bold">Referral Url</H3>
        {affiliate.referralCode ? (
          <RafReferralUrl affiliate={affiliate} />
        ) : (
          <Body1>
            This affiliate does not have a referral code. Please try again
            later.
          </Body1>
        )}
      </FullWidthBoCard>
      <FullWidthBoCard>
        <H3>Rewards</H3>
        <AffiliateDetailRewardsContainer>
          <RafRewardsSection affiliateId={affiliate.id} />
          <RafRewardHistoryTable affiliateId={affiliate.id} />
        </AffiliateDetailRewardsContainer>
      </FullWidthBoCard>
      <FullWidthBoCard>
        <H3>Referrals</H3>
        <AffiliateReferralTable affiliateId={affiliate.id} />
      </FullWidthBoCard>
      <FullWidthBoCard>
        {affiliateDetailTableColumns.slice(1).map((column) => (
          <RhFlexBox key={column} my={2}>
            <H3 $fontWeight="Bold">
              {convertCamelCaseToSentenceCase(column)}:
            </H3>
            <H4>{affiliate[column] || NOT_APPLICABLE_ABBREVIATION}</H4>
          </RhFlexBox>
        ))}
      </FullWidthBoCard>

      <BoButton color="primary" onClick={goToAffiliateEditPage}>
        Edit Affiliate
      </BoButton>
    </AffiliateContainer>
  );
};
