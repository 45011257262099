import { z } from "zod";

export const PriceTierSnapshotZod = z.object({
  color: z.string(),
  consumptionPricePerKwh: z.string(),
  expectedVolumePercentage: z.string(),
  name: z.string(),
  scheduleDescription: z.string(),
});

export type PriceTierSnapshotType = z.infer<typeof PriceTierSnapshotZod>;

export const GenerationPriceTierSnapshotZod = z.object({
  color: z.string(),
  expectedVolumePercentage: z.string(),
  generationPricePerKwh: z.string(),
  name: z.string(),
  scheduleDescription: z.string(),
});

export type GenerationPriceTierSnapshotType = z.infer<
  typeof GenerationPriceTierSnapshotZod
>;
