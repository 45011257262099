import { billingApi } from "@common/api/billingApi";
import { useQuery } from "@tanstack/react-query";

export function useFetchRSASignature(zuoraPageId: string) {
  const query = useQuery({
    queryFn: () => billingApi.fetchRSASignature(zuoraPageId),
    queryKey: [`fetch-rsa-signature-${zuoraPageId}`],
    refetchOnWindowFocus: false,
  });

  return query;
}
