import { RhApiError } from "@common/types/errorTypes";

export enum RewardNames {
  AutoPay = "AutoPay",
  AveragedBillingOptIn = "AveragedBillingOptIn",
  CampaignPromo = "CampaignPromo",
  CourtesyCredit = "CourtesyCredit",
  ETFCredit = "ETFCredit",
  ETFWaiver = "ETFWaiver",
  FeeWaiver = "FeeWaiver",
  LateFeeWaiver = "LateFeeWaiver",
  OfferPromo = "OfferPromo",
  OnFlow = "OnFlow",
  Paperless = "Paperless",
  ReRateCredit = "ReRateCredit",
  Referee = "Referee",
  Referral = "Referral",
  RhythmRewards = "RhythmRewards",
  eBill = "eBill",
}

export interface RewardType {
  createdAt: string;
  metadata?: Record<string, string | number>;
  name: RewardNames | null;
  points: number;
  redeemedAt: string | null;
  value: number;
}

export interface RewardsResponseType extends Partial<RhApiError> {
  balance: number;
  history: RewardType[];
  lifetime: number;
  referredCustomers: number;
  value: number;
}

export interface RewardPointsRedemptionResponseType
  extends Partial<RhApiError> {
  remainingBalance: number;
}

export interface RewardRedemptionHistoryType {
  points: number;
  redeemedAt: string;
}

export interface RewardsAddPointsResponseType {
  success: boolean;
}
