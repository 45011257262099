import { blankAddress } from "@common/constants/blankAddress.constant";
import { Customer } from "@common/models/Customer.model";
import { IdType } from "@common/types/apiTypes";
import { createRhContext } from "@common/utils/contextHelpers";
import { BoButtonProps } from "@ops-design-system/components/BoButton/BoButton";
import { MailingAddressFormValues } from "@ops/components/AddPremiseWizard/MailingAddressForm/MailingAddressForm";
import { StartDateFormValues } from "@ops/components/AddPremiseWizard/StartDateForm/StartDateForm";
import { ValidatedAddressType } from "@ops/components/MeterSearchForms/useMeterSearchForms";
import React, { PropsWithChildren, useMemo, useState } from "react";

export enum AddPremiseWizardComponentName {
  addressMeterForm = "newPremiseAddressMeterForm",
  confirmDetails = "confirmDetails",
  mailingAddressForm = "mailingAddressForm",
  offerForm = "offerForm",
  startDateForm = "startDateForm",
}

export interface AddPremiseWizardStateType
  extends ValidatedAddressType,
    MailingAddressFormValues,
    StartDateFormValues {
  offerSnapshotId: IdType;
  validatedAddress?: ValidatedAddressType;
}

export interface AddPremiseWizardContextType {
  activeComponentName: AddPremiseWizardComponentName;
  closeModal: () => void;
  customer: Customer;
  nextButtonProps: Pick<BoButtonProps, "disabled" | "loading">;
  restoreStateToInitialValues: () => void;
  setActiveComponentName: (
    componentName: AddPremiseWizardComponentName
  ) => void;
  setNextButtonProps: (
    props: Pick<BoButtonProps, "disabled" | "loading">
  ) => void;
  setPreviousComponentAsActive: () => void;
  updateWizardState: (payload: Partial<AddPremiseWizardStateType>) => void;
  wizardState: AddPremiseWizardStateType;
}

export const initialAddPremiseWizardState: AddPremiseWizardStateType = {
  enrollmentType: null,
  esiId: "",
  mailingAddress: { ...blankAddress },
  offerSnapshotId: "",
  paperless: true,
  priorityEnrollment: false,
  serviceAddress: { ...blankAddress },
  startDate: null,
};

export const AddPremiseWizardContext =
  createRhContext<AddPremiseWizardContextType>();

export interface AddPremiseWizardStateProviderProps {
  closeModal: () => void;
  customer: Customer;
}

export const AddPremiseWizardProvider = ({
  customer,
  closeModal,
  children,
}: PropsWithChildren<AddPremiseWizardStateProviderProps>) => {
  const [state, setState] = useState<AddPremiseWizardStateType>(
    initialAddPremiseWizardState
  );
  const [activeComponentName, setActiveComponentName] =
    useState<AddPremiseWizardComponentName>(
      AddPremiseWizardComponentName.addressMeterForm
    );
  const [nextButtonProps, setNextButtonProps] = useState<
    Pick<BoButtonProps, "disabled" | "loading">
  >({ disabled: true });
  const [visitedComponents, setVisitedComponents] = useState<
    AddPremiseWizardComponentName[]
  >([]);

  const updateWizardState = (payload: Partial<AddPremiseWizardStateType>) => {
    const existingState = { ...state };

    setState({ ...existingState, ...payload });
  };
  const handleActiveComponentUpdate = (
    componentName: AddPremiseWizardComponentName
  ) => {
    setVisitedComponents([...visitedComponents, activeComponentName]);
    setActiveComponentName(componentName);
  };
  const setPreviousComponentAsActive = () => {
    const [previousComponentName, ...componentNames] =
      visitedComponents.reverse();

    if (previousComponentName) {
      setVisitedComponents(componentNames.reverse());
      setActiveComponentName(previousComponentName);
    }
  };
  const restoreStateToInitialValues = () => {
    setActiveComponentName(AddPremiseWizardComponentName.addressMeterForm);
    setState(initialAddPremiseWizardState);
  };

  const value: AddPremiseWizardContextType = useMemo(
    () => ({
      activeComponentName,
      closeModal,
      customer,
      nextButtonProps,
      restoreStateToInitialValues,
      setActiveComponentName: handleActiveComponentUpdate,
      setNextButtonProps,
      setPreviousComponentAsActive,
      updateWizardState,
      wizardState: state,
    }),
    [
      activeComponentName,
      closeModal,
      customer,
      handleActiveComponentUpdate,
      nextButtonProps,
      setPreviousComponentAsActive,
      state,
      updateWizardState,
    ]
  );

  return (
    <AddPremiseWizardContext.Provider value={value}>
      {children}
    </AddPremiseWizardContext.Provider>
  );
};
