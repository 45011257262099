import { darkPurple } from "@ops-design-system/styles/palette.colors";
import React from "react";
import styled from "styled-components";

export interface BoLinkProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, "ref" | "as"> {
  activeColor?: string;
  color?: string;
  hoverColor?: string;
}

const BoLinkStyled = styled.a<BoLinkProps>`
  color: ${(p) => p.color ?? darkPurple["500"]};
  text-decoration: none;

  &:hover {
    color: ${(p) => p.hoverColor ?? darkPurple["400"]};
    text-decoration: underline;
  }

  &:active {
    color: ${(p) => p.activeColor ?? darkPurple["600"]};
    text-decoration: underline;
  }
`;

export const BoLink = ({ children, ...props }: BoLinkProps) => {
  return <BoLinkStyled {...props}>{children}</BoLinkStyled>;
};
