import { useAjaxState } from "@common/hooks/useAjaxState";
import { AddressForm } from "@common/models/AddressForm.model";
import { RhApiError } from "@common/types/errorTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Body2, H2 } from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { PremiseDetails } from "@ops/components/PremiseDetails/PremiseDetails";
import { PremiseDetailType } from "@ops/components/PremiseDetails/PremiseDetails.types";
import { TransferServiceSubmission } from "@ops/components/TransferServiceWizard/ConfirmTransferDetails/TransferServiceSubmission.service";
import {
  CheckboxContainer,
  CheckboxLabel,
} from "@ops/components/TransferServiceWizard/shared/TransferServiceWizard.styled";
import { WizardComponentName } from "@ops/components/TransferServiceWizard/TransferServiceWizard.types";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import { customerRefetch } from "@ops/slices/customerSlice";
import dayjs from "dayjs";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  margin: auto;
  width: 80%;
`;
const HeaderContainer = styled(FormFlexRow)`
  justify-content: center;
`;
const StyledDivider = styled(BoDivider)`
  margin: ${rhOpsSpacingPx(2.5)} 0;
`;

const errorMessage = (errorCode: string | null) => {
  switch (errorCode) {
    case "rhythm.premise.fields.invalid.expected_end_date_null": {
      return "Service cannot be transferred since the previous order's expected end date is not set.";
    }
    case "rhythm.premise.fields.invalid.order_expired": {
      return "Service cannot be transferred since the previous order has already expired.";
    }
    case "rhythm.premise.order.not_found": {
      return "Service cannot be transferred since the originating premise has no billing order.";
    }
    case "rhythm.premise.fields.invalid.premise_customer_mismatch": {
      return "Premise does not belong to given customer";
    }
    default: {
      return "An error occurred while attempting to transfer service. Please try again later.";
    }
  }
};

export const ConfirmTransferDetails = () => {
  const { customer, premise, tosWizardState, setNextButtonProps, closeModal } =
    useTransferServiceWizardContext();
  const [confirmAgreement, setConfirmAgreement] = useState<boolean>(false);
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();
  const dispatch = useDispatch();
  const flash = useRhFlash();

  useEffect(() => {
    if (requestMonitor.isPending) {
      return;
    }

    if (confirmAgreement) {
      setNextButtonProps({ disabled: false, loading: false });
      return;
    }

    setNextButtonProps({ disabled: true, loading: false });
  }, [confirmAgreement, requestMonitor.isPending]);

  const currentOrderOfferSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: premise.currentOrder?.offersnapshotId ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offersnapshotId),
    },
  });

  const selectedOfferSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: tosWizardState.offerSnapshotId,
  });

  const {
    currentOrder,
    serviceAddress: currentServiceAddress,
    mailingAddress: currentMailingAddress,
  } = premise;

  if (!currentOrder || !currentServiceAddress) {
    // We should NEVER hit this scenario since the button is disabled if there isn't a currentOrder & serviceAddress
    flash.error(
      "Service cannot be transferred since the originating premise has no billing order."
    );
    closeModal();
    return null;
  }

  if (
    currentOrderOfferSnapshotQuery.isPending ||
    selectedOfferSnapshotQuery.isPending
  ) {
    return (
      <Container>
        <BoCircularProgress />
      </Container>
    );
  }

  if (
    currentOrderOfferSnapshotQuery.isError ||
    selectedOfferSnapshotQuery.isError
  ) {
    return (
      <Container>
        <Body2>Failed to fetch offer details</Body2>
      </Container>
    );
  }

  const newServiceAddress = new AddressForm(tosWizardState.serviceAddress);
  const newEsiId = tosWizardState.esiId;
  const newUtility = tosWizardState.utilityName;
  const newMailingAddress = new AddressForm(tosWizardState.mailingAddress);
  const currentOrderOfferSnapshot = currentOrderOfferSnapshotQuery.data;
  const selectedOfferSnapshot = selectedOfferSnapshotQuery.data;

  const newOfferStartDate = tosWizardState.moveIn as string;
  const newOfferEndDateUseTermCalculation =
    selectedOfferSnapshot &&
    (selectedOfferSnapshot.termMonths < 2 ||
      selectedOfferSnapshot.id !== currentOrderOfferSnapshot.id);
  const newOfferEndDate = newOfferEndDateUseTermCalculation
    ? formatMonthDayYear(
        dayjs(tosWizardState.moveIn as string)
          .add(selectedOfferSnapshot.termMonths, "months")
          .toString()
      )
    : formatMonthDayYear(currentOrder.contractEndDate);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submission = new TransferServiceSubmission(tosWizardState, premise);

    if (submission.isValid) {
      setPending();

      setNextButtonProps({ loading: true });

      submission
        .submit()
        .then(() => {
          setSuccess();
          flash.success("Successfully completed transfer of service");
          dispatch(customerRefetch(customer.id));
          closeModal();
        })
        .catch((error: RhApiError) => {
          setFailure();
          flash.error(errorMessage(error.data.errorCode));
        });
      return;
    }

    flash.error(
      `You are missing required data: ${submission.errors.join(",")}`
    );
    setConfirmAgreement(false);
  };

  return (
    <Container>
      <HeaderContainer>
        <H2 $fontWeight="SemiBold">Confirm Transfer Details</H2>
      </HeaderContainer>
      <PremiseDetails
        type={PremiseDetailType.TRANSFER_FROM}
        serviceAddress={currentServiceAddress}
        mailingAddress={currentMailingAddress ?? currentServiceAddress}
        offerSnapshot={currentOrderOfferSnapshot}
        orderStartDate={currentOrder.startDate}
        orderEndDate={currentOrder.contractEndDate}
        paperless={premise.isPaperless}
        autopay={premise.autopay}
        moveDate={tosWizardState.moveOut}
        esiId={premise?.meter?.esiId}
        utilityName={premise?.meter?.utilityName}
      />
      <StyledDivider />
      <PremiseDetails
        type={PremiseDetailType.TRANSFER_TO}
        serviceAddress={newServiceAddress}
        mailingAddress={newMailingAddress}
        offerSnapshot={selectedOfferSnapshot}
        orderStartDate={newOfferStartDate}
        orderEndDate={newOfferEndDate}
        autopay={false} // We will add a autopay state to the wizard soon
        paperless={tosWizardState.paperless}
        moveDate={tosWizardState.moveIn as string}
        esiId={newEsiId}
        utilityName={newUtility}
      />
      <form
        noValidate
        onSubmit={onSubmit}
        id={WizardComponentName.confirmTransferDetails}
      >
        <FormFlexRow>
          <CheckboxContainer $shiftLeft>
            <BoCheckboxInput
              name="confirmAgreement"
              id="confirmAgreement"
              onChange={() => setConfirmAgreement(!confirmAgreement)}
              checked={confirmAgreement}
            />
          </CheckboxContainer>
          <CheckboxLabel $htmlFor="confirmAgreement">
            Was the Transfer of Service script read and did the customer agree
            to the terms and conditions?
          </CheckboxLabel>
        </FormFlexRow>
      </form>
    </Container>
  );
};
