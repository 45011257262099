import { BoTextButton } from "@ops-design-system/components/BoTextButton/BoTextButton";
import { ReactComponent as Pencil } from "@ops-design-system/icons/Pencil.svg";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { MouseEventHandler } from "react";
import styled, { css } from "styled-components";

const StyledEditLink = styled(BoTextButton)`
  color: ${grey["500"]};
  margin-top: -2px;
  padding-left: ${rhOpsSpacingPx(0.5)};
  ${({ disabled }) =>
    disabled
      ? css`
          display: none;
        `
      : ""};
  &:hover {
    color: ${darkPurple["500"]};
  }
  &:active {
    color: ${darkPurple["600"]};
  }
`;

type EditLinkProps = {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const EditLink = (props: EditLinkProps) => (
  <StyledEditLink {...props}>
    <Pencil />
  </StyledEditLink>
);
