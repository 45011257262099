import {
  BoSelectInput,
  BoSelectOptionType,
  BoSelectProps,
  BoSelectValue,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { red } from "@ops-design-system/styles/palette.colors";
import React, { useEffect, useState } from "react";
import { useField } from "react-final-form";
import styled from "styled-components";

interface BoSelectFieldProps extends BoSelectProps {
  isMulti?: boolean;
  label?: string | JSX.Element;
  name: string;
  requiredIndicator?: boolean;
}

const BoSelectWrapper = styled.div`
  width: 100%;
`;
const StyledError = styled(Body2).attrs({ as: "div" })`
  color: ${red.main};
  padding-top: 7px;
`;

export const BoSelectField = ({
  label,
  name,
  options,
  placeholder,
  defaultValue,
  ariaLabel,
  disabled,
  requiredIndicator,
  value,
  variant,
  onChange,
}: BoSelectFieldProps) => {
  const [focused, setFocused] = useState(false);
  const {
    input: { onChange: rffInputOnChange, ...input },
    meta,
  } = useField<BoSelectValue>(name);
  const hasErrors =
    (meta.touched && Boolean(meta.error)) ||
    (meta.submitFailed && Boolean(meta.submitError));
  const labelId = `select-field__${name}`;
  const labelString = ariaLabel ?? labelId;
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useEffect(() => {
    if (value && !options.map((opt) => opt.value).includes(value)) {
      rffInputOnChange(null);
    }
  }, [value, options]);

  const handleOnChange = (option: BoSelectOptionType | null) => {
    rffInputOnChange(option?.value ?? null);
    onChange?.(option);
  };

  const boSelectProps = {
    ...input,
    ariaLabel: labelString,
    defaultValue,
    disabled,
    hasErrors,
    onBlur,
    onChange: handleOnChange,
    onFocus,
    options,
    placeholder,
    value: value ?? input.value,
    variant,
  };

  if (label) {
    return (
      <BoSelectWrapper>
        <BoLabel
          id={labelId}
          htmlFor={name}
          $isFocused={focused}
          $hasErrors={hasErrors}
          requiredIndicator={requiredIndicator}
        >
          {label}
        </BoLabel>
        <BoSelectInput {...boSelectProps} ariaLabelledby={labelId} />
        {hasErrors && (
          <StyledError>{meta.error || meta.submitError}</StyledError>
        )}
      </BoSelectWrapper>
    );
  } else {
    return (
      <>
        <BoSelectInput {...boSelectProps} />
        {hasErrors && !meta.dirtySinceLastSubmit && (
          <StyledError>{meta.error || meta.submitError}</StyledError>
        )}
      </>
    );
  }
};
