import { premiseApi } from "@common/api/premiseApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { AccountSummaryType } from "@common/types/accountSummaryTypes";
import { RedeemRewardsResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { RhApiErrorDetail } from "@common/types/errorTypes";
import { RewardsResponseType } from "@common/types/rewardsTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { StyledButtonContainer } from "@ops/components/RedeemRewardsPoints/RedeemRewardsPoints.styled";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

interface RedeemPointsButtonProps {
  onUpdate: () => void;
  rewards: RewardsResponseType;
}

export const RedeemRewardsPoints = ({
  rewards,
  onUpdate,
}: RedeemPointsButtonProps) => {
  const flash = useRhFlash();
  const { isOpen, open, close } = useModalState();
  const { premiseId } = useParams<{ premiseId: IdType }>();

  const [
    { data: accountSummary, requestMonitor: accountSummaryRequestMonitor },
    {
      setSuccess: setAccountSummarySuccess,
      setPending: setAccountSummaryPending,
      setFailure: setAccountSummaryFailure,
    },
  ] = useAjaxState<AccountSummaryType>();

  const [
    { requestMonitor: redeemRewardsResponseMonitor },
    {
      setSuccess: setRedeemRewardsSuccess,
      setPending: setRedeemRewardsPending,
      setFailure: setRedeemRewardsFailure,
    },
  ] = useAjaxState<RedeemRewardsResponseType>();

  const fetchAccountSummary = () => {
    if (!premiseId) {
      return;
    }
    setAccountSummaryPending();

    return premiseApi.accountSummary
      .retrieve(premiseId)
      .then(setAccountSummarySuccess)
      .catch((err) => {
        setAccountSummaryFailure(err);
        const error = err.data?.errors
          ?.map((e: RhApiErrorDetail) => e.detail)
          .join(".");
        let message =
          "There was a getting the account summary. Please try again later.";

        if (error) {
          message += ` Error: ${error}`;
        }
        flash.error(message);
      });
  };

  const redeemPoints = (rewardsBalance: number) => {
    if (premiseId) {
      setRedeemRewardsPending();

      premiseApi
        .redeemRewards(premiseId, rewardsBalance)
        .then((data) => {
          setRedeemRewardsSuccess(data);
          onUpdate();
          close();
        })
        .catch((err) => {
          setRedeemRewardsFailure(err);
          flash.error(
            "There was a problem redeeming points. Please try again later."
          );
        });
    }
  };

  useEffect(() => {
    if (!premiseId) {
      return;
    }
    fetchAccountSummary();
  }, [premiseId]);

  return (
    <>
      <StyledButtonContainer>
        <BoButton onClick={open} variant="secondary" size="extraSmall">
          Redeem Points
        </BoButton>
      </StyledButtonContainer>
      <BoModal open={isOpen} onClose={close}>
        {(accountSummaryRequestMonitor.isPending ||
          redeemRewardsResponseMonitor.isPending) && (
          <BoCircularProgress position="absolute" />
        )}
        <BoDialogHeader>Redeem Points</BoDialogHeader>
        <BoDialogBody>
          <Subtitle2>
            Amount: {rewards.balance}/
            {formatCurrency(rewardPointsToDollars(rewards.balance))}
          </Subtitle2>
          <br />
          <Subtitle2>
            Current Balance:{" "}
            {accountSummary && formatCurrency(accountSummary.totalBalance)}
          </Subtitle2>
        </BoDialogBody>
        <BoDialogButtonFooter
          confirmBtnText="Apply Points"
          cancelBtnText="Close"
          confirmBtnType="button"
          confirmDisabled={rewards.balance <= 0}
          onCancel={close}
          onConfirm={() => {
            redeemPoints(rewards.balance);
          }}
        />
      </BoModal>
    </>
  );
};
