import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface AutopayPaperlessReminderData extends EventLogBaseData {
  autopayPaperlessCreditAmount: string;
  autopayStatus: boolean;
  onAutopayPaperlessDiscountPlan: boolean;
  paperlessStatus: boolean;
}

export class AutopayPaperlessReminder extends EventLog {
  public readonly autopayPaperlessCreditAmount: string;
  public readonly autopayStatus: boolean;
  public readonly onAutopayPaperlessDiscountPlan: boolean;
  public readonly paperlessStatus: boolean;

  constructor({
    autopayPaperlessCreditAmount,
    autopayStatus,
    onAutopayPaperlessDiscountPlan,
    paperlessStatus,
    ...baseEventData
  }: AutopayPaperlessReminderData) {
    super(baseEventData);

    this.autopayPaperlessCreditAmount = autopayPaperlessCreditAmount;
    this.autopayStatus = autopayStatus;
    this.onAutopayPaperlessDiscountPlan = onAutopayPaperlessDiscountPlan;
    this.paperlessStatus = paperlessStatus;
  }

  override get body() {
    return `AutopayPaperlessReminder sent - AutoPay status: ${this.autopayStatus} - Paperless status: ${this.paperlessStatus}`;
  }
}
