import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ResubmitEnrollmentData extends EventLogBaseData {
  serviceRequestedDate: string;
}

export class ResubmitEnrollment extends EventLog {
  public readonly serviceRequestedDate: string;

  constructor({
    serviceRequestedDate,
    ...baseEventData
  }: ResubmitEnrollmentData) {
    super(baseEventData);

    this.serviceRequestedDate = serviceRequestedDate;
  }

  override get body(): string | null {
    return `${this.name} - Service Requested Date: ${this.serviceRequestedDate}`;
  }
}
