import { premiseUrls } from "@common/api/premiseUrls";
import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { IdType } from "@common/types/apiTypes";
import { authenticatedFileDownload } from "@common/utils/authenticatedFileDownload";

export const buildContractFilename = (
  premiseId: IdType,
  contractId: IdType
) => {
  return `${premiseId}-dpp-contract-${contractId}.pdf`;
};

export const dppContractDownload = (premiseId: IdType, contractId: IdType) => {
  const url = premiseUrls.deferredPaymentPlans.downloadContract(
    premiseId,
    contractId
  );
  const filename = buildContractFilename(premiseId, contractId);

  return authenticatedFileDownload(url, filename, PDF_MIME_TYPE);
};
