import { IdType } from "@common/types/apiTypes";
import {
  IndicatorsStateType,
  PremiseIndicatorsWizardComponentName,
  PremiseIndicatorsWizardContext,
  PremiseIndicatorsWizardContextType,
  initialWizardState,
} from "@ops/components/PremiseIndicatorsWizard/PremiseIndicatorsWizardHelpers";
import React, { PropsWithChildren, useState } from "react";

interface PremiseIndicatorsWizardStateProviderProps {
  closeModal: () => void;
  premiseId: IdType;
}

export const PremiseIndicatorsWizardProvider = ({
  closeModal,
  children,
  premiseId,
}: PropsWithChildren<PremiseIndicatorsWizardStateProviderProps>) => {
  const [state, setState] = useState<IndicatorsStateType>(initialWizardState);

  const [activeComponentName, setActiveComponentName] =
    useState<PremiseIndicatorsWizardComponentName>(
      PremiseIndicatorsWizardComponentName.selectIndicators
    );

  const [visitedComponents, setVisitedComponents] = useState<
    PremiseIndicatorsWizardComponentName[]
  >([]);

  const updateWizardState = (payload: Partial<IndicatorsStateType>) => {
    const existingState = { ...state };

    setState({ ...existingState, ...payload });
  };

  const handleActiveComponentUpdate = (
    contentName: PremiseIndicatorsWizardComponentName
  ) => {
    setVisitedComponents([...visitedComponents, activeComponentName]);
    setActiveComponentName(contentName);
  };

  const resetWizardState = () => {
    setActiveComponentName(
      PremiseIndicatorsWizardComponentName.selectIndicators
    );
    updateWizardState(initialWizardState);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: PremiseIndicatorsWizardContextType = {
    activeComponentName,
    closeModal,
    premiseId,
    resetWizardState,
    setActiveComponentName: handleActiveComponentUpdate,
    updateWizardState,
    wizardState: state,
  };

  return (
    <PremiseIndicatorsWizardContext.Provider value={value}>
      {children}
    </PremiseIndicatorsWizardContext.Provider>
  );
};
