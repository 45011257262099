import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface TaxConfigurationCreatedData extends EventLogBaseData {
  salesTaxTypeDisplay: string;
  taxExemptionPercentageDisplay: string;
  taxExemptionReasonCodeDisplay: string;
  taxExemptionsDisplay: string;
}

export class TaxConfigurationCreated extends EventLog {
  public readonly salesTaxTypeDisplay: string;
  public readonly taxExemptionPercentageDisplay: string;
  public readonly taxExemptionReasonCodeDisplay: string;
  public readonly taxExemptionsDisplay: string;

  constructor({
    salesTaxTypeDisplay,
    taxExemptionPercentageDisplay,
    taxExemptionReasonCodeDisplay,
    taxExemptionsDisplay,
    ...baseEventData
  }: TaxConfigurationCreatedData) {
    super({
      ...baseEventData,
      eventText: "Tax Configuration Created",
    });

    this.salesTaxTypeDisplay = salesTaxTypeDisplay;
    this.taxExemptionsDisplay = taxExemptionsDisplay;
    this.taxExemptionPercentageDisplay = taxExemptionPercentageDisplay;
    this.taxExemptionReasonCodeDisplay = taxExemptionReasonCodeDisplay;
  }

  override get body() {
    return `${this.name}
    Exemption Reason Code: ${this.taxExemptionReasonCodeDisplay}
    Exemptions: ${this.taxExemptionsDisplay}
    Exemption Percentage: ${this.taxExemptionPercentageDisplay}
    Sales Tax Type:  ${this.salesTaxTypeDisplay}
    `;
  }
}
