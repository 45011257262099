export const validImageFileTypes = [
  "image/bmp",
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/heic",
] as const;

export type ValidImageFileTypes = (typeof validImageFileTypes)[number];

export const fileIsValidImage = (file: File) => {
  return validImageFileTypes.includes(file.type as ValidImageFileTypes);
};

export const fileIsPdf = (file: File) => {
  return file.type === "application/pdf";
};

/**
 * Checks if a file is within a specific range
 *
 * @param {File} file - The file to check
 * @param {number} maxFileSize - max size of the file in bytes
 * @param {number} [minFileSize=0] - An optional minimum size of file in bytes
 * @returns boolean
 */
export const fileIsValidSize = (
  file: File,
  maxFileSize: number,
  minFileSize: number = 0
) => {
  return minFileSize <= file.size && file.size <= maxFileSize;
};

export function formatFileSize(file: File) {
  const fileSize = file.size;

  if (fileSize < 1024) {
    return `${fileSize} bytes`;
  } else if (fileSize >= 1024 && fileSize < 1048576) {
    return `${(fileSize / 1024).toFixed(1)} KB`;
  } else if (fileSize >= 1048576) {
    return `${(fileSize / 1048576).toFixed(1)} MB`;
  }
}

export const getFileName = (file: File) => file.name.replace(/\.[^/.]+$/, "");

export const isFileTypeAccepted = (file: File, accept: string): boolean => {
  const acceptedTypes = accept.split(",").map((type) => type.trim());

  return acceptedTypes.includes(
    `.${file.name.split(".").pop()?.toLowerCase()}`
  );
};
