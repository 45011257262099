import { IdType, UUIDType } from "@common/types/apiTypes";

export enum ProductPriceTermPeriod {
  MONTHS = "MONTHS",
}

export enum ProductPriceFrequency {
  ANNUALLY = "ANNUALLY",
  MONTHLY = "MONTHLY",
}

export const ProductPriceFrequencyPrettyNames: Record<
  ProductPriceFrequency,
  string
> = {
  [ProductPriceFrequency.MONTHLY]: "month",
  [ProductPriceFrequency.ANNUALLY]: "year",
};

export interface ProductType {
  description: string;
  icon: string;
  id: UUIDType;
  name: string;
  tiers: ProductTierType[];
}

export interface ProductTierType {
  description: string;
  id: UUIDType;
  name: string;
  prices: ProductPriceType[];
}

export interface ProductPriceType {
  frequency: ProductPriceFrequency;
  id: UUIDType;
  rate: string;
  termLength: string;
  termPeriod: ProductPriceTermPeriod;
}

export interface ProductCreateRequestType {
  premiseId: IdType;
  priceId: UUIDType;
  startDate: string;
  termLength: string;
  termPeriod: ProductPriceTermPeriod;
}
