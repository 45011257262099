import { urls } from "@common/api/urls";
import { EM_DASH } from "@common/constants/characters.constant";
import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { authenticatedFileDownload } from "@common/utils/authenticatedFileDownload";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { DocumentLink } from "@ops-design-system/styles/common.styles";
import { filterNotices } from "@ops/components/ContractExpirationNoticesList/filterContractExpirationNotices";
import { useContractExpirationNotices } from "@ops/hooks/queries/useContractExpirationNotices";
import React from "react";

interface ContractExpirationNoticesListProps {
  orderId: IdType;
  premiseId: IdType;
}

interface ContractExpirationsRowProps {
  filename: string;
  label: string;
  pdfUrl: string;
}

const ContractExpirationRow = ({
  pdfUrl,
  filename,
  label,
}: ContractExpirationsRowProps) => {
  const flash = useRhFlash();

  const onDownload = () => {
    authenticatedFileDownload(pdfUrl, filename, PDF_MIME_TYPE).catch(
      (response: RhApiError) => {
        const message =
          response.data.errors.map((error) => error.detail).join("\n") ||
          "An unexpected error occurred and the file could not be downloaded.";

        flash.error(message);
      }
    );
  };

  return (
    <li>
      <BoInfoValue>
        <DocumentLink onClick={onDownload} role="link" aria-label="">
          View ({label})
        </DocumentLink>
      </BoInfoValue>
    </li>
  );
};

export const ContractExpirationNoticesList = ({
  premiseId,
  orderId,
}: ContractExpirationNoticesListProps) => {
  const { isPending, data } = useContractExpirationNotices(premiseId);

  let rows = <BoInfoValue>{EM_DASH}</BoInfoValue>;

  if (data && data?.length !== 0) {
    const filteredNotices = filterNotices(data, orderId);

    if (filteredNotices.length > 0) {
      rows = (
        <ul>
          {filteredNotices.map((notice) => {
            const pdfUrl = urls.contracts.expirationNotices.download(
              premiseId,
              notice.id
            );

            return (
              <ContractExpirationRow
                key={notice.id}
                pdfUrl={pdfUrl}
                filename={`contract-expiration-${premiseId}-${notice.noticeDate}.pdf`}
                label={formatMonthDayYear(notice.noticeDate)}
              />
            );
          })}
        </ul>
      );
    }
  }

  return (
    <BoInfoContainer>
      <BoInfoLabel>Contract Expirations</BoInfoLabel>
      {isPending ? <BoCircularProgress /> : rows}
    </BoInfoContainer>
  );
};
