import { OktaAuth, OktaAuthOptions, toRelativeUrl } from "@okta/okta-auth-js";
import type { NavigateFunction } from "react-router-dom";

let singleton: AuthClient | null = null;

export class AuthClient {
  public authClient: OktaAuth;

  constructor(oktaConfig: OktaAuthOptions) {
    this.authClient = new OktaAuth(oktaConfig);
  }

  public static getInstance(oktaConfig: OktaAuthOptions) {
    if (singleton === null) {
      singleton = new AuthClient(oktaConfig);
    }

    return singleton;
  }

  public signIn(email: string, password: string): Promise<void> {
    return this.authClient
      .signInWithCredentials({
        password,
        username: email,
      })
      .then((transaction) => {
        const { status, sessionToken } = transaction;

        if (status !== "SUCCESS" || !sessionToken) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw transaction;
        }
        return this.redirectToRedirectUri(sessionToken);
      });
  }

  public redirectToRedirectUri(sessionToken: string): Promise<void> {
    return this.authClient.token.getWithRedirect({
      sessionToken,
    });
  }

  public getRestoreOriginalUri(navigate: NavigateFunction) {
    return (oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
        replace: true,
      });
    };
  }
}
