import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { formatEnergyRate } from "@ops/formatters";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface OneClickRenewal3Data extends EventLogBaseData {
  currentContractAveragePriceAt2000: string;
  currentContractEndDate: string;
  currentContractName: string;
  planName: string;
  renewalPriceAt2000Kwh: string;
}
export class OneClickRenewal3 extends EventLog {
  public readonly renewalPriceAt2000Kwh: string;
  public readonly currentContractAveragePriceAt2000: string;
  public readonly currentContractEndDate: string;
  public readonly currentContractName: string;
  public readonly planName: string;

  constructor({
    renewalPriceAt2000Kwh,
    currentContractAveragePriceAt2000,
    currentContractEndDate,
    currentContractName,
    planName,
    ...baseEventData
  }: OneClickRenewal3Data) {
    super(baseEventData);

    this.renewalPriceAt2000Kwh = renewalPriceAt2000Kwh;
    this.currentContractAveragePriceAt2000 = currentContractAveragePriceAt2000;
    this.currentContractEndDate = currentContractEndDate;
    this.currentContractName = currentContractName;
    this.planName = planName;
  }

  override get body() {
    return `${this.name}\nCurrent Plan ${
      this.currentContractName
    } - Rate @ 2000kwh: ${formatEnergyRate(
      this.currentContractAveragePriceAt2000
    )}\nExpiration Date: ${formatMonthDayYear(
      this.currentContractEndDate
    )}\nOffered Plan ${this.planName} - Rate @ 2000kwh: ${formatEnergyRate(
      this.renewalPriceAt2000Kwh
    )}`;
  }
}
