import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { isDefined } from "@common/utils/arrayUtils";
import { noop } from "@common/utils/genericFunctions";
import { legacyCustomerApi } from "@ops-data/api/legacyCustomerApi";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLogService } from "@ops/components/SupportLogs/EventLog/EventLogService";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export const MINIMUM_INTERVAL_MS = 200;

export class FetchEventLogsService {
  public readonly targetId?: IdType;
  public readonly intervalMs: number | null;
  public onSuccess: (data: EventLog[]) => void;
  public onFail: (err?: RhApiError | null) => void;

  private keepGoing: boolean;
  private timeoutId: NodeJS.Timeout | undefined;

  // to disable continuous fetch (without the feature flag)
  // send in `intervalMs=null`
  constructor(
    targetId: IdType | undefined,
    intervalMs: number | null,
    onSuccess: (resp: EventLog[]) => void,
    onFail?: (err?: RhApiError | null) => void
  ) {
    this.keepGoing = true;
    this.onSuccess = onSuccess;
    this.onFail = onFail ?? noop;
    this.targetId = targetId;
    this.intervalMs = intervalMs;
  }

  public fetch() {
    if (!this.targetId) {
      return;
    }
    return legacyCustomerApi.eventLogs
      .list(this.targetId)
      .then(({ events: rawEvents }) => {
        const events = this.processRawEvents(rawEvents ?? []);

        this.onSuccess(events);
      })
      .catch((err) => {
        this.onFail(err);
        this.stop();
      });
  }

  public fetchContinuous() {
    return this.fetch()?.then(() => {
      if (this.keepGoing && this.intervalMs) {
        this.timeoutId = setTimeout(
          () => this.fetchContinuous(),
          this.intervalMs
        );
      }
    });
  }

  public stop() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.keepGoing = false;
  }

  private processRawEvents(rawEvents: EventLogBaseData[]): EventLog[] {
    const eventModels = rawEvents.map((eventData) =>
      EventLogService.eventLogModel(eventData)
    );

    return eventModels.filter(isDefined);
  }
}
