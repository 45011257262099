import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { EMAIL_VALIDATION_REGEX } from "@common/constants/regex.constant";
import { EmailValidator } from "commons-validator-js";
import dayjs from "dayjs";

export type FinalFormFieldValidator<
  InputValueType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormState extends Record<string, any> = Record<string, any>,
> = (value: InputValueType | null, allValues: FormState) => string | undefined;

export type Validator<
  InputValueType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormState extends Record<string, any> = Record<string, any>,
> = (
  value: InputValueType | null,
  allValues?: FormState,
  messageId?: string
) => string | undefined;

export type ValidationDictionary<FormState extends object> = Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FinalFormFieldValidator<any, FormState>[]
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const opsAndPricingIsRequired: Validator<any> = (value, _allValues) => {
  if (!value) {
    return "Some required information is missing.";
  }
};

export const opsAndPricingIsValidEmail: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (!EMAIL_VALIDATION_REGEX.test(value)) {
    return "Invalid email";
  }

  const emailValidator = new EmailValidator();

  if (!emailValidator.isValid(value)) {
    return "Invalid email";
  }
};

export const opsAndPricingIsPositiveNumeric: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (!/^[.\d]+$/.test(value)) {
    return "Is not a positive number.";
  }
};

export const opsAndPricingIsValidDateTodayOrFuture: Validator<string> = (
  value
) => {
  if (!value) {
    return;
  }
  const date = dayjs(value, ISO8601_DATE_FORMAT, true);
  const today = dayjs().startOf("day");

  if (!date.isValid()) {
    return "Please enter a valid date (YYYY-mm-dd).";
  }
  if (date.isBefore(today)) {
    return "Please enter a date in the future." as string;
  }
};
