import { UUIDType } from "@common/types/apiTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { ProductAddOnCard } from "@ops/components/ProductAddOnCard/ProductAddOnCard";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProducts } from "@ops/hooks/queries/useProducts";
import React from "react";
import { Form } from "react-final-form";

const ProductAddonsContent = () => {
  const productQuery = useProducts();

  if (productQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (productQuery.isError) {
    return <p>Something went wrong.</p>;
  }

  const { data } = productQuery;

  if (data.count === 0) {
    return <p>No products found.</p>;
  }

  return (
    <div>
      {data.results.map((product) => {
        return (
          <ProductAddOnCard
            product={product}
            selectionRequired={false}
            key={product.id}
          />
        );
      })}
    </div>
  );
};

interface FormValuesType {
  productSelection: UUIDType;
}

export const ProductAddonsTab = () => {
  const { prospect, updateProspectState } = useEnrollmentFlowContext();
  const { productPrices } = prospect;

  const currentProductSelection = productPrices?.[0];

  const onSubmit = (values: FormValuesType) => {
    const newPriceList = values.productSelection
      ? [values.productSelection]
      : [];

    updateProspectState({
      productPrices: newPriceList,
    });
  };

  return (
    <div>
      <h1>Product Addons</h1>
      <Form
        onSubmit={onSubmit}
        initialValues={{ productSelection: currentProductSelection }}
        render={({ handleSubmit }) => (
          <form id={TabType.productAddons} onSubmit={handleSubmit}>
            <ProductAddonsContent />
          </form>
        )}
      />
    </div>
  );
};
