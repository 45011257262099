import { IdType } from "@common/types/apiTypes";
import { noop } from "@common/utils/genericFunctions";
import { welcomePacketDownload } from "@common/utils/welcomePacketDownload";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { DocumentLink } from "@ops-design-system/styles/common.styles";
import { captureException as sentryCaptureException } from "@sentry/react";
import React, { MouseEventHandler, PropsWithChildren } from "react";
import styled from "styled-components";

interface WelcomePacketDownloadProps {
  orderId: IdType;
  premiseId: IdType;
}

export const DocumentListEntry = styled.div``;

export const WelcomePacketDownload = ({
  premiseId,
  orderId,
  children,
}: PropsWithChildren<WelcomePacketDownloadProps>) => {
  const flash = useRhFlash();
  const onDownload: MouseEventHandler<HTMLAnchorElement> = (e) => {
    welcomePacketDownload(premiseId, orderId)
      .then(noop)
      .catch((err) => {
        // Because we're downloading a PDF, the normal api error format
        // as JSON does not appear to include the server error code
        // so instead we can switch on the status text.
        sentryCaptureException(err);
        if (err.statusText === "Not Found") {
          flash.error("We were unable to find the welcome packet.");
        } else {
          flash.error("Something went wrong. Please try again later.");
        }
      });
  };

  return (
    <DocumentListEntry>
      <DocumentLink
        title="Download Welcome Packet"
        onClick={onDownload}
        aria-label="welcome packet download"
        role="link"
      >
        {children}
      </DocumentLink>
    </DocumentListEntry>
  );
};
