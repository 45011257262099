import { WizardComponentName } from "@ops/components/TransferServiceWizard/TransferServiceWizard.types";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import { useWizardForm } from "@ops/hooks/useWizardForm";
import React, { PropsWithChildren, useEffect } from "react";
import { useFormState } from "react-final-form";

interface TransferServiceWizardFormProps {
  id: WizardComponentName;
}

export const TransferServiceWizardForm = ({
  children,
  id,
}: PropsWithChildren<TransferServiceWizardFormProps>) => {
  const { hasValidationErrors } = useFormState();
  const {
    setNextButtonProps,
    setVisitedComponents,
    activeComponentName,
    visitedComponents,
  } = useTransferServiceWizardContext();
  const { onSubmit } = useWizardForm(() => {
    const visitComponents = [...visitedComponents];

    visitComponents.push(activeComponentName);
    setVisitedComponents(visitComponents);
  });

  useEffect(() => {
    if (hasValidationErrors) {
      setNextButtonProps({ disabled: true });
    } else {
      setNextButtonProps({ disabled: false });
    }
  }, [hasValidationErrors]);

  return (
    <form noValidate onSubmit={onSubmit} id={id}>
      {children}
    </form>
  );
};
