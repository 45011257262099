// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable typescript-sort-keys/string-enum */

export const customerTabs = [
  "#customer",
  "#billing",
  "#retention",
  "#rewards",
  "#usage",
  "#operations",
  "#documents",
  "#admin",
  "#add-ons",
  "#devices",
] as const;

export type CustomerTabType = (typeof customerTabs)[number];
