import { ajax } from "@common/services/ajax";
import { BatchContactLogUploadType } from "@ops-utils/types/batchContactLogUploadTypes";
import { ContactLogCategoryChoice } from "@ops-utils/types/contactLogTypes";

const BASE_PATH = "/api/support-logs/contact-logs";

export const legacyContactLogApi = {
  batchUploads: {
    create: (
      csvFile: File
    ): Promise<{ name: string; numberOfLogsAdded: number }> => {
      const data = new FormData();

      data.append("batch_notes_file", csvFile, csvFile.name);
      return ajax.postFile(`${BASE_PATH}/batch-uploads/`, data);
    },
    list: (): Promise<BatchContactLogUploadType[]> => {
      return ajax.get(`${BASE_PATH}/batch-uploads/`);
    },
  },
  categories: {
    list: (): Promise<ContactLogCategoryChoice[]> => {
      return ajax.get(`${BASE_PATH}/categories/`);
    },
  },
};
