import { BoTextInputStyled } from "@ops-design-system/components/BoTextInput/BoTextInput.styled";
import { TextInput } from "@ops-design-system/components/BoTextInput/TextInput";
import { BoFieldIconContainer } from "@ops-design-system/styles/common.styles";
import React, { FC } from "react";

export type BoTextInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "name" | "placeholder"
> & {
  afterText?: string;
  errorMessage?: string;
  hasErrors?: boolean;
  icon?: FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
  name: string;
  placeholder?: string;
  requiredIndicator?: boolean;
  width?: string;
};
export const BoTextInput = ({
  children,
  hasErrors,
  errorMessage,
  placeholder,
  icon: Icon,
  name,
  requiredIndicator,
  className,
  width,
  ...rest
}: BoTextInputProps) => {
  return (
    <BoTextInputStyled width={width} className={className}>
      <TextInput
        name={name}
        error={hasErrors}
        {...rest}
        placeholder={placeholder ?? ""}
        errorMessage={errorMessage}
        requiredIndicator={requiredIndicator}
      >
        {children}
      </TextInput>
      {Icon && (
        <BoFieldIconContainer>
          <Icon />
        </BoFieldIconContainer>
      )}
    </BoTextInputStyled>
  );
};
