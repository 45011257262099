import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { FC, MouseEventHandler } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledBoTabs = styled.div`
  align-items: center;
  border-bottom: 1px solid ${grey["100"]};
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface BoTabsProps {
  label?: string;
}

export const BoTabs: FC<React.PropsWithChildren<BoTabsProps>> = ({
  label,
  children,
}) => {
  return (
    <StyledBoTabs aria-label={label} role="tablist">
      {children}
    </StyledBoTabs>
  );
};

interface StyledTabProps {
  $active?: boolean;
}
const StyledTab = styled.button<StyledTabProps>`
  background: none;
  border: none;
  bottom: -1px;
  cursor: pointer;
  margin-bottom: 0;
  margin-right: ${rhOpsSpacingPx(4)};
  padding-bottom: calc(${rhOpsSpacingPx(1.5)} - 1px);
  padding-left: 1px;
  padding-right: 1px;
  position: relative;

  &:hover {
    color: ${({ $active }) =>
      $active ? darkPurple["500"] : grey["600"]} !important;
    text-decoration: none !important;
  }

  text-transform: capitalize;
  ${({ $active }) => {
    if ($active) {
      return `
        color: ${darkPurple["500"]};
        border-bottom: 2px solid;
      `;
    }

    return `color: ${grey["600"]};`;
  }}
`;

interface StyledTabNameProps {
  $active?: boolean;
}
const StyledTabName = styled<typeof Body2>(Body2)<StyledTabNameProps>`
  color: currentColor;
  font-weight: ${({ $active }) =>
    $active ? FontWeight.SemiBold : FontWeight.Regular};
`;

interface BoHashTabProps {
  active?: boolean;
  className?: string;
  hashkey?: string;
  name: string;
  onClick?: (tabName: string) => void;
}

export const BoHashTab = ({
  name,
  hashkey,
  onClick,
  active,
  className,
}: BoHashTabProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick: MouseEventHandler = (_event) => {
    if (typeof onClick === "function") {
      onClick(name);
    }

    navigate(`${location.pathname}#${hashkey ?? name}`);
  };

  return (
    <StyledTab
      onClick={handleClick}
      $active={Boolean(active)}
      role="tab"
      aria-selected={Boolean(active)}
      key={name}
      className={className}
    >
      <StyledTabName $active={Boolean(active)}>{name}</StyledTabName>
    </StyledTab>
  );
};
