import { BoDialog } from "@ops-design-system/components/BoDialog/BoDialog";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { TOUGenerationPricingTable } from "@ops/components/TOUGenerationPricingTable/TOUGenerationPricingTable";
import { TOUPricingTable } from "@ops/components/TOUPricingTable/TOUPricingTable";
import React from "react";
import styled from "styled-components";

interface TOUPriceScheduleTableModalProps {
  closeModal: () => void;
  offerSnapshot: OfferSnapshot;
}

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const TOUPriceScheduleTableModal = ({
  closeModal,
  offerSnapshot,
}: TOUPriceScheduleTableModalProps) => {
  return (
    <BoDialog
      size="medium"
      open
      header={`${offerSnapshot.title} Energy Rate Breakdown`}
      onClose={closeModal}
    >
      <StyledBody>
        {offerSnapshot.priceTierSnapshots ? (
          <TOUPricingTable priceTiers={offerSnapshot.priceTierSnapshots} />
        ) : null}

        {offerSnapshot.isTimeOfUseGeneration ? (
          <TOUGenerationPricingTable
            generationPriceTiers={
              offerSnapshot.generationPriceTierSnapshots || []
            }
          />
        ) : null}
      </StyledBody>
    </BoDialog>
  );
};
