import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface InvoiceDeletedData extends EventLogBaseData {
  energyInvoiceId: string;
  invoiceId: string;
  invoiceNumber: string;
}

export class InvoiceDeleted extends EventLog {
  public readonly energyInvoiceId: string;
  public readonly invoiceId: string;
  public readonly invoiceNumber: string;

  constructor({
    energyInvoiceId,
    invoiceId,
    invoiceNumber,
    ...baseEventData
  }: InvoiceDeletedData) {
    super(baseEventData);

    this.energyInvoiceId = energyInvoiceId;
    this.invoiceId = invoiceId;
    this.invoiceNumber = invoiceNumber;
  }

  override get body() {
    return `${this.name} - Invoice Number: ${this.invoiceNumber} - Invoice ID: ${this.invoiceId} - Energy Invoice Run ID: ${this.energyInvoiceId}`;
  }
}
