import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { AddProspectPaymentMethod } from "@ops/components/ProspectEditor/BillingInfoTab/ProspectPaymentMethod/AddProspectPaymentMethod";
import { ShowProspectPaymentMethod } from "@ops/components/ProspectEditor/BillingInfoTab/ProspectPaymentMethod/ShowProspectPaymentMethod";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React from "react";
import styled from "styled-components";

const StyledCard = styled(BoCard)`
  margin-top: ${rhOpsSpacingPx(1)};
`;

export const ProspectPaymentMethod = () => {
  const {
    prospect: { billingPaymentMethodId },
  } = useEnrollmentFlowContext();

  return billingPaymentMethodId ? (
    <ShowProspectPaymentMethod
      billingPaymentMethodId={billingPaymentMethodId}
    />
  ) : (
    <StyledCard>
      <AddProspectPaymentMethod />
    </StyledCard>
  );
};
