import { StyledAdminPage } from "@ops/components/AdminDashboard/shared/StyledAdminPage";
import { ReprocessInvoiceForm } from "@ops/components/ReprocessInvoiceForm/ReprocessInvoiceForm";
import React from "react";

export const ReprocessInvoicePage = () => {
  return (
    <StyledAdminPage pageName="Reprocess Invoice">
      <ReprocessInvoiceForm />
    </StyledAdminPage>
  );
};
