import { BoAccordionSummary } from "@ops-design-system/components/BoAccordion/BoAccordion";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ContractRenewalWizardStepForm } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import styled from "styled-components";

export const OffersStepForm = styled(ContractRenewalWizardStepForm)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  justify-content: center;
`;

export const OffersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${rhOpsSpacingPx(2)};
  justify-content: center;
`;

export const StyledBoAccordionSummary = styled(BoAccordionSummary)`
  ${Body2} {
    font-size: 14px;
  }
`;

export const EmailOffersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(3)};
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const EmailOfferSnapshotContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(0.5)};
`;

export const EmailInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(0.5)};

  & > span:first-child {
    &::after {
      content: ":";
    }
  }

  & > span[data-expired="true"] {
    color: ${red["400"]};
    font-weight: ${FontWeight.Bold};
  }
`;
