import {
  MONTH_DAY_YEAR_SLASH,
  MONTH_NAME_DAY_YEAR,
} from "@common/constants/date.constant";
import {
  InvoiceType,
  InvoiceTypeEnum,
} from "@common/types/accountSummaryTypes";
import { ZuoraIdType } from "@common/types/apiTypes";
import dayjs from "dayjs";

export class Invoice {
  public id: ZuoraIdType;
  public invoiceNumber: string;
  public dueDate: string;
  public amount: number;
  public balance: number;
  public lateFeeApplied: boolean | undefined;
  public rewardCreditApplied: boolean;
  public serviceStartDate: string | null;
  public serviceEndDate: string | null;
  public invoiceDate: string;
  public totalKwh: number | null;
  public accountId: ZuoraIdType;
  public invoiceType: InvoiceTypeEnum;

  public constructor({
    amount,
    balance,
    dueDate,
    id,
    invoiceNumber,
    lateFeeApplied,
    rewardCreditApplied,
    serviceStartDate,
    serviceEndDate,
    accountId,
    invoiceDate,
    totalKwh,
    invoiceType,
  }: InvoiceType) {
    this.id = id;
    this.amount = amount;
    this.balance = balance;
    this.dueDate = dueDate;
    this.invoiceNumber = invoiceNumber;
    this.lateFeeApplied = lateFeeApplied;
    this.rewardCreditApplied = rewardCreditApplied;
    this.serviceStartDate = serviceStartDate;
    this.serviceEndDate = serviceEndDate;

    this.accountId = accountId;
    this.invoiceDate = invoiceDate;
    this.totalKwh = totalKwh;
    this.invoiceType = invoiceType;
  }

  public get formattedInvoiceDate(): string {
    return dayjs(this.invoiceDate).format(MONTH_NAME_DAY_YEAR);
  }

  public get formattedInvoiceServicePeriod(): string[] {
    return [
      this.serviceStartDate
        ? `${dayjs(this.serviceStartDate).format(MONTH_DAY_YEAR_SLASH)} - `
        : "",
      dayjs(this.serviceEndDate).format(MONTH_DAY_YEAR_SLASH),
    ];
  }
}
