import { IdType } from "@common/types/apiTypes";
import { rhapsodyCustomerDocumentsApi } from "@ops-data/api/rhapsodyCustomerDocumentsApi";
import { useMutation } from "@tanstack/react-query";

export const useCustomerDocumentDownloadMutation = () => {
  return useMutation({
    mutationFn: async ({
      documentId,
      documentName,
    }: {
      documentId: IdType;
      documentName: string;
    }) => {
      const response = await rhapsodyCustomerDocumentsApi.download(documentId);

      const url = window.URL.createObjectURL(new Blob([response]));

      const link = document.createElement("a");

      link.href = url;
      link.download = documentName;

      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  });
};
