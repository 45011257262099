import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { CustomerSearchResponseType } from "@common/types/apiResponseTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body2, H1 } from "@ops-design-system/components/Typography/Typography";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { CustomerSearchForm } from "@ops/components/CustomerSearchForm/CustomerSearchForm";
import { CustomerTable } from "@ops/components/CustomerTable/CustomerTable";
import React, { useState } from "react";
import styled from "styled-components";

const NoMatchMessage = styled(Body2).attrs({ as: "div" })``;
const ErrorMessage = styled(Body2).attrs({ as: "div" })`
  color: ${red.main};
`;

const Header = styled(H1)`
  margin-bottom: ${rhOpsSpacingPx(5)};
`;

const ResultsWrapper = styled.section`
  margin-top: ${rhOpsSpacingPx(3)};
`;

export const CustomerSearchPage = () => {
  const flash = useRhFlash();
  const [
    { data: customers, requestMonitor: status, error },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<CustomerSearchResponseType>([]);

  const [lastQuery, setLastQuery] = useState<string>("");

  const handleSubmit = (query: string) => {
    setPending();
    setLastQuery(query);
    api.customers
      .search(query)
      .then((data) => {
        setSuccess(data);
      })
      .catch((err) => {
        setFailure(err);
        flash.error(err.statusText);
      });
  };

  if (!customers) {
    return null;
  }

  const renderResults = (): JSX.Element => {
    if (customers.length) {
      return <CustomerTable customers={customers} />;
    } else {
      return (
        <>
          {lastQuery && (
            <NoMatchMessage>
              Found no matches for &quot;{lastQuery}&quot;
            </NoMatchMessage>
          )}
          {status.didFail && (
            <ErrorMessage>
              The search request failed. {error?.statusText}
            </ErrorMessage>
          )}
        </>
      );
    }
  };

  return (
    <div>
      <Header>Search Customers</Header>
      <BoCard>
        <CustomerSearchForm onSubmit={handleSubmit} />
      </BoCard>
      <ResultsWrapper>
        {status.isPending ? <BoCircularProgress /> : renderResults()}
      </ResultsWrapper>
    </div>
  );
};
