import { useModalState } from "@common/hooks/useModalState";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { AccountNotificationSwitches } from "@ops/components/ContactInfoCard/EditCustomerNotifications/AccountNotificationSwitches";
import React from "react";

export const EditCustomerNotifications = () => {
  const { open, close, isOpen } = useModalState();

  return (
    <>
      <BoButton fullWidth size="extraSmall" variant="secondary" onClick={open}>
        Manage Notifications
      </BoButton>
      <BoModal open={isOpen}>
        <BoDialogHeader>Manage Notifications</BoDialogHeader>
        <BoDialogBody>
          <AccountNotificationSwitches />
        </BoDialogBody>
        <BoDialogButtonFooter cancelBtnText="Done" onCancel={close} />
      </BoModal>
    </>
  );
};
