import { orange, red } from "@ops-design-system/styles/palette.colors";
import {
  MatchCriteria,
  PremiseMatchCollisionStatusLevels,
} from "@ops-utils/types/prospectValidationReportTypes";
import React from "react";
import styled from "styled-components";

interface ValidationLogProps {
  validationErrorList: MatchCriteria[];
  validationStatus: PremiseMatchCollisionStatusLevels;
}

const StyledValidationLogEntryContainer = styled.div``;
const ValidationErrorStyles = styled.ul<{ status: string }>`
  color: ${(props) => (props.status === "Error" ? red.main : orange[300])};
  list-style-type: disc;
  margin-left: 10px;
`;

export function ValidationReportCriteriaList({
  validationErrorList,
  validationStatus,
}: ValidationLogProps): JSX.Element {
  return (
    <StyledValidationLogEntryContainer>
      <div>
        <ValidationErrorStyles status={validationStatus}>
          {validationErrorList.map((validationError) => (
            <li key={validationError}>{validationError}</li>
          ))}
        </ValidationErrorStyles>
      </div>
    </StyledValidationLogEntryContainer>
  );
}
