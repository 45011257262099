import { PaymentExtensionType } from "@common/types/billingTypes";
import {
  formatCurrency,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { StyledSuccessMessage } from "@ops/components/MakeAPayment/MakeAPayment.styled";
import React from "react";

interface EditPaymentExtensionSuccessProps {
  currentPaymentExtension: PaymentExtensionType;
  onClose: () => void;
}

export const EditPaymentExtensionSuccess = ({
  currentPaymentExtension,
  onClose,
}: EditPaymentExtensionSuccessProps) => {
  return (
    <>
      <BoDialogHeader>Edit Payment Extension</BoDialogHeader>
      <BoDialogBody>
        <StyledSuccessMessage>
          <Subtitle2>Payment extension date been updated to </Subtitle2>
          <Subtitle2 $fontWeight="Bold">
            {formatMonthDayShortYear(currentPaymentExtension.extendToDate)}
          </Subtitle2>
          .
        </StyledSuccessMessage>
        <StyledSuccessMessage>
          <Subtitle2>Amount extended of </Subtitle2>
          <Subtitle2 $fontWeight="Bold">
            {formatCurrency(
              Number(currentPaymentExtension.amountExtended ?? 0)
            )}
          </Subtitle2>
          <Subtitle2> has been submitted.</Subtitle2>
        </StyledSuccessMessage>
      </BoDialogBody>
      <BoDialogButtonFooter
        cancelBtnText="Back to Customer Page"
        onCancel={onClose}
      />
    </>
  );
};
