import { useAjaxState } from "@common/hooks/useAjaxState";
import { SupportLogTarget } from "@ops/components/SupportLogs/SupportLogs.types";
import { EventLog } from "@ops/models/EventLog/EventLog.model";
import {
  FetchEventLogsService,
  MINIMUM_INTERVAL_MS,
} from "@ops/services/FetchEventLogsService";
import { useEffect, useState } from "react";

export const useEventLogsInState = (
  target: SupportLogTarget,
  options: { refreshIntervalSeconds: number }
) => {
  const refresh = options.refreshIntervalSeconds;
  const [{ error, requestMonitor }, { setFailure }] = useAjaxState();

  const [events, setEvents] = useState<EventLog[]>();

  useEffect(() => {
    if (!target.id) {
      return;
    }
    const refreshMs = Math.max(MINIMUM_INTERVAL_MS, refresh * 1000);

    const service = new FetchEventLogsService(
      target.id,
      refreshMs,
      setEvents,
      setFailure
    );

    service.fetchContinuous();

    return () => {
      service.stop();
    };
  }, [target.id, refresh]);

  return {
    data: events,
    error,
    requestMonitor,
  };
};
