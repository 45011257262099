import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledDocumentsTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;

export const StyledDocumentsTabRow = styled.div`
  align-items: flex-end;
  display: flex;
  gap: ${rhOpsSpacingPx(3)};
`;

export const StyledDocumentRadioInput = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhOpsSpacingPx(1)};
`;

export const StyledDownloadDocumentButton = styled.button`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const StyledDocumentTypeSelect = styled.div`
  color: ${grey[500]};
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  width: 240px;
`;

export const StyledUploadButtonContainer = styled.div`
  border: 1px solid ${grey[100]};
  border-radius: 6px;
  padding: ${rhOpsSpacingPx(2.5)};
`;
