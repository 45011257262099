import { useModalState } from "@common/hooks/useModalState";
import { AcquisitionCampaign } from "@common/models/AcquisitionCampaign.model";
import { createRhContext } from "@common/utils/contextHelpers";
import { AcquisitionCampaignEditor } from "@ops/components/AcquisitionCampaignEditor/AcquisitionCampaignEditor";
import { AcquisitionCampaignsTable } from "@ops/components/AcquisitionCampaignsTable/AcquisitionCampaignsTable";
import { StyledAdminPage } from "@ops/components/AdminDashboard/shared/StyledAdminPage";
import { useAcquisitionCampaignsInState } from "@ops/hooks/useAcquisitionCampaignsInState";
import React, { useContext, useEffect, useState } from "react";

interface AcquisitionCampaignsEditorContextType
  extends ReturnType<typeof useModalState> {
  selectedCampaign: AcquisitionCampaign | null;
  setSelectedCampaign: (camp: AcquisitionCampaign | null) => void;
}

type AcquisitionCampaignsListContextType = Pick<
  ReturnType<typeof useAcquisitionCampaignsInState>,
  "fetch" | "data" | "requestMonitor"
>;

const AcquisitionCampaignsEditorContext =
  createRhContext<AcquisitionCampaignsEditorContextType>();

const AcquisitionCampaignsListContext =
  createRhContext<AcquisitionCampaignsListContextType>();

export function useAcquisitionCampaignsEditorContext(): AcquisitionCampaignsEditorContextType {
  const context = useContext<AcquisitionCampaignsEditorContextType | null>(
    AcquisitionCampaignsEditorContext
  );

  if (context === null) {
    throw new Error("Using context outside AcquisitionCampaignsEditorContext"); // throw error if using this hook outside the provider
  }
  return context;
}

export function useAcquisitionCampaignsListContext(): AcquisitionCampaignsListContextType {
  const context = useContext<AcquisitionCampaignsListContextType | null>(
    AcquisitionCampaignsListContext
  );

  if (context === null) {
    throw new Error("Using context outside AcquisitionCampaignsListContext"); // throw error if using this hook outside the provider
  }
  return context;
}

export const AcquisitionCampaignsPage = () => {
  const { open, close, isOpen } = useModalState();
  const { fetch, requestMonitor, data } = useAcquisitionCampaignsInState();
  const [selectedCampaign, setSelectedCampaign] =
    useState<AcquisitionCampaign | null>(null);

  useEffect(fetch, [fetch]);

  return (
    <AcquisitionCampaignsEditorContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ close, isOpen, open, selectedCampaign, setSelectedCampaign }}
    >
      <AcquisitionCampaignsListContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{ data, fetch, requestMonitor }}
      >
        <StyledAdminPage
          pageName="Manage Sales Campaigns"
          nameInLineButton="New Campaign"
          onButtonClick={open}
        >
          <AcquisitionCampaignsTable />
        </StyledAdminPage>

        <AcquisitionCampaignEditor />
      </AcquisitionCampaignsListContext.Provider>
    </AcquisitionCampaignsEditorContext.Provider>
  );
};
