import { CheckboxProps, FormControlLabel } from "@mui/material";
import { StyledBoCheckbox } from "@ops-design-system/components/BoCheckbox/BoCheckbox.styled";
import React from "react";
import { useField } from "react-final-form";
import styled from "styled-components";

export interface BoCheckboxFieldProps {
  disabled?: boolean;
  label?: string;
  name: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const BoCheckboxField = ({
  disabled,
  name,
  label,
  onClick,
}: BoCheckboxFieldProps) => {
  const { input } = useField<boolean>(name, {
    type: "checkbox",
  });

  const checkbox = (
    <StyledBoCheckbox
      disabled={disabled}
      id={name}
      onClick={onClick}
      {...input}
    />
  );

  if (!label) {
    return checkbox;
  }

  return <FormControlLabel control={checkbox} label={label} />;
};

export const BoCheckboxInput = (props: CheckboxProps) => {
  return <StyledBoCheckbox color="primary" {...props} />;
};

export const BoCheckbox = styled.input.attrs((props) => ({
  type: "checkbox",
}))`
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;

  border: 1px solid rgb(136, 118, 219);
  border-radius: 0.25em;

  display: grid;
  height: 20px;
  /* Not removed via appearance */
  margin: 0;
  padding: 0;
  place-content: center;
  width: 20px;

  &::before {
    box-shadow: inset 1em 1em rgb(136, 118, 219);
    clip-path: polygon(3% 62%, 16% 45%, 43% 64%, 82% 12%, 96% 23%, 42% 88%);
    content: "";
    height: 14px;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 80ms transform ease-in-out;
    width: 14px;
  }

  &:checked::before {
    transform: scale(1);
  }
`;
