import { EdiActions } from "@common/constants/edi.constants";
import { IdType } from "@common/types/apiTypes";
import { ReactComponent as UnlockedIcon } from "@ops-design-system/icons/UnlockedIcon.svg";
import { EdiActionTriggerWithConfirmation } from "@ops/components/EdiActionTriggerWithConfirmation/EdiActionTriggerWithConfirmation";
import React from "react";

interface RemoveSwitchHoldProps {
  customerId: IdType;
}

export const RemoveSwitchHold = ({ customerId }: RemoveSwitchHoldProps) => {
  return (
    <EdiActionTriggerWithConfirmation
      action={EdiActions.SWITCH_HOLD_REMOVE}
      buttonIcon={UnlockedIcon}
      confirmBtnText="Remove Switch Hold"
      confirmMessage="Are you sure you want to remove the switch hold?"
      customerId={customerId}
      errorMessage="There was a problem sending the remove switch hold.  Please try again."
      header="Remove Switch Hold"
      triggerOpenDialogBtnText="Remove Switch Hold"
    />
  );
};
