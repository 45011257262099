export interface HistoricalUsageType {
  endDate: string;
  quantity: number;
  startDate: string;
  type: string;
}

export interface UsageType {
  consumptionCost: string;
  consumptionKwh: string;
  consumptionRate: string;
  consumptionTierColor?: string;
  consumptionTierName?: string;
  consumptionTierOrder?: number;
  datetime: string;
  generationEarned: string;
  generationKwh: string;
  generationRate: string;
  generationTierColor?: string;
  generationTierName?: string;
  generationTierOrder?: number;
}

export type ChartDirection = "horizontal" | "vertical";

export enum ChartType {
  "daily" = "daily",
  "hourly" = "hourly",
  "monthly" = "monthly",
}

export enum SelectedGraph {
  "billPeriod" = "billPeriod",
  "hourly" = "hourly",
  "monthly" = "monthly",
  "yearly" = "yearly",
}

export enum SelectedGraphMode {
  "both" = "both",
  "consumption" = "consumption",
  "surplus" = "surplus",
}

export const chartTypeMapping: Record<SelectedGraph, ChartType> = {
  [SelectedGraph.billPeriod]: ChartType.daily,
  [SelectedGraph.monthly]: ChartType.daily,
  [SelectedGraph.yearly]: ChartType.monthly,
  [SelectedGraph.hourly]: ChartType.hourly,
};

export type TemperatureType = {
  average: number;
  datetime: string;
  high: number;
  low: number;
};

export enum GraphTemperatureValue {
  Average = "Average",
  HighLow = "HighLow",
}
