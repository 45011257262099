import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { Dayjs } from "dayjs";
import React from "react";
import styled from "styled-components";

interface CalendarHeaderProps {
  value: Dayjs;
}

const StyledCalendarContainer = styled.div`
  background-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  color: ${CSSVariables.COLOR_WHITE};
  font-size: 18px;
  grid-column: 2 / 4;
  grid-row: 1;
  padding: ${rhSpacingPx(2.5)};
`;

export const CalendarHeader = (props: CalendarHeaderProps) => {
  const { value: pickedDate } = props;

  const pickedYear = pickedDate ? pickedDate.year() : "";
  const formattedData = pickedDate ? pickedDate.format("ddd, MMM D") : "";

  return (
    <StyledCalendarContainer>
      <div>{pickedYear}</div>
      <div>{formattedData}</div>
    </StyledCalendarContainer>
  );
};
