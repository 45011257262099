import { ReactComponent as TDSPChangeIcon } from "@design-system/icons/bill-factors/TDSPChange.svg";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { StyledBillFactor } from "@ops/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorsTDSPChangeProps {
  differencePercentage: number;
  utilityName: string;
}

export const BillFactorsTDSPChange = ({
  differencePercentage,
  utilityName,
}: BillFactorsTDSPChangeProps) => {
  return (
    <StyledBillFactor>
      <TDSPChangeIcon />
      <Body1 $fontWeight="Bold">{utilityName} Delivery Charge Changes</Body1>
      <Body2>
        {utilityName}, which controls your energy delivery charges, increased
        their rates by {differencePercentage}%. These are reflected on your bill
        without markup.
      </Body2>
    </StyledBillFactor>
  );
};
