import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { OpsStoreType } from "@ops-utils/types/opsSlicesTypes";

export const selectPaymentMethodsState = ({
  paymentMethods,
}: Pick<OpsStoreType, "paymentMethods">) => {
  const { data, error, requestStatus } = paymentMethods;

  let paymentMethodList = null;

  if (data !== null) {
    paymentMethodList = data.map(
      (paymentMethod) => new PaymentMethod(paymentMethod)
    );
  }

  return {
    error,
    paymentMethods: paymentMethodList,
    status: new RequestMonitor(requestStatus),
  };
};
