// https://emailregex.com/
export const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ZIPCODE_VALIDATION_REGEX = /^([0-9]{5}(?:-[0-9]{4})?)$/;
export const SSN_VALIDATION_REGEX =
  /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
export const BASE_URL_REGEX = /^.*\/\/[^/]+/;
export const FIVE_DIGIT_ZIP_CODE = /^[0-9]{5}$/;
export const VALID_CURRENCY_AMOUNT = /^\d+(\.\d{2})?$/;
export const SPECIAL_CHAR_REGEX = /[!@#$%^&*()[\]{}|;:'",.<>/?™]/;
export const TEN_OR_ELEVEN_PHONE_DIGIT_NUMBER_REGARDLESS_OF_SYMBOLS =
  /^(?:\D*\d){10,11}\D*$/;
