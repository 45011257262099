import { EM_DASH } from "@common/constants/characters.constant";
import { Order } from "@common/models/Order.model";
import { Premise } from "@common/models/Premise.model";
import { IdType } from "@common/types/apiTypes";
import { formatMonthDayShortYear } from "@common/utils/dataFormatters";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableLabel,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { ContractExpirationNoticesList } from "@ops/components/ContractExpirationNoticesList/ContractExpirationNoticesList";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { WelcomePacketDownload } from "@ops/components/WelcomePacketDownload/WelcomePacketDownload";
import { formatEnergyRate } from "@ops/formatters";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";

const COLUMNS = [
  "Contract Start Date",
  "Contract End Date",
  "Plan Name",
  "Rhythm Energy Rate",
  "Contract Term",
  "Welcome Kit PDF",
  "Contract Exp. PDF",
  "Termination Date",
];

interface PlanHistoryRowProps {
  order: Order;
  premise: Premise;
}

const PlanHistoryRow = ({ premise, order }: PlanHistoryRowProps) => {
  return (
    <BoTableRow>
      <BoTableCell>{`${formatMonthDayShortYear(order.startDate)}`}</BoTableCell>
      <BoTableCell>{`${
        order.expectedEndDate
          ? formatMonthDayShortYear(order.expectedEndDate)
          : EM_DASH
      }`}</BoTableCell>
      <BoTableCell>{order.title}</BoTableCell>
      <BoTableCell>
        {order.isTimeOfUse ? (
          <TOUEnergyBreakdownModals offerSnapshotId={order.offersnapshotId} />
        ) : (
          `${formatEnergyRate(order.energyRate, 3)}`
        )}
      </BoTableCell>
      <BoTableCell>{order.termMonths} months</BoTableCell>

      <BoTableCell>
        <WelcomePacketDownload premiseId={premise.id} orderId={order.id}>
          View
        </WelcomePacketDownload>
      </BoTableCell>
      <BoTableCell>
        <ContractExpirationNoticesList
          premiseId={premise.id}
          orderId={order.id}
        />
      </BoTableCell>
      <BoTableCell>{`${formatMonthDayShortYear(order.endDate)}`}</BoTableCell>
    </BoTableRow>
  );
};

export const PlanHistory = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();

  const { premise } = usePremise(premiseId);

  if (!premise || !premise?.historicOrders.length) {
    return null;
  }

  return (
    <>
      <BoTableLabel>Plan History</BoTableLabel>
      <BoTable>
        <BoTableHead>
          <BoTableRow>
            {COLUMNS.map((columnName) => (
              <BoTableHeadCell key={columnName}>{columnName}</BoTableHeadCell>
            ))}
          </BoTableRow>
        </BoTableHead>
        <BoTableBody>
          {premise.historicOrders.map((order) => (
            <PlanHistoryRow
              premise={premise}
              order={order}
              key={`${premise.id}-${order.id}`}
            />
          ))}
        </BoTableBody>
      </BoTable>
    </>
  );
};
