import { AxiosErrorCodes } from "@common/constants/errorCodes.constants";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { HTMLInputEvent } from "@design-system/types/domEventTypes";
import { legacyContactLogApi } from "@ops-data/api/legacyContactLogApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import {
  ErrorContainer,
  ErrorText,
  StyledInput,
} from "@ops-design-system/components/BoTextInput/BoTextInput.styled";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { BATCH_CONTACT_LOG_UPLOAD_ERROR_CODE } from "@ops/components/SupportLogs/SupportLogs.types";
import React, { useRef, useState } from "react";
import styled from "styled-components";

const FileInputContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(2)};
`;

interface BatchNoteUploaderProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
}

export const BatchNoteUploader = ({
  open,
  onClose,
  onSubmit,
}: BatchNoteUploaderProps) => {
  const [
    { requestMonitor, error },
    { setInitialState, setSuccess, setFailure, setPending },
  ] = useAjaxState();
  const [csvFile, setCsvFile] = useState<File | null>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const flash = useRhFlash();

  const closeModal = () => {
    if (!requestMonitor.isPending) {
      setInitialState();
      setCsvFile(undefined);
      onClose();
    }
  };
  const handleChange = (event: HTMLInputEvent) => {
    if (event && event.target.files?.length) {
      const file = event?.target.files[0];
      const { name } = file;
      const pattern = /.csv$/i;

      if (pattern.test(name) === false) {
        flash.error("File upload must be a CSV File");
        setCsvFile(undefined);
        return;
      }
      setCsvFile(file);
    }
  };
  const handleSubmit = () => {
    if (requestMonitor.isPending || !csvFile) {
      return;
    }
    setPending();

    legacyContactLogApi.batchUploads
      .create(csvFile)
      .then(({ name, numberOfLogsAdded }) => {
        setSuccess();
        flash.success(
          `Successfully created ${numberOfLogsAdded} logs from file ${name}`
        );
        closeModal();
      })
      .catch((err: RhError) => {
        setFailure(err);

        const {
          data: { errorCode },
        } = err;

        switch (errorCode) {
          case BATCH_CONTACT_LOG_UPLOAD_ERROR_CODE: {
            setCsvFile(null);
            flash.error(`Failed to create logs from file ${csvFile.name}`);
            return;
          }

          case AxiosErrorCodes.AXIOS_TIMEOUT_ERROR_CODE: {
            flash.success(
              "The server may take a while to process your batch upload request. Please check back later for the status.",
              { variant: "info" }
            );
            closeModal();
            return;
          }

          default: {
            flash.error(`Something went wrong: ${errorCode}`);
            closeModal();
          }
        }
      })
      .finally(onSubmit);
  };

  return (
    <BoModal open={open} onClose={closeModal}>
      <BoDialogHeader>Upload Batch Notes</BoDialogHeader>
      <BoDialogBody>
        <form>
          <FileInputContainer>
            <BoLabel htmlFor="batchNotesInput">Choose a file</BoLabel>
            <input
              type="file"
              id="batchNotesInput"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleChange}
            />
            <StyledInput disabled placeholder={csvFile?.name ?? "file name"} />
            {error && (
              <ErrorContainer>
                <ErrorText>
                  {error.data.errors.map((err) => err.detail).join(", ")}
                </ErrorText>
              </ErrorContainer>
            )}
          </FileInputContainer>
          <BoButton
            variant="secondary"
            onClick={() => fileInputRef.current?.click()}
          >
            Choose a file
          </BoButton>
        </form>
      </BoDialogBody>
      <BoDialogButtonFooter
        onConfirm={handleSubmit}
        confirmLoading={requestMonitor.isPending}
        confirmDisabled={!csvFile}
        confirmBtnText="Upload"
        onCancel={closeModal}
        cancelBtnText="Close"
        cancelDisabled={requestMonitor.isPending}
      />
    </BoModal>
  );
};
