import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ProductContractCreatedData extends EventLogBaseData {
  contractEnd: string;
  contractNumber: string;
  contractProductName: string;
  contractRate: string;
  contractStart: string;
  contractTerm: string;
  contractTierName: string;
}

export class ProductContractCreated extends EventLog {
  public readonly contractNumber: string;
  public readonly contractProductName: string;
  public readonly contractTierName: string;
  public readonly contractStart: string;
  public readonly contractEnd: string;
  public readonly contractRate: string;
  public readonly contractTerm: string;

  constructor({
    contractNumber,
    contractProductName,
    contractTierName,
    contractStart,
    contractEnd,
    contractRate,
    contractTerm,
    ...baseEventData
  }: ProductContractCreatedData) {
    super({
      ...baseEventData,
      eventText: "Product Contract Created",
    });
    this.contractNumber = contractNumber;
    this.contractProductName = contractProductName;
    this.contractTierName = contractTierName;
    this.contractStart = contractStart;
    this.contractEnd = contractEnd;
    this.contractRate = contractRate;
    this.contractTerm = contractTerm;
  }

  override get body() {
    return `${this.name} - Customer has opted in to Product Add-Ons. ${this.contractProductName} - ${this.contractTierName} - ${this.contractTerm} months`;
  }
}
