import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { RhError } from "@common/types/errorTypes";
import { z } from "zod";

export const IdInstance = z.coerce.string();
export type IdType = z.infer<typeof IdInstance>;
export type UUIDType = string;
export type EpochDateType = number;
export type ZuoraIdType = string;
export type DateType = string;
export type IronHideIdType = string;

export type SimpleURLMaker = () => string;
export type ParamURLMaker<Params = string> = (params: Params) => string;

export const AjaxStatuses = {
  Failure: "failure",
  Idle: "idle",
  Pending: "pending",
  Success: "success",
} as const;

export type AjaxStatus =
  | typeof AjaxStatuses.Idle
  | typeof AjaxStatuses.Pending
  | typeof AjaxStatuses.Success
  | typeof AjaxStatuses.Failure;

export interface UseAjaxStateType<ResponseType> {
  data: ResponseType | null;
  error: RhError | null;
  requestMonitor: RequestMonitor;
}

export interface UseAjaxSetters<ResponseType> {
  setFailure: (error?: RhError | null) => void;
  setInitialState: () => void;
  setPending: () => void;
  setSuccess: (data: ResponseType) => void;
}

type OneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type ZeroToNine = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type YYYY = `19${ZeroToNine}${ZeroToNine}` | `20${ZeroToNine}${ZeroToNine}`;
type MM = `0${OneToNine}` | `1${0 | 1 | 2}`;
type DD = `0${OneToNine}` | `${1 | 2}${ZeroToNine}` | `3${0 | 1}`;

export type DashedYYYYMMDD = `${YYYY}-${MM}-${DD}`;
