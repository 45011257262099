import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const BoButtonTabContainer = styled.div.attrs({
  role: "tablist",
})`
  align-items: center;
  border-bottom: 1px solid ${grey["100"]};
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const BoButtonTab = styled.button.attrs({
  role: "tab",
})`
  background: none;
  border: none;
  bottom: -1px;

  color: ${grey["600"]};
  cursor: pointer;

  font-size: 12px;
  font-weight: ${FontWeight.Regular};
  line-height: 16.8px;
  margin-bottom: 0;
  margin-right: ${rhOpsSpacingPx(4)};
  padding-bottom: calc(${rhOpsSpacingPx(1.5)} - 1px);
  padding-left: 1px;
  padding-right: 1px;
  position: relative;

  &[data-active="true"] {
    border-bottom: 2px solid;
    color: ${darkPurple["500"]};
    font-weight: ${FontWeight.SemiBold};
  }
`;
