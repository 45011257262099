import { DateType, IdType } from "@common/types/apiTypes";

export enum StatusIndicatorLevel {
  ALERT = "ALERT",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARN = "WARN",
}

export interface StatusIndicatorUpdateType {
  activationDate?: DateType;
  expirationDate?: DateType;
  id: IdType;
}
export interface StatusIndicatorType {
  activationDate: DateType | null;
  expirationDate: DateType | null;
  id?: IdType;
  level: StatusIndicatorLevel;
  name: string;
  priority: number;
}
