import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface AffiliateCreatedData extends EventLogBaseData {
  email: string;
  firstName: string;
  lastName: string;
}

export class AffiliateCreated extends EventLog {
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;

  constructor({
    firstName,
    lastName,
    email,
    ...baseEventData
  }: AffiliateCreatedData) {
    super(baseEventData);

    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  override get body() {
    return `${this.name} - Affiliate's First Name: ${this.firstName} - Affiliate's Last Name: ${this.lastName} - Affiliate's Email: ${this.email}`;
  }
}
