import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { EnrollmentFlowStateProvider } from "@ops/components/ProspectEditor/EnrollmentFlowStateProvider";
import { ProspectEditor } from "@ops/components/ProspectEditor/ProspectEditor";
import { MIN_WIDTH } from "@ops/constants/layout.constants";
import { useProspectInState } from "@ops/hooks/useProspectInState";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export const StyledEnrollmentPage = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-width: ${MIN_WIDTH};
  width: 100%;
`;

export const EnrollmentPage = () => {
  const { id } = useParams<{ id: IdType }>();
  const flash = useRhFlash();
  const { prospect, requestMonitor, fetchProspect } = useProspectInState(id);

  useEffect(() => {
    fetchProspect();
  }, []);

  if (requestMonitor.isPending || !prospect) {
    return <BoCircularProgress />;
  }

  if (requestMonitor.didFail) {
    flash.error("An error occurred. Unable to get prospect data.");

    return null;
  }

  return (
    <StyledEnrollmentPage>
      <EnrollmentFlowStateProvider prospect={prospect}>
        <ProspectEditor />
      </EnrollmentFlowStateProvider>
    </StyledEnrollmentPage>
  );
};
