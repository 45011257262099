import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  CustomerDocumentType,
  CustomerDocumentsSearchOptions,
} from "@ops-utils/types/customerDocumentTypes";

const BASE_PATH = "/api/rhapsody/customer-documents";

export const rhapsodyCustomerDocumentsApi = {
  download: (documentId: IdType) =>
    ajax.get<Blob>(`${BASE_PATH}/${documentId}/download`, {
      responseType: "blob",
    }),
  retrieve: ({
    customerId,
    documentType,
    limit,
    offset,
    ordering,
    premiseId,
  }: CustomerDocumentsSearchOptions) =>
    ajax.get<PaginationEndpointResponseType<CustomerDocumentType>>(
      `${BASE_PATH}?customer_id=${customerId}&premise_id=${premiseId}&document_type=${documentType}&ordering=${ordering}&limit=${limit}&offset=${offset}`
    ),
  types: () => ajax.get<{ types: string[] }>(`${BASE_PATH}/types`),
  upload: (formData: FormData) =>
    ajax.postFile(`${BASE_PATH}/upload`, formData),
};
