export const distributeDPPTotal = (total: number, installments: number) => {
  const totalInCents = +(total * 100).toFixed(0);
  const numberOfHighValue = totalInCents % installments;
  const lowValue = Math.floor(totalInCents / installments) / 100;
  const highValue = Math.floor(totalInCents / installments + 1) / 100;

  const installmentValues = Array.from(new Array(installments)).map(
    (value, index) => {
      return index < numberOfHighValue ? String(highValue) : String(lowValue);
    }
  );

  return installmentValues;
};
