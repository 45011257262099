import { ContactInfoForm } from "@ops/components/ProspectEditor/ContactInfoTab/ContactInfoForm";
import { StyledTab } from "@ops/components/ProspectEditor/shared/ProspectEditor.styled";
import React from "react";

export const ContactInfoTab = () => {
  return (
    <StyledTab>
      <ContactInfoForm />
    </StyledTab>
  );
};
