import { createGlobalStyle, css } from "styled-components";

// CSS Color Properties
//
// This is the custom property used in both the var and defined in the :root of GlobalBrandedThemeStyles.
enum CSSColorProperties {
  COLOR_BLACK = "--color-black",
  COLOR_DISABLED_BACKGROUND = "--color-disabled-background",
  COLOR_DISABLED_BORDER = "--color-disabled-border",
  COLOR_DISABLED_HOVER = "--color-disabled-hover",
  COLOR_DISABLED_TEXT = "--color-disabled-text",
  COLOR_ERROR_DARK = "--color-error-dark",
  COLOR_ERROR_LIGHT = "--color-error-light",
  COLOR_ERROR_MAIN = "--color-error-main",
  COLOR_GREY_100 = "--color-grey-100",
  COLOR_GREY_200 = "--color-grey-200",
  COLOR_GREY_300 = "--color-grey-300",
  COLOR_GREY_400 = "--color-grey-400",
  COLOR_GREY_50 = "--color-grey-50",
  COLOR_GREY_500 = "--color-grey-500",
  COLOR_GREY_600 = "--color-grey-600",
  COLOR_GREY_700 = "--color-grey-700",
  COLOR_GREY_800 = "--color-grey-800",
  COLOR_GREY_900 = "--color-grey-900",
  COLOR_INFO_DARK = "--color-info-dark",
  COLOR_INFO_LIGHT = "--color-info-light",
  COLOR_INFO_MAIN = "--color-info-main",
  COLOR_PRIMARY_DARK = "--color-primary-dark",
  COLOR_PRIMARY_LIGHT = "--color-primary-light",
  COLOR_PRIMARY_LIGHTEST = "--color-primary-lightest",
  COLOR_PRIMARY_MAIN = "--color-primary-main",
  COLOR_PRIMARY_MEDIUM = "--color-primary-medium",
  COLOR_SECONDARY_DARK = "--color-secondary-dark",
  COLOR_SECONDARY_LIGHT = "--color-secondary-light",
  COLOR_SECONDARY_LIGHTEST = "--color-secondary-lightest",
  COLOR_SECONDARY_MAIN = "--color-secondary-main",
  COLOR_SECONDARY_MEDIUM = "--color-secondary-medium",
  COLOR_SUCCESS_DARK = "--color-success-dark",
  COLOR_SUCCESS_LIGHT = "--color-success-light",
  COLOR_SUCCESS_MAIN = "--color-success-main",
  COLOR_WARNING_DARK = "--color-warning-dark",
  COLOR_WARNING_LIGHT = "--color-warning-light",
  COLOR_WARNING_MAIN = "--color-warning-main",
  COLOR_WHITE = "--color-white",
}

// CSS Variables
//
// This is what will be used in the codebase to reference custom properties.
export enum CSSVariables {
  COLOR_BLACK = `var(${CSSColorProperties.COLOR_BLACK})`,
  COLOR_DISABLED_BACKGROUND = `var(${CSSColorProperties.COLOR_DISABLED_BACKGROUND})`,
  COLOR_DISABLED_BORDER = `var(${CSSColorProperties.COLOR_DISABLED_BORDER})`,
  COLOR_DISABLED_HOVER = `var(${CSSColorProperties.COLOR_DISABLED_HOVER})`,
  COLOR_DISABLED_TEXT = `var(${CSSColorProperties.COLOR_DISABLED_TEXT})`,
  COLOR_ERROR_DARK = `var(${CSSColorProperties.COLOR_ERROR_DARK})`,
  COLOR_ERROR_LIGHT = `var(${CSSColorProperties.COLOR_ERROR_LIGHT})`,
  COLOR_ERROR_MAIN = `var(${CSSColorProperties.COLOR_ERROR_MAIN})`,
  COLOR_GREY_100 = `var(${CSSColorProperties.COLOR_GREY_100})`,
  COLOR_GREY_200 = `var(${CSSColorProperties.COLOR_GREY_200})`,
  COLOR_GREY_300 = `var(${CSSColorProperties.COLOR_GREY_300})`,
  COLOR_GREY_400 = `var(${CSSColorProperties.COLOR_GREY_400})`,
  COLOR_GREY_50 = `var(${CSSColorProperties.COLOR_GREY_50})`,
  COLOR_GREY_500 = `var(${CSSColorProperties.COLOR_GREY_500})`,
  COLOR_GREY_600 = `var(${CSSColorProperties.COLOR_GREY_600})`,
  COLOR_GREY_700 = `var(${CSSColorProperties.COLOR_GREY_700})`,
  COLOR_GREY_800 = `var(${CSSColorProperties.COLOR_GREY_800})`,
  COLOR_GREY_900 = `var(${CSSColorProperties.COLOR_GREY_900})`,
  COLOR_INFO_DARK = `var(${CSSColorProperties.COLOR_INFO_DARK})`,
  COLOR_INFO_LIGHT = `var(${CSSColorProperties.COLOR_INFO_LIGHT})`,
  COLOR_INFO_MAIN = `var(${CSSColorProperties.COLOR_INFO_MAIN})`,
  COLOR_PRIMARY_DARK = `var(${CSSColorProperties.COLOR_PRIMARY_DARK})`,
  COLOR_PRIMARY_LIGHT = `var(${CSSColorProperties.COLOR_PRIMARY_LIGHT})`,
  COLOR_PRIMARY_LIGHTEST = `var(${CSSColorProperties.COLOR_PRIMARY_LIGHTEST})`,
  COLOR_PRIMARY_MAIN = `var(${CSSColorProperties.COLOR_PRIMARY_MAIN})`,
  COLOR_PRIMARY_MEDIUM = `var(${CSSColorProperties.COLOR_PRIMARY_MEDIUM})`,
  COLOR_SECONDARY_DARK = `var(${CSSColorProperties.COLOR_SECONDARY_DARK})`,
  COLOR_SECONDARY_LIGHT = `var(${CSSColorProperties.COLOR_SECONDARY_LIGHT})`,
  COLOR_SECONDARY_LIGHTEST = `var(${CSSColorProperties.COLOR_SECONDARY_LIGHTEST})`,
  COLOR_SECONDARY_MAIN = `var(${CSSColorProperties.COLOR_SECONDARY_MAIN})`,
  COLOR_SECONDARY_MEDIUM = `var(${CSSColorProperties.COLOR_SECONDARY_MEDIUM})`,
  COLOR_SUCCESS_DARK = `var(${CSSColorProperties.COLOR_SUCCESS_DARK})`,
  COLOR_SUCCESS_LIGHT = `var(${CSSColorProperties.COLOR_SUCCESS_LIGHT})`,
  COLOR_SUCCESS_MAIN = `var(${CSSColorProperties.COLOR_SUCCESS_MAIN})`,
  COLOR_WARNING_DARK = `var(${CSSColorProperties.COLOR_WARNING_DARK})`,
  COLOR_WARNING_LIGHT = `var(${CSSColorProperties.COLOR_WARNING_LIGHT})`,
  COLOR_WARNING_MAIN = `var(${CSSColorProperties.COLOR_WARNING_MAIN})`,
  COLOR_WHITE = `var(${CSSColorProperties.COLOR_WHITE})`,
}

// Global Styles
//
// This is where the CSS Variables are defined using their corresponding theme values.
export const GlobalBrandedThemeStyles = createGlobalStyle` 
  :root {    
    /* Theme custom vars here */
    ${({ theme }) => css`
      ${CSSColorProperties.COLOR_BLACK}: ${theme.colors.black};

      ${CSSColorProperties.COLOR_DISABLED_BACKGROUND}: ${theme.colors.disabled
        .background};
      ${CSSColorProperties.COLOR_DISABLED_BORDER}: ${theme.colors.disabled
        .border};
      ${CSSColorProperties.COLOR_DISABLED_HOVER}: ${theme.colors.disabled
        .hover};
      ${CSSColorProperties.COLOR_DISABLED_TEXT}: ${theme.colors.disabled.text};
      ${CSSColorProperties.COLOR_ERROR_DARK}: ${theme.colors.error.dark};
      ${CSSColorProperties.COLOR_ERROR_LIGHT}: ${theme.colors.error.light};
      ${CSSColorProperties.COLOR_ERROR_MAIN}: ${theme.colors.error.main};
      ${CSSColorProperties.COLOR_GREY_100}: ${theme.colors.grey[100]};
      ${CSSColorProperties.COLOR_GREY_200}: ${theme.colors.grey[200]};
      ${CSSColorProperties.COLOR_GREY_300}: ${theme.colors.grey[300]};
      ${CSSColorProperties.COLOR_GREY_400}: ${theme.colors.grey[400]};
      ${CSSColorProperties.COLOR_GREY_50}: ${theme.colors.grey[50]};
      ${CSSColorProperties.COLOR_GREY_500}: ${theme.colors.grey[500]};
      ${CSSColorProperties.COLOR_GREY_600}: ${theme.colors.grey[600]};
      ${CSSColorProperties.COLOR_GREY_700}: ${theme.colors.grey[700]};
      ${CSSColorProperties.COLOR_GREY_800}: ${theme.colors.grey[800]};
      ${CSSColorProperties.COLOR_GREY_900}: ${theme.colors.grey[900]};
      ${CSSColorProperties.COLOR_INFO_DARK}: ${theme.colors.info.dark};
      ${CSSColorProperties.COLOR_INFO_LIGHT}: ${theme.colors.info.light};
      ${CSSColorProperties.COLOR_INFO_MAIN}: ${theme.colors.info.main};
      ${CSSColorProperties.COLOR_PRIMARY_DARK}: ${theme.colors.primary.dark};
      ${CSSColorProperties.COLOR_PRIMARY_LIGHT}: ${theme.colors.primary.light};
      ${CSSColorProperties.COLOR_PRIMARY_LIGHTEST}: ${theme.colors.primary
        .lightest};
      ${CSSColorProperties.COLOR_PRIMARY_MAIN}: ${theme.colors.primary.main};
      ${CSSColorProperties.COLOR_PRIMARY_MEDIUM}: ${theme.colors.primary
        .medium};
      ${CSSColorProperties.COLOR_SECONDARY_DARK}: ${theme.colors.secondary
        .dark};
      ${CSSColorProperties.COLOR_SECONDARY_LIGHT}: ${theme.colors.secondary
        .light};
      ${CSSColorProperties.COLOR_SECONDARY_LIGHTEST}: ${theme.colors.secondary
        .lightest};
      ${CSSColorProperties.COLOR_SECONDARY_MAIN}: ${theme.colors.secondary
        .main};
      ${CSSColorProperties.COLOR_SECONDARY_MEDIUM}: ${theme.colors.secondary
        .medium};
      ${CSSColorProperties.COLOR_SUCCESS_DARK}: ${theme.colors.success.dark};
      ${CSSColorProperties.COLOR_SUCCESS_LIGHT}: ${theme.colors.success.light};
      ${CSSColorProperties.COLOR_SUCCESS_MAIN}: ${theme.colors.success.main};
      ${CSSColorProperties.COLOR_WARNING_DARK}: ${theme.colors.warning.dark};
      ${CSSColorProperties.COLOR_WARNING_LIGHT}: ${theme.colors.warning.light};
      ${CSSColorProperties.COLOR_WARNING_MAIN}: ${theme.colors.warning.main};
      ${CSSColorProperties.COLOR_WHITE}: ${theme.colors.white};
    `}
	}
`;
