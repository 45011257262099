import { EM_DASH } from "@common/constants/characters.constant";
import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { isOps } from "@common/utils/authenticationHelpers";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoRadioGroup,
  BoRadioInput,
} from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import {
  Body1,
  Body2,
  Body3,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { DisplayAddress } from "@ops/components/DisplayAddress/DisplayAddress";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import {
  ContractOptions,
  WizardComponentName,
} from "@ops/components/WinbackWizard/winbackWizard.enums";
import { ContractOption } from "@ops/components/WinbackWizard/winbackWizard.types";
import { formatEnergyRate } from "@ops/formatters";
import { useAccountSummaryQuery } from "@ops/hooks/queries/useAccountSummaryQuery";
import { useWinbackWizardContext } from "@ops/hooks/useWinbackWizardContext";
import dayjs from "dayjs";
import React, {
  FormEvent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";

const Header = styled(Subtitle2).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

const ErrorMessage = styled("div").attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

const ErrorHeader = styled(Body3).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
`;

const ErrorContent = styled(Body3).attrs({ as: "div" })`
  color: ${red.main};
`;

const Container = styled.div`
  margin: auto;
  width: 600px;
`;

const Section = styled.div`
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(3)};
  margin-top: ${rhOpsSpacingPx(3)};
`;

const InfoDetailsContainer = styled.div`
  & > label {
    font-weight: ${FontWeight.SemiBold};
  }
`;

const Label = styled(Body2).attrs({ as: "label" })``;

const Text = styled(Body1).attrs({ as: "div" })``;

const UnavailableMessage = styled(Body2)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

interface InfoDetailsProps {
  label: string;
}

const InfoDetails = ({
  label,
  children,
}: PropsWithChildren<InfoDetailsProps>) => {
  return (
    <InfoDetailsContainer>
      <Label>{label}</Label>
      <Text>{children}</Text>
    </InfoDetailsContainer>
  );
};

export const WinbackInfoDetails = () => {
  const { data: groups } = useAuthenticatedUserGroups();
  const {
    setLoadingModalChild,
    setNextButtonProps,
    setActiveComponentName,
    updateWinbackWizardState,
    customer,
    premise,
    winbackWizardState: { contractOption },
  } = useWinbackWizardContext();
  const [currentRadio, setCurrentRadio] = useState<ContractOption>(
    contractOption ?? null
  );

  const { currentOrder: order } = premise;
  const {
    data: accountSummary,
    isPending,
    isError,
  } = useAccountSummaryQuery(premise.id);
  const flash = useRhFlash();

  const premiseInactiveDays = (() => {
    const today = dayjs();
    const serviceEndDate = dayjs(premise.serviceEndDate);

    if (serviceEndDate < today) {
      return today.diff(serviceEndDate, "day", false);
    }
    return 0;
  })();

  const isPremiseInactive = premiseInactiveDays > 60;

  const isContractExpired = (() => {
    const today = dayjs();
    const contractEndDate = dayjs(order?.contractEndDate);

    return contractEndDate < today;
  })();

  const handleRadioClick = (val: number | string) => {
    setCurrentRadio(val as ContractOption);
  };

  useEffect(() => {
    if (isPending) {
      setLoadingModalChild(true);
    } else {
      setLoadingModalChild(false);
      if (isError) {
        flash.error("Error getting past due balance");
      }
    }
  }, [isPending, isError]);

  useEffect(() => {
    setNextButtonProps({ disabled: !currentRadio });
  }, [currentRadio]);

  if (isPending) {
    return null;
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!currentRadio) {
      flash.error("An contract option must be selected in order to proceed.");
      return;
    }

    let nextContentName = WizardComponentName.winbackChoosePlan;

    if (currentRadio === ContractOptions.keepSameContract) {
      nextContentName = WizardComponentName.winbackStartDateForm;
      updateWinbackWizardState({
        contractOption: currentRadio,
        offerSnapshotId: premise.currentOrder?.offersnapshotId,
      });
    } else {
      updateWinbackWizardState({
        contractOption: currentRadio,
        offerSnapshotId: "",
      });
    }
    setActiveComponentName(nextContentName);
  };

  return (
    <Container>
      <Section>
        <Header>Customer Information</Header>
        <BoGrid $variant={GridVariants.Grid3_1}>
          <BoGridColumn>
            <InfoDetails label="Customer Name">{customer.fullName}</InfoDetails>
          </BoGridColumn>
          <BoGridColumn>
            <InfoDetails label="Service Address">
              <DisplayAddress address={premise.serviceAddress ?? undefined} />
            </InfoDetails>
          </BoGridColumn>
          <BoGridColumn>
            <InfoDetails label="Past Due Balance">
              {accountSummary && accountSummary.pastDueBalance > 0
                ? formatCurrency(accountSummary.pastDueBalance)
                : EM_DASH}
            </InfoDetails>
          </BoGridColumn>
        </BoGrid>
      </Section>
      <Section>
        <Header>Current Contract</Header>
        {(isPremiseInactive || isContractExpired) && (
          <ErrorMessage>
            <ErrorHeader>
              {isContractExpired ? "Contract Expiration Note" : "Inactive Note"}
            </ErrorHeader>
            <ErrorContent>
              {isContractExpired
                ? "The current contract is expired and cannot be used to update contract"
                : "Service has been inactive for more than 60 days, please select a new plan"}
            </ErrorContent>
          </ErrorMessage>
        )}
        {order && (
          <BoGrid $variant={GridVariants.Grid3_1}>
            <BoGridColumn>
              <InfoDetails label="Current Plan">{order.title}</InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails label="Rhythm Energy Rate">
                {order.isTimeOfUse ? (
                  <TOUEnergyBreakdownModals
                    offerSnapshotId={order.offersnapshotId}
                  />
                ) : (
                  `${order.contractRate.valueOf()} ${EM_DASH} ${formatEnergyRate(
                    order.energyRate,
                    3
                  )}`
                )}
              </InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails label="Contract Start Date">
                {formatMonthDayYear(order.startDate)}
              </InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails label="Term">{order.termMonths} months</InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails label="Average Price at Sign Up">
                {formatEnergyRate(order.averageRateAt2000Kwh)}
              </InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails label="Contract End Date">
                {formatMonthDayYear(order.contractEndDate)}
              </InfoDetails>
            </BoGridColumn>
          </BoGrid>
        )}
        {!order && (
          <UnavailableMessage>
            It appears this user does not have an active order so we have no
            plan data to show here.
          </UnavailableMessage>
        )}
      </Section>
      <StyledDivider />
      <Section>
        <Header>Contract update</Header>
        <form
          noValidate
          onSubmit={onSubmit}
          id={WizardComponentName.winbackInfoDetails}
        >
          <BoRadioGroup>
            <BoGrid $variant={GridVariants.Grid3_1}>
              <BoGridColumn>
                <BoRadioInput
                  label="Keep same contract"
                  name={ContractOptions.keepSameContract}
                  onClick={handleRadioClick}
                  value={ContractOptions.keepSameContract}
                  checked={currentRadio === ContractOptions.keepSameContract}
                  title={ContractOptions.keepSameContract}
                  disabled={
                    (!isOps(groups) && isPremiseInactive) || isContractExpired
                  }
                />
              </BoGridColumn>
              <BoGridColumn>
                <BoRadioInput
                  label="Start new contract"
                  name={ContractOptions.startNewContract}
                  value={ContractOptions.startNewContract}
                  onClick={handleRadioClick}
                  checked={currentRadio === ContractOptions.startNewContract}
                />
              </BoGridColumn>
            </BoGrid>
          </BoRadioGroup>
        </form>
      </Section>
    </Container>
  );
};
