import { EM_DASH } from "@common/constants/characters.constant";
import { IdType } from "@common/types/apiTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import {
  FutureRenewalCard,
  FutureRenewalLoadingCard,
  NoFutureRenewalText,
} from "@ops/components/CustomerDashboard/RetentionTab/FutureRenewals/FutureRenewals.styled";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { WelcomePacketDownload } from "@ops/components/WelcomePacketDownload/WelcomePacketDownload";
import { formatEnergyRate } from "@ops/formatters";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";

export const FutureRenewals = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise, requestMonitor } = usePremise(premiseId);

  if (requestMonitor.isPending) {
    return (
      <section aria-label="Future Plan">
        <BoCardLabel>Future Plan</BoCardLabel>
        <FutureRenewalLoadingCard>
          <BoCircularProgress position="relative" />
        </FutureRenewalLoadingCard>
      </section>
    );
  }

  if (!premise) {
    return null;
  }

  const { futureOrder } = premise;

  if (!futureOrder) {
    return (
      <section aria-label="Future Plan">
        <BoCardLabel>Future Plan</BoCardLabel>
        <BoCard>
          <NoFutureRenewalText>
            No future plans have been renewed.
          </NoFutureRenewalText>
        </BoCard>
      </section>
    );
  }

  return (
    <section aria-label="Future Plan">
      <BoCardLabel>Future Plan</BoCardLabel>
      <FutureRenewalCard>
        <BoInfoContainer>
          <BoInfoLabel>Renewed Contract</BoInfoLabel>
          <BoInfoValue>{futureOrder.title}</BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>Rhythm Energy Rate</BoInfoLabel>
          <BoInfoValue>
            {futureOrder.isTimeOfUse ? (
              <TOUEnergyBreakdownModals
                offerSnapshotId={futureOrder.offersnapshotId}
              />
            ) : (
              `${futureOrder.contractRate.valueOf()} ${EM_DASH} ${formatEnergyRate(
                futureOrder.energyRate,
                3
              )}`
            )}
          </BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>Avg. Price (2000 kWh)</BoInfoLabel>
          <BoInfoValue>
            {formatEnergyRate(futureOrder.averageRateAt2000Kwh)}
          </BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>Contract Start Date</BoInfoLabel>
          <BoInfoValue>{formatMonthDayYear(futureOrder.startDate)}</BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>Contract End Date</BoInfoLabel>
          <BoInfoValue>
            {formatMonthDayYear(futureOrder.contractEndDate)}
          </BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>Contract Term</BoInfoLabel>
          <BoInfoValue>{futureOrder.termMonths} months</BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>Welcome Kit</BoInfoLabel>
          <BoInfoValue>
            <WelcomePacketDownload
              premiseId={premise.id}
              orderId={futureOrder.id}
            >
              View
            </WelcomePacketDownload>
          </BoInfoValue>
        </BoInfoContainer>
      </FutureRenewalCard>
    </section>
  );
};
