import { borders } from "@ops-design-system/styles/borders";
import { grey } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

interface BoDividerProps {
  $light?: boolean;
}

export const BoDivider = styled.div<BoDividerProps>`
  border: ${({ $light }) =>
    $light ? `0.5px solid ${grey["100"]}` : borders["100"]};
`;
