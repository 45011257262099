import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { borders } from "@ops-design-system/styles/borders";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import {
  BORDER_RADIUS,
  PADDING,
} from "@ops-design-system/styles/layout.constants";
import { green, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled, { css } from "styled-components";

export interface BoCardProps {
  $variant?: BoCardVariants;
}

export const BoCardLabel = styled(Body2).attrs({ as: "h4" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const BoCard = styled.div<BoCardProps>`
  border: ${borders["100"]};
  border-radius: ${BORDER_RADIUS.default};
  padding: ${PADDING.large.v}px ${PADDING.large.h}px;
  ${({ $variant = BoCardVariants.Normal }) => {
    switch ($variant) {
      case BoCardVariants.Warning: {
        return css`
          border-color: ${red[500]};
        `;
      }

      case BoCardVariants.Success: {
        return css`
          border-color: ${green[500]};
        `;
      }
      default: {
        return "";
      }
    }
  }}
`;
