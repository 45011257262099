import { AddressFormType } from "@common/types/customerTypes";

export class AddressForm implements AddressFormType {
  addressLine1!: string;
  unitNumber?: string;
  city!: string;
  state!: string;
  zipCode!: string;

  public constructor(address: AddressFormType) {
    this.addressLine1 = address.addressLine1;
    this.unitNumber = address.unitNumber;
    this.city = address.city;
    this.state = address.state;
    this.zipCode = address.zipCode;
  }

  public get line1(): string {
    return `${this.addressLine1} ${this.unitNumber || ""}`.trim();
  }

  public get line2(): string {
    return `${this.city}, ${this.state} ${this.zipCode}`;
  }

  public get isEmpty(): boolean {
    return !(
      Boolean(this.addressLine1) &&
      Boolean(this.city) &&
      Boolean(this.zipCode)
    );
  }
  public isEqual({
    addressLine1,
    unitNumber,
    city,
    state,
    zipCode,
  }: AddressForm): boolean {
    return (
      this.addressLine1 === addressLine1 &&
      this.unitNumber === unitNumber &&
      this.city === city &&
      this.state === state &&
      this.zipCode === zipCode
    );
  }
}
