import { FormRow } from "@ops-design-system/styles/common.styles";
import { TransferServiceErrors } from "@ops/components/TransferServiceWizard/shared/enums";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import React from "react";
import styled from "styled-components";

const TransferServiceErrorBody = styled(FormRow)`
  color: red;
  font-size: 18px;
  padding: 220px 0;
  text-align: center;
`;

export const TransferServiceError = () => {
  const {
    tosWizardState: { transferServiceError },
  } = useTransferServiceWizardContext();

  switch (transferServiceError) {
    case TransferServiceErrors.endDateBeforeToday: {
      return (
        <TransferServiceErrorBody>
          Transfer Service cannot be completed. Customer does not have a billing
          order created.
        </TransferServiceErrorBody>
      );
    }
    case TransferServiceErrors.noEndDate:
    case TransferServiceErrors.noPreviousOrder: {
      return (
        <TransferServiceErrorBody>
          Transfer Service cannot be completed. Customer does not have a valid
          subscription.
        </TransferServiceErrorBody>
      );
    }
    default: {
      return (
        <TransferServiceErrorBody>
          Transfer Service cannot be completed.
        </TransferServiceErrorBody>
      );
    }
  }
};
