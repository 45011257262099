import { CreditCheckResultType } from "@common/types/creditCheckTypes";
import { Prospect } from "@ops-utils/models/Prospect.model";

// Validation checks
const requiresDeposit = ({
  requiresDeposit: depositRequired,
  bypassedCreditCheck,
}: Prospect) => depositRequired || (depositRequired && bypassedCreditCheck);
const requiresDepositBypassedCreditCheck = ({
  requiresDeposit: depositRequired,
  bypassedCreditCheck,
}: Prospect) => depositRequired && bypassedCreditCheck;
const ssnRequired = ({
  ssnProvided,
  ssnRequired: ssnReq,
  depositAmount,
}: Prospect) => ssnReq && !ssnProvided && depositAmount !== 0;
const idRequired = ({ needsManualReview, bypassedCreditCheck }: Prospect) =>
  needsManualReview && !bypassedCreditCheck;
const noCreditCheck = ({ hasCreditCheck }: Prospect) => !hasCreditCheck;
const insufficientData = ({
  firstName,
  lastName,
  dateOfBirth,
  phone,
  email,
  serviceAddress,
}: Prospect) =>
  !firstName ||
  !lastName ||
  !dateOfBirth ||
  !phone ||
  !email ||
  !serviceAddress?.addressLine1 ||
  !serviceAddress?.city ||
  !serviceAddress?.state ||
  !serviceAddress?.zipCode;
// nos --> new occupant statement
const nosRequired = ({
  hasCreditCheck,
  needsManualReview,
  nosRequired: nosReq,
  additionalInformationRequired,
}: Prospect) =>
  hasCreditCheck &&
  needsManualReview &&
  (nosReq || additionalInformationRequired);

// The order of these checks is very important! Don't haphazardly change this order.
const creditCheckValidators: [
  CreditCheckResultType,
  (prospect: Prospect) => boolean,
][] = [
  [CreditCheckResultType.requiresDeposit, requiresDeposit],
  [CreditCheckResultType.ssnRequired, ssnRequired],
  [CreditCheckResultType.idRequired, idRequired],
  [CreditCheckResultType.nosRequired, nosRequired],
  [CreditCheckResultType.requiresDeposit, requiresDepositBypassedCreditCheck],
  [CreditCheckResultType.noCreditCheck, noCreditCheck],
  [CreditCheckResultType.insufficientData, insufficientData],
];

export const creditCheckResult = (prospect: Prospect) => {
  const validationErrorCollection = creditCheckValidators.filter(
    ([_, validatorFn]) => validatorFn(prospect)
  );

  const [result] = validationErrorCollection[
    validationErrorCollection.length - 1
  ] ?? [CreditCheckResultType.success];

  return result;
};
