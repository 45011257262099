import { IdType } from "@common/types/apiTypes";

const EXTERNAL_PAYMENTS_BASE_URL = "/api/external-payments";

export const externalPaymentsUrls = {
  files: {
    list: () => `${EXTERNAL_PAYMENTS_BASE_URL}/`,
    unmatchedDownload: (externalPaymentFileId: IdType) =>
      `${EXTERNAL_PAYMENTS_BASE_URL}/${externalPaymentFileId}/missing/download`,
    upload: () => `${EXTERNAL_PAYMENTS_BASE_URL}/`,
  },
  rsaSignature: (): string => `${EXTERNAL_PAYMENTS_BASE_URL}/rsa_signature`,
};
