import {
  BoTable,
  BoTableCell,
} from "@ops-design-system/components/BoTable/BoTable";
import {
  rhOpsSpacingPx,
  visuallyHidden,
} from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledBoTable = styled(BoTable)`
  width: 100%;
`;

export const HiddenCaption = styled.caption`
  ${visuallyHidden}
`;

export const NoResultsMessage = styled(BoTableCell)`
  text-align: center;
`;

export const StyledPageCount = styled.div`
  align-self: center;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  width: 60px;
`;

export const TableFooter = styled.div`
  display: grid;
  grid-template-areas: "selectionCount pagination pageSize";
  grid-template-columns: 120px 1fr 120px;
  margin-top: ${rhOpsSpacingPx(0.5)};
`;

export const PaginationContainer = styled.nav`
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(1)};
  grid-area: pagination;
  justify-self: center;
`;

export const PageSizeLabel = styled.label`
  display: flex;
  flex-direction: column;
  grid-area: pageSize;
  text-align: right;
`;

export const SelectionCountContainer = styled.div`
  grid-area: selectionCount;
  text-align: right;
`;

export const BoReactTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MaxWidthTableCell = styled(BoTableCell)`
  max-width: 250px;
  overflow-wrap: break-word;
`;

export const StyledSortIconContainer = styled.span<{ $isDesc: boolean }>`
  display: inline-block;
  padding-left: ${rhOpsSpacingPx(1)};
  vertical-align: middle;

  > svg {
    height: 12px;
    ${({ $isDesc }) => ($isDesc ? "" : "transform: rotate(180deg);")}
  }
`;
