export const RH_HEADER_HEIGHT = 7.5;
export const ANNOUNCEMENT_HEIGHT = 4;
export const PAGE_GUTTER = 16; // px
export const UNIT_OF_SPACING = 8;
export const INPUT_ACTIVE_UNDERLINE_HEIGHT = 2;
export const MAIN_CONTAINER_WIDTH = 1096;
export const PORTAL_TOP_MARGIN = 2;
export const LOGGED_OUT_TOP_MARGIN_MOBILE = 4;
export const LOGGED_OUT_TOP_MARGIN = 6;
export const FULL_SCREEN_DIALOG_CONTENT_MAX_WIDTH = 900;

export const OPS_CUSTOMER_DETAIL_BUTTON_WIDTH = 350;
export const OPS_CUSTOMER_DETAIL_DIALOG_WIDTH = 300;
export const DIALOG_BUTTON_WIDTH = 180;

// sign up flow
export const OFFER_CONTAINER_WIDTH_4CARD = 1200;
export const OFFER_CONTAINER_WIDTH_3CARD = 960;
export const OFFER_CONTAINER_WIDTH_2CARD = 720;
export const OFFER_CONTAINER_WIDTH_1CARD = 360;
export const LOGGED_OUT_FORM_WIDTH = 350;
export const LOGGED_OUT_PAGE_DENSE_WIDTH = 650;
export const SMALL_MOBILE_LOGGED_OUT_FORM_WIDTH = 288;

// rhythm breakpoints
export enum RhythmBreakpoints {
  XXS = 321,
  XS = 600,
  SM = 960,
  MD = 1280,
  LG = 1920,
}
