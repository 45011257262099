import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { useWizardForm } from "@ops/hooks/useWizardForm";
import React, { PropsWithChildren } from "react";

interface ProspectEditorFormProps {
  id: TabType;
}

export const ProspectEditorForm = ({
  id,
  children,
}: PropsWithChildren<ProspectEditorFormProps>) => {
  const { onSubmit } = useWizardForm();

  return (
    <form noValidate onSubmit={onSubmit} id={id}>
      {children}
    </form>
  );
};
