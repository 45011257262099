import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { ReactComponent as ChevronDown } from "@ops-design-system/icons/ChevronDown.svg";
import { ReactComponent as DropdownArrow } from "@ops-design-system/icons/DropdownArrow.svg";
import {
  activeBorders,
  borders,
  errorBorders,
} from "@ops-design-system/styles/borders";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacing } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import {
  GroupBase,
  SelectComponentsConfig,
  StylesConfig,
  components,
} from "react-select";

export enum SelectVariants {
  Borderless = "Borderless",
  MultiSelect = "MultiSelect",
  Primary = "Primary",
}

type SelectThemeEntryType<
  Option = BoSelectOptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  components: SelectComponentsConfig<Option, IsMulti, Group>;
  styles: (hasErrors?: boolean) => StylesConfig<Option, IsMulti, Group>;
};

type SelectThemesType = {
  [key in
    | SelectVariants.Primary
    | SelectVariants.Borderless]: SelectThemeEntryType;
};

export const SelectThemes: SelectThemesType = {
  [SelectVariants.Primary]: {
    components: {
      DropdownIndicator: (props) => (
        <components.DropdownIndicator {...props}>
          <DropdownArrow />
        </components.DropdownIndicator>
      ),
    },
    styles: (hasErrors: boolean = false) => ({
      container: (provided, props) => {
        return {
          ...provided,
          borderBottom: borderExpand(hasErrors, props.isFocused ?? false),
          paddingTop: "6px",
        };
      },
      control: (provided, props) => ({
        ...provided,
        "&:hover": {},
        borderBottom: borderBottom(hasErrors, props.isFocused),
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        boxShadow: "none",
        fontSize: "16px",
        paddingBottom: "4px",
      }),
      dropdownIndicator: (provided, props) => {
        const color = props.isFocused ? darkPurple["500"] : grey["900"];

        return {
          ...provided,
          "&:hover": {
            color,
          },
          color,
        };
      },
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      option: (provided) => ({
        ...provided,
        color: darkPurple["900"],
      }),
      valueContainer: (provided) => ({
        ...provided,
        display: "flex",
        padding: "2px 0",
      }),
    }),
  },
  [SelectVariants.Borderless]: {
    components: {
      DropdownIndicator: (props) => (
        <components.DropdownIndicator {...props}>
          <ChevronDown width={10} height={10} />
        </components.DropdownIndicator>
      ),
    },
    styles: () => ({
      control: (provided) => ({
        ...provided,
        "&:hover": {},
        borderBottom: "none",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        boxShadow: "none",
        fontSize: "14px",
        fontWeight: FontWeight.Semibold,
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: darkPurple["500"],
        padding: 0,
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      menu: (provided) => ({
        ...provided,
        width: "max-content",
      }),
      option: (provided) => ({
        ...provided,
        color: darkPurple["900"],
        paddingRight: rhOpsSpacing(3.5),
      }),
      singleValue: () => ({
        paddingRight: rhOpsSpacing(1),
      }),
      valueContainer: (provided) => ({
        ...provided,
        display: "flex",
        padding: "2px 0",
      }),
    }),
  },
};

const borderBottom = (hasErrors?: boolean, isFocused?: boolean) => {
  if (hasErrors) {
    return errorBorders["500"];
  } else if (isFocused) {
    return activeBorders["500"];
  } else {
    return borders["300"];
  }
};

const borderExpand = (hasErrors?: boolean, isFocused?: boolean) => {
  if (isFocused || hasErrors) {
    return activeBorders["500"];
  }
};
