import { OktaGroups } from "@common/constants/okta.constant";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { ContractRenewalWizard } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard";
import { ContractRenewal } from "@ops/components/ContractSwapsAndRenewals/ContractRenewal/ContractRenewal";
import { ContractSwap } from "@ops/components/ContractSwapsAndRenewals/ContractSwap/ContractSwap";
import { OfferLookup } from "@ops/components/OfferLookup/OfferLookup";
import React from "react";

export const ContractChangesCard = () => {
  const sectionName = "Contract Changes";

  const { featureFlagClient } = useFeatureFlagClient();
  const { opsRenewalWizard } = featureFlagClient.getFlags([
    ["opsRenewalWizard", false],
  ]);

  return (
    <section aria-label={sectionName}>
      <BoCardLabel>{sectionName}</BoCardLabel>
      <BoCard>
        <ButtonsGrid>
          <AccessControl>
            <OfferLookup />
          </AccessControl>
          <AccessControl allowedGroups={[OktaGroups.CSRTier2]}>
            <ContractSwap />
          </AccessControl>
          {opsRenewalWizard.value ? (
            <ContractRenewalWizard />
          ) : (
            <ContractRenewal />
          )}
        </ButtonsGrid>
      </BoCard>
    </section>
  );
};
