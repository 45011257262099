import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface InvoicePaidData extends EventLogBaseData {
  amount: string;
  billPeriodEnd: string;
  billPeriodStart: string;
  invoiceAmountPaid: string;
  invoiceNumber: string;
  paymentDate: string;
}

export class InvoicePaid extends EventLog {
  public readonly invoiceNumber: string;
  public readonly billPeriodStart: string;
  public readonly billPeriodEnd: string;
  public readonly amount: string;
  public readonly paymentDate: string;
  public readonly invoiceAmountPaid: string;

  constructor({
    invoiceNumber,
    billPeriodStart,
    billPeriodEnd,
    amount,
    paymentDate,
    invoiceAmountPaid,
    ...baseEventData
  }: InvoicePaidData) {
    super(baseEventData);

    this.invoiceNumber = invoiceNumber;
    this.billPeriodStart = formatMonthDayYear(billPeriodStart);
    this.billPeriodEnd = formatMonthDayYear(billPeriodEnd);
    this.amount = amount;
    this.paymentDate = formatMonthDayYear(paymentDate);
    this.invoiceAmountPaid = invoiceAmountPaid;
  }

  override get body() {
    return `${this.name} - Bill Period Start: ${this.billPeriodStart} - Bill Period End: ${this.billPeriodEnd} - Amount: $${this.amount} - Amount Paid: $${this.invoiceAmountPaid} - Payment Date: ${this.paymentDate}`;
  }
}
