import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CustomerClaimSubmittedData extends EventLogBaseData {
  contractProductName: string;
  contractTierName: string;
}

export class CustomerClaimSubmitted extends EventLog {
  public readonly contractProductName: string;
  public readonly contractTierName: string;

  constructor({
    contractProductName,
    contractTierName,
    ...baseEventData
  }: CustomerClaimSubmittedData) {
    super(baseEventData);
    this.contractProductName = contractProductName;
    this.contractTierName = contractTierName;
  }

  override get body() {
    return `${this.name} - Status: In Review - Product Type: ${this.contractProductName} - Product Name: ${this.contractTierName} `;
  }
}
