import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CancelRebillData extends EventLogBaseData {
  actionTaken: string;
  cancelRebillId: string;
}

export class CancelRebill extends EventLog {
  public readonly actionTaken: string;
  public readonly cancelRebillId: string;

  constructor({
    actionTaken,
    cancelRebillId,
    ...baseEventData
  }: CancelRebillData) {
    super(baseEventData);

    this.actionTaken = actionTaken;
    this.cancelRebillId = cancelRebillId;
  }

  override get body() {
    return `${this.name} ${this.cancelRebillId || ""} - Action Taken: ${
      this.actionTaken
    }`;
  }
}
