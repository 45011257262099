import {
  fileIsValidSize,
  formatFileSize,
  getFileName,
  isFileTypeAccepted,
} from "@common/utils/fileHelpers";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectInput } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { StyledInput } from "@ops-design-system/components/BoTextInput/BoTextInput.styled";
import { OpsDragAndDropFileUpload } from "@ops-design-system/components/OpsDragAndDropFileUpload/OpsDragAndDropFileUpload";
import { OpsRadioInput } from "@ops-design-system/components/OpsRadioInput/OpsRadioInput";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as CircleExclamation } from "@ops-design-system/icons/CircleExclamation.svg";
import { ReactComponent as UploadIcon } from "@ops-design-system/icons/UploadIcon.svg";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { CustomerDocumentUploadType } from "@ops-utils/types/customerDocumentTypes";
import {
  StyledFileError,
  StyledUploadCustomerDocumentFormBody,
  StyledUploadCustomerDocumentFormInput,
  StyledUploadDocumentButton,
  StyledUploadDocumentFormRadioContainer,
  StyledUploadDocumentFormRow,
} from "@ops/components/UploadCustomerDocumentModal/UploadCustomerDocumentModal.styled";
import { useCustomerDocumentUploadMutation } from "@ops/hooks/mutations/useCustomerDocumentUpload.mutation";
import { useCustomerDocumentTypesQuery } from "@ops/hooks/queries/useCustomerDocumentTypes.query";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

interface UploadCustomerDocumentModalProps {
  customerId: string;
  premiseAccountNumber: string;
  premiseId: string;
}

type UploadCustomerDocumentFormType = {
  documentName?: string;
  documentType: string;
  file: File | null;
  uploadLevel: "premise" | "customer";
};

const MAX_FILE_SIZE_MB = 50;
const ACCEPTED_TYPES = ".pdf,.png,.jpg,.jpeg";

export const UploadCustomerDocumentModal = (
  props: UploadCustomerDocumentModalProps
) => {
  const { customerId, premiseId, premiseAccountNumber } = props;
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid, errors },
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm<UploadCustomerDocumentFormType>({
    defaultValues: {
      documentName: "",
      documentType: "",
      file: null,
      uploadLevel: "customer",
    },
  });

  const selectedDocumentType = watch("documentType");
  const selectedFile = watch("file");

  const documentTypesQuery = useCustomerDocumentTypesQuery();
  const customerDocumentUploadMutation = useCustomerDocumentUploadMutation();
  const flash = useRhFlash();

  useEffect(() => {
    if (documentTypesQuery.isError) {
      flash.error("Could not retrieve document types. Please try again later.");
    }
  }, [documentTypesQuery.isError, flash]);

  const documentTypeOptions = useMemo(
    () =>
      (documentTypesQuery.data?.types || []).map((docType) => ({
        label: docType,
        value: docType,
      })),
    [documentTypesQuery.data]
  );

  const onSubmit = (data: UploadCustomerDocumentFormType) => {
    if (!data.file) {
      return;
    }

    const documentName = data.documentName || getFileName(data.file);

    const uploadData: CustomerDocumentUploadType = {
      customer: customerId,
      documentName,
      documentType: data.documentType,
      file: data.file,
      premise: data.uploadLevel === "premise" ? premiseId : "",
    };

    customerDocumentUploadMutation.mutate(uploadData, {
      onError: () =>
        flash.error(
          "Something went wrong while uploading customer document. Please try again!"
        ),
      onSuccess: () => {
        flash.success("Customer document upload successfully.");
        handleClose();
      },
    });
  };

  const handleClose = () => {
    setIsUploadModalOpen(false);
    reset();
  };

  const handleFileUpload = (files: FileList | null) => {
    clearErrors("file");

    if (!files || files.length <= 0) {
      setValue("file", null, { shouldValidate: true });
      return;
    }

    const newFile = files[0];

    if (!fileIsValidSize(newFile, MAX_FILE_SIZE_MB * 1024 * 1024)) {
      setValue("file", null);
      setError("file", {
        message: `Error: file is too big (${MAX_FILE_SIZE_MB}MB max)`,
        type: "manual",
      });
      return;
    }

    if (!isFileTypeAccepted(newFile, ACCEPTED_TYPES)) {
      setValue("file", null);
      setError("file", {
        message: "Error: file type not supported",
        type: "manual",
      });
      return;
    }

    setValue("file", newFile, { shouldValidate: true });
  };

  return (
    <>
      <StyledUploadDocumentButton
        variant="secondary"
        icon={UploadIcon}
        size="extraSmall"
        onClick={() => setIsUploadModalOpen(true)}
      >
        Upload Document
      </StyledUploadDocumentButton>
      <BoModal open={isUploadModalOpen} onClose={handleClose}>
        <BoDialogHeader>Upload Document</BoDialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BoDialogBody>
            <StyledUploadCustomerDocumentFormBody>
              <StyledUploadDocumentFormRow>
                <StyledUploadDocumentFormRadioContainer>
                  <OpsRadioInput
                    id="customer"
                    value="customer"
                    {...register("uploadLevel")}
                  />
                  <label htmlFor="customer">
                    <Body1>Customer ID: </Body1>
                    <Body1 $fontWeight="Bold">{customerId}</Body1>
                  </label>
                </StyledUploadDocumentFormRadioContainer>
                <StyledUploadDocumentFormRadioContainer>
                  <OpsRadioInput
                    id="premise"
                    value="premise"
                    {...register("uploadLevel")}
                  />
                  <label htmlFor="premise">
                    <Body1>Billing Account Number: </Body1>
                    <Body1 $fontWeight="Bold">{premiseAccountNumber}</Body1>
                  </label>
                </StyledUploadDocumentFormRadioContainer>
              </StyledUploadDocumentFormRow>
              <StyledUploadCustomerDocumentFormInput>
                <BoLabel htmlFor="documentType" requiredIndicator>
                  Document Type
                </BoLabel>
                <Controller
                  control={control}
                  name="documentType"
                  rules={{ required: true }}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <BoSelectInput
                      id="documentType"
                      onBlur={onBlur}
                      onChange={(option) => onChange(option?.value)}
                      value={
                        documentTypeOptions.find(
                          (option) => option.value === value
                        )?.value
                      }
                      ariaLabelledby="document_type"
                      placeholder="Choose Document"
                      options={documentTypeOptions}
                    />
                  )}
                />
              </StyledUploadCustomerDocumentFormInput>
              {selectedDocumentType?.toLocaleLowerCase() === "other" ? (
                <StyledUploadCustomerDocumentFormInput>
                  <BoLabel htmlFor="documentName">
                    Document Name (Optional)
                  </BoLabel>
                  <StyledInput
                    id="documentName"
                    {...register("documentName")}
                  />
                </StyledUploadCustomerDocumentFormInput>
              ) : null}
              <StyledUploadCustomerDocumentFormInput>
                <BoLabel htmlFor="file" requiredIndicator>
                  Upload Document
                </BoLabel>
                <Controller
                  name="file"
                  control={control}
                  rules={{
                    required: "Document is required",
                  }}
                  render={() => (
                    <OpsDragAndDropFileUpload
                      accept={ACCEPTED_TYPES}
                      maxFileSize={`${MAX_FILE_SIZE_MB}MB`}
                      onFileUpload={handleFileUpload}
                      {...(selectedFile
                        ? {
                            fileName: selectedFile.name,
                            fileSize: formatFileSize(selectedFile),
                          }
                        : {})}
                    />
                  )}
                />
                {errors.file && (
                  <StyledFileError>
                    <CircleExclamation />
                    <Body2>{errors.file.message}</Body2>
                  </StyledFileError>
                )}
              </StyledUploadCustomerDocumentFormInput>
            </StyledUploadCustomerDocumentFormBody>
          </BoDialogBody>
          <BoDialogButtonFooter
            cancelBtnText="Cancel"
            onCancel={handleClose}
            confirmBtnText="Submit"
            confirmBtnType="submit"
            confirmDisabled={!isValid || isSubmitting}
          />
        </form>
      </BoModal>
    </>
  );
};
