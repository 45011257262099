import { ajax } from "@common/services/ajax";
import {
  AccountSummaryResponseType,
  PaginationEndpointResponseType,
} from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  StatusIndicatorType,
  StatusIndicatorUpdateType,
} from "@common/types/statusIndicatorTypes";
import { ThermostatSearchOptions } from "@common/types/thermostatTypes";
import { UsageType } from "@common/types/usageTypes";
import { zodIsValid } from "@common/utils/zodHelper";
import {
  DeviceConnectionActivityType,
  DeviceDemandResponseActivityType,
  DevicesType,
} from "@ops-utils/types/deviceTypes";
import {
  RhapsodyOrderType,
  RhapsodyOrderZod,
} from "@ops-utils/types/orderTypes";
import {
  CreatePaymentDataType,
  CreatePaymentResponseType,
} from "@ops-utils/types/paymentTypes";
import { PremiseDepositAlternativeType } from "@ops-utils/types/premiseTypes";
import { OneClickRenewalOfferSnapshotResponse } from "@ops-utils/types/renewalTypes";
import {
  BillForecastSummaryType,
  KwhUsageSummaryType,
  TemperatureSummaryType,
} from "@ops-utils/types/usageTypes";
import { z } from "zod";

const BASE_PATH_V1 = "/api/rhapsody/premises";
const BASE_PATH_V2 = "/api/rhapsody/v2/premises";

export const rhapsodyPremiseApi = {
  accountSummary: {
    retrieve: (premiseId: IdType): Promise<AccountSummaryResponseType> => {
      return ajax.get(`${BASE_PATH_V2}/${premiseId}/account_summary/`);
    },
  },
  attachedDevices: (premiseId: IdType) =>
    ajax.get<DevicesType>(`${BASE_PATH_V2}/${premiseId}/attached-devices`),
  billForecastSummary: (premiseId: IdType): Promise<BillForecastSummaryType> =>
    ajax.get(`${BASE_PATH_V2}/${premiseId}/bill-forecast-summary`),
  communicationPreferences: ({
    premiseId,
    paperless,
  }: {
    paperless: boolean;
    premiseId: IdType;
  }) =>
    ajax.post<null>(`${BASE_PATH_V2}/${premiseId}/communication-preferences`, {
      paperless,
    }),
  createPayment: (
    premiseId: IdType,
    payload: CreatePaymentDataType
  ): Promise<CreatePaymentResponseType> =>
    ajax.post(`${BASE_PATH_V2}/${premiseId}/create-payment/`, payload),
  depositAlternative: {
    retrieve: (premiseId: IdType): Promise<PremiseDepositAlternativeType> => {
      return ajax.get(`${BASE_PATH_V2}/${premiseId}/deposit-alternative`);
    },
  },
  kwhUsageDay: (
    premiseId: IdType,
    targetDate: string
  ): Promise<UsageType[]> => {
    return ajax.get(`${BASE_PATH_V2}/${premiseId}/kwh-usage-day`, {
      params: {
        targetDate,
      },
    });
  },
  kwhUsageSummary: (premiseId: IdType): Promise<KwhUsageSummaryType> => {
    return ajax.get(`${BASE_PATH_V2}/${premiseId}/kwh-usage-summary`);
  },
  oneClickRenewalOfferSnapshots: (
    premiseId: IdType
  ): Promise<OneClickRenewalOfferSnapshotResponse> =>
    ajax.get(`${BASE_PATH_V2}/${premiseId}/one-click-renewal-offer-snapshots`),
  orders: async (premiseId: IdType) => {
    const result = await ajax.get<RhapsodyOrderType[]>(
      `${BASE_PATH_V2}/${premiseId}/orders/`
    );

    const schema = z.array(RhapsodyOrderZod);

    zodIsValid(schema, result);

    return result;
  },
  quickPay: {
    sendPaymentLink: (premiseId: IdType): Promise<void> => {
      return ajax.post(`${BASE_PATH_V1}/${premiseId}/quick-pay-token/`, {});
    },
  },
  renewContract: ({
    premiseId,
    paperless,
    offerSnapshotId,
  }: {
    offerSnapshotId: IdType;
    paperless: boolean;
    premiseId: IdType;
  }) =>
    ajax.post<null>(`${BASE_PATH_V2}/${premiseId}/renew-contract`, {
      offerSnapshotId,
      paperless,
    }),
  statusIndicators: {
    premiseAssignedStatusIndicators: {
      list: (premiseId: IdType): Promise<StatusIndicatorType[]> => {
        return ajax.get(`${BASE_PATH_V1}/${premiseId}/status-indicators/`);
      },
      update: (
        premiseId: IdType,
        premiseIndicators: StatusIndicatorUpdateType[]
      ): Promise<void> => {
        return ajax.post(`${BASE_PATH_V1}/${premiseId}/status-indicators/`, {
          premiseIndicators,
        });
      },
    },
    premiseTypeStatusIndicators: {
      list: (): Promise<StatusIndicatorType[]> => {
        return ajax.get(`${BASE_PATH_V1}/status-indicators/`);
      },
    },
  },
  temperatureSummary: (premiseId: IdType) =>
    ajax.get<TemperatureSummaryType>(
      `${BASE_PATH_V2}/${premiseId}/temperature-summary`
    ),
  thermostatConnectionActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: ThermostatSearchOptions;
  }) =>
    ajax.get<PaginationEndpointResponseType<DeviceConnectionActivityType>>(
      `${BASE_PATH_V2}/${premiseId}/thermostat-connection-activity/`,
      {
        params: {
          ...searchOptions,
        },
      }
    ),
  thermostatDemandResponseActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: ThermostatSearchOptions;
  }) =>
    ajax.get<PaginationEndpointResponseType<DeviceDemandResponseActivityType>>(
      `${BASE_PATH_V2}/${premiseId}/thermostat-demand-response-activity/`,
      {
        params: {
          ...searchOptions,
        },
      }
    ),
};
