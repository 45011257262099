import { RhythmCampaignSlugs } from "@common/constants/campaigns.constant";
import { OfferSnapshotSearchOptions } from "@ops-utils/types/offerSnapshotTypes";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { BoCampaignSlugSelect } from "@ops/components/BoCampaignSlugSelect/BoCampaignSlugSelect";
import {
  SelectContainer,
  SelectLabel,
} from "@ops/components/BoCampaignSlugSelect/layoutComponents";
import { ActiveFilters } from "@ops/components/OfferSnapshotFiltering/ActiveFilters/ActiveFilters";
import { FilterMenu } from "@ops/components/OfferSnapshotFiltering/FilterMenu/FilterMenu";
import {
  StyledContainer,
  StyledFilterAndSortRow,
} from "@ops/components/OfferSnapshotFiltering/OfferSnapshotFiltering.styled";
import { OfferSnapshotSorting } from "@ops/components/OfferSnapshotFiltering/OfferSnapshotSorting/OfferSnapshotSorting";
import React from "react";

interface BoOffersFilterAndSortProps {
  searchOptions: OfferSnapshotSearchOptions;
  setSearchOptions: (value: OfferSnapshotSearchOptions) => void;
}

export const OfferSnapshotFiltering = ({
  searchOptions,
  setSearchOptions,
}: BoOffersFilterAndSortProps) => {
  const handleSlugChange = (slug: RhythmCampaignSlugs | null) =>
    setSearchOptions({ ...searchOptions, campaignSlug: slug });

  const handleFiltersChange = (data: OfferSnapshotSearchOptions) => {
    setSearchOptions({
      ...searchOptions,
      ...data,
    });
  };

  return (
    <StyledContainer>
      <StyledFilterAndSortRow>
        <FilterMenu
          searchOptions={searchOptions}
          onSubmit={handleFiltersChange}
        />
        <OfferSnapshotSorting
          value={searchOptions.ordering}
          onChange={(value) =>
            setSearchOptions({ ...searchOptions, ordering: value })
          }
        />
        <AccessControl>
          <SelectContainer>
            <SelectLabel id="override_campaign_slug">
              Override Campaign Slug
            </SelectLabel>
            <BoCampaignSlugSelect
              ariaLabelledby="override_campaign_slug"
              onChange={handleSlugChange}
              value={searchOptions.campaignSlug as RhythmCampaignSlugs}
            />
          </SelectContainer>
        </AccessControl>
      </StyledFilterAndSortRow>
      {searchOptions && (
        <ActiveFilters
          filters={searchOptions}
          setFilters={handleFiltersChange}
        />
      )}
    </StyledContainer>
  );
};
