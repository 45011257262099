import { premiseApi } from "@common/api/premiseApi";
import { CreatePaymentExtensionPayloadType } from "@common/types/billingTypes";
import { useMutation } from "@tanstack/react-query";

export const useCreatePaymenExtensiontMutation = () => {
  return useMutation({
    mutationFn: ({
      premiseId,
      paymentExtensionData,
    }: {
      paymentExtensionData: CreatePaymentExtensionPayloadType;
      premiseId: string;
    }) => premiseApi.paymentExtensions.create(premiseId, paymentExtensionData),
  });
};
