import { ExternalPaymentMethodType } from "@common/types/paymentMethodTypes";

export class ExternalPaymentMethod {
  id: string;
  type: string;

  constructor(paymentMethod: ExternalPaymentMethodType) {
    this.id = paymentMethod.id;
    this.type = paymentMethod.type;
  }
}
