import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { BatchContactLogUpload } from "@ops/models/BatchContactLogUpload";
import React from "react";

interface BatchContactLogUploadTableProps {
  batchContactLogUploads: BatchContactLogUpload[] | null;
}

export const BatchContactLogUploadTable = ({
  batchContactLogUploads,
}: BatchContactLogUploadTableProps) => {
  return (
    <BoTable>
      <BoTableHead>
        <BoTableRow>
          <BoTableHeadCell>Upload Date</BoTableHeadCell>
          <BoTableHeadCell>Time of Upload</BoTableHeadCell>
          <BoTableHeadCell>File Name</BoTableHeadCell>
          <BoTableHeadCell>Status</BoTableHeadCell>
          <BoTableHeadCell>Reason of Status</BoTableHeadCell>
          <BoTableHeadCell>Log Count</BoTableHeadCell>
        </BoTableRow>
      </BoTableHead>
      <BoTableBody>
        {(batchContactLogUploads ?? []).map((batchContactLogUpload) => (
          <BoTableRow key={batchContactLogUpload.createdAt.toISOString()}>
            <BoTableCell>{batchContactLogUpload.uploadDate}</BoTableCell>
            <BoTableCell>{batchContactLogUpload.timeOfUpload}</BoTableCell>
            <BoTableCell>{batchContactLogUpload.fileName}</BoTableCell>
            <BoTableCell>{batchContactLogUpload.status}</BoTableCell>
            <BoTableCell>{batchContactLogUpload.reasonOfStatus}</BoTableCell>
            <BoTableCell>{batchContactLogUpload.logCount}</BoTableCell>
          </BoTableRow>
        ))}
      </BoTableBody>
    </BoTable>
  );
};
