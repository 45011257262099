import { rewardsApi } from "@common/api/rewardsApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  opsAndPricingIsPositiveNumeric,
  opsAndPricingIsRequired,
} from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import {
  RewardNames,
  RewardType,
  RewardsResponseType,
} from "@common/types/rewardsTypes";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import {
  StyledAmount,
  StyledAmountInputWrapper,
  StyledButtonContainer,
  StyledFields,
} from "@ops/components/AddRewardsPoints/AddRewardsPoints.styled";
import { RewardPointsWithValue } from "@ops/components/RewardPointsWithValue/RewardPointsWithValue";
import dayjs from "dayjs";
import React from "react";
import { Form } from "react-final-form";

const validator = generateValidationErrorCollector<AddRewardPointsFormValues>({
  points: [opsAndPricingIsPositiveNumeric],
  type: [opsAndPricingIsRequired],
});

interface AddRewardPointsFormValues {
  points: string;
  type: RewardNames;
}

interface AddRewardsPointsProps {
  customer: Customer;
  onSuccess: (newReward: RewardType) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  rewards?: RewardsResponseType;
}

export const AddRewardsPoints = ({
  customer,
  onSuccess,
}: AddRewardsPointsProps) => {
  const flash = useRhFlash();
  const { open, close, isOpen } = useModalState();
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();
  const rewardTypeOptions = [
    { label: "ETF Reimburse", value: RewardNames.ETFCredit },
    { label: "Rhythm Rewards", value: RewardNames.RhythmRewards },
    {
      label: "Averaged Billing Opt In",
      value: RewardNames.AveragedBillingOptIn,
    },
    { label: "Courtesy Credit", value: RewardNames.CourtesyCredit },
    { label: "Late Fee Waiver", value: RewardNames.LateFeeWaiver },
    { label: "ReRate Credit", value: RewardNames.ReRateCredit },
    { label: "Fee Waiver", value: RewardNames.FeeWaiver },
    { label: "ETF Waiver", value: RewardNames.ETFWaiver },
  ];
  const handleSuccess = (reward: RewardType) => {
    setSuccess();
    onSuccess(reward);
    close();
  };

  const handleSubmit = ({
    type,
    points: givenPoints,
  }: AddRewardPointsFormValues) => {
    const points = parseInt(givenPoints, 10);
    const reward: RewardType = {
      createdAt: dayjs().toISOString(),
      name: type,
      points,
      redeemedAt: null,
      value: rewardPointsToDollars(points),
    };

    setPending();
    rewardsApi.customers
      .addPoints(customer.id, reward.points, type)
      .then(() => handleSuccess(reward))
      .catch(() => {
        setFailure();
        flash.error(
          "There was a problem adding points to this customer.  Please try again later."
        );
      });
  };

  return (
    <>
      <StyledButtonContainer>
        <BoButton onClick={open} size="extraSmall" variant="secondary">
          Add Points
        </BoButton>
      </StyledButtonContainer>
      <BoModal open={isOpen} onClose={close}>
        <Form<AddRewardPointsFormValues>
          onSubmit={handleSubmit}
          initialValues={{ points: "" }}
          validate={validator}
          render={({
            handleSubmit: handleFormSubmit,
            valid,
            values,
            dirty,
          }) => {
            return (
              <form onSubmit={handleFormSubmit}>
                <BoDialogHeader>Add Reward Points</BoDialogHeader>
                <BoDialogBody>
                  {requestMonitor.isPending ? (
                    <BoCircularProgress />
                  ) : (
                    <StyledFields>
                      <BoSelectField
                        label="Reason"
                        name="type"
                        options={rewardTypeOptions}
                      />
                      <StyledAmountInputWrapper>
                        <BoTextField
                          name="points"
                          type="number"
                          inputMode="numeric"
                        >
                          Amount
                        </BoTextField>
                        <StyledAmount>
                          <RewardPointsWithValue points={values.points} />
                        </StyledAmount>
                      </StyledAmountInputWrapper>
                    </StyledFields>
                  )}
                </BoDialogBody>
                <BoDialogButtonFooter
                  confirmDisabled={!valid || !dirty || requestMonitor.isPending}
                  confirmBtnText="Add Points"
                  confirmBtnType="submit"
                  cancelBtnText="Close"
                  onCancel={close}
                />
              </form>
            );
          }}
        />
      </BoModal>
    </>
  );
};
