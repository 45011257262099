import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { RhythmCampaignSlugs } from "@common/constants/campaigns.constant";
import { OktaGroups } from "@common/constants/okta.constant";
import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as Extension } from "@ops-design-system/icons/Extension.svg";
import {
  OfferSnapshotOrderOptions,
  OfferSnapshotSearchOptions,
} from "@ops-utils/types/offerSnapshotTypes";
import {
  DateHeader,
  EmptyOfferMessage,
  Header,
  HeaderSubsection,
  OfferSectionsWrapper,
  OffersSection,
  StyledLabel,
  TermsCheckboxRow,
} from "@ops/components/BoCampaignSlugSelect/layoutComponents";
import {
  ContractRenewalDivider,
  DateContainer,
  NewContractDatesContainer,
} from "@ops/components/ContractSwapsAndRenewals/ContractRenewal/ContractRenewal.styled";
import { EmailOutReachOfferSnapshots } from "@ops/components/ContractSwapsAndRenewals/ContractRenewal/EmailOutReachOfferSnapshots";
import { OfferSnapshotCardGroup } from "@ops/components/ContractSwapsAndRenewals/ContractRenewal/OfferSnapshotCardGroup";
import { useContractFormState } from "@ops/components/ContractSwapsAndRenewals/shared/useContractFormState.hook";
import { OfferSnapshotFiltering } from "@ops/components/OfferSnapshotFiltering/OfferSnapshotFiltering";
import { PlanInfo } from "@ops/components/PlanInfo/PlanInfo";
import { termsAgreement } from "@ops/constants/contractTerms.constants";
import { usePremiseRenewContract } from "@ops/hooks/mutations/usePremiseRenewContract";
import { usePremise } from "@ops/hooks/usePremise";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const initialSearchOptions = {
  campaignSlug: RhythmCampaignSlugs.InboundTelemarketingRenewal,
  dunsNumber: "",
  limit: 30,
  offerLabels: [],
  ordering: OfferSnapshotOrderOptions["Price: Low to High"],
  planType: [],
  termMonths: [],
} satisfies OfferSnapshotSearchOptions;

export const ContractRenewal = () => {
  const { open, close, isOpen } = useModalState();
  const {
    selectedOfferSnapshot,
    agreedToTerms,
    reset: resetState,
    handleOfferSnapshotSelected,
    handleToggleTermsCheckbox,
  } = useContractFormState({
    defaultSlug: RhythmCampaignSlugs.InboundTelemarketingRenewal,
  });

  const dispatch = useDispatch();
  const flash = useRhFlash();
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);

  const renewContractMutation = usePremiseRenewContract();

  const { groups } = useContext(AuthGroupsContext);
  const allowedRenewAnytime =
    !!groups &&
    (groups.includes(OktaGroups.CSRTier2) ||
      groups.includes(OktaGroups.CSRTier3) ||
      groups.includes(OktaGroups.Ops));

  const [searchOptions, setSearchOptions] =
    useState<OfferSnapshotSearchOptions>(initialSearchOptions);

  if (!premise) {
    return null;
  }

  const handleClose = () => {
    resetState();
    setSearchOptions(initialSearchOptions);
    close();
  };

  const handleSubmit = () => {
    if (!selectedOfferSnapshot) {
      flash.error("An offerSnapshot must be selected in order to proceed.");
      return;
    } else if (!agreedToTerms) {
      flash.error("The terms must be accepted in order to proceed");
      return;
    }

    renewContractMutation.mutate(
      { offerSnapshotId: selectedOfferSnapshot.id, premiseId: premise.id },
      {
        onError: () => {
          flash.error(
            "Issue updating contract for this premise. Please try again."
          );
        },
        onSuccess: () => {
          flash.success("Contract renewal for premise successful");
          dispatch(premiseRefetch(premise.id));
          handleClose();
        },
      }
    );
  };

  const monthToMonth = premise.currentOrder?.isMonthToMonth;

  const expectedEndDate = () => {
    if (selectedOfferSnapshot) {
      return formatMonthDayYear(
        dayjs(premise.currentOrder?.renewalStartDate)
          .add(selectedOfferSnapshot?.termMonths, "months")
          .toString()
      );
    }
    return "";
  };
  const dunsNumber = premise?.currentOrder?.dunsNumber;

  const { inRenewalWindow } = premise;

  const visible = allowedRenewAnytime || monthToMonth || inRenewalWindow;

  return (
    <>
      {visible ? (
        <BoButton
          onClick={open}
          variant="secondary"
          size="extraSmall"
          icon={Extension}
          aria-label="Open Renew Contract Modal"
        >
          Renew Contract
        </BoButton>
      ) : null}

      {isOpen ? (
        <BoModal open={isOpen} onClose={handleClose} size="large">
          <BoDialogHeader>Renew Contract</BoDialogHeader>
          <BoDialogBody>
            <Header>Current Contract</Header>
            <PlanInfo showDocuments={false} />
            <ContractRenewalDivider />
            <HeaderSubsection>
              <div>
                <Header>New Contract</Header>
                <DateContainer>
                  <NewContractDatesContainer>
                    <DateHeader>Start Date</DateHeader>
                    <Body2>
                      {formatMonthDayYear(
                        premise?.currentOrder?.renewalStartDate ?? ""
                      )}
                    </Body2>
                  </NewContractDatesContainer>
                  <NewContractDatesContainer>
                    <DateHeader>End Date</DateHeader>
                    <Body2>{expectedEndDate()}</Body2>
                  </NewContractDatesContainer>
                </DateContainer>
              </div>
            </HeaderSubsection>
            <OfferSnapshotFiltering
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
            />
            <form noValidate onSubmit={handleSubmit}>
              <OfferSectionsWrapper>
                <OffersSection>
                  {dunsNumber ? (
                    <OfferSnapshotCardGroup
                      selectedOfferSnapshotId={selectedOfferSnapshot?.id}
                      offerSnapshotListSearchOptions={{
                        ...searchOptions,
                        dunsNumber,
                      }}
                      onOfferSnapshotSelect={handleOfferSnapshotSelected}
                    />
                  ) : (
                    <EmptyOfferMessage>No plans available.</EmptyOfferMessage>
                  )}
                </OffersSection>
                <EmailOutReachOfferSnapshots
                  premiseId={premise.id}
                  selectedOfferSnapshot={selectedOfferSnapshot}
                  handleOfferSnapshotSelected={handleOfferSnapshotSelected}
                />
              </OfferSectionsWrapper>
              <TermsCheckboxRow>
                <BoCheckboxInput
                  id="termsAgreement"
                  onChange={handleToggleTermsCheckbox}
                />
                <StyledLabel htmlFor="termsAgreement">
                  {termsAgreement}
                </StyledLabel>
              </TermsCheckboxRow>
            </form>
          </BoDialogBody>
          <BoDialogButtonFooter
            confirmBtnText="Renew Contract"
            confirmBtnType="submit"
            cancelBtnText="Cancel"
            onCancel={handleClose}
            onConfirm={handleSubmit}
            confirmDisabled={
              !agreedToTerms ||
              !selectedOfferSnapshot ||
              renewContractMutation.isPending
            }
            confirmLoading={renewContractMutation.isPending}
          />
        </BoModal>
      ) : null}
    </>
  );
};
