import {
  isProduction,
  isStaging,
} from "@common/services/getEnvApplicationSettings.service";
import { isbot } from "isbot";

export const isBot = (): boolean => {
  const {
    navigator: { userAgent },
  } = window;

  // https://docs.datadoghq.com/synthetics/guide/identify_synthetics_bots/?tab=browsertests#user-agent
  if (userAgent.includes("DatadogSynthetics") && isProduction()) {
    return true;
  }

  // Make sure Datadog tests for staging can hit the APIs and do stuff correctly
  if (userAgent.includes("DatadogSynthetics") && isStaging()) {
    return false;
  }

  return isbot(userAgent);
};
