import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface PaymentExtensionCreatedData extends EventLogBaseData {
  amountPaid: string;
  balance: string;
  downPaymentPercentage: string;
  dueDate: string;
}

export class PaymentExtensionCreated extends EventLog {
  public readonly amountPaid: string;
  public readonly balance: string;
  public readonly downPaymentPercentage: string;
  public readonly dueDate: string;

  constructor({
    amountPaid,
    balance,
    downPaymentPercentage,
    dueDate,
    ...baseEventData
  }: PaymentExtensionCreatedData) {
    super(baseEventData);

    this.balance = balance;
    this.amountPaid = amountPaid;
    this.downPaymentPercentage = downPaymentPercentage;
    this.dueDate = formatMonthDayYear(dueDate);
  }

  override get body(): string | null {
    const baseMessage = `${this.name}\nExtension Date: ${this.dueDate}\nAmount Extended: ${formatCurrency(this.balance)}`;

    if (this.amountPaid) {
      return `${baseMessage} - Down Payment: ${formatCurrency(this.amountPaid)} - Down Payment Percentage: ${this.downPaymentPercentage}%`;
    } else {
      return baseMessage;
    }
  }
}
