import { AjaxStatuses, IdType } from "@common/types/apiTypes";
import { CustomerType } from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { CustomerStateType } from "@ops-utils/types/opsSlicesTypes";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import merge from "lodash/merge";

export const initialCustomerState = Object.freeze<CustomerStateType>({
  data: null,
  error: null,
  requestStatus: AjaxStatuses.Idle,
});

// Handlers
export const customerUpdatedHandler: CaseReducer<
  CustomerStateType,
  PayloadAction<Partial<CustomerType>>
> = (state, action) => ({
  data: merge({}, state.data, action.payload),
  error: state.error,
  requestStatus: state.requestStatus,
});

export const customerRequestedHandler: CaseReducer<
  CustomerStateType,
  PayloadAction
> = (state) => ({
  ...state,
  requestStatus: AjaxStatuses.Pending,
});

export const customerReceivedHandler: CaseReducer<
  CustomerStateType,
  PayloadAction<CustomerType>
> = (state, action) => ({
  data: action.payload,
  error: null,
  requestStatus: AjaxStatuses.Success,
});

export const customerErroredHandler: CaseReducer<
  CustomerStateType,
  PayloadAction<RhApiError>
> = (state, action) => ({
  data: state.data,
  error: action.payload,
  requestStatus: AjaxStatuses.Failure,
});

export const customerClearHandler: CaseReducer<
  CustomerStateType,
  PayloadAction
> = (_state) => ({
  ...initialCustomerState,
});

export const customerCancelHandler: CaseReducer<
  CustomerStateType,
  PayloadAction
> = customerClearHandler;

const customerReducers = {
  cancel: customerCancelHandler,
  clear: customerClearHandler,
  errored: customerErroredHandler,
  received: customerReceivedHandler,
  requested: customerRequestedHandler,
  updated: customerUpdatedHandler,
};

const customerSlice = createSlice<CustomerStateType, typeof customerReducers>({
  initialState: initialCustomerState,
  name: "customer",
  reducers: customerReducers,
});

export const customerReducer = customerSlice.reducer;
export const customerUpdated = customerSlice.actions.updated;
export const customerRequested = customerSlice.actions.requested;
export const customerReceived = customerSlice.actions.received;
export const customerErrored = customerSlice.actions.errored;
export const customerClear = customerSlice.actions.clear;
export const customerCancel = customerSlice.actions.cancel;

export const customerFetch = createAction<IdType>(
  `${customerSlice.name}/fetch`
);
export const customerRefetch = createAction<IdType>(
  `${customerSlice.name}/refetch`
);
export const customerRefetchWithPremise = createAction<{
  customerId: IdType;
  premiseId?: IdType;
}>(`${customerSlice.name}/refetchWithPremise`);
