import { Premise } from "@common/models/Premise.model";
import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { OpsStoreType } from "@ops-utils/types/opsSlicesTypes";

export const selectPremiseState = ({
  premise,
}: Pick<OpsStoreType, "premise">) => {
  const { data, error, requestStatus } = premise;

  return {
    error,
    premise: data ? new Premise(data) : null,
    status: new RequestMonitor(requestStatus),
  };
};
