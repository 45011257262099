import { IdType } from "@common/types/apiTypes";

export const affiliatesPath = () => {
  return "/affiliates";
};

export const newAffiliatePath = () => {
  return "/affiliates/new";
};

export const affiliateDetailPath = (affiliateId: string) => {
  return `/affiliates/${affiliateId}`;
};

export const affiliateEditPath = (affiliateId: string) => {
  return `${affiliateDetailPath(affiliateId)}/edit`;
};

export const customerDetailPath = (customerId: string) => {
  return `/customers/${customerId}`;
};

/**
 * @deprecated
 * If premise is know, please use premiseCustomerPath instead.
 * This should only be used in cases where premise is not known, like search results, and enrollment sign up confirmation.
 */
export const customerPath = (
  customerId: string,
  opts?: Record<string, string>
) => {
  const searchParams = new URLSearchParams(opts);

  return `/ops/customers/${customerId}/${
    opts ? `?${searchParams.toString()}` : ""
  }`;
};

export const premiseCustomerPath = (
  customerId: string,
  premiseId: string,
  currentTab?: string
) => {
  if (!currentTab) {
    return `/ops/customers/${customerId}/premise/${premiseId}/`;
  }

  const postfix = currentTab.startsWith("#") ? currentTab : `#${currentTab}`;

  return `/ops/customers/${customerId}/premise/${premiseId}/${postfix}`;
};

export const customerSearchPath = () => {
  return "/customers/search";
};

export const homePath = (): string => {
  return "/";
};

export const signInPath = (): string => {
  return "/sign-in";
};

export const logoutPath = (): string => {
  return "/logout";
};

export const unauthorizedPath = (): string => {
  return "/unauthorized";
};

export const loginCallbackPath = (): string => {
  return "/implicit/callback";
};

export const financeRootPath = () => {
  return "/finance";
};

export const financeFampPath = () => {
  return `${financeRootPath()}/famp`;
};

export const prospectsHomePath = () => {
  return "/prospects";
};

export const prospectPath = (prospectId: IdType) => {
  return `/prospects/${prospectId}`;
};

export const adminPaths = {
  acquisitionCampaigns: () => "/admin/acquisition-campaigns",
  contactLogs: () => "/admin/contact-logs",
  fidelityPayments: () => "/admin/fidelity-payments",
  home: () => "/admin",
  reprocessInvoice: () => "/admin/reprocess-invoice",
  transferEdiMessage: () => "/admin/transfer-edi-message",
};
