import { maskCurrency } from "@common/forms/currency.mask";
import {
  BoTextField,
  BoTextFieldProps,
} from "@ops-design-system/components/BoTextField/BoTextField";
import React, { PropsWithChildren } from "react";

interface BoCurrencyFieldProps extends BoTextFieldProps<string> {}

export const BoCurrencyField = ({
  name,
  disabled,
  children,
  ...rest
}: PropsWithChildren<BoCurrencyFieldProps>) => {
  return (
    <BoTextField
      name={name}
      inputMode="numeric"
      format={maskCurrency}
      disabled={disabled}
      {...rest}
    >
      {children}
    </BoTextField>
  );
};
