import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import { IdType } from "@common/types/apiTypes";
import { createRhContext } from "@common/utils/contextHelpers";
import { BoButtonProps } from "@ops-design-system/components/BoButton/BoButton";
import { ValidatedAddressType } from "@ops/components/MeterSearchForms/useMeterSearchForms";
import { MailingAddressFormValues } from "@ops/components/TransferServiceWizard/MailingAddressForm/MailingAddressForm";
import { MovingFormValues } from "@ops/components/TransferServiceWizard/MovingForm/MovingForm";
import { TransferServiceErrors } from "@ops/components/TransferServiceWizard/shared/enums";
import { initialTosWizardState } from "@ops/components/TransferServiceWizard/TransferServiceWizard.constants";
import { WizardComponentName } from "@ops/components/TransferServiceWizard/TransferServiceWizard.types";
import React, { PropsWithChildren, useMemo, useState } from "react";

export interface TosWizardStateType
  extends ValidatedAddressType,
    MailingAddressFormValues,
    MovingFormValues {
  offerSnapshotId: IdType;
  transferServiceError: TransferServiceErrors | null;
  validatedAddress?: ValidatedAddressType;
}

export interface TransferServiceWizardContextType {
  activeComponentName: WizardComponentName;
  closeModal: () => void;
  customer: Customer;
  nextButtonProps: Pick<BoButtonProps, "disabled" | "loading">;
  premise: Premise;
  restoreStateToInitialValues: () => void;
  setActiveComponentName: (contentName: WizardComponentName) => void;
  setNextButtonProps: (
    props: Pick<BoButtonProps, "disabled" | "loading">
  ) => void;
  setVisitedComponents: (componentNames: WizardComponentName[]) => void;
  tosWizardState: TosWizardStateType;
  updateTosWizardState: (payload: Partial<TosWizardStateType>) => void;
  visitedComponents: WizardComponentName[];
}

export const TransferServiceWizardContext =
  createRhContext<TransferServiceWizardContextType>();

interface TransferServiceWizardStateProviderProps {
  closeModal: () => void;
  customer: Customer;
  premise: Premise;
}

export const TransferServiceWizardProvider = ({
  premise,
  customer,
  closeModal,
  children,
}: PropsWithChildren<TransferServiceWizardStateProviderProps>) => {
  const [state, setState] = useState<TosWizardStateType>(initialTosWizardState);

  const [activeComponentName, setActiveComponentName] =
    useState<WizardComponentName>(WizardComponentName.newPremiseForm);
  const [nextButtonProps, setNextButtonProps] = useState<
    Pick<BoButtonProps, "disabled" | "loading">
  >({});

  const [visitedComponents, setVisitedComponents] = useState<
    WizardComponentName[]
  >([]);

  const updateTosWizardState = (payload: Partial<TosWizardStateType>) => {
    const existingState = { ...state };

    setState({ ...existingState, ...payload });
  };
  const updateActiveContent = (contentName: WizardComponentName) => {
    setActiveComponentName(contentName);
  };
  const updateNextButtonProps = (
    props: Pick<BoButtonProps, "disabled" | "loading">
  ) => {
    setNextButtonProps(props);
  };
  const restoreStateToInitialValues = () => {
    setState(initialTosWizardState);
    setActiveComponentName(WizardComponentName.newPremiseForm);
    setNextButtonProps({ disabled: true });
  };
  const updateVisitedComponents = (componentNames: WizardComponentName[]) => {
    setVisitedComponents(componentNames);
  };

  const value: TransferServiceWizardContextType = useMemo(
    () => ({
      activeComponentName,
      closeModal,
      customer,
      nextButtonProps,
      premise,
      restoreStateToInitialValues,
      setActiveComponentName: updateActiveContent,
      setNextButtonProps: updateNextButtonProps,
      setVisitedComponents: updateVisitedComponents,
      tosWizardState: state,
      updateTosWizardState,
      visitedComponents,
    }),
    [
      activeComponentName,
      closeModal,
      customer,
      nextButtonProps,
      premise,
      state,
      updateTosWizardState,
      visitedComponents,
    ]
  );

  return (
    <TransferServiceWizardContext.Provider value={value}>
      {children}
    </TransferServiceWizardContext.Provider>
  );
};
