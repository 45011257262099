import { rewardsApi } from "@common/api/rewardsApi";
import { IdType } from "@common/types/apiTypes";
import { AffiliateQueryKeys } from "@ops/constants/queryKeys.constants";
import { QUERY_STALE_TIME_DEFAULT_SHORT } from "@ops/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UseAffiliateRewardsProps {
  affiliateId: IdType;
}
export const useAffiliateRewards = (props: UseAffiliateRewardsProps) => {
  const { affiliateId } = props;

  return useQuery({
    queryFn: () => rewardsApi.affiliates.retrieve(affiliateId),
    queryKey: AffiliateQueryKeys.rewards(affiliateId),
    staleTime: QUERY_STALE_TIME_DEFAULT_SHORT,
  });
};
