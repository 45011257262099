import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const MovingDatesContainer = styled(FormFlexRow)`
  > div:last-child {
    margin-left: ${rhOpsSpacingPx(5)};
  }
`;

export const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(2.5)};
`;

export const AutoPayInfoCard = styled(BoCard)`
  color: ${red["500"]};
`;

export const PaperlessWarningCard = styled(BoCard)`
  color: ${red["500"]};
`;

export const PaperlessCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PaperlessDisclaimer = styled(Body2)`
  color: ${grey["500"]};
`;

export const BillingPreferencesContainer = styled.div`
  display: grid;
  gap: ${rhOpsSpacingPx(3)};
  grid-template-columns: 1fr 1fr;

  & > div {
    display: flex;
    flex-direction: column;
    gap: ${rhOpsSpacingPx(1)};
  }
`;
