import { OktaGroups } from "@common/constants/okta.constant";
import { PremiseStatus } from "@common/enums/premise.enum";
import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { ReactComponent as Cog } from "@ops-design-system/icons/Cog.svg";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { WinbackWizardModal } from "@ops/components/WinbackWizard/WinbackWizardModal";
import { WinbackWizardProvider } from "@ops/components/WinbackWizard/WinbackWizardProvider";
import { useCustomer } from "@ops/hooks/useCustomer";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";

export const WinbackWizard = () => {
  const { open, close, isOpen } = useModalState();

  const showWinbackButton = (
    <BoButton onClick={open} variant="secondary" size="extraSmall" icon={Cog}>
      Winback
    </BoButton>
  );

  const flash = useRhFlash();
  const { id, premiseId } = useParams<{ id: IdType; premiseId: IdType }>();
  const { customer, error: customerError } = useCustomer(id);
  const { premise, error: premiseError } = usePremise(premiseId);

  if (premiseError || customerError) {
    flash.error(
      "We're having trouble getting customer or premise data. Please try again."
    );
  }

  if (!premise || !customer) {
    return null;
  }

  return (
    <section aria-label="Service Update">
      <BoCardLabel>Service Update</BoCardLabel>
      <BoCard>
        <ButtonsGrid>
          {premise.status === PremiseStatus.INACTIVE ? (
            showWinbackButton
          ) : (
            <AccessControl allowedGroups={[OktaGroups.CSRTier2]}>
              {showWinbackButton}
            </AccessControl>
          )}
        </ButtonsGrid>
      </BoCard>
      <WinbackWizardProvider
        premise={premise}
        customer={customer}
        closeModal={close}
      >
        <WinbackWizardModal close={close} isOpen={isOpen} />
      </WinbackWizardProvider>
    </section>
  );
};
