import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { PremiseIndicatorsWizardModal } from "@ops/components/PremiseIndicatorsWizard/PremiseIndicatorsWizardModal";
import { PremiseIndicatorsWizardProvider } from "@ops/components/PremiseIndicatorsWizard/PremiseIndicatorsWizardProvider";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-left: auto;
`;

interface PremiseIndicatorsWizardProps {
  premiseId: IdType;
}

export const PremiseIndicatorsWizard = ({
  premiseId,
}: PremiseIndicatorsWizardProps) => {
  const { open, close, isOpen } = useModalState();

  return (
    <Container>
      <EditLink onClick={open} />
      <PremiseIndicatorsWizardProvider closeModal={close} premiseId={premiseId}>
        <PremiseIndicatorsWizardModal open={isOpen} />
      </PremiseIndicatorsWizardProvider>
    </Container>
  );
};
