const EDI_BASE_URL = "/api/edi";

export const ediUrls = {
  ediMessage: {
    copy: () => "/api/edi/copy-message",
    move: () => "/api/edi/move-message",
  },
  enrollmentStatuses: (): string => `${EDI_BASE_URL}/enrollment_statuses`,
  invoiceMatches: {
    reprocessInvlUsgmMessage: () =>
      `${EDI_BASE_URL}/edi-invoice-matches/reprocess-invl-usgm-message`,
  },
  meter: {
    retrieve: (esiId: string): string => `${EDI_BASE_URL}/meters/${esiId}/`,
  },
  meterAvailability: (): string => `${EDI_BASE_URL}/meter/availability/`,
  serviceStartDates: (): string => `${EDI_BASE_URL}/service/start_dates`,
  utilities: {
    index: (): string => `${EDI_BASE_URL}/utilities/`,
    search: () => `${EDI_BASE_URL}/utilities/search/`,
  },
};
