import {
  buttonSizeThemes,
  buttonVariantThemes,
} from "@ops-design-system/components/BoButton/BoButton.styled";
import {
  StyledBoLinkButton,
  StyledLinkAugmentedIconContainer,
  StyledLinkAugmentedText,
} from "@ops-design-system/components/BoLinkButton/BoLinkButton.styled";
import React, { FC, PropsWithChildren } from "react";

export type BoButtonVariants = keyof typeof buttonVariantThemes;
export type BoButtonSizes = keyof typeof buttonSizeThemes;
export interface BoLinkButtonProps {
  fullWidth?: boolean;
  icon?: FC<React.SVGProps<SVGSVGElement>>;
  size?: BoButtonSizes;
  to: string;
  variant?: BoButtonVariants;
}

export const BoLinkButton = ({
  icon: Icon,
  variant,
  size,
  children,
  fullWidth,
  to,
}: PropsWithChildren<BoLinkButtonProps>) => {
  return (
    <StyledBoLinkButton
      to={to}
      $variant={variant}
      style={{
        justifyContent: Icon ? "start" : "center",
        width: fullWidth ? "100%" : "auto",
      }}
      role="link"
    >
      {Icon && (
        <StyledLinkAugmentedIconContainer $variant={variant} $size={size}>
          <Icon aria-hidden="true" />
        </StyledLinkAugmentedIconContainer>
      )}
      <StyledLinkAugmentedText
        $variant={variant}
        $size={size}
        $iconPresent={Boolean(Icon)}
      >
        {children}
      </StyledLinkAugmentedText>
    </StyledBoLinkButton>
  );
};
