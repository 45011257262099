import { RequestMonitor } from "@common/models/RequestMonitor.model";
import {
  AjaxStatuses,
  UseAjaxSetters,
  UseAjaxStateType,
} from "@common/types/apiTypes";
import { RhError } from "@common/types/errorTypes";
import { Dispatch, SetStateAction, useState } from "react";

const pristineUseAjaxState = {
  data: null,
  error: null,
  requestMonitor: new RequestMonitor(),
};

function successHandler<ResponseType>(
  setState: Dispatch<SetStateAction<UseAjaxStateType<ResponseType>>>
) {
  return (data: ResponseType) => {
    setState({
      data,
      error: null,
      requestMonitor: new RequestMonitor(AjaxStatuses.Success),
    });
  };
}
function failureHandler<ResponseType>(
  setState: Dispatch<SetStateAction<UseAjaxStateType<ResponseType>>>
) {
  return (error: RhError | null = null) => {
    setState((state) => ({
      data: state.data,
      error,
      requestMonitor: new RequestMonitor(AjaxStatuses.Failure),
    }));
  };
}
function pendingHandler<ResponseType>(
  setState: Dispatch<SetStateAction<UseAjaxStateType<ResponseType>>>
) {
  return () => {
    setState((state) => ({
      ...state,
      requestMonitor: new RequestMonitor(AjaxStatuses.Pending),
    }));
  };
}
function initialStateHandler<ResponseType>(
  setState: Dispatch<SetStateAction<UseAjaxStateType<ResponseType>>>
) {
  return () => {
    setState({ ...pristineUseAjaxState });
  };
}

// Docs:
// https://www.notion.so/gotrhythm/7958d63be7bf494fa121fbaa57a5db34#f65e2fa4bdc84f0596578da17b0dd705

export const useAjaxState = <ResponseType = void>(
  initialData?: ResponseType,
  initialError?: RhError
): [UseAjaxStateType<ResponseType>, UseAjaxSetters<ResponseType>] => {
  const initialState: UseAjaxStateType<ResponseType> = {
    ...pristineUseAjaxState,
  };

  if (initialData) {
    initialState.data = initialData;
    initialState.requestMonitor = new RequestMonitor(AjaxStatuses.Success);
  }
  if (initialError) {
    initialState.error = initialError;
    initialState.requestMonitor = new RequestMonitor(AjaxStatuses.Failure);
  }
  const [state, setState] =
    useState<UseAjaxStateType<ResponseType>>(initialState);

  return [
    state,
    {
      setFailure: failureHandler<ResponseType>(setState),
      setInitialState: initialStateHandler<ResponseType>(setState),
      setPending: pendingHandler<ResponseType>(setState),
      setSuccess: successHandler<ResponseType>(setState),
    },
  ];
};
