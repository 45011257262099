import { ComparisonDataType } from "@common/components/BillComparisonChart/types";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { grey } from "@design-system/theme/palette.colors";
import { fontFamily } from "@design-system/theme/typography";
import { ChartOptions, Plugin } from "chart.js";

interface GetChartOptionsArgs {
  comparisionData: ComparisonDataType;
  isMobile?: boolean;
  tableHeaderWidth: number;
  xLabels: {
    lastMonth: string;
    lastYear: string;
    thisMonth: string;
  };
}

// Custom plugin to draw the Y-axis title
export const drawYAxisTitlePlugin = ({
  isMobile,
  title,
}: {
  isMobile: boolean;
  title: string;
}) =>
  ({
    beforeDraw: (chart) => {
      const { ctx, chartArea } = chart;

      const { bottom, top, left } = chartArea;

      const fontSize = isMobile ? 9 : 12;

      ctx.save();

      ctx.font = `bold ${fontSize}px ${fontFamily}`;
      ctx.fillStyle = grey["600"];

      ctx.translate(left, (top + bottom) / 2);
      ctx.rotate(-Math.PI / 2);

      const textWidth = ctx.measureText(title).width;

      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(title, 0, -textWidth / 2);

      ctx.restore();
    },
    id: "drawYAxisTitlePlugin",
  }) satisfies Plugin<"bar">;

export const getBillComparisonChartOptions = ({
  xLabels,
  comparisionData,
  isMobile,
  tableHeaderWidth,
}: GetChartOptionsArgs) => {
  const fontSize = isMobile ? 9 : 14;
  const numberOfColumns = comparisionData.previousYearPeriod ? 4 : 3;
  const mobileTitlePadding = numberOfColumns === 4 ? 30 : 50;

  const boldXLabels = [
    parseLabel(xLabels.lastMonth),
    parseLabel(xLabels.thisMonth),
  ];
  const dateLabels = [
    [
      formatMonthDayYear(comparisionData.previousPeriod?.startDate),
      formatMonthDayYear(comparisionData.previousPeriod?.endDate),
    ],
    [
      formatMonthDayYear(comparisionData.currentPeriod.startDate),
      formatMonthDayYear(comparisionData.currentPeriod.endDate),
    ],
  ];

  if (comparisionData.previousYearPeriod) {
    boldXLabels.unshift(parseLabel(xLabels.lastYear));
    dateLabels.unshift([
      formatMonthDayYear(comparisionData.previousYearPeriod.startDate),
      formatMonthDayYear(comparisionData.previousYearPeriod.endDate),
    ]);
  }

  return {
    font: {
      family: fontFamily,
      size: isMobile ? 9 : 14,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        labels: boldXLabels,
        ticks: {
          color: grey[900],
          font: {
            weight: "bold",
          },
          padding: 0,
        },
      },
      x2: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        labels: dateLabels,

        ticks: {
          font: {
            family: fontFamily,
            size: fontSize,
          },
          padding: 0,
        },
      },
      y: {
        afterFit: (scale) => {
          if (isMobile) {
            const chartWidth = scale.chart.width;

            // eslint-disable-next-line no-param-reassign
            scale.width = chartWidth * (1 / numberOfColumns);
          } else {
            // eslint-disable-next-line no-param-reassign
            scale.width = tableHeaderWidth;
          }
        },
        beginAtZero: true,
        grid: {
          drawTicks: false,
        },
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: isMobile ? mobileTitlePadding : 100,
        },
        ticks: {
          color: grey[900],
          font: {
            family: fontFamily,
            size: fontSize,
            weight: "bold",
          },
          padding: 8,
          stepSize: 500,
        },
      },
    },
  } as ChartOptions<"bar">;
};

// Transform "[Factura de], [este mes]" into [["Factura de"], ["este mes"]]
function parseLabel(label: string) {
  const matches = label.match(/\[([^\]]+)\]/g);

  if (matches) {
    return matches.map((item) => item.replace(/[[\]]/g, "").trim());
  }

  return label;
}
