import { ediApi } from "@common/api/ediApi";
import { blankAddress } from "@common/constants/blankAddress.constant";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { AddressForm as AddressFormModel } from "@common/models/AddressForm.model";
import { AddressFormType } from "@common/types/customerTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { AddressFormFields } from "@ops/components/MeterSearchForms/AddressFormFields";
import { ValidationButtonContainer } from "@ops/components/MeterSearchForms/MeterSearchForms.styled";
import { ValidatedAddressType } from "@ops/components/MeterSearchForms/useMeterSearchForms";
import { isValidUSAddress } from "@ops/forms/validators";
import React, { useState } from "react";
import { Form } from "react-final-form";

interface AddressFormValues {
  serviceAddress: AddressFormType;
}

export interface AddressFormProps {
  align?: "left" | "right";
  currentServiceAddress?: AddressFormModel | null;
  disabled?: boolean;
  onSubmit: (response?: ValidatedAddressType, error?: string) => void;
}

const formValidators = generateValidationErrorCollector<AddressFormValues>({
  serviceAddress: isValidUSAddress,
});

export const AddressForm = ({
  onSubmit,
  disabled,
  currentServiceAddress,
  align = "right",
}: AddressFormProps) => {
  const initialServiceAddress = currentServiceAddress ?? {};
  const [validating, setValidating] = useState<boolean>(false);
  const [initialValues] = useState<AddressFormValues>({
    serviceAddress: { ...blankAddress, ...initialServiceAddress },
  });

  const onFormSubmit = async ({
    serviceAddress: givenServiceAddress,
  }: AddressFormValues) => {
    setValidating(true);

    const [error, response] = await handleAjaxCall(
      ediApi.meterAvailability({
        city: givenServiceAddress.city,
        secondary: givenServiceAddress.unitNumber,
        state: givenServiceAddress.state,
        streetLine: givenServiceAddress.addressLine1,
        zipcode: givenServiceAddress.zipCode,
      })
    );
    const { esiId, dunsNumber, utilityName } = response || {
      esiId: "",
    };

    setValidating(false);

    onSubmit(
      { dunsNumber, esiId, serviceAddress: givenServiceAddress, utilityName },
      error || !esiId ? "No meter found. Please try again." : undefined
    );
  };

  return (
    <Form<AddressFormValues>
      validate={formValidators}
      initialValues={initialValues}
      validateOnChange
      onSubmit={onFormSubmit}
      render={({ hasValidationErrors, handleSubmit }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <AddressFormFields groupName="serviceAddress" disabled={disabled} />
            <ValidationButtonContainer $align={align}>
              <BoButton
                type="submit"
                variant="secondary"
                disabled={disabled || hasValidationErrors}
                loading={validating}
              >
                Validate Address
              </BoButton>
            </ValidationButtonContainer>
          </form>
        );
      }}
    />
  );
};
