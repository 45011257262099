import { acquisitionCampaignUrls } from "@common/api/campaignPromosUrls";
import { ajax } from "@common/services/ajax";
import { AcquisitionCampaignListResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  AcquisitionCampaignCreatePostDataType,
  AcquisitionCampaignUpdatePatchDataType,
} from "@common/types/campaignTypes";

export const acquisitionCampaignApi = {
  create: (data: AcquisitionCampaignCreatePostDataType): Promise<void> =>
    ajax.post(acquisitionCampaignUrls.create(), data),
  list: (): Promise<AcquisitionCampaignListResponseType> =>
    ajax.get<AcquisitionCampaignListResponseType>(
      acquisitionCampaignUrls.list()
    ),
  update: (
    id: IdType,
    data: AcquisitionCampaignUpdatePatchDataType
  ): Promise<void> => ajax.put(acquisitionCampaignUrls.update(id), data),
};
