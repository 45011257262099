import {
  darkPurple,
  grey,
  red,
} from "@ops-design-system/styles/palette.colors";

export const borders = {
  100: `1px solid ${grey[100]}`,
  200: `1px solid ${grey[200]}`,
  300: `1px solid ${grey[300]}`,
};

export const activeBorders = {
  100: `2px solid ${darkPurple[100]}`,
  300: `2px solid ${darkPurple[300]}`,
  500: `1px solid ${darkPurple[500]}`,
};

export const errorBorders = {
  500: `2px solid ${red[500]}`,
};
