import { ContractRenewalWizardBillingPreferencesStep } from "@ops/components/ContractRenewalWizard/ContractRenewalWizardBillingPreferencesStep/ContractRenewalWizardBillingPreferencesStep";
import { ContractRenewalWizardConfirmationStep } from "@ops/components/ContractRenewalWizard/ContractRenewalWizardConfirmationStep/ContractRenewalWizardConfirmationStep";
import { ContractRenewalWizardOffersStep } from "@ops/components/ContractRenewalWizard/ContractRenewalWizardOffersStep/ContractRenewalWizardOffersStep";
import { ContractRenewalWizardSuccessStep } from "@ops/components/ContractRenewalWizard/ContractRenewalWizardSuccessStep/ContractRenewalWizardSuccessStep";
import React from "react";

export const contractRenewalWizardStepMapping = {
  billingPreferencesStep: <ContractRenewalWizardBillingPreferencesStep />,
  confirmationStep: <ContractRenewalWizardConfirmationStep />,
  offersStep: <ContractRenewalWizardOffersStep />,
  successStep: <ContractRenewalWizardSuccessStep />,
};
