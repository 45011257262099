import { EM_DASH } from "@common/constants/characters.constant";
import { Address } from "@common/models/Address.model";
import { AddressForm } from "@common/models/AddressForm.model";
import React from "react";

interface DisplayAddressProps {
  address?: Address | AddressForm;
}
export const DisplayAddress = ({ address }: DisplayAddressProps) => {
  if (!address) {
    return <div>{EM_DASH}</div>;
  }

  return (
    <>
      <div>{address.line1}</div>
      <div>{address.line2}</div>
    </>
  );
};
