import { Premise } from "@common/models/Premise.model";
import { IdType } from "@common/types/apiTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body2, H3 } from "@ops-design-system/components/Typography/Typography";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { contractRenewalWizardStateAtom } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.atoms";
import { billingPreferencesStepSchema } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.schemas";
import { ContractRenewalWizardButtonContainer } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import {
  AutoPayInfoCard,
  BillingPreferencesStepContainer,
  BillingPreferencesStepForm,
  PaperlessCard,
  PaperlessCheckbox,
  PaperlessContainer,
  PaperlessDisclaimer,
  PaperlessLabel,
  PaperlessWarningCard,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizardBillingPreferencesStep/ContractRenewalWizardBillingPreferencesStep.styled";
import { useContractRenewalWizard } from "@ops/components/ContractRenewalWizard/useContractRenewalWizard";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { usePremise } from "@ops/hooks/usePremise";
import { useAtomValue } from "jotai/react";
import React, { PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

const Form = ({
  offerSnapshot,
  premise,
}: {
  offerSnapshot: OfferSnapshot;
  premise: Premise;
}) => {
  const { paperless } = useAtomValue(contractRenewalWizardStateAtom);

  const determineDefaultValue = () => {
    if (paperless !== undefined) {
      return paperless;
    }

    if (offerSnapshot.isAutoPayPaperlessDiscountOffer) {
      return true;
    }

    return premise.isPaperless || premise.isOnLegacyEBill;
  };

  const {
    handleSubmit,
    watch,
    register,
    formState: { isValid },
  } = useForm<z.infer<typeof billingPreferencesStepSchema>>({
    defaultValues: {
      paperless: determineDefaultValue(),
    },
    resolver: zodResolver(billingPreferencesStepSchema),
  });

  const {
    handleNextStep,
    handlePreviousStep,
    isNextStepAvailable,
    isPreviousStepAvailable,
  } = useContractRenewalWizard();

  const onSubmit = handleSubmit((values) => {
    handleNextStep(values);
  });

  const paperlessValue = watch("paperless");

  const showPaperlessWarning =
    offerSnapshot.isAutoPayPaperlessDiscountOffer && !paperlessValue;

  const showAutoPayMessage =
    offerSnapshot.isAutoPayPaperlessDiscountOffer && !premise.autopay;

  return (
    <BillingPreferencesStepForm onSubmit={onSubmit}>
      <PaperlessContainer>
        <PaperlessCard>
          <PaperlessLabel>
            <PaperlessCheckbox {...register("paperless")} />
            Paperless
          </PaperlessLabel>

          <PaperlessDisclaimer>
            The customer agrees to receive their welcome packet, bills, contract
            documents, and contract related notices (including contract
            expiration notices and disconnect notices) via email. They
            understand they won&apos;t receive their bills via regular mail.
          </PaperlessDisclaimer>
        </PaperlessCard>
        {showPaperlessWarning ? (
          <PaperlessWarningCard $variant={BoCardVariants.Warning}>
            The customer must be enrolled in Auto Pay and Paperless to receive
            the Auto Pay and Paperless Credit.
          </PaperlessWarningCard>
        ) : null}
      </PaperlessContainer>

      {showAutoPayMessage ? (
        <AutoPayInfoCard $variant={BoCardVariants.Warning}>
          Auto Pay must be turned on after the completion of renewing for the
          customer to receive the Auto Pay and Paperless Credit.
        </AutoPayInfoCard>
      ) : null}

      <ContractRenewalWizardButtonContainer>
        <BoButton
          variant="secondary"
          type="button"
          onClick={handlePreviousStep}
          disabled={!isPreviousStepAvailable}
        >
          Previous Page
        </BoButton>
        <BoButton
          variant="primary"
          type="submit"
          disabled={!isNextStepAvailable || !isValid}
        >
          Next Page
        </BoButton>
      </ContractRenewalWizardButtonContainer>
    </BillingPreferencesStepForm>
  );
};

const Wrapper = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <BillingPreferencesStepContainer>
      <H3>Billing Preferences</H3>
      {children}
    </BillingPreferencesStepContainer>
  );
};

export const ContractRenewalWizardBillingPreferencesStep = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);

  const { offerSnapshotId } = useAtomValue(contractRenewalWizardStateAtom);

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId,
  });

  if (!premise) {
    return null;
  }

  if (offerSnapshotQuery.isPending) {
    return (
      <Wrapper>
        <BoCircularProgress />
      </Wrapper>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <Wrapper>
        <Body2>Error fetching offer details</Body2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Form premise={premise} offerSnapshot={offerSnapshotQuery.data} />
    </Wrapper>
  );
};
