import { rhapsodyCampaignApi } from "@ops-data/api/rhapsodyCampaignApi";
import { CampaignQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useActiveCampaignSlugs = (filter?: string) => {
  return useQuery({
    queryFn: () => rhapsodyCampaignApi.campaignSlugs.retrieve(filter),
    queryKey: [CampaignQueryKeys.CAMPAIGN_SLUGS, filter],
    select: (data) => {
      return data.campaignSlugs;
    },
  });
};
