import { boxShadows } from "@design-system/constants/boxShadows";
import { grey } from "@design-system/theme/palette.colors";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const borderRadius = "12px";
const timeMargin = "-0.75rem";
const heightSpacing = "2rem";

export const heightSpacingWithMargin = `calc(${heightSpacing} + 0.5rem)`;

export const StyledPriceScheduleBreakdownVisual = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(50% - 1em);

  &:only-of-type {
    border-radius: ${borderRadius};
    width: 100%;
  }

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const StyledChart = styled.div`
  align-items: stretch;
  background-color: ${grey[50]};
  border-radius: ${borderRadius};
  box-shadow: ${boxShadows.default};
  display: flex;
  flex-direction: column;
  height: 25rem;
  justify-content: stretch;
  margin: 1rem 0;
  width: ${heightSpacing};

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
    height: ${heightSpacing};
    width: 100%;
  }
`;

export const StyledChartTime = styled.span`
  margin-left: ${heightSpacingWithMargin};
  position: absolute;
  top: -0.5rem;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    margin-left: ${timeMargin};
    top: ${heightSpacing};
  }
`;

export const StyledChartTier = styled.div<{
  $isTouGeneration?: boolean;
}>`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  position: relative;

  &:first-child {
    border-radius: ${({ $isTouGeneration }) =>
      $isTouGeneration
        ? `${borderRadius} 0  0 0`
        : `${borderRadius} ${borderRadius} 0 0`};

    @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
      border-radius: ${({ $isTouGeneration }) =>
        $isTouGeneration
          ? `${borderRadius} 0  0 0`
          : `${borderRadius} 0 0 ${borderRadius}`};

      ${StyledChartTime} {
        margin-left: ${timeMargin};
      }
    }
  }

  &:last-child {
    border-radius: ${({ $isTouGeneration }) =>
      $isTouGeneration
        ? `0 0 0 ${borderRadius}`
        : `0 0 ${borderRadius} ${borderRadius}`};

    &:after {
      bottom: -0.5rem;
      content: "12am";
      margin-left: ${heightSpacingWithMargin};
      position: absolute;

      @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
        margin-left: 0;
        margin-right: ${timeMargin};
        right: 0;
        top: ${heightSpacing};
      }
    }

    @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
      border-radius: ${({ $isTouGeneration }) =>
        $isTouGeneration
          ? ` 0 ${borderRadius} 0 0`
          : `0 ${borderRadius} ${borderRadius} 0`};
    }
  }

  &:only-of-type {
    border-radius: ${borderRadius};
  }

  &.timeTop ${StyledChartTime} {
    right: ${heightSpacingWithMargin};

    @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
      margin-left: ${timeMargin};
      margin-right: 0;
      right: auto;
      top: -1.25rem;
    }
  }
`;
