import {
  BoTable,
  BoTableBody,
} from "@ops-design-system/components/BoTable/BoTable";
import { BO_TABLE_CORNER_RADIUS } from "@ops-design-system/components/BoTable/BoTable.constants";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { borders } from "@ops-design-system/styles/borders";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledRedeemedMessage = styled(Body2)`
  color: ${grey[500]};
  font-style: italic;
  margin-left: ${rhOpsSpacingPx(1)};
`;

export const StyledCustomerRewardsTable = styled(BoTable)`
  width: 100%;
`;

export const StyledCustomerRewardsTitle = styled(Body2).attrs({
  as: "div",
})`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const StyledCustomerRewardsTableBody = styled(BoTableBody)`
  & tr:first-child td {
    border-top: ${borders["100"]};

    &:first-child {
      border-top-left-radius: ${BO_TABLE_CORNER_RADIUS};
    }

    &:last-child {
      border-top-right-radius: ${BO_TABLE_CORNER_RADIUS};
    }
  }
`;
