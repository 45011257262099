import { ContactLogEntry } from "@ops/components/SupportLogs/ContactLog/ContactLogEntry/ContactLogEntry";
import { generateJSXFromStringMatch } from "@ops/components/SupportLogs/SearchLog/SupportLogSearchEntry";
import { ContactLog } from "@ops/models/ContactLog.model";
import React from "react";

interface ContactLogSearchEntryProps {
  contactLog: ContactLog;
  query?: string;
}

const styleFilteredContactLog = (
  query: string | undefined,
  contactLog: ContactLog
): Record<string, JSX.Element | string> => {
  const notes = generateJSXFromStringMatch(contactLog.notes, query);
  const category = generateJSXFromStringMatch(contactLog.category, query);
  const type = generateJSXFromStringMatch(contactLog.type, query);
  const reason = generateJSXFromStringMatch(contactLog.reason, query);
  const contactMethod = generateJSXFromStringMatch(
    contactLog.contactMethod,
    query
  );
  const csrName = generateJSXFromStringMatch(contactLog.csrName, query);

  return {
    category,
    contactMethod,
    csrName,
    notes,
    reason,
    type,
  };
};

export const ContactLogSearchEntry = ({
  contactLog,
  query,
}: ContactLogSearchEntryProps) => {
  const { id, createdAt, outboundContact } = contactLog;
  const { notes, category, type, reason, contactMethod, csrName } =
    styleFilteredContactLog(query, contactLog);

  return (
    <ContactLogEntry
      id={id}
      createdAt={createdAt}
      outboundContact={outboundContact}
      notes={notes}
      category={category}
      type={type}
      reason={reason}
      contactMethod={contactMethod}
      csrName={csrName}
    />
  );
};
