import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import { RhapsodyCustomerType } from "@ops-utils/types/customerTypes";

const BASE_PATH = "/api/rhapsody/customer";

export const rhapsodyCustomerApi = {
  update: ({
    customerId,
    updateData,
  }: {
    customerId: IdType;
    updateData: RhapsodyCustomerType;
  }): Promise<RhapsodyCustomerType> => {
    return ajax.patch(`${BASE_PATH}/${customerId}/`, updateData);
  },
};
