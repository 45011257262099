import { premiseApi } from "@common/api/premiseApi";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import {
  paymentMethodsErrored,
  paymentMethodsFetch,
  paymentMethodsReceived,
  paymentMethodsRequested,
} from "@ops/slices/paymentMethodsSlice";
import { SagaReturnType, all, call, put, takeLatest } from "redux-saga/effects";

export function* fetchPaymentMethodsWorker(action: {
  payload: IdType;
  type: string;
}) {
  yield put(paymentMethodsRequested());
  try {
    const {
      paymentMethods,
    }: SagaReturnType<typeof premiseApi.paymentMethods.list> = yield call(
      premiseApi.paymentMethods.list,
      action.payload
    );

    yield put(paymentMethodsReceived(paymentMethods));
  } catch (err: unknown) {
    yield put(paymentMethodsErrored(err as RhApiError));
  }
}

export function* paymentMethodsWatcher() {
  yield all([takeLatest(paymentMethodsFetch.type, fetchPaymentMethodsWorker)]);
}
