import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledUploadDocumentButton = styled(BoButton)`
  width: 200px;
`;

export const StyledUploadCustomerDocumentFormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;

export const StyledUploadCustomerDocumentFormInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledUploadDocumentFormRow = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(3)};
`;

export const StyledUploadDocumentFormRadioContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhOpsSpacingPx(1)};

  label {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledFileError = styled.div`
  align-items: center;
  color: ${red[500]};
  display: flex;
  gap: ${rhOpsSpacingPx(1)};
  margin-top: ${rhOpsSpacingPx(2)};
`;
