import { IdType } from "@common/types/apiTypes";

export enum FampPaymentMethod {
  ACH = "ACH",
  FWT = "FWT",
  NONE = "",
}

export interface CreateFampMessageType {
  endDate: string;
  startDate: string;
  utility: IdType;
}
export interface SubmitFampType {
  intendedSettlementDate: string;
  paymentMethod: FampPaymentMethod;
}

export interface CreateFampMessageResponseType {
  endDate: string;
  id: number;
  payerIntendedSettlementDate: string | null;
  paymentMethod: FampPaymentMethod;
  startDate: string;
  totalDistributionAmount: number;
  totalInvoices: number;
  traceNumber: string;
  utility: IdType;
  utilityDuns: string;
}

export interface SubmitFampResponseType {}
