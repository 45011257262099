import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const Section = styled.section`
  margin: ${rhOpsSpacingPx(1)} 0;
`;

export const StyledHeaderBox = styled.div`
  padding-bottom: ${rhOpsSpacingPx(2.5)};
  padding-top: ${rhOpsSpacingPx(2.5)};
`;
