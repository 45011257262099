import { StatusIndicatorLevel } from "@common/types/statusIndicatorTypes";
import {
  StyledActiveIndicator,
  StyledStatus,
  StyledStatusContainer,
} from "@ops/components/StatusIndicator/StatusIndicator.styled";
import React from "react";

interface StatusIndicatorProps {
  children: string;
  status?: StatusIndicatorLevel;
}

export const StatusIndicator = ({ status, children }: StatusIndicatorProps) => {
  if (!status) {
    return null;
  }

  return (
    <StyledStatusContainer $status={status}>
      <StyledActiveIndicator />
      <StyledStatus $status={status}>{children}</StyledStatus>
    </StyledStatusContainer>
  );
};
