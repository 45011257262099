import {
  BoTextField,
  BoTextFieldProps,
} from "@ops-design-system/components/BoTextField/BoTextField";
import { FormFlexRow, FormRow } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

interface AddressFormFieldsProps {
  disabled?: boolean;
  groupName: string;
}

const AddressFieldContainer = styled.div`
  flex-basis: 80%;
  padding-right: ${rhOpsSpacingPx(5)};
`;
const StateFieldContainer = styled.div`
  padding-right: ${rhOpsSpacingPx(2)};
  width: 100%;
`;
const ZipFieldContainer = styled.div`
  width: 100%;
`;

export const AddressFormFields = ({
  groupName,
  disabled = false,
}: AddressFormFieldsProps) => {
  const sharedFieldProps: Partial<BoTextFieldProps<string>> = {
    disabled,
    hideErrorMessage: true,
    placeholder: "",
    type: "text",
  };

  return (
    <>
      <FormFlexRow>
        <AddressFieldContainer>
          <BoTextField {...sharedFieldProps} name={`${groupName}.addressLine1`}>
            Street Address
          </BoTextField>
        </AddressFieldContainer>
        <div>
          <BoTextField {...sharedFieldProps} name={`${groupName}.unitNumber`}>
            Unit #
          </BoTextField>
        </div>
      </FormFlexRow>
      <FormRow>
        <BoTextField {...sharedFieldProps} name={`${groupName}.city`}>
          City
        </BoTextField>
      </FormRow>
      <FormFlexRow>
        <StateFieldContainer>
          <BoTextField {...sharedFieldProps} name={`${groupName}.state`}>
            State
          </BoTextField>
        </StateFieldContainer>
        <ZipFieldContainer>
          <BoTextField {...sharedFieldProps} name={`${groupName}.zipCode`}>
            Zip Code
          </BoTextField>
        </ZipFieldContainer>
      </FormFlexRow>
    </>
  );
};
