import {
  AmountType,
  MakeAPaymentFormValues,
  MakeAPaymentStepType,
  PaymentSuccessPayloadType,
} from "@ops/components/MakeAPayment/MakeAPayment.types";
import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

export const makeAPaymentFormAtom = atomWithReset<MakeAPaymentFormValues>({
  amount: "",
  amountType: AmountType.Total,
  paymentMethodId: "",
});

export const paymentSuccessPaylodAtom =
  atomWithReset<PaymentSuccessPayloadType>({
    paymentNumber: "",
    submittedAmount: 0,
  });

export const makeAPaymentStepAtom = atom<MakeAPaymentStepType>("loading");
