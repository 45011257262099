import { restrictWithinRange } from "@common/utils/numericHelpers";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCurrencyInput } from "@ops-design-system/components/BoCurrencyInput/BoCurrencyInput";
import { BoTextInput } from "@ops-design-system/components/BoTextInput/BoTextInput";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useDownPaymentCalculatorState } from "@ops/components/DownPaymentCalculator/useDownPaymentCalculatorState";
import React, { ChangeEventHandler, useEffect } from "react";
import styled from "styled-components";

const CardContents = styled.div`
  margin-bottom: ${rhOpsSpacingPx(2)};
  margin-top: ${rhOpsSpacingPx(1)};
`;

const Header = styled(Body1).attrs({ as: "div" })`
  font-weight: ${FontWeight.Bold};
  padding-bottom: ${rhOpsSpacingPx(3)};
`;

const FieldsColumn = styled.div`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const DownPaymentCalculator = () => {
  const {
    updateCalculatorState,
    totalAmountDue,
    downPayment,
    downPaymentPercent,
  } = useDownPaymentCalculatorState();

  const computeDownPaymentFromPercentage = (percent_: number) => {
    return Math.round(totalAmountDue * percent_) / 100.0;
  };

  useEffect(() => {
    updateCalculatorState({
      downPayment: computeDownPaymentFromPercentage(downPaymentPercent),
    });
  }, [totalAmountDue]);

  const onChange = (percent: number, newDownPayment: number) => {
    updateCalculatorState({
      downPayment: newDownPayment,
      downPaymentPercent: percent,
    });
  };

  const handlePercentChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const percent = restrictWithinRange(parseInt(event.target.value, 10), 100);

    const newDownPayment = computeDownPaymentFromPercentage(percent);

    onChange(percent, newDownPayment);
  };

  const handleDownPaymentChange = (value: number) => {
    const newDownPayment = restrictWithinRange(value, totalAmountDue);

    const percent = Math.round((newDownPayment / totalAmountDue) * 100);

    onChange(percent, newDownPayment);
  };

  return (
    <BoCard>
      <CardContents>
        <Header>Down Payment Calculator</Header>
        <FieldsColumn>
          <BoTextInput
            name="downPaymentPercentage"
            onChange={handlePercentChange}
            value={downPaymentPercent}
          >
            Down Payment %
          </BoTextInput>
          <BoCurrencyInput
            name="downPaymentAmountDollars"
            onChange={handleDownPaymentChange}
            value={downPayment}
          >
            Down Payment $
          </BoCurrencyInput>
        </FieldsColumn>
      </CardContents>
    </BoCard>
  );
};
