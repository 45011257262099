import { RhCalendarDatePicker } from "@design-system/components/RhCalendarDatePicker/RhCalendarDatePicker";
import { ISO8601_DATE_FORMAT } from "@design-system/constants/date.constant";
import { MobileDatePickerProps } from "@mui/x-date-pickers";
import { ReactComponent as CalendarIcon } from "@ops-design-system/icons/CalendarIcon.svg";
import { BoFieldIconContainer } from "@ops-design-system/styles/common.styles";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { useField, useForm } from "react-final-form";
import styled from "styled-components";

interface BoDateFieldProps {
  label: string;
  name: string;
  placeholder?: string;
}

const DateFieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const BoDateField = ({ name, label, placeholder }: BoDateFieldProps) => {
  const {
    input: { value },
    meta,
  } = useField<string>(name, {
    type: "date",
  });
  const form = useForm();

  const hasSyncError = Boolean(meta.error);
  const hasAsyncError = Boolean(meta.submitError);
  const hasValidationError =
    hasSyncError || (!meta.dirtySinceLastSubmit && hasAsyncError);

  const hasErrors = !meta.active && meta.touched && hasValidationError;

  return (
    <DateFieldContainer>
      <RhCalendarDatePicker
        label={label}
        format="MM/DD/YYYY"
        inputName={name}
        value={value ? dayjs(value) : undefined}
        onChange={(date: Dayjs) => {
          const formattedDate = date.format(ISO8601_DATE_FORMAT);

          form.change(name, formattedDate);
        }}
        okLabel="OK"
        cancelLabel="Cancel"
        hasErrors={hasErrors}
        placeholder={placeholder ?? label}
      />
      <BoFieldIconContainer>
        <CalendarIcon />
      </BoFieldIconContainer>
    </DateFieldContainer>
  );
};

interface BoDateInputProps
  extends Omit<
    MobileDatePickerProps<string>,
    "value" | "onChange" | "variant"
  > {
  allowedDates?: string[];
  closeOnSelect?: boolean;
  label: string;
  name: string;
  onChange?: (dateString: string | null) => void;
  placeholder?: string;
  value?: string | null;
}

export const BoDateInput = ({
  allowedDates,
  closeOnSelect = false,
  name,
  label,
  placeholder,
  onChange,
  value,
  minDate,
  maxDate,
  disabled,
}: BoDateInputProps) => {
  return (
    <DateFieldContainer>
      <RhCalendarDatePicker
        closeOnSelect={closeOnSelect}
        inputId={name}
        inputName={name}
        label={label}
        placeholder={placeholder ?? label}
        format="MM/DD/YYYY"
        okLabel="OK"
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        cancelLabel="Cancel"
        value={value ? dayjs(value) : undefined}
        allowedDates={allowedDates ? allowedDates.map(dayjs) : undefined}
        onChange={(date: Dayjs) => {
          const formattedDate = date.format(ISO8601_DATE_FORMAT);

          onChange?.(formattedDate);
        }}
        disabled={disabled}
      />
      <BoFieldIconContainer>
        <CalendarIcon />
      </BoFieldIconContainer>
    </DateFieldContainer>
  );
};
