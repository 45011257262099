import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface TaxConfigurationUpdatedData extends EventLogBaseData {
  prevSalesTaxTypeDisplay: string;
  prevTaxExemptionPercentageDisplay: string;
  prevTaxExemptionReasonCodeDisplay: string;
  prevTaxExemptionsDisplay: string;
  salesTaxTypeDisplay: string;
  taxExemptionPercentageDisplay: string;
  taxExemptionReasonCodeDisplay: string;
  taxExemptionsDisplay: string;
}

export class TaxConfigurationUpdated extends EventLog {
  public readonly prevSalesTaxTypeDisplay: string;
  public readonly prevTaxExemptionPercentageDisplay: string;
  public readonly prevTaxExemptionReasonCodeDisplay: string;
  public readonly prevTaxExemptionsDisplay: string;
  public readonly salesTaxTypeDisplay: string;
  public readonly taxExemptionPercentageDisplay: string;
  public readonly taxExemptionReasonCodeDisplay: string;
  public readonly taxExemptionsDisplay: string;

  constructor({
    prevSalesTaxTypeDisplay,
    prevTaxExemptionPercentageDisplay,
    prevTaxExemptionReasonCodeDisplay,
    prevTaxExemptionsDisplay,
    salesTaxTypeDisplay,
    taxExemptionPercentageDisplay,
    taxExemptionReasonCodeDisplay,
    taxExemptionsDisplay,
    ...baseEventData
  }: TaxConfigurationUpdatedData) {
    super({
      ...baseEventData,
      eventText: "Tax Configuration Updated",
    });

    this.prevSalesTaxTypeDisplay = prevSalesTaxTypeDisplay;
    this.prevTaxExemptionsDisplay = prevTaxExemptionsDisplay;
    this.prevTaxExemptionPercentageDisplay = prevTaxExemptionPercentageDisplay;
    this.prevTaxExemptionReasonCodeDisplay = prevTaxExemptionReasonCodeDisplay;

    this.salesTaxTypeDisplay = salesTaxTypeDisplay;
    this.taxExemptionsDisplay = taxExemptionsDisplay;
    this.taxExemptionPercentageDisplay = taxExemptionPercentageDisplay;
    this.taxExemptionReasonCodeDisplay = taxExemptionReasonCodeDisplay;
  }

  override get body() {
    return `${this.name}
    Changed From:
    Exemption Reason Code: ${this.prevTaxExemptionReasonCodeDisplay}
    Exemptions: ${this.taxExemptionsDisplay}
    Exemption Percentage: ${this.prevTaxExemptionPercentageDisplay}
    Sales Tax Type:  ${this.prevSalesTaxTypeDisplay}
    To:
    Exemption Reason Code: ${this.taxExemptionReasonCodeDisplay}
    Exemptions: ${this.taxExemptionsDisplay}
    Exemption Percentage: ${this.taxExemptionPercentageDisplay}
    Sales Tax Type:  ${this.salesTaxTypeDisplay}
    `;
  }
}
