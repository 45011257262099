import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface EmailDeliveredData extends EventLogBaseData {
  email: string;
  templateName: string;
}

export class EmailDelivered extends EventLog {
  public readonly emailAddress: string;
  public readonly templateName: string;

  constructor({ email, templateName, ...baseEventData }: EmailDeliveredData) {
    super(baseEventData);

    this.emailAddress = email;
    this.templateName = templateName;
  }

  override get body() {
    return `${this.name} - ${this.emailAddress} - ${this.templateName}`;
  }
}
