import { EM_DASH } from "@common/constants/characters.constant";
import { Premise } from "@common/models/Premise.model";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { DownPaymentCalculatorStateProvider } from "@ops/components/DownPaymentCalculator/DownPaymentCalculatorStateProvider";
import { EditPaymentExtension } from "@ops/components/PaymentExtensionCard/EditPaymentExtension/EditPaymentExtension";
import { PaymentExtensionFormStateProvider } from "@ops/components/PaymentExtensionForm/PaymentExtensionFormStateProvider";
import { PaymentExtensionModalStateProvider } from "@ops/components/PaymentExtensionWizardModal/PaymentExtensionModalStateProvider";
import { PaymentExtensionWizardModal } from "@ops/components/PaymentExtensionWizardModal/PaymentExtensionWizardModal";
import { AccountSummary } from "@ops/models/AccountSummary.model";
import React from "react";

interface PaymentExtensionCardProps {
  accountSummary: AccountSummary;
  onMakePayment: () => void;
  premise: Premise;
}

export const PaymentExtensionCard = ({
  premise,
  accountSummary,
  onMakePayment,
}: PaymentExtensionCardProps) => {
  let extendPaymentInfo: string;

  const { currentPaymentExtension, id } = premise;

  if (currentPaymentExtension) {
    extendPaymentInfo = `${formatCurrency(
      parseFloat(currentPaymentExtension.amountExtended)
    )} by ${formatMonthDayYear(currentPaymentExtension.extendToDate)}`;
  } else {
    extendPaymentInfo = EM_DASH;
  }

  const renderPaymentExceptionComponent = (
    premiseHasPaymentExtension: boolean
  ): JSX.Element | null => {
    if (premiseHasPaymentExtension) {
      return (
        <EditPaymentExtension
          pastDueBalance={accountSummary.pastDueBalance}
          currentPaymentExtension={currentPaymentExtension}
          premise={premise}
        />
      );
    }

    return (
      <PaymentExtensionModalStateProvider>
        <PaymentExtensionFormStateProvider>
          <DownPaymentCalculatorStateProvider>
            <PaymentExtensionWizardModal
              pastDueBalance={accountSummary.pastDueBalance}
              premiseId={id}
              onMakePayment={onMakePayment}
            />
          </DownPaymentCalculatorStateProvider>
        </PaymentExtensionFormStateProvider>
      </PaymentExtensionModalStateProvider>
    );
  };

  return (
    <BoCard data-testid="premise-billing-card-target">
      <BoGrid>
        <BoGridColumn>
          <BoFlexBox>
            <BoInfoContainer>
              <BoInfoLabel>Extend Payment</BoInfoLabel>
              <BoInfoValue>{extendPaymentInfo}</BoInfoValue>
            </BoInfoContainer>
          </BoFlexBox>
        </BoGridColumn>
        <BoGridColumn>
          {renderPaymentExceptionComponent(Boolean(currentPaymentExtension))}
        </BoGridColumn>
      </BoGrid>
    </BoCard>
  );
};
