import { JSONRender } from "@common/components/PreJSON/JSONRender";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { BillingInfoTab } from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoTab";
import { ConfirmationTab } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationTab";
import { ContactInfoTab } from "@ops/components/ProspectEditor/ContactInfoTab/ContactInfoTab";
import { MarketingTab } from "@ops/components/ProspectEditor/MarketingTab/MarketingTab";
import { NewProspectHeader } from "@ops/components/ProspectEditor/NewProspectHeader";
import { PlansTab } from "@ops/components/ProspectEditor/PlansTab/PlansTab";
import { ProductAddonsTab } from "@ops/components/ProspectEditor/ProductAddonsTab/ProductAddonsTab";
import { SaveButtons } from "@ops/components/ProspectEditor/SaveButtons/SaveButtons";
import { ServiceAddressTab } from "@ops/components/ProspectEditor/ServiceAddressTab/ServiceAddressTab";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { ProspectEditorTabs } from "@ops/components/ProspectEditorTabs/ProspectEditorTabs";
import { SupportLogs } from "@ops/components/SupportLogs/SupportLogs";
import { TargetType } from "@ops/components/SupportLogs/SupportLogs.types";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { prospectsHomePath } from "@ops/routes/routePaths";
import { isLocal } from "@ops/utils/env.util";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const tabComponents: Record<TabType, JSX.Element> = {
  billing: <BillingInfoTab />,
  confirmation: <ConfirmationTab />,
  contactInfo: <ContactInfoTab />,
  marketing: <MarketingTab />,
  plan: <PlansTab />,
  productAddons: <ProductAddonsTab />,
  serviceAddress: <ServiceAddressTab />,
};

const ReturnLinkWrapper = styled(Body1).attrs({ as: "div" })``;

export const ProspectEditor = () => {
  const { currentTabComponent, prospect } = useEnrollmentFlowContext();

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`#${currentTabComponent}`);
  }, [currentTabComponent]);

  return (
    <BoGrid $variant={GridVariants.Grid2_1}>
      <BoGridColumn>
        <ReturnLinkWrapper>
          <Link to={prospectsHomePath()}>&lt; Prospects</Link>
        </ReturnLinkWrapper>

        <div>
          <NewProspectHeader prospect={prospect} />
        </div>

        {isLocal() && (
          <BoCard
            style={{ height: "150px", maxWidth: "720px", overflow: "scroll" }}
          >
            <JSONRender object={prospect} />
          </BoCard>
        )}
        <ProspectEditorTabs />
        {tabComponents[currentTabComponent]}
        {currentTabComponent !== TabType.confirmation && <SaveButtons />}
      </BoGridColumn>
      <BoGridColumn>
        <SupportLogs target={{ id: prospect.id, type: TargetType.PROSPECT }} />
      </BoGridColumn>
    </BoGrid>
  );
};
