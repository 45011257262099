// import { DeferPaymentComponentType } from "@ops/components/DeferPaymentManageWizard/DeferPaymentManageModal";
import React, { PropsWithChildren, createContext, useState } from "react";

interface ModalStateType {
  activeComponent: DeferPaymentManageComponentType;
}

export enum DeferPaymentManageComponentType {
  addPaymentMethod = "addPaymentMethod",
  deferPaymentManageForm = "deferPaymentManageForm",
  deferPaymentPayOffForm = "deferPaymentPayOffForm",
  deferPaymentPayOffSuccess = "deferPaymentPayOffSuccess",
  loading = "loading",
}

export interface DeferPaymentManageModalStateProviderContext {
  activeComponent: DeferPaymentManageComponentType;
  resetModalState: () => void;
  updateModalState: (
    content: Partial<DeferPaymentManageModalStateProviderContext>
  ) => void;
}

export const initialDeferPaymentManageModalState = {
  activeComponent: DeferPaymentManageComponentType.loading,
};

export const DeferPaymentManageModalStateContext =
  createContext<DeferPaymentManageModalStateProviderContext>({
    activeComponent: DeferPaymentManageComponentType.loading,
    resetModalState: () => {},
    updateModalState: (content) => {},
  });

interface DeferPaymentManageModalStateProviderProps {}
export const DeferPaymentManageModalStateProvider = ({
  children,
}: PropsWithChildren<DeferPaymentManageModalStateProviderProps>) => {
  const [modalState, setModalState] = useState<ModalStateType>(
    initialDeferPaymentManageModalState
  );

  const updateModalState = (content: Partial<ModalStateType>) => {
    setModalState({ ...modalState, ...content });
  };

  const resetModalState = () => {
    updateModalState({
      activeComponent: DeferPaymentManageComponentType.deferPaymentManageForm,
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: DeferPaymentManageModalStateProviderContext = {
    activeComponent: modalState.activeComponent,
    resetModalState,
    updateModalState,
  };

  return (
    <DeferPaymentManageModalStateContext.Provider value={actions}>
      {children}
    </DeferPaymentManageModalStateContext.Provider>
  );
};
