import { IdType } from "@common/types/apiTypes";
import { rhapsodyProductContractsApi } from "@ops-data/api/rhapsodyProductContractsApi";
import { ProductContractKeys } from "@ops/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCancelProductContract = (premiseId: IdType) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: rhapsodyProductContractsApi.cancel,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ProductContractKeys.PREMISE_LIST(premiseId),
      });
    },
  });

  return mutation;
};
