export enum EdiActions {
  DISCONNECT = "disconnect",
  MOVE_OUT_CANCEL = "move_out_cancel",
  RECONNECT = "reconnect",
  RESUBMIT_ENROLLMENT = "resubmit_enrollment",
  SWITCH_CANCEL = "switch_cancel",
  SWITCH_HOLD_CREATE = "switch_hold_create",
  SWITCH_HOLD_REMOVE = "switch_hold_remove",
}

export const DUNS_NUMBERS = {
  AEP_CENTRAL_DUNS: "007924772",
  AEP_NORTH_DUNS: "007923311",
  CENTERPOINT_DUNS: "957877905",
  ONCOR_DUNS: "1039940674000",
  TNMP_DUNS: "007929441",
};

export const EDI_CONSTANTS = {
  ...DUNS_NUMBERS,
  AEP_CENTRAL_NAME: "AEP_CENTRAL",
  AEP_NORTH_NAME: "AEP_NORTH",
  CENTERPOINT_NAME: "CENTERPOINT",
  ONCOR_NAME: "ONCOR",
  TNMP_NAME: "TNMP",
};

export const DUNS_NAME_MAPPING = {
  [EDI_CONSTANTS.CENTERPOINT_DUNS]: EDI_CONSTANTS.CENTERPOINT_NAME,
  [EDI_CONSTANTS.ONCOR_DUNS]: EDI_CONSTANTS.ONCOR_NAME,
  [EDI_CONSTANTS.AEP_CENTRAL_DUNS]: EDI_CONSTANTS.AEP_CENTRAL_NAME,
  [EDI_CONSTANTS.AEP_NORTH_DUNS]: EDI_CONSTANTS.AEP_NORTH_NAME,
  [EDI_CONSTANTS.TNMP_DUNS]: EDI_CONSTANTS.TNMP_NAME,
};

export const DUNS_DISPLAY_NAME_MAPPING = {
  [EDI_CONSTANTS.CENTERPOINT_DUNS]: "CenterPoint",
  [EDI_CONSTANTS.ONCOR_DUNS]: "Oncor",
  [EDI_CONSTANTS.AEP_CENTRAL_DUNS]: "AEP Central",
  [EDI_CONSTANTS.AEP_NORTH_DUNS]: "AEP North",
  [EDI_CONSTANTS.TNMP_DUNS]: "TNMP",
};
