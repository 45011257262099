import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

const TICK_WIDTH = "6px";

export const EnergyChargeContainer = styled.div`
  margin-bottom: ${rhSpacingPx(2)};
`;

export const PriceScheduleBreakdownDateRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
  }
`;

export const ScheduleTitlesContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: column;
  }
`;

export const ScheduleTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  padding: 0 ${rhSpacingPx(2)};
  text-align: center;
  width: calc(100% - ${TICK_WIDTH});

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
    padding: ${rhSpacingPx(2)} 0;
    text-align: start;
    width: 100%;
  }
`;

export const BorderLine = styled.div`
  background-color: ${grey[100]};
  height: 100%;
  width: 1px;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    height: 1px;
    width: 100%;
  }
`;

export const ScheduleBreakdown = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const TickContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: ${rhSpacingPx(2)} 0;
  position: relative;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    padding: 0;
    width: 100%;
  }
`;

export const TickLine = styled.div`
  background-color: ${grey[300]};
  height: calc(100% - 2 * ${rhSpacingPx(2)});
  position: absolute;
  width: 1px;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    height: 1px;
    width: 100%;
  }
`;

export const Ticks = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: ${TICK_WIDTH};

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
    height: ${TICK_WIDTH};
    width: 100%;
  }
`;

export const Tick = styled.div`
  background-color: ${grey[300]};
  height: 1px;
  width: 100%;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    height: 100%;
    width: 1px;
  }
`;
