import { CustomerStatus } from "@common/enums/customer.enum";
import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import { StatusIndicatorLevel } from "@common/types/statusIndicatorTypes";
import { StatusIndicator } from "@ops/components/StatusIndicator/StatusIndicator";
import React from "react";
import styled from "styled-components";

const customerStatusType = {
  [CustomerStatus.ACTIVE]: StatusIndicatorLevel.SUCCESS,
  [CustomerStatus.INACTIVE]: StatusIndicatorLevel.ALERT,
  [CustomerStatus.PENDING]: StatusIndicatorLevel.WARN,
};

interface CustomerStatusIndicatorsProps {
  activePremise: Premise | null;
  customer: Customer;
}

export const StyledCustomerStatusIndicator = styled.div`
  display: flex;

  & > :last-child {
    span:last-child {
      margin-right: 5px;
    }
  }
`;

export const CustomerStatusIndicators = ({
  customer,
  activePremise,
}: CustomerStatusIndicatorsProps) => {
  return (
    <StyledCustomerStatusIndicator>
      {activePremise && (
        <StatusIndicator status={customerStatusType[customer.status]}>
          {customer.status}
        </StatusIndicator>
      )}
      {customer.isFromIronhide && (
        <StatusIndicator status={StatusIndicatorLevel.INFO}>
          IRONHIDE
        </StatusIndicator>
      )}
      {customer.isEmployee && (
        <StatusIndicator status={StatusIndicatorLevel.INFO}>
          EMPLOYEE
        </StatusIndicator>
      )}
    </StyledCustomerStatusIndicator>
  );
};
