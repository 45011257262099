import { useDebounce } from "@common/hooks/useDebounce";
import { useEffect, useState } from "react";

interface WindowSize {
  height: number;
  width: number;
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const updateWindowSize = () => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  const debouncedUpdateWindowSize = useDebounce(updateWindowSize, 50);

  useEffect(() => {
    window.addEventListener("resize", debouncedUpdateWindowSize);
    return () => {
      window.removeEventListener("resize", debouncedUpdateWindowSize);
    };
  }, []);

  return windowSize;
};
