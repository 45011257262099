import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import {
  ContactLogType,
  CreateContactLogType,
} from "@ops-utils/types/contactLogTypes";
import { EventLogResponse } from "@ops-utils/types/eventLogTypes";

const BASE_PATH = "/api/customers";

export const legacyCustomerApi = {
  contactLogs: {
    create: (
      customerId: IdType,
      contactLog: CreateContactLogType
    ): Promise<void> => {
      return ajax.post(`${BASE_PATH}/${customerId}/contact-logs/`, contactLog);
    },
    list: (customerId: IdType): Promise<ContactLogType[]> => {
      return ajax.get(`${BASE_PATH}/${customerId}/contact-logs/`);
    },
  },
  eventLogs: {
    list: (customerId: IdType): Promise<EventLogResponse> => {
      return ajax.get(`${BASE_PATH}/${customerId}/event-logs/`);
    },
  },
};
