import { StyledAdminPage } from "@ops/components/AdminDashboard/shared/StyledAdminPage";
import { TransferEdiMessageForm } from "@ops/components/TransferEdiMessageForm/TransferEdiMessageForm";
import React from "react";

export const TransferEdiMessagePage = () => {
  return (
    <StyledAdminPage pageName="Transfer EDI Messages">
      <TransferEdiMessageForm />
    </StyledAdminPage>
  );
};
