import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { ReactComponent as ContractChangesIcon } from "@design-system/icons/bill-factors/ContractChanges.svg";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { StyledBillFactor } from "@ops/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorsContractChangesProps {
  isVariableRate: boolean;
  previousContractEndDate: string;
}

export const BillFactorsContractChanges = ({
  isVariableRate,
  previousContractEndDate,
}: BillFactorsContractChangesProps) => {
  return (
    <StyledBillFactor>
      <ContractChangesIcon />
      <Body1 $fontWeight="Bold">Contract Changes</Body1>
      <Body2>
        {isVariableRate
          ? `Your contract ended on ${formatMonthDayYear(previousContractEndDate)}, and you have been automatically enrolled in a month-to-month plan.`
          : `Your contract ended on ${formatMonthDayYear(previousContractEndDate)}, and you are in a new contract.`}
      </Body2>
    </StyledBillFactor>
  );
};
