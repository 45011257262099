import { ajax } from "@common/services/ajax";
import { rhWindow } from "@common/utils/rhWindow";

export const authenticatedFileDownload = (
  url: string,
  filename: string,
  mimeType: MimeType
) => {
  return (
    ajax
      .get(url, {
        responseType: "blob",
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((fileBlob: any) => {
        const objectUrl = rhWindow.URL.createObjectURL(
          new Blob([fileBlob], mimeType)
        );
        const anchor = document.createElement("a");

        anchor.href = objectUrl;
        anchor.download = filename;
        anchor.click();
        rhWindow.URL.revokeObjectURL(objectUrl);
      })
  );
};
