import { premiseUrls } from "@common/api/premiseUrls";
import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { IdType } from "@common/types/apiTypes";
import { authenticatedFileDownload } from "@common/utils/authenticatedFileDownload";

interface BuildInvoiceFilenameProps {
  billingAccountNumber: string;
  invoiceDate: string;
}

interface InvoiceDownloadProps extends BuildInvoiceFilenameProps {
  invoiceId: IdType;
  premiseId: IdType;
}

export const buildInvoiceFilename = ({
  billingAccountNumber,
  invoiceDate,
}: BuildInvoiceFilenameProps) => {
  return `${billingAccountNumber}-invoice-${invoiceDate}.pdf`;
};

export const invoiceFileDownload = ({
  billingAccountNumber,
  invoiceDate,
  invoiceId,
  premiseId,
}: InvoiceDownloadProps) => {
  const url = premiseUrls.invoicePdf(premiseId, invoiceId);
  const filename = buildInvoiceFilename({ billingAccountNumber, invoiceDate });

  return authenticatedFileDownload(url, filename, PDF_MIME_TYPE);
};
