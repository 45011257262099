import { createRhContext } from "@common/utils/contextHelpers";
import { BoSelectValue } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import React, { PropsWithChildren, useState } from "react";

interface FormStateType {
  currentPaymentMethod: BoSelectValue;
  errors: string | null;
}

export interface DeferPaymentManageFormStateProviderContext {
  currentPaymentMethod: BoSelectValue;
  errors: string | null;
  resetFormState: () => void;
  updateFormState: (
    content: Partial<DeferPaymentManageFormStateProviderContext>
  ) => void;
}

export const initialDeferPaymentManageFormState = {
  currentPaymentMethod: null,
  errors: null,
};

export const DeferPaymentManageFormStateContext =
  createRhContext<DeferPaymentManageFormStateProviderContext>();

interface DeferPaymentManageFormStateProviderProps {}
export const DeferPaymentManageFormStateProvider = ({
  children,
}: PropsWithChildren<DeferPaymentManageFormStateProviderProps>) => {
  const [formState, setFormState] = useState<FormStateType>(
    initialDeferPaymentManageFormState
  );

  const updateFormState = (content: Partial<FormStateType>) => {
    setFormState({ ...formState, ...content });
  };

  const resetFormState = () => {
    updateFormState(initialDeferPaymentManageFormState);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: DeferPaymentManageFormStateProviderContext = {
    currentPaymentMethod: formState.currentPaymentMethod,
    errors: formState.errors,
    resetFormState,
    updateFormState,
  };

  return (
    <DeferPaymentManageFormStateContext.Provider value={actions}>
      {children}
    </DeferPaymentManageFormStateContext.Provider>
  );
};
