import { KILOWATT_HOURS_ABBREV } from "@common/constants/other.constant";

export const formatEnergyRate = (
  rateDollarsPerKwh: number | string,
  fractionDigits = 1
) => {
  const val =
    typeof rateDollarsPerKwh === "number"
      ? rateDollarsPerKwh
      : parseFloat(rateDollarsPerKwh);

  return `${(100.0 * val).toFixed(fractionDigits)}¢/${KILOWATT_HOURS_ABBREV}`;
};
