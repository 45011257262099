import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  BoModal,
  BoModalProps,
} from "@ops-design-system/components/BoModal/BoModal";
import {
  Body2,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as HighFive } from "@ops-design-system/images/HighFive.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

interface NewEnrollmentModalProps
  extends Pick<BoModalProps, "open" | "onClose"> {
  confirmationNumber: string;
  name: string;
}

const ImageContainer = styled.div`
  width: 70px;
`;
const TextContainer = styled.div`
  padding-left: ${rhOpsSpacingPx(4)};
`;
const ConfirmationNumberContainer = styled.div`
  padding-top: ${rhOpsSpacingPx(2)};
`;

export const NewEnrollmentModal = ({
  open,
  onClose,
  confirmationNumber,
  name,
}: NewEnrollmentModalProps) => {
  return (
    <BoModal open={open} onClose={onClose}>
      <BoDialogHeader>New Enrollment</BoDialogHeader>
      <BoDialogBody>
        <BoFlexBox $alignItems="center">
          <ImageContainer>
            <HighFive />
          </ImageContainer>
          <TextContainer>
            <Subtitle2 $fontWeight="SemiBold" as="div">
              Congrats!
            </Subtitle2>
            <Subtitle2 as="div">{name} is signed up!</Subtitle2>
            <ConfirmationNumberContainer>
              <Body2>Confirmation #{confirmationNumber}</Body2>
            </ConfirmationNumberContainer>
          </TextContainer>
        </BoFlexBox>
      </BoDialogBody>
      <BoDialogButtonFooter
        cancelBtnText="View Customer Page"
        onCancel={onClose}
      />
    </BoModal>
  );
};
