import { PremiseStatus } from "@common/enums/premise.enum";
import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { DeferredPaymentPlanType } from "@common/types/deferredPaymentPlanTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as Cog } from "@ops-design-system/icons/Cog.svg";
import { AddACreditCardForm } from "@ops/components/AddCreditCardForm/AddACreditCardForm";
import { DeferPaymentManageForm } from "@ops/components/DeferPaymentManageForm/DeferPaymentManageForm";
import { useDeferPaymentManageFormState } from "@ops/components/DeferPaymentManageForm/useDeferPaymentManageFormState";
import { DeferPaymentManageComponentType } from "@ops/components/DeferPaymentManageWizard/DeferPaymentManageModalStateProvider";
import { useDeferPaymentManageModalState } from "@ops/components/DeferPaymentManageWizard/useDeferPaymentManageModalStateProvider";
import { DeferPaymentPayOffForm } from "@ops/components/DeferPaymentPayOffForm/DeferPaymentPayOffForm";
import { DeferPaymentPayOffSuccess } from "@ops/components/DeferPaymentPayOffForm/DeferPaymentPayOffSuccess";
import { LoadingModalContent } from "@ops/components/LoadingModalContent/LoadingModalContent";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { paymentMethodsFetch } from "@ops/slices/paymentMethodsSlice";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export interface CreateDeferPaymentPayOffSuccessType {
  amountPaid: number;
  zuoraPaymentNumber?: string;
}

interface DeferPaymentManageModalProps {
  activeDeferredPaymentPlan: DeferredPaymentPlanType;
  currentBalance: number;
  onSuccess: () => void;
  premise: Premise;
}

export const DeferPaymentManageModal = ({
  activeDeferredPaymentPlan,
  currentBalance,
  premise,
  onSuccess,
}: DeferPaymentManageModalProps) => {
  const { open, close: closeModal, isOpen } = useModalState();

  const { resetFormState } = useDeferPaymentManageFormState();

  const { paymentMethods, requestMonitor } = usePaymentMethods(premise.id);

  const [
    createDeferPaymentPayOffSuccessResponse,
    setCreateDeferPaymentPayOffSuccessResponse,
  ] = useState<CreateDeferPaymentPayOffSuccessType | null>(null);

  const { updateModalState, activeComponent, resetModalState } =
    useDeferPaymentManageModalState();

  const dispatch = useDispatch();

  const resetDeferModalState = () => {
    setCreateDeferPaymentPayOffSuccessResponse(null);
    resetFormState();
    resetModalState();
  };

  const close = () => {
    closeModal();
    resetDeferModalState();
  };

  const handleAddedCard = () => {
    dispatch(paymentMethodsFetch(premise.id));
    updateModalState({
      activeComponent: DeferPaymentManageComponentType.deferPaymentManageForm,
    });
  };

  const handleSuccess = (amountPaid: number, zuoraPaymentNumber?: string) => {
    setCreateDeferPaymentPayOffSuccessResponse({
      amountPaid,
      zuoraPaymentNumber,
    });
    updateModalState({
      activeComponent:
        DeferPaymentManageComponentType.deferPaymentPayOffSuccess,
    });
  };

  const deferPaymentManageButtonDisabled =
    !premise.hasActiveDpp || premise.status !== PremiseStatus.ACTIVE;

  useEffect(() => {
    const setInitialActivePaymentComponent = (paymentMethodCount: number) => {
      if (isNil(paymentMethodCount)) {
        updateModalState({
          activeComponent: DeferPaymentManageComponentType.loading,
        });
      } else {
        updateModalState({
          activeComponent:
            DeferPaymentManageComponentType.deferPaymentManageForm,
        });
      }
    };

    if (requestMonitor.didSucceed) {
      setInitialActivePaymentComponent(paymentMethods?.length ?? 0);
    }
    if (requestMonitor.didFail) {
      updateModalState({
        activeComponent: DeferPaymentManageComponentType.deferPaymentManageForm,
      });
    }
  }, [paymentMethods, requestMonitor.didSucceed, requestMonitor.didFail]);

  const deferPaymentManageComponents: Record<
    DeferPaymentManageComponentType,
    () => JSX.Element
  > = {
    addPaymentMethod: () => (
      <AddACreditCardForm
        premiseId={premise.id}
        onCancel={() => {
          updateModalState({
            activeComponent:
              DeferPaymentManageComponentType.deferPaymentManageForm,
          });
        }}
        onSuccess={handleAddedCard}
        setAsDefault={(paymentMethods?.length ?? 0) === 0}
      />
    ),
    deferPaymentManageForm: () => (
      <DeferPaymentManageForm
        activeDeferredPaymentPlan={activeDeferredPaymentPlan}
        currentBalance={currentBalance}
        premiseId={premise.id}
        closeModal={close}
        onSuccess={onSuccess}
      />
    ),
    deferPaymentPayOffForm: () => (
      <DeferPaymentPayOffForm
        activeDeferredPaymentPlan={activeDeferredPaymentPlan}
        currentBalance={currentBalance}
        premiseId={premise.id}
        closeModal={close}
        onSuccess={handleSuccess}
      />
    ),
    deferPaymentPayOffSuccess: () => (
      <DeferPaymentPayOffSuccess
        newDeferPaymentPayOffData={createDeferPaymentPayOffSuccessResponse}
        closeModal={close}
        onSuccess={onSuccess}
      />
    ),
    loading: () => <LoadingModalContent headerText="Manage Deferred Payment" />,
  };

  return (
    <>
      <BoButton
        fullWidth
        icon={Cog}
        variant="secondary"
        size="extraSmall"
        onClick={open}
        disabled={deferPaymentManageButtonDisabled}
      >
        Manage DPP
      </BoButton>

      <BoModal
        open={isOpen}
        size={
          activeComponent ===
          DeferPaymentManageComponentType.deferPaymentPayOffForm
            ? "medium"
            : "default"
        }
        onClose={close}
      >
        {deferPaymentManageComponents[activeComponent]()}
      </BoModal>
    </>
  );
};
