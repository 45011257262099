import { MaskedPattern } from "imask";

const ssnMask = new MaskedPattern({ mask: "000-00-0000" });

export const maskSSN = (value: string) => {
  ssnMask.value = value ?? "";
  return ssnMask.value;
};

export const unmaskSSN = (value: string) => {
  ssnMask.value = value ?? "";
  return ssnMask.unmaskedValue;
};
