import {
  DeferPaymentManageFormStateContext,
  DeferPaymentManageFormStateProviderContext,
} from "@ops/components/DeferPaymentManageForm/DeferPaymentManageFormStateProvider";
import { useContext } from "react";

export function useDeferPaymentManageFormState(): DeferPaymentManageFormStateProviderContext {
  const context = useContext<DeferPaymentManageFormStateProviderContext | null>(
    DeferPaymentManageFormStateContext
  );

  if (context === null) {
    throw new Error(
      "Using DeferPaymentManageFormStateProviderContext outside provider"
    ); // throw error if using this hook outside the provider
  }
  return context;
}
