import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const NewPremiseDetailsSideBarContentBox = styled.div`
  padding-top: ${rhOpsSpacingPx(2)};
`;

export const NewPremiseDetailsSideBarStyledLabel = styled(Body3).attrs({
  as: "div",
})`
  color: ${grey["900"]};
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 2px;
`;

export const NewPremiseDetailsSideBarStyledValue = styled(Body2).attrs({
  as: "div",
})`
  color: ${grey["600"]};
`;

export const NewPremiseDetailsSideBarContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(1.5)};
`;
