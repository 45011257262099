import { formatCurrency } from "@common/utils/dataFormatters";
import { ReactComponent as PastDueIcon } from "@design-system/icons/bill-factors/PastDue.svg";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { StyledBillFactor } from "@ops/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorsPastDueProps {
  pastDueAmount: string;
}

export const BillFactorsPastDue = ({
  pastDueAmount,
}: BillFactorsPastDueProps) => {
  return (
    <StyledBillFactor>
      <PastDueIcon />
      <Body1 $fontWeight="Bold">Rollover Balance</Body1>
      <Body2>
        There&apos;s a rollover balance of {formatCurrency(pastDueAmount)} on
        your account, which has been added to this bill.
      </Body2>
    </StyledBillFactor>
  );
};
