import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface LoadProfileChangedData extends EventLogBaseData {
  esiId: string;
  newValue: string;
  oldValue: string;
}

export class LoadProfileChanged extends EventLog {
  public readonly oldValue: string;
  public readonly newValue: string;
  public readonly esiId: string;

  constructor({
    oldValue,
    newValue,
    esiId,
    ...baseEventData
  }: LoadProfileChangedData) {
    super(baseEventData);

    this.oldValue = oldValue;
    this.newValue = newValue;
    this.esiId = esiId;
  }

  override get body() {
    return `${this.name} - Old value: ${this.oldValue} - New value: ${this.newValue} - Esiid: ${this.esiId}`;
  }
}
