import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ProductContractExpirationNoticeData extends EventLogBaseData {
  currentEnd: string;
  currentProductName: string;
  currentRate: string;
  currentStart: string;
  currentTerm: string;
  rolloverEnd: string;
  rolloverProductName: string;
  rolloverRate: string;
  rolloverStart: string;
  rolloverTerm: string;
}

export class ProductContractExpirationNotice extends EventLog {
  public readonly currentProductName: string;
  public readonly currentStart: string;
  public readonly currentEnd: string;
  public readonly currentRate: string;
  public readonly currentTerm: string;
  public readonly rolloverProductName: string;
  public readonly rolloverStart: string;
  public readonly rolloverEnd: string;
  public readonly rolloverRate: string;
  public readonly rolloverTerm: string;

  constructor({
    currentProductName,
    currentStart,
    currentEnd,
    currentRate,
    currentTerm,
    rolloverProductName,
    rolloverStart,
    rolloverEnd,
    rolloverRate,
    rolloverTerm,
    ...baseEventData
  }: ProductContractExpirationNoticeData) {
    super({
      ...baseEventData,
      eventText: "Product Contract Expiration Notice Sent",
    });
    this.currentProductName = currentProductName;
    this.currentStart = currentStart;
    this.currentEnd = currentEnd;
    this.currentRate = currentRate;
    this.currentTerm = currentTerm;
    this.rolloverProductName = rolloverProductName;
    this.rolloverStart = rolloverStart;
    this.rolloverEnd = rolloverEnd;
    this.rolloverRate = rolloverRate;
    this.rolloverTerm = rolloverTerm;
  }

  override get body() {
    return `${this.name} - Email sent to the customer advising their Product Add On, ${this.rolloverProductName} has auto-renewed. The new cost per month $ ${this.rolloverRate} - Term: ${this.rolloverTerm} months - set to begin on ${this.rolloverStart}`;
  }
}
