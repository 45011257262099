import { OPS_THEME_SPACING } from "@ops-design-system/utils/constants";
import { css } from "styled-components";

export const rhOpsSpacing = (size: number) => {
  return OPS_THEME_SPACING * size;
};
export const rhOpsSpacingPx = (num: number) => `${num * OPS_THEME_SPACING}px`;

// This is like doing display: None, but does not hide the element
export const visuallyHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
