import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { ReactComponent as RhythmLogo } from "@common/images/rhythmRhapsodyLogo.svg";
import { isCSRs, isFinance, isOps } from "@common/utils/authenticationHelpers";
import { useOktaAuth } from "@okta/okta-react";
import { BoHeader } from "@ops-design-system/components/BoHeader/BoHeader";
import {
  adminPaths,
  affiliatesPath,
  customerSearchPath,
  financeFampPath,
  logoutPath,
  prospectsHomePath,
  signInPath,
} from "@ops/routes/routePaths";
import React from "react";
import { Link, NavLink } from "react-router-dom";

interface RhNavLinkProps {
  label: string;
  to: string;
}

const RhNavLink = ({ to, label }: RhNavLinkProps) => {
  return (
    <NavLink to={to} title={label}>
      {label}
    </NavLink>
  );
};

const FinanceNavLinks = [
  <RhNavLink key={financeFampPath()} to={financeFampPath()} label="FAMP" />,
];

const AccessControlNavLinks = [
  <RhNavLink key={affiliatesPath()} to={affiliatesPath()} label="Affiliates" />,
];

export const OpsHeader = () => {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated || false;

  const OpsAndCSRsNavLinks = [
    <RhNavLink
      key={prospectsHomePath()}
      to={prospectsHomePath()}
      label="Prospects"
    />,
    <RhNavLink
      key={customerSearchPath()}
      to={customerSearchPath()}
      label="Customer Search"
    />,
  ];

  const { data: groups } = useAuthenticatedUserGroups();

  let items: JSX.Element[] = [];

  if (!isAuthenticated) {
    items.push(
      <RhNavLink key={signInPath()} to={signInPath()} label="Log In" />
    );
  } else {
    if (isOps(groups)) {
      items = items.concat(AccessControlNavLinks);
    }
    if (isOps(groups) || isCSRs(groups)) {
      items = items.concat(OpsAndCSRsNavLinks);
    }
    if (isFinance(groups)) {
      items = items.concat(FinanceNavLinks);
    }
    if (isOps(groups)) {
      items.push(
        <RhNavLink
          key={adminPaths.home()}
          to={adminPaths.home()}
          label="Admin"
        />
      );
    }
    items.push(
      <RhNavLink key={logoutPath()} to={logoutPath()} label="Log Out" />
    );
  }

  const logo = (
    <Link
      title={isAuthenticated ? "Home" : "Sign In"}
      to={isAuthenticated ? "/" : signInPath()}
    >
      <RhythmLogo />
    </Link>
  );

  return <BoHeader items={items} logo={logo} />;
};
