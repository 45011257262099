import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

interface ValidationButtonContainerProps {
  $align: string;
}

export const ValidationButtonContainer = styled.div<ValidationButtonContainerProps>`
  ${({ $align = "right" }) =>
    $align === "right" ? "margin-left: auto;" : "margin-right: auto;"}
  width: 145px;
`;

export const FormRadioContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: ${rhOpsSpacingPx(1)};
  padding-bottom: ${rhOpsSpacingPx(3)};
`;
