import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoDialog } from "@ops-design-system/components/BoDialog/BoDialog";
import { BoTextButton } from "@ops-design-system/components/BoTextButton/BoTextButton";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { TOUPriceScheduleModal } from "@ops/components/TOUPriceScheduleModal/TOUPriceScheduleModal";
import { TOUPriceScheduleTableModal } from "@ops/components/TOUPriceScheduleTableModal/TOUPriceScheduleTableModal";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const StyledModalLinks = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: ${rhOpsSpacingPx(0.5)};
`;

const StyledBreakdownButton = styled(Body2)`
  color: ${darkPurple["500"]};
`;

enum EnergyBreakdownModalType {
  graphBreakdown = "graphBreakdown",
  rateBreakdown = "rateBreakdown",
}

interface TOUEnergyBreakdownModalsProps {
  offerSnapshotId: string;
}

type ModalBodyProps = {
  closeModal: () => void;
  modal: EnergyBreakdownModalType;
  offerSnapshotId: string;
};

const ModalBody = ({ closeModal, offerSnapshotId, modal }: ModalBodyProps) => {
  const {
    data: offerSnapshot,
    isError,
    isPending,
  } = useOfferSnapshotQuery({ offerSnapshotId });
  const flash = useRhFlash();

  useEffect(() => {
    if (isError) {
      flash.error("Unable to retrieve offerSnapshot");
    }
  }, [isError, flash]);

  if (isPending || !offerSnapshot) {
    return (
      <BoDialog size="medium" open>
        <BoCircularProgress />
      </BoDialog>
    );
  }

  if (modal === EnergyBreakdownModalType.graphBreakdown) {
    return (
      <TOUPriceScheduleModal
        closeModal={closeModal}
        offerSnapshot={offerSnapshot}
      />
    );
  }

  return (
    <TOUPriceScheduleTableModal
      closeModal={closeModal}
      offerSnapshot={offerSnapshot}
    />
  );
};

export const TOUEnergyBreakdownModals = ({
  offerSnapshotId,
}: TOUEnergyBreakdownModalsProps) => {
  const [modal, setModal] = useState<EnergyBreakdownModalType>();

  return (
    <StyledModalLinks>
      <BoTextButton
        onClick={() => setModal(EnergyBreakdownModalType.graphBreakdown)}
        type="button"
      >
        <StyledBreakdownButton>Graph Breakdown</StyledBreakdownButton>
      </BoTextButton>
      <BoTextButton
        onClick={() => setModal(EnergyBreakdownModalType.rateBreakdown)}
        type="button"
      >
        <StyledBreakdownButton>Rate Breakdown</StyledBreakdownButton>
      </BoTextButton>
      {modal && (
        <ModalBody
          closeModal={() => setModal(undefined)}
          offerSnapshotId={offerSnapshotId}
          modal={modal}
        />
      )}
    </StyledModalLinks>
  );
};
