import {
  ErrorContainer,
  ErrorText,
  InputContainer,
  StyledInput,
} from "@ops-design-system/components/BoTextInput/BoTextInput.styled";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import React, { FC, RefAttributes } from "react";

export interface TextInputProps extends React.InputHTMLAttributes<unknown> {
  error?: boolean;
  errorMessage?: string;
  name: string;
  placeholder: string;
  requiredIndicator?: boolean;
}

export const TextInput: FC<
  React.PropsWithChildren<TextInputProps & RefAttributes<HTMLInputElement>>
> = React.forwardRef(
  (
    {
      className,
      name,
      error = false,
      errorMessage = "",
      children,
      disabled,
      requiredIndicator,
      ...restProps
    },
    ref
  ) => {
    return (
      <InputContainer>
        <BoLabel
          htmlFor={name}
          $hasErrors={error}
          requiredIndicator={requiredIndicator}
        >
          {children}
        </BoLabel>
        <StyledInput
          $error={error}
          id={name}
          disabled={disabled}
          ref={ref}
          {...restProps}
        />
        <ErrorContainer>
          <ErrorText>{error ? errorMessage : ""}</ErrorText>
        </ErrorContainer>
      </InputContainer>
    );
  }
);
