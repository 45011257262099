import { OktaGroups } from "@common/constants/okta.constant";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";

interface UseOktaGroupsResolvedResponse {
  groups: OktaGroups[];
  hasResolvedGroups: true;
}

interface UseOktaGroupsUnresolvedResponse {
  groups: null;
  hasResolvedGroups: false;
}

export function useOktaGroups():
  | UseOktaGroupsResolvedResponse
  | UseOktaGroupsUnresolvedResponse {
  const { oktaAuth } = useOktaAuth();
  const [groups, setGroups] = useState<OktaGroups[] | null>(null);

  useEffect(() => {
    const getGroups = async () => {
      try {
        const user = await oktaAuth.getUser();

        let userGroups: OktaGroups[] = [];

        if ("groups" in user) {
          userGroups = user.groups as OktaGroups[];
        }

        setGroups(userGroups);
      } catch (e) {
        setGroups([]);
      }
    };

    getGroups();

    return () => {
      setGroups(null);
    };
  }, [oktaAuth]);

  if (groups === null) {
    return {
      groups: null,
      hasResolvedGroups: false,
    };
  }

  return { groups, hasResolvedGroups: true };
}
