import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import {
  StyledFullWidthDividerWrapper,
  StyledIconButton,
  StyledIconWrapper,
  StyledList,
  StyledListItem,
} from "@design-system/components/RhHeader/MobileRhHeader/MobileRhHeader.styled";
import { RhHeaderProps } from "@design-system/components/RhHeader/RhHeader";
import { ReactComponent as BurgerIcon } from "@design-system/icons/BurgerIcon.svg";
import { ReactComponent as XCloseIcon } from "@design-system/icons/XCloseIcon.svg";
import { Slide } from "@mui/material";
import React, { Key, useState } from "react";

type MobileRhHeaderProps = Omit<RhHeaderProps, "logo">;

export const MobileRhHeader = ({
  items,
  divideLastItem,
}: MobileRhHeaderProps) => {
  const [isSlideOpen, setIsSlideOpen] = useState<boolean>(false);

  if (!items.length) {
    return null;
  }

  const toggleSlide = () => {
    setIsSlideOpen(!isSlideOpen);
  };

  const fullWidthDivider = (
    <StyledFullWidthDividerWrapper>
      <RhDivider light orientation="horizontal" />
    </StyledFullWidthDividerWrapper>
  );

  const listItems = (
    // onClick event only applies to mobile, so no need to add a11y key handlers
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <StyledList data-testid="RhHeader__list" onClick={toggleSlide}>
      {items.map((item, index) => {
        if (!item) {
          return null;
        }

        if (item && !item.key) {
          throw new Error(
            "Each list element passed in the props must have a key"
          );
        }

        return (
          <React.Fragment key={item.key as Key}>
            {divideLastItem && index === items.length - 1 && fullWidthDivider}
            <StyledListItem
              $dividedLastItem={
                !!(divideLastItem && index === items.length - 1)
              }
            >
              {item}
            </StyledListItem>
          </React.Fragment>
        );
      })}
    </StyledList>
  );

  return (
    <>
      <StyledIconWrapper>
        <StyledIconButton
          disableRipple
          aria-label="toggle nav"
          onClick={toggleSlide}
        >
          {isSlideOpen ? <XCloseIcon /> : <BurgerIcon />}
        </StyledIconButton>
      </StyledIconWrapper>
      <Slide direction="right" in={isSlideOpen}>
        {listItems}
      </Slide>
    </>
  );
};
