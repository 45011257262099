export const trueBlack = "#000";
export const trueWhite = "#FFF";

export const grey = {
  100: "#EDEBF0",
  200: "#CCC9D1",
  300: "#ABA7B2",
  400: "#918D99",
  50: "#F5F5F7",
  500: "#777380",
  600: "#5E5A66",
  700: "#45414C",
  800: "#2E2B33",
  900: "#171619",
};

export const purple = {
  100: "#F5EBFF",
  200: "#F2F2FF",
  300: "#C085FF",
  50: "#FAF5FF",
  500: "#9633FF",
  700: "#5A1F99",
  900: "#1C0D31",
  dark: "#5A1F99",
  light: "#C085FF",
  main: "#9633FF",
};

export const darkPurple = {
  100: "#E6E6FF",
  200: "#B1ABED",
  300: "#8876DB",
  400: "#6948C9",
  50: "#F2F2FF",
  500: "#5421B7",
  600: "#4500A5",
  700: "#370084",
  800: "#290063",
  900: "#1C0042",
};

export const blue = {
  100: "#D1F1FF",
  200: "#98E0FF",
  300: "#54B8E3",
  400: "#1C94C8",
  50: "#E8F8FF",
  500: "#0078AC",
  600: "#006590",
  700: "#005275",
  800: "#003E59",
  900: "#002B3D",
};

const darkRed = "#CC2329";
const mainRed = "#B30309";

export const red = {
  100: "#FFD6D8",
  200: "#FFA4A7",
  300: "#E65E62",
  400: darkRed,
  50: "#FFEAEB",
  500: mainRed,
  600: "#990207",
  700: "#800105",
  800: "#660004",
  900: "#4D0003",
  dark: darkRed,
  main: mainRed,
};

export const orange = {
  300: "#F2994A",
  500: "#C66C1D",
  main: "#C66C1D",
};

export const green = {
  100: "#C8FAE3",
  200: "#93EDBC",
  300: "#51D192",
  400: "#1CB472",
  50: "#E3FCF1",
  500: "#04985F",
  600: "#027B54",
  700: "#015F45",
  800: "#004334",
  900: "#002620",
};
