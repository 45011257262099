import { ediApi } from "@common/api/ediApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoTextInput } from "@ops-design-system/components/BoTextInput/BoTextInput";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { FormEvent, useState } from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  margin-bottom: ${rhOpsSpacingPx(3)};
  width: 33%;
`;

const ActionWrapper = styled.div`
  display: inline-block;
`;
const Header = styled(Body2)`
  font-weight: 600;
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

const Form = styled.form`
  padding: ${rhOpsSpacingPx(2)} 0;
`;

export const ReprocessInvoiceForm = () => {
  const [crossReferenceNumber, setCrossReferenceNumber] = useState<string>("");

  const [{ requestMonitor }, { setSuccess, setPending, setFailure }] =
    useAjaxState();
  const flash = useRhFlash();

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    setPending();

    const data = {
      ediInvoiceMatchCrossReferenceNumber: crossReferenceNumber,
    };

    ediApi.invoiceMatches
      .reprocessInvlUsgmMessage(data)
      .then(() => {
        setSuccess();
        flash.success("Reprocessing Invoice");
      })
      .catch((error: RhApiError) => {
        setFailure(error);
        flash.error("Error attempting to reprocess invoice");
      });
  };

  return (
    <>
      <Header>Invoice</Header>
      <BoCard>
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <BoTextInput
              name="crossReferenceNumber"
              autoFocus
              onChange={(e) => {
                setCrossReferenceNumber(e.target.value);
              }}
            >
              Cross Reference #
            </BoTextInput>
          </InputWrapper>
          <ActionWrapper>
            <BoButton
              size="small"
              disabled={!crossReferenceNumber}
              loading={requestMonitor.isPending}
              type="submit"
            >
              Reprocess Invoice
            </BoButton>
          </ActionWrapper>
        </Form>
      </BoCard>
    </>
  );
};
