import { IdType } from "@common/types/apiTypes";

const PREMISE_BASE_URL = "/api/premises";

export const premiseUrls = {
  accountSummary: {
    retrieve: (id: IdType) => `${PREMISE_BASE_URL}/${id}/account_summary/`,
  },
  autopay: (id: IdType): string => `${PREMISE_BASE_URL}/${id}/autopay/`,
  averagedBilling: (id: IdType) => `${PREMISE_BASE_URL}/${id}/averaged-billing`,
  averagedBillingMonthlyCharge: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/averaged-billing-monthly-charge`,
  contractExpirationDownload: (premiseId: IdType, orderId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/order/${orderId}/contract-expiration.pdf`,
  contracts: {
    renew: (id: IdType) => `${PREMISE_BASE_URL}/${id}/renew_contract`,
    swap: (id: IdType) => `${PREMISE_BASE_URL}/${id}/swap_contract`,
  },
  create: () => `${PREMISE_BASE_URL}/`,
  deferredPaymentPlans: {
    create: (id: IdType) => `${PREMISE_BASE_URL}/${id}/deferred-payment-plans`,
    downloadContract: (id: IdType, dppId: IdType) =>
      `${PREMISE_BASE_URL}/${id}/deferred-payment-plans/${dppId}/contract/`,
    list: (id: IdType) => `${PREMISE_BASE_URL}/${id}/deferred-payment-plans`,
    settle: (id: IdType, dppId: IdType) =>
      `${PREMISE_BASE_URL}/${id}/deferred-payment-plans/${dppId}/settle`,
  },
  disconnectNoticePdf: (
    premiseId: IdType,
    invoiceId: IdType,
    noticeId: IdType
  ) =>
    `${PREMISE_BASE_URL}/${premiseId}/invoices/${invoiceId}/notice/${noticeId}/disconnect_notice.pdf`,
  ediMessages: {
    create: (id: IdType) => `${PREMISE_BASE_URL}/${id}/create_edi_message/`,
    list: (id: IdType) => `${PREMISE_BASE_URL}/${id}/edi_messages/`,
  },
  esiId: (id: IdType): string => `${PREMISE_BASE_URL}/${id}/esi_id/`,
  invoicePdf: (premiseId: IdType, invoiceId: IdType = "") =>
    `${PREMISE_BASE_URL}/${premiseId}/invoice/${invoiceId}/`,
  listPaymentMethods: (id: IdType): string =>
    `${PREMISE_BASE_URL}/${id}/payment-methods`,
  mailingAddress: (id: IdType) => `${PREMISE_BASE_URL}/${id}/mailing_address/`,
  orderWelcomePacketDownload: (premiseId: IdType, orderId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/order/${orderId}/welcome-packet.pdf`,
  paymentExtensions: {
    create: (id: IdType) => `${PREMISE_BASE_URL}/${id}/payment_extensions`,
    update: (premiseId: IdType, paymentExtensionId: IdType) =>
      `${PREMISE_BASE_URL}/${premiseId}/payment-extensions/${paymentExtensionId}`,
  },
  paymentMethods: {
    delete: (id: IdType, paymentMethodId: string) =>
      `${PREMISE_BASE_URL}/${id}/payment-methods/${paymentMethodId}/`,
  },
  payments: {
    create: (id: IdType) => `${PREMISE_BASE_URL}/${id}/payments/`,
    listPaymentHistory: (id: IdType) =>
      `${PREMISE_BASE_URL}/${id}/payment_history/`,
  },
  pickedBillingDueDay: (id: IdType): string =>
    `${PREMISE_BASE_URL}/${id}/picked-billing-due-day/`,
  redeemRewards: (id: IdType) => `${PREMISE_BASE_URL}/${id}/redeem_rewards/`,
  renewalToken: (renewalToken: string) =>
    `${PREMISE_BASE_URL}/renewal-tokens/${renewalToken}`,
  retrieve: (id: IdType) => `${PREMISE_BASE_URL}/${id}`,
  serviceEndDate: (id: IdType) => `${PREMISE_BASE_URL}/${id}/service-end-date/`,
  serviceStartDate: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/service-start-date/`,
  setEnrollmentStatus: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/set_enrollment_status/`,
  usageHistory: (id: IdType) => `${PREMISE_BASE_URL}/${id}/usage-history`,
  welcomePacketMetadata: (premiseId: IdType, orderId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/order/${orderId}/welcome-packet-metadata/`,
  winback: (id: IdType) => `${PREMISE_BASE_URL}/${id}/winback/`,
};
