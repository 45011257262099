import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { StyledBillBreakdownFactorsBody } from "@ops/components/BillBreakdownFactors/BillBreakdownFactors.styled";
import { BillFactors } from "@ops/components/BillFactors/BillFactors";
import { useBillBreakdownFactorsQuery } from "@ops/hooks/queries/useBillBreakdownFactors.query";
import React from "react";

interface BillBreakdownFactorsBodyProps {
  invoiceId: string;
}

export const BillBreakdownFactorsBody = (
  props: BillBreakdownFactorsBodyProps
) => {
  const { invoiceId } = props;

  const { isError, isPending, data } = useBillBreakdownFactorsQuery({
    invoiceId,
  });

  if (isPending) {
    return <BoCircularProgress />;
  }

  if (isError) {
    return (
      <StyledBillBreakdownFactorsBody>
        <Body1>
          Oops! Something went wrong while getting your previous bill data.
          Please try again later.
        </Body1>
      </StyledBillBreakdownFactorsBody>
    );
  }

  if (data.invoiceCompatible === false) {
    return (
      <StyledBillBreakdownFactorsBody>
        <Body1>There&apos;s nothing to compare at this moment.</Body1>
        <Body2>
          This might be because the necessary data isn&apos;t available. Please
          check back with the next invoice for a comparison.
        </Body2>
      </StyledBillBreakdownFactorsBody>
    );
  }

  const isNoFactors = data.invoiceCompatible && Object.keys(data).length === 1;

  if (isNoFactors) {
    return (
      <StyledBillBreakdownFactorsBody>
        <Body1 $fontWeight="Bold">
          Not much has changed since your last bill.
        </Body1>
        <Body2>Your bill and usage are on par with last billing cycle.</Body2>
      </StyledBillBreakdownFactorsBody>
    );
  }

  return <BillFactors billFactors={data} />;
};
