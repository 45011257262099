import { ediApi } from "@common/api/ediApi";
import { IdType } from "@common/types/apiTypes";
import { EdiQueryKeys } from "@ops/constants/queryKeys.constants";
import { QUERY_STALE_TIME_DEFAULT_SHORT } from "@ops/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UseServiceStartDatesProps {
  enabled?: boolean;
  offerSnapshotId?: IdType;
  overrideMinimumDayToStartService?: boolean;
}

export const useServiceStartDates = (props: UseServiceStartDatesProps) => {
  const {
    enabled = true,
    offerSnapshotId,
    overrideMinimumDayToStartService = true,
  } = props;

  return useQuery({
    enabled,
    queryFn: () =>
      ediApi.getServiceStartDates(
        offerSnapshotId,
        overrideMinimumDayToStartService
      ),
    queryKey: EdiQueryKeys.serviceStartDates(
      offerSnapshotId,
      overrideMinimumDayToStartService
    ),
    staleTime: QUERY_STALE_TIME_DEFAULT_SHORT,
  });
};
