import { createRhContext } from "@common/utils/contextHelpers";
import { BoSelectValue } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { initialPaymentExtensionFormState } from "@ops/components/PaymentExtensionForm/PaymentExtensionForm.constants";
import React, { PropsWithChildren, useState } from "react";

interface FormStateType {
  currentPaymentMethod?: BoSelectValue;
  extendToDate: string;
  hasMadeDownPayment?: boolean;
  includePaymentTransactionFee?: boolean;
}

export interface PaymentExtensionFormStateProviderContext
  extends FormStateType {
  updateFormState: (
    content: Partial<PaymentExtensionFormStateProviderContext>
  ) => void;
}

export const PaymentExtensionFormStateContext =
  createRhContext<PaymentExtensionFormStateProviderContext>();

interface PaymentExtensionFormStateProviderProps {}
export const PaymentExtensionFormStateProvider = ({
  children,
}: PropsWithChildren<PaymentExtensionFormStateProviderProps>) => {
  const [formState, setFormState] = useState<FormStateType>(
    initialPaymentExtensionFormState
  );

  const updateFormState = (content: Partial<FormStateType>) => {
    setFormState({ ...formState, ...content });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: PaymentExtensionFormStateProviderContext = {
    ...formState,
    updateFormState,
  };

  return (
    <PaymentExtensionFormStateContext.Provider value={actions}>
      {children}
    </PaymentExtensionFormStateContext.Provider>
  );
};
