import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { OpsCheckboxInput } from "@ops-design-system/components/OpsCheckboxInput/OpsCheckboxInput";
import { OpsTextArea } from "@ops-design-system/components/OpsTextArea/OpsTextArea";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import {
  ContactLogCategoryChoice,
  ContactMethodOptions,
  CreateContactLogType,
  SentimentOptions,
} from "@ops-utils/types/contactLogTypes";
import {
  StyledAddNewButton,
  StyledContactLogCheckboxLabel,
  StyledContactLogCheckboxWrapper,
  StyledContactLogError,
  StyledContactLogSelect,
  StyledFormBody,
} from "@ops/components/SupportLogs/ContactLog/AddContactLog/AddContactLog.styled";
import { useSupportLogsContext } from "@ops/components/SupportLogs/hooks/useSupportLogsContext";
import { useCreateContactLogEntryMutation } from "@ops/hooks/mutations/useCreateContactLogEntry.mutation";
import { useContactLogOptionsQuery } from "@ops/hooks/queries/useContactLogOptions.query";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

interface ContactLogOptionsType {
  categories: BoSelectOptionType[];
  reasons: Record<IdType, BoSelectOptionType[]>;
  types: Record<IdType, BoSelectOptionType[]>;
}

export interface AddContactLogFormValues {
  category?: string;
  contactMethod: string;
  notes: string;
  outboundContact: boolean;
  reason?: string;
  sentiment?: string;
  type?: string;
}

const parseContactLogOptions = (response: ContactLogCategoryChoice[]) => {
  const contactLogOptions: ContactLogOptionsType = {
    categories: [],
    reasons: {},
    types: {},
  };

  contactLogOptions.categories = response.map((category) => {
    contactLogOptions.types[category.id] = category.types.map((type) => {
      contactLogOptions.reasons[type.id] = type.reasons.map((reason) => ({
        label: reason.value,
        value: reason.id,
      }));

      return {
        label: type.value,
        value: type.id,
      };
    });

    return { label: category.value, value: category.id };
  });

  return contactLogOptions;
};

const emptyFormValues = {
  category: "",
  contactMethod: "",
  notes: "",
  outboundContact: false,
  reason: "",
  sentiment: SentimentOptions.NEUTRAL,
  type: "",
};

const sentimentOptionElements = [
  SentimentOptions.POSITIVE,
  SentimentOptions.NEUTRAL,
  SentimentOptions.NEGATIVE,
].map((value) => (
  <option key={value} value={value}>
    {value}
  </option>
));

export const AddContactLog = () => {
  const { target } = useSupportLogsContext();
  const flash = useRhFlash();

  const optionsQuery = useContactLogOptionsQuery();

  useEffect(() => {
    if (optionsQuery.isError) {
      flash.error("Unable to get categories, types, or reasons");
    }
  }, [optionsQuery.isError, flash]);

  const createContactLogMutation = useCreateContactLogEntryMutation(
    target.type
  );

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    reset,
  } = useForm<AddContactLogFormValues>({
    defaultValues: emptyFormValues,
  });

  const selectedContactMethod = watch("contactMethod");
  const customerInteraction =
    selectedContactMethod !== ContactMethodOptions.NO_INTERACTION;
  const selectedCategory = watch("category");
  const selectedType = watch("type");

  const { open, close, isOpen } = useModalState();

  const handleClose = () => {
    reset(emptyFormValues);
    close();
  };

  const fillOBCollections = () => {
    reset({
      ...emptyFormValues,
      category: "15", // Outbound Call
      contactMethod: ContactMethodOptions.NO_INTERACTION,
      notes:
        "***OB COLLECTIONS***Courtesy call regarding a past due balance on the account. No Answer.",
      outboundContact: true,
      reason: "103", // No Answer
      sentiment: "", // N/A
      type: "88", // Collections Dialer
    });
  };

  const onSubmit: SubmitHandler<AddContactLogFormValues> = (formValues) => {
    const contactLog: CreateContactLogType = {
      category: `${formValues.category}`,
      contactMethod: `${formValues.contactMethod}`,
      notes: formValues.notes,
      outboundContact: formValues.outboundContact,
      reason: undefined,
      sentiment: undefined,
      type: `${formValues.type}`,
    };

    if (formValues.reason) {
      contactLog.reason = `${formValues.reason}`;
    }

    if (formValues.sentiment) {
      contactLog.sentiment = `${formValues.sentiment}`;
    }

    createContactLogMutation.mutate(
      { contactLog, targetId: target.id },
      {
        onError: () => flash.error("Unable to save contact log."),
        onSuccess: () => handleClose(),
      }
    );
  };

  if (optionsQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (optionsQuery.isError) {
    return null;
  }

  const contactLogOptions = parseContactLogOptions(optionsQuery.data);

  /*  Note: possibly revisit to fetch methods from backend */
  const contactMethodOptions = Object.values(ContactMethodOptions).map(
    (method) => ({
      label: method,
      value: method,
    })
  );

  const errorString = "Some required information is missing.";

  const contactMethodOptionElements = contactMethodOptions.map(
    (contactMethod) => (
      <option key={contactMethod.value} value={contactMethod.value ?? ""}>
        {contactMethod.label}
      </option>
    )
  );

  const categoryOptionElements = contactLogOptions.categories.map(
    (category) => (
      <option key={category.value} value={category.value ?? ""}>
        {category.label}
      </option>
    )
  );

  const typeOptionElements = selectedCategory
    ? contactLogOptions.types[selectedCategory].map((type) => (
        <option key={type.value} value={type.value ?? ""}>
          {type.label}
        </option>
      ))
    : null;

  const reasonsExist =
    selectedType && contactLogOptions.reasons[selectedType].length > 0;

  const reasonOptionElements =
    selectedType && reasonsExist
      ? contactLogOptions.reasons[selectedType].map((reason) => (
          <option key={reason.value} value={reason.value ?? ""}>
            {reason.label}
          </option>
        ))
      : null;

  return (
    <>
      <StyledAddNewButton onClick={open}>+ Add New</StyledAddNewButton>

      <BoModal open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BoDialogHeader>Add Contact Log</BoDialogHeader>
          <BoDialogBody>
            <StyledFormBody>
              <BoLabel requiredIndicator htmlFor="contactMethod">
                Contact Method
              </BoLabel>
              <StyledContactLogSelect
                {...register("contactMethod", {
                  onChange: (event) => {
                    if (
                      event.target.value === ContactMethodOptions.NO_INTERACTION
                    ) {
                      resetField("sentiment", { defaultValue: "" });
                    } else {
                      resetField("sentiment", {
                        defaultValue: SentimentOptions.NEUTRAL,
                      });
                    }
                  },
                  required: errorString,
                })}
                id="contactMethod"
              >
                <option value="" disabled>
                  Select...
                </option>
                {contactMethodOptionElements}
              </StyledContactLogSelect>
              {errors.contactMethod && (
                <StyledContactLogError>
                  {errors.contactMethod.message}
                </StyledContactLogError>
              )}
              <BoLabel requiredIndicator htmlFor="category">
                Category
              </BoLabel>
              <StyledContactLogSelect
                {...register("category", {
                  onChange: () => {
                    resetField("type", { defaultValue: "" });
                    resetField("reason", { defaultValue: "" });
                  },
                  required: errorString,
                })}
                id="category"
              >
                <option value="" disabled>
                  Select...
                </option>
                {categoryOptionElements}
              </StyledContactLogSelect>
              {errors.category && (
                <StyledContactLogError>
                  {errors.category.message}
                </StyledContactLogError>
              )}
              <BoLabel requiredIndicator htmlFor="type">
                Type
              </BoLabel>
              <StyledContactLogSelect
                {...register("type", {
                  onChange: () => {
                    resetField("reason", { defaultValue: "" });
                  },
                  required: errorString,
                })}
                id="type"
              >
                <option value="" disabled>
                  Select...
                </option>
                {typeOptionElements}
              </StyledContactLogSelect>
              {errors.type && (
                <StyledContactLogError>
                  {errors.type.message}
                </StyledContactLogError>
              )}
              <BoLabel htmlFor="reason">Reason</BoLabel>
              <StyledContactLogSelect
                {...register("reason", { disabled: !reasonsExist })}
                id="reason"
              >
                {reasonsExist ? (
                  <option value="" disabled>
                    Select...
                  </option>
                ) : (
                  <option value="" disabled>
                    N/A
                  </option>
                )}
                {reasonOptionElements}
              </StyledContactLogSelect>
              <BoLabel requiredIndicator htmlFor="notes">
                Notes
              </BoLabel>
              <OpsTextArea
                {...register("notes", { required: errorString })}
                id="notes"
              />
              {errors.notes && (
                <StyledContactLogError>
                  {errors.notes.message}
                </StyledContactLogError>
              )}
              <BoLabel htmlFor="sentiment">Customer Experience</BoLabel>
              <StyledContactLogSelect
                {...register("sentiment", {
                  required: {
                    message: errorString,
                    value: customerInteraction,
                  },
                })}
                id="sentiment"
                disabled={!customerInteraction}
              >
                <option value="" disabled>
                  N/A
                </option>
                {sentimentOptionElements}
              </StyledContactLogSelect>
              <StyledContactLogCheckboxWrapper>
                <StyledContactLogCheckboxLabel>
                  <OpsCheckboxInput
                    {...register("outboundContact")}
                    id="outboundContact"
                  />
                  Outbound Contact
                </StyledContactLogCheckboxLabel>
                <BoButton onClick={fillOBCollections}>
                  OB Dialer - No Answer
                </BoButton>
              </StyledContactLogCheckboxWrapper>
            </StyledFormBody>
          </BoDialogBody>
          <BoDialogButtonFooter
            confirmDisabled={createContactLogMutation.isPending}
            confirmBtnText="Add Contact Log"
            confirmBtnType="submit"
            cancelBtnText="Close"
            onCancel={handleClose}
          />
        </form>
      </BoModal>
    </>
  );
};
