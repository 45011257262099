import { IdType, ZuoraIdType } from "@common/types/apiTypes";

const BILLING_BASE_URL = "/api/billing";

export const billingUrls = {
  paymentMethods: {
    default: (id: ZuoraIdType): string =>
      `${BILLING_BASE_URL}/payment_methods/${id}/default/`,

    delete: (id: IdType) => `${BILLING_BASE_URL}/payment_methods/${id}/delete/`,
    list: () => `${BILLING_BASE_URL}/payment_methods/`,
    retrieve: (id: IdType) => `${BILLING_BASE_URL}/payment_methods/${id}/`,
    update: (id: IdType) => billingUrls.paymentMethods.retrieve(id),
  },
  rsaSignature: (): string => `${BILLING_BASE_URL}/rsa_signature`,
};
