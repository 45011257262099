import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ProspectType } from "@ops-utils/types/prospectTypes";
import { AutopayPaperlessCard } from "@ops/components/ProspectEditor/BillingInfoTab/AutopayPaperlessCard/AutopayPaperlessCard";
import { CreditCheckCard } from "@ops/components/ProspectEditor/BillingInfoTab/CreditCheckCard/CreditCheckCard";
import { ProspectPaymentMethod } from "@ops/components/ProspectEditor/BillingInfoTab/ProspectPaymentMethod/ProspectPaymentMethod";
import { ServiceCard } from "@ops/components/ProspectEditor/BillingInfoTab/ServiceCard/ServiceCard";
import { StyledTab } from "@ops/components/ProspectEditor/shared/ProspectEditor.styled";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React, { FormEventHandler, useCallback, useState } from "react";
import styled from "styled-components";

const SectionContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(2)};

  & > * {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;
const SectionHeader = styled(Subtitle2).attrs({
  as: "div",
  fontWeight: "Bold",
})``;

type BillingFormFields =
  | "bypassedCreditCheck"
  | "governmentIdRequested"
  | "nosRequested"
  | "autopay"
  | "enrollmentType"
  | "serviceStartDate"
  | "isPaperless"
  | "offersnapshotId"
  | "depositAlternativeElected";

export type BillingFormValues = Pick<ProspectType, BillingFormFields>;

export const BillingInfoTab = () => {
  const {
    updateProspectState,
    prospect: {
      bypassedCreditCheck,
      governmentIdRequested,
      nosRequested,
      autopay,
      serviceStartDate,
      isPaperless,
      enrollmentType,
      offersnapshotId,
      depositAlternativeElected,
    },
  } = useEnrollmentFlowContext();
  const [formValues, setFormValues] = useState<BillingFormValues>({
    autopay,
    bypassedCreditCheck,
    depositAlternativeElected,
    enrollmentType,
    governmentIdRequested,
    isPaperless,
    nosRequested,
    offersnapshotId,
    serviceStartDate,
  });

  const onSave: FormEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();

    updateProspectState(formValues);
  };

  const updateFormValues = useCallback((values: Partial<BillingFormValues>) => {
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
  }, []);

  return (
    <StyledTab>
      <form id={TabType.billing} onSubmit={onSave}>
        <SectionContainer>
          <SectionHeader>Credit Check and Deposit</SectionHeader>
          <CreditCheckCard
            formValues={{
              bypassedCreditCheck: formValues.bypassedCreditCheck,
              depositAlternativeElected: formValues.depositAlternativeElected,
              governmentIdRequested: formValues.governmentIdRequested,
              nosRequested: formValues.nosRequested,
            }}
            onChange={updateFormValues}
          />
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>Settings</SectionHeader>
          <AutopayPaperlessCard
            formValues={{
              autopay: formValues.autopay,
              isPaperless: formValues.isPaperless,
            }}
            updateFormValues={updateFormValues}
            offerSnapshotId={offersnapshotId}
          />
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>Payment Methods</SectionHeader>
          <ProspectPaymentMethod />
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>Service Type and Start Date</SectionHeader>
          <ServiceCard
            formValues={{
              enrollmentType: formValues.enrollmentType,
              offersnapshotId: formValues.offersnapshotId,
              serviceStartDate: formValues.serviceStartDate,
            }}
            onChange={updateFormValues}
          />
        </SectionContainer>
      </form>
    </StyledTab>
  );
};
