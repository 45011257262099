import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { AverageBillingCard } from "@ops/components/AverageBillingCard/AverageBillingCard";
import { DeferPaymentCard } from "@ops/components/DeferPaymentCard/DeferPaymentCard";
import { InvoiceTable } from "@ops/components/InvoiceTable/InvoiceTable";
import { PaymentCard } from "@ops/components/PaymentCard/PaymentCard";
import { PaymentExtensionCard } from "@ops/components/PaymentExtensionCard/PaymentExtensionCard";
import { PaymentTable } from "@ops/components/PaymentTable/PaymentTable";
import {
  useAccountSummaryQuery,
  useInvalidateAccountSummaryQuery,
} from "@ops/hooks/queries/useAccountSummaryQuery";
import { useCustomer } from "@ops/hooks/useCustomer";
import { usePremise } from "@ops/hooks/usePremise";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const PaymentTableWrapper = styled.div`
  flex-basis: 50%;
  width: 50%;
`;

export const BillingTab = () => {
  const { id: customerId, premiseId } = useParams<{
    id: IdType;
    premiseId: IdType;
  }>();
  const { premise, error } = usePremise(premiseId);
  const { customer } = useCustomer(customerId);
  const flash = useRhFlash();
  const dispatch = useDispatch();

  const {
    data: accountSummary,
    isPending,
    isError,
  } = useAccountSummaryQuery(premise?.id || "");
  const invalidateAccountSummaryQuery = useInvalidateAccountSummaryQuery();

  useEffect(() => {
    if (isError) {
      flash.error(
        "We're having trouble getting the account summary data. Please try again."
      );
    }
  }, [isError, flash]);

  if (!premise) {
    return <BoCircularProgress />;
  }

  if (isPending) {
    return <BoCircularProgress />;
  }

  if (error || isError || !accountSummary) {
    return null;
  }

  return (
    <>
      <BoGrid>
        <BoGridColumn>
          <PaymentCard
            accountSummary={accountSummary}
            customer={customer}
            premise={premise}
          />

          <AverageBillingCard premise={premise} />
        </BoGridColumn>
        <BoGridColumn>
          <DeferPaymentCard
            accountSummary={accountSummary}
            onSuccess={() => {
              dispatch(premiseRefetch(premise.id));
            }}
            premise={premise}
          />
          <PaymentExtensionCard
            accountSummary={accountSummary}
            onMakePayment={() => {
              invalidateAccountSummaryQuery(premise.id);
            }}
            premise={premise}
          />
        </BoGridColumn>
      </BoGrid>
      <InvoiceTable
        invoices={accountSummary.invoices}
        premise={premise}
        header="Invoice History"
      />
      <PaymentTableWrapper>
        <PaymentTable
          premise={premise}
          header="Payment History"
          selectable={false}
        />
      </PaymentTableWrapper>
    </>
  );
};
