export const generateAuthorizationHeader = (): Record<string, string> => {
  const oktaTokenStorage =
    localStorage && localStorage?.getItem("okta-token-storage");

  if (!oktaTokenStorage) {
    return {};
  }

  const parsedOktaTokenStorage = JSON.parse(oktaTokenStorage);

  if (!parsedOktaTokenStorage.accessToken) {
    return {};
  }

  const tokenValue = parsedOktaTokenStorage.accessToken.accessToken;
  const { tokenType } = parsedOktaTokenStorage.accessToken;

  return { Authorization: `${tokenType} ${tokenValue}` };
};
