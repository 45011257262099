import {
  EnrollmentFlowStateContext,
  EnrollmentFlowStateProviderContext,
} from "@ops/components/ProspectEditor/EnrollmentFlowStateProvider";
import { useContext } from "react";

export const useEnrollmentFlowContext = () => {
  const context = useContext<EnrollmentFlowStateProviderContext | null>(
    EnrollmentFlowStateContext
  );

  if (!context) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }

  return context;
};
