import {
  PremiseIndicatorsWizardContext,
  PremiseIndicatorsWizardContextType,
} from "@ops/components/PremiseIndicatorsWizard/PremiseIndicatorsWizardHelpers";
import { useContext } from "react";

export function useIndicatorsWizardContext(): PremiseIndicatorsWizardContextType {
  const context = useContext<PremiseIndicatorsWizardContextType | null>(
    PremiseIndicatorsWizardContext
  );

  if (context === null) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }
  return context;
}
