import { Caption } from "@ops-design-system/components/Typography/Typography";
import {
  darkPurple,
  grey,
  red,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled, { css } from "styled-components";

export const InputContainer = styled.div`
  position: relative;
`;

interface BoTextInputStyledProps {
  width?: string;
}

export const BoTextInputStyled = styled.div<BoTextInputStyledProps>`
  ${({ width }) => width && `width: ${width}`};
  position: relative;
  & input::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
`;

interface StyledInputProps {
  $error?: boolean;
  $readOnly?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  background: none;
  border: none;
  border-bottom: 1px solid ${grey["300"]};
  border-radius: 0;

  color: ${grey["900"]};
  font-size: 16px;
  margin: 0;
  margin-bottom: -1px;
  padding: 11px 10px 11px 0;
  width: 100%;

  &:active {
    outline: none;
  }

  &:focus {
    border-bottom-color: ${({ $error }) =>
      $error ? red.main : darkPurple["500"]};
    border-bottom-width: 2px;
    margin-bottom: -2px;
    outline: none;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  ${({ $readOnly }) => {
    return $readOnly
      ? css`
          color: ${grey["500"]};

          &:hover {
            border-bottom-width: 2px;
            margin-bottom: -2px;
          }
        `
      : "";
  }}

  ${({ $error }) =>
    $error
      ? css`
          border-bottom-color: ${red.main};
          border-bottom-width: 2px;
          margin-bottom: -2px;
        `
      : css``}
`;

export const ErrorContainer = styled.div`
  bottom: -${rhOpsSpacingPx(0.5)};
  position: relative;
`;

export const ErrorText = styled(Caption)`
  color: ${red.main};
`;
