import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import { createRhContext } from "@common/utils/contextHelpers";
import { BoButtonProps } from "@ops-design-system/components/BoButton/BoButton";
import { initialWinbackWizardState } from "@ops/components/WinbackWizard/winbackWizard.constants";
import { WizardComponentName } from "@ops/components/WinbackWizard/winbackWizard.enums";
import { WinbackWizardStateType } from "@ops/components/WinbackWizard/winbackWizard.types";
import React, { PropsWithChildren, useMemo, useState } from "react";

export interface WinbackWizardContextType {
  activeComponentName: WizardComponentName;
  closeModal: () => void;
  customer: Customer;
  loadingModalChild: boolean;
  nextButtonProps: Pick<BoButtonProps, "disabled" | "loading">;
  premise: Premise;
  restoreStateToInitialValues: () => void;
  setActiveComponentName: (contentName: WizardComponentName) => void;
  setLoadingModalChild: (loading: boolean) => void;
  setNextButtonProps: (
    props: Pick<BoButtonProps, "disabled" | "loading">
  ) => void;
  setPreviousComponentAsActive: () => void;
  updateWinbackWizardState: (payload: Partial<WinbackWizardStateType>) => void;
  visitedComponents: WizardComponentName[];
  winbackWizardState: WinbackWizardStateType;
}

export const WinbackWizardContext = createRhContext<WinbackWizardContextType>();

interface WinbackWizardStateProviderProps {
  closeModal: () => void;
  customer: Customer;
  premise: Premise;
}

export const WinbackWizardProvider = ({
  premise,
  customer,
  closeModal,
  children,
}: PropsWithChildren<WinbackWizardStateProviderProps>) => {
  const [state, setState] = useState<WinbackWizardStateType>(
    initialWinbackWizardState
  );

  const [activeComponentName, setActiveComponentName] =
    useState<WizardComponentName>(WizardComponentName.winbackInfoDetails);
  const [loadingModalChild, setLoadingModalChild] = useState<boolean>(false);
  const [nextButtonProps, setNextButtonProps] = useState<
    Pick<BoButtonProps, "disabled" | "loading">
  >({});

  const [visitedComponents, setVisitedComponents] = useState<
    WizardComponentName[]
  >([]);

  const updateWinbackWizardState = (
    payload: Partial<WinbackWizardStateType>
  ) => {
    const existingState = { ...state };

    setState({ ...existingState, ...payload });
  };
  const handleActiveComponentUpdate = (contentName: WizardComponentName) => {
    setVisitedComponents([...visitedComponents, activeComponentName]);
    setActiveComponentName(contentName);
  };
  const handleLoadingModalChild = (loading: boolean) => {
    setLoadingModalChild(loading);
  };
  const setPreviousComponentAsActive = () => {
    const [previousComponentName, ...componentNames] =
      visitedComponents.reverse();

    if (previousComponentName) {
      setVisitedComponents(componentNames.reverse());
      setActiveComponentName(previousComponentName);
    }
  };
  const restoreStateToInitialValues = () => {
    setActiveComponentName(WizardComponentName.winbackInfoDetails);
    setLoadingModalChild(false);
    setState(initialWinbackWizardState);
  };
  const updateNextButtonProps = (
    props: Pick<BoButtonProps, "disabled" | "loading">
  ) => {
    setNextButtonProps(props);
  };

  const value: WinbackWizardContextType = useMemo(
    () => ({
      activeComponentName,
      closeModal,
      customer,
      loadingModalChild,
      nextButtonProps,
      premise,
      restoreStateToInitialValues,
      setActiveComponentName: handleActiveComponentUpdate,
      setLoadingModalChild: handleLoadingModalChild,
      setNextButtonProps: updateNextButtonProps,
      setPreviousComponentAsActive,
      updateWinbackWizardState,
      visitedComponents,
      winbackWizardState: state,
    }),
    [
      activeComponentName,
      closeModal,
      customer,
      handleActiveComponentUpdate,
      loadingModalChild,
      nextButtonProps,
      premise,
      setPreviousComponentAsActive,
      state,
      updateWinbackWizardState,
      visitedComponents,
    ]
  );

  return (
    <WinbackWizardContext.Provider value={value}>
      {children}
    </WinbackWizardContext.Provider>
  );
};
