import {
  TabButton,
  TabList,
  TabPanel,
} from "@design-system/components/RhTabs/RhTabs.styled";
import { kebabCase } from "lodash";
import React, { useState } from "react";

interface RhTabItem {
  component: JSX.Element;
  title: string;
}

interface RhTabsProps {
  tabPanelId: string;
  tabs: RhTabItem[];
}

export const RhTabs = (props: RhTabsProps) => {
  const { tabs, tabPanelId } = props;
  const [activeTab, setActiveTab] = useState(0);

  const formattedTabPanelId = `tabpanel-${kebabCase(tabPanelId)}`;

  return (
    <>
      <TabList role="tablist">
        {tabs.map(({ title }, index) => {
          const isActiveTab = activeTab === index;

          return (
            <TabButton
              key={title}
              $active={isActiveTab}
              onClick={() => setActiveTab(index)}
              role="tab"
              aria-selected={isActiveTab}
              aria-controls={formattedTabPanelId}
              id={`tab-${kebabCase(title)}`}
            >
              {title}
            </TabButton>
          );
        })}
      </TabList>
      <TabPanel role="tabpanel" id={formattedTabPanelId}>
        {tabs[activeTab].component}
      </TabPanel>
    </>
  );
};
