import { legacyCustomerApi } from "@ops-data/api/legacyCustomerApi";
import { legacyProspectApi } from "@ops-data/api/legacyProspectApi";
import { CreateContactLogType } from "@ops-utils/types/contactLogTypes";
import { TargetType } from "@ops/components/SupportLogs/SupportLogs.types";
import { ContactLogKeys } from "@ops/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateContactLogEntryMutation = (targetType: TargetType) => {
  const queryClient = useQueryClient();

  const apiFn = {
    [TargetType.PROSPECT]: legacyProspectApi.contactLogs.create,
    [TargetType.CUSTOMER]: legacyCustomerApi.contactLogs.create,
  }[targetType];

  return useMutation({
    mutationFn: ({
      contactLog,
      targetId,
    }: {
      contactLog: CreateContactLogType;
      targetId: string;
    }) => apiFn(targetId, contactLog),
    onSuccess: (data, variables) => {
      const { targetId } = variables;

      queryClient.invalidateQueries({
        queryKey: ContactLogKeys.ENTRIES(targetType, targetId),
      });
    },
  });
};
