import { UUIDType } from "@common/types/apiTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import {
  ProductPriceFrequency,
  ProductPriceFrequencyPrettyNames,
  ProductPriceTermPeriod,
  ProductPriceType,
} from "@ops-utils/types/productTypes";
import { ProductTier } from "@ops/models/Products/ProductTier.model";

export class ProductPrice {
  tier: ProductTier;
  id: UUIDType;
  frequency: ProductPriceFrequency;
  rate: string;
  termPeriod: ProductPriceTermPeriod;
  termLength: string;

  constructor(tier: ProductTier, price: ProductPriceType) {
    this.tier = tier;
    this.id = price.id;
    this.frequency = price.frequency;
    this.rate = price.rate;
    this.termPeriod = price.termPeriod;
    this.termLength = price.termLength;
  }

  get product() {
    return this.tier.product;
  }

  get formattedPricePerFrequency() {
    const frequencyPrettyName =
      ProductPriceFrequencyPrettyNames[this.frequency];

    return `${formatCurrency(this.rate)} / ${frequencyPrettyName}`;
  }
}
