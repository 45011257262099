import { acquisitionCampaignApi } from "@common/api/campaignPromosApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { IdType } from "@common/types/apiTypes";
import {
  AcquisitionCampaignCreatePostDataType,
  AcquisitionCampaignType,
} from "@common/types/campaignTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoPhoneField } from "@ops-design-system/components/BoPhoneField/BoPhoneField";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { isValidPhoneNumber, isValidSlug } from "@ops/forms/validators";
import { useActiveCampaignSlugs } from "@ops/hooks/queries/useActiveCampaignSlugs";
import {
  useAcquisitionCampaignsEditorContext,
  useAcquisitionCampaignsListContext,
} from "@ops/pages/Admin/AcquisitionCampaignsPage/AcquisitionCampaignsPage";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const EditorDialogBody = styled(BoDialogBody)`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(3)};
  }
`;

enum ActiveSelectValue {
  active = "active",
  inactive = "inactive",
}
interface AcquisitionCampaignFormType
  extends Omit<
    AcquisitionCampaignType,
    "id" | "createdAt" | "salesChannel" | "active"
  > {
  active: ActiveSelectValue;
  medium: string;
}
const activeSelectOptions: Record<ActiveSelectValue, string> = {
  [ActiveSelectValue.active]: "Active",
  [ActiveSelectValue.inactive]: "Inactive",
};
const acquisitionCampaignFormValidator =
  generateValidationErrorCollector<AcquisitionCampaignFormType>({
    medium: [opsAndPricingIsRequired],
    name: [opsAndPricingIsRequired],
    phone: [isValidPhoneNumber],
    slug: [opsAndPricingIsRequired, isValidSlug],
  });
const emptyFormValues: AcquisitionCampaignFormType = {
  active: ActiveSelectValue.active,
  medium: "",
  name: "",
  phone: "",
  pricingCampaignSlug: "",
  slug: "",
};

const apiUpsert = (
  id: IdType | undefined,
  data: AcquisitionCampaignCreatePostDataType
) => {
  return id
    ? acquisitionCampaignApi.update(id, data)
    : acquisitionCampaignApi.create(data);
};

export const AcquisitionCampaignEditor = () => {
  const flash = useRhFlash();
  const { setSelectedCampaign, selectedCampaign, close, isOpen } =
    useAcquisitionCampaignsEditorContext();
  const { fetch: refetchAcquisiontCampaignsList } =
    useAcquisitionCampaignsListContext();
  const { slug, name, pricingCampaignSlug, active } =
    selectedCampaign ?? emptyFormValues;
  const selectedCampaignId = selectedCampaign?.id;
  const [campaignOptions, setCampaignOptions] = useState<BoSelectOptionType[]>(
    []
  );

  const campaignSlugQuery = useActiveCampaignSlugs();

  useEffect(() => {
    if (campaignSlugQuery.isError) {
      flash.error(
        "Unable to retrieve pricing campaign slugs. Please try again."
      );
      handleClose();
    }
  }, [campaignSlugQuery.isError]);

  useEffect(() => {
    if (campaignSlugQuery.data) {
      const campaignSlugs: string[] = [];

      if (
        pricingCampaignSlug &&
        !campaignSlugQuery.data.includes(pricingCampaignSlug)
      ) {
        campaignSlugs.push(pricingCampaignSlug);
      }

      campaignSlugs.push(...campaignSlugQuery.data);

      setCampaignOptions(
        campaignSlugs.map((campaignSlug) => {
          return { label: campaignSlug, value: campaignSlug };
        })
      );
    }
  }, [campaignSlugQuery.data, pricingCampaignSlug]);

  const initialFormValues: AcquisitionCampaignFormType = {
    active: active ? ActiveSelectValue.active : ActiveSelectValue.inactive,
    medium: selectedCampaign?.salesChannel ?? emptyFormValues.medium,
    name,
    phone: selectedCampaign?.phoneNumber ?? emptyFormValues.phone,
    pricingCampaignSlug,
    slug,
  };

  const handleClose = () => {
    setSelectedCampaign(null);
    close();
  };

  const submitAcquisitionCampaign = (values: AcquisitionCampaignFormType) => {
    const data = {
      ...values,
      active: values.active === ActiveSelectValue.active,
    };

    apiUpsert(selectedCampaignId, data)
      .then(() => {
        flash.success("Successfully saved campaign");
        refetchAcquisiontCampaignsList();
        handleClose();
      })
      .catch(() => {
        flash.error("Unable to save. Please try again.");
      });
  };

  return (
    <BoModal open={isOpen} onClose={handleClose}>
      <Form<AcquisitionCampaignFormType>
        validate={acquisitionCampaignFormValidator}
        initialValues={initialFormValues}
        onSubmit={submitAcquisitionCampaign}
        render={({ handleSubmit }) => (
          <>
            <BoDialogHeader>{`${
              selectedCampaignId ? "Edit" : "New"
            } Sales Campaign`}</BoDialogHeader>
            <EditorDialogBody>
              {campaignSlugQuery.isPending ? (
                <div>
                  <BoCircularProgress />
                </div>
              ) : (
                <>
                  <BoTextField name="name">Name</BoTextField>
                  <BoTextField name="slug">
                    Acquisition Campaign Slug
                  </BoTextField>
                  <BoPhoneField name="phone">Phone Number</BoPhoneField>
                  <BoSelectField
                    name="pricingCampaignSlug"
                    options={campaignOptions}
                    label="Pricing Campaign Slug (optional)"
                  />
                  <BoTextField name="medium">Sales Channel</BoTextField>
                  <BoSelectField
                    name="active"
                    options={Object.keys(activeSelectOptions).map((value) => ({
                      label:
                        activeSelectOptions[
                          value as unknown as ActiveSelectValue
                        ],
                      value,
                    }))}
                    label="Status"
                  />
                </>
              )}
            </EditorDialogBody>
            <BoDialogButtonFooter
              confirmBtnType="submit"
              confirmBtnText={`${
                selectedCampaignId ? "Update" : "Add"
              } Campaign`}
              cancelBtnText="Cancel"
              onCancel={handleClose}
              onConfirm={handleSubmit}
              confirmDisabled={!campaignSlugQuery.isSuccess}
            />
          </>
        )}
      />
    </BoModal>
  );
};
