import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { BoTextButton } from "@ops-design-system/components/BoTextButton/BoTextButton";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { usePaymentMethodContext } from "@ops/hooks/useManagePaymentContextHook";
import React from "react";
import styled from "styled-components";

interface PaymentMethodRowProps {
  allowDelete: boolean;
  paymentMethod: PaymentMethod;
  setDefaultPaymentMethod: (paymentMethodId: string) => void;
}

const StyledDelete = styled(Body2)`
  color: ${red["500"]};
  margin-left: ${rhOpsSpacingPx(2)};
`;

const Body1Expiry = styled(Body1)`
  color: ${grey["600"]};
  margin-left: ${rhOpsSpacingPx(1)};
`;

const EditFlexBox = styled(BoFlexBox)`
  justify-content: space-between;
  margin-top: ${rhOpsSpacingPx(1)};
`;

const DeleteFlexBox = styled(BoFlexBox)`
  justify-content: flex-end;
  margin-top: ${rhOpsSpacingPx(1)};
`;

export const PaymentMethodRow = ({
  paymentMethod,
  setDefaultPaymentMethod,
  allowDelete,
}: PaymentMethodRowProps) => {
  const context = usePaymentMethodContext();
  const defaultPaymentText = paymentMethod.defaultPaymentMethod
    ? ""
    : "Set as Default";

  return (
    <BoCard key={paymentMethod.id}>
      <BoFlexBox $justifyContent="space-between">
        <div>
          <Body1 $fontWeight="Bold">{paymentMethod.companyName}</Body1>
          <Body1Expiry>Exp. {paymentMethod.expiration}</Body1Expiry>
        </div>
        <Body1>{paymentMethod.trimmedMaskedNumber}</Body1>
      </BoFlexBox>
      <EditFlexBox>
        <div>
          {paymentMethod.defaultPaymentMethod ? "Default Payment Method" : ""}
        </div>
        <DeleteFlexBox>
          <BoTextButton
            onClick={() => setDefaultPaymentMethod(paymentMethod.id)}
          >
            <Body2>{defaultPaymentText}</Body2>
          </BoTextButton>
          {allowDelete && (
            <BoTextButton onClick={() => context.handleDelete(paymentMethod)}>
              <StyledDelete>Delete</StyledDelete>
            </BoTextButton>
          )}
        </DeleteFlexBox>
      </EditFlexBox>
    </BoCard>
  );
};
