import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { MARGIN } from "@ops-design-system/styles/layout.constants";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled, { css } from "styled-components";

interface GridStyleProps {
  $variant?: GridVariants;
}

export const ButtonsGrid = styled(BoFlexBox)`
  display: grid;
  grid-gap: ${rhOpsSpacingPx(2)};
  grid-template-columns: 2fr 2fr;
  width: 100%;
`;

export const BoGridColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.large.v}px;
`;

const gridVariant =
  (defaultVariant?: GridVariants) =>
  ({ $variant }: GridStyleProps) => {
    switch ($variant) {
      case GridVariants.Grid1: {
        return css`
          grid-template-columns: 1fr;
        `;
      }

      case GridVariants.Grid2_1: {
        return css`
          grid-template-columns: 2fr 1fr;
        `;
      }
      case GridVariants.Grid1_1: {
        return css`
          grid-template-columns: 1fr 1fr;
        `;
      }

      case GridVariants.Grid3_1: {
        return css`
          grid-template-columns: repeat(3, 1fr);
        `;
      }

      case GridVariants.Grid4_1: {
        return css`
          grid-template-columns: repeat(4, 1fr);
        `;
      }
      default: {
        return css`
          grid-template-columns: 1fr 1fr;
        `;
      }
    }
  };

export const BoGrid = styled.div<GridStyleProps>`
  box-sizing: border-box;
  display: grid;
  gap: ${rhOpsSpacingPx(2)} ${rhOpsSpacingPx(2.5)};
  position: relative;
  width: 100%;
  ${gridVariant()}
`;
