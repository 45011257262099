import { ReactComponent as ColdWeatherIcon } from "@design-system/icons/bill-factors/ColdWeather.svg";
import { ReactComponent as HotWeatherIcon } from "@design-system/icons/bill-factors/HotWeather.svg";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { StyledBillFactor } from "@ops/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorsWeatherProps {
  numberOfColdDays: number;
  numberOfHotDays: number;
}

export const BillFactorsWeather = ({
  numberOfColdDays,
  numberOfHotDays,
}: BillFactorsWeatherProps) => {
  const isColdDays = numberOfColdDays > numberOfHotDays;

  return (
    <StyledBillFactor>
      {isColdDays ? <ColdWeatherIcon /> : <HotWeatherIcon />}
      <Body1 $fontWeight="Bold">Weather</Body1>
      <Body2>
        {isColdDays
          ? "Colder weather days impacted your bill this month."
          : "Hotter weather days impacted your bill this month."}
      </Body2>
    </StyledBillFactor>
  );
};
