import { OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";

type FlashMessage = string | JSX.Element | undefined | null;

type ShowFlash = (
  message: FlashMessage,
  options?: OptionsObject
) => SnackbarKey | undefined;

export interface RhFlashProviderContext {
  close(key?: SnackbarKey): void;
  error: ShowFlash;
  success: ShowFlash;
  warning: ShowFlash;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmptyObject(arg: any) {
  return Object.keys(arg).length === 0 && arg.constructor === Object;
}

export function useRhFlash(): RhFlashProviderContext {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showFlash = useCallback(
    (message: FlashMessage, options?: OptionsObject) => {
      const finalMessage = message;

      if (!finalMessage || isEmptyObject(finalMessage as JSX.Element)) {
        return;
      }

      return enqueueSnackbar(finalMessage, {
        ...options,
      });
    },
    [enqueueSnackbar]
  );

  const showError = useCallback(
    (message: FlashMessage, options?: OptionsObject) => {
      return showFlash(message, {
        variant: "error",
        ...options,
      });
    },
    [showFlash]
  );

  const showSuccess = useCallback(
    (message: FlashMessage, options?: OptionsObject) => {
      return showFlash(message, {
        variant: "success",
        ...options,
      });
    },
    [showFlash]
  );

  const showWarning = useCallback(
    (message: FlashMessage, options?: OptionsObject) => {
      return showFlash(message, {
        variant: "warning",
        ...options,
      });
    },
    [showFlash]
  );

  return useMemo(() => {
    return {
      close: closeSnackbar,
      error: showError,
      success: showSuccess,
      warning: showWarning,
    };
  }, [closeSnackbar, showError, showSuccess, showWarning]);
}
