import {
  WinbackWizardContext,
  WinbackWizardContextType,
} from "@ops/components/WinbackWizard/WinbackWizardProvider";
import { useContext } from "react";

export function useWinbackWizardContext(): WinbackWizardContextType {
  const context = useContext<WinbackWizardContextType | null>(
    WinbackWizardContext
  );

  if (context === null) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }
  return context;
}
