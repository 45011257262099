import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface PaymentFailedData extends EventLogBaseData {
  accountNumber: string;
  invoiceDueDate: string;
  invoiceId: string;
  invoiceNumber: string;
  paymentStatus: string;
}

export class PaymentFailed extends EventLog {
  public readonly invoiceNumber: string;
  public readonly invoiceDueDate: string;
  public readonly invoiceId: string;
  public readonly accountNumber: string;
  public readonly paymentStatus: string;

  constructor({
    invoiceNumber,
    invoiceDueDate,
    invoiceId,
    accountNumber,
    paymentStatus,
    ...baseEventData
  }: PaymentFailedData) {
    super(baseEventData);

    this.invoiceNumber = invoiceNumber;
    this.invoiceDueDate = invoiceDueDate;
    this.invoiceId = invoiceId;
    this.accountNumber = accountNumber;
    this.paymentStatus = paymentStatus;
  }

  override get body(): string | null {
    return `${this.name} - Invoice Number: ${this.invoiceNumber}, `;
  }
}
