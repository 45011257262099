import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ThermostatDisconnectedData extends EventLogBaseData {
  planOverridesDeviceCredit: string;
  rhythmDeviceId: string;
}

export class ThermostatDisconnected extends EventLog {
  public readonly planOverridesDeviceCredit: string;
  public readonly rhythmDeviceId: string;

  constructor({
    planOverridesDeviceCredit,
    rhythmDeviceId,
    ...baseEventData
  }: ThermostatDisconnectedData) {
    super(baseEventData);
    this.planOverridesDeviceCredit = planOverridesDeviceCredit;
    this.rhythmDeviceId = rhythmDeviceId;
  }

  override get body() {
    return `${this.name}
    Rhythm Device ID: ${this.rhythmDeviceId}
    Plan Credit Eligible: ${this.planOverridesDeviceCredit}`;
  }
}
