import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { rhapsodyPremiseApi } from "@ops-data/api/rhapsodyPremiseApi";
import {
  premiseStatusIndicatorsErrored,
  premiseStatusIndicatorsFetch,
  premiseStatusIndicatorsReceived,
  premiseStatusIndicatorsRequested,
} from "@ops/slices/premiseStatusIndicatorsSlice";
import { SagaReturnType, all, call, put, takeLatest } from "redux-saga/effects";

export function* fetchPremiseStatusIndicatorsWorker(action: {
  payload: IdType;
  type: string;
}) {
  const premiseId = action.payload;

  yield put(premiseStatusIndicatorsRequested());
  try {
    const statusIndicators: SagaReturnType<
      typeof rhapsodyPremiseApi.statusIndicators.premiseAssignedStatusIndicators.list
    > = yield call(
      rhapsodyPremiseApi.statusIndicators.premiseAssignedStatusIndicators.list,
      premiseId
    );

    yield put(premiseStatusIndicatorsReceived(statusIndicators));
  } catch (err: unknown) {
    yield put(premiseStatusIndicatorsErrored(err as RhApiError));
  }
}

export function* premiseStatusIndicatorsWatcher() {
  yield all([
    takeLatest(
      premiseStatusIndicatorsFetch.type,
      fetchPremiseStatusIndicatorsWorker
    ),
  ]);
}
