import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface StatusIndicatorChangedData extends EventLogBaseData {
  indicatorsAdded: string[];
  indicatorsRemoved: string[];
}

export class StatusIndicatorChanged extends EventLog {
  public readonly indicatorsAdded: string[];
  public readonly indicatorsRemoved: string[];

  constructor({
    indicatorsAdded,
    indicatorsRemoved,
    ...baseEventData
  }: StatusIndicatorChangedData) {
    super(baseEventData);

    this.indicatorsAdded = indicatorsAdded || [];
    this.indicatorsRemoved = indicatorsRemoved || [];
  }

  quotedJoin(input: string[], postFix: string) {
    return input.length > 0
      ? input.map((text) => `'${text}'`).join(", ") + postFix
      : "";
  }

  override get body() {
    const added = this.quotedJoin(this.indicatorsAdded, " added. ");
    const removed = this.quotedJoin(this.indicatorsRemoved, " removed. ");

    return `${this.name} - ${added} ${removed}`;
  }
}
