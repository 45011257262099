import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { zodResolver } from "@hookform/resolvers/zod";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body1, H3 } from "@ops-design-system/components/Typography/Typography";
import { contractRenewalWizardStateAtom } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.atoms";
import {
  contractRenewalWizardSchema,
  contractRenewalWizardSubmissionSchema,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.schemas";
import { ContractRenewalWizardButtonContainer } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import { ContractRenewalWizardConfirmationDetails } from "@ops/components/ContractRenewalWizard/ContractRenewalWizardConfirmationStep/ContractRenewalWizardConfirmationDetails";
import {
  ConfirmationCheckbox,
  ConfirmationCheckboxLabel,
  ConfirmationHeader,
  ConfirmationStepContainer,
  ConfirmationStepForm,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizardConfirmationStep/ContractRenewalWizardConfirmationStep.styled";
import { useContractRenewalWizard } from "@ops/components/ContractRenewalWizard/useContractRenewalWizard";
import { usePremiseRenewContractMutation } from "@ops/hooks/mutations/usePremiseRenewContract.mutation";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { usePremise } from "@ops/hooks/usePremise";
import { useAtomValue } from "jotai";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

export const ContractRenewalWizardConfirmationStep = () => {
  const flash = useRhFlash();
  const { handlePreviousStep, isPreviousStepAvailable, handleNextStep } =
    useContractRenewalWizard();

  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);
  const { offerSnapshotId, paperless, confirmRenewal } = useAtomValue(
    contractRenewalWizardStateAtom
  );

  const renewContractMutation = usePremiseRenewContractMutation();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<z.infer<typeof contractRenewalWizardSchema>>({
    defaultValues: {
      confirmRenewal,
      offerSnapshotId,
      paperless,
    },
    reValidateMode: "onChange",
    resolver: zodResolver(contractRenewalWizardSubmissionSchema),
  });

  const renewalOfferSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId,
  });

  const currentOrderOfferSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: premise?.currentOrder?.offersnapshotId ?? "",
    queryOptions: {
      enabled: Boolean(premise?.currentOrder?.offersnapshotId),
    },
  });

  // This shouldn't happen but makes typescript happy
  if (!premise || !premise.serviceAddress || !premise.currentOrder) {
    return null;
  }

  if (
    currentOrderOfferSnapshotQuery.isPending ||
    renewalOfferSnapshotQuery.isPending
  ) {
    return (
      <ConfirmationStepContainer>
        <H3>Confirm Renewal</H3>

        <BoCircularProgress />
      </ConfirmationStepContainer>
    );
  }

  if (
    currentOrderOfferSnapshotQuery.isError ||
    renewalOfferSnapshotQuery.isError
  ) {
    return (
      <ConfirmationStepContainer>
        <H3>Confirm Renewal</H3>

        <Body1>Error fetching offer details, please try again</Body1>
      </ConfirmationStepContainer>
    );
  }

  const renewalOfferSnapshot = renewalOfferSnapshotQuery.data;
  const currentOrderOfferSnapshot = currentOrderOfferSnapshotQuery.data;

  const onSubmit = handleSubmit((values) => {
    try {
      const validatedData = contractRenewalWizardSubmissionSchema.parse(values);

      renewContractMutation.mutate(
        {
          offerSnapshotId: validatedData.offerSnapshotId,
          paperless: validatedData.paperless,
          premiseId: premise.id,
        },
        {
          onError: (error) => {
            flash.error(
              `Error Renewing Contract: ${error.data.errors[0].detail}`
            );
          },
          onSuccess: () => {
            handleNextStep();
          },
        }
      );
    } catch (err) {
      if (err instanceof z.ZodError) {
        flash.error(err.issues.map((issue) => issue.message).join(", "));
      }
    }
  });

  return (
    <ConfirmationStepContainer>
      <ConfirmationHeader>Confirm Renewal</ConfirmationHeader>

      <ContractRenewalWizardConfirmationDetails
        premise={premise}
        renewalOfferSnapshot={renewalOfferSnapshot}
        currentOrderOfferSnapshot={currentOrderOfferSnapshot}
        paperlessEnabled={paperless}
      />

      <ConfirmationStepForm onSubmit={onSubmit}>
        <ConfirmationCheckboxLabel>
          <ConfirmationCheckbox {...register("confirmRenewal")} />
          Was the renewal script read and did the customer agree to the terms
          and conditions?
        </ConfirmationCheckboxLabel>

        <ContractRenewalWizardButtonContainer>
          <BoButton
            variant="secondary"
            type="button"
            onClick={handlePreviousStep}
            disabled={!isPreviousStepAvailable}
          >
            Previous Page
          </BoButton>
          <BoButton
            variant="primary"
            type="submit"
            disabled={!isValid || renewContractMutation.isPending}
          >
            Submit
          </BoButton>
        </ContractRenewalWizardButtonContainer>
      </ConfirmationStepForm>
    </ConfirmationStepContainer>
  );
};
