import { MONTH_DAY_YEAR_SLASH } from "@common/constants/date.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { convertSnakeCaseToTitleCase } from "@common/utils/stringFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Container } from "@mui/material";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { Section } from "@ops/components/AdminDashboard/shared/AdminDashboard.styled";
import { Header } from "@ops/components/BoCampaignSlugSelect/layoutComponents";
import { DisplayAddress } from "@ops/components/DisplayAddress/DisplayAddress";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import {
  AutoPayWarningText,
  PaperlessWarningText,
  WinBackConfirmationCenteredRow,
  WinBackConfirmationCheckboxContainer,
  WinBackConfirmationInfoDetailsContainer,
  WinBackConfirmationLabel,
  WinBackConfirmationText,
} from "@ops/components/WinbackWizard/WinbackConfirmation/WinbackConfirmation.styled";
import { WinbackSubmission } from "@ops/components/WinbackWizard/WinbackConfirmation/WinbackSubmission.service";
import {
  ContractOptions,
  WizardComponentName,
} from "@ops/components/WinbackWizard/winbackWizard.enums";
import { termsAgreement } from "@ops/constants/contractTerms.constants";
import { formatEnergyRate } from "@ops/formatters";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { useWinbackWizardContext } from "@ops/hooks/useWinbackWizardContext";
import { customerRefetch } from "@ops/slices/customerSlice";
import dayjs from "dayjs";
import React, {
  FormEvent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const errorMessage = (errorCode: string | null) => {
  switch (errorCode) {
    case "rhythm.premise.fields.invalid.expected_end_date_null": {
      return "Service cannot be winback since the previous order's expected end date is not set.";
    }
    case "rhythm.premise.order.not_found": {
      return "Service cannot be winback since the originating premise has no billing order.";
    }
    default: {
      return "An error occurred while attempting to winback the customer. Please try again later.";
    }
  }
};

interface InfoDetailsProps {
  inline?: boolean;
  label: string;
}

interface CheckboxLabelProps {
  $htmlFor: string;
}
const CheckboxLabel = styled(Body3).attrs<CheckboxLabelProps>(
  ({ $htmlFor }) => ({
    as: "label",
    htmlFor: $htmlFor,
  })
)`
  align-self: center;
`;

const InfoDetails = ({
  label,
  inline,
  children,
}: PropsWithChildren<InfoDetailsProps>) => {
  const as = inline ? "span" : "div";

  return (
    <WinBackConfirmationInfoDetailsContainer aria-label={label} role="region">
      <WinBackConfirmationLabel>{label}</WinBackConfirmationLabel>
      <WinBackConfirmationText as={as}>
        {inline && " "}
        {children}
      </WinBackConfirmationText>
    </WinBackConfirmationInfoDetailsContainer>
  );
};

interface OfferSnapshotDetailsProps {
  offerSnapshotId: IdType;
}

const OfferSnapshotDetails = (props: OfferSnapshotDetailsProps) => {
  const { offerSnapshotId } = props;

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId,
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <div>
        <BoCircularProgress />
      </div>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <div>
        <Body2>Failed to fetch offer details</Body2>
      </div>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const termMonthsAndRate = `${offerSnapshot.termMonths} months, ${
    offerSnapshot.isMonthToMonth ? "Variable" : "Fixed"
  } Rate`;

  return (
    <>
      <div>Plan: {offerSnapshot.title}</div>
      <div>
        Energy Rate:{" "}
        {offerSnapshot.isTimeOfUse ? (
          <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshot.id} />
        ) : (
          formatEnergyRate(offerSnapshot.rhythmKwhRate)
        )}
      </div>
      {offerSnapshot.solarEligible && !offerSnapshot.isTimeOfUseGeneration ? (
        <div>Buyback Rate: {offerSnapshot.solarCreditKwhRate}</div>
      ) : null}
      <div>
        Avg Price (2000kWh): {formatEnergyRate(offerSnapshot.price2000Kwh)}
      </div>
      <div>{termMonthsAndRate}</div>
    </>
  );
};

export const WinbackConfirmation = () => {
  const {
    setNextButtonProps,
    customer,
    premise,
    winbackWizardState,
    closeModal,
    restoreStateToInitialValues,
  } = useWinbackWizardContext();
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const {
    contractOption,
    paperless,
    startDate,
    enrollmentType,
    offerSnapshotId,
    priorityEnrollment,
  } = winbackWizardState;
  const { currentOrder: order } = premise;
  const isNewContract = ContractOptions.startNewContract === contractOption;
  const [confirmAgreement, setConfirmAgreement] = useState<boolean>(false);

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId,
  });

  useEffect(() => {
    setNextButtonProps({
      disabled: !confirmAgreement,
      loading: requestMonitor.isPending,
    });
  }, [confirmAgreement, requestMonitor.isPending]);

  if (offerSnapshotQuery.isPending) {
    return (
      <Container>
        <BoCircularProgress />
      </Container>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <Container>
        <Body2>Failed to fetch offer details</Body2>
      </Container>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const contractEndDate = (() => {
    if (isNewContract) {
      const termMonths = offerSnapshot.termMonths ?? 0;

      return dayjs(startDate)
        .add(termMonths, "month")
        .format(MONTH_DAY_YEAR_SLASH);
    }
    return formatMonthDayYear(order?.contractEndDate ?? "");
  })();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submission = new WinbackSubmission(winbackWizardState, premise);

    if (submission.isValid) {
      setPending();

      submission
        .submit()
        .then(() => {
          setSuccess();
          flash.success("Successfully completed winback");
          dispatch(customerRefetch(customer.id));
          closeModal();
          restoreStateToInitialValues();
        })
        .catch((error: RhApiError) => {
          setFailure();
          flash.error(errorMessage(error.data.errorCode));
        });
      return;
    }

    flash.error(
      `You are missing required data: ${submission.errors.join(",")}`
    );
    setConfirmAgreement(false);
  };

  const newContractOfferSnapshotId = isNewContract
    ? offerSnapshotId
    : premise.currentOrder?.offersnapshotId;

  const showPaperlessWarning =
    offerSnapshot.isAutoPayPaperlessDiscountOffer && !paperless;

  const showAutoPayWarning = offerSnapshot.isAutoPayPaperlessDiscountOffer;

  return (
    <form
      noValidate
      onSubmit={onSubmit}
      id={WizardComponentName.winbackConfirmation}
    >
      <Container>
        <Section>
          <WinBackConfirmationCenteredRow>
            <Header>
              {isNewContract
                ? "Review New Plan Details"
                : "Review Current Plan Details"}
            </Header>
          </WinBackConfirmationCenteredRow>
          <BoGrid $variant={GridVariants.Grid4_1}>
            <BoGridColumn>
              <InfoDetails label="Customer Name">
                {customer.fullName}
              </InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails label={`${isNewContract ? "New" : ""} Plan`}>
                <OfferSnapshotDetails
                  offerSnapshotId={newContractOfferSnapshotId ?? ""}
                />
              </InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails inline label="Paperless:">
                {paperless ? "On" : "Off"}
              </InfoDetails>
              {showPaperlessWarning ? (
                <PaperlessWarningText>
                  Auto Pay and Paperless must be turned on for the Auto Pay and
                  Paperless credit.
                </PaperlessWarningText>
              ) : null}
              <InfoDetails inline label="Auto Pay:">
                Off
              </InfoDetails>
              {showAutoPayWarning ? (
                <AutoPayWarningText>
                  Auto Pay and Paperless must be turned on for the Auto Pay and
                  Paperless credit.
                </AutoPayWarningText>
              ) : null}
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails inline label="Service Start Date:">
                {formatMonthDayYear(startDate ?? "")}
              </InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails label="Service Address">
                <DisplayAddress address={premise.serviceAddress ?? undefined} />
              </InfoDetails>
            </BoGridColumn>
            <BoGridColumn>
              <InfoDetails inline label="Contract Start Date:">
                {formatMonthDayYear(startDate ?? "")}
              </InfoDetails>
              <InfoDetails inline label="Contract End Date:">
                {contractEndDate}
              </InfoDetails>
            </BoGridColumn>
            <BoGridColumn />
            <BoGridColumn>
              <InfoDetails inline label="Type:">
                {`${convertSnakeCaseToTitleCase(enrollmentType ?? "")}${
                  priorityEnrollment ? ", priority" : ""
                }`}
              </InfoDetails>
            </BoGridColumn>
          </BoGrid>
        </Section>
        <Section>
          <FormFlexRow>
            <WinBackConfirmationCheckboxContainer>
              <BoCheckboxInput
                name="confirmAgreement"
                id="confirmAgreement"
                onChange={() => setConfirmAgreement(!confirmAgreement)}
                checked={confirmAgreement}
              />
            </WinBackConfirmationCheckboxContainer>
            <CheckboxLabel $htmlFor="confirmAgreement">
              {termsAgreement}
            </CheckboxLabel>
          </FormFlexRow>
        </Section>
      </Container>
    </form>
  );
};
