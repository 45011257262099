import {
  contractRenewalWizardCurrentStepAtom,
  contractRenewalWizardModalAtom,
  contractRenewalWizardStateAtom,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.atoms";
import { ContractRenewalWizardFormStateType } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.schemas";
import { useAtom, useSetAtom } from "jotai";
import { RESET } from "jotai/utils";

export const useContractRenewalWizard = () => {
  const [currentStepAtom, setCurrentStepAtom] = useAtom(
    contractRenewalWizardCurrentStepAtom
  );
  const [formStateAtom, setFormStateAtom] = useAtom(
    contractRenewalWizardStateAtom
  );

  const setContractRenewalWizardModalAtom = useSetAtom(
    contractRenewalWizardModalAtom
  );

  const isNextStepAvailable = currentStepAtom !== "confirmationStep";
  const isPreviousStepAvailable = currentStepAtom !== "offersStep";

  const reset = () => {
    setFormStateAtom(RESET);
    setCurrentStepAtom(RESET);
    setContractRenewalWizardModalAtom(RESET);
  };

  const handleNextStep = (
    newFormState?: Partial<ContractRenewalWizardFormStateType>
  ) => {
    const newState = newFormState;

    switch (currentStepAtom) {
      case "offersStep": {
        setCurrentStepAtom("billingPreferencesStep");
        break;
      }
      case "billingPreferencesStep": {
        setCurrentStepAtom("confirmationStep");
        break;
      }
      case "confirmationStep": {
        setCurrentStepAtom("successStep");
        break;
      }
    }

    setFormStateAtom({
      ...formStateAtom,
      ...newState,
    });
  };

  const handlePreviousStep = () => {
    switch (currentStepAtom) {
      case "offersStep": {
        break;
      }
      case "billingPreferencesStep": {
        setCurrentStepAtom("offersStep");
        break;
      }
      case "confirmationStep": {
        setCurrentStepAtom("billingPreferencesStep");
        break;
      }
    }
  };

  return {
    handleNextStep,
    handlePreviousStep,
    isNextStepAvailable,
    isPreviousStepAvailable,
    reset,
  };
};
