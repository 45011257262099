import {
  isLocal,
  isStaging,
} from "@common/services/getEnvApplicationSettings.service";
import { captureException as sentryCaptureException } from "@sentry/react";
import { z } from "zod";

export function zodIsValid<T>(schema: z.ZodType<T>, data: unknown): boolean {
  // this function is purely a validity check
  // it returns true, or throws an error (or returns false) if invalid
  const result = schema.safeParse(data);

  if (!result.success) {
    if (isLocal()) {
      throw result.error;
    }
    sentryCaptureException(result.error);
    if (isStaging()) {
      // eslint-disable-next-line no-console
      console.warn(result.error);
    }
  }
  return result.success;
}
