import { BoTextInput } from "@ops-design-system/components/BoTextInput/BoTextInput";
import { FieldValidator } from "final-form";
import isArray from "lodash/isArray";
import React, { FC } from "react";
import { useField } from "react-final-form";

export interface BoTextFieldProps<Type> {
  afterText?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: (value: any, name: string) => any;
  hideErrorMessage?: boolean;
  icon?: FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
  inputMode?: "text" | "numeric" | "email";
  maxLength?: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: (value: any, name: string) => any;
  placeholder?: string;
  readOnly?: boolean;
  requiredIndicator?: boolean;
  type?: "text" | "email" | "date" | "tel" | "number" | "password";
  validate?: FieldValidator<Type>;
  width?: string;
}

export const BoTextField: FC<
  React.PropsWithChildren<BoTextFieldProps<string>>
> = ({
  autoComplete = "off",
  autoFocus,
  children,
  disabled,
  format,
  parse,
  name,
  placeholder,
  type,
  validate,
  readOnly,
  requiredIndicator,
  hideErrorMessage,
  inputMode = "text",
  icon,
  maxLength,
  width,
  afterText,
  className,
}) => {
  const { input, meta } = useField<string>(name, {
    format,
    parse,
    type,
    validate,
  });
  const hasErrors =
    (meta.touched && Boolean(meta.error)) ||
    (meta.submitFailed && Boolean(meta.submitError));
  let errorMessage;

  if (hasErrors && !meta.dirtySinceLastSubmit && !hideErrorMessage) {
    if (isArray(meta.error)) {
      errorMessage = meta.error.join(" ");
    } else {
      errorMessage = meta.error || meta.submitError;
    }
  }

  return (
    <BoTextInput
      {...input}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled || meta.submitting}
      hasErrors={hasErrors}
      errorMessage={errorMessage}
      icon={icon}
      inputMode={inputMode}
      placeholder={placeholder}
      readOnly={readOnly}
      requiredIndicator={requiredIndicator}
      maxLength={maxLength}
      width={width}
      afterText={afterText}
      className={className}
    >
      {children}
    </BoTextInput>
  );
};
