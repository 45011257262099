import { AddressFormType } from "@common/types/customerTypes";

export interface CreditScoreContactDataType {
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface CreditScoreDataType
  extends CreditScoreContactDataType,
    AddressFormType {}

export enum CreditScoreOutcome {
  approved = "Approved",
  manualReview = "Manual Review",
  unavailable = "Unavailable",
  // "Denied" is deactivated at the API level — we cannot legally deny service
}

export interface CreditScoreEvaluationType {
  additionalInformationRequired: boolean;
  depositAlternativeAmount: string | null;
  depositAmount: number | null;
  nosRequired: boolean;
  outcome: CreditScoreOutcome;
  result: "success" | "partial" | "fail";
  ssnProvided: boolean;
  ssnRequired: boolean;
}

export enum CreditCheckResultType {
  idRequired = "idRequired",
  insufficientData = "insufficientData",
  noCreditCheck = "noCreditCheck",
  nosRequired = "nosRequired",
  requiresDeposit = "requiresDeposit",
  ssnRequired = "ssnRequired",
  success = "success",
}
