import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import {
  ContactLogType,
  CreateContactLogType,
} from "@ops-utils/types/contactLogTypes";

const BASE_PATH = "/api/prospects";

export const legacyProspectApi = {
  contactLogs: {
    create: (id: IdType, contactLog: CreateContactLogType): Promise<void> => {
      return ajax.post(`${BASE_PATH}/${id}/contact-logs/`, contactLog);
    },
    list: (id: IdType): Promise<ContactLogType[]> => {
      return ajax.get(`${BASE_PATH}/${id}/contact-logs/`);
    },
  },
};
