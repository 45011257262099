import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { CancellationActionsCard } from "@ops/components/CancellationActionsCard/CancellationActionsCard";
import { ConnectionActionsCard } from "@ops/components/ConnectionActionsCard/ConnectionActionsCard";
import { StyledOperationsTab } from "@ops/components/CustomerDashboard/OperationsTab/OperationsTab.styled";
import { EdiMessagesCard } from "@ops/components/EdiMessagesCard/EdiMessagesCard";
import { EnrollmentActionsCard } from "@ops/components/EnrollmentActionsCard/EnrollmentActionsCard";
import { ServiceChangesCard } from "@ops/components/ServiceChangesCard/ServiceChangesCard";
import { SwitchHoldCard } from "@ops/components/SwitchHoldCard/SwitchHoldCard";
import { useCustomer } from "@ops/hooks/useCustomer";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";

export const OperationsTab = () => {
  const { id, premiseId } = useParams<{ id: IdType; premiseId: IdType }>();
  const { premise } = usePremise(premiseId);
  const { customer } = useCustomer(id);

  if (!customer || !premise) {
    return <BoCircularProgress />;
  }

  return (
    <StyledOperationsTab>
      <BoGrid $variant={GridVariants.Grid1_1}>
        <BoGridColumn>
          <EnrollmentActionsCard customer={customer} premise={premise} />
          <CancellationActionsCard customer={customer} premise={premise} />
          <SwitchHoldCard customerId={customer.id} />
        </BoGridColumn>
        <BoGridColumn>
          <ServiceChangesCard customer={customer} premise={premise} />
          <ConnectionActionsCard customerId={customer.id} />
        </BoGridColumn>
      </BoGrid>
      <EdiMessagesCard premiseId={premise.id} />
    </StyledOperationsTab>
  );
};
