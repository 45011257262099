import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const AutoPayDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: space-between;
  margin-bottom: ${rhOpsSpacingPx(2)};
  padding-bottom: ${rhOpsSpacingPx(2)};
`;

export const SelectContainer = styled.div`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const AutoPayWarningText = styled(Body2)`
  color: ${red.main};
`;
