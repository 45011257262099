import { IdType } from "@common/types/apiTypes";
import { CustomerDocumentsSearchOptions } from "@ops-utils/types/customerDocumentTypes";
import { OfferSnapshotSearchOptions } from "@ops-utils/types/offerSnapshotTypes";
import { TargetType } from "@ops/components/SupportLogs/SupportLogs.types";

export const ProspectQueryKeys = {
  PROSPECT_PREMISE_MATCHES: ["prospect", "premiseMatches", "list"] as const,
};

export const ContactLogKeys = {
  ENTRIES: (targetType: TargetType, targetId: IdType) =>
    ["contactLog", "entries", targetType, targetId] as const,
};

export const ContractQueryKeys = {
  CONTRACT_EXPIRATION_NOTICES: [
    "contract",
    "expirationNotices",
    "list",
  ] as const,
};

export const ProductKeys = {
  AVAILABLE_FOR_PREMISE: (premiseId: IdType) =>
    ["premise", premiseId, "products", "available"] as const,
  PRODUCTS: ["products"] as const,
};

export const ProductContractKeys = {
  ACTIVE_CONTRACTS_BY_PREMISE_LIST: (premiseId: IdType) =>
    [...ProductContractKeys.PREMISE_LIST(premiseId), "active"] as const,
  EXPIRED_CONTRACTS_BY_PREMISE_LIST: (premiseId: IdType) =>
    [...ProductContractKeys.PREMISE_LIST(premiseId), "expired"] as const,
  PREMISE_LIST: (premiseId: IdType) =>
    ["premise", premiseId, "productContracts", "list"] as const,
  UPCOMING_CONTRACTS_BY_PREMISE_LIST: (premiseId: IdType) =>
    [...ProductContractKeys.PREMISE_LIST(premiseId), "upcoming"] as const,
};

export const ProductContractClaimKeys = {
  list: (premiseId: IdType) =>
    ["premise", premiseId, "productContractClaims"] as const,
};

export const PremiseKeys = {
  ACCOUNT_SUMMARY: (premiseId: IdType) =>
    [...PremiseKeys.PREMISE(premiseId), "accountSummary"] as const,
  DEPOSIT_ALTERNATIVE: (premiseId: IdType) =>
    [...PremiseKeys.PREMISE(premiseId), "depositAlternative"] as const,
  ORDERS: (premiseId: IdType) =>
    [...PremiseKeys.PREMISE(premiseId), "orders"] as const,
  PREMISE: (premiseId: IdType) => ["premise", premiseId] as const,
  attachedDevices: (premiseId: IdType) => [
    ...PremiseKeys.PREMISE(premiseId),
    "attachedDevices",
  ],
  billForecastSummary: (premiseId: IdType) => [
    ...PremiseKeys.PREMISE(premiseId),
    "billForecastSummary",
  ],
  ediMessageHistory: (premiseId: IdType) =>
    ["premise", premiseId, "edi", "history"] as const,
  kwhUsageDay: (premiseId: IdType, date: string) => [
    ...PremiseKeys.PREMISE(premiseId),
    "kwhUsageDay",
    date,
  ],
  kwhUsageSummary: (premiseId: IdType) => [
    ...PremiseKeys.PREMISE(premiseId),
    "kwhUsageSummary",
  ],
  oneClickRenewalOfferSnapshots: (premiseId: IdType) =>
    [
      ...PremiseKeys.PREMISE(premiseId),
      "oneClickRenewalOfferSnapshots",
    ] as const,
  temperatureSummary: (premiseId: IdType) => [
    ...PremiseKeys.PREMISE(premiseId),
    "temperatureSummary",
  ],
  thermostatConnectionActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: {
      limit: number;
      offset: number;
      thermostatId: IdType;
    };
  }) =>
    [
      ...PremiseKeys.PREMISE(premiseId),
      "thermostatConnectionActivity",
      searchOptions,
    ] as const,
  thermostatDemandResponseActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: {
      limit: number;
      offset: number;
      thermostatId: IdType;
    };
  }) =>
    [
      ...PremiseKeys.PREMISE(premiseId),
      "thermostatDemandResponseActivity",
      searchOptions,
    ] as const,
};

export const CampaignQueryKeys = {
  CAMPAIGN_SLUGS: ["campaigns", "campaign slugs"] as const,
};

export const AffiliateQueryKeys = {
  BASE: ["affiliate"] as const,
  redeemHistory: (affiliateId: IdType) =>
    [...AffiliateQueryKeys.BASE, affiliateId, "redeemHistory"] as const,
  referrals: (affiliateId: IdType) =>
    [...AffiliateQueryKeys.BASE, affiliateId, "referrals"] as const,
  retrieve: (affiliateId: IdType) =>
    [...AffiliateQueryKeys.BASE, affiliateId] as const,
  rewards: (affiliateId: IdType) =>
    [...AffiliateQueryKeys.BASE, affiliateId, "rewards"] as const,
};

export const EdiQueryKeys = {
  BASE: ["edi"] as const,
  serviceStartDates: (offerSnapshotId?: IdType, useMinimumDate?: boolean) =>
    [
      ...EdiQueryKeys.BASE,
      "serviceStartDates",
      { offerSnapshotId, useMinimumDate },
    ] as const,
};

export const OfferSnapshotQueryKeys = {
  BASE: ["offerSnapshots"] as const,
  list: (options: OfferSnapshotSearchOptions) => [
    ...OfferSnapshotQueryKeys.BASE,
    options,
  ],
  retrieve: (offerSnapshotId: string) => [
    ...OfferSnapshotQueryKeys.BASE,
    offerSnapshotId,
  ],
};

export const InvoiceQueryKeys = {
  BASE: ["invoices"] as const,
  compare: (invoiceId: string) => [
    ...InvoiceQueryKeys.BASE,
    "compare",
    invoiceId,
  ],
  factors: (invoiceId: string) => [
    ...InvoiceQueryKeys.BASE,
    "factors",
    invoiceId,
  ],
  retrieve: (invoiceId: string) => [...InvoiceQueryKeys.BASE, invoiceId],
  temperature: (invoiceId: string) => [
    ...InvoiceQueryKeys.BASE,
    "temperature",
    invoiceId,
  ],
  usage: (invoiceId: string) => [...InvoiceQueryKeys.BASE, "usage", invoiceId],
};

export const ContactLogOptionQueryKeys = {
  LIST: ["contactLog", "categories"] as const,
};

export const CustomerDocumentsQueryKeys = {
  BASE: ["customer-documents"] as const,
  retrieve: (searchOptions: CustomerDocumentsSearchOptions) => [
    ...CustomerDocumentsQueryKeys.BASE,
    searchOptions,
  ],
  types: () => [...CustomerDocumentsQueryKeys.BASE, "types"],
};
