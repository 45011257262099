import { StyledToast } from "@design-system/components/RhFlashProvider/RhFlashProvider.styled";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import React from "react";

interface RhFlashProviderProps extends SnackbarProviderProps {}

export const RhFlashProvider = (props: RhFlashProviderProps): JSX.Element => {
  return (
    <SnackbarProvider
      hideIconVariant
      maxSnack={3}
      autoHideDuration={5000}
      dense={false}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      Components={{
        error: StyledToast,
        success: StyledToast,
      }}
      {...props}
    />
  );
};
