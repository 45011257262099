import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { PriceScheduleBreakdownDateRangeList } from "@ops/components/PriceScheduleBreakdown/PriceScheduleBreakdownDateRangeList/PriceScheduleBreakdownDateRangeList";
import { priceScheduleBreakdownDefaultLabels } from "@ops/components/PriceScheduleBreakdown/priceScheduleBreakdownDefaultLabels";
import { PriceScheduleBreakdownLegend } from "@ops/components/PriceScheduleBreakdown/PriceScheduleBreakdownVisual/PriceScheduleBreakdownLegend";
import React from "react";
import styled from "styled-components";

const StyledPriceScheduleBreakdownContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  width: 100%;
`;

export type PriceScheduleBreakdownLabels = {
  buybackRateLabel: string;
  chargeRateLabel: string;
  energyCharge: string;
  energyChargeDescription?: string;
  energyChargeRate: string;
  energyChargeRateDescription?: string;
  energyScheduleLabel: string;
  solarBuybackCreditSchedule: string;
};

type PriceScheduleBreakdownProps = {
  className?: string;
  id?: string;
  labels?: PriceScheduleBreakdownLabels;
  offerSnapshot: OfferSnapshot;
  title?: string;
};

export const PriceScheduleBreakdown = ({
  offerSnapshot,
  title,
  className,
  id,
  labels = priceScheduleBreakdownDefaultLabels,
}: PriceScheduleBreakdownProps) => {
  const {
    generationPriceScheduleBreakdown,
    generationPriceTierSnapshots,
    priceScheduleBreakdown,
    priceTierSnapshots,
    isTimeOfUseGeneration,
  } = offerSnapshot;

  return (
    <StyledPriceScheduleBreakdownContainer className={className} id={id}>
      {title && <RhTypography variant="h2">{title}</RhTypography>}
      {priceScheduleBreakdown && (
        <PriceScheduleBreakdownDateRangeList
          priceScheduleBreakdowns={priceScheduleBreakdown}
          generationPriceScheduleBreakdowns={generationPriceScheduleBreakdown}
          labels={labels}
          isTouGeneration={isTimeOfUseGeneration}
        />
      )}
      {isTimeOfUseGeneration ? (
        <div>
          <RhTypography variant="h3">{labels.energyChargeRate}</RhTypography>
          <RhTypography>{labels.energyChargeRateDescription}</RhTypography>
        </div>
      ) : null}
      <PriceScheduleBreakdownLegend
        buybackRateLabel={labels.buybackRateLabel}
        chargeRateLabel={labels.chargeRateLabel}
        generationPriceTierSnapshots={generationPriceTierSnapshots}
        priceTierSnapshots={priceTierSnapshots}
      />
    </StyledPriceScheduleBreakdownContainer>
  );
};
