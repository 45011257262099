import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  OfferSnapshotSearchOptions,
  OfferSnapshotType,
} from "@ops-utils/types/offerSnapshotTypes";

const BASE_PATH = "/api/rhapsody/offer-snapshot";

export const rhapsodyOfferSnapshotsApi = {
  list: (options: OfferSnapshotSearchOptions) => {
    return ajax.get<PaginationEndpointResponseType<OfferSnapshotType>>(
      `${BASE_PATH}/`,
      {
        params: options,
      }
    );
  },
  retrieve: (id: IdType) => {
    return ajax.get<OfferSnapshotType>(`${BASE_PATH}/${id}/`);
  },
};
