import { useModalState } from "@common/hooks/useModalState";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { AddCreditCardButton } from "@ops/components/AddCreditCardForm/AddCreditCardButton";
import { ZuoraCardForm } from "@ops/components/ZuoraCardForm/ZuoraCardForm";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectInState } from "@ops/hooks/useProspectInState";
import React, { useEffect } from "react";
import styled from "styled-components";

const StyledButtonBox = styled.div`
  & > * {
    margin-top: ${rhOpsSpacingPx(1)};
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr fit-content(1px);
`;

export const AddProspectPaymentMethod = () => {
  const {
    prospect: { id, autopay, creditCheckSummary },
    updateProspectState,
  } = useEnrollmentFlowContext();
  const flash = useRhFlash();

  const { open: modalOpen, close: modalClose, isOpen } = useModalState();

  const {
    prospect,
    requestMonitor: prospectRequestMonitor,
    fetchProspect,
  } = useProspectInState(id);

  useEffect(() => {
    if (prospect && prospect.billingPaymentMethodId) {
      updateProspectState(prospect);
    }
  }, [prospect?.billingPaymentMethodId]);

  const handleAddPayment = (
    creditCardResponse: ZuoraAddPaymentResponseType
  ) => {
    updateProspectState({ billingPaymentMethodId: creditCardResponse.refId });
    modalClose();
  };

  const handleFailure = (errorMessage: string) => {
    flash.error(`Failed to add this Payment Method ${errorMessage}`);
    modalClose();
  };

  const handleIvrButtonClick = () => {
    fetchProspect();
  };

  const message =
    autopay || creditCheckSummary?.depositAmount
      ? "Payment method is required"
      : "Payment method is optional";

  if (prospectRequestMonitor.isPending) {
    return <BoCircularProgress />;
  }

  return (
    <>
      <StyledGrid>
        <div>
          <Body1>{message}</Body1>
        </div>
        <StyledButtonBox>
          <AddCreditCardButton onClick={modalOpen} />
          <BoButton variant="secondary" onClick={handleIvrButtonClick}>
            Fetch IVR Payment Info
          </BoButton>
        </StyledButtonBox>
      </StyledGrid>

      <BoModal open={isOpen} onClose={modalClose}>
        <BoDialogHeader>Add Credit or Debit</BoDialogHeader>
        <BoDialogBody>
          <ZuoraCardForm
            onFailure={handleFailure}
            onSuccess={handleAddPayment}
            premiseId={null}
            setAsDefault={false}
          />
        </BoDialogBody>
      </BoModal>
    </>
  );
};
