import {
  maskCurrency,
  maskCurrencyWithFractionalZeros,
  unmaskCurrency,
} from "@common/forms/currency.mask";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { IdType } from "@common/types/apiTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhSelect } from "@design-system/components/RhSelect/RhSelect.styled";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { OpsCheckboxInput } from "@ops-design-system/components/OpsCheckboxInput/OpsCheckboxInput";
import { OpsRadioInput } from "@ops-design-system/components/OpsRadioInput/OpsRadioInput";
import {
  Body1,
  H3,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import {
  CreatePaymentDataType,
  PAYMENT_TRANSACTION_FEE,
} from "@ops-utils/types/paymentTypes";
import { AddBankAccountButton } from "@ops/components/AddBankAccount/AddBankAccountButton";
import { AddCreditCardButton } from "@ops/components/AddCreditCardForm/AddCreditCardButton";
import { makeAPaymentFormAtom } from "@ops/components/MakeAPayment/MakeAPayment.atoms";
import {
  AmountTypeContainer,
  AmountTypeOption,
  AmountTypeRow,
  CurrencyInput,
  PaymentMethodContainer,
  PaymentMethodsSection,
  PaymentTransactionFee,
  PaymentTransactionFeeNote,
  StyledPaymentButtonsWrapper,
  TotalAmount,
} from "@ops/components/MakeAPayment/MakeAPayment.styled";
import {
  AmountType,
  MakeAPaymentFormValues,
} from "@ops/components/MakeAPayment/MakeAPayment.types";
import { useMakeAPaymentStep } from "@ops/components/MakeAPayment/useMakeAPaymentStep";
import { useCreatePaymentMutation } from "@ops/hooks/mutations/useCreatePayment.mutation";
import { useInvalidateAccountSummaryQuery } from "@ops/hooks/queries/useAccountSummaryQuery";
import { usePremise } from "@ops/hooks/usePremise";
import { AccountSummary } from "@ops/models/AccountSummary.model";
import { useAtomValue } from "jotai";
import React, { useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";

interface MakeAPaymentFormProps {
  accountSummary: AccountSummary;
  closeModal: () => void;
  paymentMethods: PaymentMethod[] | null;
  premiseId: IdType;
}

export const MakeAPaymentForm = ({
  accountSummary,
  paymentMethods,
  premiseId,
  closeModal,
}: MakeAPaymentFormProps) => {
  const flash = useRhFlash();
  const invalidateAccountSummaryQuery = useInvalidateAccountSummaryQuery();

  const currencyInputRef = useRef<HTMLInputElement>(null);

  const { premise, requestMonitor: premiseRequestMonitor } =
    usePremise(premiseId);

  const createPaymentMutation = useCreatePaymentMutation();

  const { setMakeAPaymentStep } = useMakeAPaymentStep(premiseId);
  const defaultValues = useAtomValue(makeAPaymentFormAtom);
  const isTotalAmountDisabled = Number(accountSummary.totalBalance) <= 0;

  const {
    register,
    formState: { isValid },
    getValues,
    handleSubmit,
    setValue,
    watch,
    trigger,
  } = useForm<MakeAPaymentFormValues>({
    defaultValues: {
      amount: defaultValues.amount,
      amountType: isTotalAmountDisabled
        ? AmountType.Custom
        : defaultValues.amountType,
      includePaymentTransactionFee: true,
      paymentMethodId:
        defaultValues.paymentMethodId ||
        paymentMethods?.find((method) => method.defaultPaymentMethod)?.id,
    },
  });
  const watchAmountType = watch("amountType");
  const watchAmount = watch("amount");
  const watchIncludePaymentTransactionFee = watch(
    "includePaymentTransactionFee"
  );

  const { ref, ...registerAmountProps } = register("amount", {
    onChange: (e) => {
      setValue("amount", maskCurrency(e.target.value));
    },
    setValueAs: (value) => unmaskCurrency(value),
    validate: (value) => {
      if (watchAmountType !== AmountType.Custom) {
        return true;
      }

      return value && Number(value) >= 0.01;
    },
  });

  useImperativeHandle(ref, () => currencyInputRef.current);

  useEffect(() => {
    if (watchAmountType === AmountType.Custom) {
      currencyInputRef.current?.focus();
    }

    trigger();
  }, [watchAmountType, trigger]);

  const showAddAch = !premise?.isNoCheckOrAch;

  const showAddCredit = !premise?.isNoCreditOrDebitCard;

  const showUnableToCollect =
    premise?.isNoCheckOrAch && premise?.isNoCreditOrDebitCard;

  const paymentAmount = useMemo(() => {
    if (watchAmountType === AmountType.PastDue) {
      return accountSummary.pastDueBalance;
    }

    if (watchAmountType === AmountType.Total) {
      return accountSummary.totalBalance;
    }

    return Number(watchAmount);
  }, [
    watchAmount,
    watchAmountType,
    accountSummary.totalBalance,
    accountSummary.pastDueBalance,
  ]);

  const onSubmit = handleSubmit((values: MakeAPaymentFormValues) => {
    const { includePaymentTransactionFee, paymentMethodId } = values;

    const paymentData: CreatePaymentDataType = {
      amount: totalAmount,
      paymentMethodId,
    };

    if (shouldShowTransactionFee && includePaymentTransactionFee) {
      paymentData.paymentTransactionFee = PAYMENT_TRANSACTION_FEE;
    }

    createPaymentMutation.mutate(
      {
        paymentData,
        premiseId,
      },
      {
        onError: (error) => {
          flash.error(error.data.errors.map((e) => e?.detail).join("\n"));
        },
        onSuccess: (response) => {
          setMakeAPaymentStep({
            payload: {
              paymentNumber: response.paymentNumber,
              submittedAmount: totalAmount,
            },
            step: "paymentSuccess",
          });

          invalidateAccountSummaryQuery(premiseId);
        },
      }
    );
  });

  const handleAddCardClick = () => {
    setMakeAPaymentStep({
      formValues: getValues(),
      step: "addACreditCard",
    });
  };

  const handleAddBankAccountClick = () => {
    setMakeAPaymentStep({
      formValues: getValues(),
      step: "addBankAccount",
    });
  };

  if (createPaymentMutation.isPending || premiseRequestMonitor.isPending) {
    <>
      <BoDialogHeader>Make a Payment</BoDialogHeader>
      <BoDialogBody>
        <BoCircularProgress />
      </BoDialogBody>
    </>;
  }

  const shouldShowTransactionFee = premise?.isPaymentTransactionFeeAllowed;

  const totalAmount =
    watchIncludePaymentTransactionFee && shouldShowTransactionFee
      ? paymentAmount + PAYMENT_TRANSACTION_FEE
      : paymentAmount;

  return (
    <>
      <BoDialogHeader>Make a Payment</BoDialogHeader>
      <form onSubmit={onSubmit}>
        <BoDialogBody>
          {showUnableToCollect ? (
            <Subtitle2>
              Unable to collect ACH or Card payment. Advise customer to proceed
              with cash payment.
            </Subtitle2>
          ) : (
            <>
              <AmountTypeContainer>
                <AmountTypeRow>
                  <AmountTypeOption>
                    <OpsRadioInput
                      id="total-amount"
                      value={AmountType.Total}
                      disabled={isTotalAmountDisabled}
                      {...register("amountType")}
                    />
                    <label htmlFor="total-amount">Pay Current Balance</label>
                  </AmountTypeOption>
                  <Body1>{formatCurrency(accountSummary.totalBalance)}</Body1>
                </AmountTypeRow>
                <AmountTypeRow>
                  <AmountTypeOption>
                    <OpsRadioInput
                      id="past-due-amount"
                      value={AmountType.PastDue}
                      disabled={Number(accountSummary.pastDueBalance) <= 0}
                      {...register("amountType")}
                    />
                    <label htmlFor="past-due-amount">
                      Pay Past due Balance
                    </label>
                  </AmountTypeOption>
                  <Body1>{formatCurrency(accountSummary.pastDueBalance)}</Body1>
                </AmountTypeRow>
                <AmountTypeRow>
                  <AmountTypeOption>
                    <OpsRadioInput
                      id="custom-amount"
                      value={AmountType.Custom}
                      {...register("amountType")}
                    />
                    <label htmlFor="custom-amount">Pay Custom Amount</label>
                  </AmountTypeOption>
                  <CurrencyInput
                    type="text"
                    placeholder="$0"
                    disabled={watchAmountType !== AmountType.Custom}
                    ref={currencyInputRef}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    {...registerAmountProps}
                  />
                </AmountTypeRow>
              </AmountTypeContainer>
              {shouldShowTransactionFee ? (
                <PaymentTransactionFee>
                  <AmountTypeRow>
                    <AmountTypeOption>
                      <OpsCheckboxInput
                        id="payment-fee"
                        type="checkbox"
                        {...register("includePaymentTransactionFee")}
                      />
                      <label htmlFor="payment-fee">
                        Payment Transaction Fee
                      </label>
                    </AmountTypeOption>
                    <Body1>{formatCurrency(PAYMENT_TRANSACTION_FEE)}</Body1>
                  </AmountTypeRow>
                  <PaymentTransactionFeeNote>
                    Note: A ${PAYMENT_TRANSACTION_FEE} Payment Transaction Fee
                    will be applied to process this payment
                  </PaymentTransactionFeeNote>
                </PaymentTransactionFee>
              ) : null}
              <PaymentMethodsSection>
                <TotalAmount data-testid="total-amount">
                  <Subtitle2>Total Payment Amount</Subtitle2>
                  <H3 $fontWeight="Bold">
                    {maskCurrencyWithFractionalZeros(totalAmount)}
                  </H3>
                </TotalAmount>
                {paymentMethods && (
                  <PaymentMethodContainer>
                    <BoLabel>Payment Method</BoLabel>
                    <RhSelect {...register("paymentMethodId")}>
                      {paymentMethods.map((paymentMethod) => (
                        <option key={paymentMethod.id} value={paymentMethod.id}>
                          {paymentMethod.companyNameAndNumber}
                        </option>
                      ))}
                    </RhSelect>
                  </PaymentMethodContainer>
                )}
                <StyledPaymentButtonsWrapper>
                  {showAddCredit ? (
                    <AddCreditCardButton onClick={handleAddCardClick} />
                  ) : null}

                  {showAddAch ? (
                    <AddBankAccountButton onClick={handleAddBankAccountClick} />
                  ) : null}
                </StyledPaymentButtonsWrapper>
              </PaymentMethodsSection>
            </>
          )}
        </BoDialogBody>
        <BoDialogButtonFooter
          confirmBtnText="Make Payment"
          cancelBtnText="Cancel"
          confirmBtnType="submit"
          confirmDisabled={
            createPaymentMutation.isPending || !isValid || !!showUnableToCollect
          }
          onCancel={closeModal}
        />
      </form>
    </>
  );
};
