import { RhythmCampaignSlugs } from "@common/constants/campaigns.constant";
import { IdType } from "@common/types/apiTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body2, H3 } from "@ops-design-system/components/Typography/Typography";
import {
  OfferSnapshotOrderOptions,
  OfferSnapshotSearchOptions,
} from "@ops-utils/types/offerSnapshotTypes";
import { contractRenewalWizardStateAtom } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.atoms";
import { offersStepSchema } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.schemas";
import {
  ContractRenewalWizardButtonContainer,
  ContractRenewalWizardStepContainer,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import {
  OffersContainer,
  OffersStepForm,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizardOffersStep/ContractRenewalWizardOffersStep.styled";
import { EmailOutReachOfferSnapshots } from "@ops/components/ContractRenewalWizard/ContractRenewalWizardOffersStep/EmailOutReachOfferSnapshots";
import { useContractRenewalWizard } from "@ops/components/ContractRenewalWizard/useContractRenewalWizard";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import { OfferSnapshotFiltering } from "@ops/components/OfferSnapshotFiltering/OfferSnapshotFiltering";
import { useOfferSnapshotsQuery } from "@ops/hooks/queries/useOfferSnapshots.query";
import { usePremise } from "@ops/hooks/usePremise";
import { useAtomValue } from "jotai/react";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

const initialSearchOptions = {
  campaignSlug: RhythmCampaignSlugs.InboundTelemarketingRenewal,
  dunsNumber: "",
  limit: 30,
  offerLabels: [],
  ordering: OfferSnapshotOrderOptions["Price: Low to High"],
  planType: [],
  termMonths: [],
} satisfies OfferSnapshotSearchOptions;

export const ContractRenewalWizardOffersStep = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);

  const {
    handleNextStep,
    handlePreviousStep,
    isNextStepAvailable,
    isPreviousStepAvailable,
  } = useContractRenewalWizard();

  const { offerSnapshotId } = useAtomValue(contractRenewalWizardStateAtom);

  const [searchOptions, setSearchOptions] =
    useState<OfferSnapshotSearchOptions>(initialSearchOptions);

  useEffect(() => {
    if (premise?.currentOrder?.dunsNumber) {
      setSearchOptions((prevState) => {
        return {
          ...prevState,
          dunsNumber: premise?.currentOrder?.dunsNumber ?? "",
        };
      });
    }
  }, [premise?.currentOrder?.dunsNumber]);

  const methods = useForm<z.infer<typeof offersStepSchema>>({
    defaultValues: {
      offerSnapshotId,
    },
    resolver: zodResolver(offersStepSchema),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = methods;

  const onSubmit = handleSubmit((values) => {
    handleNextStep(values);
  });

  const selectedOfferSnapshotId = watch("offerSnapshotId");

  const offerSnapshotsQuery = useOfferSnapshotsQuery({
    queryOptions: {
      enabled: Boolean(searchOptions.dunsNumber),
    },
    searchOptions,
  });

  if (!premise?.currentOrder?.dunsNumber) {
    return <div>Duns Number is required to proceed</div>;
  }

  if (offerSnapshotsQuery.isError) {
    return (
      <div>
        An error occurred. Unable to get offers for this service address.
      </div>
    );
  }

  if (offerSnapshotsQuery.isPending) {
    return (
      <div>
        <BoCircularProgress />
      </div>
    );
  }

  return (
    <ContractRenewalWizardStepContainer>
      <H3>Choose a plan</H3>
      <OfferSnapshotFiltering
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
      />
      <FormProvider {...methods}>
        <OffersStepForm onSubmit={onSubmit}>
          {offerSnapshotsQuery.data.count === 0 ? (
            <Body2>No offers available</Body2>
          ) : (
            <OffersContainer>
              {offerSnapshotsQuery.data.results.map((offerSnapshot) => {
                return (
                  <Controller
                    key={offerSnapshot.id}
                    control={control}
                    name="offerSnapshotId"
                    render={({ field }) => {
                      return (
                        <OfferCard
                          offerSnapshot={offerSnapshot}
                          active={offerSnapshot.id === selectedOfferSnapshotId}
                          onSelect={() => {
                            setValue("offerSnapshotId", offerSnapshot.id, {
                              shouldValidate: true,
                            });
                          }}
                        />
                      );
                    }}
                  />
                );
              })}
            </OffersContainer>
          )}

          <EmailOutReachOfferSnapshots
            premiseId={premise.id}
            selectedOfferSnapshotId={selectedOfferSnapshotId}
          />

          <ContractRenewalWizardButtonContainer>
            <BoButton
              variant="secondary"
              type="button"
              onClick={() => handlePreviousStep()}
              disabled={!isPreviousStepAvailable}
            >
              Previous Page
            </BoButton>
            <BoButton
              variant="primary"
              type="submit"
              disabled={!isNextStepAvailable || !isValid}
            >
              Next Page
            </BoButton>
          </ContractRenewalWizardButtonContainer>
        </OffersStepForm>
      </FormProvider>
    </ContractRenewalWizardStepContainer>
  );
};
