import { AffiliateType } from "@common/types/affiliateType";
import { ReactComponent as CopyIcon } from "@design-system/icons/CopyIcon.svg";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { signUpReferralUrl } from "@ops/services/raf";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const CLEAR_LINK_COPIED_TIME = 2000;

interface RafReferralUrlProps {
  affiliate: AffiliateType;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;

  & > input {
    width: 100%;
  }
`;

export const RafReferralUrl = ({ affiliate }: RafReferralUrlProps) => {
  const copyLinkInputRef = useRef<HTMLInputElement>(null);
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const isDisabled = !affiliate.referralCode || isLinkCopied;

  useEffect(() => {
    if (isLinkCopied) {
      const timeoutId = setTimeout(() => {
        setIsLinkCopied(false);
      }, CLEAR_LINK_COPIED_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLinkCopied]);

  const copyTextToClipboard = () => {
    if (!isLinkCopied) {
      navigator.clipboard.writeText(referralUrl);
      setIsLinkCopied(true);
    }
  };

  const referralUrl = signUpReferralUrl(affiliate);

  const buttonText = isLinkCopied ? "Copied" : "Copy Link";

  return (
    <Container>
      <input readOnly ref={copyLinkInputRef} value={referralUrl} disabled />
      <BoButton
        size="small"
        color="primary"
        disabled={isDisabled}
        onClick={copyTextToClipboard}
        icon={CopyIcon}
      >
        {buttonText}
      </BoButton>
    </Container>
  );
};
