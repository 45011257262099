import { IdType } from "@common/types/apiTypes";
import { rhapsodyPremiseApi } from "@ops-data/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@ops/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseTemperatureSummaryQueryOptions {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const usePremiseTemperatureSummaryQuery = (
  options: UsePremiseTemperatureSummaryQueryOptions
) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = options;

  const query = useQuery({
    enabled,
    queryFn: () => rhapsodyPremiseApi.temperatureSummary(premiseId),
    queryKey: PremiseKeys.temperatureSummary(premiseId),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });

  return query;
};
