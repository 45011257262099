import { EnvTypes } from "@common/enums/envTypes.enum";

export interface EnvSettings {
  adminUrl: string;
  axiosBaseUrl: string;
  axiosTimeoutMs: number;
  cdnUrl: string;
  fullStoryOrgId?: string;
  ironhideBaseUrl: string;
  iterableProjectId: string;
  marketingUrl: string;
  oktaClientId: string;
  oktaIssuer: string;
  oktaRedirectUri: string;
  portalUrl: string;
  sentryDSN: string;
  sentryRelease: string;
  sentryTracesSampleRate: number;
  zuoraAddBankAccountPageId: string;
  zuoraBaseUrl: string;
  zuoraCreditCardPaymentPageId: string;
}

export const allEnvApplicationSettings: Record<EnvTypes, EnvSettings> = {
  local: {
    adminUrl: "http://localhost:8080/admin",
    axiosBaseUrl: "",
    axiosTimeoutMs: 60000,
    cdnUrl: "https://cdn.staging.gotrhythm.com",
    ironhideBaseUrl: "https://ih-performance.gotrhythm.com",
    iterableProjectId: "9043",
    marketingUrl: "http://localhost:1337",
    oktaClientId: "0oa232nki3EwVw2qC1d6",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://local.gotrhythm.com:3001/implicit/callback",
    portalUrl: "https://local.gotrhythm.com:3000",
    sentryDSN: "",
    sentryRelease: "",
    sentryTracesSampleRate: 0,
    zuoraAddBankAccountPageId: "8ad08c8489cf3c850189d09bfa654445",
    zuoraBaseUrl: "https://apisandbox.zuora.com",
    zuoraCreditCardPaymentPageId: "8ad096ca89de7ac70189e002e4773005",
  },
  localStaging: {
    adminUrl: "https://api.staging.gotrhythm.com/admin",
    axiosBaseUrl: "https://api.staging.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.staging.gotrhythm.com",
    ironhideBaseUrl: "https://ih-performance.gotrhythm.com",
    iterableProjectId: "9043",
    marketingUrl: "https://staging.gotrhythm.com",
    oktaClientId: "0oa232nki3EwVw2qC1d6",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://local.gotrhythm.com:3001/implicit/callback",
    portalUrl: "https://app.staging.gotrhythm.com",
    sentryDSN: "",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.5,
    zuoraAddBankAccountPageId: "8ad08c8489cf3c850189d09bfa654445",
    zuoraBaseUrl: "https://apisandbox.zuora.com",
    zuoraCreditCardPaymentPageId: "2c92c0f87a1828f1017a2024d522771f",
  },
  production: {
    adminUrl: "https://api.gotrhythm.com/admin",
    axiosBaseUrl: "https://api.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.gotrhythm.com",
    fullStoryOrgId: "ZPQE0",
    ironhideBaseUrl: "https://ironhide.gotrhythm.com",
    iterableProjectId: "9042",
    marketingUrl: "https://www.gotrhythm.com",
    oktaClientId: "0oa17m920makU4E8m4x7",
    oktaIssuer: "https://login.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://ops.gotrhythm.com/implicit/callback",
    portalUrl: "https://www.app.gotrhythm.com",
    sentryDSN:
      "https://8c3e90ea3edd435888af4bf6ef6eb864@o415325.ingest.sentry.io/5465235",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.2,
    zuoraAddBankAccountPageId: "8a12867b8a02c0ab018a050d5c8733a4",
    zuoraBaseUrl: "https://www.zuora.com",
    zuoraCreditCardPaymentPageId: "2c92a00c7a187273017a211e895143bd",
  },
  staging: {
    adminUrl: "https://api.staging.gotrhythm.com/admin",
    axiosBaseUrl: "https://api.staging.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.staging.gotrhythm.com",
    ironhideBaseUrl: "https://ih-performance.gotrhythm.com",
    iterableProjectId: "9043",
    marketingUrl: "https://staging.gotrhythm.com",
    oktaClientId: "0oa25s5zxsXckkr651d6",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://ops.staging.gotrhythm.com/implicit/callback",
    portalUrl: "https://app.staging.gotrhythm.com",
    sentryDSN:
      "https://8c3e90ea3edd435888af4bf6ef6eb864@o415325.ingest.sentry.io/5465235",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.5,
    zuoraAddBankAccountPageId: "8ad08c8489b55f320189b70675905bdb",
    zuoraBaseUrl: "https://apisandbox.zuora.com",
    zuoraCreditCardPaymentPageId: "2c92c0f87a1828f1017a2024d522771f",
  },
};
