import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";

export interface AddAverageBillingFormValues {
  averageBillingMonthlyCharge: string;
}

export const addAverageBillingFormValidators =
  generateValidationErrorCollector<AddAverageBillingFormValues>({
    averageBillingMonthlyCharge: [opsAndPricingIsRequired],
  });
