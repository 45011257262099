import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { BoLinkButton } from "@ops-design-system/components/BoLinkButton/BoLinkButton";
import { ReactComponent as Cog } from "@ops-design-system/icons/Cog.svg";
import { Section } from "@ops/components/AdminDashboard/shared/AdminDashboard.styled";
import { adminPaths } from "@ops/routes/routePaths";
import React from "react";

export const ManageContactLogsSection = () => {
  const sectionName = "Contact Log";

  return (
    <Section aria-label={sectionName}>
      <BoCardLabel>{sectionName}</BoCardLabel>
      <BoCard>
        <ButtonsGrid>
          <BoLinkButton
            to={adminPaths.contactLogs()}
            variant="secondary"
            icon={Cog}
          >
            Manage Contact Logs
          </BoLinkButton>
        </ButtonsGrid>
      </BoCard>
    </Section>
  );
};
