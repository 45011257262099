import { ReactComponent as ChargeRate } from "@common/images/ChargeRate.svg";
import { ReactComponent as SolarBuyBack } from "@common/images/SolarBuyBack.svg";
import { PriceScheduleBreakdownVisualType } from "@common/types/priceScheduleBreakdownVisualTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PriceScheduleBreakdownLabels } from "@ops/components/PriceScheduleBreakdown/PriceScheduleBreakdown";
import {
  BorderLine,
  EnergyChargeContainer,
  PriceScheduleBreakdownDateRangeContainer,
  ScheduleBreakdown,
  ScheduleContainer,
  ScheduleTitle,
  ScheduleTitlesContainer,
  Tick,
  TickContainer,
  TickLine,
  Ticks,
} from "@ops/components/PriceScheduleBreakdown/PriceScheduleBreakdownDateRange/PriceScheduleBreakdownDateRange.styled";
import { GenerationPriceScheduleBreakdownVisual } from "@ops/components/PriceScheduleBreakdown/PriceScheduleBreakdownVisual/GenerationPriceScheduleBreakdownVisual";
import { PriceScheduleBreakdownVisual } from "@ops/components/PriceScheduleBreakdown/PriceScheduleBreakdownVisual/PriceScheduleBreakdownVisual";
import React from "react";

interface PriceScheduleBreakdownDateRangeProps {
  generationPriceScheduleBreakdown?: PriceScheduleBreakdownVisualType;
  isTouGeneration?: boolean;
  labels: PriceScheduleBreakdownLabels;
  priceScheduleBreakdown: PriceScheduleBreakdownVisualType;
}

export const PriceScheduleBreakdownDateRange = ({
  generationPriceScheduleBreakdown,
  priceScheduleBreakdown,
  labels,
  isTouGeneration,
}: PriceScheduleBreakdownDateRangeProps) => {
  if (!isTouGeneration) {
    return (
      <PriceScheduleBreakdownDateRangeContainer>
        <ScheduleContainer>
          <ScheduleBreakdown>
            <PriceScheduleBreakdownVisual
              priceScheduleBreakdown={priceScheduleBreakdown}
            />
          </ScheduleBreakdown>
        </ScheduleContainer>
      </PriceScheduleBreakdownDateRangeContainer>
    );
  }

  return (
    <PriceScheduleBreakdownDateRangeContainer>
      <EnergyChargeContainer>
        <RhTypography variant="h3">{labels.energyCharge}</RhTypography>
        <RhTypography>{labels.energyChargeDescription}</RhTypography>
      </EnergyChargeContainer>
      <ScheduleContainer>
        <ScheduleTitlesContainer>
          <ScheduleTitle>
            <div>
              <ChargeRate />
            </div>
            <RhTypography fontWeight="Bold">
              {labels.energyScheduleLabel}
            </RhTypography>
          </ScheduleTitle>
          <BorderLine />
          <ScheduleTitle>
            <div>
              <SolarBuyBack />
            </div>
            <RhTypography fontWeight="Bold">
              {labels.solarBuybackCreditSchedule}
            </RhTypography>
          </ScheduleTitle>
        </ScheduleTitlesContainer>
        <ScheduleBreakdown>
          <PriceScheduleBreakdownVisual
            priceScheduleBreakdown={priceScheduleBreakdown}
            isTouGeneration={isTouGeneration}
          />
          <TickContainer>
            <Ticks>
              {Array(25)
                .fill(null)
                .map((el, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tick key={`tick-${index}`} />
                ))}
            </Ticks>
            <TickLine />
          </TickContainer>
          {generationPriceScheduleBreakdown ? (
            <GenerationPriceScheduleBreakdownVisual
              priceScheduleBreakdown={generationPriceScheduleBreakdown}
            />
          ) : null}
        </ScheduleBreakdown>
      </ScheduleContainer>
    </PriceScheduleBreakdownDateRangeContainer>
  );
};
