import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { AffiliateType } from "@common/types/affiliateType";
import { isValidEmail } from "@ops/forms/validators";

export type AffiliateFormValues = Omit<
  AffiliateType,
  "id" | "referralCode" | "createdAt"
>;

export const affiliateFormValidator =
  generateValidationErrorCollector<AffiliateFormValues>({
    addressLine1: [],
    city: [],
    einTaxId: [],
    email: [opsAndPricingIsRequired, isValidEmail],
    firstName: [opsAndPricingIsRequired],
    lastName: [opsAndPricingIsRequired],
    organizationName: [],
    phone: [],
    state: [],
    unitNumber: [],
    zipCode: [],
  });
