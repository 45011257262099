import { LONG_DATE_TIME_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import { EDIMessageType } from "@common/types/ediTypes";
import dayjs from "dayjs";

export class EDIMessage {
  public id: IdType = "";
  public createdAt: string;
  public updatedAt: string;
  public actionId: string;
  public status: string;
  public jsonMessage: Record<string, unknown>;
  public outbound: boolean;

  public constructor(init: EDIMessageType) {
    this.id = init.id;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
    this.actionId = init.actionId;
    this.status = init.status;
    this.jsonMessage = init.jsonMessage;
    this.outbound = init.outbound;
  }

  public formatCreatedAt(): string {
    return dayjs(this.createdAt).format(LONG_DATE_TIME_FORMAT);
  }

  public direction(): string {
    return this.outbound ? "OUT" : "IN";
  }

  public formatSummary(): string {
    return `[${this.direction()}] ${
      this.actionId
    } -- ${this.formatCreatedAt()} --
    id:${this.id} ${this.status}`.replace(/\s+/gm, " ");
  }
}

export const orderByCreatedAtAndIdDesc = (
  a: EDIMessage,
  b: EDIMessage
): number => {
  if (b.createdAt === a.createdAt) {
    return parseInt(b.id, 10) - parseInt(a.id, 10);
  }
  return b.createdAt > a.createdAt ? 1 : -1;
};
