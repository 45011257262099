import { boxShadows } from "@design-system/constants/boxShadows";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as CheckIcon } from "@design-system/icons/CheckIcon.svg";
import { ReactComponent as MapPinIcon } from "@design-system/icons/MapPinIcon.svg";
import {
  darkPurple,
  green,
  grey,
  red,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled, { css } from "styled-components";

const MIN_WIDTH = "300px";

export const StyledOpsSelectorContainer = styled.div<{ $minWidth?: string }>`
  display: flex;
  flex: 2;
  max-width: ${MIN_WIDTH};
  min-height: 55px;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${RhythmBreakpoints.XXS}px) {
    min-width: ${MIN_WIDTH};
  }
`;

interface StyledChevronIconContainerProps {
  $isOpen: boolean;
}

export const StyledChevronIconContainer = styled.span<StyledChevronIconContainerProps>`
  background: ${trueWhite};
  border: 1px solid ${grey[200]};
  border-radius: 3px;
  display: flex;
  padding: ${rhOpsSpacingPx(1)};

  svg {
    transform: rotate(0);
    transition: transform 0.1s linear;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

interface StyledSelectedItemShownProps {
  $hasMenuItems: boolean;
}

export const StyledSelectedItemShown = styled.button<StyledSelectedItemShownProps>`
  align-items: center;
  appearance: none;
  background: ${trueWhite};
  border: 1px solid ${grey[200]};
  border-radius: 5px;
  display: flex;
  flex: 2;
  font-family: inherit;
  gap: 1rem;
  padding: ${rhOpsSpacingPx(1)};
  text-align: left;
  width: 100%;

  @media screen and (min-width: ${RhythmBreakpoints.XXS}px) {
    min-width: ${MIN_WIDTH};
  }

  & > *:first-child {
    flex: 1;
  }

  ${({ $hasMenuItems }) =>
    $hasMenuItems
      ? css`
          &:hover,
          &:focus,
          &:focus-within {
            border-color: ${darkPurple.main};
            cursor: pointer;
            outline: none;

            ${StyledChevronIconContainer} {
              border-color: ${darkPurple.main};
            }
          }
        `
      : css`
          pointer-events: none;
        `}
`;

export const StyledOpsSelectorMenuContainer = styled.div`
  left: 0;
  min-width: ${MIN_WIDTH};
  padding-top: 0.75rem;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
`;

export const StyledOpsSelectorMenu = styled.ul`
  background: ${trueWhite};
  border: 1px solid ${grey[100]};
  border-radius: 5px;
  box-shadow: ${boxShadows.default};
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  padding: 0;

  &:focus,
  &:focus-within {
    border-color: ${darkPurple.main};
    cursor: pointer;
    outline: none;
  }
`;

export const StyledOpsSelectorMenuItemLink = styled.a`
  color: ${grey[900]};
  display: flex;
  padding: ${rhOpsSpacingPx(2)};
  width: 100%;
`;

export const StyledOpsSelectorMenuItem = styled.li<{
  $isSelected?: boolean;
}>`
  cursor: pointer;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${darkPurple[50]};
  }

  &:hover {
    background-color: ${darkPurple[50]};
    cursor: pointer;

    ${StyledOpsSelectorMenuItemLink} {
      text-decoration: none;
    }
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${darkPurple[50]};
    `}
`;

export const StyledOpsSelectorListItem = styled.span`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

interface StyledOpsSelectorStatusIndicatorProps {
  $statusColor: string;
}

export const StyledOpsSelectorStatusIndicator = styled.span<StyledOpsSelectorStatusIndicatorProps>`
  background-color: ${red.main};
  border-radius: 1000px;
  display: block;
  min-height: 26px;
  width: 2px;

  ${({ $statusColor }) =>
    $statusColor &&
    css`
      background-color: ${$statusColor};
    `}
`;

export const StyledOpsSelectorTitleContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;

export const StyledOpsSelectorStatusText = styled.span<StyledOpsSelectorStatusIndicatorProps>`
  color: ${red.main};
  font-size: 0.8rem;
  font-weight: ${FontWeight.Semibold};
  padding-top: 0.25rem;

  ${({ $statusColor }) =>
    $statusColor &&
    css`
      color: ${$statusColor};
    `}
`;

export const StyledOpsSelectorSubtitle = styled.span`
  color: ${grey[400]};
  display: block;
  font-size: 0.75rem;
  text-transform: capitalize;
`;

interface StyledOpsSelectorTitleProps {
  $isSelected: boolean;
}

export const StyledOpsSelectorTitle = styled.span<StyledOpsSelectorTitleProps>`
  font-size: 0.8rem;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      font-weight: ${FontWeight.Bold};
    `}
`;

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${green.main};
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const StyledMapPinIcon = styled(MapPinIcon)`
  color: ${darkPurple.main};
`;
