import { AddressForm } from "@common/models/AddressForm.model";
import { RhApiError } from "@common/types/errorTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body2, H2 } from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { AddPremiseWizardForm } from "@ops/components/AddPremiseWizard/AddPremiseWizardForm";
import { AddPremiseSubmission } from "@ops/components/AddPremiseWizard/ConfirmDetails/AddPremiseSubmission.service";
import { PremiseDetails } from "@ops/components/PremiseDetails/PremiseDetails";
import { useAddPremiseWizardContext } from "@ops/hooks/contexts/useAddPremiseWizardContext";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { customerRefetch } from "@ops/slices/customerSlice";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  margin: auto;
  width: 80%;
`;
const HeaderContainer = styled(FormFlexRow)`
  justify-content: center;
`;
const CheckboxContainer = styled.div`
  margin-left: -12px;
`;

interface CheckboxLabelProps {
  $htmlFor: string;
}

const CheckboxLabel = styled(Body2).attrs<CheckboxLabelProps>(
  ({ $htmlFor }) => ({
    as: "label",
    htmlFor: $htmlFor,
  })
)`
  align-self: center;
`;

export const ConfirmDetails = () => {
  const {
    wizardState,
    customer,
    setNextButtonProps,
    closeModal,
    restoreStateToInitialValues,
  } = useAddPremiseWizardContext();
  const [confirmAgreement, setConfirmAgreement] = useState<boolean>(false);
  const dispatch = useDispatch();
  const flash = useRhFlash();
  const {
    serviceAddress,
    mailingAddress,
    offerSnapshotId,
    startDate,
    enrollmentType,
    paperless,
    esiId,
    utilityName,
  } = wizardState;

  const offerSnapshotQuery = useOfferSnapshotQuery({ offerSnapshotId });

  if (offerSnapshotQuery.isPending) {
    return (
      <Container>
        <BoCircularProgress />
      </Container>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <Container>
        <Body2>Failed to fetch offer details</Body2>
      </Container>
    );
  }

  if (
    !serviceAddress ||
    !mailingAddress ||
    !offerSnapshotId ||
    !startDate ||
    !enrollmentType
  ) {
    flash.error(
      "You are missing required data. Please ensure all fields are filled in."
    );
    return null;
  }

  const onSubmit = () => {
    if (!confirmAgreement) {
      return;
    }
    const submissionService = new AddPremiseSubmission(wizardState, customer);

    if (!submissionService.isValid) {
      flash.error(
        `You are missing required data: ${submissionService.errors.join(",")}`
      );
      setConfirmAgreement(false);
      return;
    }

    setNextButtonProps({ loading: true });

    submissionService
      .submit()
      .then(() => {
        flash.success("Successfully added new premise");
        dispatch(customerRefetch(customer.id));
        restoreStateToInitialValues();
        closeModal();
      })
      .catch((error: RhApiError) => {
        flash.error(
          `An error occurred while attempting to create new premise: '${
            error.data.errorCode ?? "Unknown Error"
          }'. Please try again.`
        );
        setNextButtonProps({ loading: false });
      });
  };

  const offerSnapshot = offerSnapshotQuery.data;

  return (
    <Container>
      <HeaderContainer>
        <H2 $fontWeight="SemiBold">Review Premise Details</H2>
      </HeaderContainer>
      <PremiseDetails
        serviceAddress={new AddressForm(serviceAddress)}
        mailingAddress={new AddressForm(mailingAddress)}
        offerSnapshot={offerSnapshot}
        orderStartDate={startDate}
        orderEndDate={formatMonthDayYear(
          dayjs(startDate).add(offerSnapshot.termMonths, "months").toString()
        )}
        enrollmentType={enrollmentType}
        paperless={paperless}
        esiId={esiId}
        utilityName={utilityName}
      />
      <AddPremiseWizardForm
        onSubmit={onSubmit}
        hasValidationErrors={!confirmAgreement}
      >
        <FormFlexRow>
          <CheckboxContainer>
            <BoCheckboxInput
              name="confirmAgreement"
              id="confirmAgreement"
              onChange={() => setConfirmAgreement(!confirmAgreement)}
              checked={confirmAgreement}
            />
          </CheckboxContainer>
          <CheckboxLabel $htmlFor="confirmAgreement">
            Was the Add Premise script read and did the customer agree to the
            terms and conditions?
          </CheckboxLabel>
        </FormFlexRow>
      </AddPremiseWizardForm>
    </Container>
  );
};
