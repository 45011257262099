import { premiseUrls } from "@common/api/premiseUrls";
import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { IdType } from "@common/types/apiTypes";
import { authenticatedFileDownload } from "@common/utils/authenticatedFileDownload";

export const welcomePacketDownload = (premiseId: IdType, orderId: IdType) => {
  const url = premiseUrls.orderWelcomePacketDownload(premiseId, orderId);
  const filename = "welcome-packet.pdf";

  return authenticatedFileDownload(url, filename, PDF_MIME_TYPE);
};
