import {
  BoTextInput,
  BoTextInputProps,
} from "@ops-design-system/components/BoTextInput/BoTextInput";
import { CURRENCY_MASK_PATTERN } from "@ops-design-system/forms/currency.mask";
import { MaskedPattern } from "imask";
import React, { ChangeEventHandler, useEffect, useState } from "react";

type BoCurrencyInputProps = Omit<BoTextInputProps, "onChange"> & {
  onChange: (value: number) => void;
  value?: number;
};

const mask = new MaskedPattern(CURRENCY_MASK_PATTERN);

export const BoCurrencyInput = ({
  onChange,
  value,
  children,
  ...rest
}: BoCurrencyInputProps) => {
  const [formattedValue, setFormattedValue] = useState<string>(
    value ? value.toString() : ""
  );
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    mask.value = e?.currentTarget?.value ?? "";
    setFormattedValue(mask.value);
    onChange && onChange(parseFloat(mask.unmaskedValue));
  };

  useEffect(() => {
    mask.value = value?.toString() ?? "";
    setFormattedValue(mask.value);
  }, [value]);

  return (
    <BoTextInput {...rest} onChange={handleChange} value={formattedValue}>
      {children}
    </BoTextInput>
  );
};
