import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { AffiliateType } from "@common/types/affiliateType";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoLinkButton } from "@ops-design-system/components/BoLinkButton/BoLinkButton";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { Body1, H1 } from "@ops-design-system/components/Typography/Typography";
import { useAffiliates } from "@ops/hooks/queries/useAffiliates";
import { affiliateDetailPath, newAffiliatePath } from "@ops/routes/routePaths";
import sortBy from "lodash/sortBy";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const columns: (keyof AffiliateType)[] = [
  "id",
  "firstName",
  "lastName",
  "email",
  "phone",
  "zipCode",
  "organizationName",
  "einTaxId",
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 600px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TableBodyRow = styled(BoTableRow)`
  cursor: pointer;
`;

export const AffiliatesPage = () => {
  const navigate = useNavigate();

  const affiliatesQuery = useAffiliates();

  if (affiliatesQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (affiliatesQuery.isError) {
    return (
      <BoCard>
        <Body1>Unable to get affiliates. Please try again.</Body1>
      </BoCard>
    );
  }

  if (affiliatesQuery.data.length === 0) {
    return (
      <Container>
        <Header>
          <H1>Affiliates</H1>
          <BoLinkButton to={newAffiliatePath()}>New Affiliate</BoLinkButton>
        </Header>
        <BoCard>
          <Body1>No affiliates found</Body1>
        </BoCard>
      </Container>
    );
  }

  const goToAffiliateDetailPage = (affiliateId: string) => {
    navigate(affiliateDetailPath(affiliateId));
  };

  return (
    <Container>
      <Header>
        <H1>Affiliates</H1>
        <BoLinkButton to={newAffiliatePath()}>New Affiliate</BoLinkButton>
      </Header>

      <BoTable>
        <BoTableHead>
          <BoTableRow>
            {columns.map((column) => (
              <BoTableHeadCell key={column}>
                {convertCamelCaseToSentenceCase(column)}
              </BoTableHeadCell>
            ))}
            <BoTableHeadCell>Sign Up Date</BoTableHeadCell>
          </BoTableRow>
        </BoTableHead>
        <BoTableBody>
          {sortBy(affiliatesQuery.data, "lastName").map((affiliate) => (
            <TableBodyRow
              key={affiliate.id}
              onClick={() => goToAffiliateDetailPage(affiliate.id)}
            >
              {columns.map((column) => (
                <BoTableCell key={`${column}${affiliate.id}`}>
                  {affiliate[column] || NOT_APPLICABLE_ABBREVIATION}
                </BoTableCell>
              ))}
              <BoTableCell>
                {formatMonthDayYear(affiliate.createdAt)}
              </BoTableCell>
            </TableBodyRow>
          ))}
        </BoTableBody>
      </BoTable>
    </Container>
  );
};
