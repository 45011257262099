import { premiseApi } from "@common/api/premiseApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCheckboxField } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { isValidZipCode } from "@ops/forms/validators";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import { editDialogContentsCss } from "@ops/styles/mixins";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

interface EditAddressFormValues {
  addressLine1: string;
  city: string;
  mailingAddressSameAsServiceAddress: boolean;
  state: string;
  unitNumber: string;
  zipCode: string;
}

const editAddressFormValidators =
  generateValidationErrorCollector<EditAddressFormValues>({
    addressLine1: [opsAndPricingIsRequired],
    city: [opsAndPricingIsRequired],
    zipCode: [isValidZipCode, opsAndPricingIsRequired],
  });

interface EditMailingAddressFormProps {
  addressLine1: string;
  city: string;
  close: () => void;
  mailingAddressSameAsServiceAddress: boolean;
  open: boolean;
  premiseId: IdType;
  state: string;
  unitNumber?: string;
  zipCode: string;
}

const StyledForm = styled.form`
  width: 100%;
`;
const CurrentAddressWrapper = styled.div`
  ${editDialogContentsCss}
`;
const Col3of4 = styled.div`
  flex-basis: 75%;
`;
const Col1of4 = styled.div`
  flex-basis: 25%;
`;
const Col2of4 = styled.div`
  flex-basis: 50%;
`;
const InputsRow = styled(BoFlexBox)`
  margin-bottom: ${rhOpsSpacingPx(3)};
  & > * {
    margin-right: ${rhOpsSpacingPx(2.0)};
  }
`;

export const EditMailingAddressForm = ({
  premiseId,
  addressLine1,
  unitNumber,
  city,
  state,
  zipCode,
  mailingAddressSameAsServiceAddress,
  open,
  close,
}: EditMailingAddressFormProps) => {
  const [mailingSameAsServiceChecked, setMailingSameAsServiceChecked] =
    useState<boolean>(mailingAddressSameAsServiceAddress);

  const dispatch = useDispatch();
  const flash = useRhFlash();

  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();

  const handleSubmit = (formValues: EditAddressFormValues) => {
    setPending();
    const sendFormValues = {
      ...formValues,
      unitNumber: formValues.unitNumber ? formValues.unitNumber : "",
    };

    return premiseApi
      .setMailingAddress(premiseId, sendFormValues)
      .then(() => {
        setSuccess();
        dispatch(premiseRefetch(premiseId));
        close();
      })
      .catch(() => {
        setFailure();
        flash.error(
          "The mailing address for this customer could not be updated. Please try again."
        );
      });
  };

  return (
    <BoModal open={open} onClose={close}>
      <Form<EditAddressFormValues>
        initialValues={{
          addressLine1,
          city,
          mailingAddressSameAsServiceAddress,
          state,
          unitNumber,
          zipCode,
        }}
        onSubmit={handleSubmit}
        validate={editAddressFormValidators}
        render={({
          handleSubmit: formHandleSubmit,
          dirty,
          hasValidationErrors,
        }) => {
          return (
            <StyledForm noValidate onSubmit={formHandleSubmit}>
              <BoDialogHeader>Edit Mailing Address</BoDialogHeader>
              <BoDialogBody>
                <CurrentAddressWrapper>
                  <Subtitle2 as="div">
                    Customer&apos;s current mailing address:
                  </Subtitle2>
                  <Subtitle2 as="div" $fontWeight="Bold">
                    <div>{`${addressLine1} ${unitNumber}`}</div>
                    <div>{`${city} ${state} ${zipCode}`}</div>
                  </Subtitle2>
                </CurrentAddressWrapper>
                <BoCheckboxField
                  name="mailingAddressSameAsServiceAddress"
                  label="Mailing Address Same as Service Address"
                  onClick={() => {
                    setMailingSameAsServiceChecked(
                      !mailingSameAsServiceChecked
                    );
                  }}
                />

                <InputsRow>
                  <Col3of4>
                    <BoTextField
                      name="addressLine1"
                      placeholder=""
                      type="text"
                      disabled={mailingSameAsServiceChecked}
                    >
                      Address
                    </BoTextField>
                  </Col3of4>
                  <Col1of4>
                    <BoTextField
                      name="unitNumber"
                      placeholder=""
                      type="text"
                      disabled={mailingSameAsServiceChecked}
                    >
                      Unit Number
                    </BoTextField>
                  </Col1of4>
                </InputsRow>

                <InputsRow>
                  <Col2of4>
                    <BoTextField
                      name="city"
                      placeholder=""
                      type="text"
                      disabled={mailingSameAsServiceChecked}
                    >
                      City
                    </BoTextField>
                  </Col2of4>
                  <Col1of4>
                    <BoTextField name="state" placeholder="TX" type="text">
                      State
                    </BoTextField>
                  </Col1of4>
                  <Col1of4>
                    <BoTextField
                      name="zipCode"
                      placeholder=""
                      type="text"
                      disabled={mailingSameAsServiceChecked}
                    >
                      Zip Code
                    </BoTextField>
                  </Col1of4>
                </InputsRow>
              </BoDialogBody>
              <BoDialogButtonFooter
                cancelDisabled={requestMonitor.isPending}
                confirmDisabled={
                  requestMonitor.isPending || hasValidationErrors || !dirty
                }
                confirmBtnText="Update"
                confirmBtnType="submit"
                cancelBtnText="Cancel"
                onCancel={close}
              />
            </StyledForm>
          );
        }}
      />
    </BoModal>
  );
};
