import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { OktaGroups } from "@common/constants/okta.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useOktaAuth } from "@okta/okta-react";
import { useContext, useEffect } from "react";

export type OktaGetUserResponse = {
  email: string;
  emailVerified: boolean;
  groups: OktaGroups[];
  sub: string;
};

export const useAuthenticatedUserGroups = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { setGroups } = useContext(AuthGroupsContext);
  const [ajaxState, { setSuccess, setPending, setFailure }] =
    useAjaxState<OktaGroups[]>();
  const handleReceivedGroups = (groups: OktaGroups[]) => {
    setSuccess(groups);
    setGroups(groups);
  };

  const isAuthenticated = authState?.isAuthenticated || false;

  useEffect(() => {
    if (isAuthenticated) {
      setPending();
      oktaAuth
        .getUser()
        .then((user) => {
          let effectiveGroups: Set<OktaGroups> = new Set<OktaGroups>();

          if ("groups" in user) {
            effectiveGroups = new Set(user.groups as OktaGroups[]);
            if (effectiveGroups.has(OktaGroups.Ops)) {
              effectiveGroups.add(OktaGroups.CSRTier3);
            }
            if (effectiveGroups.has(OktaGroups.CSRTier3)) {
              effectiveGroups.add(OktaGroups.CSRTier2);
            }
            if (effectiveGroups.has(OktaGroups.CSRTier2)) {
              effectiveGroups.add(OktaGroups.CSRs);
            }
          }

          handleReceivedGroups(Array.from(effectiveGroups));
        })
        .catch(setFailure);
    } else {
      handleReceivedGroups([]);
    }
  }, [isAuthenticated]);

  return ajaxState;
};
