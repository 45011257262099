import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledButtonContainer = styled.div``;
export const StyledAmount = styled(Body2).attrs({ as: "div" })``;
export const StyledAmountInputWrapper = styled.div`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;
export const StyledFields = styled.div`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
