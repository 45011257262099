import { EM_DASH, EN_DASH } from "@common/constants/characters.constant";
import {
  MONTH_DAY_SHORT_YEAR_SLASH,
  MONTH_DAY_YEAR_SLASH,
  SHORT_DATE_FORMAT,
  SHORT_MONTH_NAME_DAY_YEAR,
} from "@common/constants/date.constant";
import { KILOWATT_HOURS_ABBREV } from "@common/constants/other.constant";
import { TWELVE_HOUR_MINUTE_AM_PM } from "@common/constants/time.constants";
import { AddressFormType } from "@common/types/customerTypes";
import { capitalizeFirstLetterOfWords } from "@common/utils/stringFormatters";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import React from "react";
import styled from "styled-components";

dayjs.extend(utc);
// plugin to allow strict date parsing
dayjs.extend(customParseFormat);

export const validDayjs = (
  date: string | undefined | null,
  format?: string,
  strict?: boolean
) => {
  if (
    !(date === undefined || date === null) &&
    dayjs(date, format, strict).isValid()
  ) {
    return dayjs(date);
  }

  return {
    format: (template: string) => "",
  };
};

export const formatPhoneNumber = (digits?: string | null) => {
  if (!digits) {
    return EM_DASH;
  }
  const digitsWithoutSymbols = digits.replace(/\D/g, "");
  const phoneString =
    digitsWithoutSymbols?.length === 11
      ? digitsWithoutSymbols.slice(1)
      : digitsWithoutSymbols;
  const areaCode = phoneString.slice(0, 3);
  const prefix = phoneString.slice(3, 6);
  const suffix = phoneString.slice(6, 10);

  return `(${areaCode}) ${prefix}-${suffix}`;
};
export const formatDollarsToCents = (
  priceInDollars: string | number | null,
  includeSymbol: boolean | undefined = true,
  fractionDigits = 1,
  nanValue = "-"
): string => {
  const parsedPrice = parseFloat((priceInDollars as string) ?? "0");

  if (!Number.isFinite(parsedPrice)) {
    return nanValue;
  }
  const cents = (parsedPrice * 100).toFixed(fractionDigits);

  return includeSymbol ? `${cents}¢` : cents;
};

export const formatToCents = (
  price: string | number | null,
  includeSymbol: boolean | undefined = true,
  fractionDigits = 1,
  nanValue = "-"
): string => {
  const parsedPrice = parseFloat((price as string) ?? "0");

  if (!Number.isFinite(parsedPrice)) {
    return nanValue;
  }
  const cents = parsedPrice.toFixed(fractionDigits);

  return includeSymbol ? `${cents}¢` : cents;
};

export const formatCurrency = (
  value: number | string,
  fractionDigits: 0 | 2 | 3 = 2,
  nanValue = "-"
): string => {
  const parsedValue = typeof value === "string" ? parseFloat(value) : value;

  if (!Number.isFinite(parsedValue)) {
    return nanValue;
  }
  const currencySymbol = parsedValue < 0 ? "-$" : "$";
  const finalValue = Math.abs(parsedValue).toFixed(fractionDigits);

  return `${currencySymbol}${finalValue}`;
};

export const formatKwh = (value: number, fractionDigits: 0 | 2 = 2): string => {
  const finalValue = value.toFixed(fractionDigits);

  return `${finalValue} ${KILOWATT_HOURS_ABBREV}`;
};

export const separateNumber = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const separateWordByUppercase = (word: string) => {
  // Add space before each upper case, except for acronyms
  return word.replace(/([A-Z]+)/g, " $1").trim();
};

export const cityStateZip = ({ city, state, zipCode }: AddressFormType) =>
  `${capitalizeFirstLetterOfWords(city)}, ${state.toUpperCase()} ${zipCode}`;

/**
 * Date Formatters
 */

export const formatShorthandMonthDay = (date: string) => {
  return validDayjs(date).format("MMM D");
};

export const formatShorthandMonthDayYear = (date: string) => {
  return validDayjs(date).format(SHORT_MONTH_NAME_DAY_YEAR);
};

export const formatAsLocalTime = (timeStamp: string) => {
  return dayjs(timeStamp).local().format(TWELVE_HOUR_MINUTE_AM_PM);
};

export const formatMonthDayShortYear = (
  value: string,
  { leadingZeros = true }: { leadingZeros?: boolean } = {}
): string => {
  return validDayjs(value).format(
    leadingZeros ? MONTH_DAY_SHORT_YEAR_SLASH : SHORT_DATE_FORMAT
  );
};

export const formatMonthDayYear = (date: string | null | undefined) =>
  validDayjs(date).format(MONTH_DAY_YEAR_SLASH);

export const formatDateRange = (startDate: string, endDate: string): string => {
  return `${formatMonthDayShortYear(startDate, {
    leadingZeros: false,
  })}${EN_DASH}${formatMonthDayShortYear(endDate, {
    leadingZeros: false,
  })}`;
};

interface FormatPercentageProps {
  decimals?: number;
  value: number | string | null;
}

export const formatPercentage = ({
  value,
  decimals = 0,
}: FormatPercentageProps): string => {
  if (!value || !Number(value)) {
    return "";
  }

  const num = Number(value) / 100;

  return num.toLocaleString("en", {
    minimumFractionDigits: decimals,
    style: "percent",
  });
};

// eslint-disable-next-line react-refresh/only-export-components
const DescriptionBoldLine = styled.div`
  font-weight: 700;

  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

export const formatTimeOfUseScheduleDescription = (input: string) => {
  const elements = input.split("\n").map((line, index) => {
    // Check if the first character of the line is not a number
    if (line.length === 0) {
      // eslint-disable-next-line react/no-array-index-key
      return null;
    } else if (isNaN(parseInt(line.charAt(0), 10))) {
      // If the line doesn't start with a number, bold it
      // eslint-disable-next-line react/no-array-index-key
      return <DescriptionBoldLine key={index}>{line}</DescriptionBoldLine>;
    } else {
      // If the line starts with a number, return it without bold styling
      // eslint-disable-next-line react/no-array-index-key
      return <div key={index}>{line}</div>;
    }
  });

  return <>{elements}</>;
};
