import { ediApi } from "@common/api/ediApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { Meter } from "@common/models/Meter.model";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoTextField,
  BoTextFieldProps,
} from "@ops-design-system/components/BoTextField/BoTextField";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { ValidationButtonContainer } from "@ops/components/MeterSearchForms/MeterSearchForms.styled";
import { ValidatedAddressType } from "@ops/components/MeterSearchForms/useMeterSearchForms";
import React, { useState } from "react";
import { Form } from "react-final-form";

interface EsiIdFormValues {
  esiId: string;
}

export interface EsiIdFormProps {
  align?: "left" | "right";
  disabled?: boolean;
  meter?: Meter | null;
  onSubmit: (response?: ValidatedAddressType, error?: string) => void;
}

const formValidators = generateValidationErrorCollector<EsiIdFormValues>({
  esiId: [opsAndPricingIsRequired],
});

export const EsiIdForm = ({
  onSubmit,
  disabled,
  meter,
  align = "right",
}: EsiIdFormProps) => {
  const [validating, setValidating] = useState<boolean>(false);
  const [initialValues] = useState<EsiIdFormValues>({ esiId: "" });

  const onFormSubmit = async (EsiIdFormValues: EsiIdFormValues) => {
    const esiId = EsiIdFormValues.esiId.trim();

    if (meter?.esiId === esiId) {
      onSubmit(undefined, "New ESIID must be different than current ESIID.");
      return;
    }

    setValidating(true);

    const [error, response] = await handleAjaxCall(
      ediApi.meterAvailability({ esiId })
    );
    const { serviceAddress, dunsNumber, utilityName } = response || {};

    setValidating(false);

    if (error || !serviceAddress) {
      onSubmit(undefined, "No meter found. Please try again.");
      return;
    }

    onSubmit({
      dunsNumber,
      esiId,
      serviceAddress,
      utilityName,
    });
  };

  const sharedFieldProps: Partial<BoTextFieldProps<string>> = {
    disabled,
    hideErrorMessage: true,
    placeholder: "",
    type: "text",
  };

  return (
    <Form<EsiIdFormValues>
      validate={formValidators}
      initialValues={initialValues}
      validateOnChange
      onSubmit={onFormSubmit}
      render={({ hasValidationErrors, handleSubmit }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <FormRow>
              <BoTextField {...sharedFieldProps} name="esiId">
                ESIID
              </BoTextField>
            </FormRow>
            <ValidationButtonContainer $align={align}>
              <BoButton
                type="submit"
                variant="secondary"
                disabled={disabled || hasValidationErrors}
                loading={validating}
              >
                Validate ESIID
              </BoButton>
            </ValidationButtonContainer>
          </form>
        );
      }}
    />
  );
};
