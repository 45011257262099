import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { EnergyUsageChartHandler } from "@ops/components/EnergyUsageChartHandler/EnergyUsageChartHandler";
import { UsageHistoryTable } from "@ops/components/UsageHistoryTable/UsageHistoryTable";
import React from "react";

export const UsageTab = () => {
  return (
    <BoGrid $variant={GridVariants.Grid1}>
      <BoGridColumn>
        <EnergyUsageChartHandler />
        <UsageHistoryTable />
      </BoGridColumn>
    </BoGrid>
  );
};
