import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { MaterialDesignContent } from "notistack";
import styled from "styled-components";

export const StyledToast = styled(MaterialDesignContent)`
  &.notistack-MuiContent-success {
    background-color: ${CSSVariables.COLOR_SUCCESS_MAIN};
    font-size: 16px;
    font-weight: ${FontWeight.Medium};
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  &.notistack-MuiContent-error {
    background-color: ${CSSVariables.COLOR_ERROR_MAIN};
    font-size: 16px;
    font-weight: ${FontWeight.Medium};
    justify-content: center;
    text-align: center;
    width: 100%;
  }
`;
