import {
  BoButton,
  BoButtonSizes,
  BoButtonVariants,
} from "@ops-design-system/components/BoButton/BoButton";
import { BoDialog } from "@ops-design-system/components/BoDialog/BoDialog";
import React, { FC, useState } from "react";

export const useModalState = (initialState = false) => {
  const [isOpen, setOpen] = useState<boolean>(initialState);
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return { close, isOpen, open };
};

interface BoButtonWithConfirmDialogProps {
  buttonContent: string | JSX.Element;
  buttonFullWidth?: boolean;
  cancelBtnText?: string;
  confirmBtnText?: string;
  disabled?: boolean;
  handleConfirm: () => void;
  header: string;
  icon?: FC<React.SVGProps<SVGSVGElement>>;
  message: string | JSX.Element;
  size?: BoButtonSizes;
  variant?: BoButtonVariants;
}

export const BoButtonWithConfirmDialog: FC<
  React.PropsWithChildren<BoButtonWithConfirmDialogProps>
> = ({
  buttonContent,
  header,
  icon,
  message,
  confirmBtnText,
  cancelBtnText,
  handleConfirm,
  variant,
  size,
  buttonFullWidth = true,
  disabled = false,
}) => {
  const { open, close, isOpen } = useModalState(false);
  const onConfirm = () => {
    handleConfirm();
    close();
  };

  const iconProp = icon ? { icon } : {};

  return (
    <>
      <BoButton
        variant={variant ?? "secondary"}
        size={size}
        onClick={open}
        fullWidth={buttonFullWidth}
        disabled={disabled}
        {...iconProp}
      >
        {buttonContent}
      </BoButton>
      <BoDialog
        open={isOpen}
        header={header}
        onConfirm={onConfirm}
        onClose={close}
        confirmBtnText={confirmBtnText ?? "Confirm"}
        cancelBtnText={cancelBtnText ?? "Cancel"}
      >
        {message}
      </BoDialog>
    </>
  );
};
