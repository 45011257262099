import { IdType } from "@common/types/apiTypes";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { AddBankAccountButton } from "@ops/components/AddBankAccount/AddBankAccountButton";
import { AddCreditCardButton } from "@ops/components/AddCreditCardForm/AddCreditCardButton";
import {
  StyledNoPaymentMethodBody,
  StyledPaymentButtonsWrapper,
} from "@ops/components/MakeAPayment/MakeAPayment.styled";
import { useMakeAPaymentStep } from "@ops/components/MakeAPayment/useMakeAPaymentStep";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";

interface NoPaymentMethodModalContentProps {
  closeModal: () => void;
  premiseId: IdType;
}

export const NoPaymentMethodModalContent = ({
  closeModal,
  premiseId,
}: NoPaymentMethodModalContentProps) => {
  const { premise } = usePremise(premiseId);

  const { setMakeAPaymentStep } = useMakeAPaymentStep(premiseId);

  const showAddAch = !premise?.isNoCheckOrAch;

  const showAddCredit = !premise?.isNoCreditOrDebitCard;

  const showUnableToCollect =
    premise?.isNoCheckOrAch && premise?.isNoCreditOrDebitCard;

  return (
    <>
      <BoDialogHeader>Make a Payment</BoDialogHeader>
      <BoDialogBody>
        {showUnableToCollect ? (
          <Subtitle2>
            Credit & Debit Cards and ACH payments have been disbled for this
            premise. Please use other payment method.
          </Subtitle2>
        ) : (
          <StyledNoPaymentMethodBody>
            <Subtitle2>
              To make a payment, first add a payment method.
            </Subtitle2>
            <StyledPaymentButtonsWrapper>
              {showAddCredit && (
                <AddCreditCardButton
                  onClick={() =>
                    setMakeAPaymentStep({ step: "addACreditCard" })
                  }
                />
              )}
              {showAddAch && (
                <AddBankAccountButton
                  onClick={() =>
                    setMakeAPaymentStep({ step: "addBankAccount" })
                  }
                />
              )}
            </StyledPaymentButtonsWrapper>
          </StyledNoPaymentMethodBody>
        )}
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText="Make Payment"
        cancelBtnText="Cancel"
        confirmDisabled
        onCancel={closeModal}
      />
    </>
  );
};
