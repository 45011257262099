import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { pricingApi } from "@ops-data/api/pricingApi";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface OfferCardRadioGroupProps {
  initialSelectedOfferSnapshotId?: IdType | null;
  offerSnapshotListSearchParams: {
    dunsNumber: string;
    rcid?: string | null;
  };
  onOfferSnapshotSelect?: (offerSnapshot: OfferSnapshot) => void;
}

const Cards = styled(FormFlexRow)`
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 0;
  & > * {
    margin: 1em;
  }
`;

export const OfferCardRadioGroup = ({
  onOfferSnapshotSelect = (_: OfferSnapshot) => {},
  offerSnapshotListSearchParams: { dunsNumber, rcid },
  initialSelectedOfferSnapshotId = null,
}: OfferCardRadioGroupProps) => {
  const flash = useRhFlash();
  const [
    { requestMonitor, data: offerSnapshots, error },
    { setFailure, setSuccess, setPending },
  ] = useAjaxState<OfferSnapshot[]>();
  const [selectedOfferSnapshotId, setSelectedOfferSnapshotId] =
    useState<IdType | null>(initialSelectedOfferSnapshotId);

  useEffect(() => {
    if (dunsNumber.length <= 0) {
      // eslint-disable-next-line no-console
      console.warn(
        "Unable to get offers in OfferCardRadioGroup because dunsNumber is empty.",
        dunsNumber
      );
      return;
    }

    setPending();

    pricingApi.offerSnapshots
      .search(dunsNumber, rcid ?? "")
      .then(({ offerSnapshots: resp }) => {
        const data = resp.map(
          (offerSnapshotData) => new OfferSnapshot(offerSnapshotData)
        );
        const selectedOfferSnapshot = data.find(
          (offerSnapshot) => offerSnapshot.id === initialSelectedOfferSnapshotId
        );

        if (selectedOfferSnapshot) {
          onOfferSnapshotSelect(selectedOfferSnapshot);
        }

        setSuccess(data);
      })
      .catch(setFailure);
  }, [dunsNumber, rcid]);

  if (error) {
    flash.error(
      "An error occurred. Unable to get offers for this service address."
    );
  }

  const handleSelect = (offerSnapshot: OfferSnapshot) => {
    setSelectedOfferSnapshotId(offerSnapshot.id);
    onOfferSnapshotSelect(offerSnapshot);
  };

  return (
    <Cards>
      {requestMonitor.isPending ? (
        <BoCircularProgress />
      ) : (
        (offerSnapshots ?? []).map((offerSnapshot) => (
          <OfferCard
            key={offerSnapshot.id}
            offerSnapshot={offerSnapshot}
            onSelect={handleSelect}
            active={offerSnapshot.id === selectedOfferSnapshotId}
            name="offerChooser"
          />
        ))
      )}
    </Cards>
  );
};
