import { formatCurrency } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ReleaseDepositData extends EventLogBaseData {
  amount: string;
  interestAmount: string;
  refundAmount: string;
  refundPayment: string;
}

export class ReleaseDeposit extends EventLog {
  public readonly amount: string;
  public readonly interestAmount: string;
  public readonly refundAmount: string;
  public readonly refundPayment: string;

  constructor({
    amount,
    interestAmount,
    refundAmount,
    refundPayment,
    ...baseEventData
  }: ReleaseDepositData) {
    super({
      ...baseEventData,
      eventText: "Release Deposit",
    });

    this.amount = amount;
    this.interestAmount = interestAmount
      ? `- Deposit Interest Amount: ${formatCurrency(interestAmount)}`
      : "";
    this.refundAmount = refundAmount
      ? `- Refund Amount: ${formatCurrency(refundAmount)}`
      : "";
    this.refundPayment = refundPayment;
  }

  override get body() {
    return `Released Deposit Amount: ${formatCurrency(this.amount)}
    ${this.interestAmount}
    - Requested Refund: ${this.refundPayment} ${this.refundAmount}
     `;
  }
}
