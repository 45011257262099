import { EdiActions } from "@common/constants/edi.constants";
import { IdType } from "@common/types/apiTypes";
import { ReactComponent as LockedIcon } from "@ops-design-system/icons/LockedIcon.svg";
import { EdiActionTriggerWithConfirmation } from "@ops/components/EdiActionTriggerWithConfirmation/EdiActionTriggerWithConfirmation";
import React from "react";

interface TriggerSwitchHoldProps {
  customerId: IdType;
}

export const TriggerSwitchHold = ({ customerId }: TriggerSwitchHoldProps) => {
  return (
    <EdiActionTriggerWithConfirmation
      action={EdiActions.SWITCH_HOLD_CREATE}
      buttonIcon={LockedIcon}
      confirmBtnText="Trigger Switch Hold"
      confirmMessage="Are you sure you want to trigger a switch hold?"
      customerId={customerId}
      errorMessage="There was a problem sending the switch hold.  Please try again."
      header="Trigger Switch Hold"
      triggerOpenDialogBtnText="Trigger Switch Hold"
    />
  );
};
