import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as FlyingDollarSign } from "@ops-design-system/icons/FlyingDollarSign.svg";
import { AddBankAccountForm } from "@ops/components/AddBankAccount/AddBankAccountForm";
import { AddACreditCardForm } from "@ops/components/AddCreditCardForm/AddACreditCardForm";
import { LoadingModalContent } from "@ops/components/LoadingModalContent/LoadingModalContent";
import { makeAPaymentStepAtom } from "@ops/components/MakeAPayment/MakeAPayment.atoms";
import { MakeAPaymentStepType } from "@ops/components/MakeAPayment/MakeAPayment.types";
import { MakeAPaymentForm } from "@ops/components/MakeAPayment/MakeAPaymentForm";
import { MakeAPaymentSuccess } from "@ops/components/MakeAPayment/MakeAPaymentSuccess";
import { NoPaymentMethodModalContent } from "@ops/components/MakeAPayment/NoPaymentMethodModalContent";
import { useMakeAPaymentStep } from "@ops/components/MakeAPayment/useMakeAPaymentStep";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { AccountSummary } from "@ops/models/AccountSummary.model";
import { paymentMethodsFetch } from "@ops/slices/paymentMethodsSlice";
import { useAtomValue } from "jotai";
import React from "react";
import { useDispatch } from "react-redux";

interface MakeAPaymentProps {
  accountSummary: AccountSummary;
  includeButtonIcon?: boolean;
  premiseId: IdType;
}

export const MakeAPayment = ({
  accountSummary,
  premiseId,
  includeButtonIcon,
}: MakeAPaymentProps) => {
  const { open: modalOpen, close: modalClose, isOpen } = useModalState();
  const { paymentMethods } = usePaymentMethods(premiseId);

  const activeMakeAPaymentStep = useAtomValue(makeAPaymentStepAtom);

  const { resetMakeAPaymentAtoms, setMakeAPaymentStep } =
    useMakeAPaymentStep(premiseId);

  const open = () => {
    if (paymentMethods) {
      setMakeAPaymentStep({ step: "initial" });
    }
    modalOpen();
  };

  const close = () => {
    resetMakeAPaymentAtoms();

    modalClose();
  };

  const dispatch = useDispatch();

  const handleAddedCard = () => {
    dispatch(paymentMethodsFetch(premiseId));
    setMakeAPaymentStep({ step: "initial" });
  };

  const makeAPaymentSteps: Record<MakeAPaymentStepType, () => JSX.Element> = {
    addACreditCard: () => (
      <AddACreditCardForm
        premiseId={premiseId}
        onCancel={() => setMakeAPaymentStep({ step: "initial" })}
        onSuccess={handleAddedCard}
        setAsDefault={(paymentMethods?.length ?? 0) === 0}
      />
    ),
    addBankAccount: () => (
      <AddBankAccountForm
        premiseId={premiseId}
        onCancel={() => setMakeAPaymentStep({ step: "initial" })}
        onSuccess={handleAddedCard}
        setAsDefault={(paymentMethods?.length ?? 0) === 0}
      />
    ),
    loading: () => (
      <LoadingModalContent
        headerText="Make a Payment"
        cancelBtnText="Cancel"
        confirmBtnText="Make Payment"
        confirmBtnType="submit"
      />
    ),
    makeAPaymentForm: () => (
      <MakeAPaymentForm
        accountSummary={accountSummary}
        premiseId={premiseId}
        paymentMethods={paymentMethods}
        closeModal={close}
      />
    ),
    noPaymentOptions: () => (
      <NoPaymentMethodModalContent premiseId={premiseId} closeModal={close} />
    ),
    paymentSuccess: () => <MakeAPaymentSuccess closeModal={close} />,
  };

  return (
    <>
      <BoButton
        fullWidth
        onClick={open}
        variant="secondary"
        icon={includeButtonIcon ? FlyingDollarSign : undefined}
        size="extraSmall"
      >
        Make A Payment
      </BoButton>
      <BoModal open={isOpen} onClose={close}>
        {makeAPaymentSteps[activeMakeAPaymentStep]()}
      </BoModal>
    </>
  );
};
