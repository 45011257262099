import React, { PropsWithChildren, createContext, useState } from "react";

interface DownPaymentCalculatorStateType {
  downPayment: number;
  downPaymentPercent: number;
  totalAmountDue: number;
}

export interface DownPaymentCalculatorStateProviderContext
  extends DownPaymentCalculatorStateType {
  resetCalculatorState: () => void;
  updateCalculatorState: (
    content: Partial<DownPaymentCalculatorStateProviderContext>
  ) => void;
}

export const initialDownPaymentCalculatorState = {
  downPayment: 0,
  downPaymentPercent: 50,
  totalAmountDue: 0,
};

export const DownPaymentCalculatorStateContext =
  createContext<DownPaymentCalculatorStateProviderContext>({
    downPayment: 0,
    downPaymentPercent: 50,
    resetCalculatorState: () => {},
    totalAmountDue: 0,
    updateCalculatorState: (content) => {},
  });

interface DownPaymentCalculatorStateProviderProps {}
export const DownPaymentCalculatorStateProvider = ({
  children,
}: PropsWithChildren<DownPaymentCalculatorStateProviderProps>) => {
  const [calculatorState, setCalculatorState] =
    useState<DownPaymentCalculatorStateType>(initialDownPaymentCalculatorState);

  const updateCalculatorState = (
    content: Partial<DownPaymentCalculatorStateType>
  ) => {
    setCalculatorState({ ...calculatorState, ...content });
  };

  const resetCalculatorState = () => {
    updateCalculatorState({ ...initialDownPaymentCalculatorState });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: DownPaymentCalculatorStateProviderContext = {
    downPayment: calculatorState.downPayment,
    downPaymentPercent: calculatorState.downPaymentPercent,
    resetCalculatorState,
    totalAmountDue: calculatorState.totalAmountDue,
    updateCalculatorState,
  };

  return (
    <DownPaymentCalculatorStateContext.Provider value={actions}>
      {children}
    </DownPaymentCalculatorStateContext.Provider>
  );
};
