import { externalPaymentsUrls } from "@common/api/externalPaymentsUrls";
import { TXT_MIME_TYPE } from "@common/constants/other.constant";
import { ExternalPaymentFile } from "@common/models/ExternalPayment.model";
import { authenticatedFileDownload } from "@common/utils/authenticatedFileDownload";

export const buildUnmatchedPaymentFilename = (
  externalPaymentFile: ExternalPaymentFile
) => {
  const fileSuffix = externalPaymentFile.fileName.substring(
    0,
    externalPaymentFile.fileName.lastIndexOf(".")
  );

  return `${fileSuffix} - Ironhide.txt`;
};

export const unmatchedPaymentDownload = (
  externalPaymentFile: ExternalPaymentFile
) => {
  const url = externalPaymentsUrls.files.unmatchedDownload(
    externalPaymentFile.id
  );

  const filename = buildUnmatchedPaymentFilename(externalPaymentFile);

  return authenticatedFileDownload(url, filename, TXT_MIME_TYPE);
};
