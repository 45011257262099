import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import {
  OfferSnapshotSearchResponseType,
  OfferSnapshotType,
} from "@ops-utils/types/offerSnapshotTypes";

const BASE_PATH = "/api/pricing";

export const pricingApi = {
  offerSnapshots: {
    eflUrl: (id: IdType) => {
      return `${BASE_PATH}/offer-snapshots/${id}/efl/?locale=en`;
    },
    retrieve: async (id: IdType) => {
      const offerSnapshot = await ajax.get<OfferSnapshotType>(
        `${BASE_PATH}/offer-snapshots/${id}`
      );

      return new OfferSnapshot(offerSnapshot);
    },
    search: <R = OfferSnapshotSearchResponseType>(
      dunsNumber: string,
      campaignSlug: string | null,
      prospectId?: IdType | null
    ): Promise<R> => {
      return ajax.get(`${BASE_PATH}/offer-snapshots/search`, {
        params: { campaignSlug, dunsNumber, prospectId },
      });
    },
  },
};
