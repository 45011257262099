import {
  AddPremiseWizardContext,
  AddPremiseWizardContextType,
} from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";
import { useContext } from "react";

export function useAddPremiseWizardContext(): AddPremiseWizardContextType {
  const context = useContext<AddPremiseWizardContextType | null>(
    AddPremiseWizardContext
  );

  if (context === null) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }
  return context;
}
