import { AcquisitionCampaignForm } from "@ops/components/ProspectEditor/MarketingTab/AcquisitionCampaignForm";
import { StyledTab } from "@ops/components/ProspectEditor/shared/ProspectEditor.styled";
import React from "react";

export const MarketingTab = () => {
  return (
    <StyledTab>
      <AcquisitionCampaignForm />
    </StyledTab>
  );
};
