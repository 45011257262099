import { FontWeight } from "@design-system/enums/fontWeight.enum";

export const makeFontFamily = (
  fontUrl: string,
  fontFamily: string,
  fontWeight: FontWeight,
  fontStyle: "normal" | "italic" = "normal"
) => ({
  fontDisplay: "swap",
  fontFamily,
  fontStyle,
  fontWeight,
  src: `url(${fontUrl})`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
});
