import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as DoubleDollarSign } from "@ops-design-system/icons/DoubleDollarSign.svg";
import { AddBankAccountForm } from "@ops/components/AddBankAccount/AddBankAccountForm";
import { AddACreditCardForm } from "@ops/components/AddCreditCardForm/AddACreditCardForm";
import { DeletePaymentMethodConfirmDialog } from "@ops/components/ManagePaymentMethods/DeletePaymentMethodConfirmDialog";
import {
  ManagePaymentMethodsContextProvider,
  PaymentMode,
} from "@ops/components/ManagePaymentMethods/ManagePaymentMethodsContextProvider";
import { ManagePaymentMethodsDialog } from "@ops/components/ManagePaymentMethods/ManagePaymentMethodsDialog";
import { usePaymentMethodContext } from "@ops/hooks/useManagePaymentContextHook";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { paymentMethodsFetch } from "@ops/slices/paymentMethodsSlice";
import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";

interface ManagePaymentMethodsProps {
  premise: Premise;
}

const ModalContent = () => {
  const dispatch = useDispatch();

  const { mode, premiseId, setContextMode } = usePaymentMethodContext();
  const { paymentMethods } = usePaymentMethods(premiseId);

  const managePaymentMethodsContents: Record<PaymentMode, () => ReactElement> =
    {
      [PaymentMode.listMode]: () => <ManagePaymentMethodsDialog />,
      [PaymentMode.deleteMode]: () => <DeletePaymentMethodConfirmDialog />,
      [PaymentMode.addCardMode]: () => (
        <AddACreditCardForm
          setAsDefault={(paymentMethods?.length ?? 0) === 0}
          premiseId={premiseId}
          onCancel={() => setContextMode(PaymentMode.listMode)}
          onSuccess={() => {
            setContextMode(PaymentMode.listMode);
            dispatch(paymentMethodsFetch(premiseId));
          }}
        />
      ),
      [PaymentMode.addBankAccountMode]: () => (
        <AddBankAccountForm
          setAsDefault={(paymentMethods?.length ?? 0) === 0}
          premiseId={premiseId}
          onCancel={() => setContextMode(PaymentMode.listMode)}
          onSuccess={() => {
            setContextMode(PaymentMode.listMode);
            dispatch(paymentMethodsFetch(premiseId));
          }}
        />
      ),
    };

  const content = managePaymentMethodsContents[mode]();

  return content;
};

export const ManagePaymentMethods = ({
  premise,
}: ManagePaymentMethodsProps) => {
  const { open, close, isOpen } = useModalState();

  return (
    <>
      <BoButton
        fullWidth
        variant="secondary"
        onClick={open}
        icon={DoubleDollarSign}
        size="extraSmall"
      >
        Manage Payment
      </BoButton>
      <BoModal open={isOpen} onClose={close}>
        <ManagePaymentMethodsContextProvider
          premiseId={premise.id}
          closeModal={close}
        >
          <ModalContent />
        </ManagePaymentMethodsContextProvider>
      </BoModal>
    </>
  );
};
