import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { formatCurrency } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhapsodyProspectApi } from "@ops-data/api/rhapsodyProspectApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoRadioInput } from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { Prospect } from "@ops-utils/models/Prospect.model";
import { ProspectCreditCheckRequestType } from "@ops-utils/types/prospectTypes";
import {
  WarningCard,
  WarningMessage,
} from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoStyledCards";
import { CreditCheckCardProps } from "@ops/components/ProspectEditor/BillingInfoTab/CreditCheckCard/CreditCheckCard";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectInState } from "@ops/hooks/useProspectInState";
import React, { useEffect } from "react";
import styled from "styled-components";

const CreditCheckWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr fit-content(1px);
`;

const DepositSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(7)};
`;

interface WarningMessageProps extends DepositRequiredCardProps {
  handleRefetch: () => void;
  prospect: Prospect;
}

const WarningMessageWithDepositAlternative = ({
  prospect,
  handleRefetch,
  onChange,
  formValues,
}: WarningMessageProps) => {
  return (
    <WarningCard>
      <WarningMessage>Deposit is required.</WarningMessage>
      <BoDivider />

      <DepositSelectionContainer>
        <BoRadioInput
          label={`${formatCurrency(
            prospect.depositAmount ?? "0"
          )} Refundable Deposit`}
          name="depositSelection"
          value="deposit"
          checked={!formValues.depositAlternativeElected}
          onClick={() => onChange({ depositAlternativeElected: false })}
        />
        <BoRadioInput
          label={`${formatCurrency(
            prospect.depositAlternativeAmount ?? "0"
          )} Deposit Alternative Fee (non-refundable)`}
          name="depositSelection"
          value="depositAlternative"
          checked={formValues.depositAlternativeElected}
          onClick={() => onChange({ depositAlternativeElected: true })}
        />
      </DepositSelectionContainer>

      <BoButton variant="secondary" onClick={handleRefetch}>
        Refetch Credit Status
      </BoButton>
    </WarningCard>
  );
};

const WarningMessageWithoutDepositAlternative = ({
  prospect,
  handleRefetch,
  onChange,
  formValues,
}: WarningMessageProps) => {
  useEffect(() => {
    // Failsafe in the case of rechecking credit and landing here after having a deposit alternative has been selected
    if (formValues.depositAlternativeElected) {
      onChange({
        depositAlternativeElected: false,
      });
    }
  }, [formValues.depositAlternativeElected, onChange]);

  return (
    <WarningCard>
      <CreditCheckWrapper>
        <WarningMessage>
          {formatCurrency(prospect.depositAmount ?? 0)} deposit is required.
        </WarningMessage>
        <BoButton variant="secondary" onClick={handleRefetch}>
          Refetch Credit Status
        </BoButton>
      </CreditCheckWrapper>
    </WarningCard>
  );
};

interface DepositRequiredCardProps extends CreditCheckCardProps {}

export const DepositRequiredCard = ({
  formValues,
  onChange,
}: DepositRequiredCardProps) => {
  const { prospect: prospectState, updateProspectState } =
    useEnrollmentFlowContext();
  const prospect = new Prospect(prospectState);
  const { fetchProspect } = useProspectInState(prospect.id);
  const flash = useRhFlash();

  const { featureFlagClient } = useFeatureFlagClient();
  const { opsDepositAlternativeProgram } = featureFlagClient.getFlags([
    ["opsDepositAlternativeProgram", false],
  ]);

  const handleRefetch = () => {
    if (
      !prospectState.firstName ||
      !prospectState.lastName ||
      !prospectState.dateOfBirth ||
      !prospectState.phone ||
      !prospectState.email ||
      !prospectState.addressLine1 ||
      !prospectState.city ||
      !prospectState.state ||
      !prospectState.zipCode
    ) {
      flash.error("Needs more prospect data to run credit check");

      return;
    }

    const creditCheckData: ProspectCreditCheckRequestType = {
      acquisitionMedium: prospectState.acquisitionMedium,
      addressLine1: prospectState.addressLine1,
      city: prospectState.city,
      dateOfBirth: prospectState.dateOfBirth,
      email: prospectState.email,
      firstName: prospectState.firstName,
      lastName: prospectState.lastName,
      phone: prospectState.phone,
      prospectId: prospectState.id,
      state: prospectState.state,
      unitNumber: prospectState.unitNumber,
      zipCode: prospectState.zipCode,
    };

    rhapsodyProspectApi
      .creditScoreEvaluation(creditCheckData)
      .then(() => {
        fetchProspect()
          .then((prospectRefetchData) => {
            if (prospectRefetchData) {
              updateProspectState(prospectRefetchData);
            }
          })
          .catch(() => flash.error("Unable to gather updated prospect data"));
      })
      .catch(() => {
        flash.error("Unable to fetch credit check information");
      });
  };

  const showDap =
    opsDepositAlternativeProgram.value &&
    !["0", null].includes(prospect.depositAlternativeAmount);

  if (showDap) {
    return (
      <WarningMessageWithDepositAlternative
        prospect={prospect}
        handleRefetch={handleRefetch}
        formValues={formValues}
        onChange={onChange}
      />
    );
  }

  return (
    <WarningMessageWithoutDepositAlternative
      prospect={prospect}
      handleRefetch={handleRefetch}
      onChange={onChange}
      formValues={formValues}
    />
  );
};
