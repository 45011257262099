import { BoButtonWithConfirmDialog } from "@ops-design-system/components/BoButtonWithConfirmDialog/BoButtonWithConfirmDialog";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { H3, H4 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as WarningIcon } from "@ops-design-system/icons/WarningIcon.svg";
import { ReactComponent as XInCircle } from "@ops-design-system/icons/XInCircle.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

const StyledFlexBox = styled(BoFlexBox)`
  gap: ${rhOpsSpacingPx(1)};
  padding-right: ${rhOpsSpacingPx(5)};
`;

const ButtonContent = () => {
  return (
    <StyledFlexBox>
      <XInCircle />
      Opt Out
    </StyledFlexBox>
  );
};

const StyledH4 = styled(H4)`
  margin-top: ${rhOpsSpacingPx(4)};
  text-align: center;
`;

interface CancelDialogContentProps {
  productName: string;
}
const CancelDialogContent = ({ productName }: CancelDialogContentProps) => {
  return (
    <BoFlexBox $flexDirection="column" $alignItems="center">
      <WarningIcon />
      <StyledH4>
        Are you sure you want to opt the customer out of{" "}
        <H3 as="span">{productName}</H3>
      </StyledH4>
    </BoFlexBox>
  );
};

interface CancelProductContractDialogProps {
  onConfirm: () => void;
  productName: string;
}

export const CancelProductContractDialog = ({
  productName,
  onConfirm,
}: CancelProductContractDialogProps) => {
  return (
    <BoButtonWithConfirmDialog
      buttonContent={<ButtonContent />}
      message={<CancelDialogContent productName={productName} />}
      confirmBtnText="Confirm"
      cancelBtnText="Cancel"
      header="Opt Out"
      handleConfirm={onConfirm}
      buttonFullWidth={false}
    />
  );
};
