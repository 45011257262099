import { customerWatcher } from "@ops/sagas/customer.saga";
import { paymentMethodsWatcher } from "@ops/sagas/paymentMethods.saga";
import { premiseWatcher } from "@ops/sagas/premise.saga";
import { premiseStatusIndicatorsWatcher } from "@ops/sagas/statusIndicators.saga";
import { all } from "redux-saga/effects";

// Ops sagas registered here
const sagas = [
  customerWatcher(),
  premiseWatcher(),
  paymentMethodsWatcher(),
  premiseStatusIndicatorsWatcher(),
];

export function* rootSaga() {
  yield all(sagas);
}
