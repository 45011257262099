import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import { EventLogEntry } from "@ops/components/SupportLogs/EventLog/EventLogEntry/EventLogEntry";
import { generateJSXFromStringMatch } from "@ops/components/SupportLogs/SearchLog/SupportLogSearchEntry";
import { EventLog } from "@ops/models/EventLog/EventLog.model";
import { Searchable, safeIncludes } from "@ops/models/searchable";

interface EventLogSearchEntryProps {
  customer: Customer;
  eventLog: EventLog;
  index: number;
  premise: Premise;
  query?: string;
}

const getHeaderAndSubheader = (
  premiseId: string | null,
  customer: Customer,
  premise: Premise
): [string, string] => {
  let header = customer.id;
  let subheader = customer.fullName;

  if (premiseId === premise.id) {
    header = premise.billingAccountNumber;
    subheader = premise.serviceAddress?.line1 ?? subheader;
  }

  return [header, subheader];
};

export const EventLogSearchEntry = ({
  query,
  eventLog,
  premise,
  customer,
  index,
}: EventLogSearchEntryProps) => {
  const key = `${eventLog.receivedAt}__${index}`;

  const [rawHeader, rawSubheader] = getHeaderAndSubheader(
    eventLog.premiseId,
    customer,
    premise
  );

  const date = generateJSXFromStringMatch(eventLog.date ?? undefined, query);
  const time = generateJSXFromStringMatch(eventLog.time ?? undefined, query);
  const initiatedBy = generateJSXFromStringMatch(eventLog.initiatedBy, query);
  const body = generateJSXFromStringMatch(eventLog.body ?? undefined, query);
  const header = generateJSXFromStringMatch(rawHeader, query);
  const subheader = generateJSXFromStringMatch(rawSubheader, query);

  return EventLogEntry({
    body,
    date,
    header,
    initiatedBy,
    key,
    subheader,
    time,
  });
};

export class SearchableEventLog implements Searchable {
  public readonly eventLogModel: EventLog;
  public readonly premise: Premise;
  public readonly customer: Customer;

  constructor(eventLogModel: EventLog, premise: Premise, customer: Customer) {
    this.premise = premise;
    this.eventLogModel = eventLogModel;
    this.customer = customer;
  }

  matches(query: string): boolean {
    const { id, fullName } = this.customer;
    const { billingAccountNumber } = this.premise;
    const serviceAddress = this.premise.serviceAddress?.line1;

    return (
      this.eventLogModel.matches(query) ||
      safeIncludes(id, query) ||
      safeIncludes(fullName, query) ||
      safeIncludes(billingAccountNumber, query) ||
      safeIncludes(serviceAddress, query)
    );
  }
}
