import { useWindowSize } from "@common/hooks/useWindowSize";

export enum ScreenResolution {
  Desktop = "desktop",
  Laptop = "laptop",
  Phone = "phone",
  Tablet = "tablet",
  Unknown = "unknown",
}

export const useScreenResolution = () => {
  const { width } = useWindowSize();

  if (width <= 600) {
    return ScreenResolution.Phone;
  } else if (width > 600 && width < 1068) {
    return ScreenResolution.Tablet;
  } else if (width > 1068 && width < 1440) {
    return ScreenResolution.Laptop;
  } else if (width >= 1440) {
    return ScreenResolution.Desktop;
  }

  return ScreenResolution.Unknown;
};
