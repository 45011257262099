import { EM_DASH } from "@common/constants/characters.constant";
import { IdType } from "@common/types/apiTypes";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { DisplayAddress } from "@ops/components/DisplayAddress/DisplayAddress";
import { EditEnrollmentStatus } from "@ops/components/PremiseAddressCard/EditEnrollmentStatus/EditEnrollmentStatus";
import { EditEsiId } from "@ops/components/PremiseAddressCard/EditEsiId/EditEsiId";
import { EditMailingAddress } from "@ops/components/PremiseAddressCard/EditMailingAddress/EditMailingAddress";
import { usePremiseDepositAlternativeQuery } from "@ops/hooks/queries/usePremiseDepositAlternative.query";
import { usePremise } from "@ops/hooks/usePremise";
import React, { ComponentType, ReactElement } from "react";
import { useParams } from "react-router-dom";

const CardEntry: ComponentType<
  React.PropsWithChildren<{
    component?: ComponentType<React.PropsWithChildren<unknown>>;
    label: string | ReactElement;
  }>
> = ({ label, component: Component = BoInfoValue, children, ...props }) => (
  <BoInfoContainer {...props}>
    <BoInfoLabel>{label}</BoInfoLabel>
    <Component>
      {children && typeof children === "string"
        ? stringOrEmDash(children)
        : children}
      {!children && EM_DASH}
    </Component>
  </BoInfoContainer>
);

export const PremiseAddressCard = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise, error } = usePremise(premiseId);

  const depositAlternativeQuery = usePremiseDepositAlternativeQuery({
    premiseId,
  });

  if (!premise || error) {
    return null;
  }

  const serviceAddress = premise.serviceAddress ?? undefined;

  const mailingAddress = premise.mailingAddress
    ? premise.mailingAddress
    : serviceAddress;

  return (
    <BoCard>
      <BoGrid $variant={GridVariants.Grid1_1}>
        <CardEntry label="Service Address">
          <DisplayAddress address={serviceAddress} />
        </CardEntry>
        <CardEntry
          label={
            <>
              Mailing Address
              <EditMailingAddress premiseId={premise.id} />
            </>
          }
        >
          <DisplayAddress address={mailingAddress} />
        </CardEntry>
        <CardEntry
          label="Premise Type"
          data-testid="premiseAddressCard__premiseType"
        >
          {premise.meter?.premiseType}
        </CardEntry>
        <CardEntry label="Meter Cycle">
          {premise.meter?.meterReadCycle}
        </CardEntry>
        <CardEntry label="Utility">{premise.meter?.utilityName}</CardEntry>
        <CardEntry
          label="Meter Type"
          data-testid="premiseAddressCard__meterType"
        >
          {/* The actual value is the tdsp ams indicator, but confirmed that showing it as "meter type" is intended */}
          {premise.meter?.tdspAmsIndicator}
        </CardEntry>
        <CardEntry
          label={
            <>
              ESI-ID
              <AccessControl>
                <EditEsiId premiseId={premise.id} />
              </AccessControl>
            </>
          }
        >
          {premise.meter?.esiId}
        </CardEntry>
        <CardEntry label="Load Profile">{premise.meter?.loadProfile}</CardEntry>
        <CardEntry label="Enrollment Transaction Type">
          {premise.enrollmentType}
        </CardEntry>
        <CardEntry
          label={
            <>
              Enrollment Status
              <AccessControl>
                <EditEnrollmentStatus premise={premise} />
              </AccessControl>
            </>
          }
        >
          {premise.enrollmentStatus}
        </CardEntry>
        <CardEntry label="Service Start Date">
          {formatMonthDayYear(premise.confirmedStartDate ?? "")}
        </CardEntry>
        <CardEntry label="Service End Date">
          {formatMonthDayYear(premise.serviceEndDate ?? "")}
        </CardEntry>
        <CardEntry label="Service Requested Date">
          {formatMonthDayYear(premise.serviceRequestedDate ?? "")}
        </CardEntry>
        <CardEntry
          label="Deposit Alternative (DAP)"
          data-testid="premiseAddressCard__depositAlternative"
        >
          {
            // We don't want this to be blocking to the whole card so only check success here
            (depositAlternativeQuery.isSuccess &&
              formatCurrency(depositAlternativeQuery.data.amount)) ??
              EM_DASH
          }
        </CardEntry>
      </BoGrid>
    </BoCard>
  );
};
