/// <reference types="../../../@types/react-table-overrides.d.ts" />

import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoReactTableContainer,
  HiddenCaption,
  MaxWidthTableCell,
  NoResultsMessage,
  PageSizeLabel,
  PaginationContainer,
  SelectionCountContainer,
  StyledBoTable,
  StyledPageCount,
  StyledSortIconContainer,
  TableFooter,
} from "@ops-design-system/components/BoReactTable/BoReactTable.styled";
import { BoReactTableProps } from "@ops-design-system/components/BoReactTable/BoReactTable.types";
import {
  BoTableBody,
  BoTableCell,
  BoTableCellContentWidth,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as ArrowDownIcon } from "@ops-design-system/icons/ArrowDown.svg";
import {
  CoreOptions,
  PaginationOptions,
  PaginationTableState,
  RowSelectionOptions,
  RowSelectionTableState,
  SortingOptions,
  SortingTableState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useMemo } from "react";

export function BoReactTable<T extends object>(props: BoReactTableProps<T>) {
  const {
    columns,
    data,
    pagination,
    rowSelection,
    tableCaption,
    rowLabel,
    className,
    rowId,
    sorting,
  } = props;

  const getPaginationProps = () => {
    if (!pagination) {
      return {};
    }

    const { pageCount, setPagination } = props;

    return {
      manualPagination: true,
      onPaginationChange: setPagination,
      pageCount,
    } satisfies PaginationOptions;
  };

  const getPaginationState = () => {
    if (!pagination) {
      return {};
    }

    const { pageIndex, pageSize } = props;

    return {
      pagination: {
        pageIndex,
        pageSize,
      },
    } satisfies PaginationTableState;
  };

  const getRowSelectionProps = () => {
    if (!rowSelection) {
      return {};
    }

    const { setRowSelection } = props;

    return {
      enableRowSelection: true,
      getRowId: (row) => String(row[rowId]),
      onRowSelectionChange: setRowSelection,
    } satisfies RowSelectionOptions<T> & Partial<CoreOptions<T>>;
  };

  const getRowSelectionState = () => {
    if (!rowSelection) {
      return {};
    }

    const { rowSelectionState } = props;

    return { rowSelection: rowSelectionState } satisfies RowSelectionTableState;
  };

  const getSortingProps = () => {
    if (!sorting) {
      return {};
    }

    const { setSorting } = props;

    return {
      manualSorting: true,
      onSortingChange: setSorting,
    } satisfies SortingOptions<T>;
  };

  const getSortingState = () => {
    if (!sorting) {
      return {};
    }

    return { sorting } satisfies SortingTableState;
  };

  const table = useReactTable({
    columns: useMemo(() => columns, [columns]),
    data: useMemo(() => data, [data]),
    getCoreRowModel: getCoreRowModel(),
    getRowId: rowId ? (row) => String(row[rowId]) : undefined,
    ...(pagination && getPaginationProps()),

    ...(rowSelection && getRowSelectionProps()),
    ...(sorting && getSortingProps()),

    // Manually controlled state

    state: {
      ...(pagination && getPaginationState()),
      ...(rowSelection && getRowSelectionState()),
      ...(sorting && getSortingState()),
    },
  });

  const pageSizeOptions =
    pagination && props.pageSizeOptions
      ? props.pageSizeOptions
      : [10, 20, 30, 40, 50];

  return (
    <BoReactTableContainer className={className}>
      <StyledBoTable>
        <HiddenCaption>{tableCaption}</HiddenCaption>
        <BoTableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <BoTableRow key={headerGroup.id} aria-label="Header Row">
              {headerGroup.headers.map((header) => {
                return (
                  <BoTableHeadCell
                    key={header.id}
                    onClick={
                      header.column.columnDef.enableSorting && sorting
                        ? () =>
                            props.setSorting([
                              {
                                desc: !sorting[0]?.desc,
                                id: header.id,
                              },
                            ])
                        : undefined
                    }
                    $isClickable={header.column.columnDef.enableSorting}
                    $width={
                      header.getSize() !== 150 ? header.getSize() : undefined
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.columnDef.enableSorting &&
                      sorting &&
                      sorting[0]?.id === header.id && (
                        <StyledSortIconContainer $isDesc={sorting[0].desc}>
                          <ArrowDownIcon />
                        </StyledSortIconContainer>
                      )}
                  </BoTableHeadCell>
                );
              })}
            </BoTableRow>
          ))}
        </BoTableHead>

        <BoTableBody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => {
              const tableRowLabel = rowLabel
                ? `${rowLabel}-${row.id}`
                : `row-${row.id}`;

              return (
                <BoTableRow key={row.id} aria-label={tableRowLabel}>
                  {row.getVisibleCells().map((cell) => {
                    let CellComponent = BoTableCell;

                    if (cell.column.columnDef.meta?.fitContent) {
                      CellComponent = BoTableCellContentWidth;
                    }

                    if (cell.column.columnDef.meta?.wrapContent) {
                      CellComponent = MaxWidthTableCell;
                    }

                    return (
                      <CellComponent key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </CellComponent>
                    );
                  })}
                </BoTableRow>
              );
            })
          ) : (
            <BoTableRow aria-label="No Data Row">
              <NoResultsMessage colSpan={columns.length}>
                <Body1>No Data Available</Body1>
              </NoResultsMessage>
            </BoTableRow>
          )}
        </BoTableBody>
      </StyledBoTable>

      {(pagination || rowSelection) && (
        <TableFooter>
          <SelectionCountContainer>
            {rowSelection && (
              <span>
                <strong>{table.getSelectedRowModel().flatRows.length}</strong>{" "}
                records selected
              </span>
            )}
          </SelectionCountContainer>

          {pagination ? (
            <>
              <PaginationContainer aria-label="Page Navigation">
                <BoButton
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                  aria-label="First Page"
                  type="button"
                >
                  {"<<"}
                </BoButton>
                <BoButton
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  aria-label="Previous Page"
                  type="button"
                >
                  {"<"}
                </BoButton>

                <StyledPageCount>
                  {data.length !== 0 ? (
                    <>
                      <span>Page</span>
                      <strong>
                        {table.getState().pagination.pageIndex + 1} of{" "}
                        {table.getPageCount()}
                      </strong>
                    </>
                  ) : null}
                </StyledPageCount>

                <BoButton
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  aria-label="Next Page"
                  type="button"
                >
                  {">"}
                </BoButton>
                <BoButton
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                  aria-label="Last Page"
                  type="button"
                >
                  {">>"}
                </BoButton>
              </PaginationContainer>
              <PageSizeLabel htmlFor="select-page-size">
                Page Size:
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                  id="select-page-size"
                >
                  {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                      Show {option}
                    </option>
                  ))}
                </select>
              </PageSizeLabel>
            </>
          ) : null}
        </TableFooter>
      )}
    </BoReactTableContainer>
  );
}
