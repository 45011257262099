import { IdType } from "@common/types/apiTypes";
import { Subtitle1 } from "@ops-design-system/components/Typography/Typography";
import {
  StyledBillBreakdownComparisonContainer,
  StyledBillBreakdownComparisonTitleContainer,
  StyledBillComparisonTitle,
} from "@ops/components/BillBreakdownComparison/BillBreakdownComparison.styled";
import { BillBreakdownComparisonBody } from "@ops/components/BillBreakdownComparison/BillBreakdownComparisonBody";
import React from "react";

interface BillBreakdownComparisonProps {
  invoiceId: IdType;
}

export const BillBreakdownComparison = (
  props: BillBreakdownComparisonProps
) => {
  const { invoiceId } = props;

  return (
    <StyledBillBreakdownComparisonContainer>
      <StyledBillBreakdownComparisonTitleContainer>
        <StyledBillComparisonTitle>
          Here’s this bill compared to past bills.
        </StyledBillComparisonTitle>
        <Subtitle1>
          See exactly how much energy you used last year during this time, as
          well as last month.
        </Subtitle1>
      </StyledBillBreakdownComparisonTitleContainer>
      <BillBreakdownComparisonBody invoiceId={invoiceId} />
    </StyledBillBreakdownComparisonContainer>
  );
};
