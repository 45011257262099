import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface AutopayPreferenceUpdatedData extends EventLogBaseData {
  autopay: boolean;
  lastFourDigits: string;
  paymentType: string;
}

export class AutopayPreferenceUpdated extends EventLog {
  public readonly autopay: boolean;
  public readonly lastFourDigits: string;
  public readonly paymentType: string;

  constructor({
    autopay,
    lastFourDigits,
    paymentType,
    ...baseEventData
  }: AutopayPreferenceUpdatedData) {
    super(baseEventData);

    this.autopay = autopay;
    this.lastFourDigits = lastFourDigits;
    this.paymentType = paymentType;
  }

  override get body() {
    if (this.autopay === true) {
      return `Autopay turned on for ${this.paymentType} ****${this.lastFourDigits}`;
    } else if (this.autopay === false) {
      return `Autopay turned off for ${this.paymentType} ****${this.lastFourDigits}`;
    } else {
      return `Autopay is in an unknown state for ${this.paymentType} ****${this.lastFourDigits}`;
    }
  }
}
