import { rewardsUrls } from "@common/api/rewardsUrls";
import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import {
  RewardPointsRedemptionResponseType,
  RewardRedemptionHistoryType,
  RewardsAddPointsResponseType,
  RewardsResponseType,
} from "@common/types/rewardsTypes";

export const rewardsApi = {
  affiliates: {
    redeemRewardedPoints: ({
      affiliateId,
      amount,
    }: {
      affiliateId: IdType;
      amount: number;
    }): Promise<RewardPointsRedemptionResponseType> => {
      const body = { amount };

      return ajax.post(
        rewardsUrls.affiliates.redeemRewardedPoints(affiliateId),
        body
      );
    },
    retrieve: (affiliateId: IdType): Promise<RewardsResponseType> =>
      ajax.get(rewardsUrls.affiliates.retrieve(affiliateId)),
    rewardRedemptionHistory: (
      affiliateId: IdType
    ): Promise<RewardRedemptionHistoryType[]> =>
      ajax.get(rewardsUrls.affiliates.rewardRedemptionHistory(affiliateId)),
  },
  customers: {
    addPoints: (
      customerId: IdType,
      points: number,
      type?: string
    ): Promise<RewardsAddPointsResponseType> =>
      ajax.post(rewardsUrls.customers.addPoints(customerId), {
        points,
        type: type ?? "default",
      }),
    retrieve: (customerId: IdType): Promise<RewardsResponseType> =>
      ajax.get(rewardsUrls.customers.retrieve(customerId)),
  },
  raf: {
    validateReferralCode: (referralCode: string): Promise<null> =>
      ajax.get(rewardsUrls.raf.validateReferralCode(referralCode)),
  },
};
