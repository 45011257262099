import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  RH_HEADER_HEIGHT,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { AppBar } from "@mui/material";
import { grey } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

export const LOGO_WIDTH = "224px"; // 192px + 32px of horizontal padding

export const BoHeaderContainer = styled(AppBar)`
  &.MuiAppBar-root {
    background-color: white;
    border-bottom: ${borders[100]};
    flex-direction: row;
    flex-wrap: nowrap;
    height: ${rhSpacingPx(RH_HEADER_HEIGHT)};
    padding: 0;
  }
`;

export const BoHeaderItem = styled.li`
  &.BoHeader__DividerItem {
    margin-right: ${rhSpacingPx(3)};
  }

  &:hover {
    color: blue;
  }

  font-size: 14px;
  margin: ${rhSpacingPx(2)} ${rhSpacingPx(5)} ${rhSpacingPx(2)} 0;
  white-space: nowrap;
`;

export const BoHeaderListContainer = styled.ul`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;
  list-style: none;
`;

export const BoHeaderLogoContainer = styled.div`
  & svg {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  &:hover {
    color: ${grey[300]};
  }

  @media (max-width: ${RhythmBreakpoints.SM}) {
    margin: auto;
  }

  color: ${grey[500]};
  height: ${rhSpacingPx(RH_HEADER_HEIGHT)};
  padding: 0 ${rhSpacingPx(2)};
  transition: color 200ms;
  width: ${LOGO_WIDTH};
`;
