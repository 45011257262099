import { Premise } from "@common/models/Premise.model";
import { formatCurrency } from "@common/utils/dataFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { AverageBillingPreference } from "@ops/components/AverageBillingPreference/AverageBillingPreference";
import React, { PropsWithChildren } from "react";

interface AverageBillingCardProps {
  premise: Premise;
}

export const AverageBillingCard = ({
  premise,
}: PropsWithChildren<AverageBillingCardProps>) => {
  return (
    <BoCard>
      <BoGrid>
        <BoGridColumn>
          <BoGrid>
            <BoGridColumn>
              <BoInfoLabel>Average Billing</BoInfoLabel>
              <BoInfoValue>
                {premise.averagedBilling
                  ? formatCurrency(Number(premise.averagedBillingMonthlyCharge))
                  : "OFF"}
              </BoInfoValue>
            </BoGridColumn>
            <BoGridColumn>
              <BoInfoLabel>Accrual Balance</BoInfoLabel>
              <BoInfoValue>
                {premise.averagedBilling
                  ? formatCurrency(Number(premise.averagedBillingBalance))
                  : "—"}
              </BoInfoValue>
            </BoGridColumn>
          </BoGrid>
        </BoGridColumn>
        <BoGridColumn>
          <AverageBillingPreference premise={premise} />
        </BoGridColumn>
      </BoGrid>
    </BoCard>
  );
};
