import { IRONHIDE_URL } from "@ops/settings/config";

export const linkToIronhideAccount = (
  ironhideId: string | undefined
): string | undefined => {
  if (!ironhideId) {
    return;
  }
  return `${IRONHIDE_URL}/Customer/Details/${ironhideId}`;
};
