import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCheckbox } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import {
  darkPurple,
  grey,
  red,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import {
  ContractRenewalWizardStepContainer,
  ContractRenewalWizardStepForm,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import styled from "styled-components";

export const BillingPreferencesStepContainer = styled(
  ContractRenewalWizardStepContainer
)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  position: relative;
`;

export const BillingPreferencesStepForm = styled(ContractRenewalWizardStepForm)`
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(2)};
`;

export const PaperlessDisclaimer = styled(Body2)`
  color: ${grey["500"]};
`;

export const PaperlessWarningCard = styled(BoCard)`
  color: ${red["500"]};
  height: min-content;
  width: 350px;
`;

export const AutoPayInfoCard = styled(BoCard)`
  color: ${red["500"]};
  height: min-content;
  width: 350px;
`;

export const PaperlessCheckbox = styled(BoCheckbox)`
  border: 1px solid ${darkPurple["500"]};

  &::before {
    box-shadow: inset 1em 1em ${darkPurple["500"]};
  }
`;

export const PaperlessLabel = styled.label`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(1)};
  width: 100%;
`;

export const PaperlessCard = styled(BoCard)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  width: 350px;
`;

export const PaperlessContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;
