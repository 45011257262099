import {
  Body1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as CalendarIcon } from "@ops-design-system/icons/CalendarIcon.svg";
import {
  darkPurple,
  grey,
  red,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const CustomerBalance = styled.span`
  color: ${red["500"]};
`;

export const Subheader = styled(Subtitle2).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const ComputedFormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rhOpsSpacingPx(2)};
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
`;

export const PurpleCalendarIcon = styled(CalendarIcon)`
  color: ${darkPurple["500"]};
`;

export const FormFieldWrapper = styled.div`
  flex-basis: 30%;
`;

export const PaymentTransactionFeeRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const PaymentTransactionFeeInput = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhOpsSpacingPx(1)};
`;

export const PaymentTransactionFee = styled.div`
  border-top: 1px solid ${grey["100"]};
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  padding-top: ${rhOpsSpacingPx(2)};
`;

export const PaymentTransactionFeeNote = styled(Body1)`
  color: ${grey[500]};
  padding-left: ${rhOpsSpacingPx(4)};
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(3)};
`;

export const TotalAmount = styled.div`
  border-top: 1px solid ${grey["100"]};
  display: flex;
  justify-content: space-between;
  padding-top: ${rhOpsSpacingPx(2)};
`;
