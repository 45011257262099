import { IdType } from "@common/types/apiTypes";

export enum PaymentMethodTypes {
  ACH = "ACH",
  CASH = "Cash",
  CHECK = "Check",
  CreditCard = "CreditCard",
}

export enum PaymentTypes {
  ELECTRONIC = "Electronic",
  EXTERNAL = "External",
}

export interface CreditCardRetrieveType {
  creditCardExpirationMonth: number;
  creditCardExpirationYear: number;
  creditCardMaskNumber: string;
  creditCardType: string;
  type: PaymentMethodTypes.CreditCard;
}

export interface ACHRetrieveType {
  achAccountNumberMask: string;
  achBankName: string;
  type: PaymentMethodTypes.ACH;
}

export type PaymentMethodRetrieveType =
  | CreditCardRetrieveType
  | ACHRetrieveType;

export interface CreditCardType {
  creditCardExpirationMonth: number;
  creditCardExpirationYear: number;
  creditCardMaskNumber: string;
  creditCardType: string;
  defaultPaymentMethod: boolean;
  id: string;
  type: PaymentMethodTypes.CreditCard;
}

export interface ACHType {
  achAccountName: string;
  achAccountNumberMask: string;
  achAccountType: string;
  achBankName: string;
  defaultPaymentMethod: boolean;
  id: string;
  type: PaymentMethodTypes.ACH;
}

export type PaymentMethodType = CreditCardType | ACHType;

export type ExternalPaymentMethodType = {
  id: string;
  type:
    | PaymentMethodTypes.ACH
    | PaymentMethodTypes.CASH
    | PaymentMethodTypes.CHECK
    | PaymentMethodTypes.CreditCard;
};

export interface PaymentMethodsResponseType {
  paymentMethods: PaymentMethodType[];
}

export interface UpdatePaymentMethodType {
  premiseId: IdType;
}

export type CreatePaymentDataType = {
  amount: number;
  isOneTimePayment?: boolean;
  paymentMethodId: string;
};
