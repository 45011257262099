import { MONTH_DAY_YEAR_SLASH } from "@common/constants/date.constant";
import { TWELVE_HOUR_MINUTE_AM_PM } from "@common/constants/time.constants";
import { IdType } from "@common/types/apiTypes";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { Searchable, safeIncludes } from "@ops/models/searchable";
import dayjs, { Dayjs } from "dayjs";

export class EventLog implements Searchable {
  public readonly type: string;
  public readonly name: string;
  public readonly receivedAt: Dayjs;
  public readonly premiseId: IdType | null;
  public readonly initiatedBy: string;

  constructor(data: EventLogBaseData) {
    this.type = data.event;
    this.name = data.eventText;
    this.receivedAt = dayjs(data.receivedAt);
    this.initiatedBy = stringOrEmDash(data.initiatedBy);
    this.premiseId = data.premiseId ? String(data.premiseId) : null;
  }

  get date(): string {
    return this.receivedAt.format(MONTH_DAY_YEAR_SLASH);
  }

  get time(): string {
    return this.receivedAt.local().format(TWELVE_HOUR_MINUTE_AM_PM);
  }

  get body(): string | null {
    return `${this.name}`;
  }

  matches(query: string): boolean {
    return (
      safeIncludes(this.type, query) ||
      safeIncludes(this.name, query) ||
      safeIncludes(this.initiatedBy, query) ||
      safeIncludes(this.date, query) ||
      safeIncludes(this.time, query) ||
      safeIncludes(this.body ?? undefined, query)
    );
  }
}
