import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import React from "react";
import styled from "styled-components";

const MatchedSearchTerm = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const generateJSXFromStringMatch = (
  value?: string,
  searchQuery?: string
): JSX.Element | string => {
  if (!value) {
    return "";
  }

  if (!searchQuery) {
    return value;
  }
  const parts = value.split(new RegExp(`(${searchQuery})`, "gi"));

  return (
    <span>
      {parts.map((part, i) => {
        return part.toLowerCase() === searchQuery.toLowerCase() ? (
          // eslint-disable-next-line react/no-array-index-key
          <MatchedSearchTerm key={`${i}-${part}`}>{part}</MatchedSearchTerm>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`${i}-${part}`}>{part}</span>
        );
      })}
    </span>
  );
};
