import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoDialogBodyContainer } from "@ops-design-system/components/BoDialog/BoDialog.styled";
import {
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { NewPremiseDetailsSidebar } from "@ops/components/NewPremiseDetailsSidebar/NewPremiseDetailsSidebar";
import { StartDateForm } from "@ops/components/WinbackWizard/StartDateForm/StartDateForm";
import { WinbackChoosePlan } from "@ops/components/WinbackWizard/WinbackChoosePlan/WinbackChoosePlan";
import { WinbackConfirmation } from "@ops/components/WinbackWizard/WinbackConfirmation/WinbackConfirmation";
import { WinbackInfoDetails } from "@ops/components/WinbackWizard/WinbackInfoDetails/WinbackInfoDetails";
import {
  ContractOptions,
  WizardComponentName,
} from "@ops/components/WinbackWizard/winbackWizard.enums";
import { useWinbackWizardContext } from "@ops/hooks/useWinbackWizardContext";
import React, { ReactElement } from "react";
import styled from "styled-components";

const WinbackWizardBody = styled(BoDialogBodyContainer)`
  display: flex;
  flex-direction: row;
`;
const SideBarWrapper = styled.div`
  flex-basis: 20%;
`;
const BodyContentWrapper = styled.div`
  margin-left: ${rhOpsSpacingPx(6)};
  min-height: 595px;
  width: 100%;
  &:only-child {
    margin-left: 0px;
  }
`;

interface WinbackWizardModalProps {
  close: () => void;
  isOpen: boolean;
}

const WIZARD_COMPONENT: Record<WizardComponentName, () => ReactElement> = {
  [WizardComponentName.winbackInfoDetails]: () => <WinbackInfoDetails />,
  [WizardComponentName.winbackChoosePlan]: () => <WinbackChoosePlan />,
  [WizardComponentName.winbackStartDateForm]: () => <StartDateForm />,
  [WizardComponentName.winbackConfirmation]: () => <WinbackConfirmation />,
};

export const WinbackWizardModal = ({
  close,
  isOpen,
}: WinbackWizardModalProps) => {
  const {
    loadingModalChild,
    activeComponentName,
    nextButtonProps: { disabled, loading },
    restoreStateToInitialValues,
    setPreviousComponentAsActive,
    winbackWizardState: { contractOption, offerSnapshotId },
    premise,
  } = useWinbackWizardContext();

  const submitContentForm = () => {
    document.getElementById(activeComponentName)?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
        cancelable: true,
      })
    );
  };

  const handleModalClose = () => {
    restoreStateToInitialValues();
    close();
  };

  const displaySidebar = [
    WizardComponentName.winbackChoosePlan,
    WizardComponentName.winbackStartDateForm,
  ].includes(activeComponentName);

  const getOfferSnapshotId = (): IdType => {
    if (!premise.currentOrder) {
      return "";
    }

    if (contractOption === ContractOptions.startNewContract) {
      return offerSnapshotId;
    }

    return premise.currentOrder.offersnapshotId;
  };

  return (
    <BoModal open={isOpen} onClose={handleModalClose} size="large">
      <BoDialogHeader>Winback</BoDialogHeader>
      <WinbackWizardBody>
        {displaySidebar && (
          <SideBarWrapper>
            <NewPremiseDetailsSidebar
              existingAddress
              address={
                premise.serviceAddress ? premise.serviceAddress : undefined
              }
              mailingAddress={
                premise.mailingAddress ? premise.mailingAddress : undefined
              }
              offerSnapshotId={getOfferSnapshotId()}
            />
          </SideBarWrapper>
        )}
        <BodyContentWrapper>
          {WIZARD_COMPONENT[activeComponentName]()}
          {loadingModalChild && <BoCircularProgress />}
        </BodyContentWrapper>
      </WinbackWizardBody>
      <BoDialogButtonFooter
        confirmBtnText={
          activeComponentName === WizardComponentName.winbackConfirmation
            ? "Submit"
            : "Next"
        }
        confirmDisabled={disabled}
        confirmLoading={loading}
        cancelBtnText={
          activeComponentName !== WizardComponentName.winbackInfoDetails
            ? "Back"
            : undefined
        }
        onCancel={setPreviousComponentAsActive}
        onConfirm={submitContentForm}
      />
    </BoModal>
  );
};
