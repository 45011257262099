import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { css } from "styled-components";

export const editDialogContentsCss = css`
  border-bottom: 1px solid ${grey["50"]};
  margin-bottom: ${rhOpsSpacingPx(3)};
  padding-bottom: ${rhOpsSpacingPx(3)};
  & > * {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;
