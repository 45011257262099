import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import {
  fontBold,
  fontBoldItalic,
  fontLight,
  fontLightItalic,
  fontMedium,
  fontMediumItalic,
  fontRegular,
  fontRegularItalic,
} from "@design-system/theme/avertaFont";
import { UNIT_OF_SPACING } from "@design-system/theme/style.constant";
import { typography } from "@design-system/theme/typography";
import { ThemeOptions, createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

const mui5Typography = typography as TypographyOptions;

export const theme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [
          fontLight,
          fontLightItalic,
          fontRegular,
          fontRegularItalic,
          fontMedium,
          fontMediumItalic,
          fontBold,
          fontBoldItalic,
        ],
        a: {
          "&:not(.MuiButton-root):active": {
            color: CSSVariables.COLOR_PRIMARY_DARK,
            textDecoration: "underline",
          },
          "&:not(.MuiButton-root):hover": {
            textDecoration: "underline",
          },
          color: CSSVariables.COLOR_PRIMARY_MAIN,
          cursor: "pointer",
          textDecoration: "none",
        },
        html: {
          scrollBehavior: "smooth",
        },
        ul: {
          "list-style": "none",
          margin: 0,
          padding: 0,
        },
      },
    },
  },
  palette: {
    common: {
      black: brandTheme.colors.black,
      white: brandTheme.colors.white,
    },
    error: brandTheme.colors.error,
    grey: brandTheme.colors.grey,
    info: brandTheme.colors.info,
    primary: brandTheme.colors.primary,
    success: brandTheme.colors.success,
    text: {
      disabled: brandTheme.colors.grey[200],
      primary: brandTheme.colors.grey[900],
      secondary: brandTheme.colors.grey[600],
    },
  },
  spacing: UNIT_OF_SPACING,
  typography: mui5Typography,
};

export const muiTheme = createTheme(theme);
