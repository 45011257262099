import { premiseUrls } from "@common/api/premiseUrls";
import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { Invoice } from "@common/models/Invoice.model";
import { Premise } from "@common/models/Premise.model";
import { InvoiceType } from "@common/types/accountSummaryTypes";
import { PremiseType } from "@common/types/premiseTypes";
import { authenticatedFileDownload } from "@common/utils/authenticatedFileDownload";

export const buildDisconnectNoticeFilename = (
  { billingAccountNumber }: Premise | PremiseType,
  { invoiceDate, invoiceNumber }: Invoice | InvoiceType,
  noticeId: string
) => {
  return `${billingAccountNumber}-dnp-for-invoice-${invoiceNumber}-${noticeId}-${invoiceDate}.pdf`;
};

export const disconnectNoticeFileDownload = (
  premise: Premise,
  invoice: Invoice,
  noticeId: string
) => {
  const url = premiseUrls.disconnectNoticePdf(premise.id, invoice.id, noticeId);
  const filename = buildDisconnectNoticeFilename(premise, invoice, noticeId);

  return authenticatedFileDownload(url, filename, PDF_MIME_TYPE);
};
