import { H3 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledBillBreakdownComparisonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;

export const StyledBillBreakdownComparisonTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  text-align: center;
`;

export const StyledBillComparisonTitle = styled(H3)`
  color: ${darkPurple[500]};
`;

export const StyledBillBreakdownComparisonBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  padding: 0 ${rhOpsSpacingPx(2)};
`;
