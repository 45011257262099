import { premiseApi } from "@common/api/premiseApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { Premise } from "@common/models/Premise.model";
import { FormError } from "@common/types/errorTypes";
import { WinbackDataType } from "@common/types/premiseTypes";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { ContractOptions } from "@ops/components/WinbackWizard/winbackWizard.enums";
import { WinbackWizardStateType } from "@ops/components/WinbackWizard/winbackWizard.types";

const validator = generateValidationErrorCollector<WinbackWizardStateType>({
  contractOption: [opsAndPricingIsRequired],
  enrollmentType: [opsAndPricingIsRequired],
  startDate: [opsAndPricingIsRequired],
});

export class WinbackSubmission {
  private readonly winbackWizardState: WinbackWizardStateType;
  private readonly premise: Premise;
  private winbackData?: WinbackDataType;
  private errorMessages?: (string | FormError)[];

  constructor(winbackWizardState: WinbackWizardStateType, premise: Premise) {
    this.winbackWizardState = winbackWizardState;
    this.premise = premise;
  }

  submit() {
    if (!this.winbackData) {
      throw Error("Data is not validated");
    }

    return premiseApi.winback(this.premise.id, this.winbackData);
  }

  get isValid(): boolean {
    const errors = validator(this.winbackWizardState);

    this.errorMessages = Object.entries(errors).reduce<string[]>(
      (memo, [field, fieldErrors]) => {
        if (Array.isArray(fieldErrors) && fieldErrors.length > 0) {
          memo.push(convertCamelCaseToSentenceCase(field));
        }
        return memo;
      },
      []
    );

    if (!this.winbackWizardState.offerSnapshotId) {
      this.errorMessages.push("OfferSnapshot");
    }

    const valid = this.errorMessages.length === 0;

    if (valid) {
      this.assignWinbackData();
    }

    return valid;
  }

  get errors(): (string | FormError)[] {
    if (this.errorMessages) {
      return this.errorMessages;
    }

    throw Error(
      "Must validate data by running isValid() before calling errors"
    );
  }

  private assignWinbackData() {
    const {
      offerSnapshotId,
      startDate,
      enrollmentType,
      paperless,
      priorityEnrollment,
      contractOption,
    } = this.winbackWizardState;

    this.winbackData = {
      eBill: paperless ?? false,
      enrollmentType,
      offerId: offerSnapshotId,
      priorityEnrollment: priorityEnrollment ?? false,
      serviceStartDate: startDate as string,
      useNewContract: contractOption === ContractOptions.startNewContract,
    };
  }
}
