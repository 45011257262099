import { acquisitionCampaignApi } from "@common/api/campaignPromosApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { AcquisitionCampaignListResponseType } from "@common/types/apiResponseTypes";
import { useCallback } from "react";

export const useAcquisitionCampaignsInState = () => {
  const [
    { data, error, requestMonitor },
    { setPending, setSuccess, setFailure },
  ] = useAjaxState<AcquisitionCampaignListResponseType>();

  const fetch = useCallback(() => {
    setPending();

    acquisitionCampaignApi.list().then(setSuccess).catch(setFailure);
  }, []);

  return {
    data,
    error,
    fetch,
    requestMonitor,
  };
};
