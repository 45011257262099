import { PremiseStatus } from "@common/enums/premise.enum";
import { premiseStatusLevel } from "@common/models/Premise.model";
import { ReactComponent as CheckIcon } from "@design-system/icons/CheckIcon.svg";
import { Body3 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { PremiseSelectOptionType } from "@ops/components/PremiseSelect/PremiseSelect";
import { STATUS_INDICATOR_COLOR } from "@ops/components/StatusIndicator/StatusIndicator.styled";
import type { StylesConfig } from "react-select";
import styled from "styled-components";

interface ColoredBarProps {
  $status: PremiseStatus;
}

export const ColoredBar = styled.span<ColoredBarProps>`
  background-color: ${({ $status }) =>
    STATUS_INDICATOR_COLOR[premiseStatusLevel[$status]]};
  border-radius: 2px;
  display: inline-block;
  height: 16px;
  margin-right: ${rhOpsSpacingPx(1)};
  width: 2px;
`;

export const GraySeparatorBar = styled.span`
  background-color: ${grey["200"]};
  border-radius: 1px;
  display: inline-block;
  height: 10px;
  margin: 0px ${rhOpsSpacingPx(1)} 0px ${rhOpsSpacingPx(1)};
  width: 2px;
`;

interface SelectOptionWrapperProps {
  isActive: boolean;
}

interface AccountNumberWrapperProps {
  $includePremiseId: boolean;
}

export const SelectOptionWrapper = styled.div<SelectOptionWrapperProps>`
  align-items: center;
  background-color: ${({ isActive }) =>
    isActive ? darkPurple["50"] : "transparent"};
  display: flex;
  justify-content: flex-start;
  padding: 4px 12px 4px 10px;
`;

export const SelectedOptionValueWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 6px 0 6px 8px;
`;

export const AccountNumberSelected = styled(Body3)<AccountNumberWrapperProps>`
  margin-right: ${({ $includePremiseId }) =>
    $includePremiseId ? "0px" : "10px"};
`;

export const PremiseIdSelected = styled(Body3)`
  margin-right: 10px;
`;

export const AccountNumberOption = styled(Body3)<AccountNumberWrapperProps>`
  width: ${({ $includePremiseId }) => ($includePremiseId ? "71px" : "81px")};
`;

export const PremiseIdOption = styled(Body3)`
  width: 100px;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  height: 8.25px;
  margin-left: auto;
  width: auto;
`;

export const customStyles: <Option = PremiseSelectOptionType>(
  isLongText?: boolean
) => StylesConfig<Option, false> = (isLongText = false) => ({
  control: (provided) => ({
    ...provided,
    "&:hover": {
      borderColor: darkPurple["500"],
    },
    borderColor: darkPurple["200"],
    boxShadow: "none",
    height: 28,
    minHeight: "none",
    width: 25,
  }),
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      "&:hover": {
        color: darkPurple["500"],
      },
      color: darkPurple["500"],
      marginRight: 4,
      padding: 0,
      width: 16,
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      display: "none",
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      borderRadius: 4,
      left: "auto",
      marginTop: 4,
      right: 0,
      width: isLongText ? 380 : 280,
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      paddingBottom: 6,
      paddingTop: 6,
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      margin: 0,
      maxWidth: "none",
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: 0,
      position: "static",
    };
  },
});
