import { OktaGroups } from "@common/constants/okta.constant";
import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { IdType } from "@common/types/apiTypes";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import {
  Body2,
  Body3,
  H3,
} from "@ops-design-system/components/Typography/Typography";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { CustomerEmployeeIndicatorModal } from "@ops/components/CustomerInfoCard/CustomerEmployeeIndicatorModal";
import { CustomerStatusIndicators } from "@ops/components/CustomerInfoCard/CustomerStatusIndicators";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { usePremise } from "@ops/hooks/usePremise";
import { linkToIronhideAccount } from "@ops/utils/ironhideHelpers";
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

interface CustomerInfoCardProps {
  customer: Customer;
}

const NameContainer = styled.div`
  color: ${darkPurple[900]};
`;

const CustomerInfoContainer = styled(BoFlexBox)`
  align-items: flex-start;
  flex-direction: column;
  & > * {
    padding-bottom: ${rhOpsSpacingPx(0.5)};
  }
  & > :last-child {
    padding-bottom: 0;
  }
`;

const Item = styled(BoFlexBox)`
  align-items: center;
  justify-content: flex-start;
`;

const IdContainer = styled(Body2).attrs({ as: "div" })`
  color: ${darkPurple[900]};
  padding-right: ${rhOpsSpacingPx(1)};
`;

const MeterContainer = styled(Body3).attrs({ as: "span" })`
  border-right: 1px solid ${grey["200"]};
  line-height: 15px;
  margin-right: ${rhOpsSpacingPx(0.5)};
  padding-right: ${rhOpsSpacingPx(0.5)};
`;

export const CustomerInfoCard: FC<
  React.PropsWithChildren<CustomerInfoCardProps>
> = ({ customer }) => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);
  const { open, close, isOpen } = useModalState();

  const premiseCount = customer.premiseIds?.length ?? 0;
  const meterCount = () =>
    `${premiseCount} meter${premiseCount !== 1 ? "s" : ""}`;

  const ironhideLink = linkToIronhideAccount(customer.ironhideId);

  return (
    <CustomerInfoContainer data-testid="customer-name-card-target">
      <NameContainer>
        <H3 $fontWeight="SemiBold">{customer.fullName}</H3>
      </NameContainer>
      <Item>
        <IdContainer>{customer.id}</IdContainer>
        <CustomerStatusIndicators activePremise={premise} customer={customer} />
        <AccessControl allowedGroups={[OktaGroups.CSRTier3]}>
          <EditLink onClick={open} />
          <CustomerEmployeeIndicatorModal
            open={isOpen}
            onClose={close}
            customer={customer}
          />
        </AccessControl>
      </Item>
      <Item>
        <MeterContainer>
          <Body3>{meterCount()}</Body3>
        </MeterContainer>
        <div>
          {customer.isFromIronhide ? (
            <div>
              <MeterContainer>
                <BoLink href={customer.zendeskUrl} target="_blank">
                  <Body3>View on Zendesk</Body3>
                </BoLink>
              </MeterContainer>
              <BoLink href={ironhideLink} target="_blank">
                <Body3>View on Ironhide</Body3>
              </BoLink>
            </div>
          ) : (
            <div>
              <BoLink href={customer.zendeskUrl} target="_blank">
                <Body3>View on Zendesk</Body3>
              </BoLink>
            </div>
          )}
        </div>
      </Item>
    </CustomerInfoContainer>
  );
};
