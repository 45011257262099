import { IdType } from "@common/types/apiTypes";
import { rhapsodyProductContractsApi } from "@ops-data/api/rhapsodyProductContractsApi";
import { ProductContractStatusFilter } from "@ops-utils/types/productContractTypes";
import { ProductContractKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useActiveProductContracts = (premiseId: IdType) => {
  return useQuery({
    queryFn: () =>
      rhapsodyProductContractsApi.list(
        premiseId,
        ProductContractStatusFilter.ACTIVE
      ),
    queryKey: ProductContractKeys.ACTIVE_CONTRACTS_BY_PREMISE_LIST(premiseId),
  });
};
