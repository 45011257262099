import {
  DeferPaymentModalStateContext,
  DeferPaymentModalStateProviderContext,
} from "@ops/components/DeferPaymentWizard/DeferPaymentModalStateProvider";
import { useContext } from "react";

export function useDeferPaymentModalState(): DeferPaymentModalStateProviderContext {
  const context = useContext<DeferPaymentModalStateProviderContext>(
    DeferPaymentModalStateContext
  );

  if (context === null) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }
  return context;
}
