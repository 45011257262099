import { fampUrls } from "@common/api/fampUrls";
import { ajax } from "@common/services/ajax";
import {
  CreateFampMessageResponseType,
  CreateFampMessageType,
  SubmitFampResponseType,
  SubmitFampType,
} from "@common/types/fampTypes";

export const fampApi = {
  createMessage: (data: CreateFampMessageType) => {
    return ajax.post<CreateFampMessageResponseType>(
      fampUrls.createMessage(),
      data
    );
  },
  download: (fampMessageId: number): Promise<Blob> => {
    return ajax.getFile(fampUrls.download(fampMessageId));
  },
  generateAndSend: (fampMessageId: number, data: SubmitFampType) => {
    return ajax.post<SubmitFampResponseType>(
      fampUrls.generateAndSend(fampMessageId),
      data
    );
  },
};
