import { Address } from "@common/models/Address.model";
import { AddressForm } from "@common/models/AddressForm.model";
import { IdType } from "@common/types/apiTypes";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import {
  NewPremiseDetailsSideBarContainer,
  NewPremiseDetailsSideBarContentBox,
  NewPremiseDetailsSideBarStyledLabel,
  NewPremiseDetailsSideBarStyledValue,
} from "@ops/components/NewPremiseDetailsSidebar/NewPremiseDetailsSidebar.styled";
import { NewPremiseDetailsOfferSnapshotDetails } from "@ops/components/NewPremiseDetailsSidebar/NewPremiseDetailsSidebarOfferSnapshotDetails";
import React from "react";

interface NewPremiseDetailsSidebarProps {
  address?: AddressForm | Address;
  esiId?: string;
  existingAddress?: boolean;
  mailingAddress?: AddressForm;
  offerSnapshotId?: IdType;
  utilityName?: string;
}

interface StyledAddressValueProps {
  address: AddressForm | Address;
}
const StyledAddressValue = ({ address }: StyledAddressValueProps) => {
  return (
    <NewPremiseDetailsSideBarStyledValue>
      <div>{address.line1}</div>
      <div>{address.line2}</div>
    </NewPremiseDetailsSideBarStyledValue>
  );
};

export const NewPremiseDetailsSidebar = (
  props: NewPremiseDetailsSidebarProps
) => {
  const {
    address,
    mailingAddress,
    offerSnapshotId,
    esiId,
    utilityName,
    existingAddress,
  } = props;

  if (!address) {
    return null;
  }
  const displayEsiId = Boolean(esiId);
  const displayMailingAddress =
    mailingAddress?.addressLine1 &&
    !(
      address?.line1 === mailingAddress?.line1 &&
      address?.line2 === mailingAddress?.line2
    );

  return (
    <NewPremiseDetailsSideBarContentBox>
      <BoCard>
        <NewPremiseDetailsSideBarContainer>
          <NewPremiseDetailsSideBarStyledLabel>{`${
            existingAddress ? "Service" : "New"
          } Address`}</NewPremiseDetailsSideBarStyledLabel>
          <StyledAddressValue address={address} />
        </NewPremiseDetailsSideBarContainer>
        {displayEsiId && (
          <>
            <NewPremiseDetailsSideBarContainer>
              <NewPremiseDetailsSideBarStyledLabel>
                ESIID
              </NewPremiseDetailsSideBarStyledLabel>
              <NewPremiseDetailsSideBarStyledValue>
                {esiId}
              </NewPremiseDetailsSideBarStyledValue>
            </NewPremiseDetailsSideBarContainer>
            <NewPremiseDetailsSideBarContainer>
              <NewPremiseDetailsSideBarStyledLabel>
                Utility
              </NewPremiseDetailsSideBarStyledLabel>
              <NewPremiseDetailsSideBarStyledValue>
                {utilityName}
              </NewPremiseDetailsSideBarStyledValue>
            </NewPremiseDetailsSideBarContainer>
          </>
        )}
        {displayMailingAddress && (
          <NewPremiseDetailsSideBarContainer>
            <NewPremiseDetailsSideBarStyledLabel>{`${
              existingAddress ? "" : "New "
            }Mailing Address`}</NewPremiseDetailsSideBarStyledLabel>
            <StyledAddressValue address={mailingAddress} />
          </NewPremiseDetailsSideBarContainer>
        )}

        {offerSnapshotId ? (
          <NewPremiseDetailsOfferSnapshotDetails
            offerSnapshotId={offerSnapshotId}
          />
        ) : null}
      </BoCard>
    </NewPremiseDetailsSideBarContentBox>
  );
};
