import { EdiActions } from "@common/constants/edi.constants";
import { IdType } from "@common/types/apiTypes";
import { ReactComponent as Plug } from "@ops-design-system/icons/Plug.svg";
import { EdiActionTriggerWithConfirmation } from "@ops/components/EdiActionTriggerWithConfirmation/EdiActionTriggerWithConfirmation";
import React from "react";

interface TriggerSwitchHoldProps {
  customerId: IdType;
}

export const TriggerReconnect = ({ customerId }: TriggerSwitchHoldProps) => {
  return (
    <EdiActionTriggerWithConfirmation
      action={EdiActions.RECONNECT}
      buttonIcon={Plug}
      confirmBtnText="Reconnect"
      confirmMessage="Are you sure you want to trigger reconnect?"
      customerId={customerId}
      errorMessage="There was a problem reconnecting.  Please try again."
      header="Trigger Reconnect"
      triggerOpenDialogBtnText="Trigger Reconnect"
    />
  );
};
