import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { borders } from "@ops-design-system/styles/borders";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled, { css } from "styled-components";

export const StyledSupportLog = styled.div`
  border: ${borders["100"]};
  border-radius: 4px;
  padding: ${rhOpsSpacingPx(2)} 0 0;
`;

export const StyledHeaderContainer = styled.div`
  border-bottom: ${borders[100]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rhOpsSpacingPx(1)};
  padding: 0 ${rhOpsSpacingPx(2.5)} ${rhOpsSpacingPx(2)};
`;

export const StyledEmptyText = styled.div`
  padding-bottom: ${rhOpsSpacingPx(4)};
  padding-top: ${rhOpsSpacingPx(4)};
  text-align: center;
`;

export const StyledWarningText = styled(StyledEmptyText)`
  color: ${red.main};
`;

export const StyledEntry = styled.div`
  align-items: flex-start;
  border-bottom: ${borders[100]};
  display: flex;
  margin: 0 ${rhOpsSpacingPx(2.5)};
  padding: ${rhOpsSpacingPx(1)} 0 ${rhOpsSpacingPx(1.5)};
`;
export const DateTimeWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${rhOpsSpacingPx(0.5)};
  margin-top: ${rhOpsSpacingPx(0.25)};
`;
const INNER_LEFT_COLUMN_WIDTH = "97px";

export const LeftContainer = styled.div`
  margin-right: ${rhOpsSpacingPx(2)};
  min-width: ${INNER_LEFT_COLUMN_WIDTH};
  width: ${INNER_LEFT_COLUMN_WIDTH};
`;
const StyledText = css`
  color: ${grey["900"]};
  margin: 0;
`;

export const StyledTextWrapper = styled(Body2).attrs({ as: "div" })<{
  $whiteSpace?: React.CSSProperties["whiteSpace"];
}>`
  ${StyledText}
  white-space: ${({ $whiteSpace }) => $whiteSpace || "normal"};
`;
export const StyledTextWrapperSmall = styled(Body3).attrs({ as: "div" })`
  ${StyledText}
`;
export const StyledTime = styled(Body3)`
  color: ${grey["600"]};
`;
export const StyledDate = styled(Body3)`
  color: ${grey["900"]};
  font-weight: ${FontWeight.Bold};
  margin-right: ${rhOpsSpacingPx(0.5)};
`;

export const ScrollWrap = styled.div`
  height: calc(100vh - 200px);
  overflow-y: auto;
`;
