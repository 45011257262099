import {
  ISO8601_DATE_FORMAT,
  SHORT_TIME_FORMAT,
} from "@common/constants/date.constant";
import { ExternalPaymentStatus } from "@common/enums/externalPayment.enum";
import { ExternalPaymentFile } from "@common/models/ExternalPayment.model";
import { RhApiError } from "@common/types/errorTypes";
import { ExternalPaymentFileType } from "@common/types/paymentTypes";
import { noop } from "@common/utils/genericFunctions";
import { numberToStringOrEmDash } from "@common/utils/stringFormatters";
import { unmatchedPaymentDownload } from "@common/utils/unmatchedPaymentDownload";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { H4 } from "@ops-design-system/components/Typography/Typography";
import dayjs from "dayjs";
import React, { MouseEvent } from "react";

const HEADERS = [
  "Upload Date",
  "Time of Upload",
  "File Name",
  "Status",
  "Error Count",
  "Success Count",
  "Processing Count",
  "Total Match Found",
  "No Match File",
];

interface FidelityPaymentFileTableProps {
  externalPaymentFiles: ExternalPaymentFileType[] | null;
}

interface FidelityPaymentFileTableRowProps {
  externalPaymentFile: ExternalPaymentFile;
}

const downloadClickWrapper =
  (cb: () => void | Promise<void>) =>
  (e: MouseEvent<HTMLAnchorElement>): void | Promise<void> => {
    e.preventDefault();
    return cb();
  };

const FidelityPaymentFileTableRow = ({
  externalPaymentFile,
}: FidelityPaymentFileTableRowProps) => {
  const flash = useRhFlash();
  const downloadUnmatched = downloadClickWrapper(() =>
    unmatchedPaymentDownload(externalPaymentFile)
      .then(noop)
      .catch((_error: RhApiError) => {
        flash.error(
          "We are having issues downloading unmatched payments. Please try again."
        );
      })
  );

  return (
    <BoTableRow>
      <BoTableCell $align="left">
        {dayjs(externalPaymentFile.createdAt).format(ISO8601_DATE_FORMAT)}
      </BoTableCell>
      <BoTableCell $align="left">
        {dayjs(externalPaymentFile.createdAt).format(SHORT_TIME_FORMAT)}
      </BoTableCell>
      <BoTableCell $align="left">{externalPaymentFile.fileName}</BoTableCell>
      <BoTableCell $align="left">{externalPaymentFile.status}</BoTableCell>
      <BoTableCell $align="left">
        {numberToStringOrEmDash(
          externalPaymentFile.getMatchedPaymentCountByStatus(
            ExternalPaymentStatus.ERROR
          )
        )}
      </BoTableCell>
      <BoTableCell $align="left">
        {numberToStringOrEmDash(
          externalPaymentFile.getMatchedPaymentCountByStatus(
            ExternalPaymentStatus.SUCCESS
          )
        )}
      </BoTableCell>
      <BoTableCell $align="left">
        {numberToStringOrEmDash(
          externalPaymentFile.getMatchedPaymentCountByStatus(
            ExternalPaymentStatus.UNPROCESSED
          )
        )}
      </BoTableCell>
      <BoTableCell $align="left">
        {numberToStringOrEmDash(
          externalPaymentFile.getTotalPaymentCount() -
            externalPaymentFile.getUnmatchedPaymentCount()
        )}
      </BoTableCell>
      <BoTableCell $align="left">
        {externalPaymentFile.getUnmatchedPaymentCount() > 0 ? (
          <BoLink onClick={downloadUnmatched} href="#">
            Download {externalPaymentFile.getUnmatchedPaymentCount()} unmatched
            payments
          </BoLink>
        ) : (
          <>0 unmatched payments</>
        )}
      </BoTableCell>
    </BoTableRow>
  );
};

export const FidelityPaymentFileTable = ({
  externalPaymentFiles,
}: FidelityPaymentFileTableProps) => {
  if (!externalPaymentFiles || externalPaymentFiles.length < 1) {
    return (
      <BoCard>
        <H4>No Fidelity Payment Files Available</H4>
      </BoCard>
    );
  }

  return (
    <BoTable>
      <BoTableHead>
        <BoTableRow>
          {HEADERS.map((header) => (
            <BoTableHeadCell $align="left" key={header}>
              {header}
            </BoTableHeadCell>
          ))}
        </BoTableRow>
      </BoTableHead>
      <BoTableBody>
        {externalPaymentFiles.map((externalPaymentFile) => (
          <FidelityPaymentFileTableRow
            externalPaymentFile={new ExternalPaymentFile(externalPaymentFile)}
            key={`Fidelity Payment File - ${externalPaymentFile.id}`}
          />
        ))}
      </BoTableBody>
    </BoTable>
  );
};
