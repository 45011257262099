import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { PropsWithChildren, useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const ButtonContainer = styled.div`
  border-top: 1px solid ${grey["100"]};
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(3)};
  justify-content: end;
  margin-top: ${rhOpsSpacingPx(4)};
  padding-bottom: ${rhOpsSpacingPx(2)};
  padding-top: ${rhOpsSpacingPx(2.5)};
`;

export const BoFormWizardFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type BoFormWizardProps<T = unknown> = {
  formName?: string;
  handleCancel?: () => void;
  initialValues?: Partial<T>;
  onSubmit: (values: T) => void;
};

interface PreviousOrCloseButtonProps {
  handleCancel?: () => void;
  handlePreviousPage: () => void;
  isFirstPage: boolean;
}

const PreviousOrCloseButton = ({
  isFirstPage,
  handleCancel,
  handlePreviousPage,
}: PreviousOrCloseButtonProps) => {
  if (handleCancel && isFirstPage) {
    return (
      <BoButton variant="secondary" onClick={handleCancel}>
        Cancel
      </BoButton>
    );
  }
  if ((handleCancel && !isFirstPage) || !handleCancel) {
    return (
      <BoButton disabled={isFirstPage} onClick={handlePreviousPage}>
        Previous
      </BoButton>
    );
  }

  return null;
};

/**
 * The main purpose of BoFormWizard is to allow multi step forms and to be contained within a single ReactFinalForm instance
 *
 * A type must be passed into the BoWizardForm to handle values being set in the onSubmit and being able to set the optional initialValues
 *
 * Children are expected to be passed in as separate components to represent pages such as:
 * ```
 *  <BoFormWizard<T>>
 *     <Page1 />
 *     <Page2 />
 *  </BoFormWizard>
 * ```
 *
 * Field validation can be done at the fields individual level and can prevent proceeding to the next page using the `validate` prop
 *
 *
 * A formName can be sent in as an optional prop to allow the form to be named for testing/accessibility purposes
 */
export function BoFormWizard<T = unknown>({
  onSubmit,
  children,
  initialValues,
  formName,
  handleCancel,
}: PropsWithChildren<BoFormWizardProps<T>>) {
  const [page, setPage] = useState(0);

  const activePage = React.Children.toArray(children)[page];

  const pageCount = React.Children.count(children) - 1;

  const isLastPage = page === pageCount;
  const isFirstPage = page === 0;

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, pageCount));
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  // Both of these need to be declared by the Wizard component to work
  const handleValidation = (values: T) => {
    if (React.isValidElement(activePage) && activePage.props?.validate) {
      return activePage?.props?.validate(values);
    }
  };

  const handleSubmit = (values: T) => {
    if (isLastPage) {
      return onSubmit(values);
    }
    handleNextPage();
  };

  return (
    <Form<T>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={handleValidation}
      render={({
        handleSubmit: formHandleSubmit,
        hasValidationErrors,
        submitting,
      }) => {
        return (
          <BoFormWizardFormWrapper
            noValidate
            onSubmit={formHandleSubmit}
            name={formName}
            aria-label={formName}
          >
            {activePage}

            <ButtonContainer>
              <PreviousOrCloseButton
                isFirstPage={isFirstPage}
                handlePreviousPage={handlePreviousPage}
                handleCancel={handleCancel}
              />
              {isLastPage ? (
                <BoButton
                  type="submit"
                  disabled={hasValidationErrors || submitting}
                >
                  Submit
                </BoButton>
              ) : (
                <BoButton type="submit" disabled={hasValidationErrors}>
                  Next
                </BoButton>
              )}
            </ButtonContainer>
          </BoFormWizardFormWrapper>
        );
      }}
    />
  );
}
