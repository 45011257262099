import {
  PaymentExtensionFormStateContext,
  PaymentExtensionFormStateProviderContext,
} from "@ops/components/PaymentExtensionForm/PaymentExtensionFormStateProvider";
import { useContext } from "react";

export function usePaymentExtensionFormState(): PaymentExtensionFormStateProviderContext {
  const context = useContext<PaymentExtensionFormStateProviderContext | null>(
    PaymentExtensionFormStateContext
  );

  if (context === null) {
    throw new Error("Using PaymentExtensionFormStateContext outside provider"); // throw error if using this hook outside the provider
  }

  return context;
}
