import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { BillBreakdownComparison } from "@ops/components/BillBreakdownComparison/BillBreakdownComparison";
import { BillBreakdownDetails } from "@ops/components/BillBreakdownDetails/BillBreakdownDetails";
import { BillBreakdownFactors } from "@ops/components/BillBreakdownFactors/BillBreakdownFactors";
import { BillBreakdownGraph } from "@ops/components/BillBreakdownGraph/BillBreakdownGraph";
import { StyledBillBreakdownContainer } from "@ops/components/BillBreakdownModal/BillBreakdownModal.styled";
import React from "react";

interface BillBreakdownModalProps {
  closeModal: () => void;
  invoiceId: string;
}

export const BillBreakdownModal = (props: BillBreakdownModalProps) => {
  const { invoiceId, closeModal } = props;

  const { featureFlagClient } = useFeatureFlagClient();

  const { opsBillBreakdownGraph } = featureFlagClient.getFlags([
    ["opsBillBreakdownGraph", false],
  ]);

  return (
    <BoModal open onClose={closeModal} size="medium">
      <StyledBillBreakdownContainer>
        <Body1 $fontWeight="Bold">Bill Breakdown</Body1>
        <BillBreakdownDetails invoiceId={invoiceId} />
        <BillBreakdownFactors invoiceId={invoiceId} />
        <BillBreakdownComparison invoiceId={invoiceId} />
        {opsBillBreakdownGraph.value ? (
          <BillBreakdownGraph invoiceId={invoiceId} />
        ) : null}
      </StyledBillBreakdownContainer>
    </BoModal>
  );
};
