import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const FormFieldBox = styled.div`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const FampCreateMessageFormContainer = styled.div`
  width: 500px;
`;

export const DatesTitleContainer = styled.div`
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const DatesBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    width: 40%;
  }
`;
