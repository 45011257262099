import { premiseApi } from "@common/api/premiseApi";
import { EdiActions } from "@common/constants/edi.constants";
import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { ReactComponent as SlashedPlug } from "@ops-design-system/icons/SlashedPlug.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { usePremise } from "@ops/hooks/usePremise";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import { useQueryClient } from "@tanstack/react-query";
import React, { MouseEventHandler, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export interface TriggerDisconnectFormValues {
  disconnectLocationCode: string;
}

const StyledBody = styled(BoDialogBody)`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

export const TriggerDisconnect = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise, error } = usePremise(premiseId);
  const [showConfirmMessage, setShowConfirmMessage] = useState<boolean>(false);
  const { open, close, isOpen } = useModalState();
  const dispatch = useDispatch();
  const flash = useRhFlash();
  const queryClient = useQueryClient();
  const disconnectLocationCodeOptions = [
    { label: "Meter Disconnect (MTR)", value: "MTR" },
    { label: "Premium Disconnect Location (PDL)", value: "PDL" },
    { label: "Roll to Other Location (ROL)", value: "ROL" },
  ];

  if (!premise || error) {
    return null;
  }

  const handleTriggerDisconnect = (formValues: TriggerDisconnectFormValues) => {
    return premiseApi.ediMessages
      .create(premise.id, {
        actionName: EdiActions.DISCONNECT,
        ...formValues,
      })
      .then(() => {
        dispatch(premiseRefetch(premise.id));
        queryClient.invalidateQueries({
          queryKey: ["premise", premise.id],
        });
      })
      .catch((_error: RhApiError) => {
        flash.error("Issue updating customer account. Please try again.");
      });
  };

  const closeModal = () => {
    setShowConfirmMessage(false);
    close();
  };

  const handleSubmit = (formValues: TriggerDisconnectFormValues) => {
    closeModal();
    return handleTriggerDisconnect(formValues);
  };

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = (event) => {
    const shouldShowConfirmMessage =
      premise.isConfirmationRequiredForEnrollmentStatus && !showConfirmMessage;

    if (shouldShowConfirmMessage) {
      event.preventDefault();
      setShowConfirmMessage(true);
    }
  };

  return (
    <>
      <BoButton
        onClick={open}
        variant="secondary"
        size="extraSmall"
        icon={SlashedPlug}
        disabled={premise.isCancelled}
      >
        Trigger Disconnect
      </BoButton>
      {isOpen ? (
        <BoModal open={isOpen} onClose={closeModal}>
          <Form<TriggerDisconnectFormValues>
            initialValues={{
              disconnectLocationCode: disconnectLocationCodeOptions[0].value,
            }}
            onSubmit={handleSubmit}
            validateOnBlur
            render={({
              handleSubmit: formHandleSubmit,
              values: { disconnectLocationCode },
            }) => (
              <form noValidate onSubmit={formHandleSubmit}>
                <BoDialogHeader>Trigger Disconnect</BoDialogHeader>
                <StyledBody>
                  {!showConfirmMessage ? (
                    <BoSelectField
                      name="disconnectLocationCode"
                      options={disconnectLocationCodeOptions}
                      label="Disconnect Location Code"
                    />
                  ) : (
                    <>
                      Are you sure you want to trigger a disconnect with
                      location code {disconnectLocationCode}?
                    </>
                  )}
                </StyledBody>
                <BoDialogButtonFooter
                  confirmBtnText="Disconnect"
                  confirmBtnType="submit"
                  cancelBtnText="Close"
                  onCancel={closeModal}
                  onConfirm={handleConfirm}
                />
              </form>
            )}
          />
        </BoModal>
      ) : null}
    </>
  );
};
