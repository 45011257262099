import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ContractRenewalWizardStepContainer } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import styled from "styled-components";

export const SuccessStepContainer = styled(ContractRenewalWizardStepContainer)`
  display: grid;
  gap: ${rhOpsSpacingPx(3)};
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  min-height: 200px;
`;

export const SuccessImageContainer = styled.div`
  width: 100px;
`;

export const SuccessServiceAddress = styled(Body1)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(0.25)};
  text-wrap: balance;
`;

export const SuccessTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
`;
