import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { isOps } from "@common/utils/authenticationHelpers";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { ReactComponent as SidewaysGraph } from "@ops-design-system/icons/SidewaysGraph.svg";
import { AddAverageBillingDialog } from "@ops/components/AverageBillingPreference/AddAverageBillingDialog";
import { EditAverageBillingDialog } from "@ops/components/AverageBillingPreference/EditAverageBillingDialog";
import React, { useContext } from "react";

interface AverageBillingPreferenceProps {
  premise: Premise;
}

export const AverageBillingPreference = ({
  premise,
}: AverageBillingPreferenceProps) => {
  const { open, close, isOpen } = useModalState();
  const { groups } = useContext(AuthGroupsContext);

  if (!premise) {
    return null;
  }

  const disableButton =
    (Boolean(!premise.averagedBillingMonthlyCharge) &&
      isOps(groups) !== true) ||
    premise.hasActiveDpp;

  return (
    <>
      <BoButton
        fullWidth
        variant="secondary"
        size="extraSmall"
        onClick={open}
        disabled={disableButton}
        icon={SidewaysGraph}
      >
        Average Billing
      </BoButton>

      {!premise.averagedBilling ? (
        <AddAverageBillingDialog
          open={isOpen}
          close={close}
          premise={premise}
        />
      ) : (
        <EditAverageBillingDialog
          open={isOpen}
          close={close}
          premise={premise}
        />
      )}
    </>
  );
};
