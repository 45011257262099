import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

export const OpsTextArea = styled.textarea`
  border: 1px solid ${grey["200"]};
  border-radius: 4px;
  height: 120px;
  margin: 8px 0;
  outline: none;
  padding: 12px 12px;
  resize: none;
  width: 100%;
  &:focus {
    border: 1px solid ${darkPurple["500"]};
  }
`;
