import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

export const BoInfoLabel = styled(Body3).attrs({ as: "label" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 2px;
`;

export const BoInfoValue = styled(Body2).attrs({ as: "div" })``;

interface BoInfoContainerProps {
  $error?: boolean;
}

export const BoInfoContainer = styled.div.attrs<BoInfoContainerProps>(
  ({ $error }) => ({
    "aria-invalid": $error,
  })
)<BoInfoContainerProps>`
  color: ${grey[900]};
  &[aria-invalid="true"] {
    color: ${red[500]};
  }
`;
