import { BoToggle } from "@ops-design-system/components/BoToggleField/BoToggle";
import {
  ToggleIndicator,
  ToggleWithIndicatorWrapper,
} from "@ops-design-system/components/BoToggleField/BoToggle.styled";
import { onOff } from "@ops-design-system/utils/booleanHelpers";
import React from "react";
import { useField } from "react-final-form";

interface BoToggleFieldProps {
  name: string;
  withIndicator?: boolean;
}

export const BoToggleField = ({ name, withIndicator }: BoToggleFieldProps) => {
  const { input } = useField<boolean>(name, { type: "checkbox" });
  const { value, checked, ...rest } = input;

  if (!withIndicator) {
    return <BoToggle inputProps={rest} />;
  }
  return (
    <ToggleWithIndicatorWrapper>
      <ToggleIndicator>{onOff(checked).toUpperCase()}</ToggleIndicator>
      <BoToggle inputProps={{ ...rest, checked }} />
    </ToggleWithIndicatorWrapper>
  );
};
