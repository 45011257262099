import { useAjaxState } from "@common/hooks/useAjaxState";
import { StatusIndicator } from "@common/models/StatusIndicator.model";
import { DateType } from "@common/types/apiTypes";
import { StatusIndicatorType } from "@common/types/statusIndicatorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhapsodyPremiseApi } from "@ops-data/api/rhapsodyPremiseApi";
import {
  BoModal,
  BoModalProps,
} from "@ops-design-system/components/BoModal/BoModal";
import { EditActivationDateModal } from "@ops/components/PremiseIndicatorsWizard/EditActivationDateModal/EditActivationDateModal";
import { EditExpirationDateModal } from "@ops/components/PremiseIndicatorsWizard/EditExpirationDateModal/EditExpirationDateModal";
import { EditPremiseIndicatorsModal } from "@ops/components/PremiseIndicatorsWizard/EditPremiseIndicatorsModal/EditPremiseIndicatorsModal";
import {
  CheckboxStatusIndicator,
  PremiseIndicatorsWizardComponentName,
} from "@ops/components/PremiseIndicatorsWizard/PremiseIndicatorsWizardHelpers";
import { useIndicatorsWizardContext } from "@ops/hooks/useIndicatorsWizardContext";
import { usePremiseStatusIndicators } from "@ops/hooks/usePremiseStatusIndicators";
import { sortBy } from "lodash";
import React, { ReactElement, useEffect } from "react";

const WIZARD_COMPONENT: Record<
  PremiseIndicatorsWizardComponentName,
  () => ReactElement
> = {
  [PremiseIndicatorsWizardComponentName.selectIndicators]: () => (
    <EditPremiseIndicatorsModal />
  ),
  [PremiseIndicatorsWizardComponentName.setExpirationDate]: () => (
    <EditExpirationDateModal />
  ),
  [PremiseIndicatorsWizardComponentName.setActivationDate]: () => (
    <EditActivationDateModal />
  ),
};

interface IndicatorDates {
  activationDate: DateType | null;
  expirationDate: DateType | null;
}

export const PremiseIndicatorsWizardModal = ({
  open,
}: Pick<BoModalProps, "open">) => {
  const {
    premiseId,
    activeComponentName,
    updateWizardState,
    resetWizardState,
    closeModal,
  } = useIndicatorsWizardContext();
  const { premiseStatusIndicators } = usePremiseStatusIndicators(premiseId);

  const [
    { data: statusIndicatorList, error },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<StatusIndicator[]>();
  const flash = useRhFlash();

  const numIndicators = statusIndicatorList?.length ?? 0;

  useEffect(() => {
    if (error || numIndicators > 0) {
      return;
    }

    setPending();
    rhapsodyPremiseApi.statusIndicators.premiseTypeStatusIndicators
      .list()
      .then((response) => {
        setSuccess(
          response.map(
            (indicator: StatusIndicatorType) => new StatusIndicator(indicator)
          )
        );
      })
      .catch((e) => {
        setFailure();
        flash.error(
          "An error occurred while fetching all indicators. Please try again."
        );
      });
  }, [error, numIndicators]);

  useEffect(() => {
    if (!open || !statusIndicatorList?.length) {
      return;
    }

    // Creates an array of objects where key is the id and value is the expiration date,
    // this variable will help be used below to determine if initial state of checkbox
    // is checked or not and also to show the expiration date if indicator has one.
    const premiseAssignedStatusIndicators =
      premiseStatusIndicators?.reduce(
        (
          memo: Record<string, IndicatorDates | null>,
          indicator: StatusIndicator
        ) => {
          return {
            ...memo,
            [indicator.id]: {
              activationDate: indicator.activationDate ?? null,
              expirationDate: indicator.expirationDate ?? null,
            },
          };
        },
        {}
      ) ?? {};

    // Creates an array of objects containing the information needed for the checkboxes to display.
    const checkboxStatusIndicator: CheckboxStatusIndicator = sortBy(
      statusIndicatorList,
      "name"
    ).reduce<CheckboxStatusIndicator>(
      (memo, indicator) => ({
        ...memo,
        [indicator.id]: {
          activationDate:
            premiseAssignedStatusIndicators[indicator.id]?.activationDate ??
            null,
          checked: indicator.id in premiseAssignedStatusIndicators,
          expirationDate:
            premiseAssignedStatusIndicators[indicator.id]?.expirationDate ??
            null,
          name: indicator.name,
        },
      }),
      {} as CheckboxStatusIndicator
    );

    // Array of ids sorted by name.
    const sortedStatusIndicatorsIds = sortBy(statusIndicatorList, "name").map(
      (obj) => obj.id
    );

    updateWizardState({
      checkboxStatusIndicator,
      sortedStatusIndicatorsIds,
    });
  }, [open, statusIndicatorList]);

  const handleModalClose = () => {
    closeModal();
    resetWizardState();
  };

  return (
    <BoModal open={open} onClose={handleModalClose} size="large">
      {WIZARD_COMPONENT[activeComponentName]()}
    </BoModal>
  );
};
