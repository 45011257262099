import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { isOps } from "@common/utils/authenticationHelpers";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import {
  EnrollmentRadioGroup,
  EnrollmentValueType,
} from "@ops/components/EnrollmentRadioGroup/EnrollmentRadioGroup";
import { BillingFormValues } from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoTab";
import { ServiceDatePicker } from "@ops/components/ServiceDatePicker/ServiceDatePicker";
import React from "react";
import styled from "styled-components";

const StyledSectionLabel = styled(Body2).attrs({ as: "div" })`
  color: ${grey["500"]};
  font-weight: bold;
  margin-bottom: 12px;
`;

interface ServiceCardProps {
  formValues: Pick<
    BillingFormValues,
    "enrollmentType" | "serviceStartDate" | "offersnapshotId"
  >;
  onChange: (values: Partial<BillingFormValues>) => void;
}

export const ServiceCard = ({ onChange, formValues }: ServiceCardProps) => {
  const { data: groups } = useAuthenticatedUserGroups();

  const handleEnrollmentChange = (enrollmentValue: EnrollmentValueType) => {
    onChange({ enrollmentType: enrollmentValue });
  };
  const handleStartDateChange = (startDateValue: string | null) => {
    onChange({ serviceStartDate: startDateValue });
  };

  return (
    <BoCard>
      <BoGrid $variant={GridVariants.Grid1_1}>
        <div>
          <StyledSectionLabel>Enrollment Type</StyledSectionLabel>
          <EnrollmentRadioGroup
            initialValue={formValues.enrollmentType}
            onChange={handleEnrollmentChange}
          />
        </div>
        <div>
          <ServiceDatePicker
            name="serviceStartDate"
            label="Service Start Date"
            value={formValues.serviceStartDate}
            allowAnyDaysInTheFuture={isOps(groups)}
            onChange={handleStartDateChange}
            offerSnapshotId={formValues?.offersnapshotId || ""}
          />
        </div>
      </BoGrid>
    </BoCard>
  );
};
