import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { OktaGroups } from "@common/constants/okta.constant";
import { intersect } from "@common/utils/arrayUtils";
import { ReactNode, useContext } from "react";

export const useAuthorizeComponent = ({
  allowedGroups,
  component,
  unauthorizedComponent,
}: {
  allowedGroups: OktaGroups[];
  component: ReactNode;
  unauthorizedComponent: ReactNode;
}) => {
  const { groups, hasResolvedGroups } = useContext(AuthGroupsContext);

  if (!hasResolvedGroups) {
    return null;
  }

  if (!groups || groups.length < 1) {
    return null;
  }

  // add heirarchy groups based on permission
  // if Tier 2 -> add Tier 3 (if Tier 2 is allowed, so is Tier 3)
  const additionalGroups: OktaGroups[] = [];

  if (allowedGroups.includes(OktaGroups.CSRTier2)) {
    additionalGroups.push(OktaGroups.CSRTier3);
  }
  // Ops group is always allowed
  additionalGroups.push(OktaGroups.Ops);

  const permittedGroups = allowedGroups.concat(additionalGroups);

  const isPermitted = intersect<OktaGroups>(permittedGroups, groups).length > 0;

  if (isPermitted) {
    return component;
  } else {
    return unauthorizedComponent;
  }
};
