import {
  DeferPaymentFormStateContext,
  DeferPaymentFormStateProviderContext,
} from "@ops/components/DeferPaymentForm/DeferPaymentFormStateProvider";
import { useContext } from "react";

export function useDeferPaymentFormState(): DeferPaymentFormStateProviderContext {
  const context = useContext<DeferPaymentFormStateProviderContext | null>(
    DeferPaymentFormStateContext
  );

  if (context === null) {
    throw new Error(
      "Using DeferPaymentFormStateProviderContext outside provider"
    ); // throw error if using this hook outside the provider
  }
  return context;
}
