import { CustomerSearchType } from "@common/types/apiResponseTypes";
import { formatPhoneNumber } from "@common/utils/dataFormatters";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { premiseCustomerPath } from "@ops/routes/routePaths";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type CustomerTableRowProps = {
  customer: CustomerSearchType;
};

type CustomerTableProps = {
  customers: CustomerSearchType[];
};

const CustomerTableRow = ({ customer }: CustomerTableRowProps) => {
  const opsDashboardLink = premiseCustomerPath(
    customer.id,
    customer.primaryPremiseId,
    "#customer"
  );

  return (
    <BoTableRow data-testid={`customerTable__row:${customer.id}`}>
      <BoTableCell>
        <Link to={opsDashboardLink}>{customer.id}</Link>
      </BoTableCell>
      <BoTableCell>{customer.fullName}</BoTableCell>
      <BoTableCell>{formatPhoneNumber(customer.phone)}</BoTableCell>

      <BoTableCell>
        <Link
          to={opsDashboardLink}
          data-testid={`customerTable__emailLink:${customer.id}`}
        >
          {customer.email}
        </Link>
      </BoTableCell>
      <BoTableCell>{customer.status}</BoTableCell>
      <BoTableCell>{customer.primaryEsiId}</BoTableCell>
      <BoTableCell>
        {customer.primaryServiceAddress.deliveryLine1}{" "}
        {customer.primaryServiceAddress.city}{" "}
        {customer.primaryServiceAddress.zipCode}
      </BoTableCell>
      <BoTableCell>{customer.meterCount}</BoTableCell>
    </BoTableRow>
  );
};

const TableWrapper = styled.div`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;
const ResultCountContainer = styled(Body2).attrs({
  as: "div",
  fontWeight: "Bold",
})``;

interface ResultCountProps {
  count: number;
}
const ResultCount = ({ count }: ResultCountProps) => {
  return <ResultCountContainer>{count} Customers Found</ResultCountContainer>;
};

export const CustomerTable = ({ customers }: CustomerTableProps) => {
  return (
    <TableWrapper>
      <ResultCount count={customers.length} />
      <BoTable>
        <BoTableHead data-testid="customerTableHeader">
          <BoTableRow>
            <BoTableHeadCell>ID</BoTableHeadCell>
            <BoTableHeadCell>Name</BoTableHeadCell>
            <BoTableHeadCell>Phone Number</BoTableHeadCell>
            <BoTableHeadCell>Email</BoTableHeadCell>
            <BoTableHeadCell>Status</BoTableHeadCell>
            <BoTableHeadCell>ESI-ID</BoTableHeadCell>
            <BoTableHeadCell>Service Address</BoTableHeadCell>
            <BoTableHeadCell># of Meters</BoTableHeadCell>
          </BoTableRow>
        </BoTableHead>
        <BoTableBody>
          {customers.map((customer: CustomerSearchType) => (
            <CustomerTableRow customer={customer} key={`row-${customer.id}`} />
          ))}
        </BoTableBody>
      </BoTable>
    </TableWrapper>
  );
};
