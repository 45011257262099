import { StatusIndicatorLevel } from "@common/types/statusIndicatorTypes";
import { Body3 } from "@ops-design-system/components/Typography/Typography";
import {
  blue,
  green,
  grey,
  orange,
  red,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const STATUS_INDICATOR_COLOR = {
  [StatusIndicatorLevel.INFO]: blue["500"],
  [StatusIndicatorLevel.SUCCESS]: green["300"],
  [StatusIndicatorLevel.ALERT]: red["500"],
  [StatusIndicatorLevel.WARN]: orange.main,
};

export const STATUS_TEXT_COLOR = {
  ...STATUS_INDICATOR_COLOR,
  [StatusIndicatorLevel.SUCCESS]: green["500"],
  [StatusIndicatorLevel.WARN]: grey["500"],
};

interface StyledStatusContainerProps {
  $status: StatusIndicatorLevel;
}
export const StyledStatusContainer = styled.div<StyledStatusContainerProps>`
  align-items: center;
  color: ${({ $status }) => STATUS_INDICATOR_COLOR[$status]};
  display: flex;
  & > * {
    margin-right: ${rhOpsSpacingPx(2.5)};
  }

  &:last-child {
    margin-right: 0;
  }
`;

interface StyledStatusProps {
  $status: StatusIndicatorLevel;
}

export const StyledStatus = styled(Body3)<StyledStatusProps>`
  color: ${({ $status }) => STATUS_TEXT_COLOR[$status]};
  text-transform: uppercase;
`;

export const StyledActiveIndicator = styled.div`
  background: currentColor;
  border-radius: 50%;
  height: 6px;
  margin-right: ${rhOpsSpacingPx(0.5)};
  width: 6px;
`;
