import { Body2 } from "@ops-design-system/components/Typography/Typography";
import styled from "styled-components";

interface CheckboxLabelProps {
  $htmlFor: string;
}

export const CheckboxLabel = styled(Body2).attrs<CheckboxLabelProps>(
  ({ $htmlFor }) => ({
    as: "label",
    htmlFor: $htmlFor,
  })
)`
  align-self: center;
`;

interface CheckboxContainerProps {
  $shiftLeft: boolean;
}
export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  margin-left: ${({ $shiftLeft }) => ($shiftLeft ? "-" : "")}12px;
`;
