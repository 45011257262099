import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { ProspectValidationReport } from "@ops-utils/models/ProspectValidationReport.model";
import { useSupportLogsContext } from "@ops/components/SupportLogs/hooks/useSupportLogsContext";
import { Header } from "@ops/components/SupportLogs/shared/Header";
import {
  StyledEmptyText,
  StyledSupportLog,
} from "@ops/components/SupportLogs/SupportLogs.styled";
import { ValidationReportEntryList } from "@ops/components/SupportLogs/ValidationReport/ValidationReportEntryList";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectPremiseMatches } from "@ops/hooks/queries/useProspectPremiseMatches";
import React from "react";
import styled from "styled-components";

const RefetchingAlert = styled.div`
  border: solid 1px;
  border-color: ${darkPurple["500"]};
  border-radius: 5px;
  color: ${darkPurple["500"]};
  margin: 2em;
  padding-bottom: 10px;
  padding-left: 2em;
  padding-top: 10px;
`;

export const ValidationReport = () => {
  const { target } = useSupportLogsContext();
  const premiseMatches = useProspectPremiseMatches({
    prospectId: target.id,
  });

  const { prospect } = useEnrollmentFlowContext();

  const nosRequired = prospect?.creditCheckSummary?.nosRequired;

  const flash = useRhFlash();

  if (premiseMatches.isError) {
    flash.error(
      "An error occurred while attempting to fetch the validation report"
    );

    return (
      <StyledSupportLog>
        <Header />
        <div>
          <StyledEmptyText>
            Failed to retrieve the validation report.
          </StyledEmptyText>
        </div>
      </StyledSupportLog>
    );
  }

  if (premiseMatches.isPending) {
    return (
      <StyledSupportLog>
        <Header />
        <BoCircularProgress position="relative" />
      </StyledSupportLog>
    );
  }

  if (premiseMatches.isSuccess) {
    return (
      <StyledSupportLog>
        <Header />
        {premiseMatches.isRefetching ? (
          <RefetchingAlert>Refreshing ... </RefetchingAlert>
        ) : null}
        <ValidationReportEntryList
          premiseMatches={premiseMatches.data.map(
            (match) => new ProspectValidationReport(match)
          )}
          nosRequired={nosRequired ?? false}
        />
      </StyledSupportLog>
    );
  }
  return <div>Encountered a problem loading the Validation Report.</div>;
};
