import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledEdiMessageCardHeader = styled.div`
  > * {
    margin-bottom: ${rhOpsSpacingPx(1)};
    &:last-child {
      margin-bottom: ${rhOpsSpacingPx(2)};
    }
  }
`;

export const StyledEdiMessageCount = styled(Body1).attrs({ as: "div" })`
  color: ${grey["500"]};
`;
