import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { UsageType } from "@common/types/usageTypes";
import dayjs from "dayjs";
import { useMemo } from "react";

export const useLatestAvailableDate = (
  usageData: UsageType[],
  setSelectedDate: (date: string) => void
) => {
  const latestDate = useMemo(() => {
    if (usageData.length > 0) {
      const latestUsage = usageData.slice(-1).pop();
      const date = dayjs(latestUsage?.datetime).format(ISO8601_DATE_FORMAT);

      setSelectedDate(date);

      return date;
    }

    return "";
  }, [usageData, setSelectedDate]);

  return latestDate;
};
