import {
  BoRadioGroup,
  BoRadioInput,
} from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

const RadioWrapper = styled.div``;

const DownPaymentQuestionnaireHeader = styled(Subtitle2).attrs({
  as: "div",
})`
  padding-bottom: ${rhOpsSpacingPx(2)};
  padding-top: ${rhOpsSpacingPx(2)};
`;

const DownPaymentQuestionnaireWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > * {
    flex: 1;
    width: 100%;
  }
`;

const DownPaymentRadioGroup = styled(BoRadioGroup)``;

interface DownPaymentQuestionnaireProps {
  handleChange: (val: boolean) => void;
  hasMadeDownPayment: boolean | undefined;
}

export const DownPaymentQuestionnaire = ({
  handleChange,
  hasMadeDownPayment,
}: DownPaymentQuestionnaireProps) => {
  const onClick = (value: string | number) => {
    handleChange(value === "yes");
  };

  return (
    <DownPaymentQuestionnaireWrapper>
      <DownPaymentQuestionnaireHeader>
        Has customer paid a down payment?
      </DownPaymentQuestionnaireHeader>
      <DownPaymentRadioGroup>
        <RadioWrapper>
          <BoRadioInput
            checked={Boolean(hasMadeDownPayment)}
            label="Yes"
            value="yes"
            onClick={onClick}
          />
        </RadioWrapper>
        <RadioWrapper>
          <BoRadioInput
            checked={
              hasMadeDownPayment === undefined ? false : !hasMadeDownPayment
            }
            label="No, pay down payment now"
            value="no"
            onClick={onClick}
          />
        </RadioWrapper>
      </DownPaymentRadioGroup>
    </DownPaymentQuestionnaireWrapper>
  );
};
