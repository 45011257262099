// import { DeferPaymentComponentType } from "@ops/components/DeferPaymentWizard/DeferPaymentModal";
import React, { PropsWithChildren, createContext, useState } from "react";

interface ModalStateType {
  activeComponent: DeferPaymentComponentType;
}

export enum DeferPaymentComponentType {
  addPaymentMethod = "addPaymentMethod",
  confirmNoDownPayment = "confirmNoDownPayment",
  deferPaymentForm = "deferPaymentForm",
  loading = "loading",
}

export interface DeferPaymentModalStateProviderContext {
  activeComponent: DeferPaymentComponentType;
  resetModalState: () => void;
  updateModalState: (
    content: Partial<DeferPaymentModalStateProviderContext>
  ) => void;
}

export const initialDeferPaymentModalState = {
  activeComponent: DeferPaymentComponentType.loading,
};

export const DeferPaymentModalStateContext =
  createContext<DeferPaymentModalStateProviderContext>({
    activeComponent: DeferPaymentComponentType.loading,
    resetModalState: () => {},
    updateModalState: (content) => {},
  });

interface DeferPaymentModalStateProviderProps {}
export const DeferPaymentModalStateProvider = ({
  children,
}: PropsWithChildren<DeferPaymentModalStateProviderProps>) => {
  const [modalState, setModalState] = useState<ModalStateType>(
    initialDeferPaymentModalState
  );

  const updateModalState = (content: Partial<ModalStateType>) => {
    setModalState({ ...modalState, ...content });
  };

  const resetModalState = () => {
    updateModalState({
      activeComponent: DeferPaymentComponentType.deferPaymentForm,
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: DeferPaymentModalStateProviderContext = {
    activeComponent: modalState.activeComponent,
    resetModalState,
    updateModalState,
  };

  return (
    <DeferPaymentModalStateContext.Provider value={actions}>
      {children}
    </DeferPaymentModalStateContext.Provider>
  );
};
