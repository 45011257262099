import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { AddressFormType } from "@common/types/customerTypes";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { AddPremiseWizardForm } from "@ops/components/AddPremiseWizard/AddPremiseWizardForm";
import { AddPremiseWizardComponentName } from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";
import { AddressFormFields } from "@ops/components/MeterSearchForms/AddressFormFields";
import { isValidUSAddress } from "@ops/forms/validators";
import { useAddPremiseWizardContext } from "@ops/hooks/contexts/useAddPremiseWizardContext";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

export interface MailingAddressFormValues {
  mailingAddress: AddressFormType;
}
const Container = styled.div`
  width: 480px;
`;
const formValidators =
  generateValidationErrorCollector<MailingAddressFormValues>({
    mailingAddress: isValidUSAddress,
  });

export const MailingAddressForm = () => {
  const {
    wizardState: { mailingAddress },
    updateWizardState,
    setActiveComponentName,
  } = useAddPremiseWizardContext();

  const onSubmit = ({
    mailingAddress: givenMailingAddress,
  }: MailingAddressFormValues) => {
    updateWizardState({ mailingAddress: { ...givenMailingAddress } });
    setActiveComponentName(AddPremiseWizardComponentName.offerForm);
  };

  return (
    <Form<MailingAddressFormValues>
      validate={formValidators}
      initialValues={{ mailingAddress }}
      validateOnChange
      onSubmit={onSubmit}
      render={() => (
        <AddPremiseWizardForm>
          <Container>
            <FormRow>
              <Subtitle2 $fontWeight="Bold">New mailing address</Subtitle2>
            </FormRow>
            <AddressFormFields groupName="mailingAddress" />
          </Container>
        </AddPremiseWizardForm>
      )}
    />
  );
};
