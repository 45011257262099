import { Subtitle1 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import {
  StyledBillBreakdownFactorsContainer,
  StyledFactorsTitle,
} from "@ops/components/BillBreakdownFactors/BillBreakdownFactors.styled";
import { BillBreakdownFactorsBody } from "@ops/components/BillBreakdownFactors/BillBreakdownFactorsBody";
import React from "react";

interface BillBreakdownFactorsProps {
  invoiceId: string;
}

export const BillBreakdownFactors = (props: BillBreakdownFactorsProps) => {
  const { invoiceId } = props;

  return (
    <StyledBillBreakdownFactorsContainer>
      <StyledFactorsTitle color={darkPurple[500]}>
        What changed from last billing cycle?
      </StyledFactorsTitle>
      <Subtitle1>A lot of factors can affect your bill each month.</Subtitle1>
      <Subtitle1>These are the biggest ones.</Subtitle1>
      <BillBreakdownFactorsBody invoiceId={invoiceId} />
    </StyledBillBreakdownFactorsContainer>
  );
};
