import {
  camelizeResponseKeys,
  decamelizeRequestKeys,
  sentryErrorInterceptor,
  toRhythmError,
} from "@common/services/ajaxInterceptors";
import { generateAuthorizationHeader } from "@common/services/generateAuthorizationHeader";
import { generateAxiosHeaders } from "@common/services/generateAxiosHeaders";
import { identity } from "@common/utils/genericFunctions";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestConfig,
} from "axios";
import { decamelizeKeys } from "humps";

const MULTIPART_FORM_BOUNDARY =
  "--RhythmFormBoundary63c5979328c44e2c869349443a94200--";

export const axiosInstance: AxiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    accept: "application/json",
    ...generateAxiosHeaders(),
  },
  paramsSerializer: {
    indexes: null,
  },
  transformRequest: [decamelizeRequestKeys],
  transformResponse: [camelizeResponseKeys],
  withCredentials: true,
});

export const axiosMultiPartFormInstance: AxiosInstance = axios.create({
  headers: {
    "Content-Type": `multipart/form-data; boundary=${MULTIPART_FORM_BOUNDARY}`,
    ...generateAxiosHeaders(),
  },
  transformResponse: [camelizeResponseKeys],
  withCredentials: true,
});

axiosInstance.interceptors.response.use(identity, sentryErrorInterceptor);
axiosInstance.interceptors.response.use(identity, toRhythmError);
axiosMultiPartFormInstance.interceptors.response.use(identity, toRhythmError);

// Most axios config values are common, but app- or environment-driven values can be merged in with this function
// This allows the common lib to have reference to axiosInstance, while giving final say to each app
export function connectApi(config: RawAxiosRequestConfig) {
  Object.assign(axiosInstance.defaults, config);
  Object.assign(axiosMultiPartFormInstance.defaults, config);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AjaxDataType = Record<string, any>;

interface AjaxInterface {
  destroy<ResponseType>(url: string): Promise<ResponseType>;
  get<ResponseType>(
    url: string,
    config?: RawAxiosRequestConfig
  ): Promise<ResponseType>;
  getFile<ResponseType>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ResponseType>;
  patch<ResponseType, DataType = AjaxDataType>(
    url: string,
    data: DataType,
    config?: RawAxiosRequestConfig
  ): Promise<ResponseType>;
  post<ResponseType, DataType = AjaxDataType>(
    url: string,
    data: DataType,
    config?: AxiosRequestConfig
  ): Promise<ResponseType>;
  postFile<ResponseType, DataType = AjaxDataType>(
    url: string,
    data: DataType,
    config?: AxiosRequestConfig
  ): Promise<ResponseType>;
  put<ResponseType, DataType = AjaxDataType>(
    url: string,
    data: DataType,
    config?: AxiosRequestConfig
  ): Promise<ResponseType>;
}

const debugLog = (
  method: string,
  url: string,
  options: {
    data?: AjaxDataType;
    params?: Record<string, string>;
  } = {}
) => {
  if (import.meta.env.VITE_AJAX_LOGGING !== "true") {
    return;
  }

  let message = `[ajax#${method.toUpperCase()}] ${url}`;

  if (options?.params) {
    message += JSON.stringify(options.params);
  }
  if (options?.data) {
    message += JSON.stringify(options.data);
  }
  // eslint-disable-next-line no-console
  console.debug(message);
};

const requestGetHappeningMap: Record<
  string,
  Promise<AxiosResponse> | undefined
> = {};

export const ajax: AjaxInterface = {
  destroy: (url: string, config?: AxiosRequestConfig) => {
    debugLog("destroy", url);

    return axiosInstance
      .delete(url, {
        headers: {
          ...(config?.headers || {}),
          ...generateAuthorizationHeader(),
        },
      })
      .then((res) => (import.meta.env.VITE_CONTRACT_API_ENV ? res : res.data));
  },
  get: (url: string, config?: AxiosRequestConfig) => {
    debugLog("get", url, { params: config?.params });

    const params = config?.params || {};
    const paramsAsString = JSON.stringify(params, Object.keys(params).sort());

    const requestGetHappeningMapKey = url + paramsAsString;

    if (!requestGetHappeningMap[requestGetHappeningMapKey]) {
      requestGetHappeningMap[requestGetHappeningMapKey] = axiosInstance.get(
        url,
        {
          ...config,
          headers: {
            ...(config?.headers || {}),
            ...generateAuthorizationHeader(),
          },
          params: decamelizeKeys(params),
        }
      );
    }

    const request = requestGetHappeningMap[
      requestGetHappeningMapKey
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] as Promise<AxiosResponse<any>>;

    return request
      .then((res) => {
        return import.meta.env.VITE_CONTRACT_API_ENV ? res : res.data;
      })
      .finally(() => {
        delete requestGetHappeningMap[requestGetHappeningMapKey];
      });
  },
  getFile: (url: string, config?: AxiosRequestConfig) => {
    debugLog("getFile", url, { params: config?.params });
    return axiosInstance
      .get(url, {
        ...config,
        headers: {
          ...(config?.headers || {}),
          ...generateAuthorizationHeader(),
        },
        params: decamelizeKeys(config?.params || {}),
        responseType: "blob",
      })
      .then((res) => (import.meta.env.VITE_CONTRACT_API_ENV ? res : res.data));
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch: (url: string, data: any, config?: AxiosRequestConfig) => {
    debugLog("patch", url, { data });
    return axiosInstance
      .patch(url, data, {
        headers: {
          ...(config?.headers || {}),
          ...generateAuthorizationHeader(),
        },
      })
      .then((res) => (import.meta.env.VITE_CONTRACT_API_ENV ? res : res.data));
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: (url: string, data: any, config?: AxiosRequestConfig) => {
    debugLog("post", url, { data });
    return axiosInstance
      .post(url, data, {
        headers: {
          ...(config?.headers || {}),
          ...generateAuthorizationHeader(),
        },
      })
      .then((res) => (import.meta.env.VITE_CONTRACT_API_ENV ? res : res.data));
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postFile: (url: string, data: any, config?: AxiosRequestConfig) => {
    debugLog("postFile", url, { data });
    return axiosMultiPartFormInstance
      .post(url, data, {
        headers: {
          ...(config?.headers || {}),
          ...generateAuthorizationHeader(),
        },
      })
      .then((res) => (import.meta.env.VITE_CONTRACT_API_ENV ? res : res.data));
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put: (url: string, data: any, config?: AxiosRequestConfig) => {
    debugLog("put", url, { data });
    return axiosInstance
      .put(url, data, {
        headers: {
          ...(config?.headers || {}),
          ...generateAuthorizationHeader(),
        },
      })
      .then((res) => (import.meta.env.VITE_CONTRACT_API_ENV ? res : res.data));
  },
};
