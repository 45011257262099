import { rhapsodyCustomerDocumentsApi } from "@ops-data/api/rhapsodyCustomerDocumentsApi";
import { CustomerDocumentsQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useCustomerDocumentTypesQuery = () => {
  return useQuery({
    queryFn: () => rhapsodyCustomerDocumentsApi.types(),
    queryKey: CustomerDocumentsQueryKeys.types(),
  });
};
