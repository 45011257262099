import { premiseApi } from "@common/api/premiseApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  opsAndPricingIsRequired,
  opsAndPricingIsValidDateTodayOrFuture,
} from "@common/forms/validators";
import { Customer } from "@common/models/Customer.model";
import { FormError } from "@common/types/errorTypes";
import { PremiseCreateDataType } from "@common/types/premiseTypes";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { AddPremiseWizardStateType } from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";

const validator = generateValidationErrorCollector<AddPremiseWizardStateType>({
  enrollmentType: [opsAndPricingIsRequired],
  esiId: [opsAndPricingIsRequired],
  mailingAddress: [opsAndPricingIsRequired],
  offerSnapshotId: [opsAndPricingIsRequired],
  startDate: [opsAndPricingIsRequired, opsAndPricingIsValidDateTodayOrFuture],
});

export class AddPremiseSubmission {
  private readonly wizardState: AddPremiseWizardStateType;
  private readonly customer: Customer;
  private newPremiseData: PremiseCreateDataType | undefined;
  private errorMessages: (string | FormError)[] | undefined;

  constructor(wizardState: AddPremiseWizardStateType, customer: Customer) {
    this.wizardState = wizardState;
    this.customer = customer;
  }

  submit() {
    if (!this.newPremiseData) {
      throw Error("Data is not validated");
    }

    return premiseApi.create(this.newPremiseData);
  }

  get isValid() {
    const errors = validator(this.wizardState);

    this.errorMessages = Object.entries(errors).reduce<string[]>(
      (memo, [field, fieldErrors]) => {
        if (Array.isArray(fieldErrors) && fieldErrors.length > 0) {
          memo.push(convertCamelCaseToSentenceCase(field));
        }

        return memo;
      },
      []
    );

    const valid = this.errorMessages.length === 0;

    if (valid) {
      this.assignNewPremiseData();
    }

    return valid;
  }

  get errors(): (string | FormError)[] {
    if (this.errorMessages) {
      return this.errorMessages;
    }

    throw Error(
      "Must validate data by running isValid() before calling errors"
    );
  }

  private assignNewPremiseData() {
    const {
      esiId,
      startDate,
      mailingAddress,
      offerSnapshotId,
      paperless,
      enrollmentType,
      priorityEnrollment,
    } = this.wizardState;

    if (!startDate || !offerSnapshotId || !enrollmentType) {
      throw Error("Data is not validated");
    }

    this.newPremiseData = {
      customerId: this.customer.id,
      eBill: paperless,
      enrollmentType,
      mailingAddress,
      meterId: esiId,
      offerId: offerSnapshotId,
      priorityEnrollment,
      serviceStartDate: startDate,
    };
  }
}
