import { IdType } from "@common/types/apiTypes";

export const BATCH_CONTACT_LOG_UPLOAD_ERROR_CODE =
  "rhythm.support_logs.batch_upload_contact_logs";

export const enum TargetType {
  CUSTOMER = "customer",
  PROSPECT = "prospect",
}

export enum LogType {
  CONTACT = "contact",
  EVENT = "event",
  VALIDATION = "validation",
}

export interface SupportLogTarget {
  id: IdType;
  type: TargetType;
}

export interface SupportLogsContextType {
  activeLog: LogType;
  setActiveLog: (log: LogType) => void;
  target: SupportLogTarget;
}
