import { ajax } from "@common/services/ajax";
import {
  FidelityCashPaymentFileListResponseType,
  RSASignatureResponseType,
  UploadFidelityCashPaymentResponseType,
} from "@common/types/apiResponseTypes";

import { externalPaymentsUrls } from "./externalPaymentsUrls";

export const externalPaymentsApi = {
  fetchRSASignature: (pageId: string): Promise<RSASignatureResponseType> => {
    return ajax.get(externalPaymentsUrls.rsaSignature(), {
      params: { pageId },
    });
  },
  files: {
    create: (
      paymentFile: File
    ): Promise<UploadFidelityCashPaymentResponseType> => {
      const data = new FormData();

      data.append("file", paymentFile, paymentFile.name);
      return ajax.postFile(externalPaymentsUrls.files.upload(), data);
    },
    list: (
      source: string
    ): Promise<FidelityCashPaymentFileListResponseType> => {
      const data = { params: { limit: 1000, source } };

      return ajax.get(externalPaymentsUrls.files.list(), data);
    },
  },
};
