import { Box, BoxProps } from "@mui/material";
import React, { FC } from "react";

type RhFlexBoxProps = Omit<BoxProps, "display">;

export const RhFlexBox: FC<React.PropsWithChildren<RhFlexBoxProps>> = ({
  children,
  ...props
}) => {
  return (
    <Box display="flex" {...props}>
      {children}
    </Box>
  );
};
