import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import { rootSaga } from "@ops/sagas/root.saga";
import { customerReducer } from "@ops/slices/customerSlice";
import { paymentMethodsReducer } from "@ops/slices/paymentMethodsSlice";
import { premiseReducer } from "@ops/slices/premiseSlice";
import { premiseStatusIndicatorsReducer } from "@ops/slices/premiseStatusIndicatorsSlice";
import {
  ReducersMapObject,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

export const reducers: ReducersMapObject = {
  customer: customerReducer,
  paymentMethods: paymentMethodsReducer,
  premise: premiseReducer,
  premiseStatusIndicators: premiseStatusIndicatorsReducer,
};

const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware(), sagaMiddleware];

export const store = (() => {
  const innerStore = configureStore({
    devTools: !isProduction(),
    middleware,
    reducer: combineReducers(reducers),
  });

  sagaMiddleware.run(rootSaga);

  return innerStore;
})();
