import { billingApi } from "@common/api/billingApi";
import { useDebounce } from "@common/hooks/useDebounce";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { zuora } from "@common/utils/zuora.util";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  ZUORA_CREDIT_CARD_PAYMENT_PAGE_ID,
  ZUORA_URL,
} from "@ops/settings/config";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface ZuoraCardFormProps {
  onFailure?(error: string): void;
  onFormLoading?(isPending: boolean): void;
  onSuccess(zuoraResponse: ZuoraAddPaymentResponseType): void;
  premiseId: IdType | null;
  setAsDefault?: boolean;
}

const ZuoraFormContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const ZuoraCardForm = ({
  onSuccess,
  onFormLoading,
  onFailure,
  premiseId,
  setAsDefault,
}: ZuoraCardFormProps) => {
  const flash = useRhFlash();
  const [isZuoraFormRendering, setIsZuoraFormRendering] =
    useState<boolean>(false);
  const zuoraCardForm = useRef<HTMLDivElement>(null);

  const zuoraParams = {
    id: ZUORA_CREDIT_CARD_PAYMENT_PAGE_ID,
    locale: "en",
    param_supportedTypes:
      "AmericanExpress,JCB,Visa,MasterCard,Discover,Dankort",
    style: "inline",
    submitEnabled: "true",
    url: `${ZUORA_URL}/apps/PublicHostedPageLite.do`,
  };
  const setZuoraFormLoading = (loading: boolean) => {
    setIsZuoraFormRendering(loading);
    onFormLoading?.(loading);
  };

  /**
   * Zuora can return multiple empty error messages (like when a user hits submit without filling cc form)
   * We are just debouncing this so at most only one flash error is shown. If form is partially filled
   * then another message will be shown
   */
  const debouncedClientErrorMessageCallback = useDebounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      flash.error(errorDescription);
    },
    50
  );

  useEffect(() => {
    setZuoraFormLoading(true);
    billingApi
      .fetchRSASignature(zuoraParams.id)
      .then((signature) => {
        zuora.setEventHandler("onloadCallback", () => {
          setZuoraFormLoading(false);
        });

        zuora.renderWithErrorHandler(
          {
            ...zuoraParams,
            ...signature,
          },
          {},
          async (response: ZuoraAddPaymentResponseType) => {
            const errorMessage =
              "Unable to add card. Please verify your card information and try again.";

            if (!response.success) {
              onFailure?.(errorMessage);

              return;
            }

            if (premiseId) {
              const [error] = await handleAjaxCall(
                billingApi.paymentMethods.update(response.refId, {
                  premiseId,
                })
              );

              if (error) {
                onFailure?.(errorMessage);

                return;
              }

              if (setAsDefault) {
                const [err] = await handleAjaxCall(
                  billingApi.paymentMethods.default(response.refId, {
                    premiseId,
                  })
                );

                if (err) {
                  onFailure?.(
                    "Could not set this payment method as the default payment method."
                  );
                }
              }
            }

            // Everything went well
            onSuccess(response);
          },
          debouncedClientErrorMessageCallback
        );
      })
      .catch((err: RhApiError) => {
        onFailure?.(err.data.errorCode ?? "Something went wrong");
      });
  }, []);

  return (
    <>
      {isZuoraFormRendering && <RhCircularProgress marginBottom={4} />}
      <div ref={zuoraCardForm}>
        <ZuoraFormContainer id="zuora_payment" data-testid="zuoraPaymentForm" />
      </div>
    </>
  );
};
