import { BaseErrorPage } from "@ops/pages/BaseErrorPage/BaseErrorPage";
import React from "react";

export const Error404Page = () => {
  const code = "404";
  const header = "This is awkward";
  const message =
    "We're not sure what you were looking for, but it's not here.";

  return (
    <BaseErrorPage
      code={code}
      header={header}
      message={message}
      showLinkToHome
    />
  );
};
