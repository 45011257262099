import { grey, red } from "@ops-design-system/styles/palette.colors";
import styled, { css } from "styled-components";

export const StyledPhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  > * {
    border-bottom: 1px dashed ${grey[100]};
    padding: 0.75rem;

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const StyledStatusText = styled.div<{ $optOut: boolean }>`
  ${({ $optOut }) =>
    $optOut &&
    css`
      color: ${red.main};
    `}
`;
