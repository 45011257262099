import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { BillingErrors, RhApiError } from "@common/types/errorTypes";
import { isOps } from "@common/utils/authenticationHelpers";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  Body1,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { Prospect } from "@ops-utils/models/Prospect.model";
import { customerTabs } from "@ops-utils/types/customerDashboardTypes";
import { ConfirmationBillingCard } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationBillingCard/ConfirmationBillingCard";
import { ConfirmationContactInfoCard } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationContactInfoCard/ConfirmationContactInfoCard";
import { ConfirmationMarketingCard } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationMarketingCard/ConfirmationMarketingCard";
import { ConfirmationPlanCard } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationPlanCard/ConfirmationPlanCard";
import { ConfirmationProductAddonsCard } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationProductAddonsCard/ConfirmationProductAddonsCard";
import { ConfirmationServiceAddressCard } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationServiceAddressCard/ConfirmationServiceAddressCard";
import { ProspectSubmission } from "@ops/components/ProspectEditor/ConfirmationTab/Services/ProspectSubmission.service";
import { StyledTab } from "@ops/components/ProspectEditor/shared/ProspectEditor.styled";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { premiseCustomerPath } from "@ops/routes/routePaths";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const CreatedAt = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ConfirmationActionRow = styled(BoFlexBox)`
  align-items: flex-start;
  & > * {
    flex-basis: 175px;
  }
`;

const ConfirmationCheckRow = styled.div``;

const ConfirmationCheckLabel = styled(BoLabel)`
  align-items: center;
  display: flex;
  & > * {
    margin-left: ${rhOpsSpacingPx(1)};
  }
  .MuiCheckbox-root {
    margin: 0;
    padding: 0;
  }
`;

const ConfirmationMessage = styled(Body3)`
  color: ${grey[900]};
`;

const getErrorMessage = (error: RhApiError) => {
  const errorCodes = error.data.errors.map((e) => e.code);
  let errorMessage = "Failed to create customer";

  if (errorCodes.includes(BillingErrors.ZUORA_PAYMENT_FAILED)) {
    errorMessage += ": Deposit Payment Failed";
  }

  if (errorCodes.includes(BillingErrors.ZUORA_CONNECTION_ERROR)) {
    errorMessage += ": Connection to Zuora Failed";
  }

  if (errorCodes.includes(BillingErrors.ZUORA_PAYMENT_METHOD_NOT_FOUND)) {
    errorMessage += ": Payment method not found";
  }

  if (errorCodes.includes(BillingErrors.ZUORA_DEPOSIT_ALTERNATIVE_FAILED)) {
    errorMessage += ": Creation of deposit alternative failed";
  }

  if (errorCodes.includes(BillingErrors.ZUORA_DEPOSIT_PAYMENT_ERROR)) {
    errorMessage += ": Deposit Payment Failed";
  }

  if (errorCodes.includes(BillingErrors.ZUORA_DEPOSIT_ERROR)) {
    errorMessage += ": Creation of deposit order failed";
  }

  return errorMessage;
};

interface ConfirmationTabProps {
  submitSuccessDelay?: number;
}

export const ConfirmationTab = (props: ConfirmationTabProps) => {
  const { submitSuccessDelay = 5000 } = props;
  const { prospect: prospectData } = useEnrollmentFlowContext();
  const prospect = new Prospect(prospectData);
  const [confirmationCheck, setConfirmationCheck] = useState<boolean>();
  const [{ requestMonitor }, { setPending, setFailure, setSuccess }] =
    useAjaxState();
  const { groups } = useContext(AuthGroupsContext);
  const navigate = useNavigate();
  const flash = useRhFlash();

  const submissionService = new ProspectSubmission(prospect, {
    isOps: isOps(groups),
  });
  const readyToSubmit = submissionService.isValid;
  const { errors, errorMessages } = submissionService;

  const submitEnrollment = () => {
    if (submissionService.isValid) {
      setPending();

      submissionService
        .submit(submitSuccessDelay)
        .then(({ id, premiseIds }) => {
          setSuccess();

          navigate(
            // React router expects query params before the hash
            `${premiseCustomerPath(
              id,
              premiseIds[0]
            )}?confirmation-number=${id}${customerTabs[0]}`
          );
        })
        .catch((error: RhApiError) => {
          setFailure();

          const errorMessage = getErrorMessage(error);

          flash.error(errorMessage);
        });
    }
  };

  return (
    <StyledTab>
      <CreatedAt>
        <Body1 $fontWeight="SemiBold">Prospect Created At:&nbsp;</Body1>
        <Body1>{formatMonthDayYear(prospect.createdAt)}</Body1>
      </CreatedAt>
      <ConfirmationMarketingCard
        prospect={prospect}
        errors={errors?.marketing}
      />
      <ConfirmationServiceAddressCard
        prospect={prospect}
        errors={errors?.serviceAddress}
      />
      <ConfirmationPlanCard prospect={prospect} errors={errors?.plan} />
      <ConfirmationProductAddonsCard
        prospect={prospect}
        errors={errors?.productAddons}
      />
      <ConfirmationContactInfoCard
        prospect={prospect}
        errors={errors?.contactInfo}
      />
      <ConfirmationBillingCard
        prospect={prospect}
        errors={errors?.billing}
        errorMessages={errorMessages?.billing}
      />
      <ConfirmationCheckRow>
        <ConfirmationCheckLabel>
          <BoCheckboxInput
            disabled={!readyToSubmit}
            onClick={() => {
              setConfirmationCheck(!confirmationCheck);
            }}
          />
          <ConfirmationMessage>
            By checking this box, I agree to establish Rhythm as my retail
            electric provider, and I agree to the Terms of Service, Your Rights
            As A Consumer, and Electricity Facts Label documents associated with
            my plan. I also authorize Rhythm to switch, establish, or change my
            service.
          </ConfirmationMessage>
        </ConfirmationCheckLabel>
      </ConfirmationCheckRow>
      <ConfirmationActionRow>
        <BoButton
          disabled={!readyToSubmit || !confirmationCheck}
          onClick={submitEnrollment}
          loading={requestMonitor.isPending}
        >
          Complete Enrollment
        </BoButton>
      </ConfirmationActionRow>
    </StyledTab>
  );
};
