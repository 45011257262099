import { IdType } from "@common/types/apiTypes";
import { rhapsodyInvoicesApi } from "@ops-data/api/rhapsodyInvoicesApi";
import { InvoiceQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseBillBreakdownFactorsQueryProps {
  invoiceId: IdType;
}

export const useBillBreakdownFactorsQuery = (
  props: UseBillBreakdownFactorsQueryProps
) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => rhapsodyInvoicesApi.factors(invoiceId),
    queryKey: InvoiceQueryKeys.factors(invoiceId),
  });
};
