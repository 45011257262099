import { IdType } from "@common/types/apiTypes";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import {
  AutopayPaperlessCheckboxContainer,
  AutopayPaperlessContainer,
  StyledCard,
  StyledLabelText,
  WarningMessageContainer,
  WarningMessageText,
} from "@ops/components/ProspectEditor/BillingInfoTab/AutopayPaperlessCard/AutopayPaperlessCard.styled";
import { BillingFormValues } from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoTab";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import React, { useId } from "react";

interface AutopayPaperlessCardProps {
  formValues: Pick<BillingFormValues, "autopay" | "isPaperless">;
  offerSnapshotId: IdType | null;
  updateFormValues: (values: Partial<BillingFormValues>) => void;
}

export const AutopayPaperlessCard = (props: AutopayPaperlessCardProps) => {
  const { formValues, updateFormValues, offerSnapshotId } = props;

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: offerSnapshotId ?? "",
    queryOptions: { enabled: Boolean(offerSnapshotId) },
  });

  const paperlessCheckboxId = useId();
  const autoPayCheckboxId = useId();

  if (offerSnapshotId && offerSnapshotQuery.isPending) {
    return (
      <BoCard>
        <BoCircularProgress />
      </BoCard>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const showAutopayPaperlessDiscountWarning = Boolean(
    offerSnapshot &&
      offerSnapshot.isAutoPayPaperlessDiscountOffer &&
      (!formValues.autopay || !formValues.isPaperless)
  );

  return (
    <BoCard>
      {showAutopayPaperlessDiscountWarning ? (
        <WarningMessageContainer>
          <WarningMessageText color="red">
            Paperless & Auto Pay must be selected to be eligible to receive the
            Auto Pay and Paperless Discount
          </WarningMessageText>
          <BoDivider />
        </WarningMessageContainer>
      ) : null}
      <AutopayPaperlessContainer>
        <StyledCard>
          <AutopayPaperlessCheckboxContainer>
            <BoCheckboxInput
              id={paperlessCheckboxId}
              checked={formValues.isPaperless}
              onChange={() =>
                updateFormValues({
                  isPaperless: !formValues.isPaperless,
                })
              }
            />
            <BoLabel htmlFor={paperlessCheckboxId}>
              <StyledLabelText>Paperless</StyledLabelText>
            </BoLabel>
          </AutopayPaperlessCheckboxContainer>
        </StyledCard>
        <StyledCard>
          <AutopayPaperlessCheckboxContainer>
            <BoCheckboxInput
              id={autoPayCheckboxId}
              checked={formValues.autopay ?? false}
              onChange={() =>
                updateFormValues({ autopay: !formValues.autopay })
              }
            />
            <BoLabel htmlFor={autoPayCheckboxId}>
              <StyledLabelText>Auto Pay</StyledLabelText>
            </BoLabel>
          </AutopayPaperlessCheckboxContainer>
        </StyledCard>
      </AutopayPaperlessContainer>
    </BoCard>
  );
};
