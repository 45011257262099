import { IdType } from "@common/types/apiTypes";
import {
  formatAsLocalTime,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import {
  DateTimeWrapper,
  LeftContainer,
  StyledDate,
  StyledEntry,
  StyledTextWrapper,
  StyledTextWrapperSmall,
  StyledTime,
} from "@ops/components/SupportLogs/SupportLogs.styled";
import React, { ComponentType, PropsWithChildren } from "react";
import Linkify from "react-linkify";
import styled from "styled-components";

const StyledCategoryOptions = styled(StyledTextWrapperSmall)`
  color: ${grey["600"]};
  font-style: italic;
  margin-top: ${rhOpsSpacingPx(0.5)};
`;

interface ContactLogEntryProps {
  category: string | JSX.Element;
  contactMethod: string | JSX.Element;
  createdAt: string;
  csrName: string | JSX.Element;
  id: IdType;
  notes: string | JSX.Element;
  // eslint-disable-next-line react/no-unused-prop-types
  outboundContact: boolean;
  reason: string | JSX.Element;
  type: string | JSX.Element;
}

interface BoLinkifyProps {}

const LinkifySafeForReact18 = Linkify as ComponentType<Linkify["props"]>;

const BoLinkify = ({ children }: PropsWithChildren<BoLinkifyProps>) => {
  return (
    <LinkifySafeForReact18
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="_blank" href={decoratedHref} key={key} rel="noreferrer">
          {decoratedText}
        </a>
      )}
    >
      {children}
    </LinkifySafeForReact18>
  );
};

const NotesWrapper = styled(StyledTextWrapper)`
  overflow-wrap: anywhere;
`;

export const ContactLogEntry = ({
  createdAt,
  notes,
  contactMethod,
  type,
  category,
  reason,
  id,
  csrName,
}: ContactLogEntryProps) => {
  const date = formatMonthDayShortYear(createdAt);
  const time = formatAsLocalTime(createdAt);

  return (
    <StyledEntry data-testid={`contactLog__entry:${id}`}>
      <LeftContainer>
        <DateTimeWrapper>
          <StyledDate>{date}</StyledDate> <StyledTime>{time}</StyledTime>
        </DateTimeWrapper>
        <StyledTextWrapperSmall>
          <div>{csrName}</div>
          <div>{contactMethod}</div>
        </StyledTextWrapperSmall>
      </LeftContainer>
      <div>
        <NotesWrapper>
          <BoLinkify>{notes}</BoLinkify>
        </NotesWrapper>
        <StyledCategoryOptions>
          {category}
          {" > "}
          {type}
          {reason && (
            <span>
              {" > "} {reason}
            </span>
          )}
        </StyledCategoryOptions>
      </div>
    </StyledEntry>
  );
};
