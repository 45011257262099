import { ComparisonDataType } from "@common/components/BillComparisonChart/types";
import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { ChartData } from "chart.js";

interface GetChartDataArgs {
  comparisionData: ComparisonDataType;
}

export const getBillComparisonChartData = ({
  comparisionData,
}: GetChartDataArgs) => {
  const data: number[] = [
    Number(comparisionData.previousPeriod?.consumption || 0),
    Number(comparisionData.currentPeriod.consumption),
  ];

  const backgroundColors = [
    brandTheme.colors.primary.light,
    brandTheme.colors.secondary.dark,
  ];

  if (comparisionData.previousYearPeriod) {
    data.unshift(Number(comparisionData.previousYearPeriod.consumption));
    backgroundColors.unshift(brandTheme.colors.primary.light);
  }

  return {
    datasets: [
      {
        backgroundColor: backgroundColors,
        borderRadius: {
          topLeft: 6,
          topRight: 6,
        },
        data,
      },
    ],
  } as ChartData<"bar">;
};
