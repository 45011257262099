import { OktaGroups } from "@common/constants/okta.constant";
import { IdType } from "@common/types/apiTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import { BoSelectInput } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { OpsRadioInput } from "@ops-design-system/components/OpsRadioInput/OpsRadioInput";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as DownloadIcon } from "@ops-design-system/icons/DownloadIcon2.svg";
import { CustomerDocumentType } from "@ops-utils/types/customerDocumentTypes";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import {
  StyledDocumentRadioInput,
  StyledDocumentTypeSelect,
  StyledDocumentsTabContainer,
  StyledDocumentsTabRow,
  StyledDownloadDocumentButton,
  StyledUploadButtonContainer,
} from "@ops/components/CustomerDashboard/DocumentsTab/DocumentsTab.styled";
import { UploadCustomerDocumentModal } from "@ops/components/UploadCustomerDocumentModal/UploadCustomerDocumentModal";
import { useCustomerDocumentDownloadMutation } from "@ops/hooks/mutations/useCustomerDocumentDownload.mutation";
import { useCustomerDocumentsQuery } from "@ops/hooks/queries/useCustomerDocuments.query";
import { useCustomerDocumentTypesQuery } from "@ops/hooks/queries/useCustomerDocumentTypes.query";
import { usePremise } from "@ops/hooks/usePremise";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const DocumentsTab = () => {
  const [showPremiseDocuments, setShowPremiseDocuments] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: "uploadDate" },
  ]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { id: customerId, premiseId } = useParams<{
    id: IdType;
    premiseId: IdType;
  }>();
  const { premise } = usePremise(premiseId);

  const customerDocumentsQuery = useCustomerDocumentsQuery({
    searchOptions: {
      customerId,
      documentType,
      limit: pageSize,
      offset: pageIndex * pageSize,
      ordering: sorting[0].desc ? "-stored_at" : "stored_at",
      premiseId: showPremiseDocuments ? premiseId : "",
    },
  });

  const documentTypesQuery = useCustomerDocumentTypesQuery();

  const customerDocumentDownloadMutation =
    useCustomerDocumentDownloadMutation();

  const flash = useRhFlash();

  useEffect(() => {
    if (customerDocumentsQuery.isError) {
      flash.error(
        "We are having issues retrieving customer documents. Please try again later."
      );
    }
  }, [customerDocumentsQuery.isError, flash]);

  const columnHelper = createColumnHelper<CustomerDocumentType>();

  const columns = [
    columnHelper.display({
      cell: ({ row }) => row.original.documentType,
      header: "Document Type",
      id: "documentType",
      size: 200,
    }),
    columnHelper.display({
      cell: ({ row }) => row.original.documentName,
      header: "Document Name",
      id: "documentName",
    }),
    columnHelper.display({
      cell: ({ row }) => formatMonthDayYear(row.original.storedAt),
      enableSorting: true,
      header: "Upload Date",
      id: "uploadDate",
      size: 130,
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <StyledDownloadDocumentButton
          title="Download Customer Document"
          onClick={() => {
            customerDocumentDownloadMutation.mutate(
              {
                documentId: row.original.id,
                documentName: row.original.documentName,
              },
              {
                onError: () => {
                  flash.error(
                    "We are having issues retrieving that customer document. Please try again later."
                  );
                },
              }
            );
          }}
        >
          <DownloadIcon />
        </StyledDownloadDocumentButton>
      ),
      header: "Download",
      id: "download",
      size: 100,
    }),
  ];

  const pageCount = customerDocumentsQuery.data?.count
    ? Math.ceil(customerDocumentsQuery.data.count / pageSize)
    : 0;

  return (
    <StyledDocumentsTabContainer>
      <AccessControl allowedGroups={[OktaGroups.CSRTier2]}>
        <Body2 $fontWeight="SemiBold">Document Upload</Body2>
        <StyledUploadButtonContainer>
          <UploadCustomerDocumentModal
            customerId={customerId}
            premiseId={premiseId}
            premiseAccountNumber={premise?.billingAccountNumber ?? ""}
          />
        </StyledUploadButtonContainer>
      </AccessControl>
      <StyledDocumentsTabRow>
        <StyledDocumentRadioInput>
          <OpsRadioInput
            id="customer-documents"
            checked={!showPremiseDocuments}
            onChange={() => setShowPremiseDocuments(false)}
          />
          <label htmlFor="customer-documents">
            <Body1>Customer ID: </Body1>
            <Body1 $fontWeight="Bold">{customerId}</Body1>
          </label>
        </StyledDocumentRadioInput>
        <StyledDocumentRadioInput>
          <OpsRadioInput
            id="premise-documents"
            checked={showPremiseDocuments}
            onChange={() => setShowPremiseDocuments(true)}
          />
          <label htmlFor="premise-documents">
            <Body1>Billing Account Number: </Body1>
            <Body1 $fontWeight="Bold">{premise?.billingAccountNumber}</Body1>
          </label>
        </StyledDocumentRadioInput>
      </StyledDocumentsTabRow>
      <StyledDocumentsTabRow>
        <Body2 $fontWeight="SemiBold">Document Upload History</Body2>
        <StyledDocumentTypeSelect>
          <Body2 id="filterby_document_type" as="label" $fontWeight="Bold">
            Document Type
          </Body2>
          <BoSelectInput
            ariaLabelledby="filterby_document_type"
            id="document-type"
            value={documentType}
            placeholder="Choose Document"
            isClearable
            onChange={(option) =>
              option
                ? setDocumentType(String(option.value || ""))
                : setDocumentType("")
            }
            options={(documentTypesQuery.data?.types || []).map((docType) => ({
              label: docType,
              value: docType,
            }))}
          />
        </StyledDocumentTypeSelect>
      </StyledDocumentsTabRow>
      {customerDocumentsQuery.isPending ? (
        <BoCircularProgress />
      ) : (
        <BoReactTable<CustomerDocumentType>
          columns={columns}
          tableCaption="Document Upload History"
          pagination
          setPagination={setPagination}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          data={customerDocumentsQuery.data?.results ?? []}
          sorting={sorting}
          setSorting={setSorting}
        />
      )}
    </StyledDocumentsTabContainer>
  );
};
