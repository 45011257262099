import { IdType } from "@common/types/apiTypes";
import {
  makeAPaymentFormAtom,
  makeAPaymentStepAtom,
  paymentSuccessPaylodAtom,
} from "@ops/components/MakeAPayment/MakeAPayment.atoms";
import {
  MakeAPaymentFormValues,
  MakeAPaymentStepType,
  PaymentSuccessPayloadType,
} from "@ops/components/MakeAPayment/MakeAPayment.types";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";

export const useMakeAPaymentStep = (premiseId: IdType) => {
  const setMakeAPaymentStepAtom = useSetAtom(makeAPaymentStepAtom);
  const setPaymentSuccessPaylodAtom = useSetAtom(paymentSuccessPaylodAtom);
  const setMakeAPaymentFormAtom = useSetAtom(makeAPaymentFormAtom);

  const { paymentMethods } = usePaymentMethods(premiseId);

  const setMakeAPaymentStep = ({
    step,
    payload,
    formValues,
  }: {
    formValues?: MakeAPaymentFormValues;
    payload?: PaymentSuccessPayloadType;
    step: MakeAPaymentStepType | "initial";
  }) => {
    if (step === "initial") {
      (paymentMethods?.length || 0) > 0
        ? setMakeAPaymentStepAtom("makeAPaymentForm")
        : setMakeAPaymentStepAtom("noPaymentOptions");
    } else {
      setMakeAPaymentStepAtom(step);
    }

    if (payload) {
      setPaymentSuccessPaylodAtom(payload);
    }

    if (formValues) {
      setMakeAPaymentFormAtom(formValues);
    }
  };

  const resetMakeAPaymentAtoms = () => {
    setMakeAPaymentStep({ step: "initial" });
    setMakeAPaymentFormAtom(RESET);
    setPaymentSuccessPaylodAtom(RESET);
  };

  return { resetMakeAPaymentAtoms, setMakeAPaymentStep };
};
