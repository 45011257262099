import { Body3 } from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
`;

export const SelectLabel = styled<typeof BoLabel>(Body3).attrs({ as: "label" })`
  display: block;
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(0.5)};
`;
