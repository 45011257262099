import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import {
  darkPurple,
  grey,
  purple,
  red,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { HTMLProps } from "react";
import styled from "styled-components";

interface StyledLabelProps {
  $hasErrors?: boolean;
  $isFocused?: boolean;
  htmlFor?: string;
}

const labelColor = ({ $isFocused, $hasErrors }: StyledLabelProps) => {
  if ($hasErrors) {
    return red["500"];
  } else if ($isFocused) {
    return darkPurple["500"];
  }
  return grey["500"];
};

const StyledLabel = styled.label<StyledLabelProps>`
  color: ${labelColor};
  display: block;
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${rhOpsSpacingPx(0.5)};
`;

export const BoFieldIconContainer = styled.span`
  bottom: 11px;
  color: ${purple.main};
  pointer-events: none;
  position: absolute;
  right: 12px;
  z-index: 0;
`;
export const FormRow = styled.div`
  padding-bottom: ${rhOpsSpacingPx(2.5)};
`;
export const FormFlexRow = styled(FormRow)`
  display: flex;
  flex-direction: row;
`;

interface BoLabelProps
  extends StyledLabelProps,
    Omit<HTMLProps<HTMLLabelElement>, "ref" | "as"> {
  requiredIndicator?: boolean;
}

export const BoLabel = ({
  requiredIndicator,
  children,
  ...props
}: BoLabelProps) => {
  return (
    <StyledLabel {...props}>
      {children}
      {requiredIndicator ? "*" : ""}
    </StyledLabel>
  );
};

export const DocumentLink = styled(BoLink)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-decoration: none !important;
  & > :last-child {
    flex: 1;
    text-align: right;
  }
  & > * {
    margin-right: ${rhOpsSpacingPx(1)};
    &:last-child {
      margin-right: 0;
    }
  }
`;
