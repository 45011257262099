import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import {
  StyledInputContainer,
  StyledLabel,
  StyledMobileDatePicker,
  datePickerLayoutSxStyles,
} from "@design-system/components/RhCalendarDatePicker/RhCalendarDatePicker.styled";
import { CalendarHeader } from "@design-system/components/RhCalendarDatePicker/RhCalendarHeader";
import { DatePickerToolbarProps, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import React, { JSXElementConstructor } from "react";

interface RhCalendarDatePickerProps {
  allowedDates?: Dayjs[];
  cancelLabel: string;
  closeOnSelect?: boolean;
  disabled?: boolean;
  format?: string;
  hasErrors?: boolean;
  inputId?: string;
  inputName: string;
  label: string;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  okLabel: string;
  onChange: (value: Dayjs) => void;
  placeholder?: string;
  value?: Dayjs;
}

const createShouldDisableDate = (allowedDates: Dayjs[]) => {
  return (target: Dayjs) => !allowedDates.some((d) => d.isSame(target));
};

export const RhCalendarDatePicker = (props: RhCalendarDatePickerProps) => {
  const {
    allowedDates,
    cancelLabel,
    closeOnSelect = false,
    disabled,
    format,
    hasErrors = false,
    inputId = "RhCalendarDatePicker",
    inputName,
    label,
    maxDate,
    minDate,
    okLabel,
    onChange,
    placeholder = "",
    value,
  } = props;

  // StyledMobileDatePicker<DayJs> gives a TS error, so this is a work-around
  const DatePicker = StyledMobileDatePicker as typeof MobileDatePicker;
  const buttonsText = {
    cancelButtonLabel: cancelLabel,
    okButtonLabel: okLabel,
  };

  const shouldDisableDateProp = allowedDates
    ? { shouldDisableDate: createShouldDisableDate(allowedDates) }
    : {};

  const formatProp = format ? { format } : {};
  const actionBarSlotProps = closeOnSelect
    ? { actionBar: { actions: [] } }
    : {};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={buttonsText}>
      <StyledInputContainer>
        <StyledLabel htmlFor={inputId}>{label}</StyledLabel>
        <DatePicker<Dayjs>
          disabled={Boolean(disabled)}
          format={format}
          maxDate={maxDate}
          minDate={minDate}
          closeOnSelect={closeOnSelect}
          onAccept={(pickedDay) => {
            if (pickedDay) {
              onChange(pickedDay);
            }
          }}
          slotProps={{
            layout: {
              // Unfortunately this needs to be done via sx as the calendar is
              // attached to the body, so we can't use styled components
              sx: datePickerLayoutSxStyles,
            },
            textField: (textFieldProps) => ({
              inputProps: {
                ...textFieldProps.inputProps,
                "aria-label": "Usage chart date picker",
                id: inputId,
                name: inputName,
                placeholder,
              },
              sx: {
                ".MuiInputBase-root": {
                  paddingRight: 0,
                },
              },
            }),
            ...actionBarSlotProps,
          }}
          slots={{
            toolbar: CalendarHeader as JSXElementConstructor<
              DatePickerToolbarProps<Dayjs>
            >,
          }}
          sx={{
            "& .MuiInputBase-input": {
              border: hasErrors
                ? `2px solid ${CSSVariables.COLOR_ERROR_LIGHT}`
                : `1px solid ${CSSVariables.COLOR_GREY_200}`,
            },
          }}
          value={value}
          {...shouldDisableDateProp}
          {...formatProp}
        />
      </StyledInputContainer>
    </LocalizationProvider>
  );
};
