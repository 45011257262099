import { formatCurrency } from "@common/utils/dataFormatters";
import { BoCurrencyInput } from "@ops-design-system/components/BoCurrencyInput/BoCurrencyInput";
import { BoTextInput } from "@ops-design-system/components/BoTextInput/BoTextInput";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useDownPaymentCalculatorState } from "@ops/components/DownPaymentCalculator/useDownPaymentCalculatorState";
import React from "react";
import styled from "styled-components";

interface EquationProps {
  calculatedFieldLabel: string;
}

interface EquationWrapperProps {
  $editable?: boolean;
}
const EquationFieldWrapper = styled.div<EquationWrapperProps>`
  flex-basis: 30%;

  & input {
    color: ${({ $editable = false }) =>
      $editable ? grey["900"] : grey["500"]};
  }
`;

const Equation = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: ${rhOpsSpacingPx(2)};
`;

export const DownPaymentEquation = ({
  calculatedFieldLabel,
}: EquationProps) => {
  const { updateCalculatorState, totalAmountDue, downPayment } =
    useDownPaymentCalculatorState();

  return (
    <Equation>
      <EquationFieldWrapper>
        <BoTextInput
          name="amountExtended"
          value={formatCurrency(totalAmountDue - downPayment)}
          readOnly
        >
          {calculatedFieldLabel}
        </BoTextInput>
      </EquationFieldWrapper>
      <Subtitle2>+</Subtitle2>
      <EquationFieldWrapper>
        <BoTextInput
          name="downPayment"
          value={formatCurrency(downPayment)}
          readOnly
        >
          Down Payment
        </BoTextInput>
      </EquationFieldWrapper>
      <Subtitle2>=</Subtitle2>
      <EquationFieldWrapper $editable>
        <BoCurrencyInput
          name="total"
          value={totalAmountDue}
          onChange={(enteredAmount) =>
            updateCalculatorState({ totalAmountDue: enteredAmount })
          }
        >
          Total
        </BoCurrencyInput>
      </EquationFieldWrapper>
    </Equation>
  );
};
