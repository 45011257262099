import { PremiseStatus } from "@common/enums/premise.enum";
import { IdType } from "@common/types/apiTypes";
import { useCustomer } from "@ops/hooks/useCustomer";
import { Error404Page } from "@ops/pages/Error404Page/Error404Page";
import { premiseCustomerPath } from "@ops/routes/routePaths";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const RedirectCustomerPage = () => {
  const { id: customerId } = useParams<{ id: IdType }>();
  const navigate = useNavigate();

  const { customer, requestMonitor } = useCustomer(customerId);

  useEffect(() => {
    if (customer) {
      const { premises } = customer;

      if (premises.length > 0) {
        const premise = premises.find(
          (prem) => prem.status === PremiseStatus.ACTIVE
        );

        if (premise) {
          navigate(premiseCustomerPath(customer.id, premise.id, "#customer"));
        } else {
          navigate(
            premiseCustomerPath(customer.id, premises[0].id, "#customer")
          );
        }
      }
    }
  }, [customer, customerId, navigate]);

  if (requestMonitor.didSucceed && !customer) {
    return <Error404Page />;
  }

  if (requestMonitor.didSucceed && customer?.premises.length === 0) {
    return <Error404Page />;
  }

  if (requestMonitor.didFail) {
    return <Error404Page />;
  }

  return null;
};
