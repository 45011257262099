import { MaskedPattern } from "imask";

const DOLLAR_SIGN = "$";

export const CURRENCY_MASK_PATTERN = {
  blocks: {
    num: {
      mask: Number,
      min: 0,
      normalizeZeros: false,
      padFractionalZeros: false,
      radix: ".",
      scale: 2,
      signed: false,
      thousandsSeparator: ",",
    },
  },
  mask: `${DOLLAR_SIGN}num`,
};

const currencyMask = new MaskedPattern(CURRENCY_MASK_PATTERN);

export const maskCurrency = (value: string) => {
  currencyMask.value = value ?? "";
  return currencyMask.value;
};

export const unmaskCurrency = (value: string) => {
  currencyMask.value = value ?? "";
  return currencyMask.unmaskedValue;
};

const CURRENCY_MASK_PATTERN_WITH_FRACTIONAL_ZEROS = {
  blocks: {
    num: {
      mask: Number,
      min: 0,
      normalizeZeros: false,
      padFractionalZeros: true,
      radix: ".",
      scale: 2,
      signed: false,
      thousandsSeparator: ",",
    },
  },
  mask: `${DOLLAR_SIGN}num`,
};

const currencyMaskWithFractionalZeros = new MaskedPattern(
  CURRENCY_MASK_PATTERN_WITH_FRACTIONAL_ZEROS
);

export const maskCurrencyWithFractionalZeros = (value: string | number) => {
  currencyMaskWithFractionalZeros.value = String(value) ?? "";
  return currencyMaskWithFractionalZeros.value;
};

export const unmaskCurrencyWithFractionalZeros = (value: string) => {
  currencyMaskWithFractionalZeros.value = value ?? "";
  return currencyMaskWithFractionalZeros.unmaskedValue;
};
