export enum ContractOptions {
  keepSameContract = "keepSameContract",
  startNewContract = "startNewContract",
}

export enum WizardComponentName {
  winbackChoosePlan = "winbackChoosePlan",
  winbackConfirmation = "winbackConfirmation",
  winbackInfoDetails = "winbackInfoDetails",
  winbackStartDateForm = "winbackStartDateForm",
}
