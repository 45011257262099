import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import { IdType } from "@common/types/apiTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { GenerateInboundEDIForm } from "@ops/components/CustomerDashboard/AdminTab/GenerateInboundEDIForm";
import { EdiMessagesCard } from "@ops/components/EdiMessagesCard/EdiMessagesCard";
import { useCustomer } from "@ops/hooks/useCustomer";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";

export const AdminTab = () => {
  const { id: customerId, premiseId } = useParams<{
    id: IdType;
    premiseId: IdType;
  }>();
  const { customer } = useCustomer(customerId);
  const { premise } = usePremise(premiseId);

  if (!premise || !customer) {
    return <RhCircularProgress />;
  }

  if (!isProduction()) {
    return (
      <AccessControl>
        <BoCardLabel>Mock Inbound EDI</BoCardLabel>
        <BoCard>
          <GenerateInboundEDIForm />
        </BoCard>
        <EdiMessagesCard premiseId={premise.id} />
      </AccessControl>
    );
  }
  return null;
};
