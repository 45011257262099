import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const BoDialogHeaderContainer = styled.div`
  border-bottom: 1px solid ${grey["100"]};
  padding: ${rhOpsSpacingPx(2.5)} ${rhOpsSpacingPx(4)} ${rhOpsSpacingPx(2)};
  text-align: center;
  width: 100%;
`;

export const BoDialogBodyContainer = styled.div`
  align-self: start;
  height: 100%;
  padding: ${rhOpsSpacingPx(4)} ${rhOpsSpacingPx(4)} ${rhOpsSpacingPx(5)};
  width: 100%;
`;

export const BoDialogFooterContainer = styled.div`
  border-top: 1px solid ${grey["100"]};
  overflow: hidden;
  padding: ${rhOpsSpacingPx(3)} ${rhOpsSpacingPx(4)};
  width: 100%;
`;

export const BoDialogButtonContainer = styled.div`
  float: right;
  padding-left: ${rhOpsSpacingPx(1)};
`;
