import { AddressForm } from "@common/models/AddressForm.model";
import { BoDialogBodyContainer } from "@ops-design-system/components/BoDialog/BoDialog.styled";
import {
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { AddPremiseWizardComponentName } from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";
import { AddressMeterForm } from "@ops/components/AddPremiseWizard/AddressMeterForm/AddressMeterForm";
import { ConfirmDetails } from "@ops/components/AddPremiseWizard/ConfirmDetails/ConfirmDetails";
import { MailingAddressForm } from "@ops/components/AddPremiseWizard/MailingAddressForm/MailingAddressForm";
import { OfferForm } from "@ops/components/AddPremiseWizard/OfferForm/OfferForm";
import { StartDateForm } from "@ops/components/AddPremiseWizard/StartDateForm/StartDateForm";
import { NewPremiseDetailsSidebar } from "@ops/components/NewPremiseDetailsSidebar/NewPremiseDetailsSidebar";
import { useAddPremiseWizardContext } from "@ops/hooks/contexts/useAddPremiseWizardContext";
import React, { ReactElement } from "react";
import styled from "styled-components";

const AddPremiseWizardBody = styled(BoDialogBodyContainer)`
  display: flex;
  flex-direction: row;
`;
const SideBarWrapper = styled.div`
  flex-basis: 20%;
`;
const BodyContentWrapper = styled.div`
  margin-left: ${rhOpsSpacingPx(6)};
  min-height: 595px;
  width: 100%;
  &:only-child {
    margin-left: 0px;
  }
`;

interface AddPremiseWizardModalProps {
  isOpen: boolean;
}

const WIZARD_COMPONENT: Record<
  AddPremiseWizardComponentName,
  () => ReactElement
> = {
  [AddPremiseWizardComponentName.addressMeterForm]: () => <AddressMeterForm />,
  [AddPremiseWizardComponentName.mailingAddressForm]: () => (
    <MailingAddressForm />
  ),
  [AddPremiseWizardComponentName.offerForm]: () => <OfferForm />,
  [AddPremiseWizardComponentName.startDateForm]: () => <StartDateForm />,
  [AddPremiseWizardComponentName.confirmDetails]: () => <ConfirmDetails />,
};

export const AddPremiseWizardModal = ({
  isOpen,
}: AddPremiseWizardModalProps) => {
  const {
    setPreviousComponentAsActive,
    restoreStateToInitialValues,
    activeComponentName,
    closeModal,
    nextButtonProps: { disabled, loading },
    wizardState: {
      serviceAddress,
      mailingAddress,
      offerSnapshotId,
      esiId,
      utilityName,
    },
  } = useAddPremiseWizardContext();

  const handleModalClose = () => {
    restoreStateToInitialValues();
    closeModal();
  };
  const submitContentForm = () => {
    document
      .getElementById(activeComponentName)
      ?.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
  };

  const displaySidebar = [
    AddPremiseWizardComponentName.mailingAddressForm,
    AddPremiseWizardComponentName.offerForm,
    AddPremiseWizardComponentName.startDateForm,
  ].includes(activeComponentName);

  return (
    <BoModal open={isOpen} onClose={handleModalClose} size="large">
      <BoDialogHeader>Add Premise</BoDialogHeader>
      <AddPremiseWizardBody>
        {activeComponentName !==
          AddPremiseWizardComponentName.confirmDetails && (
          <SideBarWrapper>
            {displaySidebar && (
              <NewPremiseDetailsSidebar
                address={new AddressForm(serviceAddress)}
                mailingAddress={new AddressForm(mailingAddress)}
                offerSnapshotId={offerSnapshotId}
                esiId={esiId}
                utilityName={utilityName}
              />
            )}
          </SideBarWrapper>
        )}
        <BodyContentWrapper>
          {WIZARD_COMPONENT[activeComponentName]()}
        </BodyContentWrapper>
      </AddPremiseWizardBody>
      <BoDialogButtonFooter
        confirmBtnText={
          activeComponentName === AddPremiseWizardComponentName.confirmDetails
            ? "Submit"
            : "Next"
        }
        confirmDisabled={disabled}
        confirmLoading={loading}
        cancelBtnText={
          activeComponentName !== AddPremiseWizardComponentName.addressMeterForm
            ? "Back"
            : undefined
        }
        onConfirm={submitContentForm}
        onCancel={setPreviousComponentAsActive}
      />
    </BoModal>
  );
};
