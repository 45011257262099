export interface Searchable {
  matches: (query: string) => boolean;
}

export const safeIncludes = (val?: string, query?: string) => {
  return Boolean(
    val && query && val.toString().toLowerCase().includes(query.toLowerCase())
  );
};

export const searchLogs = <T extends Searchable>(
  logs: T[],
  query?: string
): T[] => {
  if (!query) {
    return [...logs];
  }

  return logs.filter((log) => log.matches(query));
};
