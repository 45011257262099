import { StyledEmptyText } from "@ops/components/SupportLogs/SupportLogs.styled";
import { LogType } from "@ops/components/SupportLogs/SupportLogs.types";
import React from "react";
import styled from "styled-components";

interface SupportLogProps<LT> {
  logType: LogType;
  mapper: (log: LT, index: number, array: LT[]) => JSX.Element;
  query?: string;
  supportLogs: LT[];
}

const StyledSupportLogEntryContainer = styled.div``;

export function SupportLogEntryList<T>({
  supportLogs,
  query,
  mapper,
  logType,
}: SupportLogProps<T>): JSX.Element {
  return (
    <StyledSupportLogEntryContainer>
      <div>
        {supportLogs.length ? (
          supportLogs.map(mapper)
        ) : (
          <div>
            <StyledEmptyText>
              {query
                ? `No results found matching "${query}"`
                : `No ${logType} logs created.`}{" "}
            </StyledEmptyText>
          </div>
        )}
      </div>
    </StyledSupportLogEntryContainer>
  );
}
