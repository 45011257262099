import { AddressForm } from "@common/models/AddressForm.model";
import { AddressFormType, AddressType } from "@common/types/customerTypes";

export class Address implements AddressType {
  deliveryLine1: string;
  city: string;
  state: string;
  zipCode: string;
  lastLine: string;

  static fromAddressForm(address: AddressFormType): Address {
    const addressForm = new AddressForm(address);

    return new Address({
      city: addressForm.city as string,
      deliveryLine1: addressForm.line1 ?? "",
      lastLine: addressForm.line2 ?? "",
      state: addressForm.state as string,
      zipCode: addressForm.zipCode as string,
    });
  }

  public constructor(address: AddressType) {
    this.deliveryLine1 = address.deliveryLine1;
    this.city = address.city;
    this.state = address.state;
    this.zipCode = address.zipCode;
    this.lastLine = address.lastLine;
  }

  public get line1(): string {
    return this.deliveryLine1;
  }

  public get line2(): string {
    return this.lastLine;
  }

  public isEqual({ deliveryLine1, city, state, zipCode }: Address): boolean {
    return (
      this.deliveryLine1 === deliveryLine1 &&
      this.city === city &&
      this.state === state &&
      this.zipCode === zipCode
    );
  }
}
