import {
  BoOptionsType,
  BoSelectOptionType,
  BoSelectValue,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";

export const sanitizeValue = (
  value?: BoSelectValue,
  options?: BoOptionsType
): BoSelectOptionType | null => {
  return options?.find((option) => option.value === value) ?? null;
};
