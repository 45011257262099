import { EM_DASH } from "@common/constants/characters.constant";
import { Customer } from "@common/models/Customer.model";
import { IdType } from "@common/types/apiTypes";
import { PaymentExtensionType } from "@common/types/billingTypes";
import {
  formatCurrency,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoGridColumn } from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import {
  Body2,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { MakeAPayment } from "@ops/components/MakeAPayment/MakeAPayment";
import { PremiseBillingBalanceBreakdown } from "@ops/components/PremiseBillingBalanceBreakdown/PremiseBillingBalanceBreakdown";
import {
  PremiseBillingCardGrid,
  StyledPastDue,
} from "@ops/components/PremiseBillingCard/PremiseBillingCard.styled";
import { SendPaymentLink } from "@ops/components/SendPaymentLink/SendPaymentLink";
import { AccountSummary } from "@ops/models/AccountSummary.model";
import React from "react";
import { useParams } from "react-router-dom";

interface PremiseBillingCardProps {
  accountSummary: AccountSummary;
  customer: Customer | null;
  paymentExtension: PaymentExtensionType | null;
}
export const PremiseBillingCard = ({
  accountSummary,
  paymentExtension,
  customer,
}: PremiseBillingCardProps) => {
  const { premiseId } = useParams<{ premiseId: IdType }>();

  if (!premiseId) {
    return null;
  }

  const paymentExtensionMessage = (paymentExt: PaymentExtensionType | null) => {
    return paymentExt
      ? `$${paymentExt.amountExtended} by ${formatMonthDayShortYear(
          paymentExt.extendToDate
        )}`
      : EM_DASH;
  };

  return (
    <BoCard data-testid="premise-billing-card-target">
      <PremiseBillingCardGrid>
        <BoGridColumn>
          <BoInfoContainer
            as="article"
            aria-labelledby="premise-billing-card-bill-due"
          >
            <BoInfoLabel id="premise-billing-card-bill-due">
              Bill Due
            </BoInfoLabel>
            <BoInfoValue>
              <Subtitle2>
                {formatCurrency(accountSummary.totalBalance)}
              </Subtitle2>
            </BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer
            as="article"
            aria-labelledby="premise-billing-card-past-due"
          >
            <BoInfoLabel id="premise-billing-card-past-due">
              Past Due
            </BoInfoLabel>
            <BoInfoValue>
              <StyledPastDue $pastDueBalance={accountSummary.pastDueBalance}>
                {formatCurrency(accountSummary.pastDueBalance)}
              </StyledPastDue>
            </BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer
            as="article"
            aria-labelledby="premise-billing-card-last-statemented"
          >
            <BoInfoLabel id="premise-billing-card-last-statemented">
              Last Statemented
            </BoInfoLabel>
            <BoInfoValue>
              <Body2>{formatCurrency(accountSummary.lastStatemented)}</Body2>
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>

        <BoGridColumn>
          <BoInfoContainer
            as="article"
            aria-labelledby="premise-billing-card-bill-due-date"
          >
            <BoInfoLabel id="premise-billing-card-bill-due-date">
              Bill Due Date
            </BoInfoLabel>
            <BoInfoValue>
              {accountSummary.nextDueDate
                ? formatMonthDayShortYear(accountSummary.nextDueDate)
                : `${EM_DASH}`}
            </BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer
            as="article"
            aria-labelledby="premise-billing-card-payment-extension"
          >
            <BoInfoLabel id="premise-billing-card-payment-extension">
              Payment Extension
            </BoInfoLabel>
            <BoInfoValue>
              {paymentExtensionMessage(paymentExtension)}
            </BoInfoValue>
          </BoInfoContainer>
          <MakeAPayment accountSummary={accountSummary} premiseId={premiseId} />
          {customer ? (
            <SendPaymentLink
              premiseId={premiseId}
              customerEmail={customer.email}
            />
          ) : null}
        </BoGridColumn>
      </PremiseBillingCardGrid>

      <PremiseBillingBalanceBreakdown premiseId={premiseId} />
    </BoCard>
  );
};
