import { PaymentExtensionComponentType } from "@ops/components/PaymentExtensionWizardModal/PaymentExtensionComponentType";
import React, { PropsWithChildren, createContext, useState } from "react";

interface ModalStateType {
  activeComponent: PaymentExtensionComponentType;
}

export interface PaymentExtensionModalStateProviderContext {
  activeComponent: PaymentExtensionComponentType;
  updateModalState: (
    content: Partial<PaymentExtensionModalStateProviderContext>
  ) => void;
}

export const initialPaymentExtensionModalState = {
  activeComponent: PaymentExtensionComponentType.loading,
};

export const PaymentExtensionModalStateContext =
  createContext<PaymentExtensionModalStateProviderContext>({
    activeComponent: PaymentExtensionComponentType.loading,
    updateModalState: (content) => {},
  });

interface PaymentExtensionModalStateProviderProps {}
export const PaymentExtensionModalStateProvider = ({
  children,
}: PropsWithChildren<PaymentExtensionModalStateProviderProps>) => {
  const [modalState, setModalState] = useState<ModalStateType>(
    initialPaymentExtensionModalState
  );

  const updateModalState = (content: Partial<ModalStateType>) => {
    setModalState({ ...modalState, ...content });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: PaymentExtensionModalStateProviderContext = {
    activeComponent: modalState.activeComponent,
    updateModalState,
  };

  return (
    <PaymentExtensionModalStateContext.Provider value={actions}>
      {children}
    </PaymentExtensionModalStateContext.Provider>
  );
};
