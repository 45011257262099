import { IdType } from "@common/types/apiTypes";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { RemoveSwitchHold } from "@ops/components/RemoveSwitchHold/RemoveSwitchHold";
import { TriggerSwitchHold } from "@ops/components/TriggerSwitchHold/TriggerSwitchHold";
import React from "react";
import styled from "styled-components";

export const SwitchHoldButtonsGrid = styled.div`
  display: grid;
  grid-gap: ${rhOpsSpacingPx(2)};
  grid-template-columns: 2fr 2fr;
  width: 100%;
`;

interface SwitchHoldCardProps {
  customerId: IdType;
}

export const SwitchHoldCard = ({ customerId }: SwitchHoldCardProps) => {
  return (
    <>
      <BoCardLabel>Switch Hold</BoCardLabel>
      <BoCard>
        <SwitchHoldButtonsGrid>
          <AccessControl>
            <TriggerSwitchHold customerId={customerId} />
          </AccessControl>
          <AccessControl>
            <RemoveSwitchHold customerId={customerId} />
          </AccessControl>
        </SwitchHoldButtonsGrid>
      </BoCard>
    </>
  );
};
