import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { AddressFormType } from "@common/types/customerTypes";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { EditMailingAddressForm } from "@ops/components/PremiseAddressCard/EditMailingAddress/EditMailingAddressForm";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";

interface EditMailingAddressProps {
  premiseId: IdType;
}

export const EditMailingAddress = ({ premiseId }: EditMailingAddressProps) => {
  const { premise, error } = usePremise(premiseId);
  const { open, close, isOpen } = useModalState();

  if (!premise || error) {
    return null;
  }

  if (!premise.serviceAddress) {
    return null;
  }

  const getNormalizedAddress = (): AddressFormType => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const premiseServiceAddress = premise.serviceAddress!;

    if (premise.mailingAddress) {
      return premise.mailingAddress;
    }

    const serviceAddress: AddressFormType = {
      addressLine1: premiseServiceAddress.deliveryLine1,
      city: premiseServiceAddress.city,
      state: premiseServiceAddress.state,
      unitNumber: "",
      zipCode: premiseServiceAddress.zipCode,
    };

    return serviceAddress;
  };

  const normalizedAddress = getNormalizedAddress();

  return (
    <>
      <EditLink onClick={open} aria-label="Edit Mailing Address" />
      {isOpen ? (
        <EditMailingAddressForm
          premiseId={premise.id}
          {...normalizedAddress}
          mailingAddressSameAsServiceAddress={!premise.mailingAddress}
          open={isOpen}
          close={close}
        />
      ) : null}
    </>
  );
};
