import { RhythmCampaignSlugs } from "@common/constants/campaigns.constant";
import { RecProduct } from "@common/enums/RecProduct.enum";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { CampaignTypeEnum } from "@common/types/campaignTypes";
import { OfferPromoType, PriceMethodTypes } from "@common/types/offerTypes";
import { PriceScheduleBreakdownVisualType } from "@common/types/priceScheduleBreakdownVisualTypes";
import {
  GenerationPriceTierSnapshotType,
  PriceTierSnapshotType,
} from "@common/types/priceTierSnapshotType";
import { PlanTypes } from "@ops-utils/types/planTypes";

export interface OfferSnapshotTags {
  calloutColor?: string | null;
  calloutEn?: string | null;
  calloutEs?: string | null;
}

export interface OfferSnapshotType {
  active: boolean;
  autopayPaperlessMonthlyDiscount?: string | null;
  baseChargeAmount: string;
  campaignId: IdType;
  campaignSlug: string;
  campaignType: CampaignTypeEnum;
  descriptionEn: string;
  descriptionEs: string;
  dunsNumber: string;
  endDate?: string;
  etfAmount: string;
  generationPriceScheduleBreakdown: PriceScheduleBreakdownVisualType[] | null;
  generationPriceTierSnapshots: GenerationPriceTierSnapshotType[] | null;
  grossMargin: number | null;
  id: IdType;
  longDescriptionEn: string;
  longDescriptionEs: string;
  offerLabels: string[] | null;
  offerPromoId: IdType | null;
  planId: IdType;
  price: string;
  price1000Kwh: string;
  price2000Kwh: string;
  price500Kwh: string;
  priceMethod: PriceMethodTypes;
  pricePerMwh: number | null;
  priceScheduleBreakdown: PriceScheduleBreakdownVisualType[] | null;
  priceTierSnapshots: PriceTierSnapshotType[] | null;
  priority?: string;
  promo: OfferPromoType | null;
  recProduct: RecProduct;
  rhythmAverageKwhRate: string;
  rhythmKwhRate: string;
  rhythmTosEn: string;
  rhythmTosEs: string;
  solarCreditKwhRate: string;
  solarEligible: boolean;
  solarGenerationCapped: boolean;
  solarGenerationToConsumptionRatio: string | number | null;
  startDate: string;
  tags: OfferSnapshotTags | null;
  tdspKwhRate: string;
  tdspMonthlyCharge: string;
  termMonths: number;
  thermostatManagementMonthlyDiscount: string | null | undefined;
  title: string;
  uuid: UUIDType;
  zuoraRatePlanId: IdType;
}

export enum OfferSnapshotRenewalTiersOfferLabels {
  RENEWAL_TIER_1 = "renewal-tier-1",
  RENEWAL_TIER_2 = "renewal-tier-2",
  RENEWAL_TIER_3 = "renewal-tier-3",
  RENEWAL_TIER_4 = "renewal-tier-4",
}

enum OfferSnapshotOfferLabels {}

export const OfferSnapshotLabels = {
  ...OfferSnapshotRenewalTiersOfferLabels,
  ...OfferSnapshotOfferLabels,
} as const;

export type OfferSnapshotLabelsType =
  | OfferSnapshotRenewalTiersOfferLabels
  | OfferSnapshotOfferLabels;

export enum OfferSnapshotOrderOptions {
  "Price: High to Low" = "-price",
  "Price: Low to High" = "price",
}

export enum OfferSnapshotTermLengths {
  NINE = "9",
  ONE = "1",
  SIX = "6",
  SIXTEEN = "16",
  THIRTY_SIX = "36",
  THREE = "3",
  TWELVE = "12",
  TWENTY_FOUR = "24",
}

export interface OfferSnapshotSearchOptions {
  campaignSlug?: RhythmCampaignSlugs | null;
  dunsNumber: string;
  limit?: number;
  offerLabels?: OfferSnapshotLabelsType[];
  ordering?: OfferSnapshotOrderOptions;
  planType?: PlanTypes[];
  termMonths?: OfferSnapshotTermLengths[];
}

export interface CampaignPromoType {
  code: string;
  expirationDate?: string;
  id: IdType;
  oneTimeValue: string;
  recurringValue: string;
}

export interface OfferSnapshotSearchResponseType {
  campaignPromo: CampaignPromoType | null;
  offerSnapshots: OfferSnapshotType[];
}
