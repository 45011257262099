import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogButtonFooterProps,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import React from "react";

interface LoadingModalContentProps
  extends Pick<
    BoDialogButtonFooterProps,
    "cancelBtnText" | "confirmBtnText" | "confirmBtnType"
  > {
  headerText?: string;
}

export const LoadingModalContent = ({
  headerText,
  confirmBtnText,
  cancelBtnText,
  confirmBtnType,
}: LoadingModalContentProps) => {
  return (
    <>
      <BoDialogHeader>{headerText}</BoDialogHeader>
      <BoDialogBody>
        <BoCircularProgress />
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText={confirmBtnText || ""}
        cancelBtnText={cancelBtnText || ""}
        confirmBtnType={confirmBtnType}
        confirmDisabled
        cancelDisabled
      />
    </>
  );
};
