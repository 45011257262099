import { BoGridColumn } from "@ops-design-system/components/BoGrid/BoGrid";
import { orange, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { PremiseMatchCollisionStatusLevels } from "@ops-utils/types/prospectValidationReportTypes";
import styled from "styled-components";

export const PremiseStatusContainer = styled.div<{
  $status: PremiseMatchCollisionStatusLevels;
}>`
  background-color: ${(props) =>
    props.$status === PremiseMatchCollisionStatusLevels.ERROR
      ? red[100]
      : orange[300]};
  border-radius: 5px;
  color: ${(props) =>
    props.$status === PremiseMatchCollisionStatusLevels.ERROR
      ? red.main
      : orange[300]};
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const PremiseStatus = styled.h3<{
  $status: PremiseMatchCollisionStatusLevels;
}>`
  background-color: ${(props) =>
    props.$status === PremiseMatchCollisionStatusLevels.ERROR
      ? red[400]
      : orange[300]};
  border-radius: 5px;
  color: white;
  display: inline;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
`;

export const PastDueStyle = styled.div<{ $pastDue: boolean }>`
  color: ${(props) => (props.$pastDue ? red.main : "inherit")};
`;

export const AmountStyle = styled.div`
  font-size: 16px;
`;

export const LayoutContainer = styled.div`
  padding: ${rhOpsSpacingPx(2)} ${rhOpsSpacingPx(2.5)};
`;

export const ValidationReportColumn = styled(BoGridColumn)`
  gap: ${rhOpsSpacingPx(0.25)};
`;
