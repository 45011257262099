import { ajax } from "@common/services/ajax";
import { CampaignSlugType } from "@ops-utils/types/campaignTypes";

const BASE_PATH = "/api/rhapsody/campaigns";

export const rhapsodyCampaignApi = {
  campaignSlugs: {
    retrieve: (filter?: string): Promise<CampaignSlugType> => {
      return ajax.get(`${BASE_PATH}/campaign-slugs/`, {
        params: {
          slug: filter,
        },
      });
    },
  },
};
