import { IdType } from "@common/types/apiTypes";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import {
  PremiseSelect,
  PremiseSelectOptionType,
} from "@ops/components/PremiseSelect/PremiseSelect";
import {
  StyledAddressWrapper,
  StyledPremiseSelectorCard,
  StyledZuoraLink,
} from "@ops/components/PremiseSelectorCard/PremiseSelectorCard.styled";
import { useCustomer } from "@ops/hooks/useCustomer";
import { usePremise } from "@ops/hooks/usePremise";
import { premiseCustomerPath } from "@ops/routes/routePaths";
import { ADMIN_URL } from "@ops/settings/config";
import { paymentMethodsFetch } from "@ops/slices/paymentMethodsSlice";
import { premiseStatusIndicatorsFetch } from "@ops/slices/premiseStatusIndicatorsSlice";
import { linkToZuoraAccount } from "@ops/utils/zuoraHelpers";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const PremiseSelectorCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: customerId, premiseId } = useParams<{
    id: IdType;
    premiseId: IdType;
  }>();

  const { customer, requestMonitor: customerRequestMonitor } =
    useCustomer(customerId);

  const { premise } = usePremise(premiseId);

  const onChange = (option: PremiseSelectOptionType | null) => {
    const selectedPremiseId = option?.value;

    if (selectedPremiseId) {
      const nextUrl = premiseCustomerPath(
        customerId,
        selectedPremiseId,
        location.hash
      );

      dispatch(paymentMethodsFetch(selectedPremiseId));
      dispatch(premiseStatusIndicatorsFetch(selectedPremiseId));

      navigate(nextUrl);
    }
  };

  if (customerRequestMonitor.isPending || !premise || !customer) {
    return null;
  }

  const { premises } = customer;

  const linkToZuora = linkToZuoraAccount(premise.billingAccountId);

  return (
    <StyledPremiseSelectorCard>
      <StyledAddressWrapper>
        <div>
          {premise.serviceAddress !== null
            ? `${premise.serviceAddress.deliveryLine1}, ${premise.serviceAddress.lastLine}`
            : "Unconfirmed Address"}
        </div>
        <AccessControl>
          <BoLink href={`${ADMIN_URL}/premises/${premise.id}`} target="_blank">
            <StyledZuoraLink>View in Admin</StyledZuoraLink>
          </BoLink>
        </AccessControl>

        <AccessControl>
          {linkToZuora && (
            <BoLink href={linkToZuora} target="_blank">
              <StyledZuoraLink>View In Zuora</StyledZuoraLink>
            </BoLink>
          )}
        </AccessControl>
      </StyledAddressWrapper>
      <PremiseSelect
        selected={premise.id}
        premises={premises}
        onChange={onChange}
      />
    </StyledPremiseSelectorCard>
  );
};
