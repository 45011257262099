import { IdType } from "@common/types/apiTypes";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { TriggerDisconnect } from "@ops/components/TriggerDisconnect/TriggerDisconnect";
import { TriggerReconnect } from "@ops/components/TriggerReconnect/TriggerReconnect";
import React from "react";

interface SwitchHoldCardProps {
  customerId: IdType;
}

export const ConnectionActionsCard = ({ customerId }: SwitchHoldCardProps) => {
  return (
    <>
      <BoCardLabel>Connection</BoCardLabel>
      <BoCard>
        <ButtonsGrid>
          <TriggerReconnect customerId={customerId} />
          <AccessControl>
            <TriggerDisconnect />
          </AccessControl>
        </ButtonsGrid>
      </BoCard>
    </>
  );
};
