import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface PastDueProductAddOnInvoiceData extends EventLogBaseData {
  accountNumber: string;
  dueDate: string;
  invoiceDate: string;
  invoiceId: string;
  invoiceNumber: string;
  pastDueProductBalance: string;
  productBalance: string;
}

export class PastDueProductAddOnInvoice extends EventLog {
  public readonly invoiceNumber: string;
  public readonly invoiceId: string;
  public readonly productBalance: string;
  public readonly dueDate: string;
  public readonly accountNumber: string;
  public readonly invoiceDate: string;
  public readonly pastDueProductBalance: string;

  constructor({
    invoiceNumber,
    invoiceId,
    productBalance,
    dueDate,
    accountNumber,
    invoiceDate,
    pastDueProductBalance,
    ...baseEventData
  }: PastDueProductAddOnInvoiceData) {
    super(baseEventData);

    this.invoiceNumber = invoiceNumber;
    this.invoiceId = invoiceId;
    this.productBalance = productBalance;
    this.dueDate = formatMonthDayYear(dueDate);
    this.accountNumber = accountNumber;
    this.invoiceDate = invoiceDate;
    this.pastDueProductBalance = pastDueProductBalance;
  }

  override get body() {
    return `${this.name} - Invoice Number: ${this.invoiceNumber} - Due Date: ${this.dueDate} - Product Add On Balance: $${this.pastDueProductBalance}`;
  }
}
