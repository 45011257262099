import { addressStates } from "@common/constants/addressStates.constant";
import {
  DOB_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
} from "@common/constants/date.constant";
import {
  EMAIL_VALIDATION_REGEX,
  ZIPCODE_VALIDATION_REGEX,
} from "@common/constants/regex.constant";
import { unmaskCurrency } from "@common/forms/currency.mask";
import { unmaskPhone } from "@common/forms/phone.mask";
import {
  ValidationDictionary,
  Validator,
  opsAndPricingIsRequired,
} from "@common/forms/validators";
import { ZuoraIdType } from "@common/types/apiTypes";
import { CreditScoreEvaluationType } from "@common/types/creditCheckTypes";
import { EmailValidator } from "commons-validator-js";
import dayjs from "dayjs";

export const isValidPayment: Validator<string> = (value) => {
  const amount = Number(unmaskCurrency(value ?? ""));

  if (amount < 0.01) {
    return "Payment must be at least 1 cent";
  }
};

export const isValidCreditCheck: Validator<CreditScoreEvaluationType> = (
  value,
  prospect,
  _messageId = ""
) => {
  if (!value) {
    return "Credit check is required.";
  }
  if (prospect?.bypassedCreditCheck) {
    return undefined;
  }
  if (value?.ssnRequired && !value?.ssnProvided) {
    return "The credit check needs more information before it is complete.";
  }
};

export const isValidSlug: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (/\s/g.test(value)) {
    return "Slug cannot contain any spaces.";
  }
};

export const isPaymentMethodRequired: Validator<ZuoraIdType> = (
  value,
  allValues
) => {
  if (!allValues?.creditCheckSummary) {
    return "Credit check is required.";
  }
  if (
    !value &&
    (allValues?.autopay ||
      (allValues?.creditCheckSummary.depositAmount ?? 0) > 0)
  ) {
    return "Some required information is missing.";
  }
};

export const isAfterDate = (valueDate: string, earliestDate: string) => {
  // Same day is acceptable
  const first = dayjs(earliestDate);
  const last = dayjs(valueDate);

  if (!earliestDate || !first.isValid()) {
    throw new Error("Comparison date is invalid");
  }
  if (!valueDate || !last.isValid()) {
    throw new Error("Date to check is invalid");
  }
  if (last.isSame(first) || first.isAfter(last)) {
    return "Must be after start date";
  }
};

export const isValidAdultBirthday: Validator<string> = (value) => {
  if (!value) {
    return;
  }
  const dob = dayjs(value, [DOB_DATE_FORMAT, ISO8601_DATE_FORMAT], true);

  if (!dob.isValid()) {
    return "Please enter a valid date (MM/DD/YYYY).";
  }

  const today = dayjs().startOf("day");
  const earliest18thBirthday = today.subtract(18, "year");

  if (dob.isAfter(earliest18thBirthday)) {
    return "Must be 18 years or older to sign up.";
  }
};

export const isValidUSState: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (
    !addressStates.find(
      ({ abbreviation }) => abbreviation.toLowerCase() === value?.toLowerCase()
    )
  ) {
    return "Invalid US State.";
  }
};

export const isValidZipCode: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (!ZIPCODE_VALIDATION_REGEX.test(value)) {
    return "Invalid Zipcode.";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidUSAddress: ValidationDictionary<Record<string, any>> = {
  addressLine1: [opsAndPricingIsRequired],
  city: [opsAndPricingIsRequired],
  state: [opsAndPricingIsRequired, isValidUSState],
  zipCode: [opsAndPricingIsRequired, isValidZipCode],
};

export const isValidPhoneNumber: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (unmaskPhone(value).length !== 10) {
    return "Invalid Phone Number.";
  }
};

export const isValidEmail: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  const errorMessage = "Invalid Email.";

  if (!EMAIL_VALIDATION_REGEX.test(value)) {
    return errorMessage;
  }

  const emailValidator = new EmailValidator();

  if (!emailValidator.isValid(value)) {
    return errorMessage;
  }
};
