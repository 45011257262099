import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoTable } from "@ops-design-system/components/BoTable/BoTable";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledPaymentTableHeader = styled(Body2).attrs({
  as: "div",
})`
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const StyledPaymentTable = styled(BoTable)`
  width: 100%;
`;

export const EmptyPaymentList = styled(BoCard).attrs({ as: "div" })`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100px;
`;
