import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const FullWidthBoCard = styled(BoCard)`
  width: 100%;
`;

export const AffiliateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  justify-content: center;
`;

export const AffiliateDetailRewardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
