import { affiliateApi } from "@common/api/affiliateApi";
import { AffiliateQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useAffiliates = () => {
  return useQuery({
    queryFn: affiliateApi.list,
    queryKey: AffiliateQueryKeys.BASE,
  });
};
