import {
  StyledDeleteFileButton,
  StyledDragAndDropFileFormat,
  StyledDragAndDropFileUpload,
  StyledDragAndDropSubtile,
  StyledDropzone,
  StyledFileNameContainer,
  StyledUploadIconContainer,
} from "@ops-design-system/components/OpsDragAndDropFileUpload/OpsDragAndDropFileUpload.styled";
import {
  Body2,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as PDFIcon } from "@ops-design-system/icons/PDFIcon.svg";
import { ReactComponent as UploadIcon } from "@ops-design-system/icons/UploadFancyIcon.svg";
import { ReactComponent as XIcon } from "@ops-design-system/icons/XIcon.svg";
import React, { useRef } from "react";

interface OpsDragAndDropFileUploadProps {
  accept: string;
  fileName?: string;
  fileSize?: string;
  maxFileSize: string;
  onFileUpload: (files: FileList | null) => void;
}

export const OpsDragAndDropFileUpload = ({
  accept,
  fileName,
  fileSize,
  maxFileSize,
  onFileUpload,
}: OpsDragAndDropFileUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    onFileUpload(event.dataTransfer.files);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFileUpload(event.target.files);
  };

  const acceptedFormatsText = accept
    .split(",")
    .map((ext) => ext.trim().replace(".", "").toUpperCase())
    .join(", ");

  return (
    <StyledDragAndDropFileUpload>
      <StyledDropzone
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={() => fileInputRef.current?.click()}
      >
        <StyledUploadIconContainer>
          <UploadIcon />
        </StyledUploadIconContainer>
        <Subtitle2>Select file to upload</Subtitle2>
        <StyledDragAndDropSubtile>
          or drag and drop it here
        </StyledDragAndDropSubtile>
        <StyledDragAndDropFileFormat>
          {`File format & size: ${acceptedFormatsText} | ${maxFileSize} max`}
        </StyledDragAndDropFileFormat>
      </StyledDropzone>
      <input
        ref={fileInputRef}
        type="file"
        accept={accept}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {fileName && fileSize ? (
        <StyledFileNameContainer>
          <PDFIcon />
          <Body2>{fileName}</Body2>
          <StyledDragAndDropSubtile>({fileSize})</StyledDragAndDropSubtile>
          <StyledDeleteFileButton onClick={() => onFileUpload(null)}>
            <XIcon />
          </StyledDeleteFileButton>
        </StyledFileNameContainer>
      ) : null}
    </StyledDragAndDropFileUpload>
  );
};
