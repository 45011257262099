import { contractRenewalWizardStepMapping } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.constants";
import { ContractRenewalWizardFormStateType } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.schemas";
import { atomWithReset } from "jotai/utils";

export const contractRenewalWizardModalAtom = atomWithReset(false);

export const contractRenewalWizardCurrentStepAtom =
  atomWithReset<keyof typeof contractRenewalWizardStepMapping>("offersStep");

export const contractRenewalWizardStateAtom =
  atomWithReset<ContractRenewalWizardFormStateType>({
    confirmRenewal: false,
    offerSnapshotId: "",
    paperless: undefined,
  });
