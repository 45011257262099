import { BoCheckboxField } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ValidatedAddressCheckbox } from "@ops/components/ConfirmMeterField/ValidatedAddressCheckbox";
import { ValidatedAddressType } from "@ops/components/MeterSearchForms/useMeterSearchForms";
import React from "react";
import styled from "styled-components";

const FormContainer = styled.div`
  padding-left: ${rhOpsSpacingPx(7)};
`;

const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

type ConfirmMeterFieldProps = {
  confirmValidatedAddress: boolean;
  validatedAddress?: ValidatedAddressType;
};

// For use in React Final Form
export const ConfirmMeterField = ({
  validatedAddress,
  confirmValidatedAddress,
}: ConfirmMeterFieldProps) => {
  return (
    <FormContainer>
      <FormRow>
        <Subtitle2 $fontWeight="Bold">Confirm meter</Subtitle2>
      </FormRow>
      <FormRow>
        <ValidatedAddressCheckbox
          validatedAddress={validatedAddress as ValidatedAddressType}
        />
      </FormRow>
      <StyledDivider $light />
      <BoCheckboxField
        disabled={!confirmValidatedAddress}
        name="mailingAddressSameAsServiceAddress"
        label="Mailing Address Same as Service Address"
      />
    </FormContainer>
  );
};
