import { IdType } from "@common/types/apiTypes";
import { rhapsodyInvoicesApi } from "@ops-data/api/rhapsodyInvoicesApi";
import { InvoiceQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceQueryProps {
  invoiceId: IdType;
}

export const useInvoiceQuery = (props: UseInvoiceQueryProps) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => rhapsodyInvoicesApi.retrieve(invoiceId),
    queryKey: InvoiceQueryKeys.retrieve(invoiceId),
  });
};
