import { EM_DASH } from "@common/constants/characters.constant";
import humps from "humps";

export const convertCamelCaseToSentenceCase = (text: string) =>
  humps
    .decamelize(text)
    .split("_")
    .reduce(
      (result, word) =>
        `${result} ${word.charAt(0).toUpperCase() + word.slice(1)}`,
      ""
    );

export const numberToStringOrEmDash = (value: number | null) => {
  const stringValue = value === 0 || value === null ? null : value.toString();

  return stringOrEmDash(stringValue);
};

export const stringOrEmDash = (text?: string | null) => {
  return stringOrDefault(EM_DASH, text);
};

export const stringOrDefault = (defaultVal: string, text?: string | null) => {
  if (!text) {
    return defaultVal;
  }
  return text;
};

export const convertSnakeCaseToTitleCase = (text: string) =>
  text
    .split("_")
    .reduce(
      (result, word) =>
        `${result} ${
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }`,
      ""
    );

export const capitalizeFirstLetterOfWords = (text: string) => {
  return text
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const convertTemperature = (temperature: number | string) => {
  const tempWithoutDecimal = temperature.toString().replace(/\.\d+/, "");

  return `${tempWithoutDecimal}°`;
};
