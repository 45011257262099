import { Body3 } from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledPremiseSelectorCard = styled.div`
  align-items: center;
  background-color: ${grey["50"]};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: ${rhOpsSpacingPx(1)};
  width: 100%;
`;

export const StyledAddressWrapper = styled.div`
  align-items: center;
  display: grid;
  flex: 1;
  gap: 16px;
  grid-template-columns: 1fr max-content max-content;

  padding-left: ${rhOpsSpacingPx(1.5)};
  & > :last-child {
    margin-right: ${rhOpsSpacingPx(2)};
  }
`;

export const StyledZuoraLink = styled(Body3)`
  color: inherit;
`;
