import { rhapsodyPremiseApi } from "@ops-data/api/rhapsodyPremiseApi";
import { CreatePaymentDataType } from "@ops-utils/types/paymentTypes";
import { useMutation } from "@tanstack/react-query";

export const useCreatePaymentMutation = () => {
  return useMutation({
    mutationFn: ({
      premiseId,
      paymentData,
    }: {
      paymentData: CreatePaymentDataType;
      premiseId: string;
    }) => rhapsodyPremiseApi.createPayment(premiseId, paymentData),
  });
};
