import {
  blue,
  darkPurple,
  gold,
  green,
  grey,
  lightBlue,
  purple,
  red,
  trueBlack,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { typography } from "@design-system/theme/typography";

const palette = {
  background: {
    default: trueWhite,
  },
  black: trueBlack,
  error: red,
  gold,
  grey,
  info: lightBlue,
  primary: darkPurple,
  secondary: purple,
  success: green,
  tertiary: blue,
  text: {
    disabled: grey[200], // not official
    primary: grey[900],
    secondary: grey[600],
  },
  white: trueWhite,
};

const ui = {
  borders: {
    radius: "8px",
  },
  spacing: {
    lg: "4rem",
    md: "2rem",
    sm: "1rem",
  },
};

export const theme = { palette, typography, ui };
