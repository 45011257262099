import { UUIDType } from "@common/types/apiTypes";
import { ProductType } from "@ops-utils/types/productTypes";
import { ProductPrice } from "@ops/models/Products/ProductPrice.model";
import { ProductTier } from "@ops/models/Products/ProductTier.model";

export class Product {
  name: string;
  id: UUIDType;
  description: string;
  tiers: ProductTier[];
  icon: string;

  constructor(product: ProductType) {
    this.name = product.name;
    this.id = product.id;
    this.description = product.description;
    this.tiers = product.tiers.map((tier) => new ProductTier(this, tier));
    this.icon = product.icon;
  }

  get prices(): ProductPrice[] {
    return this.tiers.reduce(
      (acc, tier) => [...acc, ...tier.prices],
      [] as ProductPrice[]
    );
  }
}
