import { useAjaxState } from "@common/hooks/useAjaxState";
import { legacyContactLogApi } from "@ops-data/api/legacyContactLogApi";
import { BatchContactLogUpload } from "@ops/models/BatchContactLogUpload";
import { useCallback } from "react";

export const useBatchContactLogUploadsInState = () => {
  const [
    { requestMonitor, data, error },
    { setPending, setFailure, setSuccess },
  ] = useAjaxState<BatchContactLogUpload[]>();

  const fetch = useCallback(() => {
    if (requestMonitor.isPending) {
      return;
    }

    setPending();

    legacyContactLogApi.batchUploads
      .list()
      .then((resp) => {
        setSuccess(resp.map((respData) => new BatchContactLogUpload(respData)));
      })
      .catch(setFailure);
  }, []);

  return {
    data,
    error,
    fetch,
    requestMonitor,
  };
};
