import { ajax } from "@common/services/ajax";
import { TriggerEdiResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { ValidInboundEDIType } from "@common/types/ediTypes";

const BASE_PATH = "/api/edi-provider";

export const legacyEdiProviderApi = {
  getAllowableInboundEDIActions: (
    premiseId: IdType
  ): Promise<{ valid: ValidInboundEDIType[] }> => {
    return ajax.get(`${BASE_PATH}/allowable-inbound-actions/`, {
      params: {
        premise_id: premiseId,
      },
    });
  },
  triggerEDIGeneration: (
    premiseId: IdType,
    ediIntent: string
  ): Promise<TriggerEdiResponseType> => {
    return ajax.post(`${BASE_PATH}/generate-edi/`, {
      ediIntent,
      premiseId,
    });
  },
};
