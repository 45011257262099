import { PaymentHistoryType } from "@common/types/premiseTypes";
import { createRhContext } from "@common/utils/contextHelpers";
import { BoSelectValue } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import React, { PropsWithChildren, useState } from "react";

interface FormStateType {
  currentPaymentMethod: BoSelectValue;
  errors: string | null;
  hasMadeDownPayment?: boolean;
  numberOfInstallments: number;
  selectedPaymentList: PaymentHistoryType[];
}

export interface DeferPaymentFormStateProviderContext {
  currentPaymentMethod: BoSelectValue;
  errors: string | null;
  hasMadeDownPayment?: boolean;
  numberOfInstallments: number;
  resetFormState: () => void;
  selectedPaymentList: PaymentHistoryType[];
  updateFormState: (
    content: Partial<DeferPaymentFormStateProviderContext>
  ) => void;
}

export const initialDeferPaymentFormState = {
  currentPaymentMethod: null,
  errors: null,
  hasMadeDownPayment: undefined,
  numberOfInstallments: 5,
  selectedPaymentList: [],
};

export const DeferPaymentFormStateContext =
  createRhContext<DeferPaymentFormStateProviderContext>();

interface DeferPaymentFormStateProviderProps {}

export const DeferPaymentFormStateProvider = ({
  children,
}: PropsWithChildren<DeferPaymentFormStateProviderProps>) => {
  const [formState, setFormState] = useState<FormStateType>(
    initialDeferPaymentFormState
  );

  const updateFormState = (content: Partial<FormStateType>) => {
    setFormState({ ...formState, ...content });
  };

  const resetFormState = () => {
    updateFormState(initialDeferPaymentFormState);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: DeferPaymentFormStateProviderContext = {
    currentPaymentMethod: formState.currentPaymentMethod,
    errors: formState.errors,
    hasMadeDownPayment: formState.hasMadeDownPayment,
    numberOfInstallments: formState.numberOfInstallments,
    resetFormState,
    selectedPaymentList: formState.selectedPaymentList,
    updateFormState,
  };

  return (
    <DeferPaymentFormStateContext.Provider value={actions}>
      {children}
    </DeferPaymentFormStateContext.Provider>
  );
};
