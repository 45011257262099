import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { AddressForm } from "@common/models/AddressForm.model";
import { isOps } from "@common/utils/authenticationHelpers";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  Body2,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow, FormRow } from "@ops-design-system/styles/common.styles";
import { ServiceDatePicker } from "@ops/components/ServiceDatePicker/ServiceDatePicker";
import {
  AutoPayInfoCard,
  BillingPreferencesContainer,
  MovingDatesContainer,
  PaperlessCheckboxContainer,
  PaperlessDisclaimer,
  PaperlessWarningCard,
  StyledDivider,
} from "@ops/components/TransferServiceWizard/MovingForm/MovingForm.styled";
import {
  CheckboxContainer,
  CheckboxLabel,
} from "@ops/components/TransferServiceWizard/shared/TransferServiceWizard.styled";
import { WizardComponentName } from "@ops/components/TransferServiceWizard/TransferServiceWizard.types";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

export interface MovingFormValues {
  moveIn: string | null;
  moveOut: string | null;
  moveOutUnknown: boolean;
  paperless: boolean;
}

export const MovingForm = () => {
  const {
    premise: { serviceAddress: currentServiceAddress },
    tosWizardState: {
      serviceAddress,
      moveOut,
      moveIn,
      moveOutUnknown,
      offerSnapshotId,
      paperless,
    },
    setNextButtonProps,
    setActiveComponentName,
    updateTosWizardState,
    visitedComponents,
    setVisitedComponents,
  } = useTransferServiceWizardContext();
  const [formValues, setFormValues] = useState<MovingFormValues>({
    moveIn,
    moveOut,
    moveOutUnknown,
    paperless,
  });
  const { data: groups } = useAuthenticatedUserGroups();

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId,
  });

  useEffect(() => {
    // clear moveOut value if moveOutUnknown is checked
    if (
      formValues.moveIn &&
      (formValues.moveOut || formValues.moveOutUnknown)
    ) {
      setNextButtonProps({ disabled: false });
    } else {
      setNextButtonProps({ disabled: true });
    }
  }, [formValues]);

  useEffect(() => {
    // handle validation -- moveIn and a moveOut option are required to progress
    if (formValues.moveOutUnknown && formValues.moveOut !== null) {
      setFormValues({ ...formValues, moveOut: null });
    }
  }, [formValues, setFormValues]);

  if (offerSnapshotQuery.isPending) {
    return (
      <div>
        <BoCircularProgress />
      </div>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <div>
        <Body2>Failed to fetch offer details</Body2>
      </div>
    );
  }

  const newServiceAddress = new AddressForm(serviceAddress);

  const handleDatePickerChange = (
    dateString: string | null,
    fieldName: "moveOut" | "moveIn"
  ) => {
    setFormValues({ ...formValues, [fieldName]: dateString });
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    const fieldName = event.target.name as
      | "moveOutUnknown"
      | "paperless"
      | "autopay";

    setFormValues({ ...formValues, [fieldName]: value });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const visitComponents = [...visitedComponents];

    visitComponents.push(WizardComponentName.movingForm);
    setVisitedComponents(visitComponents);

    updateTosWizardState(formValues);
    setActiveComponentName(WizardComponentName.confirmTransferDetails);
  };

  const offerSnapshot = offerSnapshotQuery.data;

  const showPaperlessWarning =
    offerSnapshot.isAutoPayPaperlessDiscountOffer && !formValues.paperless;

  const showAutoPayMessage = offerSnapshot.isAutoPayPaperlessDiscountOffer;

  return (
    <form noValidate onSubmit={onSubmit} id={WizardComponentName.movingForm}>
      <MovingDatesContainer>
        <ServiceDatePicker
          header="Move Out"
          name="moveOut"
          label={
            currentServiceAddress
              ? `${currentServiceAddress.line1} ${currentServiceAddress.line2}`
              : "—"
          }
          value={formValues.moveOut}
          onChange={(dateString) =>
            handleDatePickerChange(dateString, "moveOut")
          }
          disabled={formValues.moveOutUnknown}
          allowAnyDaysInTheFuture={isOps(groups)}
          overrideMinimumDayToStartService={false}
        />
        <ServiceDatePicker
          header="Move In"
          name="moveIn"
          label={
            newServiceAddress
              ? `${newServiceAddress.line1} ${newServiceAddress.line2}`
              : "—"
          }
          value={formValues.moveIn}
          onChange={(dateString) =>
            handleDatePickerChange(dateString, "moveIn")
          }
          allowAnyDaysInTheFuture={isOps(groups)}
          overrideMinimumDayToStartService={false}
        />
      </MovingDatesContainer>
      <FormFlexRow>
        <CheckboxContainer $shiftLeft>
          <BoCheckboxInput
            name="moveOutUnknown"
            id="moveOutUnknown"
            onChange={handleCheckboxChange}
            checked={formValues.moveOutUnknown}
          />
        </CheckboxContainer>
        <CheckboxLabel $htmlFor="moveOutUnknown">
          Move Out Date unknown
        </CheckboxLabel>
      </FormFlexRow>
      <StyledDivider $light />
      <FormRow>
        <Subtitle2 $fontWeight="Bold" as="div">
          Billing Preferences
        </Subtitle2>
      </FormRow>

      <BillingPreferencesContainer>
        <div>
          <BoCard>
            <PaperlessCheckboxContainer>
              <CheckboxContainer $shiftLeft>
                <BoCheckboxInput
                  name="paperless"
                  id="paperless"
                  onChange={handleCheckboxChange}
                  checked={formValues.paperless}
                />
              </CheckboxContainer>

              <CheckboxLabel $htmlFor="paperless">Paperless</CheckboxLabel>
            </PaperlessCheckboxContainer>
            <PaperlessDisclaimer>
              The customer agrees to receive their welcome packet, bills,
              contract documents, and contract related notices (including
              contract expiration notices and disconnect notices) via email.
              They understand they won&apos;t receive their bills via regular
              mail.
            </PaperlessDisclaimer>
          </BoCard>

          {showPaperlessWarning ? (
            <PaperlessWarningCard $variant={BoCardVariants.Warning}>
              The customer must be enrolled in Auto Pay and Paperless to receive
              the Auto Pay and Paperless Credit.
            </PaperlessWarningCard>
          ) : null}
        </div>

        <div>
          {showAutoPayMessage ? (
            <AutoPayInfoCard $variant={BoCardVariants.Warning}>
              Auto Pay must be turned on after the completion of signing up for
              the customer to receive the Auto Pay and Paperless Credit.
            </AutoPayInfoCard>
          ) : null}
        </div>
      </BillingPreferencesContainer>
    </form>
  );
};
