import { rhapsodyCustomerDocumentsApi } from "@ops-data/api/rhapsodyCustomerDocumentsApi";
import { CustomerDocumentUploadType } from "@ops-utils/types/customerDocumentTypes";
import { CustomerDocumentsQueryKeys } from "@ops/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCustomerDocumentUploadMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uploadData: CustomerDocumentUploadType) => {
      const formData = new FormData();

      formData.append("customer", uploadData.customer);
      formData.append("premise", uploadData.premise);
      formData.append("document_type", uploadData.documentType);
      formData.append("file", uploadData.file);
      formData.append("document_name", uploadData.documentName);

      return rhapsodyCustomerDocumentsApi.upload(formData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CustomerDocumentsQueryKeys.BASE,
      });
    },
  });
};
