import { UsageType } from "@common/types/usageTypes";
import {
  BubbleDataPoint,
  Chart as ChartJS,
  ChartTypeRegistry,
  LegendItem,
  Point,
} from "chart.js";

export const getLabels = (
  chart: ChartJS<
    keyof ChartTypeRegistry,
    (number | [number, number] | Point | BubbleDataPoint | null)[],
    unknown
  >,
  isGeneration: boolean
) => {
  const { data } = chart.data.datasets[0];

  const tiers: Record<string, { tierColor?: string; tierOrder?: number }> =
    data.reduce(
      (
        acc: Record<string, { tierColor?: string; tierOrder?: number }>,
        usageData
      ) => {
        if (usageData) {
          const {
            generationTierColor,
            generationTierName,
            generationTierOrder,
            consumptionTierName,
            consumptionTierColor,
            consumptionTierOrder,
          } = usageData as unknown as UsageType;

          const tierName = isGeneration
            ? generationTierName
            : consumptionTierName;

          if (!tierName) {
            return acc;
          }

          if (!acc[tierName]) {
            acc[tierName] = {
              tierColor: isGeneration
                ? generationTierColor
                : consumptionTierColor,
              tierOrder: isGeneration
                ? generationTierOrder
                : consumptionTierOrder,
            };
          }
        }

        return acc;
      },
      {}
    );

  const labels: LegendItem[] = Object.entries(tiers)
    .map(([key, value]) => ({
      datasetIndex: value.tierOrder,
      fillStyle: value.tierColor,
      fontColor: "#666",
      index: value.tierOrder,
      strokeStyle: value.tierColor,
      text: key,
    }))
    .sort(
      (a, b) => (a.index ?? Number.MAX_VALUE) - (b.index ?? Number.MAX_VALUE)
    );

  return labels;
};
