import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import { TemperatureType, UsageType } from "@common/types/usageTypes";
import {
  BillComparisonType,
  BillFactorsType,
} from "@ops-utils/types/billBreakdownTypes";
import { InvoiceType } from "@ops-utils/types/invoiceTypes";

const BASE_PATH = "/api/rhapsody/invoices";

export const rhapsodyInvoicesApi = {
  compare: (invoiceId: IdType) =>
    ajax.get<BillComparisonType>(
      `${BASE_PATH}/${invoiceId}/bill-explanation/compare`
    ),
  factors: (invoiceId: IdType) =>
    ajax.get<BillFactorsType>(
      `${BASE_PATH}/${invoiceId}/bill-explanation/factors`
    ),
  retrieve: (invoiceId: IdType) =>
    ajax.get<InvoiceType>(`${BASE_PATH}/${invoiceId}`),
  temperature: (invoiceId: IdType) =>
    ajax.get<{ temperatures: TemperatureType[] }>(
      `${BASE_PATH}/${invoiceId}/bill-explanation/temperature`
    ),
  usage: (invoiceId: IdType) =>
    ajax.get<UsageType[]>(`${BASE_PATH}/${invoiceId}/bill-explanation/usage`),
};
