import { getEnvApplicationSettings } from "@common/services/getEnvApplicationSettings.service";
import { allEnvApplicationSettings } from "@ops/settings/env";

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings
);

export const RHYTHM_MARKETING_URL = envApplicationSettings.marketingUrl;
export const RHYTHM_PORTAL_URL = envApplicationSettings.portalUrl;
export const PORTAL_SIGN_UP_PATHNAME = "sign-up";

export const signUpBasePath = () => {
  return `/${PORTAL_SIGN_UP_PATHNAME}`;
};

export const getIterableUrl = (email: string) =>
  `https://app.iterable.com/users/profiles/${email}/fields?projectId=${envApplicationSettings.iterableProjectId}`;
