import { EdiActions } from "@common/constants/edi.constants";
import { IdType } from "@common/types/apiTypes";
import { ReactComponent as XInCircle } from "@ops-design-system/icons/XInCircle.svg";
import { EdiActionTriggerWithConfirmation } from "@ops/components/EdiActionTriggerWithConfirmation/EdiActionTriggerWithConfirmation";
import React from "react";

interface CancelSwitchProps {
  customerId: IdType;
}

export const CancelSwitch = ({ customerId }: CancelSwitchProps) => {
  return (
    <EdiActionTriggerWithConfirmation
      action={EdiActions.SWITCH_CANCEL}
      buttonIcon={XInCircle}
      confirmBtnText="Cancel Switch"
      confirmMessage="Are you sure you want to cancel switch?"
      customerId={customerId}
      errorMessage="There was a problem sending the cancel switch.  Please try again."
      header="Cancel Switch"
      triggerOpenDialogBtnText="Cancel Switch"
    />
  );
};
