import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { rhapsodyProspectApi } from "@ops-data/api/rhapsodyProspectApi";
import { ProspectType } from "@ops-utils/types/prospectTypes";
import { useCallback } from "react";

export const useProspectInState = (id?: IdType | null) => {
  const [
    { data: prospect, requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<ProspectType>();

  const fetchProspect = useCallback((): Promise<ProspectType | void> => {
    if (!id) {
      return Promise.resolve();
    }
    setPending();

    return rhapsodyProspectApi
      .retrieve(id)
      .then((prospectData) => {
        setSuccess(prospectData);
        return prospectData;
      })
      .catch(setFailure);
  }, [id]);

  return {
    fetchProspect,
    prospect,
    requestMonitor,
  };
};
