import { BoCheckbox } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import {
  Body1,
  Body2,
  H3,
} from "@ops-design-system/components/Typography/Typography";
import {
  darkPurple,
  grey,
  red,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import {
  ContractRenewalWizardLabel,
  ContractRenewalWizardSectionContainer,
  ContractRenewalWizardStepContainer,
  ContractRenewalWizardStepForm,
  ContractRenewalWizardValue,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import styled from "styled-components";

export const ConfirmationDetailsSectionLabel = styled(
  ContractRenewalWizardLabel
).attrs({
  as: Body1,
})`
  color: ${grey["900"]};
`;

export const ConfirmationDetailsSectionValue = styled(
  ContractRenewalWizardValue
).attrs({
  as: Body1,
})`
  color: ${grey["600"]};
`;

export const ConfirmationDetailsSectionContainer = styled(
  ContractRenewalWizardSectionContainer
)`
  gap: ${rhOpsSpacingPx(0.5)};
`;

export const ConfirmationStepContainer = styled(
  ContractRenewalWizardStepContainer
)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  padding: ${rhOpsSpacingPx(3)};
  padding-top: 0;
  width: 100%;
`;

export const ConfirmationStepForm = styled(ContractRenewalWizardStepForm)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(3)};
  margin-top: ${rhOpsSpacingPx(2)};
`;

export const ConfirmationDetailsContainer = styled.div`
  display: grid;
  gap: ${rhOpsSpacingPx(1)};
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const ConfirmationDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(0.5)};
`;

export const ConfirmationCheckbox = styled(BoCheckbox)`
  border: 1px solid ${darkPurple["500"]};

  &::before {
    box-shadow: inset 1em 1em ${darkPurple["500"]};
  }
`;

export const ConfirmationCheckboxLabel = styled.label`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(1)};
  width: 100%;
`;

export const ConfirmationHeader = styled(H3)`
  text-align: center;
`;

export const PaperlessWarningText = styled(Body2)`
  color: ${red.main};
`;

export const AutoPayWarningText = styled(Body2)`
  color: ${red.main};
`;
