import { OktaGroups } from "@common/constants/okta.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhApiError } from "@common/types/errorTypes";
import { noop } from "@common/utils/genericFunctions";
import isNil from "lodash/isNil";
import React, { createContext } from "react";

export interface AuthGroupsProviderContext {
  groups: OktaGroups[] | null;
  hasResolvedGroups: boolean;
  setFailure: (err: RhApiError) => void;
  setGroups: (groups: OktaGroups[]) => void;
  setPending: () => void;
}

export const AuthGroupsContext = createContext<AuthGroupsProviderContext>({
  groups: [],
  hasResolvedGroups: false,
  setFailure: noop,
  setGroups: noop,
  setPending: noop,
});

// Create a provider for components to consume and subscribe to changes
export const AuthGroupsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [
    { data: groups, requestMonitor },
    { setSuccess: setGroups, setPending, setFailure },
  ] = useAjaxState<OktaGroups[]>();

  return (
    <AuthGroupsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        groups,
        hasResolvedGroups:
          !requestMonitor.isPending && !requestMonitor.isIdle && !isNil(groups),
        setFailure,
        setGroups,
        setPending,
      }}
    >
      {children}
    </AuthGroupsContext.Provider>
  );
};
