import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  Body1,
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import styled from "styled-components";

export const SelectLabel = styled<typeof BoLabel>(Body3).attrs({ as: "label" })`
  display: block;
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(0.5)};
`;
export const SelectContainer = styled(BoFlexBox)`
  align-items: flex-end;
  flex-direction: column;
`;
export const HeaderSubsection = styled(BoFlexBox)`
  & > * {
    flex: 1;
  }
  & > :last-child {
    flex: 0.5;
  }
`;
export const Header = styled(Body1).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(2)};
`;
export const DateHeader = styled(Body3)`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(0.5)};
`;
export const StyledLabel = styled<typeof BoLabel>(Body3).attrs({
  as: "label",
})``;
export const OfferSectionsWrapper = styled.section`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(3)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;
export const OffersSection = styled.section`
  & > :first-child {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;
export const StyledOfferCard = styled(OfferCard)`
  min-width: 0;
  width: 100%;
`;
export const OfferHeader = styled(Body2).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
`;
export const TermsCheckboxRow = styled(BoFlexBox)`
  align-items: center;
  flex-direction: row;
  margin-left: ${rhOpsSpacingPx(-2.5)};
  margin-top: ${rhOpsSpacingPx(4.5)};
`;
export const EmptyOfferMessage = styled(Body2).attrs({ as: "div" })``;
