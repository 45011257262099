import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoInfoValue } from "@ops-design-system/components/BoInfo/BoInfo";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const BillingPreferencesCard = styled(BoCard)`
  display: grid;
  gap: ${rhOpsSpacingPx(2)};
  grid-template-columns: 1fr 1fr;
`;

export const CommunicationPreferenceValue = styled(BoInfoValue)<{
  $isRed?: boolean;
}>`
  ${({ $isRed }) => ($isRed ? `color: ${red[500]}` : null)}
`;

export const BillingPreferencesLabelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const BillingPreferencesWarningCard = styled(BoCard)`
  color: ${red.main};
  margin-bottom: ${rhOpsSpacingPx(2)};
  padding: ${rhOpsSpacingPx(2)};
`;
