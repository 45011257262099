import {
  DeferPaymentManageModalStateContext,
  DeferPaymentManageModalStateProviderContext,
} from "@ops/components/DeferPaymentManageWizard/DeferPaymentManageModalStateProvider";
import { useContext } from "react";

export function useDeferPaymentManageModalState(): DeferPaymentManageModalStateProviderContext {
  const context = useContext<DeferPaymentManageModalStateProviderContext>(
    DeferPaymentManageModalStateContext
  );

  if (context === null) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }
  return context;
}
