import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  BoSelectInput,
  BoSelectOptionType,
  BoSelectValue,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import {
  AddCreditCardButton,
  AddPaymentButtonWrapper,
} from "@ops/components/AddCreditCardForm/AddCreditCardButton";
import React from "react";
import styled from "styled-components";

interface PaymentMethodSelectorProps {
  current: BoSelectValue;
  handleAddPayment: () => void;
  noCreditOrDebitCard: boolean;
  onChange: (paymentMethodOption: BoSelectOptionType | null) => void;
  options: BoSelectOptionType[];
}

const Prompt = styled(Body1).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

export const PaymentMethodSelector = ({
  options,
  current,
  onChange,
  noCreditOrDebitCard,
  handleAddPayment,
}: PaymentMethodSelectorProps) => {
  if (noCreditOrDebitCard) {
    return (
      <Prompt>
        Credit and Debit Card payments have been disabled for this premise.
        Please use another payment method.
      </Prompt>
    );
  }

  return (
    <>
      {options.length ? (
        <>
          <BoLabel htmlFor="paymentSelectorInput">Payment Methods</BoLabel>
          <BoSelectInput
            id="paymentSelectorInput"
            options={options}
            onChange={onChange}
            value={current}
          />
        </>
      ) : (
        <Prompt>To pay down payment, first add a payment method.</Prompt>
      )}
      <BoFlexBox>
        <AddPaymentButtonWrapper>
          <AddCreditCardButton onClick={handleAddPayment} />
        </AddPaymentButtonWrapper>
      </BoFlexBox>
    </>
  );
};
