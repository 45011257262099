import { IdType } from "@common/types/apiTypes";

const REWARDS_BASE_URL = "/api/rewards";
const REWARDS_AFFILIATES_BASE_URL = `${REWARDS_BASE_URL}/affiliates`;
const REWARDS_CUSTOMERS_BASE_URL = `${REWARDS_BASE_URL}/customers`;

export const rewardsUrls = {
  affiliates: {
    redeemRewardedPoints: (id: IdType) =>
      `${REWARDS_AFFILIATES_BASE_URL}/${id}/redeem_rewarded_points/`,
    retrieve: (id: IdType) => `${REWARDS_AFFILIATES_BASE_URL}/${id}/`,
    rewardRedemptionHistory: (id: IdType) =>
      `${REWARDS_AFFILIATES_BASE_URL}/${id}/reward_redemption_history/`,
  },
  customers: {
    addPoints: (id: IdType) =>
      `${REWARDS_CUSTOMERS_BASE_URL}/${id}/add_points/`,
    retrieve: (id: IdType) => `${REWARDS_CUSTOMERS_BASE_URL}/${id}/`,
  },
  raf: {
    validateReferralCode: (referralCode: string) =>
      `/api/rewards/raf_referrers/${referralCode}/`,
  },
};
