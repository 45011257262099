export type CreatePaymentDataType = {
  amount: number;
  paymentMethodId: string;
  paymentTransactionFee?: number;
};

export type CreatePaymentResponseType = {
  paymentNumber: string;
};

export const PAYMENT_TRANSACTION_FEE = 5;
