export enum ExternalPaymentFileStatus {
  ERROR = "ERROR",
  FAIL = "FAIL",
  PARTIAL_SUCCESS = "PARTIAL SUCCESS",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  UNPROCESSED = "UNPROCESSED",
}

export enum ExternalPaymentStatus {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  UNPROCESSED = "UNPROCESSED",
}
