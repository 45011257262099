import { legacyContactLogApi } from "@ops-data/api/legacyContactLogApi";
import { ContactLogOptionQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useContactLogOptionsQuery = () => {
  const query = useQuery({
    queryFn: () => legacyContactLogApi.categories.list(),
    queryKey: ContactLogOptionQueryKeys.LIST,
    refetchOnWindowFocus: false,
  });

  return query;
};
