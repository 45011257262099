import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { PropsWithChildren } from "react";
import { useField } from "react-final-form";

interface ConfirmFieldProps {
  className?: string;
  name?: string;
}
export const ConfirmField = ({
  name = "confirm",
  className,
  children,
}: PropsWithChildren<ConfirmFieldProps>) => {
  const required = (value: string) => (value ? undefined : "Required");
  const { input } = useField("confirm", {
    type: "checkbox",
    validate: required,
  });

  const checkbox = <Checkbox color="primary" id={name} {...input} />;

  return (
    <FormControlLabel
      className={className}
      control={checkbox}
      label={children}
    />
  );
};
