import {
  StyledBoToggle,
  StyledBoToggleCheckbox,
  StyledBoToggleLabel,
  StyledBoToggleLabelInner,
  StyledBoToggleLabelSwitch,
} from "@ops-design-system/components/BoToggleField/BoToggle.styled";
import React, { ComponentPropsWithRef } from "react";

interface BoToggleProps {
  className?: string;
  inputProps: ComponentPropsWithRef<"input">;
}

export const BoToggle = ({ className, inputProps }: BoToggleProps) => {
  return (
    <StyledBoToggle className={className}>
      <StyledBoToggleCheckbox
        {...inputProps}
        id={inputProps.name}
        type="checkbox"
      />
      <StyledBoToggleLabel htmlFor={inputProps?.name}>
        <StyledBoToggleLabelInner />
        <StyledBoToggleLabelSwitch />
      </StyledBoToggleLabel>
    </StyledBoToggle>
  );
};
