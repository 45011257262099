import { RewardsResponseType } from "@common/types/rewardsTypes";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import {
  BoTableCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import {
  StyledCustomerRewardsTable,
  StyledCustomerRewardsTableBody,
  StyledCustomerRewardsTitle,
  StyledRedeemedMessage,
} from "@ops/components/CustomerRewardsHistoryTable/CustomerRewardsHistoryTable.styled";
import React from "react";

interface CustomerRewardsHistoryTableProps {
  rewards: RewardsResponseType | null;
}

export const CustomerRewardsHistoryTable = ({
  rewards,
}: CustomerRewardsHistoryTableProps) => {
  if (!rewards) {
    return null;
  }

  return (
    <>
      <StyledCustomerRewardsTitle>Rewards History</StyledCustomerRewardsTitle>
      <StyledCustomerRewardsTable>
        <StyledCustomerRewardsTableBody>
          {rewards.history.map((reward) => {
            return (
              <BoTableRow key={reward.createdAt}>
                <BoTableCell>
                  {formatMonthDayYear(reward.createdAt)}
                </BoTableCell>
                <BoTableCell>
                  {reward.name}{" "}
                  {reward.redeemedAt && (
                    <StyledRedeemedMessage>
                      Redeemed {formatMonthDayYear(reward.redeemedAt)}
                    </StyledRedeemedMessage>
                  )}
                </BoTableCell>
                <BoTableCell $align="right">{reward.points} points</BoTableCell>
                <BoTableCell $align="right">
                  {formatCurrency(rewardPointsToDollars(reward.points))}
                </BoTableCell>
              </BoTableRow>
            );
          })}
        </StyledCustomerRewardsTableBody>
      </StyledCustomerRewardsTable>
    </>
  );
};
