import { Premise } from "@common/models/Premise.model";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { useBillForecastSummary } from "@ops/hooks/queries/usePremiseBillForecastSummary";
import { usePremiseKwhUsageSummary } from "@ops/hooks/queries/usePremiseKwhUsageSummary";
import React from "react";
import styled from "styled-components";

interface BillSummaryProps {
  premise: Premise;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const BillSummary = (props: BillSummaryProps) => {
  const { premise } = props;

  const billForecastSummaryQuery = useBillForecastSummary({
    premiseId: premise.id,
  });
  const kwhUsageSummaryQuery = usePremiseKwhUsageSummary({
    enabled: Boolean(
      premise.id && premise.currentOrder && premise.meter?.isSmt
    ),
    premiseId: premise.id,
  });

  if (kwhUsageSummaryQuery.isPending || billForecastSummaryQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (kwhUsageSummaryQuery.isError || billForecastSummaryQuery.isError) {
    return <Body2>Error fetching Bill Summary</Body2>;
  }

  const { billPeriodData } = kwhUsageSummaryQuery.data;

  const billPeriodConsumptionKwh = billPeriodData
    .map((billPeriodUsage) => billPeriodUsage.consumptionKwh)
    .reduce((prev, next) => (Number(prev) + Number(next)).toString(), "0");
  const billPeriodConsumptionCost = billPeriodData
    .map((billPeriodUsage) => billPeriodUsage.consumptionCost)
    .reduce((prev, next) => (Number(prev) + Number(next)).toString(), "0");

  return (
    <Container>
      <Container>
        <Body1 $fontWeight="Bold">Bill to Date</Body1>

        <Body1>
          {`${formatCurrency(billPeriodConsumptionCost)} / ${formatKwh(
            Number(billPeriodConsumptionKwh),
            2
          )}`}
        </Body1>
      </Container>

      <Container>
        <Body1 $fontWeight="Bold">Projected Bill</Body1>

        <Body1>
          {`${formatCurrency(
            billForecastSummaryQuery.data.consumptionCost
          )} / ${formatKwh(
            Number(billForecastSummaryQuery.data.consumptionKwh),
            2
          )}`}
        </Body1>
      </Container>
    </Container>
  );
};
