import { maskCurrencyWithFractionalZeros } from "@common/forms/currency.mask";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  Body1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { paymentSuccessPaylodAtom } from "@ops/components/MakeAPayment/MakeAPayment.atoms";
import { StyledSuccessMessage } from "@ops/components/MakeAPayment/MakeAPayment.styled";
import { useAtomValue } from "jotai";
import React from "react";

interface MakeAPaymentSuccessProps {
  closeModal: () => void;
}

export const MakeAPaymentSuccess = ({
  closeModal,
}: MakeAPaymentSuccessProps) => {
  const paymentPayload = useAtomValue(paymentSuccessPaylodAtom);

  return (
    <>
      <BoDialogHeader>Make a Payment</BoDialogHeader>
      <BoDialogBody>
        <StyledSuccessMessage>
          <Subtitle2>Payment of </Subtitle2>
          <Subtitle2 $fontWeight="Bold">
            {maskCurrencyWithFractionalZeros(paymentPayload.submittedAmount)}
          </Subtitle2>{" "}
          <Subtitle2>was successful!</Subtitle2>
        </StyledSuccessMessage>
        <Body1 as="div">Confirmation {paymentPayload.paymentNumber}</Body1>
      </BoDialogBody>
      <BoDialogButtonFooter
        cancelBtnText="Back to Customer Page"
        onCancel={closeModal}
      />
    </>
  );
};
