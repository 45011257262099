import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { ReactComponent as OppositeArrows } from "@ops-design-system/icons/OppositeArrows.svg";
import { TransferServiceWizardModal } from "@ops/components/TransferServiceWizard/TransferServiceWizardModal";
import { TransferServiceWizardProvider } from "@ops/components/TransferServiceWizard/TransferServiceWizardProvider";
import React from "react";

interface TransferServiceWizardProps {
  customer: Customer;
  premise: Premise;
}

export const TransferServiceWizard = ({
  premise,
  customer,
}: TransferServiceWizardProps) => {
  const { open, close, isOpen } = useModalState();

  return (
    <>
      <BoButton
        icon={OppositeArrows}
        variant="secondary"
        size="extraSmall"
        onClick={open}
      >
        Transfer Service
      </BoButton>
      <TransferServiceWizardProvider
        premise={premise}
        customer={customer}
        closeModal={close}
      >
        <TransferServiceWizardModal
          close={close}
          isOpen={isOpen}
          premise={premise}
        />
      </TransferServiceWizardProvider>
    </>
  );
};
