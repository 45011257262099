import { AddressFormType } from "@common/types/customerTypes";
import { BoRadioInput } from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import {
  AddressForm,
  AddressFormProps,
} from "@ops/components/MeterSearchForms/AddressForm";
import {
  EsiIdForm,
  EsiIdFormProps,
} from "@ops/components/MeterSearchForms/EsiIdForm";
import { FormRadioContainer } from "@ops/components/MeterSearchForms/MeterSearchForms.styled";
import React, { useState } from "react";

enum ActiveFormType {
  ADDRESS = "ADDRESS",
  ESI_ID = "ESI_ID",
}

export interface ValidatedAddressType {
  dunsNumber?: string;
  esiId: string;
  serviceAddress: AddressFormType;
  utilityName?: string;
}

export const useMeterSearchForms = () => {
  const [activeForm, setActiveForm] = useState<ActiveFormType>(
    ActiveFormType.ADDRESS
  );
  const setAddressFormActive = () => {
    setActiveForm(ActiveFormType.ADDRESS);
  };
  const setEsiIdFormActive = () => {
    setActiveForm(ActiveFormType.ESI_ID);
  };
  const addressFormActive = activeForm === ActiveFormType.ADDRESS;
  const esiIdFormActive = activeForm === ActiveFormType.ESI_ID;

  const SearchByAddress = (props: Omit<AddressFormProps, "disabled">) => (
    <>
      <FormRadioContainer>
        <BoRadioInput
          id="meter-search-form__search-by-address"
          checked={addressFormActive}
          onClick={setAddressFormActive}
          label="Search by address"
        />
      </FormRadioContainer>
      <AddressForm disabled={!addressFormActive} {...props} />
    </>
  );
  const SearchByEsiId = (props: Omit<EsiIdFormProps, "disabled">) => (
    <>
      <FormRadioContainer>
        <BoRadioInput
          id="meter-search-form__search-by-esi-id"
          checked={esiIdFormActive}
          onClick={setEsiIdFormActive}
          label="Search by ESIID"
        />
      </FormRadioContainer>
      <EsiIdForm disabled={!esiIdFormActive} {...props} />
    </>
  );

  return [SearchByAddress, SearchByEsiId];
};
