import isNil from "lodash/isNil";
import styled, { css } from "styled-components";

interface FlexBoxProps {
  $alignItems?: string;
  $flexDirection?: string;
  $flexWrap?: string;
  $justifyContent?: string;
  $margin?: number;
  $marginBottom?: number;
  $marginLeft?: number;
  $marginRight?: number;
  $marginTop?: number;
  $padding?: number;
  $paddingBottom?: number;
  $paddingLeft?: number;
  $paddingRight?: number;
  $paddingTop?: number;
}

const flexDirection = (props: FlexBoxProps) =>
  !isNil(props.$flexDirection)
    ? css`
        flex-direction: ${props.$flexDirection};
      `
    : "";

const flexWrap = (props: FlexBoxProps) =>
  !isNil(props.$flexWrap)
    ? css`
        flex-wrap: ${props.$flexWrap};
      `
    : "";

const justifyContent = (props: FlexBoxProps) =>
  !isNil(props.$justifyContent)
    ? css`
        justify-content: ${props.$justifyContent};
      `
    : "";

const alignItems = (props: FlexBoxProps) =>
  !isNil(props.$alignItems)
    ? css`
        align-items: ${props.$alignItems};
      `
    : "";

export const BoFlexBox = styled.div<FlexBoxProps>`
  display: flex;
  ${flexDirection}
  ${flexWrap}
  ${justifyContent}
  ${alignItems}
`;
