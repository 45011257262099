import {
  Body1,
  Body2,
  Subtitle1,
} from "@ops-design-system/components/Typography/Typography";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const PremiseDetailsHeader = styled(Subtitle1)`
  color: ${grey["600"]};
`;

export const PremiseDetailsContainer = styled.div`
  display: grid;
  gap: ${rhOpsSpacingPx(2)} ${rhOpsSpacingPx(2.5)};
  grid-template-columns: repeat(4, 1fr);
`;

export const PremiseDetailsInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
`;
export const PremiseDetailsInfoSubsection = styled.div`
  & + & {
    margin-top: ${rhOpsSpacingPx(4)};
  }
  & > * {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;
export const PremiseDetailsDetails = styled(Body1)`
  color: ${grey["600"]};
`;

export const PremiseDetailsPaperlessWarningText = styled(Body2)`
  color: ${red.main};
`;

export const PremiseDetailsAutoPayWarningText = styled(Body2)`
  color: ${red.main};
`;
