import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { IdType } from "@common/types/apiTypes";
import { createRhContext } from "@common/utils/contextHelpers";
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

export interface PaymentMethodContextType {
  closeModal: () => void;
  handleDelete: (paymentMethod: PaymentMethod) => void;
  mode: PaymentMode;
  paymentMethodToDelete: PaymentMethod | null;
  premiseId: string;
  setContextMode: (mode: PaymentMode) => void;
}

export enum PaymentMode {
  addBankAccountMode = "addBankAccount",
  addCardMode = "addCard",
  deleteMode = "delete",
  listMode = "list",
}

export const PaymentMethodContext = createRhContext<PaymentMethodContextType>();

interface ManagePaymentMethodsContextProviderProps {
  closeModal: () => void;
  premiseId: IdType;
}

export const ManagePaymentMethodsContextProvider = ({
  premiseId,
  children,
  closeModal,
}: PropsWithChildren<ManagePaymentMethodsContextProviderProps>) => {
  const [paymentMode, setPaymentMode] = useState<PaymentMode>(
    PaymentMode.listMode
  );

  const [paymentMethodToDelete, setPaymentMethodToDelete] =
    useState<PaymentMethod | null>(null);

  const handleDelete = useCallback((paymentMethod: PaymentMethod) => {
    setPaymentMethodToDelete(paymentMethod);
    setPaymentMode(PaymentMode.deleteMode);
  }, []);

  const handleMode = useCallback((mode: PaymentMode) => {
    setPaymentMode(mode);
  }, []);

  const value = useMemo(
    () => ({
      closeModal,
      handleDelete,
      mode: paymentMode,
      paymentMethodToDelete,
      premiseId,
      setContextMode: handleMode,
    }),
    [
      closeModal,
      handleDelete,
      paymentMode,
      paymentMethodToDelete,
      premiseId,
      handleMode,
    ]
  );

  return (
    <PaymentMethodContext.Provider value={value}>
      {children}
    </PaymentMethodContext.Provider>
  );
};
