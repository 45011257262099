import { OktaGroups } from "@common/constants/okta.constant";
import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { CancelSwitch } from "@ops/components/CancelSwitch/CancelSwitch";
import { ReSubmitEnrollment } from "@ops/components/ReSubmitEnrollment/ReSubmitEnrollment";
import { UpdateServiceStart } from "@ops/components/UpdateServiceStart/UpdateServiceStart";
import React from "react";

interface EnrollmentActionsCardProps {
  customer: Customer;
  premise: Premise;
}

export const EnrollmentActionsCard = ({
  customer,
  premise,
}: EnrollmentActionsCardProps) => {
  return (
    <>
      <BoCardLabel>Enrollment Actions</BoCardLabel>
      <BoCard>
        <ButtonsGrid>
          <AccessControl allowedGroups={[OktaGroups.CSRTier3]}>
            <CancelSwitch customerId={customer.id} />
          </AccessControl>
          <AccessControl allowedGroups={[OktaGroups.CSRTier3]}>
            <UpdateServiceStart currentPremise={premise} />
          </AccessControl>
          <AccessControl allowedGroups={[OktaGroups.CSRTier3]}>
            <ReSubmitEnrollment premise={premise} />
          </AccessControl>
        </ButtonsGrid>
      </BoCard>
    </>
  );
};
