import { ZuoraIdType } from "@common/types/apiTypes";

export interface Balance {
  pastDueBalance: number;
  totalBalance: number;
}

export interface BalanceBreakdown {
  energy: Balance;
  product: Balance;
}

export interface AccountSummaryType {
  accountDueDate: string;
  accountNumber: string;
  balanceBreakdown: BalanceBreakdown;
  invoices: InvoiceType[];
  mostRecentBalance: number;
  nextDueDate: string;
  pastDueBalance: number;
  totalBalance: number;
}

export interface InvoiceType {
  accountId: ZuoraIdType;
  amount: number;
  balance: number;
  dueDate: string;
  id: ZuoraIdType;
  invoiceDate: string;
  invoiceNumber: string;
  invoiceType: InvoiceTypeEnum;
  lateFeeApplied: boolean | undefined;
  rewardCreditApplied: boolean;
  serviceEndDate: string;
  serviceStartDate: string;
  totalKwh: number;
}

export enum InvoiceTypeEnum {
  CANCEL_REBILL = "CANCEL_REBILL",
  DEPOSIT = "DEPOSIT",
  ENERGY = "ENERGY",
}
