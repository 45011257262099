import { premiseApi } from "@common/api/premiseApi";
import { PremiseStatus } from "@common/enums/premise.enum";
import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { IdType } from "@common/types/apiTypes";
import {
  RhApiError,
  RhApiErrorDetail,
  RhApiErrorResponseData,
} from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as TripleChevron } from "@ops-design-system/icons/TripleChevron.svg";
import { AddACreditCardForm } from "@ops/components/AddCreditCardForm/AddACreditCardForm";
import { DeferPaymentForm } from "@ops/components/DeferPaymentForm/DeferPaymentForm";
import { useDeferPaymentFormState } from "@ops/components/DeferPaymentForm/useDeferPaymentFormState";
import { ConfirmNoDownPayment } from "@ops/components/DeferPaymentWizard/ConfirmNoDownPayment/ConfirmNoDownPayment";
import { DeferPaymentComponentType } from "@ops/components/DeferPaymentWizard/DeferPaymentModalStateProvider";
import { useDeferPaymentModalState } from "@ops/components/DeferPaymentWizard/useDeferPaymentModalStateProvider";
import { useDownPaymentCalculatorState } from "@ops/components/DownPaymentCalculator/useDownPaymentCalculatorState";
import { LoadingModalContent } from "@ops/components/LoadingModalContent/LoadingModalContent";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { paymentMethodsFetch } from "@ops/slices/paymentMethodsSlice";
import { distributeDPPTotal } from "@ops/utils/deferPaymentHelper";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

interface DeferPaymentModalProps {
  currentBalance: number;
  onSuccess: () => void;
  pastDueBalance: number;
  premise: Premise;
}

export const DeferPaymentModal = ({
  currentBalance,
  pastDueBalance,
  premise,
  onSuccess,
}: DeferPaymentModalProps) => {
  const { premiseId } = useParams<{ premiseId: IdType }>();

  const { open, close: closeModal, isOpen } = useModalState();

  const { resetFormState } = useDeferPaymentFormState();

  const { resetCalculatorState } = useDownPaymentCalculatorState();

  const { paymentMethods, requestMonitor } = usePaymentMethods(premise.id);

  const flash = useRhFlash();

  const [requestPending, setRequestPending] = useState(false);

  const { updateModalState, activeComponent, resetModalState } =
    useDeferPaymentModalState();

  const dispatch = useDispatch();

  const resetDeferModalState = () => {
    resetFormState();
    resetModalState();
    resetCalculatorState();
  };

  const close = () => {
    closeModal();
    resetDeferModalState();
  };

  const handleAddedCard = () => {
    dispatch(paymentMethodsFetch(premise.id));
    updateModalState({
      activeComponent: DeferPaymentComponentType.deferPaymentForm,
    });
  };

  const clearAPIError = () => updateFormState({ errors: null });

  const handleAPIError = (apiError: RhApiError) => {
    updateFormState({ errors: errorMessage(apiError.data) });
    flash.error("There was an error trying to create a deferred payment plan.");
    setRequestPending(false);
  };

  const errorMessage = (errorData: RhApiErrorResponseData) => {
    const baseMessage = "Error when creating a deferred payment plan";
    const individualErrors = errorData.errors
      .map((a: RhApiErrorDetail) => {
        if (a.field && a.field !== "non_field_errors") {
          return `${a.field}: ${a.detail}`;
        }
        return a.detail;
      })
      .join(" | ");

    return `${baseMessage}: ${individualErrors}`;
  };

  const { totalAmountDue, downPayment } = useDownPaymentCalculatorState();

  const {
    updateFormState,
    numberOfInstallments,
    currentPaymentMethod,
    hasMadeDownPayment,
    selectedPaymentList,
  } = useDeferPaymentFormState();

  const deferredTotal = totalAmountDue - downPayment;

  const installments = distributeDPPTotal(deferredTotal, numberOfInstallments);

  const handleSubmit = () => {
    setRequestPending(true);
    let deferredPaymentPlanData;

    if (hasMadeDownPayment) {
      deferredPaymentPlanData = {
        installments,
        paymentAmount: String(downPayment),
        paymentIds: selectedPaymentList.map((payment) => payment.number).flat(),
      };
    } else {
      deferredPaymentPlanData = {
        installments,
        paymentAmount: String(downPayment),
        paymentMethodId: String(currentPaymentMethod),
      };
    }

    clearAPIError();
    premiseApi.deferredPaymentPlans
      .create(premiseId, deferredPaymentPlanData)
      .then((_result) => {
        flash.success("Success!");
        onSuccess();
      })
      .catch(handleAPIError);
  };

  const deferPaymentButtonDisabled =
    premise.hasActiveDpp ||
    premise.averagedBilling ||
    premise.status !== PremiseStatus.ACTIVE;

  useEffect(() => {
    const setInitialActivePaymentComponent = (paymentMethodCount: number) => {
      if (isNil(paymentMethodCount)) {
        updateModalState({
          activeComponent: DeferPaymentComponentType.loading,
        });
      } else {
        updateModalState({
          activeComponent: DeferPaymentComponentType.deferPaymentForm,
        });
      }
    };

    if (requestMonitor.didSucceed) {
      setInitialActivePaymentComponent(paymentMethods?.length ?? 0);
    }
    if (requestMonitor.didFail) {
      updateModalState({
        activeComponent: DeferPaymentComponentType.deferPaymentForm,
      });
    }
  }, [paymentMethods, requestMonitor.didSucceed, requestMonitor.didFail]);

  const deferPaymentComponents: Record<
    DeferPaymentComponentType,
    () => JSX.Element
  > = {
    addPaymentMethod: () => (
      <AddACreditCardForm
        premiseId={premise.id}
        onCancel={() => {
          updateModalState({
            activeComponent: DeferPaymentComponentType.deferPaymentForm,
          });
        }}
        onSuccess={handleAddedCard}
        setAsDefault={(paymentMethods?.length ?? 0) === 0}
      />
    ),
    confirmNoDownPayment: () => (
      <ConfirmNoDownPayment
        handleSubmit={handleSubmit}
        requestPending={requestPending}
      />
    ),
    deferPaymentForm: () => (
      <DeferPaymentForm
        currentBalance={currentBalance}
        pastDueBalance={pastDueBalance}
        premiseId={premise.id}
        closeModal={close}
        handleSubmit={handleSubmit}
        requestPending={requestPending}
      />
    ),
    loading: () => <LoadingModalContent headerText="Deferred Payment Plan" />,
  };

  return (
    <>
      <BoButton
        fullWidth
        icon={TripleChevron}
        variant="secondary"
        size="extraSmall"
        onClick={open}
        disabled={deferPaymentButtonDisabled}
        aria-label="Open Defer Payment Modal"
      >
        Defer Payment
      </BoButton>

      <BoModal
        open={isOpen}
        size={
          activeComponent === DeferPaymentComponentType.addPaymentMethod
            ? "default"
            : "large"
        }
        onClose={close}
      >
        {deferPaymentComponents[activeComponent]()}
      </BoModal>
    </>
  );
};
