import { premiseApi } from "@common/api/premiseApi";
import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { IdType } from "@common/types/apiTypes";
import { DeferredPaymentPlanType } from "@common/types/deferredPaymentPlanTypes";
import {
  RhApiError,
  RhApiErrorDetail,
  RhApiErrorResponseData,
} from "@common/types/errorTypes";
import { isOps } from "@common/utils/authenticationHelpers";
import { formatCurrency } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import {
  Body2,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useDeferPaymentManageFormState } from "@ops/components/DeferPaymentManageForm/useDeferPaymentManageFormState";
import { DeferPaymentManageComponentType } from "@ops/components/DeferPaymentManageWizard/DeferPaymentManageModalStateProvider";
import { useDeferPaymentManageModalState } from "@ops/components/DeferPaymentManageWizard/useDeferPaymentManageModalStateProvider";
import { PaymentMethodSelector } from "@ops/components/PaymentMethodSelector/PaymentMethodSelector";
import { usePaymentMethodOptions } from "@ops/hooks/usePaymentMethodOptions";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { usePremise } from "@ops/hooks/usePremise";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

const StyledBoGridColumn = styled(BoGridColumn)`
  gap: ${rhOpsSpacingPx(1)};
`;

const StyledBold = styled.span`
  font-weight: ${FontWeight.Bold};
`;
const StyledRed = styled.span`
  color: ${red.main};
`;

interface CheckboxLabelProps {
  $htmlFor: string;
}

export const CheckboxLabel = styled(Body2).attrs<CheckboxLabelProps>(
  ({ $htmlFor }: { $htmlFor: string }) => ({
    as: "label",
    htmlFor: $htmlFor,
  })
)`
  align-self: center;
`;

interface CheckboxContainerProps {
  $shiftLeft: boolean;
}
export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  margin-left: ${({ $shiftLeft }) => ($shiftLeft ? "-" : "")}12px;
`;

const ErrorsHeader = styled(Subtitle2).attrs({ as: "div" })`
  color: red;
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

interface DeferPaymentPayOffFormProps {
  activeDeferredPaymentPlan: DeferredPaymentPlanType;
  closeModal: () => void;
  currentBalance: number;
  onSuccess: (amountPaid: number, zuoraPaymentNumber: string) => void;
  premiseId: IdType;
}

export const DeferPaymentPayOffForm = ({
  closeModal,
  onSuccess,
  premiseId,
  activeDeferredPaymentPlan,
  currentBalance,
}: DeferPaymentPayOffFormProps) => {
  const { premise } = usePremise(premiseId);

  const remainingDPPBalance = Number(activeDeferredPaymentPlan.debitBalance);
  const total = Number(activeDeferredPaymentPlan.debitBalance) + currentBalance;

  const needPayOffCheckBox = total >= 0.5;

  const { groups } = useContext(AuthGroupsContext);
  const isOpsUser = isOps(groups);

  const flash = useRhFlash();

  const { updateModalState } = useDeferPaymentManageModalState();

  const [payOffCheck, setPayOffCheck] = useState<boolean>();

  const [requestPending, setRequestPending] = useState(false);

  const { updateFormState, currentPaymentMethod, errors } =
    useDeferPaymentManageFormState();

  const { paymentMethods } = usePaymentMethods(premiseId);

  const { defaultPaymentMethod, paymentMethodOptions } =
    usePaymentMethodOptions(paymentMethods);

  const handleDeferPaymentManage = () => {
    updateModalState({
      activeComponent: DeferPaymentManageComponentType.deferPaymentManageForm,
    });
  };

  const handlePaymentMethodSelection = (
    paymentMethodOption: BoSelectOptionType | null
  ) => {
    if (paymentMethodOption) {
      updateFormState({
        currentPaymentMethod: paymentMethodOption.value,
      });
    }
  };

  const handleAddPayment = () => {
    updateModalState({
      activeComponent: DeferPaymentManageComponentType.addPaymentMethod,
    });
  };

  const clearAPIError = () => updateFormState({ errors: null });

  const handleAPIError = (apiError: RhApiError) => {
    updateFormState({ errors: errorMessage(apiError.data) });
    flash.error(
      "There was an error trying to pay off a deferred payment plan."
    );
    setRequestPending(false);
  };

  const errorMessage = (errorData: RhApiErrorResponseData) => {
    const baseMessage = "Error when paying off a deferred payment plan";
    const individualErrors = errorData.errors
      .map((a: RhApiErrorDetail) => {
        if (a.field && a.field !== "non_field_errors") {
          return `${a.field}: ${a.detail}`;
        }
        return a.detail;
      })
      .join(" | ");

    return `${baseMessage}: ${individualErrors}`;
  };

  const handleSubmit = () => {
    setRequestPending(true);

    let settleDppData;

    if (needPayOffCheckBox && payOffCheck && currentPaymentMethod) {
      settleDppData = { paymentMethodId: String(currentPaymentMethod) };
    } else {
      settleDppData = {};
    }

    clearAPIError();
    premiseApi.deferredPaymentPlans
      .settle(premiseId, activeDeferredPaymentPlan.id, settleDppData)
      .then((_result) => {
        flash.success("Success!");
        onSuccess(total, _result.paymentId ?? "");
      })
      .catch(handleAPIError);
  };

  const isFormValid =
    isOpsUser ||
    !needPayOffCheckBox ||
    (payOffCheck && Boolean(currentPaymentMethod));

  useEffect(() => {
    if (
      paymentMethods?.length &&
      !currentPaymentMethod &&
      defaultPaymentMethod
    ) {
      updateFormState({ currentPaymentMethod: defaultPaymentMethod.id });
    }
  }, [
    updateFormState,
    paymentMethods,
    currentPaymentMethod,
    defaultPaymentMethod,
  ]);

  if (!premise) {
    return <BoCircularProgress />;
  }

  return (
    <>
      <BoDialogHeader>Pay Off DPP</BoDialogHeader>
      <BoDialogBody>
        {errors ? <ErrorsHeader>{errors}</ErrorsHeader> : null}
        <BoGrid $variant={GridVariants.Grid2_1}>
          <StyledBoGridColumn>
            <Subtitle2 as="div">
              Remaining DPP Balance:{" "}
              <StyledRed>{formatCurrency(remainingDPPBalance)}</StyledRed>
            </Subtitle2>
            <Subtitle2 as="div">
              Current Balance:{" "}
              <StyledBold>{formatCurrency(currentBalance)}</StyledBold>
            </Subtitle2>
            <Subtitle2 as="div">
              Total: <StyledBold>{formatCurrency(total)}</StyledBold>
            </Subtitle2>
            {needPayOffCheckBox && (
              <FormFlexRow>
                <CheckboxContainer $shiftLeft>
                  <BoCheckboxInput
                    name="confirmPayOff"
                    id="confirmPayOff"
                    onClick={() => setPayOffCheck(!payOffCheck)}
                  />
                </CheckboxContainer>
                <CheckboxLabel $htmlFor="confirmPayOff">
                  Pay full balance now
                </CheckboxLabel>
              </FormFlexRow>
            )}
            {payOffCheck && (
              <PaymentMethodSelector
                current={
                  currentPaymentMethod ?? defaultPaymentMethod?.id ?? null
                }
                options={paymentMethodOptions}
                onChange={handlePaymentMethodSelection}
                noCreditOrDebitCard={premise.isNoCreditOrDebitCard}
                handleAddPayment={handleAddPayment}
              />
            )}
          </StyledBoGridColumn>
        </BoGrid>
      </BoDialogBody>
      <BoDialogButtonFooter
        cancelBtnText="Back"
        onCancel={handleDeferPaymentManage}
        confirmBtnText={needPayOffCheckBox ? "Pay Off DPP" : "Settle DPP"}
        onConfirm={handleSubmit}
        confirmDisabled={!isFormValid || requestPending}
      />
    </>
  );
};
