import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { Body1, H3 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as CirclePlus } from "@ops-design-system/icons/CirclePlus.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { MIN_WIDTH } from "@ops/constants/layout.constants";
import { adminPaths } from "@ops/routes/routePaths";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface StyledAdminPageProps {
  nameInLineButton?: string;
  onButtonClick?: () => void;
  pageName?: string;
  returnLinkText?: string;
  returnLinkTo?: string;
}

const ReturnLinkWrapper = styled(Body1).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(4)};
  margin-top: ${rhOpsSpacingPx(3)};
`;

export const StyledAdminHomePage = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-width: ${MIN_WIDTH};
  width: 100%;
`;

const HeaderFlexBox = styled.div`
  align-items: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
`;

export const StyledAdminPage = ({
  returnLinkTo,
  returnLinkText,
  pageName,
  nameInLineButton,
  onButtonClick,
  children,
}: PropsWithChildren<StyledAdminPageProps>) => {
  return (
    <StyledAdminHomePage>
      <BoFlexBox $flexDirection="column">
        {nameInLineButton ? (
          <>
            <Body1>
              <Link to={returnLinkTo ?? adminPaths.home()}>
                {returnLinkText ?? "< Admin"}
              </Link>
            </Body1>
            <HeaderFlexBox>
              <div>
                <H3 $fontWeight="SemiBold">{pageName}</H3>
              </div>
              <ButtonContainer>
                <BoButton
                  variant="secondary"
                  icon={CirclePlus}
                  size="extraSmall"
                  onClick={onButtonClick}
                >
                  {nameInLineButton}
                </BoButton>
              </ButtonContainer>
            </HeaderFlexBox>
          </>
        ) : (
          <>
            <ReturnLinkWrapper>
              <Link to={returnLinkTo ?? adminPaths.home()}>
                {returnLinkText ?? "< Admin"}
              </Link>
            </ReturnLinkWrapper>
            <H3 $fontWeight="SemiBold"> {pageName}</H3>
          </>
        )}
        <StyledDivider />
        {children}
      </BoFlexBox>
    </StyledAdminHomePage>
  );
};
