import {
  TransferServiceWizardContext,
  TransferServiceWizardContextType,
} from "@ops/components/TransferServiceWizard/TransferServiceWizardProvider";
import { useContext } from "react";

export function useTransferServiceWizardContext(): TransferServiceWizardContextType {
  const context = useContext<TransferServiceWizardContextType | null>(
    TransferServiceWizardContext
  );

  if (context === null) {
    throw new Error("Using context outside provider"); // throw error if using this hook outside the provider
  }
  return context;
}
