import { billingApi } from "@common/api/billingApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { PaymentMethodRetrieveType } from "@common/types/paymentMethodTypes";
import { useCallback } from "react";

export const usePaymentMethodInState = (paymentMethodId?: string | null) => {
  const [
    { requestMonitor, data: paymentMethod },
    { setPending, setFailure, setSuccess },
  ] = useAjaxState<PaymentMethodRetrieveType>();

  const fetchPaymentMethod =
    useCallback((): Promise<PaymentMethodRetrieveType | void> => {
      if (!paymentMethodId) {
        return Promise.resolve();
      }
      setPending();

      return billingApi.paymentMethods
        .retrieve(paymentMethodId)
        .then((data) => {
          setSuccess(data);
          return data;
        })
        .catch(setFailure);
    }, [paymentMethodId]);

  const deletePaymentMethod = useCallback(async () => {
    if (!paymentMethodId) {
      return;
    }

    setPending();

    const payload = await billingApi.paymentMethods.delete(paymentMethodId);

    return payload;
  }, [paymentMethodId]);

  return {
    deletePaymentMethod,
    fetchPaymentMethod,
    paymentMethod,
    requestMonitor,
  };
};
