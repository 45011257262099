import { lightBlue as materialLightBlue } from "@mui/material/colors";

export const trueBlack = "#000";
export const trueWhite = "#FFF";

export const grey = {
  100: "#EDEBF0",
  200: "#CCC9D1",
  300: "#ABA7B2",
  400: "#918D99",
  50: "#F5F5F7",
  500: "#777380",
  600: "#5E5A66",
  700: "#45414C",
  800: "#2E2B33",
  900: "#171619",
};

export const purple = {
  100: "#F5EBFF",
  200: "#F2F2FF",
  300: "#C085FF",
  50: "#FAF5FF",
  500: "#9633FF",
  700: "#5A1F99",
  dark: "#5A1F99",
  light: "#C085FF",
  main: "#9633FF",
  medium: "#B4B0FF",
};

export const darkPurple = {
  100: "#ECEBFA",
  200: "#B1ABED",
  300: "#8F73E3",
  50: "#F6F5FC",
  500: "#4500A5",
  700: "#290063",
  dark: "#290063",
  light: "#8f73e3",
  main: "#4500A5",
  medium: "#8876DB",
};

export const blue = {
  100: "#E0F1FA",
  300: "#99DAFF",
  50: "#EFF8FC",
  500: "#50B0E5",
  700: "#1382C2",
  dark: "#005DE8",
  main: "#50B0E5",
};

export const red = {
  100: "#FFD6D8",
  50: "#feedee",
  500: "#B30309",
  700: "#C24842",
  dark: "#C24842",
  main: "#B30309",
};

export const gold = {
  main: "#FFC233",
};

export const green = {
  100: "#DEF3E8",
  300: "#A4EBC4",
  50: "#EEF9F3",
  500: "#59C28A",
  600: "#027B54",
  700: "#3B9464",
  main: "#59C28A",
};

export const infoBlue = {
  200: "#B5D4FF",
  50: "#e1f5fe",
  500: "#03a9f4",
  700: "#0288d1",
};

export const turquoise = {
  main: "#00A1A5",
};

export const lightBlue = materialLightBlue;

export const orange = {
  200: "#F56E3D",
  300: "#F96935",
  400: "#F55D3E",
};

export const yellow = {
  dark: "#FFC233",
};
