import {
  BoTextInput,
  BoTextInputProps,
} from "@ops-design-system/components/BoTextInput/BoTextInput";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

const SEARCH_INPUT_HEIGHT = "24px";
const BORDER_RADIUS = "4px";
const FONT_SIZE = "12px";

const StyledSearchInput = styled.div`
  position: relative;
  & input {
    background-color: ${grey["50"]};
    border-bottom-color: ${grey["100"]};
    border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
    font-size: ${FONT_SIZE};
    height: ${SEARCH_INPUT_HEIGHT};
    padding-left: ${rhOpsSpacingPx(1)};
  }
  & label {
    margin-bottom: 0;
  }
`;

export const BoSearchInput = ({
  name,
  placeholder,
  value,
  ...rest
}: BoTextInputProps) => {
  return (
    <StyledSearchInput>
      <BoTextInput
        aria-label="Search"
        name={name}
        placeholder={placeholder}
        value={value}
        {...rest}
      />
    </StyledSearchInput>
  );
};
