import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CustomerDocumentUploadData extends EventLogBaseData {
  documentType: string;
  fileName: string;
}

export class CustomerDocumentUpload extends EventLog {
  public readonly documentType: string;
  public readonly fileName: string;

  constructor({
    documentType,
    fileName,
    ...baseEventData
  }: CustomerDocumentUploadData) {
    super({
      ...baseEventData,
      eventText: "Document Upload",
    });

    this.documentType = documentType;
    this.fileName = fileName;
  }

  override get body() {
    return `${this.name}
    Document Type: ${this.documentType}
    Document Name: ${this.fileName}`;
  }
}
