import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { ContractChangesCard } from "@ops/components/ContractChangesCard/ContractChangesCard";
import { FutureRenewals } from "@ops/components/CustomerDashboard/RetentionTab/FutureRenewals/FutureRenewals";
import { PlanHistory } from "@ops/components/PlanHistory/PlanHistory";
import { PlanInfoCard } from "@ops/components/PlanInfo/PlanInfo";
import { WinbackWizard } from "@ops/components/WinbackWizard/WinbackWizard";
import React from "react";

export const RetentionTab = () => {
  return (
    <>
      <BoGrid $variant={GridVariants.Grid1_1}>
        <BoGridColumn>
          <PlanInfoCard />
        </BoGridColumn>
        <BoGridColumn>
          <ContractChangesCard />
          <WinbackWizard />
        </BoGridColumn>
      </BoGrid>
      <FutureRenewals />
      <BoFlexBox $flexDirection="column">
        <PlanHistory />
      </BoFlexBox>
    </>
  );
};
