import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import { PremiseType } from "@common/types/premiseTypes";
import { TransferServiceDataType } from "@ops-utils/types/transferServiceTypes";

const BASE_PATH = "/api/premises";

export const legacyPremiseApi = {
  setAutopay: ({
    premiseId,
    value,
    paymentMethodId,
  }: {
    paymentMethodId?: string;
    premiseId: IdType;
    value: boolean;
  }) =>
    ajax.patch<PremiseType>(`${BASE_PATH}/${premiseId}/autopay/`, {
      paymentMethodId,
      value,
    }),
  transferService: (
    premiseId: IdType,
    transferServiceData: TransferServiceDataType
  ): Promise<PremiseType> =>
    ajax.post<PremiseType>(
      `${BASE_PATH}/${premiseId}/transfer-service/`,
      transferServiceData
    ),
};
