import { BoButtonTabContainer } from "@ops-design-system/components/BoButtonTab/BoButtonTab";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledBoButtonTabContainer = styled(BoButtonTabContainer)`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const DateAndTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rhOpsSpacingPx(2)};

  margin-top: ${rhOpsSpacingPx(2)};
  width: 100%;

  & > *:first-child {
    flex: 20%;
  }

  & > *:last-child {
    flex: 80%;
  }
`;

export const TemperaturesContainer = styled.div`
  align-items: flex-end;
  display: flex;
  gap: ${rhOpsSpacingPx(1)};
`;

export const StyledNoEnergyDataYet = styled(Body2)`
  display: flex;
  justify-content: space-around;
  margin: 8rem;
`;
