import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { DeferPaymentComponentType } from "@ops/components/DeferPaymentWizard/DeferPaymentModalStateProvider";
import { useDeferPaymentModalState } from "@ops/components/DeferPaymentWizard/useDeferPaymentModalStateProvider";
import React, { FC } from "react";
import styled from "styled-components";

interface ConfirmNoDownPaymentProps {
  handleSubmit: () => void;
  requestPending: boolean;
}

const Subheader = styled(Subtitle2).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

export const ConfirmNoDownPayment: FC<ConfirmNoDownPaymentProps> = ({
  requestPending,
  handleSubmit,
}) => {
  const { updateModalState } = useDeferPaymentModalState();

  return (
    <>
      <BoDialogHeader>Deferred Payment Plan</BoDialogHeader>
      <BoDialogBody>
        <Subheader>
          Are you sure you want to continue DPP set up with $0 down payment?
        </Subheader>
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmDisabled={requestPending}
        onConfirm={handleSubmit}
        onCancel={() => {
          updateModalState({
            activeComponent: DeferPaymentComponentType.deferPaymentForm,
          });
        }}
      />
    </>
  );
};
