import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";

type ZuoraSetEventHandler = (actionName: string, handler: () => void) => void;

type ZuoraRenderWithErrorHandler = (
  params: Record<string, unknown>,
  initFields: Record<string, unknown>,
  callback: (response: ZuoraAddPaymentResponseType) => Promise<unknown> | void,
  clientErrorMessageCallback: (
    errorType: string,
    errorCode: string,
    errorDescription: string
  ) => void
) => void;

export interface Zuora {
  renderWithErrorHandler: ZuoraRenderWithErrorHandler;
  setEventHandler: ZuoraSetEventHandler;
}

type WindowWithZuora = typeof window & {
  Z: Zuora;
};

export const zuora = {
  renderWithErrorHandler(
    params: Record<string, unknown>,
    initFields: Record<string, unknown>,
    callback: (
      response: ZuoraAddPaymentResponseType
    ) => Promise<unknown> | void,
    clientErrorMessageCallback: (
      errorType: string,
      errorCode: string,
      errorDescription: string
    ) => void
  ) {
    ((window as WindowWithZuora).Z as Zuora).renderWithErrorHandler(
      params,
      initFields,
      callback,
      clientErrorMessageCallback
    );
  },
  setEventHandler(actionName: string, handler: () => void) {
    ((window as WindowWithZuora).Z as Zuora).setEventHandler(
      actionName,
      handler
    );
  },
};
