import { BoRadioInput } from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import React from "react";
import { useField } from "react-final-form";

interface BoRadioFieldProps {
  label: string;
  name: string;
  value: string;
}

export const BoRadioField = ({ name, label, value }: BoRadioFieldProps) => {
  const { input } = useField<string>(name, {
    type: "radio",
    value,
  });

  return <BoRadioInput {...input} label={label} />;
};
