import { useCallback, useEffect, useRef } from "react";
import { debounce } from "throttle-debounce";

type CallbackType<T extends unknown[]> = (...args: T) => void;

export const useDebounce = <T extends unknown[]>(
  callback: CallbackType<T>,
  delay: number
) => {
  const callbackRef = useRef(callback);

  const debounced = debounce(delay, (...args: T) => {
    return callbackRef.current(...args);
  });

  useEffect(() => {
    callbackRef.current = callback;

    return () => {
      debounced.cancel();
    };
  }, []);

  return useCallback(debounced, [debounced]);
};
