import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const NoFutureRenewalText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${rhOpsSpacingPx(3)};
`;

export const FutureRenewalCard = styled(BoCard)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${rhOpsSpacingPx(2)};
`;

export const FutureRenewalLoadingCard = styled(BoCard)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
