import { createRhContext } from "@common/utils/contextHelpers";
import { ContactLog } from "@ops/components/SupportLogs/ContactLog/ContactLog";
import { EventLog } from "@ops/components/SupportLogs/EventLog/EventLog";
import {
  LogType,
  SupportLogsContextType,
} from "@ops/components/SupportLogs/SupportLogs.types";
import { ValidationReport } from "@ops/components/SupportLogs/ValidationReport/ValidationReport";
import React, { useState } from "react";

export const SupportLogsContext = createRhContext<SupportLogsContextType>();

export const SupportLogs = ({
  target,
}: Pick<SupportLogsContextType, "target">) => {
  const [activeLog, setActiveLog] = useState<LogType>(LogType.CONTACT);

  const logComponents: Record<LogType, JSX.Element> = {
    contact: <ContactLog />,
    event: <EventLog />,
    validation: <ValidationReport />,
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SupportLogsContext.Provider value={{ activeLog, setActiveLog, target }}>
      {logComponents[activeLog]}
    </SupportLogsContext.Provider>
  );
};
