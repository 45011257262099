import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledRewardBalanceCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: ${rhOpsSpacingPx(3.5)};
  }

  & > :last-child {
    margin-right: 0;
  }
`;

export const StyledLifetimePointsContainer = styled.div`
  flex: 1;
`;

export const StyledActionsContainer = styled.div`
  align-items: center;
  display: flex;
  & > * {
    margin-right: ${rhOpsSpacingPx(1)};
  }
`;

export const StyledButtonWrapper = styled.div`
  flex-basis: 104px;
`;
