import { AxiosErrorCodes } from "@common/constants/errorCodes.constants";
import {
  RhApiError,
  RhApiErrorResponseData,
  RhError,
  RhNetworkError,
} from "@common/types/errorTypes";
import { captureException, withScope } from "@sentry/react";
import { AxiosError } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

export const decamelizeRequestKeys = (data: Record<string, unknown>) =>
  JSON.stringify(decamelizeKeys(data));

export const camelizeResponseKeys = (data: string) => {
  try {
    return camelizeKeys(JSON.parse(data));
  } catch {
    return data;
  }
};

export const shouldReportError = (error: AxiosError<unknown>) => {
  // Ignore "forbidden" errors that usually mean a user is not logged in
  return String(error.response?.status).startsWith("5");
};

export const sentryErrorInterceptor = (
  error: AxiosError<unknown>
): Promise<AxiosError> => {
  if (shouldReportError(error)) {
    if (!error.response || !error.config) {
      return Promise.reject<AxiosError>(error);
    }

    withScope((scope) => {
      if (!error.response || !error.config) {
        return;
      }
      const errorToCapture = error;
      const statusCode = error.response?.status;
      const errorsData = (error.response.data as { errors: unknown }) || {};
      const errors = errorsData.errors || {};
      const { code } = error;
      const { baseURL, data, method, params, url } = error.config;
      const httpMethod = method?.toUpperCase();
      const errorData = {
        axiosCode: code,
        baseURL,
        data: JSON.stringify(data),
        errors: JSON.stringify(errors),
        method: httpMethod,
        params,
        statusCode,
        url,
      };

      errorToCapture.name = "API Error";
      errorToCapture.message = `${httpMethod} ${url} returned ${statusCode}`;

      scope.setContext("API Request", errorData);
      captureException(errorToCapture);
    });
  }
  return Promise.reject<AxiosError>(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformErrorData = (data: any = {}) => {
  const errorData = data ?? {};

  let errorCode: string | null = errorData.errorId ?? null;

  if (Array.isArray(errorData?.errors)) {
    const [firstError] = errorData.errors;

    errorCode = firstError?.code ?? errorCode;
  }

  const errors: { code: string; detail: string; field: string }[] =
    errorData.errors ?? [];

  const transformedError: RhApiErrorResponseData = {
    errorCode: errorCode ?? null,
    errors: errors.map(({ field, code, detail }) => ({ code, detail, field })),
  };

  if (errorData.stateToken) {
    transformedError.stateToken = errorData.stateToken;
  }

  return transformedError;
};

function getStatusAndCodeFromErrorCode(errorCode?: string): {
  code: AxiosErrorCodes;
  status: number;
} {
  if (errorCode === "ECONNABORTED") {
    return {
      code: AxiosErrorCodes.AXIOS_TIMEOUT_ERROR_CODE,
      status: 408,
    };
  } else {
    return {
      code: AxiosErrorCodes.AXIOS_UNKNOWN_ERROR_CODE,
      status: 500,
    };
  }
}

function convertAxiosExceptionToRhythmError(
  error: AxiosError<RhApiErrorResponseData | undefined>
) {
  const { status, code } = getStatusAndCodeFromErrorCode(error.code);

  const transformedError: RhNetworkError = {
    config: error.config,
    data: {
      errorCode: code,
      errors: [
        {
          code,
          detail: error.message,
        },
      ],
    },
    kind: "Network",
    status,
    statusText: error.message,
  };

  return Promise.reject<RhNetworkError>(transformedError);
}

function convertApiExceptionToRhythmError(
  error: AxiosError<RhApiErrorResponseData>
): Promise<RhApiError> {
  const { response } = error;

  // eslint-disable-next-line prefer-destructuring
  const config = error.config || {
    baseURL: undefined,
    data: undefined,
    method: undefined,
    params: undefined,
    url: undefined,
  };
  const { baseURL, data, method, url, params } = config;

  const {
    data: errorData,
    status,
    statusText,
  } = response || {
    config: {},
  };

  if (errorData instanceof Blob && errorData.type === "application/json") {
    return errorData.text().then((value) => {
      let transformedData: RhApiErrorResponseData;

      try {
        transformedData = transformErrorData(camelizeKeys(JSON.parse(value)));
      } catch (SyntaxError) {
        transformedData = { errorCode: null, errors: [] };
      }

      const transformedError: RhApiError = {
        config: {
          baseURL,
          data,
          method,
          params,
          url,
        },
        data: transformedData,
        kind: "API",
        status,
        statusText,
      };

      return Promise.reject<RhApiError>(transformedError);
    });
  }

  const transformedError: RhApiError = {
    config: {
      baseURL,
      data,
      method,
      params,
      url,
    },
    data: transformErrorData(errorData),
    kind: "API",
    status,
    statusText,
  };

  return Promise.reject<RhApiError>(transformedError);
}

export function toRhythmError(
  error: AxiosError<RhApiErrorResponseData | undefined>
): Promise<RhError> {
  function isBackendException(
    err: AxiosError<RhApiErrorResponseData | undefined>
  ) {
    return "response" in err && err.response && true;
  }

  if (isBackendException(error)) {
    return convertApiExceptionToRhythmError(
      error as AxiosError<RhApiErrorResponseData>
    );
  } else {
    return convertAxiosExceptionToRhythmError(error);
  }
}
