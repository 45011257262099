import { BoInfoValue } from "@ops-design-system/components/BoInfo/BoInfo";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { red, trueBlack } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const ErrorMessageContainer = styled.div`
  margin-bottom: ${rhOpsSpacingPx(1)};
  margin-top: ${rhOpsSpacingPx(1)};
`;

export const WarningMessageText = styled(Body2)`
  color: ${red.main};
`;

export const BillingConfirmationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
`;

export const BillingConfirmationGridColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;

interface PaperlessAutoPayValueTextProps {
  $isWarning?: boolean;
}

export const PaperlessAutoPayValueText = styled(
  BoInfoValue
)<PaperlessAutoPayValueTextProps>`
  color: ${({ $isWarning }) => ($isWarning ? red.main : trueBlack)};
`;
