import { RhApiError } from "@common/types/errorTypes";

type ThenArg<T> = T extends Promise<infer U> ? U : T;

export const handleAjaxCall = <T = never, E = RhApiError>(
  promise: Promise<T>
) => {
  type SuccessArg = ThenArg<typeof promise>;
  return promise
    .then((data: SuccessArg) => [null, data])
    .catch((error: E) => Promise.resolve([error, null])) as Promise<
    [E | null, null | SuccessArg]
  >;
};
