import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { green, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const SuccessCard = styled(BoCard).attrs({
  $variant: BoCardVariants.Success,
})``;

export const SuccessMessage = styled(Body1)`
  color: ${green[500]};
`;

export const WarningCard = styled(BoCard).attrs({
  $variant: BoCardVariants.Warning,
})`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;
export const WarningMessage = styled(Body1).attrs({ as: "div" })`
  color: ${red[500]};
`;
