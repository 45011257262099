import { EM_DASH } from "@common/constants/characters.constant";
import { DeferredPaymentPlanType } from "@common/types/deferredPaymentPlanTypes";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

interface DeferredPaymentInfoProps {
  activeDeferredPaymentPlan: DeferredPaymentPlanType | null | undefined;
}

const InfoContainerColumn = styled.div`
  margin-right: ${rhOpsSpacingPx(3)};

  & :first-child {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
`;

const PaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

enum InfoSections {
  contractLetter = "DPP Contract Letter",
  date = "Date",
  deferredPayment = "Deferred Payment",
  installmentsSummary = "Installments",
  remainingBalance = "Remaining Balance",
}

export const DeferredPaymentInfo = ({
  activeDeferredPaymentPlan,
}: DeferredPaymentInfoProps) => {
  let values: Record<InfoSections, string> = {
    [InfoSections.deferredPayment]: EM_DASH,
    [InfoSections.remainingBalance]: EM_DASH,
    [InfoSections.date]: EM_DASH,
    [InfoSections.installmentsSummary]: EM_DASH,
    [InfoSections.contractLetter]: EM_DASH,
  };

  if (activeDeferredPaymentPlan) {
    const completedInstallments = activeDeferredPaymentPlan.installments.filter(
      (i) => i.invoiceId !== null
    ).length;
    const totalInstallments = activeDeferredPaymentPlan.installments.length;

    values = {
      [InfoSections.deferredPayment]: formatCurrency(
        Number(activeDeferredPaymentPlan.creditAmount)
      ),
      [InfoSections.remainingBalance]: formatCurrency(
        Number(activeDeferredPaymentPlan.debitBalance)
      ),
      [InfoSections.date]: formatMonthDayYear(
        activeDeferredPaymentPlan.agreementDate
      ),
      [InfoSections.installmentsSummary]: `${completedInstallments} out of ${totalInstallments}`,
      [InfoSections.contractLetter]: EM_DASH,
    };
  }

  return (
    <PaymentInfoContainer>
      <InfoContainerColumn>
        <BoInfoContainer>
          <BoInfoLabel>{InfoSections.deferredPayment}</BoInfoLabel>
          <BoInfoValue>{values[InfoSections.deferredPayment]}</BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>{InfoSections.remainingBalance}</BoInfoLabel>
          <BoInfoValue>{values[InfoSections.remainingBalance]}</BoInfoValue>
        </BoInfoContainer>
      </InfoContainerColumn>

      <InfoContainerColumn>
        <BoInfoContainer>
          <BoInfoLabel>{InfoSections.date}</BoInfoLabel>
          <BoInfoValue>{values[InfoSections.date]}</BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>{InfoSections.installmentsSummary}</BoInfoLabel>
          <BoInfoValue>{values[InfoSections.installmentsSummary]}</BoInfoValue>
        </BoInfoContainer>
      </InfoContainerColumn>
    </PaymentInfoContainer>
  );
};
