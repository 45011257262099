import { Invoice } from "@common/models/Invoice.model";
import { Premise } from "@common/models/Premise.model";
import { InvoiceTypeEnum } from "@common/types/accountSummaryTypes";
import { RhApiError } from "@common/types/errorTypes";
import {
  formatCurrency,
  formatKwh,
  formatMonthDayShortYear,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { disconnectNoticeFileDownload } from "@common/utils/disconnectNoticeFileDownload";
import { noop } from "@common/utils/genericFunctions";
import { invoiceFileDownload } from "@common/utils/invoiceFileDownload";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import { ReactComponent as DownloadIcon } from "@ops-design-system/icons/DownloadIcon.svg";
import { BillBreakdownModal } from "@ops/components/BillBreakdownModal/BillBreakdownModal";
import {
  DownloadDnpLink,
  EmptyRowMessage,
  EmptyStateMessage,
  TableHeader,
} from "@ops/components/InvoiceTable/InvoiceTable.styled";
import { createColumnHelper } from "@tanstack/react-table";
import React, { MouseEvent, useState } from "react";

interface InvoiceTableProps {
  header: string;
  invoices: Invoice[];
  premise: Premise;
}

const downloadClickWrapper =
  (cb: () => void | Promise<void>) =>
  (e: MouseEvent<HTMLAnchorElement>): void | Promise<void> => {
    e.preventDefault();
    return cb();
  };

export const InvoiceTable = ({
  header,
  invoices,
  premise,
}: InvoiceTableProps) => {
  const [breakdownInvoiceId, setBreakdownInvoiceId] = useState<string | null>(
    null
  );

  const flash = useRhFlash();

  const columnHelper = createColumnHelper<Invoice>();

  const columns = [
    columnHelper.display({
      cell: ({ row }) => (
        <BoLink
          onClick={downloadClickWrapper(() =>
            invoiceFileDownload({
              billingAccountNumber: premise.billingAccountNumber,
              invoiceDate: row.original.invoiceDate,
              invoiceId: row.original.id,
              premiseId: premise.id,
            })
              .then(noop)
              .catch((_error: RhApiError) => {
                flash.error(
                  "We are having issues retrieving that invoice. Please try again."
                );
              })
          )}
          href="#"
        >
          {row.original.invoiceNumber}
        </BoLink>
      ),
      header: "Invoice",
      id: "invoiceNumber",
    }),
    columnHelper.display({
      cell: ({ row }) =>
        row.original.invoiceType === InvoiceTypeEnum.ENERGY ? (
          <BoLink onClick={() => setBreakdownInvoiceId(row.original.id)}>
            View
          </BoLink>
        ) : null,
      header: "Bill Breakdown",
      id: "billBreakdown",
    }),
    columnHelper.display({
      cell: ({ row }) => formatMonthDayYear(row.original.invoiceDate),
      header: "Invoice Date",
      id: "invoiceDate",
    }),
    columnHelper.display({
      cell: ({ row }) => formatMonthDayYear(row.original.dueDate),
      header: "Due Date",
      id: "dueDate",
    }),
    columnHelper.display({
      cell: ({ row }) => row.original.formattedInvoiceServicePeriod,
      header: "Service Period",
      id: "servicePeriod",
    }),
    columnHelper.display({
      cell: ({ row }) =>
        row.original.totalKwh ? formatKwh(row.original.totalKwh, 0) : "-",
      header: "Usage",
      id: "usage",
    }),
    columnHelper.display({
      cell: ({ row }) => formatCurrency(row.original.amount),
      header: "Amount Due",
      id: "amountDue",
    }),
    columnHelper.display({
      cell: ({ row }) => formatCurrency(row.original.balance),
      header: "Amount Outstanding",
      id: "amountOutStanding",
    }),
    columnHelper.display({
      cell: ({ row }) =>
        premise
          .disconnectNoticeForInvoices(row.original.id)
          .map((dnpNotice) => (
            <EmptyRowMessage key={dnpNotice.id}>
              {formatMonthDayShortYear(dnpNotice.disconnectionDate)}
            </EmptyRowMessage>
          )),
      header: "DNP Expiration",
      id: "dnpExpiration",
    }),
    columnHelper.display({
      cell: ({ row }) =>
        premise
          .disconnectNoticeForInvoices(row.original.id)
          .map((dnpNotice) => (
            <EmptyRowMessage key={dnpNotice.id}>
              <DownloadDnpLink
                onClick={downloadClickWrapper(() =>
                  disconnectNoticeFileDownload(
                    premise,
                    row.original,
                    dnpNotice.id
                  )
                    .then(noop)
                    .catch((_error) => {
                      flash.error(
                        "We are having issues retrieving that disconnection notice.  Please try again later."
                      );
                    })
                )}
                title="Download Disconnection Notice"
              >
                <DownloadIcon />
              </DownloadDnpLink>
            </EmptyRowMessage>
          )),
      header: "DNP Notice PDF",
      id: "dnpNoticePdf",
    }),
  ];

  return (
    <div>
      <TableHeader>{header}</TableHeader>
      {invoices.length ? (
        <BoReactTable<Invoice>
          columns={columns}
          tableCaption="Invoice History"
          pagination={false}
          data={invoices}
        />
      ) : (
        <EmptyStateMessage>There are no invoices to show.</EmptyStateMessage>
      )}
      {breakdownInvoiceId ? (
        <BillBreakdownModal
          invoiceId={breakdownInvoiceId}
          closeModal={() => setBreakdownInvoiceId(null)}
        />
      ) : null}
    </div>
  );
};
