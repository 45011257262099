import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";

// This structure does not fit with MuiTheme borders which is
// why it's not wrapped into the theme
export const borders = {
  100: `1px solid ${CSSVariables.COLOR_GREY_100}`,
  200: `1px solid ${CSSVariables.COLOR_GREY_200}`,
  400: `1px solid ${CSSVariables.COLOR_GREY_400}`,
  50: `1px solid ${CSSVariables.COLOR_GREY_50}`,
};
