import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface AffiliateMonthlyReportData extends EventLogBaseData {
  affiliateEmail: string;
  affiliateId: string;
}

export class AffiliateMonthlyReport extends EventLog {
  public readonly affiliateId: string;
  public readonly affiliateEmail: string;

  constructor({
    affiliateId,
    affiliateEmail,
    ...baseEventData
  }: AffiliateMonthlyReportData) {
    super(baseEventData);

    this.affiliateId = affiliateId;
    this.affiliateEmail = affiliateEmail;
  }

  override get body() {
    return `${this.name} - Affiliate's ID: ${this.affiliateId} - Affiliate's Email: ${this.affiliateEmail}`;
  }
}
