import { OktaGroups } from "@common/constants/okta.constant";
import { IdType } from "@common/types/apiTypes";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { Body3 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { PremiseIndicatorsWizard } from "@ops/components/PremiseIndicatorsWizard/PremiseIndicatorsWizard";
import { StatusIndicator } from "@ops/components/StatusIndicator/StatusIndicator";
import { usePremiseStatusIndicators } from "@ops/hooks/usePremiseStatusIndicators";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledPremiseStatusIndicatorBar = styled(BoCard)`
  align-items: center;
  display: flex;
  min-height: 36px;
  padding-bottom: ${rhOpsSpacingPx(1)};
  padding-top: ${rhOpsSpacingPx(1)};
`;

const StyledStatus = styled(Body3)``;

interface PlaceHolderStatusIndicatorBarProps {
  name: string;
}

const StyledPremiseStatusIndicatorBarPlaceHolder = ({
  name,
}: PlaceHolderStatusIndicatorBarProps) => {
  return (
    <StyledPremiseStatusIndicatorBar
      role="article"
      aria-label="premise status bar placeholder"
    >
      <BoFlexBox $flexWrap="wrap">
        <StyledStatus>{name}</StyledStatus>
      </BoFlexBox>
    </StyledPremiseStatusIndicatorBar>
  );
};

export const PremiseStatusIndicatorBar = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();

  const { premiseStatusIndicators, requestMonitor, error } =
    usePremiseStatusIndicators(premiseId);

  if (error) {
    return (
      <StyledPremiseStatusIndicatorBarPlaceHolder name="Status Indicators Error" />
    );
  }

  if (requestMonitor.isPending) {
    return (
      <StyledPremiseStatusIndicatorBarPlaceHolder name="Fetching Status Indicator Data..." />
    );
  }

  if (premiseStatusIndicators) {
    return (
      <StyledPremiseStatusIndicatorBar
        role="article"
        aria-label="premise status bar"
      >
        <BoFlexBox $flexWrap="wrap">
          {premiseStatusIndicators.map((indicator) => {
            return (
              <StatusIndicator
                key={`${indicator.level}_${indicator.name}`}
                status={indicator.level}
              >
                {indicator.name}
              </StatusIndicator>
            );
          })}
        </BoFlexBox>
        {premiseId && (
          <AccessControl allowedGroups={[OktaGroups.CSRTier2]}>
            <PremiseIndicatorsWizard premiseId={premiseId} />
          </AccessControl>
        )}
      </StyledPremiseStatusIndicatorBar>
    );
  }

  if (!premiseId) {
    return (
      <StyledPremiseStatusIndicatorBarPlaceHolder name="Fetching Premise Data..." />
    );
  }

  return (
    <StyledPremiseStatusIndicatorBarPlaceHolder name="Problem Fetching Status Indicator Data" />
  );
};
