import { EM_DASH } from "@common/constants/characters.constant";
import { UsageType } from "@common/types/usageTypes";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import { green, infoBlue } from "@design-system/theme/palette.colors";
import { Body2, H3 } from "@ops-design-system/components/Typography/Typography";
import React from "react";
import styled from "styled-components";

interface TotalUsageProps {
  showConsumption: boolean;
  showEarned: boolean;
  showGeneration: boolean;
  usageData: UsageType[];
}

interface TotalUsageType {
  totalConsumptionCost: number;
  totalConsumptionKwh: number;
  totalGenerationEarned: number;
  totalGenerationKwh: number;
}
const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  & > * {
    width: 125px;
  }
`;

const ConsumptionSection = styled.div`
  border-top: 2px solid ${infoBlue[200]};
`;

const SurplusSection = styled.div`
  border-top: 2px solid ${green[300]};
`;

export const TotalUsage = (props: TotalUsageProps) => {
  const { usageData, showEarned, showGeneration, showConsumption } = props;

  const totalUsage: TotalUsageType = usageData.reduce(
    (accumulator, currentValue) => {
      return {
        totalConsumptionCost:
          accumulator.totalConsumptionCost +
          Number(currentValue.consumptionCost),
        totalConsumptionKwh:
          accumulator.totalConsumptionKwh + Number(currentValue.consumptionKwh),
        totalGenerationEarned:
          accumulator.totalGenerationEarned +
          Number(currentValue.generationEarned),
        totalGenerationKwh:
          accumulator.totalGenerationKwh + Number(currentValue.generationKwh),
      };
    },
    {
      totalConsumptionCost: 0,
      totalConsumptionKwh: 0,
      totalGenerationEarned: 0,
      totalGenerationKwh: 0,
    }
  );

  return (
    <Container>
      {showConsumption ? (
        <>
          <ConsumptionSection>
            <Body2>Total Usage</Body2>
            <H3>{formatKwh(totalUsage.totalConsumptionKwh, 0)}</H3>
          </ConsumptionSection>

          <ConsumptionSection>
            <Body2>Total Cost</Body2>
            <H3>{formatCurrency(totalUsage.totalConsumptionCost, 2)}</H3>
          </ConsumptionSection>
        </>
      ) : null}
      {showGeneration && totalUsage.totalGenerationKwh > 0 ? (
        <>
          <SurplusSection>
            <Body2>Surplus Generation</Body2>
            <H3>{formatKwh(totalUsage.totalGenerationKwh, 0)}</H3>
          </SurplusSection>

          <SurplusSection>
            <Body2>Surplus Credits</Body2>
            <H3>
              {showEarned
                ? formatCurrency(totalUsage.totalGenerationEarned, 2)
                : EM_DASH}
            </H3>
          </SurplusSection>
        </>
      ) : null}
    </Container>
  );
};
