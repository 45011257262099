import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { H1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { BillingSection } from "@ops/components/AdminDashboard/BillingSection/BillingSection";
import { ManageContactLogsSection } from "@ops/components/AdminDashboard/ManageContactLogsSection/ManageContactLogsSection";
import { PaymentsSection } from "@ops/components/AdminDashboard/PaymentsSection/PaymentsSection";
import { ProspectsSection } from "@ops/components/AdminDashboard/ProspectsSection/ProspectsSection";
import React from "react";
import styled from "styled-components";

const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(4)};
  margin-top: ${rhOpsSpacingPx(2)};
`;

export const AdminDashboard = () => {
  return (
    <BoFlexBox $flexDirection="column">
      <H1>Admin</H1>
      <StyledDivider />
      <BillingSection />
      <ProspectsSection />
      <ManageContactLogsSection />
      <PaymentsSection />
    </BoFlexBox>
  );
};
