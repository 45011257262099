import { ajax } from "@common/services/ajax";
import { ZuoraIdType } from "@common/types/apiTypes";
import {
  CreditCardRetrieveType,
  UpdatePaymentMethodType,
} from "@common/types/paymentMethodTypes";

const BASE_URL = "/api/billing";

export const legacyBillingApi = {
  paymentMethods: {
    default: ({
      id,
      paymentMethodData,
    }: {
      id: ZuoraIdType;
      paymentMethodData: UpdatePaymentMethodType;
    }): Promise<CreditCardRetrieveType> => {
      return ajax.put(
        `${BASE_URL}/payment_methods/${id}/default/`,
        paymentMethodData
      );
    },
    update: ({
      id,
      paymentMethodData,
    }: {
      id: ZuoraIdType;
      paymentMethodData: UpdatePaymentMethodType;
    }): Promise<CreditCardRetrieveType> => {
      return ajax.put(`${BASE_URL}/payment_methods/${id}/`, paymentMethodData);
    },
  },
};
