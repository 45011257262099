import {
  PAGE_GUTTER,
  RH_HEADER_HEIGHT,
} from "@design-system/theme/style.constant";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { OpsDevTools } from "@ops/components/OpsDevTools/OpsDevTools";
import { OpsHeader } from "@ops/components/OpsHeader/OpsHeader";
import { RhapsodyRoutes } from "@ops/routes/Ops.route";
import React from "react";
import styled from "styled-components";

// prettier is trying to put margin in two lines, breaking the styled.main
// prettier-ignore
const Container = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin: ${rhOpsSpacingPx(RH_HEADER_HEIGHT)} ${rhOpsSpacingPx(3)} ${rhOpsSpacingPx(3)};
  max-width: calc(100% - ${PAGE_GUTTER * 2}px);
  padding-top: ${rhOpsSpacingPx(4)};
`;

export const App = () => {
  return (
    <>
      <Container>
        <OpsHeader />
        <RhapsodyRoutes />
      </Container>
      <OpsDevTools />
    </>
  );
};
