import { formatCurrency } from "@common/utils/dataFormatters";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  Body1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { CreateDeferPaymentPayOffSuccessType } from "@ops/components/DeferPaymentManageWizard/DeferPaymentManageModal";
import React from "react";
import styled from "styled-components";

const ConfirmationNumber = styled(Body1).attrs({ as: "div" })``;

const Bold = styled(Subtitle2).attrs({ $fontWeight: "Bold" })``;

const Message = styled(Subtitle2).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

interface DeferPaymentPayOffSuccessProps {
  closeModal: () => void;
  newDeferPaymentPayOffData: CreateDeferPaymentPayOffSuccessType | null;
  onSuccess: () => void;
}

export const DeferPaymentPayOffSuccess = ({
  newDeferPaymentPayOffData,
  closeModal,
  onSuccess,
}: DeferPaymentPayOffSuccessProps) => {
  const { amountPaid, zuoraPaymentNumber } = newDeferPaymentPayOffData || {
    amountPaid: 0,
    zuoraPaymentNumber: "",
  };
  const displayMessage = newDeferPaymentPayOffData
    ? "Deferred Payment Plan Paid Off"
    : "Error was found. Failed To Pay Off Deferred Payment Plan";

  return (
    <>
      <BoDialogHeader>Deferred Payment Plan</BoDialogHeader>
      <BoDialogBody>
        <Message>{displayMessage}</Message>

        {newDeferPaymentPayOffData && amountPaid > 0.5 && (
          <>
            {" "}
            <Message>
              Total of <Bold>{formatCurrency(amountPaid)}</Bold> has been
              submitted.
            </Message>
          </>
        )}

        {newDeferPaymentPayOffData &&
          zuoraPaymentNumber &&
          zuoraPaymentNumber !== "" && (
            <ConfirmationNumber>
              Confirmation {zuoraPaymentNumber}
            </ConfirmationNumber>
          )}
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText="Back to Customer Page"
        onConfirm={onSuccess}
      />
    </>
  );
};
