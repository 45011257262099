import {
  DOB_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
} from "@common/constants/date.constant";
import { validDayjs } from "@common/utils/dataFormatters";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { MaskedDate } from "imask";

// plugin to allow strict date parsing
dayjs.extend(customParseFormat);

const dateOfBirthMask = new MaskedDate({
  mask: Date,
  max: new Date(),
  min: new Date(1900, 0, 1),
  pattern: "m/d/Y0",
});

export const formatDateOfBirth = (value: string) => {
  dateOfBirthMask.value = value ?? "";
  return dateOfBirthMask.value.replace(/\./g, "/");
};

export const unformatDateOfBirth = (value: string) => {
  dateOfBirthMask.value = value ?? "";
  return validDayjs(dateOfBirthMask.value, DOB_DATE_FORMAT, true).format(
    ISO8601_DATE_FORMAT
  );
};

export const formatMaskNumber = (value: string) => {
  return value.length > 4 ? `***${value.slice(-4)}` : "";
};
