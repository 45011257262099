import { billingUrls } from "@common/api/billingUrls";
import { ajax } from "@common/services/ajax";
import { RSASignatureResponseType } from "@common/types/apiResponseTypes";
import { ZuoraIdType } from "@common/types/apiTypes";
import {
  CreditCardRetrieveType,
  PaymentMethodRetrieveType,
  UpdatePaymentMethodType,
} from "@common/types/paymentMethodTypes";

export const billingApi = {
  fetchRSASignature: (pageId: string): Promise<RSASignatureResponseType> => {
    return ajax.get(billingUrls.rsaSignature(), { params: { pageId } });
  },
  paymentMethods: {
    default: (
      id: ZuoraIdType,
      paymentMethodData: UpdatePaymentMethodType
    ): Promise<CreditCardRetrieveType> => {
      return ajax.put(
        billingUrls.paymentMethods.default(id),
        paymentMethodData
      );
    },
    delete: (id: ZuoraIdType): Promise<CreditCardRetrieveType> => {
      return ajax.destroy(billingUrls.paymentMethods.delete(id));
    },
    retrieve: (id: ZuoraIdType): Promise<PaymentMethodRetrieveType> => {
      return ajax.get(billingUrls.paymentMethods.retrieve(id));
    },

    update: (
      id: ZuoraIdType,
      paymentMethodData: UpdatePaymentMethodType
    ): Promise<CreditCardRetrieveType> => {
      return ajax.put(
        billingUrls.paymentMethods.retrieve(id),
        paymentMethodData
      );
    },
  },
};
