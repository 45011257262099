import { useModalState } from "@common/hooks/useModalState";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { Premise } from "@common/models/Premise.model";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { BoSelectValue } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { BoToggleField } from "@ops-design-system/components/BoToggleField/BoToggleField";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { red } from "@ops-design-system/styles/palette.colors";
import { onOff } from "@ops-design-system/utils/booleanHelpers";
import {
  AutoPayDetailsContainer,
  AutoPayWarningText,
  SelectContainer,
} from "@ops/components/BillingPreferencesInfoCard/EditAutoPay/EditAutoPay.styled";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { PremiseErrorCodes } from "@ops/constants/errorCodes.constants";
import { usePremiseAutopayUpdateMutation } from "@ops/hooks/mutations/usePremiseAutopayUpdate.mutation";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { usePaymentMethodOptions } from "@ops/hooks/usePaymentMethodOptions";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { paymentMethodsFetch } from "@ops/slices/paymentMethodsSlice";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const PastDueWarningWrapper = styled(Subtitle2).attrs({ as: "div" })`
  color: ${red["500"]};
`;

interface EditAutoPayFormValues {
  autoPay: boolean;
  paymentMethodId: BoSelectValue;
}

interface AutoPayModalProps {
  closeModal: () => void;
  pastDueBalance?: number;
  paymentMethods: PaymentMethod[];
  premise: Premise;
}

const EditAutoPayFormModal = ({
  closeModal,
  premise,
  pastDueBalance,
  paymentMethods,
}: AutoPayModalProps) => {
  const flash = useRhFlash();
  const dispatch = useDispatch();

  const premiseUpdateAutopayMutation = usePremiseAutopayUpdateMutation();

  const { paymentMethodOptions, defaultPaymentMethod } =
    usePaymentMethodOptions(paymentMethods, { withDefaultFallback: true });

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: premise.currentOrder?.offersnapshotId ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offersnapshotId),
    },
  });

  const balanceDueMessage = (autopay: boolean) => {
    if (pastDueBalance && autopay) {
      return (
        <PastDueWarningWrapper>
          Customer will be charged the past due amount of ${pastDueBalance}
        </PastDueWarningWrapper>
      );
    }
  };

  const handleSubmit = (formValues: EditAutoPayFormValues) => {
    premiseUpdateAutopayMutation.mutate(
      {
        paymentMethodId: formValues.paymentMethodId
          ? String(formValues.paymentMethodId)
          : undefined,
        premiseId: premise.id,
        value: formValues.autoPay,
      },
      {
        onError: (error) => {
          if (error.status === 500) {
            closeModal();
            flash.error("Something went wrong. Please try again.");
          } else {
            let message;

            switch (error.data.errorCode) {
              case PremiseErrorCodes.PAYMENT_METHOD_NOT_FOUND:
              case PremiseErrorCodes.PAYMENT_METHOD_INVALID: {
                message = "Invalid payment method. Please try again.";
                break;
              }
              default: {
                message = "Something went wrong. Please try again.";
              }
            }
            flash.error(message);
          }
        },
        onSuccess: () => {
          dispatch(premiseRefetch(premise.id));
          dispatch(paymentMethodsFetch(premise.id));

          closeModal();
        },
      }
    );
  };

  let currentSettingText = `Auto Pay is turned ${onOff(
    premise.autopay
  ).toLowerCase()}`;

  if (premise.autopay) {
    currentSettingText += ` using ${defaultPaymentMethod?.companyNameAndNumber}`;
  }

  const showAutoPayWarningMessage = Boolean(
    offerSnapshotQuery.data &&
      offerSnapshotQuery.data.isAutoPayPaperlessDiscountOffer
  );

  const warningMessageText = premise.autopay
    ? "If Auto Pay is turned OFF, the customer is no longer eligible for the Auto Pay & Paperless Credit."
    : "Auto Pay must be turned ON to be eligible to receive the Auto Pay & Paperless Credit.";

  return (
    <BoModal open onClose={closeModal}>
      <Form<EditAutoPayFormValues>
        validateOnBlur
        onSubmit={handleSubmit}
        initialValues={{
          autoPay: premise.autopay,
          paymentMethodId: defaultPaymentMethod?.id,
        }}
        render={({ handleSubmit: handleFormSubmit, values, dirty }) => {
          return (
            <form noValidate onSubmit={handleFormSubmit}>
              <BoDialogHeader>Edit Auto Pay</BoDialogHeader>
              <BoDialogBody>
                {showAutoPayWarningMessage ? (
                  <AutoPayWarningText>{warningMessageText}</AutoPayWarningText>
                ) : null}
                <AutoPayDetailsContainer>
                  <Subtitle2 as="div">{currentSettingText}</Subtitle2>
                  {balanceDueMessage(values.autoPay)}
                  <BoToggleField name="autoPay" withIndicator />
                </AutoPayDetailsContainer>
                <SelectContainer>
                  <BoSelectField
                    label="Payment Method"
                    name="paymentMethodId"
                    options={paymentMethodOptions}
                    defaultValue={defaultPaymentMethod?.id}
                    disabled={!values.autoPay}
                  />
                </SelectContainer>
              </BoDialogBody>
              <BoDialogButtonFooter
                confirmDisabled={
                  !dirty || premiseUpdateAutopayMutation.isPending
                }
                confirmBtnText="Update"
                confirmBtnType="submit"
                cancelBtnText="Cancel"
                onCancel={closeModal}
              />
            </form>
          );
        }}
      />
    </BoModal>
  );
};

interface EditAutoPayProps {
  pastDueBalance?: number;
  premise: Premise;
}

export const EditAutoPay = ({ premise, pastDueBalance }: EditAutoPayProps) => {
  const { open, close, isOpen } = useModalState();
  const { paymentMethods } = usePaymentMethods(premise.id);

  if (!paymentMethods || paymentMethods.length === 0) {
    return null;
  }

  return (
    <>
      <EditLink onClick={open} aria-label="Update Autopay" />
      {isOpen ? (
        <EditAutoPayFormModal
          closeModal={close}
          premise={premise}
          pastDueBalance={pastDueBalance}
          paymentMethods={paymentMethods ?? []}
        />
      ) : null}
    </>
  );
};
