import { featureFlagUrls } from "@common/api/featureFlagsUrls";
import { FetchedFeatureFlags } from "@common/context/featureFlagClient.context";
import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { ajax } from "@common/services/ajax";

export interface UserIdentify {
  custom?: Record<string, unknown>;
  key: string;
}

export const featureFlagsApi = {
  flags: (user: UserIdentify) => {
    return ajax.post<FetchedFeatureFlags>(
      featureFlagUrls.fetchAllFlags(),
      user
    );
  },
  metric: {
    track: (
      featureFlagUserId: string,
      eventName: FeatureFlagMetric
    ): Promise<void> => {
      const postBody = { eventName };

      return ajax.post(
        featureFlagUrls.trackMetric(featureFlagUserId),
        postBody
      );
    },
  },
  user: {
    identify: ({ key, custom }: UserIdentify) => {
      return ajax.post<boolean>(featureFlagUrls.identifyUser(), {
        custom,
        key,
      });
    },
    recordVisitor: (flagName: string, user: UserIdentify) => {
      return ajax.post(featureFlagUrls.recordVisitor(flagName), user);
    },
  },
};
