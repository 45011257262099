import { UNKNOWN_ACCOUNT_NUMBER } from "@common/constants/billing.constants";
import { Invoice } from "@common/models/Invoice.model";
import {
  AccountSummaryType,
  BalanceBreakdown,
} from "@common/types/accountSummaryTypes";
import { KeysMatch } from "@common/types/KeysMatch";
import dayjs from "dayjs";

export class AccountSummary {
  public readonly totalBalance: number;
  public readonly pastDueBalance: number;
  public readonly accountDueDate: string;
  public readonly invoices: Invoice[];
  public readonly nextDueDate: string;
  public readonly mostRecentBalance: number;
  public readonly accountNumber: string;
  public readonly balanceBreakdown: BalanceBreakdown | null;

  public constructor(accountSummaryAttrs: AccountSummaryType) {
    this.invoices = (accountSummaryAttrs.invoices ?? [])
      .map((invoice) => new Invoice(invoice))
      .sort((a, b) => {
        return dayjs(a.dueDate) <= dayjs(b.dueDate) ? 1 : -1;
      });
    this.totalBalance = accountSummaryAttrs.totalBalance ?? 0;
    this.pastDueBalance = accountSummaryAttrs.pastDueBalance ?? 0;
    this.accountDueDate = accountSummaryAttrs.accountDueDate ?? "";
    this.nextDueDate = accountSummaryAttrs.nextDueDate ?? "";
    this.mostRecentBalance = accountSummaryAttrs.mostRecentBalance ?? 0;
    this.accountNumber =
      accountSummaryAttrs.accountNumber ?? UNKNOWN_ACCOUNT_NUMBER;
    this.balanceBreakdown = accountSummaryAttrs.balanceBreakdown ?? null;
  }

  public get lastStatemented(): number {
    if (this.invoices.length > 0) {
      return this.invoices[0].amount;
    }

    return 0;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: KeysMatch<
  AccountSummaryType,
  AccountSummary
> = undefined;
