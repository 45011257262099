import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { IterableOptionType } from "@common/types/notificationTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoToggle } from "@ops-design-system/components/BoToggleField/BoToggle";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import React from "react";
import { useField } from "react-final-form";
import styled from "styled-components";

interface AccountNotificationRowProps {
  customerId: IdType;
  option: IterableOptionType;
}

enum MessageText {
  myBillIsAvailable = "I have billing and payment reminders",
  myBillIsHigher = "My energy usage is projected to be higher than usual",
  myWeeklyEnergySummary = "My weekly energy usage summary is available",
  sendMarketingPromos = "Special deals and offers are available",
}

const getMessageText = (message: string) => {
  switch (message) {
    case "NotificationOptionSwitches.myBillIsAvailable": {
      return MessageText.myBillIsAvailable;
    }
    case "NotificationOptionSwitches.myBillIsHigher": {
      return MessageText.myBillIsHigher;
    }
    case "NotificationOptionSwitches.myWeeklyEnergySummary": {
      return MessageText.myWeeklyEnergySummary;
    }
    case "NotificationOptionSwitches.sendMarketingPromos": {
      return MessageText.sendMarketingPromos;
    }
  }
};

const Container = styled.div`
  align-items: center;
  border-top: 1px solid ${grey[100]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;

  &:last-child {
    border-bottom: 1px solid ${grey[100]};
  }

  & > span {
    margin-right: 12px;
  }
`;

export const AccountNotificationRow = ({
  option,
  customerId,
}: AccountNotificationRowProps) => {
  const flash = useRhFlash();

  const optionMessageName = `messageId${option.messageId}`;

  const {
    input: { checked, onChange },
  } = useField<boolean>(optionMessageName, { type: "checkbox" });

  const updateSubscriptionStatus = () => {
    const updateStatus = !checked;
    const action = updateStatus ? "subscribe" : "unsubscribe";
    const { subscriptionGroup, messageId } = option;

    api.customers
      .updateSubscriptionStatus(customerId, {
        action,
        channelId: messageId,
        subscriptionGroup,
      })
      .then(() => {
        flash.success("Notification preferences have been updated.");
        onChange(updateStatus);
      })
      .catch((_error: RhApiError) => {
        flash.error(
          "We are having issues updating your notification preferences. Please try again later."
        );
      });
  };

  return (
    <Container>
      <Subtitle2>{getMessageText(option.channelNameIntlId)}</Subtitle2>
      <BoToggle
        inputProps={{
          checked,
          name: optionMessageName,
          onChange: updateSubscriptionStatus,
        }}
      />
    </Container>
  );
};
