import {
  EnrollmentStatus,
  EnrollmentType as EnrollmentTypeEnum,
} from "@common/enums/customer.enum";
import { PremiseStatus } from "@common/enums/premise.enum";
import { IdType, UUIDType } from "@common/types/apiTypes";
import {
  DeferredPaymentPlanType,
  DisconnectNoticeType,
  PaymentExtensionType,
  PaymentType,
} from "@common/types/billingTypes";
import {
  AddressFormType,
  AddressType,
  EnrollmentType,
} from "@common/types/customerTypes";
import { MeterType } from "@common/types/meterTypes";
import { OrderType } from "@common/types/orderTypes";
import {
  ExternalPaymentMethodType,
  PaymentMethodType,
} from "@common/types/paymentMethodTypes";

export enum CommunicationPreferenceType {
  EBILL = "EBILL",
  PAPERLESS = "PAPERLESS",
  PRINT = "PRINT",
}

export interface PremiseType {
  autopay: boolean;
  averagedBilling: boolean;
  averagedBillingBalance: number | null;
  averagedBillingMonthlyCharge: number | null;
  billingAccountId: string;
  billingAccountNumber: string;
  billingPaymentMethodId: string | null;
  canUpdateMoveOutEndDate: boolean;
  communicationPreference: CommunicationPreferenceType;
  confirmedStartDate: string | null;
  customerId: IdType;
  customerUuid: UUIDType;
  deferredPaymentPlans?: DeferredPaymentPlanType[];
  disconnectNotices?: DisconnectNoticeType[];
  endDate: string;
  enrollmentStatus: EnrollmentStatus;
  enrollmentType: EnrollmentTypeEnum;
  hasActiveDpp: boolean;
  hasHistoricUsage: boolean;
  id: IdType;
  inRenewalWindow: boolean;
  invoiceByPrint: boolean;
  isNoCheckOrAch: boolean;
  isNoCreditOrDebitCard: boolean;
  lastPyddInvoiceDate: string | null;
  lifeSupport: boolean;
  mailingAddress: AddressFormType | null;
  meter: MeterType | null;
  orders: OrderType[];
  paymentExtensions?: PaymentExtensionType[];
  pickedBillingDueDay: number | null;
  serviceAddress: AddressType | null;
  serviceEndDate: string | null;
  serviceRequestedDate: string | null;
  source: PremiseSourceType;
  status: PremiseStatus;
  thermostatManagementMonthlyDiscount: string;
  uuid: UUIDType;
  zuoraSetupServiceAddress: AddressFormType | null;
}

export enum PremiseSourceType {
  IRONHIDE = "IRONHIDE",
  RHYTHM = "RHYTHM",
}

export type PremiseListItemType = Pick<
  PremiseType,
  | "id"
  | "billingAccountNumber"
  | "billingAccountId"
  | "enrollmentStatus"
  | "serviceAddress"
  | "status"
  | "meter"
>;

export interface WinbackDataType {
  eBill: boolean | null;
  enrollmentType: EnrollmentType | null;
  offerId: IdType;
  priorityEnrollment: boolean;
  serviceStartDate: string;
  useNewContract: boolean;
}

export interface WelcomePacketMetadataType {
  lastModified: string;
  size: number;
}

export interface PaymentHistoryType extends PaymentType {
  number: string;
  paymentMethodSnapshot: PaymentMethodType | ExternalPaymentMethodType;
  paymentSource: string | null;
  selected: boolean;
  type: string;
}

export interface SelectablePaymentHistoryType extends PaymentHistoryType {
  selected: boolean;
}

export interface PremiseCreateDataType {
  customerId: IdType;
  eBill: boolean;
  enrollmentType: EnrollmentType;
  mailingAddress: AddressFormType;
  meterId: IdType;
  offerId: IdType;
  priorityEnrollment: boolean;
  serviceStartDate: string;
}
