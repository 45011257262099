import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface PastDueInvoiceData extends EventLogBaseData {
  accountNumber: string;
  balance: string;
  dueDate: string;
  invoiceDate: string;
  invoiceId: string;
  invoiceNumber: string;
  pastDueBalance: string;
}

export class PastDueInvoice extends EventLog {
  public readonly invoiceNumber: string;
  public readonly invoiceId: string;
  public readonly balance: string;
  public readonly dueDate: string;
  public readonly accountNumber: string;
  public readonly invoiceDate: string;
  public readonly pastDueBalance: string;

  constructor({
    invoiceNumber,
    invoiceId,
    balance,
    dueDate,
    accountNumber,
    invoiceDate,
    pastDueBalance,
    ...baseEventData
  }: PastDueInvoiceData) {
    super(baseEventData);

    this.invoiceNumber = invoiceNumber;
    this.invoiceId = invoiceId;
    this.balance = balance;
    this.dueDate = formatMonthDayYear(dueDate);
    this.accountNumber = accountNumber;
    this.invoiceDate = invoiceDate;
    this.pastDueBalance = pastDueBalance;
  }

  override get body() {
    return `${this.name} - Invoice Number: ${this.invoiceNumber} - Due Date: ${this.dueDate} - Balance: $${this.pastDueBalance}`;
  }
}
