import { premiseApi } from "@common/api/premiseApi";
import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { unmaskCurrency } from "@common/forms/currency.mask";
import { Premise } from "@common/models/Premise.model";
import { isOps } from "@common/utils/authenticationHelpers";
import { formatCurrency } from "@common/utils/dataFormatters";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import {
  AddAverageBillingFormValues,
  addAverageBillingFormValidators,
} from "@ops/components/AverageBillingPreference/addAverageBillingFormValidators";
import { BoCurrencyField } from "@ops/components/BoCurrencyField/BoCurrencyField";
import { premiseRefetch } from "@ops/slices/premiseSlice";
import React, { useContext } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface AddAverageBillingDialogProps {
  close: () => void;
  open: boolean;
  premise: Premise;
}

export const AddAverageBillingDialog = ({
  premise,
  open,
  close,
}: AddAverageBillingDialogProps) => {
  const averageMonthlyCharge = premise.averagedBillingMonthlyCharge
    ? formatCurrency(premise.averagedBillingMonthlyCharge)
    : "";
  const { groups } = useContext(AuthGroupsContext);
  const editDisabled = isOps(groups) !== true;

  const flash = useRhFlash();

  const dispatch = useDispatch();

  if (!premise) {
    return null;
  }

  const handleSubmit = (formValues: AddAverageBillingFormValues) => {
    const { averageBillingMonthlyCharge } = formValues;
    const averageBillingMonthlyChargeAmt = unmaskCurrency(
      averageBillingMonthlyCharge
    );

    return handleUpdateAverageBillingCharge(averageBillingMonthlyChargeAmt);
  };

  const handleUpdateAverageBillingCharge = async (
    averageBillingMonthlyCharge: string
  ) => {
    const [error] = await handleAjaxCall(
      premiseApi.setAveragedBillingMonthlyCharge(
        premise.id,
        averageBillingMonthlyCharge
      )
    );

    if (error) {
      flash.error(
        "Encountered an error while updating this customer's average billing monthly charge."
      );
      return;
    }
    dispatch(premiseRefetch(premise.id));
    close();
  };

  return (
    <BoModal open={open} onClose={close}>
      <Form<AddAverageBillingFormValues>
        initialValues={{
          averageBillingMonthlyCharge: averageMonthlyCharge,
        }}
        validate={addAverageBillingFormValidators}
        onSubmit={handleSubmit}
        render={({ handleSubmit: formHandleSubmit, hasValidationErrors }) => (
          <form noValidate onSubmit={formHandleSubmit}>
            <BoDialogHeader>Average Billing</BoDialogHeader>
            <BoDialogBody>
              <BoCurrencyField
                name="averageBillingMonthlyCharge"
                placeholder=""
                disabled={editDisabled}
                autoFocus
              >
                Average Billing Per Month
              </BoCurrencyField>
            </BoDialogBody>
            <BoDialogButtonFooter
              cancelDisabled={false}
              confirmDisabled={hasValidationErrors}
              confirmBtnText="Turn On Average Billing"
              cancelBtnText="Cancel"
              confirmBtnType="submit"
              onCancel={close}
            />
          </form>
        )}
      />
    </BoModal>
  );
};
