import { BoAccordionSummary } from "@ops-design-system/components/BoAccordion/BoAccordion";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const EmailOffersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(3)};
`;

export const EmailOfferSnapshot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(0.5)};

  & > div {
    display: flex;
    flex-direction: row;
    gap: ${rhOpsSpacingPx(0.5)};

    & > span[data-expired="true"] {
      color: ${red["400"]};
      font-weight: ${FontWeight.Bold};
    }
  }
`;

export const StyledBoAccordionSummary = styled(BoAccordionSummary)`
  ${Body2} {
    font-size: 14px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 0;
  & > * {
    margin: 1em;
  }
`;

export const ContractRenewalDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(3)};
  margin-top: ${rhOpsSpacingPx(3)};
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NewContractDatesContainer = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(9)};
  margin-bottom: ${rhOpsSpacingPx(3)};
`;
