export enum CustomerErrorCodes {
  EMAIL_UNIQUE = "rhythm.customer.email.unique",
  FIELDS_INVALID = "rhythm.customer.fields.invalid",
  UNHANDLED_ERROR = "rhythm.unhandled.error",
}

export enum PremiseErrorCodes {
  INVALID_PREMISE_STATE_FOR_OPERATION = "rhythm.premise.invalid_status_for_operation",
  PAYMENT_METHOD_INVALID = "rhythm.premise.payment_method_id.invalid",
  PAYMENT_METHOD_NOT_FOUND = "zuora.payment_method.not_found",
  SERVICE_END_DATE_INVALID = "rhythm.premise.service_end_date.invalid",
  SERVICE_START_DATE_INVALID = "rhythm.premise.service_start_date.invalid",
}

export const emailCustomerMatchError =
  "Unable to proceed with the enrollment, please see validation report log for more details.";

export const customerMatchError =
  "Unable to proceed with the enrollment, please see Billing Tab for more details.";

export const existingCustomerFoundError =
  "Please check and resolve the errors in the validation report log or select the option below.";
