import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import styled from "styled-components";

interface BoCircularProgressProps
  extends Pick<React.CSSProperties, "position"> {}

interface CircularProgressContainerProps {
  $position?: string;
}
export const CircularProgressContainer = styled.div<CircularProgressContainerProps>`
  ${({ $position }) =>
    $position ? `position: ${$position};` : "position: relative;"}
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0.5;
  right: 0;
  top: 0;

  z-index: 5;
  & > * {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;

export const BoCircularProgress = ({ position }: BoCircularProgressProps) => {
  return (
    <CircularProgressContainer $position={position}>
      <RhCircularProgress />
    </CircularProgressContainer>
  );
};
