import { MeterType, TdspAmsIndicator } from "@common/types/meterTypes";
import { StatusIndicatorLevel } from "@common/types/statusIndicatorTypes";

// these strings are defined by the backend model in apps/edi/models/meter.py
// do not change these values unless you change the values in the backend as well
export enum MeterPremiseTypes {
  PREMISE_LARGE_NONRESIDENTIAL = "Large Non-Residential",
  PREMISE_MEDIUM_NONRESIDENTIAL = "Medium Non-Residential",
  PREMISE_RESIDENTIAL = "Residential",
  PREMISE_SMALL_NONRESIDENTIAL = "Small Non-Residential",
}

export enum MeterStatus {
  STATUS_ACTIVE = "Active",
  STATUS_DEENERGIZED = "De-Energized",
  STATUS_INACTIVE = "Inactive",
}

interface ViolationErrorType {
  status: StatusIndicatorLevel;
  text: string;
  validation: (meter: Meter) => boolean;
}

const VIOLATION_ERRORS: ViolationErrorType[] = [
  {
    status: StatusIndicatorLevel.ALERT,
    text: "Small Commercial Meter",
    validation: (meter) => !meter.isResidential,
  },
  {
    status: StatusIndicatorLevel.ALERT,
    text: "Existing Rhythm Customer",
    validation: (meter) => meter.existingEsiIdAlert,
  },
  {
    status: StatusIndicatorLevel.ALERT,
    text: "Iron Hide Customer",
    validation: (meter) => meter.isMeterInIronhide,
  },
  {
    status: StatusIndicatorLevel.ALERT,
    text: "De-energized meter",
    validation: (meter) => meter.isDeEnergized,
  },
];

export class Meter {
  public addressLine1: string | null;
  public city: string | null;
  public duns: string | null;
  public esiId: string | null;
  public existingEsiIdAlert: boolean;
  public isSmt: boolean;
  public meterReadCycle: string | null;
  public state: string | null;
  public unitNumber: string | null;
  public utilityName: string | null;
  public zipCode: string | null;
  public status: string | null;
  public isMeterInIronhide: boolean;
  public hasSolar: boolean;
  public smtSyncedAt: string | null;
  public loadProfile: string | null;
  public tdspAmsIndicator: TdspAmsIndicator | null;

  private readonly rawPremiseType?: string;
  private iViolationErrors:
    | Pick<ViolationErrorType, "text" | "status">[]
    | null;

  public constructor(meter: MeterType) {
    this.addressLine1 = meter.addressLine1;
    this.city = meter.city;
    this.duns = meter.duns;
    this.esiId = meter.esiId;
    this.existingEsiIdAlert = meter.existingEsiIdAlert;
    this.isSmt = meter.isSmt;
    this.meterReadCycle = meter.meterReadCycle;
    this.state = meter.state;
    this.unitNumber = meter.unitNumber;
    this.utilityName = meter.utilityName;
    this.zipCode = meter.zipCode;
    this.status = meter.status;
    this.isMeterInIronhide = meter.isMeterInIronhide;
    this.hasSolar = meter.hasSolar;
    this.smtSyncedAt = meter.smtSyncedAt;
    this.loadProfile = meter.loadProfile;
    this.tdspAmsIndicator = meter.tdspAmsIndicator;

    this.rawPremiseType = meter.premiseType;
    this.iViolationErrors = null;
  }

  get premiseType() {
    if (
      this.rawPremiseType === MeterPremiseTypes.PREMISE_SMALL_NONRESIDENTIAL
    ) {
      return "Small Comm";
    }

    return this.rawPremiseType;
  }

  get isResidential() {
    return this.premiseType === MeterPremiseTypes.PREMISE_RESIDENTIAL;
  }

  get isDeEnergized() {
    return this.status === MeterStatus.STATUS_DEENERGIZED;
  }

  get violationErrors() {
    if (this.iViolationErrors === null) {
      this.iViolationErrors = this.compileViolationErrors();
    }

    return this.iViolationErrors;
  }

  get hasViolationErrors() {
    return this.violationErrors.length !== 0;
  }

  private compileViolationErrors() {
    return VIOLATION_ERRORS.reduce((result, { text, status, validation }) => {
      if (validation(this)) {
        result.push({ status, text });
      }
      return result;
    }, [] as Pick<ViolationErrorType, "text" | "status">[]);
  }
}
