import { ReactComponent as LightbublIcon } from "@design-system/icons/bill-factors/Lightbubl.svg";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { StyledBillFactor } from "@ops/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorsUsageProps {
  differenceInKwh: number;
}

export const BillFactorsUsage = ({
  differenceInKwh,
}: BillFactorsUsageProps) => {
  const absDifference = Math.abs(differenceInKwh);

  return (
    <StyledBillFactor>
      <LightbublIcon />
      <Body1 $fontWeight="Bold">Usage</Body1>
      <Body2>
        {differenceInKwh > 0
          ? `This month, you used ${absDifference} kWh more than last billing cycle.`
          : `This month, you used ${absDifference} kWh less than last billing cycle.`}
      </Body2>
    </StyledBillFactor>
  );
};
