import { rhapsodyOfferSnapshotsApi } from "@ops-data/api/rhapsodyOfferSnapshotsApi";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { OfferSnapshotSearchOptions } from "@ops-utils/types/offerSnapshotTypes";
import { OfferSnapshotQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseOfferSnapshotsQueryProps {
  queryOptions?: {
    enabled?: boolean;
  };
  searchOptions: OfferSnapshotSearchOptions;
}
export const useOfferSnapshotsQuery = (props: UseOfferSnapshotsQueryProps) => {
  const { searchOptions, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => rhapsodyOfferSnapshotsApi.list(searchOptions),
    queryKey: OfferSnapshotQueryKeys.list(searchOptions),
    select: (data) => {
      return {
        ...data,
        results: data.results.map(
          (offerSnapshot) => new OfferSnapshot(offerSnapshot)
        ),
      };
    },
  });
};
