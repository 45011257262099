import { useModalState } from "@common/hooks/useModalState";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { StyledAdminPage } from "@ops/components/AdminDashboard/shared/StyledAdminPage";
import { FidelityPaymentFileTable } from "@ops/components/FidelityPaymentFileTable/FidelityPaymentFileTable";
import { FidelityPaymentsUploader } from "@ops/components/FidelityPaymentsUploader/FidelityPaymentsUploader";
import { useFidelityPaymentFileUploadsInState } from "@ops/hooks/useFidelityPaymentFileUploadsInState";
import React from "react";

export const FidelityPaymentsTablePage = () => {
  const { open, close, isOpen } = useModalState();
  const { requestMonitor, data, fetch } =
    useFidelityPaymentFileUploadsInState();
  const flash = useRhFlash();

  if (requestMonitor.didFail) {
    flash.error("An error occurred while fetching Fidelity payment files.");
  }

  return (
    <StyledAdminPage
      pageName="Manage Fidelity Payments"
      nameInLineButton="Upload Payment File"
      onButtonClick={open}
    >
      <FidelityPaymentsUploader
        open={isOpen}
        onClose={close}
        onSubmit={fetch}
      />

      {requestMonitor.isPending && <BoCircularProgress />}
      {requestMonitor.didSucceed && (
        <FidelityPaymentFileTable externalPaymentFiles={data} />
      )}
    </StyledAdminPage>
  );
};
