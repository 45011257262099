import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import {
  ContractRenewalWizardLabel,
  ContractRenewalWizardSectionContainer,
  ContractRenewalWizardValue,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import styled from "styled-components";

export const ContractRenewalWizardPremiseInfoSidebarContainer = styled(BoCard)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
`;

export const SideBarLabel = styled(ContractRenewalWizardLabel).attrs({
  as: Body3,
})`
  color: ${grey["900"]};
`;

export const SideBarValue = styled(ContractRenewalWizardValue).attrs({
  as: Body2,
})`
  color: ${grey["600"]};
`;

export const SideBarSectionContainer = styled(
  ContractRenewalWizardSectionContainer
)`
  gap: ${rhOpsSpacingPx(0.25)};
`;
