import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButtonWithConfirmDialog } from "@ops-design-system/components/BoButtonWithConfirmDialog/BoButtonWithConfirmDialog";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useSendPaymentLink } from "@ops/hooks/mutations/useSendPaymentLink";
import React, { FC } from "react";
import styled from "styled-components";

export const StyledMessageContainer = styled.div`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;

interface SendPaymentLinkProps {
  customerEmail: string;
  icon?: FC<React.SVGProps<SVGSVGElement>>;
  premiseId: IdType;
}

export const SendPaymentLink = (props: SendPaymentLinkProps) => {
  const { customerEmail, premiseId, icon } = props;
  const flash = useRhFlash();
  const sendPaymentLink = useSendPaymentLink(premiseId);

  const handleClick = () => {
    sendPaymentLink.mutate(premiseId, {
      onError: () => {
        flash.error("There was an error sending the payment link.");
      },
      onSuccess: () => {
        flash.success("Payment Link Sent");
      },
    });
  };

  const message = (
    <StyledMessageContainer>
      <div>
        <Subtitle2>Send payment link to:</Subtitle2>
      </div>
      <div>
        <Subtitle2 $fontWeight="Bold">{customerEmail}</Subtitle2>
      </div>
    </StyledMessageContainer>
  );

  return (
    <BoButtonWithConfirmDialog
      handleConfirm={handleClick}
      header="Send Payment Link"
      confirmBtnText="Send"
      cancelBtnText="Cancel"
      icon={icon}
      buttonContent="Send Payment Link"
      message={message}
      size="extraSmall"
    />
  );
};
