import { EM_DASH } from "@common/constants/characters.constant";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface WinbackData extends EventLogBaseData {
  newBillingAccountNumber: string;
  previousPremiseNumber: string;
  useNewContract: boolean;
}

export class Winback extends EventLog {
  public readonly previousPremiseNumber: string;
  public readonly newPremiseNumber: string;
  public readonly useNewContract: boolean;

  constructor({
    previousPremiseNumber,
    newBillingAccountNumber,
    useNewContract,
    ...baseEventData
  }: WinbackData) {
    super(baseEventData);

    this.previousPremiseNumber = previousPremiseNumber;
    this.newPremiseNumber = newBillingAccountNumber;
    this.useNewContract = useNewContract;
  }

  get contractChoise() {
    if (this.useNewContract) {
      return "New plan selected";
    }
    return "Same plan selected";
  }

  override get body() {
    return `Winback was completed from ${this.previousPremiseNumber} to ${this.newPremiseNumber} ${EM_DASH} ${this.contractChoise}`;
  }
}
