import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface AveragedBillingChangedData extends EventLogBaseData {
  averagedBillingEnabled: boolean;
  averagedBillingMonthlyCharge: string;
}

export class AveragedBillingChanged extends EventLog {
  public readonly averagedBillingEnabled: boolean;
  public readonly averagedBillingMonthlyCharge: string;
  constructor({
    averagedBillingEnabled,
    averagedBillingMonthlyCharge,
    ...baseEventData
  }: AveragedBillingChangedData) {
    super({ ...baseEventData, eventText: "Averaged Billing Changed" });

    this.averagedBillingEnabled = averagedBillingEnabled;
    this.averagedBillingMonthlyCharge = averagedBillingMonthlyCharge;
  }

  override get body() {
    return `Averaged Billing ${
      this.averagedBillingEnabled ? "Enabled" : "Disabled"
    }: Averaged Billing Monthly Charge $${this.averagedBillingMonthlyCharge}`;
  }
}
