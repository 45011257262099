import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ServiceStartDateUpdatedData extends EventLogBaseData {
  sentAt: string;
  serviceEndDate: string;
  serviceStartDate: string;
}

export class ServiceStartDateUpdated extends EventLog {
  public readonly sentAt: string;
  public readonly serviceStartDate: string;
  public readonly serviceEndDate: string;

  constructor({
    sentAt,
    serviceStartDate,
    serviceEndDate,
    ...baseEventData
  }: ServiceStartDateUpdatedData) {
    super(baseEventData);

    this.sentAt = formatMonthDayYear(sentAt);
    this.serviceStartDate = formatMonthDayYear(serviceStartDate);
    this.serviceEndDate = formatMonthDayYear(serviceEndDate);
  }

  override get body() {
    return `${this.name} - Sent At: ${this.sentAt} - Service Start Date: ${this.serviceStartDate} - Service End Date: ${this.serviceEndDate}`;
  }
}
