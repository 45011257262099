import { OktaGroups } from "@common/constants/okta.constant";
import { Customer } from "@common/models/Customer.model";
import { RewardType, RewardsResponseType } from "@common/types/rewardsTypes";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { AddRewardsPoints } from "@ops/components/AddRewardsPoints/AddRewardsPoints";
import { RedeemRewardsPoints } from "@ops/components/RedeemRewardsPoints/RedeemRewardsPoints";
import {
  StyledActionsContainer,
  StyledButtonWrapper,
  StyledLifetimePointsContainer,
  StyledRewardBalanceCardContainer,
} from "@ops/components/RewardBalanceCard/RewardBalanceCard.styled";
import { RewardPointsWithValue } from "@ops/components/RewardPointsWithValue/RewardPointsWithValue";
import React from "react";

interface RewardBalanceCardProps {
  customer: Customer;
  onAddRewardsUpdate: (reward: RewardType) => void;
  onRedeemRewardsUpdate: () => void;
  rewards: RewardsResponseType | null;
}

export const RewardBalanceCard = ({
  customer,
  rewards,
  onAddRewardsUpdate,
  onRedeemRewardsUpdate,
}: RewardBalanceCardProps) => {
  if (!rewards) {
    return null;
  }

  return (
    <BoCard>
      <StyledRewardBalanceCardContainer>
        <BoInfoContainer>
          <BoInfoLabel>Current Points</BoInfoLabel>
          <BoInfoValue>
            <RewardPointsWithValue points={rewards.balance} />
          </BoInfoValue>
        </BoInfoContainer>
        <StyledLifetimePointsContainer>
          <BoInfoContainer>
            <BoInfoLabel>Lifetime Points</BoInfoLabel>
            <BoInfoValue>
              <RewardPointsWithValue points={rewards.lifetime} />
            </BoInfoValue>
          </BoInfoContainer>
        </StyledLifetimePointsContainer>
        <StyledActionsContainer>
          <AccessControl allowedGroups={[OktaGroups.CSRTier2]}>
            <StyledButtonWrapper>
              <AddRewardsPoints
                customer={customer}
                onSuccess={onAddRewardsUpdate}
              />
            </StyledButtonWrapper>
          </AccessControl>
          <StyledButtonWrapper>
            <RedeemRewardsPoints
              rewards={rewards}
              onUpdate={onRedeemRewardsUpdate}
            />
          </StyledButtonWrapper>
        </StyledActionsContainer>
      </StyledRewardBalanceCardContainer>
    </BoCard>
  );
};
