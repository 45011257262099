import { EM_DASH } from "@common/constants/characters.constant";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { ConfirmationSection } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationSection";
import { ConfirmationCardType } from "@ops/components/ProspectEditor/ConfirmationTab/prospectConfirmationTypes";
import React from "react";

interface ConfirmationServiceAddressCardProps extends ConfirmationCardType {}

export const ConfirmationServiceAddressCard = ({
  prospect,
  errors,
}: ConfirmationServiceAddressCardProps) => {
  return (
    <ConfirmationSection label="Service Address" error={Boolean(errors)}>
      <BoGrid $variant={GridVariants.Grid4_1}>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.serviceAddress}>
            <BoInfoLabel>Service Address</BoInfoLabel>
            <BoInfoValue>
              {!prospect.serviceAddress.isEmpty ? (
                <>
                  <div>{prospect.serviceAddress?.line1}</div>
                  <div>{prospect.serviceAddress?.line2}</div>
                </>
              ) : (
                EM_DASH
              )}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.esiId}>
            <BoInfoLabel>ESI-ID</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.esiId)}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.esiId}>
            <BoInfoLabel>Premise Type</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.premiseType)}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.esiId}>
            <BoInfoLabel>Utility</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.utilityName)}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
      </BoGrid>
    </ConfirmationSection>
  );
};
