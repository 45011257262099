import { externalPaymentsApi } from "@common/api/externalPaymentsApi";
import { AxiosErrorCodes } from "@common/constants/errorCodes.constants";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { HTMLInputEvent } from "@design-system/types/domEventTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { StyledInput } from "@ops-design-system/components/BoTextInput/BoTextInput.styled";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { useRef, useState } from "react";
import styled from "styled-components";

interface FidelityPaymentsUploaderProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
}

const FileUploadContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(2)};
`;

export const FidelityPaymentsUploader = ({
  open,
  onClose,
  onSubmit,
}: FidelityPaymentsUploaderProps) => {
  const [
    { requestMonitor },
    { setInitialState, setSuccess, setFailure, setPending },
  ] = useAjaxState();
  const [paymentFile, setPaymentFile] = useState<File | null>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const flash = useRhFlash();

  const closeModal = () => {
    if (!requestMonitor.isPending) {
      setInitialState();
      setPaymentFile(undefined);
      onClose();
    }
  };
  const handleChange = (event: HTMLInputEvent) => {
    if (event && event.target.files?.length) {
      const file = event?.target.files[0];

      setPaymentFile(file);
    }
  };
  const handleSubmit = () => {
    if (requestMonitor.isPending || !paymentFile) {
      return;
    }
    setPending();

    externalPaymentsApi.files
      .create(paymentFile)
      .then(({ name }) => {
        setSuccess();
        flash.success(`Successfully uploaded Fidelity payments file ${name}.`);
        closeModal();
      })
      .catch((err: RhError) => {
        setFailure(err);

        const {
          data: { errorCode, errors },
        } = err;

        switch (errorCode) {
          case "rhythm.unhandled.error":
          case "rhythm.cash_payments.file.empty":
          case "rhythm.cash_payments.file.invalid":
            flash.error(
              `Error processing file: ${errors.map((e) => e.detail).join(", ")}`
            );
            closeModal();
            return;

          case AxiosErrorCodes.AXIOS_TIMEOUT_ERROR_CODE:
            flash.error(
              "The server may take a while to process your fidelity payments upload request. Please check back later for the status.",
              { variant: "info" }
            );
            closeModal();
            return;

          default:
            flash.error(`Something went wrong: ${errorCode}`);
            closeModal();
        }
      })
      .finally(onSubmit);
  };

  return (
    <BoModal open={open} onClose={closeModal}>
      <BoDialogHeader>Upload Fidelity Payments File</BoDialogHeader>
      <BoDialogBody>
        <form>
          <FileUploadContainer>
            <BoLabel htmlFor="cashPaymentsInput">Choose a file</BoLabel>
            <input
              type="file"
              id="cashPaymentsInput"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleChange}
            />
            <StyledInput disabled placeholder={paymentFile?.name} />
          </FileUploadContainer>
          <BoButton
            variant="secondary"
            onClick={() => fileInputRef.current?.click()}
          >
            Choose a file
          </BoButton>
        </form>
      </BoDialogBody>
      <BoDialogButtonFooter
        onConfirm={handleSubmit}
        confirmLoading={requestMonitor.isPending}
        confirmDisabled={!paymentFile}
        confirmBtnText="Upload"
        onCancel={closeModal}
        cancelBtnText="Close"
        cancelDisabled={requestMonitor.isPending}
      />
    </BoModal>
  );
};
