import { IdType } from "@common/types/apiTypes";
import { ThermostatSearchOptions } from "@common/types/thermostatTypes";
import { rhapsodyPremiseApi } from "@ops-data/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseThermostatConnectionActivityQueryProps {
  premiseId: IdType;
  queryOptions: {
    enabled: boolean;
  };
  searchOptions: ThermostatSearchOptions;
}

export const useThermostatConnectionActivityQuery = (
  props: UseThermostatConnectionActivityQueryProps
) => {
  const { premiseId, queryOptions: { enabled } = {}, searchOptions } = props;

  return useQuery({
    enabled,
    queryFn: () =>
      rhapsodyPremiseApi.thermostatConnectionActivity({
        premiseId,
        searchOptions,
      }),
    queryKey: PremiseKeys.thermostatConnectionActivity({
      premiseId,
      searchOptions,
    }),
  });
};
