import { formatCurrency } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhapsodyProspectApi } from "@ops-data/api/rhapsodyProspectApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { BoRadioInput } from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import { Prospect } from "@ops-utils/models/Prospect.model";
import { ProspectCreditCheckRequestType } from "@ops-utils/types/prospectTypes";
import {
  WarningCard,
  WarningMessage,
} from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoStyledCards";
import { CreditCheckCardProps } from "@ops/components/ProspectEditor/BillingInfoTab/CreditCheckCard/CreditCheckCard";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectInState } from "@ops/hooks/useProspectInState";
import React from "react";
import styled from "styled-components";

const RadioWrapper = styled.div`
  flex-basis: 50%;
`;

const ButtonWrapper = styled.div`
  width: 150px;
`;

export const CreditCheckIdRequired = ({
  formValues,
  onChange,
}: CreditCheckCardProps) => {
  const { prospect: prospectData, updateProspectState } =
    useEnrollmentFlowContext();
  const prospect = new Prospect(prospectData);

  const { fetchProspect } = useProspectInState(prospectData.id);

  const flash = useRhFlash();

  const handleToggle = (value: number | string) => {
    const bypass = value === "bypassCreditCheck";

    onChange({
      bypassedCreditCheck: bypass,
      governmentIdRequested: !bypass,
    });
  };

  const handleRefetch = () => {
    if (
      !prospectData.firstName ||
      !prospectData.lastName ||
      !prospectData.dateOfBirth ||
      !prospectData.phone ||
      !prospectData.email ||
      !prospectData.addressLine1 ||
      !prospectData.city ||
      !prospectData.state ||
      !prospectData.zipCode
    ) {
      flash.error("Needs more prospect data to run credit check");

      return;
    }

    const creditCheckData: ProspectCreditCheckRequestType = {
      acquisitionMedium: prospectData.acquisitionMedium,
      addressLine1: prospectData.addressLine1,
      city: prospectData.city,
      dateOfBirth: prospectData.dateOfBirth,
      email: prospectData.email,
      firstName: prospectData.firstName,
      lastName: prospectData.lastName,
      phone: prospectData.phone,
      prospectId: prospectData.id,
      state: prospectData.state,
      unitNumber: prospectData.unitNumber,
      zipCode: prospectData.zipCode,
    };

    rhapsodyProspectApi
      .creditScoreEvaluation(creditCheckData)
      .then(() => {
        fetchProspect()
          .then((prospectRefetchData) => {
            if (prospectRefetchData) {
              updateProspectState(prospectRefetchData);
            }
          })
          .catch(() => flash.error("Unable to gather updated prospect data"));
      })
      .catch(() => {
        flash.error("Unable to fetch credit check information");
      });
  };

  return (
    <WarningCard>
      <WarningMessage>
        Customer must send a copy of their government issued photo ID to
        support@gotrhythm.com.
      </WarningMessage>
      <BoDivider />
      <BoFlexBox>
        <RadioWrapper>
          <BoRadioInput
            name="idOrBypass"
            value="idRequested"
            checked={formValues.governmentIdRequested ?? false}
            label="Customer will send ID"
            onClick={handleToggle}
          />
        </RadioWrapper>
        <RadioWrapper>
          <BoRadioInput
            name="idOrBypass"
            value="bypassCreditCheck"
            checked={formValues.bypassedCreditCheck ?? false}
            label={`Bypass and pay a ${formatCurrency(
              prospect?.depositAmount || 0
            )} deposit.`}
            onClick={handleToggle}
          />
        </RadioWrapper>
      </BoFlexBox>
      <ButtonWrapper>
        <BoButton variant="secondary" onClick={handleRefetch}>
          Refetch Credit Status
        </BoButton>
      </ButtonWrapper>
    </WarningCard>
  );
};
