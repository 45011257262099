import { BoGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

interface StylePastDueProps {
  $pastDueBalance: number;
}

export const StyledPastDue = styled(Subtitle2)<StylePastDueProps>`
  color: ${({ $pastDueBalance }) =>
    $pastDueBalance > 0 ? red["500"] : grey["900"]};
`;

export const PremiseBillingCardGrid = styled(BoGrid)`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;
