import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { ReactComponent as MapPinWithPlus } from "@ops-design-system/icons/MapPinWithPlus.svg";
import { AddPremiseWizardModal } from "@ops/components/AddPremiseWizard/AddPremiseWizardModal";
import { AddPremiseWizardProvider } from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";
import React from "react";

interface AddPremiseWizardProps {
  customer: Customer;
}

export const AddPremiseWizard = ({ customer }: AddPremiseWizardProps) => {
  const { open, close, isOpen } = useModalState();

  return (
    <>
      <BoButton
        icon={MapPinWithPlus}
        variant="secondary"
        size="extraSmall"
        onClick={open}
      >
        Add Premise
      </BoButton>
      <AddPremiseWizardProvider customer={customer} closeModal={close}>
        <AddPremiseWizardModal isOpen={isOpen} />
      </AddPremiseWizardProvider>
    </>
  );
};
