import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledDevicesTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(4)};
`;

export const StyledDevicesTabSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  width: 100%;
`;
