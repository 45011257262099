import {
  Body1,
  Subtitle1,
} from "@ops-design-system/components/Typography/Typography";
import {
  darkPurple,
  grey,
  trueWhite,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled, { css } from "styled-components";

const buttonActiveClass = css`
  background-color: ${darkPurple[50]};
  border-color: ${darkPurple[500]};
`;

export const StyledFilterButton = styled.button<{ $isPurple?: boolean }>`
  align-items: center;
  background-color: ${trueWhite};
  border: 1px solid ${grey[200]};
  border-radius: 67px;
  color: ${darkPurple[500]};
  display: flex;
  gap: ${rhOpsSpacingPx(2)};
  height: 48px;
  justify-content: center;
  min-width: 200px;
  padding: ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(2)};

  :hover {
    cursor: pointer;
    ${buttonActiveClass}
  }

  ${({ $isPurple }) => $isPurple && buttonActiveClass}
`;

export const StyledFieldset = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  width: 270px;
`;

export const Legend = styled(Subtitle1).attrs({ as: "legend" })``;

export const StyledFilterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  padding: ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(2)} ${rhOpsSpacingPx(2)};
`;

export const StyledCheckboxRow = styled(Body1).attrs({
  as: "label",
})`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: ${rhOpsSpacingPx(1)};
  justify-content: flex-start;

  & .MuiSvgIcon-root {
    color: ${darkPurple[300]};
    height: 16px;
    width: 16px;
  }

  & .MuiCheckbox-root {
    padding: 0;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(1)};
`;
