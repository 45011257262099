import {
  formatAsLocalTime,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { convertTemperature } from "@common/utils/stringFormatters";
import { convertTimeToHoursAndMinutes } from "@common/utils/timeHelpers";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import {
  DeviceDemandResponseActivityEventStatusTypes,
  DeviceDemandResponseActivityPhaseType,
  DeviceDemandResponseActivityType,
} from "@ops-utils/types/deviceTypes";
import {
  StyledPhaseContainer,
  StyledStatusText,
} from "@ops/components/DeviceDemandResponseActivityTable/DeviceDemandResponseActivityTable.styled";
import { createColumnHelper } from "@tanstack/react-table";
import React, { Dispatch, SetStateAction } from "react";

interface DeviceDemandResponseActivityTableProps {
  demandResponseActivity: DeviceDemandResponseActivityType[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  setPagination: Dispatch<
    SetStateAction<{ pageIndex: number; pageSize: number }>
  >;
}

const eventNameMap: Record<
  DeviceDemandResponseActivityEventStatusTypes,
  string
> = {
  [DeviceDemandResponseActivityEventStatusTypes.Approved]: "Approved",
  [DeviceDemandResponseActivityEventStatusTypes.Cancelled]: "Cancelled",
  [DeviceDemandResponseActivityEventStatusTypes.Draft]:
    "Pending Device Confirmation",
  [DeviceDemandResponseActivityEventStatusTypes.Finished]: "Completed",
  [DeviceDemandResponseActivityEventStatusTypes.OptedOut]: "Opted Out",
  [DeviceDemandResponseActivityEventStatusTypes.PhaseChanged]: "Phase Changed",
  [DeviceDemandResponseActivityEventStatusTypes.Ready]: "Ready",
  [DeviceDemandResponseActivityEventStatusTypes.Scheduled]: "Scheduled",
  [DeviceDemandResponseActivityEventStatusTypes.Sent]: "Sent",
  [DeviceDemandResponseActivityEventStatusTypes.Started]: "In Progress",
};

export const DeviceDemandResponseActivityTable = (
  props: DeviceDemandResponseActivityTableProps
) => {
  const {
    demandResponseActivity,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = props;
  const columnHelper = createColumnHelper<DeviceDemandResponseActivityType>();

  const sortPhaseByIndex = (phases: DeviceDemandResponseActivityPhaseType[]) =>
    phases.sort((a, b) => a.index - b.index);

  const columns = [
    columnHelper.display({
      cell: ({ row }) => (
        <StyledStatusText
          $optOut={
            row.original.status ===
            DeviceDemandResponseActivityEventStatusTypes.OptedOut
          }
        >
          {
            eventNameMap[
              row.original
                .status as DeviceDemandResponseActivityEventStatusTypes
            ]
          }
        </StyledStatusText>
      ),
      header: "Status",
      id: "status",
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <div>
          <b>{formatMonthDayYear(row.original.startAt)}</b>
          <br />
          {formatAsLocalTime(row.original.startAt)}
        </div>
      ),
      header: "Start Date and Time",
      id: "startAt",
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <StyledPhaseContainer>
          {sortPhaseByIndex(row.original.phases).map((phase) => (
            <div key={phase.index}>{convertTemperature(phase.value)}</div>
          ))}
        </StyledPhaseContainer>
      ),
      header: "Thermostat Adjustments",
      id: "temperatureAdjustment",
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <StyledPhaseContainer>
          {sortPhaseByIndex(row.original.phases).map((phase) => (
            <div key={phase.index}>
              {convertTimeToHoursAndMinutes(phase.duration)}
            </div>
          ))}
        </StyledPhaseContainer>
      ),
      header: "Duration",
      id: "duration",
    }),
  ];

  return (
    <BoReactTable<DeviceDemandResponseActivityType>
      columns={columns}
      data={demandResponseActivity}
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      pagination
      rowId="startAt"
      setPagination={setPagination}
      tableCaption=""
    />
  );
};
