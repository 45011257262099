import { appBrand } from "@common/services/getEnvApplicationSettings.service";

export const generateAxiosHeaders = () => {
  const headers = {
    Brand: appBrand,
  };

  return {
    ...headers,
  };
};
