import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { CreditCheckResultType } from "@common/types/creditCheckTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { Prospect } from "@ops-utils/models/Prospect.model";
import { MatchCriteria } from "@ops-utils/types/prospectValidationReportTypes";
import {
  SuccessCard,
  SuccessMessage,
  WarningCard,
  WarningMessage,
} from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoStyledCards";
import { CreditCheckIdRequired } from "@ops/components/ProspectEditor/BillingInfoTab/CreditCheckCard/CreditCheckIdRequired/CreditCheckIdRequired";
import { CreditCheckSsnRequired } from "@ops/components/ProspectEditor/BillingInfoTab/CreditCheckCard/CreditCheckSsnRequired/CreditCheckSsnRequired";
import { DepositRequiredCard } from "@ops/components/ProspectEditor/BillingInfoTab/CreditCheckCard/DepositRequiredCard/DepositRequiredCard";
import { InitialCreditCheck } from "@ops/components/ProspectEditor/BillingInfoTab/CreditCheckCard/InitialCreditCheck/InitialCreditCheck";
import { ExistingCustomerFoundCard } from "@ops/components/ProspectEditor/BillingInfoTab/ExistingCustomerFoundCard/ExistingCustomerFoundCard";
import { creditCheckResult } from "@ops/components/ProspectEditor/shared/creditCheck.helpers";
import { emailCustomerMatchError } from "@ops/constants/errorCodes.constants";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectPremiseMatches } from "@ops/hooks/queries/useProspectPremiseMatches";
import React from "react";

const EmailCustomerMatchCard = () => {
  return (
    <WarningCard>
      <WarningMessage>{emailCustomerMatchError}</WarningMessage>
    </WarningCard>
  );
};

const LoadingCreditAndMatchCard = () => {
  return (
    <BoCard>
      <RhCircularProgress />
    </BoCard>
  );
};

type CreditCheckSubcomponents = Record<CreditCheckResultType, JSX.Element>;

export interface CreditCheckFormValues {
  bypassedCreditCheck?: boolean;
  depositAlternativeElected?: boolean;
  governmentIdRequested?: boolean;
  nosRequested?: boolean;
}

export interface CreditCheckCardProps {
  formValues: CreditCheckFormValues;
  onChange: (values: CreditCheckFormValues) => void;
}

export const CreditCheckCard = ({
  formValues,
  onChange,
}: CreditCheckCardProps) => {
  const { prospect: prospectState } = useEnrollmentFlowContext();
  const prospect = new Prospect(prospectState);
  const flash = useRhFlash();

  const { featureFlagClient } = useFeatureFlagClient();
  const { opsAlloyValidationReport } = featureFlagClient.getFlags([
    ["opsAlloyValidationReport", false],
  ]);

  const prospectPremiseMatches = useProspectPremiseMatches({
    enabled: !!opsAlloyValidationReport.value,
    prospectId: prospect.id,
  });

  if (opsAlloyValidationReport && prospectPremiseMatches.isError) {
    flash.error("Something went wrong. Please try again.");
  }

  const emailMatch = () => {
    if (prospectPremiseMatches.isSuccess) {
      return prospectPremiseMatches.data.some((premiseMatch) => {
        return premiseMatch.matchCriteria.includes(MatchCriteria.EMAIL);
      });
    }
    return false;
  };

  if (opsAlloyValidationReport.value === true) {
    if (prospectPremiseMatches.isPending) {
      return <LoadingCreditAndMatchCard />;
    }
    if (emailMatch()) {
      return <EmailCustomerMatchCard />;
    }
  }

  const components: CreditCheckSubcomponents = {
    [CreditCheckResultType.insufficientData]: (
      <WarningCard>
        <WarningMessage>
          More information is needed to run a credit check. Please enter first
          name, last name, email, phone, and address under Contact Info.
        </WarningMessage>
      </WarningCard>
    ),
    [CreditCheckResultType.noCreditCheck]: <InitialCreditCheck />,
    [CreditCheckResultType.success]: (
      <SuccessCard>
        <SuccessMessage>
          Customer has been approved. No deposit required.
        </SuccessMessage>
      </SuccessCard>
    ),
    [CreditCheckResultType.requiresDeposit]: (
      <DepositRequiredCard formValues={formValues} onChange={onChange} />
    ),
    [CreditCheckResultType.idRequired]: (
      <CreditCheckIdRequired formValues={formValues} onChange={onChange} />
    ),
    [CreditCheckResultType.nosRequired]: (
      <ExistingCustomerFoundCard
        formValues={{
          nosRequested: formValues.nosRequested ?? false,
        }}
        updateFormValues={onChange}
      />
    ),
    [CreditCheckResultType.ssnRequired]: (
      <CreditCheckSsnRequired onChange={onChange} />
    ),
  };

  const result: CreditCheckResultType = creditCheckResult(prospect);

  return components[result];
};
