import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { ProductType } from "@ops-utils/types/productTypes";

const BASE_PATH = "/api/rhapsody/products";

export const rhapsodyProductsApi = {
  availableForPremise: (
    premiseId: IdType
  ): Promise<PaginationEndpointResponseType<ProductType>> => {
    return ajax.get(`${BASE_PATH}/`, {
      params: {
        availableForPremise: premiseId,
      },
    });
  },
  list: (): Promise<PaginationEndpointResponseType<ProductType>> =>
    ajax.get(`${BASE_PATH}/`),
};
