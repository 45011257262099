import { useAjaxState } from "@common/hooks/useAjaxState";
import { ReactComponent as PlusInsideCircle } from "@design-system/icons/PlusInsideCircle.svg";
import { rhapsodyProspectApi } from "@ops-data/api/rhapsodyProspectApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { H1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ProspectType } from "@ops-utils/types/prospectTypes";
import { ProspectsSearchForm } from "@ops/components/ProspectsSearchForm/ProspectsSearchForm";
import { ProspectsTable } from "@ops/components/ProspectsTable/ProspectsTable";
import { prospectPath } from "@ops/routes/routePaths";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ProspectsPageWrapper = styled.div`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProspectsPage = () => {
  const [prospects, setProspects] = useState<ProspectType[] | undefined>(
    undefined
  );

  const navigate = useNavigate();
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState<ProspectType>();

  const handleClick = () => {
    setPending();
    rhapsodyProspectApi
      .create({})
      .then((data) => {
        setSuccess(data);
        const marketingPath = prospectPath(data.id);

        navigate(marketingPath);
      })
      .catch(setFailure);
  };

  return (
    <ProspectsPageWrapper>
      <FlexContainer>
        <H1>Prospects</H1>

        <div>
          <BoButton
            variant="secondary"
            size="extraSmall"
            icon={PlusInsideCircle}
            onClick={handleClick}
            loading={requestMonitor.isPending}
          >
            New Prospect
          </BoButton>
        </div>
      </FlexContainer>
      <BoDivider />
      <ProspectsSearchForm onSuccess={setProspects} />
      {prospects && <ProspectsTable prospects={prospects} />}
    </ProspectsPageWrapper>
  );
};
