import { ExternalPaymentFileStatus } from "@common/enums/externalPayment.enum";
import { IdType } from "@common/types/apiTypes";
import {
  ExternalPaymentFileType,
  ExternalPaymentType,
  SkippedRowType,
} from "@common/types/paymentTypes";

export class ExternalPaymentFile {
  public id: IdType;
  public fileName: string;
  public source: string;
  public createdAt: string;
  public status: ExternalPaymentFileStatus;
  public externalPayments: ExternalPaymentType[];
  public skippedRows: SkippedRowType[];

  public constructor(data: ExternalPaymentFileType) {
    this.id = data.id;
    this.fileName = data.fileName;
    this.source = data.source;
    this.createdAt = data.createdAt;
    this.status = data.status;
    this.externalPayments = data.externalPayments;
    this.skippedRows = data.skippedRows;
  }

  private getMatchedPayments() {
    return this.externalPayments.filter((p) => p.premise != null);
  }

  getTotalPaymentCount() {
    return this.externalPayments.length;
  }

  getUnmatchedPaymentCount() {
    return this.externalPayments.filter((p) => p.premise === null).length;
  }

  getMatchedPaymentCountByStatus(status: string) {
    return this.getMatchedPayments().filter((p) => p.status === status).length;
  }
}
