// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-refresh/only-export-components */
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import styled, { css } from "styled-components";

interface TypographyProps {
  $fontWeight?: keyof typeof FontWeight;
  $gridAreaName?: string;
}

const sharedProperties = () => css<TypographyProps>`
  ${({ $gridAreaName }) =>
    $gridAreaName &&
    css`
      grid-area: ${$gridAreaName};
    `}
  margin: 0px;
  padding: 0px;
`;

export const H1 = styled.h1<TypographyProps>`
  font-size: 32px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.SemiBold};
  ${sharedProperties}
`;

export const H2 = styled.h2<TypographyProps>`
  font-size: 24px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Regular};
  ${sharedProperties}
`;

export const H3 = styled.h3<TypographyProps>`
  font-size: 20px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Bold};
  ${sharedProperties}
`;

export const H4 = styled.h4<TypographyProps>`
  font-size: 20px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Regular};
  ${sharedProperties}
`;

export const Body1 = styled.span<TypographyProps>`
  font-size: 14px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Regular};
  ${sharedProperties}
`;

export const Body2 = styled.span<TypographyProps>`
  font-size: 12px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Regular};
  line-height: 16.8px;

  ${sharedProperties}
`;

export const Caption = styled.span<TypographyProps>`
  font-size: 11px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Bold};
  ${sharedProperties}
`;

export const Body3 = styled.span<TypographyProps>`
  font-size: 10px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Regular};
  ${sharedProperties}
  line-height: 15px;
`;

export const ButtonFont = styled.span<TypographyProps>`
  font-size: 16px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.SemiBold};
  ${sharedProperties}
`;

export const Subtitle1 = styled.span<TypographyProps>`
  font-size: 18px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Regular};
  ${sharedProperties}
`;

export const Subtitle2 = styled.span<TypographyProps>`
  font-size: 16px;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? FontWeight[$fontWeight] : FontWeight.Regular};
  line-height: 1.4;
  ${sharedProperties}
`;
