import {
  ACHType,
  CreditCardType,
  PaymentMethodRetrieveType,
  PaymentMethodType,
  PaymentMethodTypes,
} from "@common/types/paymentMethodTypes";
import { truncate } from "lodash";

export class PaymentMethod {
  constructor(private paymentMethod: PaymentMethodType) {}

  static fromPaymentMethodRetrieve({
    paymentMethodId,
    paymentMethodData,
    isDefault,
  }: {
    isDefault?: boolean;
    paymentMethodData: PaymentMethodRetrieveType;
    paymentMethodId: string;
  }) {
    let newPaymentMethod: PaymentMethod | undefined;

    if (paymentMethodData.type === PaymentMethodTypes.CreditCard) {
      newPaymentMethod = new PaymentMethod({
        ...paymentMethodData,
        defaultPaymentMethod: Boolean(isDefault),
        id: paymentMethodId,
      } as CreditCardType);
    }
    if (paymentMethodData.type === PaymentMethodTypes.ACH) {
      newPaymentMethod = new PaymentMethod({
        ...paymentMethodData,
        defaultPaymentMethod: Boolean(isDefault),
        id: paymentMethodId,
      } as ACHType);
    }
    if (!newPaymentMethod) {
      throw new Error(
        "You must send in an ACH or CreditCard typed payment method"
      );
    }
    return newPaymentMethod;
  }

  get companyNameAndNumber() {
    const companyNameTruncated = truncate(this.companyName, { length: 24 });

    return `${companyNameTruncated} ${this.trimmedMaskedNumber}`;
  }

  get achType() {
    let achType: null | string = null;

    if (this.paymentMethod.type === PaymentMethodTypes.ACH) {
      achType = this.paymentMethod.achAccountType;
    }

    return achType;
  }

  get bankAccountName() {
    let bankAccountName: null | string = null;

    if (this.paymentMethod.type === PaymentMethodTypes.ACH) {
      bankAccountName = this.paymentMethod.achAccountName;
    }

    return bankAccountName;
  }

  get bankName() {
    let bankName: null | string = null;

    if (this.paymentMethod.type === PaymentMethodTypes.ACH) {
      bankName = this.paymentMethod.achBankName;
    }

    return bankName;
  }

  get defaultPaymentMethod() {
    return this.paymentMethod.defaultPaymentMethod;
  }

  get secondaryInfo() {
    return this.expiration || this.bankAccountName;
  }

  get expiration() {
    let expiration: null | string = null;

    if (this.paymentMethod.type === PaymentMethodTypes.CreditCard) {
      const yearString = `${this.paymentMethod.creditCardExpirationYear}`;
      const lastTwo = yearString.slice(yearString.length - 2);

      expiration = `${this.paymentMethod.creditCardExpirationMonth}/${lastTwo}`;
    }

    return expiration;
  }

  get id() {
    return this.paymentMethod.id;
  }

  get maskedNumber() {
    return this.paymentMethod.type === PaymentMethodTypes.ACH
      ? this.paymentMethod.achAccountNumberMask
      : this.paymentMethod.creditCardMaskNumber;
  }

  get type() {
    return this.paymentMethod.type;
  }

  get companyName() {
    return this.paymentMethod.type === PaymentMethodTypes.ACH
      ? this.paymentMethod.achBankName
      : this.paymentMethod.creditCardType;
  }

  get trimmedMaskedNumber() {
    const startChar = this.maskedNumber.length - 8;

    return this.maskedNumber.substring(startChar);
  }
}
