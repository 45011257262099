import { BoDialogButtonFooter } from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const PaperlessFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

export const PaperlessFormFooter = styled(BoDialogButtonFooter)`
  padding-bottom: 0;
`;

export const ToggleContainer = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(1)};
  height: fit-content;
`;

export const OnOffText = styled(Body2).attrs({ $fontWeight: "Bold" })`
  color: ${grey[500]};
`;
