import { IdType } from "@common/types/apiTypes";

export interface ContactLogCategoryChoice {
  id: IdType;
  types: ContactLogTypeChoice[];
  value: string;
}

export interface ContactLogTypeChoice {
  id: IdType;
  reasons: ContactLogReasonChoice[];
  value: string;
}

export interface ContactLogReasonChoice {
  id: IdType;
  value: string;
}

export enum ContactMethodOptions {
  CHAT = "Chat",
  EMAIL = "Email",
  MAIL = "Mail",
  NO_INTERACTION = "No Customer Interaction",
  PHONE = "Phone",
  SMS = "SMS",
}

export enum SentimentOptions {
  NEGATIVE = "Negative",
  NEUTRAL = "Neutral",
  POSITIVE = "Positive",
}

export interface CreateContactLogType {
  category: string;
  contactMethod: string;
  notes: string;
  outboundContact: boolean;
  reason?: string;
  sentiment?: string;
  type: string;
}

export interface ContactLogType {
  category: string;
  contactMethod: ContactMethodOptions;
  createdAt: string;
  csrName: string;
  id: IdType;
  notes: string;
  outboundContact: boolean;
  reason?: string;
  type: string;
}

export interface IContactLog {
  category: string;
  notes: string;
  reason?: string;
  type: string;
}
