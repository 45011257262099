import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  EventLogSearchEntry,
  SearchableEventLog,
} from "@ops/components/SupportLogs/EventLog/EventLogEntry/EventLogSearchEntry";
import { useSupportLogsContext } from "@ops/components/SupportLogs/hooks/useSupportLogsContext";
import { SupportLogEntryList } from "@ops/components/SupportLogs/SearchLog/SupportLogEntryList";
import { SupportLogSearchInput } from "@ops/components/SupportLogs/SearchLog/SupportLogSearchInput";
import { Header } from "@ops/components/SupportLogs/shared/Header";
import {
  ScrollWrap,
  StyledEmptyText,
  StyledSupportLog,
  StyledWarningText,
} from "@ops/components/SupportLogs/SupportLogs.styled";
import { LogType } from "@ops/components/SupportLogs/SupportLogs.types";
import { useCustomer } from "@ops/hooks/useCustomer";
import { useEventLogsInState } from "@ops/hooks/useEventLogsInState";
import { usePremise } from "@ops/hooks/usePremise";
import { EventLog as EventLogModel } from "@ops/models/EventLog/EventLog.model";
import { searchLogs } from "@ops/models/searchable";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const EventLog = () => {
  const { id: customerId, premiseId } = useParams<{
    id: IdType;
    premiseId: IdType;
  }>();
  const { premise } = usePremise(premiseId);
  const { customer } = useCustomer(customerId);
  const { target } = useSupportLogsContext();
  const { data, requestMonitor } = useEventLogsInState(target, {
    refreshIntervalSeconds: 1.5,
  });
  const flash = useRhFlash();
  const [query, setQuery] = useState<string | undefined>("");

  if (requestMonitor.didFail) {
    flash.error("An error occurred while attempting to fetch event logs");
  }

  if (
    !(requestMonitor.didSucceed || requestMonitor.didFail) &&
    (!data || !customer || !premise)
  ) {
    return (
      <StyledSupportLog>
        <Header>
          <SupportLogSearchInput
            supportType={LogType.EVENT}
            setValue={setQuery}
            value={query}
          />
        </Header>
        <BoCircularProgress position="relative" />
      </StyledSupportLog>
    );
  }

  // Mostly for typescript.  If we got this far and we haven't had a success or fail response
  // something is really screwy
  if (!customer || !premise) {
    return null;
  }

  const premiseFilteredEvents: EventLogModel[] = (data ?? []).filter(
    (event: EventLogModel) => {
      return event.premiseId === premise.id || !event.premiseId;
    }
  );

  const searchableEventLogs = premiseFilteredEvents.map(
    (event) => new SearchableEventLog(event, premise, customer)
  );

  const searchedEventLogs = searchLogs(searchableEventLogs, query);

  const filteredSearchedEvents = searchedEventLogs.map(
    (sel) => sel.eventLogModel
  );

  const mapper = (eventLog: EventLogModel, index: number): JSX.Element => (
    <EventLogSearchEntry
      key={`${eventLog.initiatedBy}__${index}`}
      eventLog={eventLog}
      query={query}
      premise={premise}
      customer={customer}
      index={index}
    />
  );

  const hasNoData = !data || data.length === 0;

  return (
    <StyledSupportLog>
      <Header>
        <SupportLogSearchInput
          supportType={LogType.EVENT}
          setValue={setQuery}
          value={query}
        />
      </Header>

      <ScrollWrap>
        {requestMonitor.didFail && (
          <StyledWarningText>
            We&apos;re having trouble fetching event logs.
          </StyledWarningText>
        )}
        {hasNoData ? (
          <StyledEmptyText>No events created.</StyledEmptyText>
        ) : (
          <SupportLogEntryList<EventLogModel>
            mapper={mapper}
            query={query}
            supportLogs={filteredSearchedEvents}
            logType={LogType.EVENT}
          />
        )}
      </ScrollWrap>
    </StyledSupportLog>
  );
};
