import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import {
  darkPurple,
  green,
  grey,
  red,
  trueWhite,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const buttonVariantThemes = {
  danger: {
    backgroundActive: red["500"],
    backgroundColor: red["500"],
    backgroundDisabled: "transparent",
    backgroundHover: red["100"],
    border: `1px solid ${red["200"]}`,
    borderActive: `1px solid ${red["500"]}`,
    borderDisabled: `1px solid ${grey["200"]}`,
    borderHover: `1px solid ${red["500"]}`,
    color: trueWhite,
    colorDisabled: grey["300"],
  },
  error: {
    backgroundActive: red["100"],
    backgroundColor: "transparent",
    backgroundDisabled: "transparent",
    backgroundHover: red["50"],
    border: `1px solid ${red["400"]}`,
    borderActive: `1px solid ${red["500"]}`,
    borderDisabled: `1px solid ${grey["200"]}`,
    borderHover: `1px solid ${red["500"]}`,
    color: red["500"],
    colorDisabled: grey["300"],
  },
  primary: {
    backgroundActive: darkPurple["600"],
    backgroundColor: darkPurple["500"],
    backgroundDisabled: grey["100"],
    backgroundHover: darkPurple["400"],
    border: `1px solid ${darkPurple["500"]}`,
    borderActive: `1px solid ${darkPurple["600"]}`,
    borderDisabled: `1px solid ${grey["100"]}`,
    borderHover: `1px solid ${darkPurple["400"]}`,
    color: trueWhite,
    colorDisabled: grey["300"],
  },
  secondary: {
    backgroundActive: darkPurple["100"],
    backgroundColor: "transparent",
    backgroundDisabled: "transparent",
    backgroundHover: darkPurple["50"],
    border: `1px solid ${darkPurple["200"]}`,
    borderActive: `1px solid ${darkPurple["500"]}`,
    borderDisabled: `1px solid ${grey["200"]}`,
    borderHover: `1px solid ${darkPurple["500"]}`,
    color: darkPurple["500"],
    colorDisabled: grey["300"],
  },
  success: {
    backgroundActive: green["100"],
    backgroundColor: "transparent",
    backgroundDisabled: "transparent",
    backgroundHover: green["50"],
    border: `1px solid ${green["400"]}`,
    borderActive: `1px solid ${green["500"]}`,
    borderDisabled: `1px solid ${grey["200"]}`,
    borderHover: `1px solid ${green["500"]}`,
    color: green["500"],
    colorDisabled: grey["300"],
  },
};

export const buttonSizeThemes = {
  extraSmall: {
    fontSize: "12px",
    iconPadding: `${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(0.75)} ${rhOpsSpacingPx(
      1
    )} ${rhOpsSpacingPx(1.75)}`,
    padding: `${rhOpsSpacingPx(0.5)} ${rhOpsSpacingPx(1.5)}`,
  },
  medium: {
    fontSize: "16px",
    iconPadding: `${rhOpsSpacingPx(1.75)} ${rhOpsSpacingPx(
      1.25
    )} ${rhOpsSpacingPx(1.75)} ${rhOpsSpacingPx(2.25)}`,
    padding: `${rhOpsSpacingPx(2)}`,
  },
  small: {
    fontSize: "14px",
    iconPadding: `${rhOpsSpacingPx(1.5)} ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(
      1.5
    )} ${rhOpsSpacingPx(2)}`,
    padding: `${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(2)}`,
  },
};

interface ButtonStyleProps {
  $disabled: boolean;
  $fullWidth?: boolean;
  $iconPresent?: boolean;
  $size?: keyof typeof buttonSizeThemes;
  $variant?: keyof typeof buttonVariantThemes;
}

export const StyledBoButton = styled.button<ButtonStyleProps>`
  align-items: center;
  border: ${({ $variant = "primary", $disabled }) =>
    $disabled
      ? buttonVariantThemes[$variant].borderDisabled
      : buttonVariantThemes[$variant].border};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family:
    Averta,
    Helvetica Neue,
    Arial,
    sans-serif;
  justify-content: ${({ $iconPresent }) => ($iconPresent ? "start" : "center")};
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  text-decoration: none !important;
  text-transform: none;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  vertical-align: middle;
  white-space: nowrap;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};

  &:hover {
    background-color: ${({ $variant = "primary" }) =>
      buttonVariantThemes[$variant].backgroundHover};
    border: ${({ $variant = "primary" }) =>
      buttonVariantThemes[$variant].borderHover};
    box-shadow: none;
  }

  &:active {
    background-color: ${({ $variant = "primary" }) =>
      buttonVariantThemes[$variant].backgroundActive};
    border: ${({ $variant = "primary" }) =>
      buttonVariantThemes[$variant].borderActive};
  }

  ${({ $disabled, $variant = "primary" }) => {
    if ($disabled) {
      return `
          background-color: ${buttonVariantThemes[$variant].backgroundDisabled};
          pointer-events: none;
        `;
    }

    return `background-color: ${buttonVariantThemes[$variant].backgroundColor};`;
  }};
`;

interface StyledButtonTextProps {
  $disabled: boolean;
  $iconPresent?: boolean;
  $size?: keyof typeof buttonSizeThemes;
  $variant?: keyof typeof buttonVariantThemes;
}

export const StyledButtonText = styled.span<StyledButtonTextProps>`
  color: ${({ $disabled, $variant = "primary" }) =>
    $disabled
      ? buttonVariantThemes[$variant].colorDisabled
      : buttonVariantThemes[$variant].color};
  font-weight: ${FontWeight.SemiBold};
  ${({ $size = "small" }) => `
    font-size: ${buttonSizeThemes[$size].fontSize};
    padding: ${buttonSizeThemes[$size].padding};
  `}
  ${({ $iconPresent }) => ($iconPresent ? "padding-left: 0;" : "")}
`;

interface StyledButtonIconContainerProps {
  $disabled: boolean;
  $size?: keyof typeof buttonSizeThemes;
  $variant?: keyof typeof buttonVariantThemes;
}

export const StyledButtonIconContainer = styled.div<StyledButtonIconContainerProps>`
  color: ${({ $disabled, $variant = "primary" }) =>
    $disabled
      ? buttonVariantThemes[$variant].colorDisabled
      : buttonVariantThemes[$variant].color};
  line-height: 0;
  padding: ${({ $size = "small" }) => buttonSizeThemes[$size].iconPadding};

  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const StyledLoadingContainer = styled.div`
  & [role="progressbar"] {
    height: 20px !important;
    width: 20px !important;
  }
`;
