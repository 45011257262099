import { OktaGroups } from "@common/constants/okta.constant";
import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { CancelMoveOut } from "@ops/components/CancelMoveOut/CancelMoveOut";
import { CancelService } from "@ops/components/CancelService/CancelService";
import { UpdateServiceEnd } from "@ops/components/UpdateServiceEnd/UpdateServiceEnd";
import React from "react";

interface CancellationActionsCardProps {
  customer: Customer;
  premise: Premise;
}

export const CancellationActionsCard = ({
  customer,
  premise,
}: CancellationActionsCardProps) => {
  return (
    <>
      <BoCardLabel>Cancellation Actions</BoCardLabel>
      <BoCard>
        <ButtonsGrid>
          <AccessControl allowedGroups={[OktaGroups.CSRTier2]}>
            <UpdateServiceEnd customer={customer} currentPremise={premise} />
            <CancelService customer={customer} currentPremise={premise} />
            <CancelMoveOut customer={customer} currentPremise={premise} />
          </AccessControl>
        </ButtonsGrid>
      </BoCard>
    </>
  );
};
