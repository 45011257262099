import { H3 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledBillBreakdownGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rhOpsSpacingPx(2.5)} ${rhOpsSpacingPx(1.5)};
  text-align: center;
`;

export const StyledBillBreakdownGraphTitle = styled(H3)`
  color: ${darkPurple[500]};
`;

export const StyledTemperaturesRadios = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(4)};
  padding: ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(2)};
`;
