import { DateType, IdType } from "@common/types/apiTypes";
import { createRhContext } from "@common/utils/contextHelpers";

export enum PremiseIndicatorsWizardComponentName {
  selectIndicators = "selectIndicators",
  setActivationDate = "setActivationDate",
  setExpirationDate = "setExpirationDate",
}

interface IndicatorDetail {
  activationDate: DateType | null;
  checked: boolean;
  expirationDate: DateType | null;
  name: string;
}

export type CheckboxStatusIndicator = Record<IdType, IndicatorDetail>;

export interface IndicatorsStateType {
  checkboxStatusIndicator: CheckboxStatusIndicator;
  indicatorIdToEditActivationDate: IdType | null;
  indicatorIdToEditExpirationDate: IdType | null;
  sortedStatusIndicatorsIds: IdType[];
}

export interface PremiseIndicatorsWizardContextType {
  activeComponentName: PremiseIndicatorsWizardComponentName;
  closeModal: () => void;
  premiseId: IdType;
  resetWizardState: () => void;
  setActiveComponentName: (
    contentName: PremiseIndicatorsWizardComponentName
  ) => void;
  updateWizardState: (payload: Partial<IndicatorsStateType>) => void;
  wizardState: IndicatorsStateType;
}

export const initialWizardState: IndicatorsStateType = {
  checkboxStatusIndicator: {},
  indicatorIdToEditActivationDate: null,
  indicatorIdToEditExpirationDate: null,
  sortedStatusIndicatorsIds: [],
};

export const PremiseIndicatorsWizardContext =
  createRhContext<PremiseIndicatorsWizardContextType>();
