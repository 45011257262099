import { AddPremiseWizardContextType } from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";
import { useAddPremiseWizardContext } from "@ops/hooks/contexts/useAddPremiseWizardContext";
import { useWizardForm } from "@ops/hooks/useWizardForm";
import React, { PropsWithChildren, useEffect } from "react";
import { useFormState } from "react-final-form";

interface AddPremiseWizardFormProps {
  hasValidationErrors?: boolean;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const AddPremiseWizardForm = ({
  children,
  hasValidationErrors,
  onSubmit,
}: PropsWithChildren<AddPremiseWizardFormProps>) => {
  const { activeComponentName, setNextButtonProps } =
    useAddPremiseWizardContext();

  useEffect(() => {
    if (hasValidationErrors === true) {
      setNextButtonProps({ disabled: true });
    } else if (hasValidationErrors === false) {
      setNextButtonProps({ disabled: false });
    }
  }, [hasValidationErrors, setNextButtonProps]);

  if (onSubmit) {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSubmit(event);
    };

    return (
      <form noValidate onSubmit={handleSubmit} id={activeComponentName}>
        {children}
      </form>
    );
  }

  return (
    <RFFForm
      activeComponentName={activeComponentName}
      setNextButtonProps={setNextButtonProps}
    >
      {children}
    </RFFForm>
  );
};

const RFFForm = ({
  children,
  activeComponentName,
  setNextButtonProps,
}: PropsWithChildren<
  Pick<
    AddPremiseWizardContextType,
    "activeComponentName" | "setNextButtonProps"
  >
>) => {
  const { hasValidationErrors } = useFormState();
  const { onSubmit } = useWizardForm();

  useEffect(() => {
    if (hasValidationErrors) {
      setNextButtonProps({ disabled: true });
    } else {
      setNextButtonProps({ disabled: false });
    }
  }, [hasValidationErrors, setNextButtonProps]);

  return (
    <form noValidate onSubmit={onSubmit} id={activeComponentName}>
      {children}
    </form>
  );
};
