import { rhapsodyCustomerDocumentsApi } from "@ops-data/api/rhapsodyCustomerDocumentsApi";
import { CustomerDocumentsSearchOptions } from "@ops-utils/types/customerDocumentTypes";
import { CustomerDocumentsQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseCustomerDocumentsQueryProps {
  searchOptions: CustomerDocumentsSearchOptions;
}

export const useCustomerDocumentsQuery = (
  props: UseCustomerDocumentsQueryProps
) => {
  const { searchOptions } = props;

  return useQuery({
    queryFn: () => rhapsodyCustomerDocumentsApi.retrieve(searchOptions),
    queryKey: CustomerDocumentsQueryKeys.retrieve(searchOptions),
  });
};
