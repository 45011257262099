import { boxShadows } from "@design-system/constants/boxShadows";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import {
  Body3,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import {
  darkPurple,
  green,
  red,
  trueWhite,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled, { css } from "styled-components";

export const OfferCardContainer = styled.article<{
  $active: boolean;
  $calloutColor?: string;
}>`
  border-radius: 6px;
  box-shadow: ${boxShadows.default};
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  width: 250px;

  &:hover {
    outline: 1px solid
      ${({ $calloutColor }) => $calloutColor ?? darkPurple["500"]};
  }

  ${({ $active, $calloutColor }) => {
    return $active
      ? css`
          border: 1px solid ${$calloutColor};
        `
      : undefined;
  }}
`;

export const StyledBody = styled.div`
  padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  padding-bottom: ${rhOpsSpacingPx(1.5)};
`;

export const TitleContainer = styled(CardRow)`
  gap: 0;

  & > ${Body3} {
    color: ${red.main};
  }
`;

interface CardFlexRowProps {
  $centered?: boolean;
}
export const CardFlexRow = styled(CardRow)<CardFlexRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $centered }) =>
    $centered ? "center" : "space-between"};
`;

export const CardDivider = styled(BoDivider).attrs({
  $light: true,
})`
  margin-bottom: ${rhSpacingPx(1.5)};
`;

interface AveragePriceGridItemProps {
  $column: number;
  $row: number;
}
export const AveragePriceGridItem = styled.div<AveragePriceGridItemProps>`
  grid-area: ${({ $row, $column }) => `${$row} / ${$column}`};
`;

export const AveragePriceGrid = styled.div`
  display: grid;
  gap: 0.5em;
  grid-template-columns: 58px 1fr;
`;

export const CardTitle = styled(Subtitle2).attrs({
  as: "h4",
  fontWeight: "Bold",
})``;

export const OfferInfoCard = styled.div`
  height: 32px;
  margin-bottom: 4px;
`;

export const StyledCardCallout = styled.div<{
  $calloutBackgroundColor: string;
}>`
  background-color: ${({ $calloutBackgroundColor }) => {
    return $calloutBackgroundColor ?? green[500];
  }};
  color: ${trueWhite};
  padding: ${rhSpacingPx(1)} ${rhSpacingPx(3)};
`;
