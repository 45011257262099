import { maskPhone, unmaskPhone } from "@common/forms/phone.mask";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 16px;

  & > {
    grid-column: attr(data-grid, 1);
  }
`;

const TwoColumnInput = styled(BoTextField)`
  grid-column: span 2;
`;

const ThreeColumnInput = styled(BoTextField)`
  grid-column: span 3;
`;

export const AffiliateFields = () => {
  return (
    <Grid>
      <TwoColumnInput name="firstName">First Name</TwoColumnInput>
      <TwoColumnInput name="lastName">Last Name</TwoColumnInput>
      <TwoColumnInput name="email" type="email">
        Email
      </TwoColumnInput>
      <TwoColumnInput
        format={maskPhone}
        parse={unmaskPhone}
        name="phone"
        type="tel"
        inputMode="numeric"
      >
        Phone Number
      </TwoColumnInput>
      <ThreeColumnInput name="addressLine1">Street Address</ThreeColumnInput>
      <BoTextField name="unitNumber">Unit #</BoTextField>
      <TwoColumnInput name="city">City</TwoColumnInput>
      <BoTextField name="state">State</BoTextField>
      <BoTextField name="zipCode">Zip Code</BoTextField>
      <TwoColumnInput name="organizationName">Organization Name</TwoColumnInput>
      <TwoColumnInput name="einTaxId">EIN or Tax ID</TwoColumnInput>
    </Grid>
  );
};
