import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  ProductContractCancelRequestType,
  ProductContractStatusFilter,
  ProductContractType,
} from "@ops-utils/types/productContractTypes";
import { ProductCreateRequestType } from "@ops-utils/types/productTypes";

const BASE_PATH = "/api/rhapsody/product-contracts";

export const rhapsodyProductContractsApi = {
  cancel: (data: ProductContractCancelRequestType): Promise<null> => {
    return ajax.patch(`${BASE_PATH}/cancel/`, data);
  },
  create: (data: ProductCreateRequestType): Promise<null> => {
    return ajax.post(`${BASE_PATH}/`, data);
  },
  list: (
    premiseId?: IdType,
    status?: ProductContractStatusFilter
  ): Promise<PaginationEndpointResponseType<ProductContractType>> => {
    return ajax.get(`${BASE_PATH}/`, {
      params: {
        premise: premiseId,
        status,
      },
    });
  },
};
