import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  Body1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { CreatePaymentExtensionSuccessType } from "@ops/components/PaymentExtensionWizardModal/PaymentExtensionWizardModal";
import React from "react";
import styled from "styled-components";

const ConfirmationNumber = styled(Body1).attrs({ as: "div" })``;

const Bold = styled(Subtitle2).attrs({ $fontWeight: "Bold" })``;

const Message = styled(Subtitle2).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

interface MakeAPaymentSuccessProps {
  closeModal: () => void;
  newPaymentExtensionData: CreatePaymentExtensionSuccessType | null;
}

export const PaymentExtensionSuccess = ({
  newPaymentExtensionData,
  closeModal,
}: MakeAPaymentSuccessProps) => {
  if (!newPaymentExtensionData || !newPaymentExtensionData.paymentExtension) {
    return <Message>Something went wrong. Please try again.</Message>;
  }

  const {
    paymentExtension: { amountExtended, extendToDate, amountPaid },
    zuoraPaymentNumber,
  } = newPaymentExtensionData;

  return (
    <>
      <BoDialogHeader>Payment Extension</BoDialogHeader>
      <BoDialogBody>
        <Message>
          Balance of <Bold>{formatCurrency(Number(amountExtended) ?? 0)}</Bold>{" "}
          has been extended until{" "}
          <Bold>{formatMonthDayYear(extendToDate)}.</Bold>
        </Message>
        {zuoraPaymentNumber && (
          <>
            <Message>
              Down payment of{" "}
              <Bold>{formatCurrency(Number(amountPaid) ?? 0)}</Bold> has been
              submitted.
            </Message>
            <ConfirmationNumber>
              Confirmation {zuoraPaymentNumber}
            </ConfirmationNumber>
          </>
        )}
      </BoDialogBody>
      <BoDialogButtonFooter
        cancelBtnText="Back to Customer Page"
        onCancel={closeModal}
      />
    </>
  );
};
