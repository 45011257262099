import { AcquisitionCampaign } from "@common/models/AcquisitionCampaign.model";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { EditLink } from "@ops/components/EditLink/EditLink";
import {
  useAcquisitionCampaignsEditorContext,
  useAcquisitionCampaignsListContext,
} from "@ops/pages/Admin/AcquisitionCampaignsPage/AcquisitionCampaignsPage";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const EditTableCell = styled(BoTableCell)`
  & > * {
    width: 0;
  }
`;

export const COLUMNS: Array<keyof AcquisitionCampaign> = [
  "id",
  "salesChannel",
  "name",
  "slug",
  "phoneNumber",
  "pricingCampaignSlug",
  "createdDate",
  "status",
];

export const AcquisitionCampaignsTable = () => {
  const flash = useRhFlash();
  const { open, setSelectedCampaign } = useAcquisitionCampaignsEditorContext();
  const { data, requestMonitor } = useAcquisitionCampaignsListContext();
  const [acquisitionCampaigns, setAcquisitionCampaigns] = useState<
    AcquisitionCampaign[] | null
  >(null);

  useEffect(() => {
    if (data) {
      setAcquisitionCampaigns(
        data.map((campaignData) => new AcquisitionCampaign(campaignData))
      );
    }
  }, [data, setAcquisitionCampaigns]);

  useEffect(() => {
    if (requestMonitor.didFail) {
      flash.error("An error occurred while fetching sales campaigns");
    }
  }, [requestMonitor.didFail]);

  if (requestMonitor.isPending) {
    return <BoCircularProgress />;
  }

  if (requestMonitor.didFail) {
    return null;
  }

  const openEditor = (selectedCampaign: AcquisitionCampaign) => {
    setSelectedCampaign(selectedCampaign);
    open();
  };

  return (
    <BoTable>
      <BoTableHead>
        <BoTableRow>
          <BoTableHeadCell />
          {COLUMNS.map((columnName) => (
            <BoTableHeadCell key={columnName}>
              {convertCamelCaseToSentenceCase(columnName)}
            </BoTableHeadCell>
          ))}
        </BoTableRow>
      </BoTableHead>
      <BoTableBody>
        {(acquisitionCampaigns ?? []).map((acquisitionCampaign) => (
          <BoTableRow key={acquisitionCampaign.id}>
            <EditTableCell>
              <EditLink onClick={() => openEditor(acquisitionCampaign)} />
            </EditTableCell>
            {COLUMNS.map((columnName) => (
              <BoTableCell key={`${columnName}${acquisitionCampaign.id}`}>
                {acquisitionCampaign[columnName] ?? ""}
              </BoTableCell>
            ))}
          </BoTableRow>
        ))}
      </BoTableBody>
    </BoTable>
  );
};
