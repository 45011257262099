import { affiliateApi } from "@common/api/affiliateApi";
import { IdType } from "@common/types/apiTypes";
import { AffiliateQueryKeys } from "@ops/constants/queryKeys.constants";
import { QUERY_STALE_TIME_DEFAULT_SHORT } from "@ops/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UseAffiliateProps {
  affiliateId: IdType;
}
export const useAffiliate = (props: UseAffiliateProps) => {
  const { affiliateId } = props;

  return useQuery({
    queryFn: () => affiliateApi.retrieve(affiliateId),
    queryKey: AffiliateQueryKeys.retrieve(affiliateId),
    staleTime: QUERY_STALE_TIME_DEFAULT_SHORT,
  });
};
