import isNaN from "lodash/isNaN";

export const restrictWithinRange = (
  value: number,
  maxValue: number,
  minValue: number = 0
) => {
  if (isNaN(value)) {
    return minValue;
  }
  return Math.min(Math.max(value, minValue), maxValue);
};
