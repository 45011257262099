/* eslint-disable camelcase */
export enum GridVariants {
  /** 1 column */
  Grid1 = "Grid 1",
  /** 2 columns that are equal width */
  Grid1_1 = "Grid 1_1",
  /** 2 columns that are 67/33 % width */
  Grid2_1 = "Grid 2_1",
  /** 3 columns, equal width */
  Grid3_1 = "Grid 3_1",
  /** 4 columns, equal width */
  Grid4_1 = "Grid 4_1",
}
/* eslint-enable camelcase */
