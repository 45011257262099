import { SupportLogsContext } from "@ops/components/SupportLogs/SupportLogs";
import { SupportLogsContextType } from "@ops/components/SupportLogs/SupportLogs.types";
import { useContext } from "react";

export function useSupportLogsContext(): SupportLogsContextType {
  const context = useContext<SupportLogsContextType | null>(SupportLogsContext);

  if (context === null) {
    throw new Error("Using SupportLogsContext outside provider"); // throw error if using this hook outside the provider
  }

  return context;
}
