import {
  H1,
  H2,
  Subtitle1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { ErrorPageContainer } from "@ops/pages/BaseErrorPage/BaseErrorPage.styled";
import React from "react";
import { Link } from "react-router-dom";

interface BaseErrorPageProps {
  code?: string;
  header: string;
  message: string;
  showLinkToHome: boolean;
}

export const BaseErrorPage = ({
  code,
  header,
  message,
  showLinkToHome,
}: BaseErrorPageProps) => {
  return (
    <ErrorPageContainer>
      {Boolean(code) && <H1>{code}</H1>}
      <H2>{header}</H2>
      <Subtitle1>{message}</Subtitle1>
      {showLinkToHome && (
        <Subtitle2>
          <Link to="/">Return Home</Link>
        </Subtitle2>
      )}
    </ErrorPageContainer>
  );
};
