import { BillComparisonChart } from "@common/components/BillComparisonChart/BillComparisonChart";
import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { StyledBillBreakdownComparisonBody } from "@ops/components/BillBreakdownComparison/BillBreakdownComparison.styled";
import { BillBreakdownComparisonTable } from "@ops/components/BillBreakdownComparisonTable/BillBreakdownComparisonTable";
import { BILL_BREAKDOWN_TABLE_HEADER } from "@ops/components/BillBreakdownComparisonTable/BillBreakdownComparisonTable.styled";
import { useBillBreakdownComparisonQuery } from "@ops/hooks/queries/useBillBreakdownComparison.query";
import React from "react";

interface BillBreakdownComparisonBodyProps {
  invoiceId: IdType;
}

export const BillBreakdownComparisonBody = (
  props: BillBreakdownComparisonBodyProps
) => {
  const { invoiceId } = props;

  const { data, isError, isPending } = useBillBreakdownComparisonQuery({
    invoiceId,
  });

  if (isError || data?.invoiceCompatible === false) {
    return (
      <Body1>
        Something went wrong while getting previous bill data. Check later or
        with another invoice.
      </Body1>
    );
  }

  if (isPending) {
    return <BoCircularProgress />;
  }

  return (
    <StyledBillBreakdownComparisonBody>
      <BillComparisonChart
        comparisionData={data}
        xLabels={{
          lastMonth: "Last month’s bill",
          lastYear: "Last year’s bill",
          thisMonth: "This month’s bill",
        }}
        yTitle="MONTHLY kWh Usage"
        tableHeaderWidth={BILL_BREAKDOWN_TABLE_HEADER}
      />

      <BillBreakdownComparisonTable comparisonData={data} />
    </StyledBillBreakdownComparisonBody>
  );
};
