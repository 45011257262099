import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";

// interface RhTypographyOptions extends TypographyOptions {
//   body3: TypographyStyleOptions;
// }

export const FONT_NAME = "Averta";
export const fontFamily = [
  FONT_NAME,
  "Helvetica Neue",
  "Arial",
  "sans-serif",
].join(",");

export const typography = {
  body1: {
    fontSize: 14,
    fontWeight: FontWeight.Regular,
  },
  body2: {
    fontSize: 12,
    fontWeight: FontWeight.Regular,
    lineHeight: 1.3,
  },
  body3: {
    fontSize: 10,
    fontWeight: FontWeight.Regular,
  },
  button: {
    fontSize: 16,
    fontWeight: FontWeight.Medium,
    textTransform: "capitalize",
  },
  caption: {
    fontSize: 11,
    fontWeight: FontWeight.Bold,
  },
  fontFamily,
  fontSize: 16,
  fontWeightBold: FontWeight.Bold,
  fontWeightLight: FontWeight.Light,
  fontWeightMedium: FontWeight.Medium,
  fontWeightRegular: FontWeight.Regular,
  h1: {
    [`@media (max-width: ${RhythmBreakpoints.SM}px)`]: {
      fontSize: 24,
    },
    fontSize: 32,
    fontWeight: FontWeight.Medium,
  },
  h2: {
    fontSize: 24,
    fontWeight: FontWeight.Regular,
  },
  h3: {
    fontSize: 20,
    fontWeight: FontWeight.Bold,
  },
  h4: {
    fontSize: 20,
    fontWeight: FontWeight.Regular,
  },
  h5: {
    fontSize: 18,
    fontWeight: FontWeight.Regular,
  },
  h6: {
    fontSize: 16,
    fontWeight: FontWeight.Regular,
  },
  htmlFontSize: 16,
  subtitle1: {
    fontSize: 18,
    fontWeight: FontWeight.Regular,
    lineHeight: 1.3,
  },
  subtitle2: {
    fontSize: 16,
    fontWeight: FontWeight.Regular,
  },
};
