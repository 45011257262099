import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { Divider } from "@mui/material";
import styled from "styled-components";

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    background-color: ${CSSVariables.COLOR_GREY_200};
  }

  &.MuiDivider-light {
    background-color: ${CSSVariables.COLOR_GREY_100};
  }
`;
