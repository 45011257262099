import { premiseApi } from "@common/api/premiseApi";
import { EDIMessage } from "@common/models/EDIMessage.model";
import { IdType } from "@common/types/apiTypes";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export function useEDIMessageHistoryQuery(premiseId: IdType) {
  return useQuery({
    queryFn: () => premiseApi.ediMessages.list(premiseId),
    queryKey: PremiseKeys.ediMessageHistory(premiseId),
    select: (data) => data.map((msg) => new EDIMessage(msg)),
  });
}
