import { UUIDType } from "@common/types/apiTypes";
import { ProductTierType } from "@ops-utils/types/productTypes";
import { Product } from "@ops/models/Products/Product.model";
import { ProductPrice } from "@ops/models/Products/ProductPrice.model";

export class ProductTier {
  product: Product;
  id: UUIDType;
  name: string;
  description: string;
  prices: ProductPrice[];

  constructor(product: Product, tier: ProductTierType) {
    this.product = product;
    this.id = tier.id;
    this.name = tier.name;
    this.description = tier.description;
    this.prices = tier.prices.map((price) => new ProductPrice(this, price));
  }
}
