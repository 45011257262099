import { IdType } from "@common/types/apiTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoSelectInput,
  BoSelectOptionType,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import {
  Body1,
  Body2,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useDeferPaymentFormState } from "@ops/components/DeferPaymentForm/useDeferPaymentFormState";
import { DeferPaymentComponentType } from "@ops/components/DeferPaymentWizard/DeferPaymentModalStateProvider";
import { PaymentSelector } from "@ops/components/DeferPaymentWizard/PaymentSelector/PaymentSelector";
import { useDeferPaymentModalState } from "@ops/components/DeferPaymentWizard/useDeferPaymentModalStateProvider";
import { DownPaymentCalculator } from "@ops/components/DownPaymentCalculator/DownPaymentCalculator";
import { CalculatedFieldLabels } from "@ops/components/DownPaymentCalculator/DownPaymentCalculator.constants";
import { DownPaymentEquation } from "@ops/components/DownPaymentCalculator/DownPaymentEquation";
import { useDownPaymentCalculatorState } from "@ops/components/DownPaymentCalculator/useDownPaymentCalculatorState";
import { DownPaymentQuestionnaire } from "@ops/components/DownPaymentQuestionnaire/DownPaymentQuestionnaire";
import { usePaymentMethodOptions } from "@ops/hooks/usePaymentMethodOptions";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { usePremise } from "@ops/hooks/usePremise";
import { distributeDPPTotal } from "@ops/utils/deferPaymentHelper";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import styled from "styled-components";

const Subheader = styled(Subtitle2).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

const CustomerBalance = styled.span`
  color: ${red["500"]};
`;

const SelectorContainer = styled.div`
  width: 150px;
`;

const InstallmentContainer = styled.div`
  margin-right: ${rhOpsSpacingPx(5)};
`;

const InstallmentDate = styled(Body2).attrs({ as: "div" })`
  font-weight: ${FontWeight.Bold};
`;

const InstallmentValue = styled(Body1).attrs({ as: "div" })`
  color: ${grey["900"]};
  margin-right: ${rhOpsSpacingPx(5)};
`;

const ComputedFormFieldWrapper = styled.div`
  margin-bottom: ${rhOpsSpacingPx(4)};
`;

const InstallmentEntriesWrapper = styled.div`
  margin-bottom: ${rhOpsSpacingPx(1)};
  margin-top: ${rhOpsSpacingPx(1.5)};
  & > * {
    margin-bottom: ${rhOpsSpacingPx(3)};
  }
`;

const ErrorsHeader = styled(Subtitle2).attrs({ as: "div" })`
  color: red;
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

interface FooterProps {
  closeModal: () => void;
  handleSubmit: () => void;
  hasMadeDownPayment: boolean;
  isFormValid: boolean;
  isNoDownPayment: boolean;
  requestPending: boolean;
}

const Footer = ({
  requestPending,
  isFormValid,
  isNoDownPayment,
  closeModal,
  hasMadeDownPayment,
  handleSubmit,
}: FooterProps) => {
  const { updateModalState } = useDeferPaymentModalState();

  if (isNoDownPayment) {
    return (
      <BoDialogButtonFooter
        confirmBtnText="Next"
        cancelBtnText="Cancel"
        confirmDisabled={requestPending}
        onConfirm={() => {
          updateModalState({
            activeComponent: DeferPaymentComponentType.confirmNoDownPayment,
          });
        }}
        onCancel={closeModal}
      />
    );
  } else {
    return (
      <BoDialogButtonFooter
        confirmBtnText={
          hasMadeDownPayment
            ? "Defer Payment"
            : "Defer Payment and Pay Down Payment"
        }
        cancelBtnText="Cancel"
        confirmDisabled={!isFormValid || requestPending}
        onConfirm={handleSubmit}
        onCancel={closeModal}
      />
    );
  }
};

interface DeferPaymentFormProps {
  closeModal: () => void;
  currentBalance: number;
  handleSubmit: () => void;
  pastDueBalance: number;
  premiseId: IdType;
  requestPending: boolean;
}

const installmentOptions: BoSelectOptionType[] = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const DeferPaymentForm = ({
  closeModal,
  currentBalance,
  pastDueBalance,
  premiseId,
  handleSubmit,
  requestPending,
}: DeferPaymentFormProps) => {
  const { premise } = usePremise(premiseId);

  const { updateCalculatorState, totalAmountDue, downPayment } =
    useDownPaymentCalculatorState();

  const {
    updateFormState,
    numberOfInstallments,
    currentPaymentMethod,
    hasMadeDownPayment,
    selectedPaymentList,
    errors,
  } = useDeferPaymentFormState();

  const { paymentMethods } = usePaymentMethods(premiseId);

  const { defaultPaymentMethod } = usePaymentMethodOptions(paymentMethods);

  const handleInstallmentsChange = (
    numberOfInstallmentsOption: BoSelectOptionType | null
  ) => {
    updateFormState({
      numberOfInstallments: numberOfInstallmentsOption?.value as number,
    });
  };

  const handleQuestionnaireChange = (val: boolean) => {
    updateFormState({ hasMadeDownPayment: val });
  };

  const deferredTotal = totalAmountDue - downPayment;

  const installments = distributeDPPTotal(deferredTotal, numberOfInstallments);

  const isFormValid =
    (hasMadeDownPayment !== undefined &&
      hasMadeDownPayment &&
      selectedPaymentList.flat().length > 0) ||
    (hasMadeDownPayment !== undefined &&
      !hasMadeDownPayment &&
      Boolean(currentPaymentMethod));

  const isNoDownPayment = downPayment === 0;

  useEffect(() => {
    if (
      paymentMethods?.length &&
      !currentPaymentMethod &&
      defaultPaymentMethod
    ) {
      updateFormState({ currentPaymentMethod: defaultPaymentMethod.id });
    }
  }, [
    updateFormState,
    paymentMethods,
    currentPaymentMethod,
    defaultPaymentMethod,
  ]);

  useEffect(() => {
    if (totalAmountDue === 0) {
      updateCalculatorState({ totalAmountDue: pastDueBalance });
    }
  }, [pastDueBalance]);

  if (!premise) {
    return <BoCircularProgress />;
  }

  return (
    <>
      <BoDialogHeader>Deferred Payment Plan</BoDialogHeader>
      <BoDialogBody>
        {errors ? <ErrorsHeader>{errors}</ErrorsHeader> : null}
        <BoGrid $variant={GridVariants.Grid2_1}>
          <BoGridColumn>
            <Subheader>
              <div>
                Current energy balance:{" "}
                <CustomerBalance>
                  {formatCurrency(currentBalance)}
                </CustomerBalance>
              </div>
              <div>
                Past due energy balance:{" "}
                <CustomerBalance>
                  {formatCurrency(pastDueBalance)}
                </CustomerBalance>
              </div>
            </Subheader>
            <BoDivider />
            <ComputedFormFieldWrapper>
              <DownPaymentEquation
                calculatedFieldLabel={CalculatedFieldLabels.deferPayment}
              />
            </ComputedFormFieldWrapper>
            <BoDivider />
            <InstallmentEntriesWrapper>
              <SelectorContainer>
                <BoLabel id="payment-installments-select">
                  Payment Installments
                </BoLabel>
                <BoSelectInput
                  ariaLabelledby="payment-installments-select"
                  options={installmentOptions}
                  value={numberOfInstallments}
                  onChange={handleInstallmentsChange}
                />
              </SelectorContainer>
              <BoFlexBox $justifyContent="flex-start">
                {installments.map((value, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <InstallmentContainer key={index}>
                    <InstallmentDate>
                      {dayjs()
                        .add(30, "day")
                        .add(index, "month")
                        .format("MM/YYYY")}
                    </InstallmentDate>
                    <InstallmentValue>
                      {formatCurrency(Number(value))}
                    </InstallmentValue>
                  </InstallmentContainer>
                ))}
              </BoFlexBox>
            </InstallmentEntriesWrapper>
            <BoDivider />
            <DownPaymentQuestionnaire
              handleChange={handleQuestionnaireChange}
              hasMadeDownPayment={hasMadeDownPayment}
            />
            <PaymentSelector />
          </BoGridColumn>
          <BoGridColumn>
            <DownPaymentCalculator />
          </BoGridColumn>
        </BoGrid>
      </BoDialogBody>
      <Footer
        requestPending={requestPending}
        isFormValid={isFormValid}
        isNoDownPayment={isNoDownPayment}
        closeModal={closeModal}
        hasMadeDownPayment={hasMadeDownPayment ?? false}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
