import { BoButtonVariants } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  BoModal,
  BoModalProps,
} from "@ops-design-system/components/BoModal/BoModal";
import React, { FC, PropsWithChildren } from "react";

export interface BoDialogProps extends BoModalProps {
  cancelBtnText?: string;
  cancelDisabled?: boolean;
  confirmBtnText?: string;
  confirmButtonVariant?: BoButtonVariants;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  header?: string;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
}
export const BoDialog: FC<PropsWithChildren<BoDialogProps>> = ({
  children,
  confirmBtnText,
  cancelBtnText,
  onConfirm,
  header,
  cancelDisabled = false,
  confirmDisabled = false,
  confirmButtonVariant,
  confirmLoading,
  ...props
}) => {
  const handleConfirmationClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    if (onConfirm) {
      onConfirm(event);
    }
  };
  const handleCancelClick = () => {
    const { onClose } = props;

    if (onClose) {
      onClose();
    }
  };

  return (
    <BoModal {...props}>
      <BoDialogHeader>{header}</BoDialogHeader>
      <BoDialogBody>{children}</BoDialogBody>
      {(confirmBtnText || cancelBtnText) && (
        <BoDialogButtonFooter
          cancelDisabled={cancelDisabled}
          confirmDisabled={confirmDisabled}
          cancelBtnText={cancelBtnText}
          confirmBtnText={confirmBtnText}
          confirmBtnType="button"
          onCancel={handleCancelClick}
          onConfirm={handleConfirmationClick}
          confirmButtonVariant={confirmButtonVariant}
          confirmLoading={confirmLoading}
        />
      )}
    </BoModal>
  );
};
