import { ajax } from "@common/services/ajax";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { ProductContractClaimType } from "@ops-utils/types/productContractTypes";

export const BASE_PATH = "/api/rhapsody/product-contract-claims";

export const rhapsodyProductContractClaimsApi = {
  downloadClaimUrl: (productContractClaimId: UUIDType) => {
    return `${BASE_PATH}/${productContractClaimId}/download-claim/`;
  },
  list: (premiseId?: IdType): Promise<ProductContractClaimType[]> => {
    return ajax.get(BASE_PATH, {
      params: {
        ordering: "-stored_at",
        premise: premiseId,
      },
    });
  },
};
