import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface DropNotificationData extends EventLogBaseData {
  dateReceived: string;
  dropDate: string;
  premiseStatus: string;
}

export class DropNotification extends EventLog {
  public readonly dateReceived: string;
  public readonly dropDate: string;
  public readonly premiseStatus: string;

  constructor({
    dateReceived,
    dropDate,
    premiseStatus,
    ...baseEventData
  }: DropNotificationData) {
    super(baseEventData);
    this.dateReceived = dateReceived;
    this.dropDate = dropDate;
    this.premiseStatus = premiseStatus;
  }

  override get body() {
    return `${this.name} - Another REP has submitted an order for this premise - Date of Request: ${this.dateReceived} - Date of Transaction: ${this.dropDate} - Premise Status: ${this.premiseStatus}`;
  }
}
