import { CustomerDashboard } from "@ops/components/CustomerDashboard/CustomerDashboard";
import { StyledCustomerPage } from "@ops/pages/CustomerPage/CustomerPage.styled";
import React from "react";

export const CustomerPage = () => {
  return (
    <StyledCustomerPage>
      <CustomerDashboard />
    </StyledCustomerPage>
  );
};
