import { ReactComponent as CalendarIcon } from "@design-system/icons/bill-factors/Calendar.svg";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { StyledBillFactor } from "@ops/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorBillingCycleProps {
  differenceInDays: number;
}

export const BillFactorBillingCycle = ({
  differenceInDays,
}: BillFactorBillingCycleProps) => {
  const absDifference = Math.abs(Number(differenceInDays));

  return (
    <StyledBillFactor>
      <CalendarIcon />
      <Body1 $fontWeight="Bold">Billing Cycle</Body1>
      <Body2>
        {Number(differenceInDays) > 0
          ? `The number of days on your billing cycle increased by ${absDifference} days this month.`
          : `This month, your billing cycle was ${absDifference} days shorter. When your meter gets read each month can fluctuate.`}
      </Body2>
    </StyledBillFactor>
  );
};
