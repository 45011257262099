import { IdType } from "@common/types/apiTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as HighFive } from "@ops-design-system/images/HighFive.svg";
import { ContractRenewalWizardButtonContainer } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.styled";
import {
  SuccessImageContainer,
  SuccessServiceAddress,
  SuccessStepContainer,
  SuccessTextContainer,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizardSuccessStep/ContractRenewalWizardSuccessStep.styled";
import { useContractRenewalWizard } from "@ops/components/ContractRenewalWizard/useContractRenewalWizard";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useParams } from "react-router-dom";

export const ContractRenewalWizardSuccessStep = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);

  const { reset } = useContractRenewalWizard();

  const handleClose = () => {
    reset();
  };

  if (!premise || !premise.serviceAddress) {
    return null;
  }

  return (
    <SuccessStepContainer>
      <SuccessImageContainer>
        <HighFive />
      </SuccessImageContainer>
      <SuccessTextContainer>
        <Subtitle2 $fontWeight="SemiBold">
          Congrats! Renewal has been completed on:
        </Subtitle2>
        <SuccessServiceAddress>
          <span>{premise.serviceAddress?.line1}</span>
          <span>{premise.serviceAddress?.line2}</span>
        </SuccessServiceAddress>
      </SuccessTextContainer>

      <ContractRenewalWizardButtonContainer>
        <BoButton variant="primary" type="button" onClick={handleClose}>
          Close
        </BoButton>
      </ContractRenewalWizardButtonContainer>
    </SuccessStepContainer>
  );
};
