import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { typography } from "@design-system/theme/typography";
import { Typography, TypographyProps } from "@mui/material";
import React from "react";

export type RhTypographyVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "body3";

type FontWeights = keyof typeof FontWeight;

// Disabling gutterBottom, paragraph was deliberate because designers aren't using it.
export interface RhTypographyProps
  extends Omit<TypographyProps, "gutterBottom" | "paragraph" | "variant"> {
  fontWeight?: FontWeight | FontWeights;
  htmlFor?: string;
  variant?: RhTypographyVariant | "inherit";
}

export const RhTypography = ({
  children,
  fontWeight,
  color,
  variant = "body1",
  ...props
}: RhTypographyProps) => {
  let fontWeightStyle = {};

  // Allows us to set fontWeight with `FontWeight.Bold` or "Bold"
  if (fontWeight) {
    if (fontWeight in FontWeight) {
      fontWeightStyle = { fontWeight };
    } else {
      fontWeightStyle = { fontWeight: FontWeight[fontWeight] };
    }
  }

  // MUI does not support custom named variants for Typography
  // until v5 https://github.com/mui-org/material-ui/pull/20203
  if (variant === "body3") {
    return (
      <Typography
        style={{
          ...(typography.body3 as React.CSSProperties),
          ...fontWeightStyle,
        }}
        color={color}
        {...props}
      >
        {children}
      </Typography>
    );
  } else {
    return (
      <Typography
        variant={variant}
        color={color}
        {...props}
        style={fontWeightStyle}
      >
        {children}
      </Typography>
    );
  }
};
