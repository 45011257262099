import { EM_DASH } from "@common/constants/characters.constant";
import { MONTH_DAY_YEAR_SLASH } from "@common/constants/date.constant";
import { TWELVE_HOUR_MINUTE_AM_PM } from "@common/constants/time.constants";
import { BatchContactLogUploadType } from "@ops-utils/types/batchContactLogUploadTypes";
import dayjs, { Dayjs } from "dayjs";

export class BatchContactLogUpload {
  public readonly fileName: string;
  public readonly logCount: number;
  public readonly uploadError: string | null;
  public readonly didSucceed: boolean;
  public readonly createdAt: Dayjs;

  constructor(data: BatchContactLogUploadType) {
    this.fileName = data.fileName;
    this.logCount = data.logCount;
    this.uploadError = data.uploadError ?? null;
    this.didSucceed = data.didSucceed;
    this.createdAt = dayjs(data.createdAt);
  }

  get uploadDate() {
    return this.createdAt.format(MONTH_DAY_YEAR_SLASH);
  }

  get timeOfUpload() {
    return this.createdAt.format(TWELVE_HOUR_MINUTE_AM_PM);
  }

  get status() {
    return this.didSucceed ? "success" : "fail";
  }

  get reasonOfStatus() {
    if (this.didSucceed) {
      return EM_DASH;
    }

    return this.uploadError;
  }
}
