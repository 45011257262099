import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { green, grey } from "@ops-design-system/styles/palette.colors";
import {
  rhOpsSpacingPx,
  visuallyHidden,
} from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

const TOGGLE_HEIGHT = 20;
const TOGGLE_WIDTH = 40;
const TOGGLE_BORDER = 2;
const TOGGLE_EASING_TIME = "200ms";

export const StyledBoToggle = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
  user-select: none;
  vertical-align: middle;
  width: ${TOGGLE_WIDTH}px;
`;

export const StyledBoToggleLabel = styled.label`
  background-color: ${grey["300"]};
  border-radius: ${TOGGLE_HEIGHT / 2}px;
  cursor: pointer;
  display: block;
  margin: 0;
  overflow: hidden;
  transition: background-color ${TOGGLE_EASING_TIME} ease-in 0s;
  &:hover {
    background-color: ${grey["500"]};
  }
`;

export const StyledBoToggleLabelInner = styled.span`
  display: block;
  margin-left: -100%;
  transition: margin ${TOGGLE_EASING_TIME} ease-in 0s;
  width: 200%;
  &:before,
  &:after {
    box-sizing: border-box;
    color: white;
    display: block;
    float: left;
    font-size: 14px;
    height: ${TOGGLE_HEIGHT}px;
    line-height: ${TOGGLE_HEIGHT}px;
    padding: 0;
    width: 50%;
  }
  &:before {
    content: "";
    padding-left: 10px;
    text-transform: uppercase;
  }
`;

export const StyledBoToggleLabelSwitch = styled.span`
  background: white;
  border-radius: 50%;
  bottom: 0;
  display: block;
  margin: ${TOGGLE_BORDER}px;
  position: absolute;
  right: ${TOGGLE_WIDTH / 2}px;
  top: 0;
  transition: all ${TOGGLE_EASING_TIME} ease-in 0s;
  width: ${TOGGLE_HEIGHT - TOGGLE_BORDER * 2}px;
`;

export const StyledBoToggleCheckbox = styled.input`
  ${visuallyHidden}
  &:checked + ${StyledBoToggleLabel} {
    background-color: ${green["500"]};

    &:hover {
      background-color: ${green["700"]};
    }
    ${StyledBoToggleLabelInner} {
      margin-left: 0;
    }
    ${StyledBoToggleLabelSwitch} {
      right: 0;
    }
  }
`;

export const ToggleWithIndicatorWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const ToggleIndicator = styled(Body2).attrs({ as: "div" })`
  margin-right: ${rhOpsSpacingPx(0.5)};
`;
