import { getBillComparisonChartData } from "@common/components/BillComparisonChart/getBillComparisonChartData";
import {
  drawYAxisTitlePlugin,
  getBillComparisonChartOptions,
} from "@common/components/BillComparisonChart/getBillComparisonChartOptions";
import { ComparisonDataType } from "@common/components/BillComparisonChart/types";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

const StyledBillComparisonChartContainer = styled.div`
  height: 200px;
`;

interface BillComparisonChartProps {
  comparisionData: ComparisonDataType;
  tableHeaderWidth: number;
  xLabels: {
    lastMonth: string;
    lastYear: string;
    thisMonth: string;
  };
  yTitle: string;
}

export const BillComparisonChart = (props: BillComparisonChartProps) => {
  const { comparisionData, tableHeaderWidth, xLabels, yTitle } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  const chartData = getBillComparisonChartData({ comparisionData });
  const chartOptions = getBillComparisonChartOptions({
    comparisionData,
    isMobile,
    tableHeaderWidth,
    xLabels,
  });

  return (
    <StyledBillComparisonChartContainer className="chart-container">
      <Bar
        options={chartOptions}
        data={chartData}
        updateMode="resize"
        plugins={[
          drawYAxisTitlePlugin({
            isMobile,
            title: yTitle,
          }),
        ]}
      />
    </StyledBillComparisonChartContainer>
  );
};
