import { Address } from "@common/models/Address.model";
import { AddressForm } from "@common/models/AddressForm.model";

export const addressIsEqual = (
  addressOne: AddressForm | Address,
  addressTwo: AddressForm | Address
) => {
  return ["line1", "line2"].every((addressKey) => {
    const key = addressKey as "line1" | "line2";

    return addressTwo[key]?.toLowerCase() === addressOne[key]?.toLowerCase();
  });
};
