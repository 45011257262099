import { IdInstance } from "@common/types/apiTypes";
import { EarlyTerminationFeeZod, OrderStatus } from "@common/types/orderTypes";
import {
  GenerationPriceTierSnapshotZod,
  PriceTierSnapshotZod,
} from "@common/types/priceTierSnapshotType";
import { z } from "zod";

export const RhapsodyOrderZod = z.object({
  averageRateAt2000Kwh: z.string(),
  description: z.string(),
  dunsNumber: z.string(),
  earlyTerminationFee: z.optional(z.nullable(EarlyTerminationFeeZod)),
  endDate: z.string(),
  energyRate: z.coerce.number(),
  expectedEndDate: z.optional(z.nullable(z.string())),
  generationPriceTierSnapshots: z.array(GenerationPriceTierSnapshotZod),
  hasContractExpiration: z.boolean(),
  id: IdInstance,
  offerSnapshotSolarEligible: z.boolean(),
  offersnapshotId: z.string(),
  orderNumber: z.string(),
  priceTierSnapshots: z.array(PriceTierSnapshotZod),
  renewalToken: z.nullable(z.string()),
  solarBuybackEnergyRate: z.string(),
  solarEligible: z.boolean(),
  solarGenerationCapped: z.boolean(),
  startDate: z.string(),
  status: z.nativeEnum(OrderStatus),
  termMonths: z.number(),
  title: z.string(),
  utilityName: z.optional(z.string()),
  welcomePacketGeneratedAt: z.nullable(z.string()),
});

export interface RhapsodyOrderType extends z.infer<typeof RhapsodyOrderZod> {}
