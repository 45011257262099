import { rhOpsSpacing } from "@ops-design-system/utils/styleHelpers";

export const MARGIN = {
  large: { h: rhOpsSpacing(2.5), v: rhOpsSpacing(2) },
};
export const PADDING = {
  large: { h: rhOpsSpacing(2.5), v: rhOpsSpacing(2) },
};
export const BORDER_RADIUS = {
  default: "6px",
};
