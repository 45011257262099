import { H3 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledBillBreakdownFactorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const StyledFactorsTitle = styled(H3)`
  color: ${darkPurple[500]};
`;

export const StyledBillBreakdownFactorsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  margin-top: ${rhOpsSpacingPx(3)};
  text-align: center;
`;
