import { EnvTypes } from "@common/enums/envTypes.enum";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";

export function isLocal(): boolean {
  return getEnv() === EnvTypes.local || getEnv() === EnvTypes.localStaging;
}

export function isProduction(): boolean {
  return getEnv() === EnvTypes.production;
}
