import { useOktaGroups } from "@common/hooks/useOktaGroups";
import { isCSRs, isFinance, isOps } from "@common/utils/authenticationHelpers";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  customerSearchPath,
  financeRootPath,
  signInPath,
} from "@ops/routes/routePaths";
import React from "react";
import { Navigate } from "react-router-dom";

export const IndexPageRedirect = () => {
  const { groups, hasResolvedGroups } = useOktaGroups();

  if (!hasResolvedGroups) {
    return <RhCircularProgress />;
  }

  if (groups.length === 0) {
    return <Navigate to={signInPath()} />;
  }

  if (isFinance(groups) && !(isOps(groups) || isCSRs(groups))) {
    return <Navigate to={financeRootPath()} />;
  }

  return <Navigate to={customerSearchPath()} />;
};
