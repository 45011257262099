import { Premise } from "@common/models/Premise.model";
import { CommunicationPreferenceType } from "@common/types/premiseTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import {
  BillingPreferencesCard,
  BillingPreferencesLabelContainer,
  BillingPreferencesWarningCard,
  CommunicationPreferenceValue,
} from "@ops/components/BillingPreferencesInfoCard/BillingPreferencesInfoCard.styled";
import { EditAutoPay } from "@ops/components/BillingPreferencesInfoCard/EditAutoPay/EditAutoPay";
import { EditCommunicationPreferences } from "@ops/components/BillingPreferencesInfoCard/EditCommunicationPreferences/EditCommunicationPreferences";
import { EditPYDD } from "@ops/components/BillingPreferencesInfoCard/EditPYDD/EditPYDD";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { usePaymentMethodOptions } from "@ops/hooks/usePaymentMethodOptions";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { ordinal } from "@ops/utils/ordinalHelper";
import React from "react";

interface BillingPreferencesInfoCardProps {
  pastDueBalance?: number;
  premise: Premise;
}

export const BillingPreferencesInfoCard = ({
  premise,
  pastDueBalance,
}: BillingPreferencesInfoCardProps) => {
  const { paymentMethods, requestMonitor } = usePaymentMethods(premise.id);
  const { defaultPaymentMethod } = usePaymentMethodOptions(paymentMethods, {
    withDefaultFallback: true,
  });

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: premise.currentOrder?.offersnapshotId ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offersnapshotId),
    },
  });

  const onOffPYDD = (val: boolean = false) =>
    val ? `${ordinal(Number(premise.pickedBillingDueDay))}, monthly` : "OFF";

  const onOffAutoPay = (val: boolean = false) =>
    val ? `${defaultPaymentMethod?.companyNameAndNumber}` : "OFF";

  const getCommunicationPreferenceLabel = () => {
    if (premise.communicationPreference === CommunicationPreferenceType.EBILL) {
      return "eBill ON";
    } else if (
      premise.communicationPreference === CommunicationPreferenceType.PAPERLESS
    ) {
      return "Paperless ON";
    } else {
      return "Paperless OFF";
    }
  };

  if (requestMonitor.isPending || offerSnapshotQuery.isPending) {
    return (
      <BoCard>
        <BoCircularProgress />
      </BoCard>
    );
  }

  const sectionName = "Billing Preferences";

  const showPaperlessAutoPayWarning = Boolean(
    offerSnapshotQuery.data?.isAutoPayPaperlessDiscountOffer &&
      (!premise.autopay || !premise.isPaperless)
  );

  return (
    <section aria-label={sectionName}>
      <BoCardLabel>{sectionName}</BoCardLabel>

      {showPaperlessAutoPayWarning ? (
        <BillingPreferencesWarningCard $variant={BoCardVariants.Warning}>
          The customer must be enrolled in Auto Pay and Paperless to be eligible
          to receive the Auto Pay and Paperless Credit.
        </BillingPreferencesWarningCard>
      ) : null}

      <BillingPreferencesCard
        $variant={
          premise.isOnLegacyEBill
            ? BoCardVariants.Warning
            : BoCardVariants.Normal
        }
      >
        <BoInfoContainer>
          <BillingPreferencesLabelContainer>
            <BoInfoLabel>Auto Pay</BoInfoLabel>
            <EditAutoPay pastDueBalance={pastDueBalance} premise={premise} />
          </BillingPreferencesLabelContainer>
          <BoInfoValue>
            {onOffAutoPay(defaultPaymentMethod && premise.autopay)}
          </BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BillingPreferencesLabelContainer>
            <BoInfoLabel>Communication Preferences</BoInfoLabel>
            <EditCommunicationPreferences premise={premise} />
          </BillingPreferencesLabelContainer>
          <CommunicationPreferenceValue $isRed={premise.isOnLegacyEBill}>
            {getCommunicationPreferenceLabel()}
          </CommunicationPreferenceValue>
        </BoInfoContainer>
        <BoInfoContainer>
          <BillingPreferencesLabelContainer>
            <BoInfoLabel>Pick Your Due Date</BoInfoLabel>
            <EditPYDD premise={premise} />
          </BillingPreferencesLabelContainer>
          <BoInfoValue>
            {onOffPYDD(Boolean(premise.pickedBillingDueDay))}
          </BoInfoValue>
        </BoInfoContainer>

        <BoInfoContainer>
          <BoInfoLabel>Average Billing</BoInfoLabel>
          <BoInfoValue>
            {premise.averagedBilling
              ? formatCurrency(Number(premise.averagedBillingMonthlyCharge))
              : "OFF"}
          </BoInfoValue>
        </BoInfoContainer>
      </BillingPreferencesCard>
    </section>
  );
};
