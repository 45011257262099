export enum TabType {
  billing = "billing",
  confirmation = "confirmation",
  contactInfo = "contactInfo",
  marketing = "marketing",
  plan = "plan",
  productAddons = "productAddons",
  serviceAddress = "serviceAddress",
}

export const prospectEditorTabs: Array<TabType> = [
  TabType.marketing,
  TabType.serviceAddress,
  TabType.plan,
  TabType.contactInfo,
  TabType.billing,
  TabType.productAddons,
  TabType.confirmation,
];

export const tabDisplayNames: Record<TabType, string> = {
  billing: "Billing",
  confirmation: "Confirmation",
  contactInfo: "Contact Info",
  marketing: "Marketing",
  plan: "Plan",
  productAddons: "Additional Services",
  serviceAddress: "Service Address",
};
