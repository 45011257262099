import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { CurrentProductContracts } from "@ops/components/CurrentProductContracts/CurrentProductContracts";
import { NewProductContractCard } from "@ops/components/NewProductContractCard/NewProductContractCard";
import { ProductContractClaimTable } from "@ops/components/ProductContractClaimTable/ProductContractClaimTable";
import { ProductContractsHistory } from "@ops/components/ProductContractsHistory/ProductContractsHistory";
import { ProductContractsUpcoming } from "@ops/components/ProductContractsUpcoming/ProductContractsUpcoming";
import React from "react";
import styled from "styled-components";

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
`;

const StyledGrid = styled(BoGrid)`
  margin-bottom: ${rhOpsSpacingPx(0.5)};
`;

export const AddOnsTab = () => {
  const { featureFlagClient } = useFeatureFlagClient();

  const {
    opsProductAddOns,
    opsPendingProductContracts,
    opsProductContractClaims,
  } = featureFlagClient.getFlags([
    ["opsProductAddOns", false],
    ["opsPendingProductContracts", false],
    ["opsProductContractClaims", false],
  ]);

  if (!opsProductAddOns.value) {
    return <section aria-label="Not Add Ons" />;
  }

  return (
    <section aria-label="Add Ons">
      <StyledGrid>
        <BoGridColumn>
          <CurrentProductContracts />
        </BoGridColumn>
        <BoGridColumn>
          <NewProductContractCard />
          {opsProductContractClaims.value && <ProductContractClaimTable />}
        </BoGridColumn>
      </StyledGrid>

      <TableContainer>
        {opsPendingProductContracts.value ? <ProductContractsUpcoming /> : null}
        <ProductContractsHistory />
      </TableContainer>
    </section>
  );
};
