import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { BoDateInput } from "@ops-design-system/components/BoDate/BoDate";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { PremiseIndicatorsWizardComponentName } from "@ops/components/PremiseIndicatorsWizard/PremiseIndicatorsWizardHelpers";
import { useIndicatorsWizardContext } from "@ops/hooks/useIndicatorsWizardContext";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  min-width: 250px;
`;

export const EditActivationDateModal = () => {
  const {
    wizardState,
    updateWizardState,
    setActiveComponentName,
    activeComponentName,
  } = useIndicatorsWizardContext();

  const { checkboxStatusIndicator, indicatorIdToEditActivationDate: id } =
    wizardState;
  const indicatorDetail = checkboxStatusIndicator[id ?? ""];
  const initialActivationDate = indicatorDetail.activationDate;
  const [activationDate, setActivationDate] = useState<string | null>(
    initialActivationDate
  );

  useEffect(() => {
    if (
      activeComponentName ===
      PremiseIndicatorsWizardComponentName.selectIndicators
    ) {
      updateWizardState({ indicatorIdToEditActivationDate: null });
    }
  }, [setActiveComponentName]);

  if (!id) {
    return null;
  }

  const openEditPremiseIndicatorsModal = () => {
    setActiveComponentName(
      PremiseIndicatorsWizardComponentName.selectIndicators
    );
  };

  const onUpdate = () => {
    // Update indicator checked attr and set Activation date
    if (activationDate !== indicatorDetail.activationDate) {
      updateWizardState({
        ...wizardState,
        checkboxStatusIndicator: {
          ...checkboxStatusIndicator,
          [id]: {
            ...checkboxStatusIndicator[id],
            activationDate,
            checked: true,
          },
        },
      });
    }

    openEditPremiseIndicatorsModal();
  };

  return (
    <div>
      <BoDialogHeader>Set Activation Date</BoDialogHeader>
      <BoDialogBody>
        <FormFlexRow>
          <DatePickerContainer>
            <Subtitle2>{indicatorDetail.name}</Subtitle2>
            <BoDateInput
              value={activationDate}
              name="ActivationDate"
              label="Activation Date"
              placeholder="mm/dd/yyyy"
              minDate={dayjs().format(ISO8601_DATE_FORMAT)}
              onChange={(value) => {
                setActivationDate(value ?? null);
              }}
              closeOnSelect
            />
          </DatePickerContainer>
        </FormFlexRow>
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText="Confirm Date"
        cancelBtnText="Cancel"
        onConfirm={onUpdate}
        onCancel={openEditPremiseIndicatorsModal}
        confirmDisabled={!activationDate}
      />
    </div>
  );
};
