import { IdType } from "@common/types/apiTypes";
import { rhapsodyInvoicesApi } from "@ops-data/api/rhapsodyInvoicesApi";
import { InvoiceQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceUsageQueryProps {
  invoiceId: IdType;
}

export const useInvoiceUsageQuery = (props: UseInvoiceUsageQueryProps) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => rhapsodyInvoicesApi.usage(invoiceId),
    queryKey: InvoiceQueryKeys.usage(invoiceId),
  });
};
