import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { H4 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ProspectType } from "@ops-utils/types/prospectTypes";
import React from "react";
import styled from "styled-components";

interface NewProspectHeaderProps {
  prospect: ProspectType;
}

const StyledSubtitle = styled(H4)`
  font-weight: ${FontWeight.SemiBold};
  margin-top: ${rhOpsSpacingPx(1)};
`;

export const NewProspectHeader = ({ prospect }: NewProspectHeaderProps) => {
  return (
    <BoGrid $variant={GridVariants.Grid1_1}>
      <BoGridColumn>
        <StyledSubtitle>New Prospect</StyledSubtitle>
      </BoGridColumn>
      <BoGridColumn>
        <BoCard>
          <BoInfoContainer>
            <BoInfoLabel>Prospect ID</BoInfoLabel>
            <BoInfoValue>{prospect.id}</BoInfoValue>
          </BoInfoContainer>
        </BoCard>
      </BoGridColumn>
    </BoGrid>
  );
};
