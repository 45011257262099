import { AddressForm } from "@common/models/AddressForm.model";
import { Premise } from "@common/models/Premise.model";
import { BoDialogBodyContainer } from "@ops-design-system/components/BoDialog/BoDialog.styled";
import {
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { NewPremiseDetailsSidebar } from "@ops/components/NewPremiseDetailsSidebar/NewPremiseDetailsSidebar";
import { ConfirmTransferDetails } from "@ops/components/TransferServiceWizard/ConfirmTransferDetails/ConfirmTransferDetails";
import { TransferServiceSubmission } from "@ops/components/TransferServiceWizard/ConfirmTransferDetails/TransferServiceSubmission.service";
import { CustomerInfoSideBar } from "@ops/components/TransferServiceWizard/CustomerInfoSideBar";
import { MailingAddressForm } from "@ops/components/TransferServiceWizard/MailingAddressForm/MailingAddressForm";
import { MovingForm } from "@ops/components/TransferServiceWizard/MovingForm/MovingForm";
import { NewPremiseForm } from "@ops/components/TransferServiceWizard/NewPremiseForm/NewPremiseForm";
import { OfferForm } from "@ops/components/TransferServiceWizard/OfferForm/OfferForm";
import { TransferServiceError } from "@ops/components/TransferServiceWizard/TransferServiceError/TransferServiceError";
import { initialTosWizardState } from "@ops/components/TransferServiceWizard/TransferServiceWizard.constants";
import { WizardComponentName } from "@ops/components/TransferServiceWizard/TransferServiceWizard.types";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";

const TransferServiceWizardBody = styled(BoDialogBodyContainer)`
  display: flex;
  flex-direction: row;
`;
const SideBarWrapper = styled.div`
  flex-basis: 20%;
`;
const BodyContentWrapper = styled.div`
  margin-left: ${rhOpsSpacingPx(6)};
  min-height: 595px;
  width: 100%;
  &:only-child {
    margin-left: 0px;
  }
`;

interface TransferServiceWizardModalProps {
  close: () => void;
  isOpen: boolean;
  premise: Premise;
}

const WIZARD_COMPONENT: Record<WizardComponentName, () => ReactElement> = {
  [WizardComponentName.newPremiseForm]: () => <NewPremiseForm />,
  [WizardComponentName.mailingAddressForm]: () => <MailingAddressForm />,
  [WizardComponentName.offerForm]: () => <OfferForm />,
  [WizardComponentName.movingForm]: () => <MovingForm />,
  [WizardComponentName.confirmTransferDetails]: () => (
    <ConfirmTransferDetails />
  ),
  [WizardComponentName.error]: () => <TransferServiceError />,
};

export const TransferServiceWizardModal = ({
  close,
  isOpen,
  premise,
}: TransferServiceWizardModalProps) => {
  const {
    activeComponentName,
    setActiveComponentName,
    nextButtonProps: { disabled, loading },
    restoreStateToInitialValues,
    updateTosWizardState,
    visitedComponents,
    setVisitedComponents,
    tosWizardState: {
      serviceAddress,
      mailingAddress,
      offerSnapshotId,
      esiId,
      utilityName,
    },
  } = useTransferServiceWizardContext();

  const submitContentForm = () => {
    document
      .getElementById(activeComponentName)
      ?.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
  };

  const handleModalClose = () => {
    restoreStateToInitialValues();
    close();
  };

  const handleOnCancel = () => {
    const currentVisitedComponents = [...visitedComponents];
    const currentActiveComponent = currentVisitedComponents.pop();

    if (currentActiveComponent !== undefined) {
      setVisitedComponents(currentVisitedComponents);
      setActiveComponentName(currentActiveComponent);
    }
  };

  useEffect(() => {
    const service = new TransferServiceSubmission(
      initialTosWizardState,
      premise
    );
    const error = service.checkTransferability();

    if (error) {
      updateTosWizardState({ transferServiceError: error });
    }
  }, [premise.id, premise.orders, activeComponentName]);

  const includeSideBar = ![
    WizardComponentName.confirmTransferDetails,
    WizardComponentName.error,
  ].includes(activeComponentName);

  const buttonDisabled =
    activeComponentName === WizardComponentName.error || disabled;

  return (
    <BoModal open={isOpen} onClose={handleModalClose} size="large">
      <BoDialogHeader>Transfer Service</BoDialogHeader>
      <TransferServiceWizardBody>
        {includeSideBar && (
          <SideBarWrapper>
            <CustomerInfoSideBar />
            {activeComponentName !== WizardComponentName.newPremiseForm && (
              <NewPremiseDetailsSidebar
                address={new AddressForm(serviceAddress)}
                mailingAddress={new AddressForm(mailingAddress)}
                offerSnapshotId={offerSnapshotId}
                esiId={esiId}
                utilityName={utilityName}
              />
            )}
          </SideBarWrapper>
        )}
        <BodyContentWrapper>
          {WIZARD_COMPONENT[activeComponentName]()}
        </BodyContentWrapper>
      </TransferServiceWizardBody>
      <BoDialogButtonFooter
        confirmBtnText={
          activeComponentName === WizardComponentName.confirmTransferDetails
            ? "Submit"
            : "Next"
        }
        confirmDisabled={buttonDisabled}
        confirmLoading={loading}
        cancelBtnText={
          activeComponentName !== WizardComponentName.newPremiseForm &&
          activeComponentName !== WizardComponentName.error
            ? "Back"
            : undefined
        }
        onCancel={handleOnCancel}
        onConfirm={submitContentForm}
      />
    </BoModal>
  );
};
